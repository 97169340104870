<div class="wrapper">
  <div class="status">
    <crc-m-shared-status-icon
      [error]="!success"
      [path]="success ? '/assets/icons/checked.svg' : '/assets/icons/close.svg'"
      [success]="success"
    >
    </crc-m-shared-status-icon>
  </div>
  <div class="text">
    {{ success ? successText : failText }}
  </div>
</div>

<div class="button--wrapper">
  <crc-m-shared-button
    (clicked)="ok.emit(success)"
    [type]="hardButtonType ?? (success ? 'active' : 'secondary')"
  >
    {{ buttonText | translate }}
  </crc-m-shared-button>
</div>
<div class="result-content">
  <ng-content></ng-content>
</div>
