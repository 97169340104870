export function getEgtDigitalCloseUrl(url: string): string | null {
  const closeUrlIndexLeft = url.indexOf(`closeUrl=`);
  if (closeUrlIndexLeft === -1) {
    return null;
  }
  const closeUrlIndexRight = url.indexOf('&demo', closeUrlIndexLeft);
  if (closeUrlIndexRight === -1) {
    return null;
  }
  const tempValue = url?.substring(closeUrlIndexLeft, closeUrlIndexRight);
  const [, ...tempValue1] = tempValue?.split('=') ?? [];
  const finalValue = (() => {
    const joined = tempValue1?.join();
    try {
      return decodeURIComponent(joined);
    } catch (err) {
      return joined;
    }
  })();
  if (!finalValue.includes('http')) {
    return null;
  }
  return finalValue || null;
}
