import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';

import { EnvironmentService, HttpService } from '@crc/shared';

import {
  fastTrackData,
  FastTrackEnv,
  FastTrackItem,
  LoginAuthToken
} from '../entity/fast-track-item';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FastTrackService {
  private readonly fusionUrl = new BehaviorSubject(null);
  private readonly sid = new BehaviorSubject(null);
  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
    private readonly apiService: HttpService
  ) {}

  track(notification: FastTrackItem): Observable<void> {
    return this.http.post<void>(
      this.env.config.fastTrackApi as string,
      notification,
      {
        headers: {
          'X-Api-Key':
            '8241de1afcbdc5b85ff12b082311af6fd62a27de438105f5395f0eb2ecd20e7e'
        }
      }
    );
  }

  getFusionUrl(): string {
    return this.fusionUrl.value;
  }
  getFTData(sid: string): Observable<LoginAuthToken> {
    return this.apiService
      .get$<FastTrackEnv>({
        customBaseUrl: 'https://am-events.fasttrack-solutions.com',
        path: '/api/v1/config/crocobet'
      })
      .pipe(
        switchMap((payload) => this.handlePayload(payload.fusionUrl, sid)),
        catchError(() => of(null))
      );
  }

  deleteFtMessage(id: number) {
    return this.apiService
      .delete$({
        customBaseUrl: this.fusionUrl.value,
        path: `/Notifications/v2/user-notification/${id}`,
        headers: {
          AuthToken: this.sid.value
        }
      })
      .pipe(catchError(() => of(null)));
  }

  markAsRead(id: number) {
    return this.apiService
      .post$({
        customBaseUrl: this.fusionUrl.value,
        path: '/Notifications/MarkNotificationAsRead',
        body: {
          MessageId: id
        },
        headers: {
          AuthToken: this.sid.value
        }
      })
      .pipe(catchError(() => of(null)));
  }

  private handlePayload(fusionUrl: string, sid: string) {
    return this.postLoginToken(fusionUrl, sid)
      .pipe(
        tap((data) => {
          this.fusionUrl.next(fusionUrl);
          this.sid.next(data.Data.Authentication.AuthToken);
        })
      )
      .pipe(catchError(() => of(null)));
  }

  private postLoginToken(
    fusionUrl: string,
    sid: string
  ): Observable<LoginAuthToken> {
    return this.apiService
      .post$<LoginAuthToken, string>({
        customBaseUrl: fusionUrl,
        path: '/Platform/LoginAuthToken',
        headers: {
          AuthToken: sid
        }
      })
      .pipe(catchError(() => of(null)));
  }

  getNotifications(
    fusionUrl: string,
    authToken: string,
    unreadOnly: boolean
  ): Observable<fastTrackData> {
    return this.apiService
      .get$<fastTrackData>({
        customBaseUrl: fusionUrl,
        path: `/Notifications/v2/user-notifications?unread-only=${unreadOnly}`,
        headers: {
          AuthToken: authToken
        }
      })
      .pipe(catchError(() => of(null)));
  }
}
