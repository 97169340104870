import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of, throttleTime } from 'rxjs';

import { AuthService } from '../../services';
import { captchaActions } from '../actions';
import { AuthResponse } from '../../entity';

@Injectable()
export class CaptchaEffects {
  resetCaptchaCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(captchaActions.resetCaptchaCode),
      throttleTime(1000),
      switchMap(({ payload }) => {
        return this.authService.resetCaptchaCode$(payload || '').pipe(
          mergeMap(({ captcha }) => {
            return [
              captchaActions.resetCaptchaCodeSuccess(),
              captchaActions.updateCaptchaCode({ payload: captcha })
            ];
          }),
          catchError((res) => {
            return of(captchaActions.resetCaptchaCodeError());
          })
        );
      })
    );
  });

  checkCaptchaValidity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(captchaActions.checkCaptchaValidity),
      throttleTime(1000),
      switchMap(({ payload }) => {
        return this.authService.checkCaptchaValidity$(payload).pipe(
          map((response: AuthResponse) => {
            return captchaActions.checkCaptchaValiditySuccess({
              payload: response.success
            });
          })
        );
      }),
      catchError(() =>
        of(captchaActions.checkCaptchaValiditySuccess({ payload: false }))
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService
  ) {}
}
