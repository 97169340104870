import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }

  static maxValueValidator(
    maxValue: number,
    error: ValidationErrors
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const valid = control.value > maxValue;

      return valid ? error : null;
    };
  }

  static notEnoughBalanceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      return { notEnoughBalanceError: true };
    };
  }

  static notEnoughBalanceValidator2(balance: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || balance >= control.value) {
        return null;
      }

      return { notEnoughBalanceError: true };
    };
  }

  static passport(control: AbstractControl): ValidationErrors | null {
    if (control?.parent) {
      const countryCode = control.parent.get('countryCode')?.value;
      const id = control.parent.get('documentId')?.value;
      const isCountryGeo = countryCode === 'GE';
      const pattern = isCountryGeo ? /^([0-9]){11}$/ : /^[0-9a-zA-Z]*$/;
      return pattern.test(id) ? null : { format: true };
    }
    return { format: true };
  }

  static mobileNumberLength(control: AbstractControl) {
    if (control?.parent) {
      const countryCode = control.parent.parent?.get('countryCode')?.value;
      const isCountryGeo = countryCode === 'GE';

      if (isCountryGeo) {
        if (control.value.length === 9) {
          return null;
        }
      } else if (control.value.length > 4) {
        return null;
      }
    }
    return { pattern: true };
  }
}
