import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Observable,
  of,
  Subject
} from 'rxjs';
import { BonusSteps } from '../entity/bonus.navigation';
import { BonusCoinService } from '../service/bonus-coin.service';
import { catchError, map, take, tap } from 'rxjs/operators';
import { formatDate, isNonNullOrUndefined } from '../functions/functions';
import {
  BalanceEventsData,
  BalanceType,
  BonusCoinHistoryData,
  ExchangeBody
} from '../entity/bonus.data';
import {
  BalanceStateExchangeOption,
  BonusData,
  exchangeData
} from '../entity/bonus.state';
import {
  ExchangeData,
  ExchangeDataResponse,
  ExchangeResult
} from '../entity/bonus.exchange';

@Injectable({
  providedIn: 'root'
})
export class BonusCoinFacade {
  private readonly $bonusNavigation = new BehaviorSubject<BonusSteps>('action');

  private readonly $openBonusPopUpWEB = new BehaviorSubject<boolean>(false);

  private readonly $resultType = new BehaviorSubject<boolean>(false);

  private readonly $balanceID = new BehaviorSubject<string>('');

  private readonly $balanceType = new BehaviorSubject<string>('');

  private readonly $landingUrl = new BehaviorSubject<string>('');

  get activeBonusBalanceId(): string {
    return this.$balanceID.value;
  }

  constructor(private readonly bonusCoinService: BonusCoinService) {}
  setBalanceTypeValue(value: string) {
    this.$balanceType.next(value);
  }
  setLandingUrlValue(value: string) {
    this.$landingUrl.next(value);
  }

  setActiveBonusBalanceId(value: string) {
    this.$balanceID.next(value);
  }
  setResultTypeValue(state: boolean) {
    this.$resultType.next(state);
  }
  getResultTypeState(): Observable<boolean> {
    return this.$resultType.asObservable();
  }
  setBonusPopUpState(state: boolean) {
    this.$openBonusPopUpWEB.next(state);
  }
  getBonusPopUpState(): Observable<boolean> {
    return this.$openBonusPopUpWEB.asObservable();
  }

  setBonusStep(value: BonusSteps) {
    this.$bonusNavigation.next(value);
  }
  getBonusStep(): Observable<BonusSteps> {
    return this.$bonusNavigation.asObservable();
  }
  get bonusStepValue(): BonusSteps {
    return this.$bonusNavigation.value;
  }

  getBonusBalance(): Observable<BonusData> {
    const period = formatDate(new Date());
    return this.bonusCoinService
      .getUserBalanceState('BONUS_COIN', String(period))
      .pipe(
        filter(isNonNullOrUndefined),
        map((data) => {
          const bonusData: BonusData = {
            balance: data?.data?.aggregations?.amount
          };
          const exchangeOptions: exchangeData[] = [];
          data?.data?.exchangeOptions?.forEach(
            (option: BalanceStateExchangeOption) => {
              const singleOption: exchangeData = {
                rate: option?.rate,
                fromBalanceId: option?.fromBalanceId,
                balanceId: option?.toBalance?.balanceId,
                promotionDetails: option?.toBalance?.promotionDetails,
                visualDetails: option?.toBalance?.visualDetails,
                amount: option?.toBalance?.aggregations?.amount,
                campaignId: option?.toBalance?.promotionDetails?.campaignId
              };
              exchangeOptions.push(singleOption);
            }
          );
          Object.assign(bonusData, {
            ...bonusData,
            exchangeOptions: exchangeOptions
          });
          return bonusData;
        })
      );
  }

  exchangeBonusBalance(
    balanceId: string,
    body: ExchangeBody
  ): Observable<ExchangeResult> {
    return combineLatest([
      this.bonusCoinService.postBonusExchange(balanceId, body),
      this.$balanceType.asObservable(),
      this.$landingUrl.asObservable()
    ]).pipe(
      filter(isNonNullOrUndefined),
      take(1),
      map(
        ([bonusExchange, balanceType, landingUrl]: [
          ExchangeDataResponse,
          string,
          string
        ]) => {
          const BALANCE_ADDED = bonusExchange.data.filter(
            (res) => res.type === 'BALANCE_ADDED'
          );

          const BALANCE_EXCHANGED = bonusExchange.data.filter(
            (exchangedData: ExchangeData) => {
              return exchangedData.type === 'BALANCE_EXCHANGED';
            }
          );
          const result: ExchangeResult = {} as ExchangeResult;
          if (BALANCE_EXCHANGED) {
            Object.assign(result, {
              success: true,
              coinAmount: BALANCE_ADDED[0]?.data?.amount,
              landingUrl: landingUrl,
              promotionName: BALANCE_EXCHANGED[0]?.data.toBalanceId,
              coinType: balanceType,
              expireDate: BALANCE_ADDED[0]?.metadata?.expiresAt
            });
          }
          return result;
        }
      ),
      catchError((error) => {
        if (error && error.error.message.includes('AccountBalanceLimitError')) {
          this.setResultTypeValue(null);
        } else {
          this.setResultTypeValue(false);
        }
        this.setBonusStep('result');
        return of(null);
      })
    );
  }

  getBonusCoinHistory(
    page: number,
    pageSize: number = 15
  ): Observable<BonusCoinHistoryData[]> {
    return this.bonusCoinService
      .getUserBonusEvents(
        'BONUS_COIN',
        BalanceType.BALANCE_EXCHANGED,
        page,
        pageSize
      )
      .pipe(
        filter(isNonNullOrUndefined),
        map((data) => {
          return data.data?.map((event: BalanceEventsData) => {
            return {
              date: event.timestamp,
              quantity: event.data.toAmount,
              promoName: event.data.toBalanceId,
              id: event.id
            };
          });
        })
      );
  }
}
