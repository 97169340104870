import { generateCrmMenu } from '../functions/functions';
import { CrmMenuItem } from './crm.interface';
import { BonusMenu } from './bonuses.interface';

export enum CRM_ROUTES {
  notifications = 'notifications',
  bonuses = 'bonuses',
  missions = 'missions'
}

export enum CRM_TYPES {
  FREEBET = 'FREEBET',
  FREESPIN = 'FREESPIN',
  UFOSPIN = 'UFOSPIN',
  AMOUNT = 'CASH'
}

export const CRM_MENU_ITEMS: CrmMenuItem[] = [
  generateCrmMenu(
    'user_notifications',
    'notification',
    CRM_ROUTES.notifications
  ),
  generateCrmMenu('user_bonuses', 'bonus', CRM_ROUTES.bonuses),
  generateCrmMenu('user_missions', 'mission', CRM_ROUTES.missions)
];

export const CRM_HEADER_MENU_ITEMS: CrmMenuItem[] = [
  generateCrmMenu('user_notifications', 'notification'),
  generateCrmMenu('user_bonuses', 'bonus'),
  generateCrmMenu('user_missions', 'mission')
];

export const BONUS_MENU_ITEMS: BonusMenu[] = [
  { filter: '', category: 'bonus.all' },
  { filter: CRM_TYPES.FREESPIN, category: 'bonus.freespin' },
  { filter: CRM_TYPES.AMOUNT, category: 'bonus.amount' },
  { filter: CRM_TYPES.UFOSPIN, category: 'bonus.ufospin' },
  { filter: CRM_TYPES.FREEBET, category: 'bonus.freebet' }
];
