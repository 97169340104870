import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { NotificationUpdate } from '../entity/crm.interface';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { CrmService } from '../services/crm.service';
import { LanguageFacade } from '@crc/shared';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FastTrackFacade, FastTrackNotification } from '../../fast-track';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class CrmNotificationsFacade {
  private readonly $filterUnread = new BehaviorSubject<boolean>(false);

  private readonly $notificationUpdate =
    new BehaviorSubject<NotificationUpdate>({
      page: 0,
      count: 10
    });

  $cashedNotifications = new Subject<FastTrackNotification[]>();

  $activeId = new BehaviorSubject<number>(null);
  notifications$: Observable<FastTrackNotification[]>;
  $notifications = new Subject<FastTrackNotification[]>();
  $notificationToRemoveId = new BehaviorSubject<number>(null);

  notificationsArray: FastTrackNotification[] = [];
  $unreadNotifications = new BehaviorSubject<FastTrackNotification[]>([]);

  constructor(
    private readonly crmService: CrmService,
    private readonly languageFacade: LanguageFacade,
    private readonly fastTrackFacade: FastTrackFacade
  ) {
    this.notifications$ = this.languageFacade.current$.pipe(
      switchMap(() => {
        return this.$notificationUpdate.pipe(
          debounceTime(300),
          switchMap((notificationUpdate: NotificationUpdate) =>
            this.fastTrackFacade.getFastTrackAllNotificationData().pipe(
              map((n) => this.fastTrackFacade.notificationsFilterByEvent(n)),
              tap((notifications) => {
                this.$unreadNotifications.next(notifications);
                this.updatePage(notificationUpdate, notifications);
              })
            )
          )
        );
      })
    );
  }

  updatePage(
    notificationUpdate: NotificationUpdate,
    notifications: FastTrackNotification[]
  ) {
    if (notificationUpdate.page === 0) {
      this.notificationsArray = [];
    }
    const startIdx = notificationUpdate.page * notificationUpdate.count;
    const endIdx = startIdx + notificationUpdate.count;
    const slicedNotifications = notifications.slice(startIdx, endIdx);
    this.notificationsArray =
      this.notificationsArray.concat(slicedNotifications);
    this.$cashedNotifications.next(this.notificationsArray);
  }

  updateNotifications(data: NotificationUpdate) {
    this.$notificationUpdate.next(data);
  }

  getFilteredNotifications(): Observable<FastTrackNotification[]> {
    return this.$cashedNotifications.pipe(
      switchMap((data) =>
        this.$filterUnread.pipe(
          map((filteredBy) => {
            filteredBy
              ? this.$notifications.next(
                  this.$unreadNotifications?.value.filter(
                    (data) => !data.IsRead
                  )
                )
              : this.$notifications.next(data);
            return data;
          })
        )
      )
    );
  }

  getNotificationLength() {
    this.fastTrackFacade.setFastTrackUnreadNotificationsDataValue(
      this.$unreadNotifications?.value?.filter((data) => !data.IsRead).length
    );
  }

  setUnreadNotification(data: boolean) {
    this.$filterUnread.next(data);
  }

  onRemoveNotification(index: number, elements: FastTrackNotification[]) {
    return elements.splice(index, 1);
  }

  getAmountOfHeaderNotifications$(): Observable<number> {
    return this.fastTrackFacade.getFastTrackUnreadNotificationData();
  }
}
