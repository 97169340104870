import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { finalize, iif, Observable, of, Subject, tap, timer, zip } from 'rxjs';
import { NgSelectItem } from '@crc/components';
import { map, switchMap } from 'rxjs/operators';
import {
  AuthFacade,
  FaqCategory,
  FaqFacade,
  FastTrackFacade,
  Register,
  RegisterFacade,
  RegisterFormHelper
} from '@crc/facade';
import {
  AnalyticsService,
  DateService,
  Language,
  LanguageFacade,
  MatomoAnalyticsService,
  nextTick
} from '@crc/shared';
import { UntypedFormGroup } from '@angular/forms';
import { MobileFaqFacade } from '../../../faq/entity';
import { LayoutStateFacade } from '../../../../layout/state';

@Component({
  selector: 'crc-m-register-container',
  templateUrl: './register-container.component.html',
  styleUrls: ['./register-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterContainerComponent implements OnDestroy {
  countries$ = this.registerFacade.getCountries$();
  cities$ = this.registerFacade.getCities$();
  months$: Observable<NgSelectItem[]>;
  years: NgSelectItem[] = [];
  days: NgSelectItem[] = [];
  registrationStep = this.registerFacade.registrationStep;
  cdaShowError$ = this.registerFacade.getCdaShowError$();
  cdaNationalityShowError$ = this.registerFacade.getCdaNationalityShowError$();
  $fieldsAvailable: Subject<{ mobileField: boolean; idField: boolean }> =
    new Subject();
  protected readonly $registrationEffect = new Subject<unknown>();
  protected readonly registrationEffect$ =
    this.$registrationEffect.asObservable();
  public readonly rateLimitErrorDataSource$ =
    this.registerFacade.getRateLimitErrorDataSource$();

  constructor(
    private readonly registerFacade: RegisterFacade,
    private readonly formHelperService: RegisterFormHelper,
    private readonly languageFacade: LanguageFacade,
    private readonly analyticsService: AnalyticsService,
    private readonly matomoAnalyticsService: MatomoAnalyticsService,
    private readonly dateService: DateService,
    private readonly authFacade: AuthFacade,
    private readonly faqFacade: FaqFacade,
    private readonly mobileFaqFacade: MobileFaqFacade,
    private readonly layoutStateFacade: LayoutStateFacade,
    private readonly fastTrackFacade: FastTrackFacade
  ) {
    this.registerFacade.$isRegistrationWizardOpen.next(true);
    this.initDates();
  }

  onSendSms(number: string) {
    this.registerFacade.sendVerificationCode$('995:' + number).subscribe();
  }

  resultClosed() {
    // this.router.navigate(['/auth/verification']).then();
  }

  onRegister(userData: Register) {
    this.isFieldsAvailable(userData)
      .pipe(
        switchMap((isAvailable) =>
          iif(() => isAvailable, this.register$(userData), of(false))
        ),
        finalize(() => {
          nextTick(() => {
            this.registerFacade.$isLoadingMob.next({ status: false });
            this.$registrationEffect.next({});
          });
        })
      )
      .subscribe();
  }

  private isFieldsAvailable(userData: Register) {
    const mobileField = this.registerFacade.checkFieldUniqueness$(
      'mobile',
      userData.phoneNumber
    );
    const idField = this.registerFacade.checkFieldUniqueness$(
      'passport-number',
      userData.documentId?.toUpperCase?.()
    );

    return zip(mobileField, idField).pipe(
      map(([mobileField, idField]) => {
        this.$fieldsAvailable.next({
          mobileField: mobileField,
          idField: idField
        });
        return mobileField && idField;
      })
    );
  }

  register$(userData: Register) {
    return this.isDocumentGenuine(userData).pipe(
      switchMap((isGenuine) =>
        iif(
          () => isGenuine,
          this.registerFacade.register$(userData),
          of(false).pipe(
            tap(() => this.registerFacade.registrationStep.next('personalData'))
          )
        )
      ),
      switchMap((result: boolean | [boolean, unknown]) => {
        const [res, data] = Array.isArray(result) ? result : [result, null];
        if (res) {
          this.authFacade.signIn(this.registerFacade.getSignInPayload());
          // Google Analytics Event Tracking for Registration
          this.analyticsService.track({
            action: 'Register',
            event: 'registration_submitted',
            registrationSubmitted: 'success'
          });
          // Matomo Analytics Event Tracking for Registration
          this.matomoAnalyticsService.selectTrackingEvent(
            'registrationSubmittedSuccess'
          );
          this.registerFacade.registrationStep.next('finished');
          return this.fastTrackFacade.track('registration');
        } else {
          this.analyticsService.track({
            action: 'Register',
            event: 'registration_submitted',
            registrationSubmitted: 'fail'
          });
          this.matomoAnalyticsService.selectTrackingEvent(
            'registrationSubmittedFail'
          );
        }
        return of(null);
      })
    );
  }

  private initDates() {
    this.months$ = this.languageFacade.current$.pipe(
      map(() => this.dateService.getAvailableMonths())
    );

    const { minValidYear, validYear } = this.dateService.getVariables();
    this.years = this.dateService.getAvailableYears(
      validYear - 7,
      minValidYear
    );

    const chosenYear = parseInt(null, 10);
    const chosenMonth = parseInt(null, 10);

    this.days = this.dateService.getAvailableDays(chosenYear, chosenMonth);
  }

  private isDocumentGenuine(userData: Register) {
    if (userData.countryCode !== 'GE') {
      return of(true);
    }
    return of(true);
  }

  onChangeRegistrationStep(
    $event: 'personalData' | 'accountData' | 'finished'
  ) {
    this.registerFacade.registrationStep.next($event);
  }

  onMonthsChanged({ month, year }) {
    const temp = this.dateService.getAvailableDays(year, month);
    if (!(Number(year) % 4) && Number(month) === 2) {
      temp.push({
        id: 29,
        key: '29',
        value: '29'
      });
    }
    this.days = temp;
  }

  policyHandler(data: (UntypedFormGroup[] | number)[]): void {
    const form = data[0],
      scrollCache = data[1] as number;
    this.registerFacade.setAccountInfo(form[0]);
    this.registerFacade.setPersonalInfo(form[1]);
    this.registerFacade.setScrollCache(scrollCache);
    this.authFacade.setSessionStatus$(true);
    this.layoutStateFacade.setLoginPopupActiveTab(null);
    zip(
      this.languageFacade.current$.pipe(
        switchMap((lang: Language) =>
          this.faqFacade.getFaqCategories$(lang).pipe(
            map((arr) => {
              return arr?.shift();
            })
          )
        )
      ),
      timer(302)
    )
      .pipe(
        tap((arr) => {
          this.authFacade.setSessionStatus$(false);
          const item: FaqCategory = arr[0] as FaqCategory;
          this.faqFacade.setShowFaqDialogState(true);
          this.mobileFaqFacade.setFaqItem(item);
          this.layoutStateFacade.setUserBarVisibility(false);
        })
      )
      .subscribe();
  }

  public reInitYears(value: string): void {
    const { minValidYear, validYear } = this.dateService.getVariables();
    this.years = this.dateService.getAvailableYears(
      value === 'GE' ? validYear - 7 : validYear,
      minValidYear
    );
  }
  public ngOnDestroy(): void {
    this.registerFacade.$isRegistrationWizardOpen.next(false);
    this.registerFacade.$rateLimitErrorShowRemainingTime.next(0);
  }
}
