import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, share, Subject } from 'rxjs';
import { MissionItem, MissionUpdate } from '../entity/missions.interface';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { CrmService } from '../services/crm.service';
import { LanguageFacade, nextTick, StorageService } from '@crc/shared';
const KEY = 'crm_read_missions';
@Injectable({
  providedIn: 'root'
})
export class CrmMissionsFacade extends StorageService<string> {
  missions$: Observable<MissionItem[]>;
  missionsArray: MissionItem[] = [];
  private $showMissionDialogState: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly $amountOfActiveMissions = new BehaviorSubject<number>(0);
  private readonly $updateMissions = new BehaviorSubject<MissionUpdate>({
    page: 0,
    count: 10
  });
  language: string;
  private readonly $cashedMissions = new Subject<MissionItem[]>();
  public readonly readMissions: {
    ids: Set<string>;
  } = {
    ids: new Set()
  };
  constructor(
    private readonly crmService: CrmService,
    private readonly languageFacade: LanguageFacade
  ) {
    super();
    this.missions$ = this.languageFacade.current$.pipe(
      switchMap((language) => {
        if (this.language !== language) {
          this.missionsArray = [];
          this.updateMissions({
            page: 0,
            count: 20
          });
        }
        this.language = language;
        return this.$updateMissions.pipe(
          debounceTime(300),
          switchMap((updateMissions: MissionUpdate) =>
            this.crmService.getMissions(updateMissions).pipe(
              map((missions) => {
                return [
                  missions.items
                    .filter((mission) => mission.mission)
                    .map((mission) => {
                      const newObj: MissionItem = {
                        ...mission,
                        isRead: this.readMissions.ids.has(mission.mission._id)
                      };
                      return newObj;
                    }),
                  missions.currentPage
                ];
              }),
              map(([missions, currentPage]: [MissionItem[], number]) => {
                if (currentPage === 0) {
                  this.countActiveMissions(missions);
                }
                if (updateMissions.page === 0) {
                  this.missionsArray = [];
                }
                this.missionsArray = [...this.missionsArray, ...missions];
                this.$cashedMissions.next(this.missionsArray);
                return missions;
              })
            )
          )
        );
      }),
      share()
    );
    this.readReadMissionsFromCache();
  }

  updateMissions(data: MissionUpdate) {
    this.$updateMissions.next(data);
  }

  getCashedMissions$(): Observable<MissionItem[]> {
    return this.$cashedMissions.asObservable();
  }

  countActiveMissions(missions: MissionItem[]) {
    const activeMissions = missions.filter((x) => {
      return (
        Number(new Date(x.mission?.endDate)) > Number(new Date()) &&
        !(x.isRead || this.readMissions.ids.has(x.mission._id))
      );
    });
    this.$amountOfActiveMissions.next(activeMissions.length);
  }

  getAmountOfMissions$(): Observable<number> {
    return this.$amountOfActiveMissions.asObservable();
  }

  setShowMissionDialogState(state: boolean) {
    this.$showMissionDialogState.next(state);
  }

  getShowMissionDialogStateValue(): boolean {
    return this.$showMissionDialogState.value;
  }

  getShowMissionDialog$(): Observable<boolean> {
    return this.$showMissionDialogState.asObservable();
  }
  public cacheReadMissions(readMissions: MissionItem[]): void {
    return void 0;
    // for time being
    this.readMissions.ids = new Set([
      ...JSON.parse(this.get(KEY) ?? '[]'),
      ...readMissions.map((value) => value.mission._id)
    ]);
    nextTick(() => {
      this.countActiveMissions(readMissions);
      this.commitCache();
    });
  }
  private readReadMissionsFromCache(): void {
    this.readMissions.ids = new Set(JSON.parse(this.get(KEY) ?? '[]'));
  }
  public commitCache(): void {
    this.set(KEY, JSON.stringify(Array.from(this.readMissions.ids)));
  }
}
