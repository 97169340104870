import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  pairwise,
  startWith,
  switchMap,
  tap
} from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthFacade } from '../../../auth/facade/auth.facade';
import {
  PromotionsFacade,
  PromotionsKeys,
  SportFacade
} from '../../../features';
import { LoginPopupFacade } from '../../../auth/facade/';
@Injectable({
  providedIn: 'root'
})
@UntilDestroy()
export class ToolTipStateFacade {
  private readonly toolTipShowDuration = 3;
  private readonly $isGiftToolTipVisible = new BehaviorSubject<boolean>(true);
  constructor(
    private readonly loginPopupFacade: LoginPopupFacade,
    private readonly authFacade: AuthFacade,
    private readonly sportFacade: SportFacade,
    private readonly promotionFacade: PromotionsFacade
  ) {}
  updateGiftToolTipState() {
    combineLatest([
      this.loginPopupFacade?.getShowLoginPopupState$(),
      this.authFacade.isLoggedIn$(),
      this.sportFacade.getSportIsOpen$()
    ])
      .pipe(
        untilDestroyed(this),
        startWith(() => null),
        pairwise(),
        map(
          ([el1, el2]: [
            [boolean, boolean, boolean | null],
            [boolean, boolean, boolean]
          ]) => {
            if (!el1.length) {
              this.setGiftToolTipVisible(!el2[1]);
            }
            return el2;
          }
        ),
        tap(([loginPopUpState, _, sportState]: [boolean, boolean, boolean]) => {
          if (loginPopUpState) {
            this.setGiftToolTipVisible(false);
          } else if (sportState) {
            this.setGiftToolTipVisible(false);
          }
        })
      )
      .subscribe();
  }
  setLocalStorageGiftToolTipState(state: boolean) {
    localStorage.setItem('isGiftTooltipVisible', JSON.stringify(state));
  }
  setGiftToolTipVisible(state: boolean) {
    const expireDate = 10 * 1000;

    if (!localStorage.getItem('isGiftTooltipClosedDate')) {
      this.setNewDate(expireDate);
      this.$isGiftToolTipVisible.next(state);
    } else {
      const nextPopup = this.getLocalStorageToolTipState();
      if (Number(nextPopup) < Number(new Date())) {
        this.setNewDate(expireDate);
        this.$isGiftToolTipVisible.next(state);
      } else {
        this.$isGiftToolTipVisible.next(false);
      }
    }
  }
  setNewDate(expireDate: number) {
    localStorage.setItem('isGiftTooltipClosedDate', JSON.stringify(expireDate));
  }
  getLocalStorageToolTipState(): number {
    return +localStorage.getItem('isGiftTooltipClosedDate');
  }
  getGiftToolTipVisibleState$(): Observable<boolean> {
    return combineLatest([
      this.promotionFacade.isPromoActive(PromotionsKeys.giftTollTipKey),
      this.$isGiftToolTipVisible.asObservable()
    ]).pipe(
      switchMap(([isPromoActive, isGiftToolTipVisible]) =>
        isPromoActive && isGiftToolTipVisible ? of(true) : of(false)
      )
    );
  }
}
