<div
  *ngIf="!withoutBlur"
  class="fade"
  [ngClass]="{ 'fade--no-blur': isSetantaRoute }"
  [attr.data-cy]="dataCyPrefix && dataCyPrefix + '-fade'"
  [class.rotatedFade]="homeIconState | async"
  (click)="closeSelf()"
  #fadeRef
></div>

<div
  class="popup"
  [class.bonus-popup]="bonusSpace"
  [class.no-max-content]="isMaxContentActive && bonusSpace"
  [ngStyle]="{ zIndex: zIndex }"
  [class.rotated]="(homeIconState | async) && !isUfoOpen && !isPokerOpen"
  [class.gameOpen]="isGameOpen"
  [class.with-out-use-bar]="(isUserBarVisible$ | async) === false"
  *ngIf="!_closed"
  @slideInAndOut
  [attr.data-cy]="dataCyPrefix && dataCyPrefix + '-popup'"
>
  <ng-template
    [ngIf]="optionalHeaderEmbeddedView"
    [ngTemplateOutlet]="optionalHeaderEmbeddedView"
  ></ng-template>
  <div
    [attr.data-cy]="dataCyPrefix && dataCyPrefix + '-header'"
    *ngIf="!withoutHeader"
    crcSharedSwipeY
    [isMinHeightActive]="bonusSpace"
    [isSwipeForbidden]="this.isCloseDisabled"
    (minimize)="closeSelf()"
    class="popup__header"
    [class.bonus-popup__header]="bonusSpace"
  >
    <ng-template #conditionalBackButton>
      <div
        *ngIf="popupAction === 'back'"
        class="popup__close-icon popup__close-icon--type-back-button"
        [attr.data-cy]="dataCyPrefix && dataCyPrefix + '-back-button-wrapper'"
      >
        <crc-m-shared-icon-button
          [dataCy]="dataCyPrefix && dataCyPrefix + '-back-button'"
          (clicked)="changeStep.emit()"
          [rotate]="true"
          class="arrow-left"
          path="/assets/icons/features/crm/missions/arrow-right.svg"
          alt="close"
        >
        </crc-m-shared-icon-button>
      </div>
    </ng-template>
    <div
      class="popup__tabs"
      [attr.data-cy]="dataCyPrefix && dataCyPrefix + '-tabs-wrapper'"
    >
      <img
        [attr.data-cy]="dataCyPrefix && dataCyPrefix + '-title-prefix-icon'"
        *ngIf="optionalTitlePrefixIconSrc as src"
        class="popup__tabs-title__icon--prefix"
        [attr.src]="src"
        style="position: relative; top: -8px; left: -12px"
        alt=""
      />
      <ng-container
        *ngIf="
          bonusSpace && bonusSpaceAction === 'history';
          else conditionalBackButton
        "
      >
        <button
          [attr.data-cy]="dataCyPrefix && dataCyPrefix + '-bonus-back-btn'"
          (click)="bonusBack.emit()"
          class="bonus-back-button"
        >
          <img
            [attr.data-cy]="
              dataCyPrefix && dataCyPrefix + '-bonus-back-btn-icon'
            "
            src="/assets/icons/features/crm/missions/arrow-left.svg"
            alt="arrow"
          />
        </button>
      </ng-container>
      <div
        [attr.data-cy]="dataCyPrefix && dataCyPrefix + '-tab--' + tabTitle"
        *ngFor="let tabTitle of tabTitles"
        class="popup__tab"
        [class.mark]="activeTab === tabTitle && tabTitles?.length !== 1"
        [class.active]="activeTab === tabTitle || activeOnlyOneTab"
        (click)="activeTabChange.emit(tabTitle)"
      >
        <span
          [attr.data-cy]="
            dataCyPrefix && dataCyPrefix + '-tab--' + tabTitle + '-text'
          "
          >{{ tabTitle | translate }}</span
        >
      </div>
    </div>
    <ng-container [ngSwitch]="popupAction">
      <ng-container *ngSwitchCase="'back'">
        <ng-container
          *ngIf="!isCloseDisabled || activeTab !== 'sms_identification'"
        >
          <ng-container
            *ngTemplateOutlet="
              closeButton;
              context: {
                $implicit: true
              }
            "
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'close'">
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>
      </ng-container>
      <div *ngSwitchCase="'logout'" class="popup__close-icon">
        <crc-m-shared-icon-button
          [dataCy]="dataCyPrefix && dataCyPrefix + '-logout-btn'"
          (clicked)="logOut.emit()"
          path="/assets/icons/profile/logout.svg"
          alt="close"
        >
        </crc-m-shared-icon-button>
      </div>
      <ng-template #closeButton let-isSpecialCase>
        <div
          class="popup__close-icon"
          [attr.data-cy]="dataCyPrefix && dataCyPrefix + '-close-btn-wrapper'"
        >
          <ng-container *ngIf="!bonusSpace; else bonusClose">
            <crc-m-shared-icon-button
              [dataCy]="dataCyPrefix && dataCyPrefix + '-close-btn'"
              (clicked)="isSpecialCase ? closeSelf() : closeWithIcon()"
              path="/assets/icons/shapes/close_white.svg"
              alt="close"
            >
            </crc-m-shared-icon-button>
          </ng-container>
          <ng-template #bonusClose>
            <crc-m-shared-icon-button
              [dataCy]="dataCyPrefix && dataCyPrefix + '-close-btn'"
              (clicked)="closeSelf()"
              class="bonus"
              path="/assets/icons/shapes/bonus-close.svg"
              alt="close"
            >
            </crc-m-shared-icon-button>
          </ng-template>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <div class="popup__content" [attr.data-cy]="dataCyPrefix + '-popup-content'">
    <ng-content></ng-content>
  </div>
  <div
    class="popup__footer"
    *ngIf="buttonText"
    [attr.data-cy]="dataCyPrefix + '-popup-footer'"
  >
    <crc-m-shared-button
      [dataCy]="dataCyPrefix + '-popup-footer'"
      [type]="buttonHighlight ? 'active' : 'secondary'"
      (click)="buttonClicked.emit()"
      >{{ buttonText }}
    </crc-m-shared-button>
  </div>
</div>
<div *ngIf="!withoutBlur" crcSharedClassForward="noscroll" to="body"></div>
