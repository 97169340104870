<div class="activate">
  <img
    class="activate__img"
    [src]="
      'https://cms.crocobet.com/customers/profile/avatars/' + avatarUrl
    "
    alt="*"
  />
  <p class="activate__text">
    {{ 'hello' | translate }},<span class="activate__span">{{ userName }}</span>
  </p>
  <div class="top-margin-15">
    <crc-m-masked-input
      [invalidPasscode]="isInvalidPasscode$ | async"
      [passcode]="passcode$ | async"
      [numberOfItems]="4"
    ></crc-m-masked-input>
  </div>
  <div class="top-margin">
    <crc-m-keyboard
      (keyboardClick)="onKeyboardClick($event)"
      offKey="forgot"
    ></crc-m-keyboard>
  </div>
</div>
