import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  AnalyticsService,
  CookieService,
  EnvironmentService,
  isAnyCrocobetApi,
  isCardsApi,
  isCmsApi,
  isCrcApi,
  isCrcAuthApi,
  isIdentomatApi,
  LanguageFacade
} from '@crc/shared';

import { SessionService } from '../services';
import { AuthFacade } from '../facade/auth.facade';
import {
  IInvalidSessionResponseBody,
  invalidSessionDescriptions,
  InvalidSessionResponseOriginEnum
} from '../entity/constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly cookieService: CookieService,
    private readonly sessionService: SessionService,
    private readonly languageFacade: LanguageFacade,
    private readonly environmentService: EnvironmentService,
    private readonly analyticsService: AnalyticsService,
    private readonly authFacade: AuthFacade
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !isAnyCrocobetApi(req.url) &&
      !isCrcApi(req.url) &&
      !isCrcAuthApi(req.url) &&
      !isIdentomatApi(req.url) &&
      !isCmsApi(req.url) &&
      !isCardsApi(req.url)
    ) {
      return next.handle(req).pipe(
        catchError((error) => {
          if (error === undefined) {
            return of(undefined);
          }
        })
      );
    }

    const { authCookieName } = this.environmentService.config;
    const token = this.sessionService.getToken();
    const lang =
      this.languageFacade.current === 'tr' ? 'en' : this.languageFacade.current;

    const reqClone = req.clone({
      setHeaders: getHeaders(token, lang, authCookieName),
      withCredentials: !isCardsApi(req.url) && !!token
    });

    return next.handle(reqClone).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          if (
            response?.body &&
            (invalidSessionDescriptions.includes(response.body.description) ||
              bodyAsStringContainsError(response.body)) &&
            (!response?.url?.includes('session/customer-data/false') ||
              (response.body as IInvalidSessionResponseBody).origin ===
                InvalidSessionResponseOriginEnum.GATEWAY) &&
            !response?.url?.includes('/logout')
          ) {
            this.authFacade.signOut(false);
          } else {
            const returnedToken = response.headers.get(authCookieName) || '';
            // TODO do not update token if returned from another server node
            // if (!token || getTokenSuffix(value) === getTokenSuffix(token)) {
            this.sessionService.setToken(returnedToken);
          }
        }
      }),
      catchError((error) => throwError(error))
    );
  }
}

function bodyAsStringContainsError(body: any) {
  return (
    body?.length &&
    (body.includes(invalidSessionDescriptions[0]) ||
      body.includes(invalidSessionDescriptions[1]) ||
      body.includes(invalidSessionDescriptions[2]))
  );
}

function getTokenSuffix(token: string) {
  return token?.split('.')[1];
}

const getHeaders = (token: string | void, lang: string, tokenKey: string) => {
  const headers = {
    'Content-type': 'application/json',
    'Request-Language': lang
  };

  if (token) {
    headers[tokenKey] = token;
  }

  return headers;
};
