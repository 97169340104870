export enum PromoCodeError {
  INVALID = 'PROMO_CODE_INVALID',
  USED = 'PROMO_CODE_USED',
  EXPIRED = 'PROMO_CODE_EXPIRED',
  LIMIT_REACHED = 'PROMO_CODE_LIMIT_REACHED',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS'
}

export interface PromoCodePrize {
  type: string;
  name: string;
  betValue: number;
  launchUrlDesktop: string;
  launchUrlMobile: string;
}

export interface PromoCodeRedeemSuccessResponse {
  success: true;
  prize: PromoCodePrize;
}

export interface PromoCodeRedeemErrorResponse {
  success: false;
  errorCode: PromoCodeError;
  retryAfter?: number;
}

export type PromoCodeRedeemResponse =
  | PromoCodeRedeemSuccessResponse
  | PromoCodeRedeemErrorResponse;
