import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import {
  ProviderSlotGames,
  SlotGame,
  SlotsFacade,
  SlotsFavoriteFacade
} from '../../slots';
import { CasinoFacade, CasinoGame, ProviderCasinoGames } from '../../casino';
import { SlotCasinoGameType, SlotCasinoGameTypeEnum } from '../entity';
import { OpenGameParams } from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class SlotCasinoFacade {
  currGameParams: OpenGameParams;

  private readonly $iframeSrc = new Subject<string>();
  private readonly $gameType: BehaviorSubject<SlotCasinoGameType> =
    new BehaviorSubject<SlotCasinoGameType>(null);
  private readonly $game: BehaviorSubject<SlotGame | CasinoGame> =
    new BehaviorSubject<SlotGame | CasinoGame>(null);
  private readonly $selectedProvider: BehaviorSubject<
    ProviderSlotGames | ProviderCasinoGames
  > = new BehaviorSubject<ProviderSlotGames | ProviderCasinoGames>(null);
  private readonly $gameName: Subject<string> = new Subject<string>();

  constructor(
    readonly slotsFavoriteFacade: SlotsFavoriteFacade,
    readonly slotsFacade: SlotsFacade,
    readonly casinoFacade: CasinoFacade,
    readonly router: Router
  ) {}

  getIframeSrc$(): Observable<string> {
    return this.$iframeSrc.asObservable();
  }

  updateIframeSrc(value: string) {
    this.$iframeSrc.next(value);
  }

  getProviderIdValue(): string {
    return this.$selectedProvider.value.provider;
  }

  getGameType$(): Observable<SlotCasinoGameType> {
    return this.$gameType.asObservable();
  }

  getGameTypeValue(): SlotCasinoGameType {
    return this.$gameType.value;
  }

  updateGameType(value: SlotCasinoGameType) {
    return this.$gameType.next(value);
  }

  getGameId$(): Observable<string> {
    return this.$game.pipe(map((game) => game && game.game_id));
  }

  getGameIdValue(): string {
    return this.$game.value.game_id;
  }

  getGameValue(): SlotGame | CasinoGame {
    return this.$game.value;
  }

  getGameName(): Observable<string> {
    return this.$gameName as Observable<string>;
  }

  updateGameName(value: string) {
    this.$gameName.next(value);
  }

  updateGame(value: SlotGame | CasinoGame) {
    return this.$game.next(value);
  }

  getSelectedProviderValue(): ProviderSlotGames | ProviderCasinoGames {
    return this.$selectedProvider.value;
  }

  updateSelectedProvider(value: ProviderSlotGames | ProviderCasinoGames) {
    return this.$selectedProvider.next(value);
  }

  getIsSlotFavourite$(): Observable<boolean> {
    return this.getGameId$().pipe(
      switchMap((id) =>
        this.slotsFavoriteFacade.favorites$.pipe(
          map((favorites) => {
            return favorites.some((favorite) => favorite.game_id === id);
          })
        )
      )
    );
  }

  redirectToMain() {
    this.router
      .navigate(
        [
          this.getGameTypeValue() === SlotCasinoGameTypeEnum.SLOT
            ? 'slots'
            : 'casino'
        ],
        {
          queryParamsHandling: 'merge'
        }
      )
      .then();
  }
}
