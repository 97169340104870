<svg-icon
  [size]="size"
  [color]="color"
  [key]="name"
  [class.full-size]="fullSize"
  [ngStyle]="styleObject"
  [style]="maxWidthHeight.width ? { 'max-width': maxWidthHeight.width } : null"
  [style.max-height]="maxWidthHeight.height ? maxWidthHeight.height : null"
>
</svg-icon>
