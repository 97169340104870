import { ICountdown } from '../entity/live-raffle-interfaces';

// returns hours, minutes, seconds from milliseconds
export function toCountdownData(time: number): ICountdown {
  return {
    seconds: Math.floor((time / 1000) % 60),
    minutes: Math.floor((time / (1000 * 60)) % 60),
    hours: Math.floor((time / (1000 * 60 * 60)) % 24)
  };
}

// fills number with zeros to digit length and converts to string
export function fillWithZeros(value: number | string, digits: number) {
  return `${value}`.padStart(digits, '0');
}
