import { Cashier } from '../entity';
export function mapOldDtoToNew(res: {
  data: Cashier[];
  code?: number;
  description?: string;
}): Cashier[] {
  res.data.sort((a, b) => a.order - b.order);
  for (const city of res.data) {
    const places = [
      ...city.locations.map((val) => {
        return {
          ...val,
          city: val.city?.['name']
        };
      })
    ];
    delete city.locations;
    city.places = places;
  }
  return res.data;
}
