import { createFeatureSelector, createSelector } from '@ngrx/store';
import { slotsFeatureKey, SlotsState } from '../reducers/slots.reducer';

export const selectSlotsState =
  createFeatureSelector<SlotsState>(slotsFeatureKey);

export const selectBuyBonusActive = createSelector(
  selectSlotsState,
  (state: SlotsState) => state?.isBuyBonusActive
);

export const selectBuyBonusData = createSelector(
  selectSlotsState,
  (state: SlotsState) => state.buyBonusData
);
