import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CaptchaPromptBaseComponent } from '../captcha-prompt-base.component';

@UntilDestroy()
@Component({
  selector: 'crc-shared-captcha-prompt',
  templateUrl: './captcha-prompt.component.html',
  styleUrls: ['./captcha-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaptchaPromptComponent extends CaptchaPromptBaseComponent {}
