import { PragmaticDigitalOdometerConfig } from './odometer.interface';

export const PragmaticDigitalOdometerConfigs: PragmaticDigitalOdometerConfig[] =
  [
    {
      index: 3,
      icon: 'A.webp',
      iconSmall: 'A-small.webp',
      listenToAnimation: true,
      level: 'LevelIV'
    },
    {
      index: 2,
      icon: 'K.webp',
      iconSmall: 'K-small.webp',
      listenToAnimation: false,
      level: 'LevelIII'
    },
    {
      index: 1,
      icon: 'Q.webp',
      iconSmall: 'Q-small.webp',
      listenToAnimation: false,
      level: 'LevelII'
    },
    {
      index: 0,
      icon: 'J.webp',
      iconSmall: 'J-small.webp',
      listenToAnimation: false,
      level: 'LevelI'
    }
  ];
