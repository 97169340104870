import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getRouletteStats } from './roulette.actions';
import { timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class RouletteFacade {
  store: Store = inject(Store);

  init() {
    timer(0, 30000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(getRouletteStats());
      });
  }
}
