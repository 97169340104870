import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ENV_CONFIG, getWindow, SentryStaticService } from '@crc/shared';

if (environment.production) {
  enableProdMode();
}
fetch('assets/config/environment.json')
  .then((response) => {
    return response.json();
  })
  .then((data) => {
    getWindow().localStorage.setItem('env', JSON.stringify(data));
    SentryStaticService.initSentry();
    platformBrowserDynamic([
      {
        provide: ENV_CONFIG,
        useValue: data
      }
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
