import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FetchBannerService {
  constructor(private readonly sanitizer: DomSanitizer) {}

  getCustomBannerSvg(url: string, loginName: string): Observable<SafeHtml> {
    return this.fetchCustomBanner(url).pipe(
      map((svg) => {
        const modifiedSvg = this.modifySvgText(svg, loginName);
        return this.sanitizer.bypassSecurityTrustHtml(modifiedSvg);
      }),
      shareReplay()
    );
  }

  modifySvgText(svg: string, newText: string): string {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svg, 'image/svg+xml');

    const textElement = xmlDoc.getElementById('nametag');
    if (textElement) {
      const tspanElement = textElement.getElementsByTagName('tspan')[0];

      if (tspanElement) {
        if (newText.length <= 7) {
          tspanElement.setAttribute('font-size', '22px');
        }
        if (newText.length >= 8) {
          tspanElement.setAttribute('font-size', '18px');
        }

        if (newText.length >= 9) {
          tspanElement.setAttribute('font-size', '16px');
          newText = newText.substring(0, 9) + '...';
        }
        tspanElement.textContent = newText;
        tspanElement.setAttribute(
          'font-family',
          '"NeueCrocoSansMedium", sans-serif'
        );
        tspanElement.setAttribute('dominant-baseline', 'middle');
        tspanElement.setAttribute('text-anchor', 'middle');
        tspanElement.setAttribute('x', '50');
      }
    }

    const serializer = new XMLSerializer();
    return serializer.serializeToString(xmlDoc);
  }

  fetchCustomBanner(url: string): Observable<string> {
    return new Observable<string>((observer) => {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then((data) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    }).pipe(shareReplay());
  }
}
