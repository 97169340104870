import { Injectable } from '@angular/core';
import { BaseUrl, HttpService } from '@crc/shared';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  constructor(private readonly apiService: HttpService) {}

  getTransactions(
    fromDate: string,
    toDate: string,
    amount: number,
    offSet: number
  ) {
    const path = `/rest/transaction/transfers/${fromDate}/${toDate}/${amount}/${offSet}`;
    const baseUrl: BaseUrl = 'apiUrl';
    return this.apiService.get$<any>({ path, baseUrl });
  }
}
