import { Banner, BannerView } from '../entity';
import { createImageSlide } from '@crc/components';
import { addQueryParamToUrl, dayCountDueTo, timeLeftDueTo } from '@crc/shared';

export interface PromotionsMenu {
  name: string;
  active?: boolean;
  filter?: string;
}

export const generatePromotionCategories = (
  name: string,
  active?: boolean,
  filter?: string
): PromotionsMenu => ({
  name,
  active,
  filter
});

export function mapBannersToBannersView(
  banners: Banner[],
  customerId: number,
  token: string,
  platform: string
): BannerView[] {
  return banners?.map((banner) => {
    return {
      ...banner,
      ...createImageSlide(
        banner.image2 || banner.image,
        customerId
          ? addQueryParamToUrl(banner.href, [
              { key: 'id', value: customerId?.toString() },
              {
                key: 'tk',
                value: btoa(<string>token)
              }
            ])
          : banner.href,
        banner.title,
        platform === 'mobile' ? '_self' : '_blank',
        banner?.imageSet
      ),
      title: banner.title,
      desc: banner.desc,
      category: banner.category,
      tags: banner.tags,
      showCountDown: banner.activeCountdown && banner.active?.end,
      countdownDayCount: dayCountDueTo(banner.active?.end),
      countdownTime: timeLeftDueTo(banner.active?.end)
    } as BannerView;
  });
}
