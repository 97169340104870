import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[crcSharedCypress]'
})
export class CypressDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    const cy = this.el?.nativeElement?.classList?.[0]
      ?.replace(/_+/g, '-')
      .replace(/-+/g, '-');
    if (cy) {
      this.renderer.setAttribute(this.el.nativeElement, 'data-cy', cy);
    }
  }
}
