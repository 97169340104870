import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceManColorInterpreter',
  standalone: true,
  pure: true
})
export class SpaceManColorInterpreterPipe implements PipeTransform {
  transform(value: number): string {
    return value <= 1
      ? '#5d6a71'
      : value <= 2
      ? '#036aa6'
      : value <= 6
      ? '#2f038e'
      : value <= 30
      ? '#550092'
      : value <= 60
      ? '#7a00a4'
      : '#8a0262';
  }
}
