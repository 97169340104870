import { Injectable } from '@angular/core';
import { Observable, Subject, take, tap } from 'rxjs';
import { TransactionsService } from '../services/transactions.service';
import { Transaction } from '../entity/transactions.interface';
import { addDays, format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class TransactionsFacade {
  private subject = new Subject<Transaction[]>();
  $loading = new Subject<boolean>();

  constructor(private readonly transactionsService: TransactionsService) {}

  requestTransaction(
    dateFrom: string,
    dateTo: string,
    amount: number,
    offSet: number
  ) {
    dateTo = format(addDays(new Date(dateTo), 1), 'yyyy-MM-dd');
    this.transactionsService
      .getTransactions(dateFrom, dateTo, amount, offSet)
      .pipe(
        tap((response) => {
          this.subject.next(response.data);
        })
      )
      .subscribe();
  }

  getTransactions() {
    return this.getTransactions$().pipe(
      take(1),
      tap((_) => {
        this.$loading.next(false);
      })
    );
  }

  getTransactions$(): Observable<Transaction[]> {
    return this.subject.asObservable();
  }
}
