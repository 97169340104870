export const UserRestrictionsInterface = {
  UPDATE_PHONE_NUMBER: 'UPDATE_PHONE_NUMBER',
  UPDATE_PASSWORD_REQUIRED: 'UPDATE_PASSWORD_REQUIRED',
  PROMPT_ABOUT_SMS_OFF: 'SMSOFF',
  PROMPT_ABOUT_SMS_ON: 'SMSON',
  PROMPT_ABOUT_SMS_ON_V2: 'SMSON_V2'
} as const;
export type UserRestrictionsType =
  (typeof UserRestrictionsInterface)[keyof typeof UserRestrictionsInterface];

export interface UserRestrictionsDto {
  data: UserRestrictions;
}
export interface UserRestrictions {
  agreementCheckbox?: boolean;
  agreementFrom: string;
  agreementTime: string;
  agreementType: string;
  phoneNumber: string;
  status: string;
}
