export function filterQueryParamsInURL(
  url: string,
  params: { value: string; key: string }[]
) {
  params.forEach((param) => {
    if (param.value && param.key) {
      url = `${url}${url.includes('?') ? '&' : '?'}${param.key}=${param.value}`;
    }
  });
  return url;
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date: Date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate())
  ].join('-');
}
export function isNonNullOrUndefined<T>(value: T): value is NonNullable<T> {
  return (value ?? null) !== null;
}
