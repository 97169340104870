import { Injectable } from '@angular/core';
import { BaseUrl, HttpService } from '@crc/shared';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ICMSAnalyticsDTO } from '../entities/interfaces';

@Injectable({
  providedIn: 'root'
})
export class CmsAnalyticsService {
  constructor(private readonly httpService: HttpService) {}

  public trackPaymentAttempt$(dto: ICMSAnalyticsDTO) {
    const path = `/analytics/payment-attempt`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.httpService
      .post$<unknown, ICMSAnalyticsDTO>({
        path,
        baseUrl,
        body: dto
      })
      .pipe(
        map(() => ({ success: true })),
        catchError(() => of({ success: false }))
      );
  }
}
