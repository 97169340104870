import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NgSelectItem } from '@crc/components';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'crc-m-select',
  templateUrl: './m-select.component.html',
  styleUrls: ['./m-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MSelectComponent {
  @Input() items: NgSelectItem[];
  @Input() selected: any;
  @Input() control: UntypedFormControl | AbstractControl;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() bindLabel = 'value';
  @Input() bindValue = 'key';
  @Input() hideFirst: boolean;
  @Input() forceError = false;
  @Input() languageSelect: boolean;
  @Input() icon: string;
  @Input() visibleSearch: boolean;
  @Output() selectedChange = new EventEmitter();

  changeSelected($event: any) {
    this.selectedChange.emit($event);
  }
  protected trackItems(_: number, item: NgSelectItem) {
    return item?.id;
  }
}
