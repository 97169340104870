import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  delayWhen,
  from,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  timer
} from 'rxjs';
import { ChangeEmailService } from '../services/change-email.service';
import { handleSettingRequiredError } from '@crc/shared';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  ICustomerVerifyEmailSmsResInterface,
  verifyEmailSteps
} from '../interfaces/request-resp.interface';

@Injectable({
  providedIn: 'root'
})
export class VerifyEmailFacade {
  private readonly $verifyEmailSteps: BehaviorSubject<verifyEmailSteps> =
    new BehaviorSubject('form');
  private readonly $timerResetState: Subject<void> = new Subject();

  private readonly $isLoading: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  constructor(private readonly ChangeEmailService: ChangeEmailService) {}

  public sendSMSToEmail$(): Observable<ICustomerVerifyEmailSmsResInterface | null> {
    return this.ChangeEmailService.sendSMSToEmail$();
  }

  public submitForm$(formValue: { smsCode: string }) {
    const { smsCode } = formValue;
    return this.ChangeEmailService.verifyEmail$(smsCode);
  }

  public generateSmsCodeForm() {
    return new UntypedFormGroup({
      smsCode: new UntypedFormControl(null, {
        validators: [Validators.required],
        updateOn: 'change'
      })
    });
  }

  public triggerTimerReset(): void {
    this.$timerResetState.next();
  }
  public getTimerResetActions$(): Observable<boolean> {
    return this.$timerResetState.asObservable().pipe(
      startWith(null),
      switchMap(() => from([true, false])),
      delayWhen((res) => (res ? of(null) : timer(0)))
    );
  }

  public handleSmsErrorRes(
    res: ICustomerVerifyEmailSmsResInterface | null,
    cnForm: UntypedFormGroup
  ): void {
    const smsCodeControl = cnForm.get('smsCode');
    if (res?.error) {
      setTimeout(() => {
        this.triggerTimerReset();
      }, 300);
      const error = res?.error === 'TOO_MANY_REQUESTS' ? res?.error : res?.payload?.errorCode;
      const generatedError = {
        manualError: `cn.${error?.toLowerCase() ?? 'default_error'}`
      };
      cnForm.setErrors({
        ...cnForm.errors,
        ...generatedError
      });
      if (error === 'TOO_MANY_REQUESTS') {
        smsCodeControl.setErrors({
          ...smsCodeControl.errors,
          ...generatedError
        });
        smsCodeControl.markAsTouched();
        smsCodeControl.markAsDirty();
        handleSettingRequiredError(smsCodeControl);
      }
    }
  }

  public handleSubmitErrorResponse(
    res: ICustomerVerifyEmailSmsResInterface | null,
    smsCodeForm: UntypedFormGroup
  ): void {
    const smsCodeControl = smsCodeForm.get('smsCode');
    const error = res?.payload?.errorCode;
    const generatedError = {
      manualError: `ce.${error?.toLowerCase() ?? 'default_error'}`
    };

    if (!res?.error) return;

    switch (error) {
      case 'INVALID_VERIFICATION_CODE':
        smsCodeControl.setErrors({
          ...smsCodeControl.errors,
          ...generatedError
        });
        break;

      case 'EMAIL_CODE_VERIFICATION_FAILED':
        this.setVerifyEmailStep('error');
        break;

      case 'CUSTOMER_RESTRICTED':
        return;

      default:
        this.setVerifyEmailStep('error');
    }

    smsCodeForm.setErrors({
      ...smsCodeForm.errors,
      ...generatedError
    });
  }

  public setVerifyEmailStep(step: verifyEmailSteps): void {
    this.$verifyEmailSteps.next(step);
  }
  public selectVerifyEmailStep$(): Observable<verifyEmailSteps> {
    return this.$verifyEmailSteps.asObservable();
  }

  public getLoadingState$(): Observable<boolean> {
    return this.$isLoading.asObservable();
  }
  public setLoadingState(state: boolean): void {
    this.$isLoading.next(state);
  }
}
