import { generateDepositOtherPayments } from '../functions/functions';
import { OtherPayment } from '../entity/deposit-other-payments.interface';

export const DEPOSIT_OTHER_PAYMENTS: OtherPayment[] = [
  generateDepositOtherPayments(
    'TBCPAY.GE',
    '/assets/icons/features/profile/cards/tbc-pay.svg',
    'https://tbcpay.ge/services/totalizatorebi-kazino-lataria/crocobet/{{pinCode}}'
  ),
  generateDepositOtherPayments(
    'BOGPAY.GE',
    '/assets/icons/features/profile/cards/bog-type_2.svg',
    'https://bogpay.ge/ka/payments/azartuli-Tamashebi/krokobeTi?customerId={{pinCode}}'
  ),
  /*  generateDepositOtherPayments(
    'EPAY.GE',
    '/assets/icons/features/profile/cards/e-pay.webp',
    'https://www.epay.ge/'
  ),*/
  generateDepositOtherPayments(
    'PAY.GE',
    '/assets/icons/features/profile/cards/pay.webp',
    'https://pay.ge/providers/1695/balance'
  ),
  generateDepositOtherPayments(
    'EMONEY',
    '/assets/icons/features/profile/cards/e-money.webp',
    'https://www.emoney.ge/index.php/main/service?id=1596'
  ),
  generateDepositOtherPayments(
    'cash_desks',
    '/assets/icons/features/profile/cards/pin.svg',
    '/profile/cashiers'
  )
];

export const WITHDRAW_OTHER_PAYMENTS: OtherPayment[] = [
  generateDepositOtherPayments(
    'secret_card',
    '/assets/icons/features/profile/cards/croco-card.webp',
    '/profile/secret-card'
  ),
  /*
  generateDepositOtherPayments(
    'MONEY MOVERS',
    '/assets/icons/features/profile/cards/mm.webp',
    'https://mm.ge/servicecenters.php'
  ),
   */
  generateDepositOtherPayments(
    'cash_desks',
    '/assets/icons/features/profile/cards/pin.svg',
    '/profile/cashiers'
  )
];
