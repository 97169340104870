import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Observable,
  Subject,
  tap
} from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvironmentService } from '@crc/shared';
import { SlotGame } from '../entity';
import { AccountFacade, AuthFacade } from '../../../auth/facade';
import { SlotsService } from '../services/slots.service';

const totalToSave = 50;

@Injectable({ providedIn: 'root' })
export class SlotsHistoryFacade {
  private readonly $historyTrigger = new Subject<void>();
  readonly historyTrigger$ = this.$historyTrigger.asObservable();

  private readonly $history = new BehaviorSubject<SlotGame[]>([]);
  readonly history$ = this.$history.asObservable().pipe(distinctUntilChanged());

  readonly totalCount$ = this.$history.pipe(map((slots) => slots.length));

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly slotsService: SlotsService,
    private readonly accountFacade: AccountFacade,
    private readonly authFacade: AuthFacade
  ) {}

  private addSlotToState(slot: SlotGame) {
    this.$history.next([slot, ...this.$history.value] as SlotGame[]);
  }

  private removeSlotFromState(slot: SlotGame) {
    this.$history.next([
      ...this.$history.value?.filter((s) => s.game_id !== slot.game_id)
    ] as SlotGame[]);
  }

  private removeLastFromStateIfFull() {
    const slots = this.$history.value;
    if (slots.length > totalToSave) {
      slots.pop();
      this.$history.next(slots);
    }
  }

  addSlotToHistory(slot: SlotGame) {
    if (this.authFacade.getIsLoggedIn()) {
      this.slotsService
        .addToHistory(slot.provider, slot.game_id)
        .pipe(
          tap((_) => {
            this.removeSlotFromState(slot);
            this.addSlotToState(slot);
            this.removeLastFromStateIfFull();
            this.$historyTrigger.next();
          })
        )
        .subscribe();
    }
  }

  updateHistory(slots: SlotGame[]) {
    this.$history.next(slots);
  }
}
