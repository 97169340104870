import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  BaseUrl,
  ChangePasswordResponse,
  ENV_CONFIG,
  EnvironmentConfig,
  HttpService
} from '@crc/shared';

import { ChangePasswordModel, ChangePasswordResponseModel } from '../entity';
import { ApiResponse } from '../../../../shared/types';
import { customerNumberSmsResInterface } from '../../change-number/interfaces';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ChangePasswordService {
  constructor(
    private readonly apiService: HttpService,
    private readonly httpClient: HttpClient,
    @Inject(ENV_CONFIG) private readonly env: EnvironmentConfig
  ) {}

  public changePasswordNew(
    body: ChangePasswordModel
  ): Observable<ChangePasswordResponseModel> {
    const path = `/customers/account/password`;
    const baseUrl: BaseUrl = 'cmsApiUrl';

    return this.apiService
      .put$<ApiResponse, ChangePasswordModel>({ baseUrl, path, body })
      .pipe(
        map(({ code, data }) => {
          if (data?.success) {
            return {
              status: ChangePasswordResponse.SUCCESS
            };
          }
          return {
            status: ChangePasswordResponse.FAIL,
            payload: data?.payload
          };
        }),
        catchError((err) => {
          return of({
            status: ChangePasswordResponse.FAIL,
            payload: err?.error?.payload
          });
        })
      );
  }
  public sendSmsToUser$() {
    const baseUrl = this.env['cmsApiUrl'];
    const path = '/customers/account/phone-number/send-verification-code';
    return this.httpClient.post(`${baseUrl}${path}`, {}).pipe(
      map((res: customerNumberSmsResInterface) => {
        return {
          status: (res?.data?.success && 'SUCCESS') || res?.error || null
        };
      }),
      catchError((val) => {
        return of({
          status: val?.error || null
        });
      })
    );
  }
}
