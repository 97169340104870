import { generateProfileMenuItems } from '../functions/generate-profile-side-menu-items';
import { ProfileSideMenuItem } from '../entity/profile-side-menu-item';

export const PROFILE_SIDE_MENU_ITEMS: ProfileSideMenuItem[] = [
  generateProfileMenuItems(
    'deposit',
    'user.deposit',
    'assets/icons/features/profile/plus.svg'
  ),
  generateProfileMenuItems(
    'withdraw',
    'user.withdraw',
    'assets/icons/features/profile/minus.svg'
  ),
  generateProfileMenuItems(
    'transactions-history',
    'transaction_history',
    'assets/icons/features/profile/transactions.svg'
  ),
  generateProfileMenuItems(
    'promocode',
    'user.promo_code',
    'assets/icons/features/profile/promocode.svg'
  ),
  generateProfileMenuItems(
    'personal-info',
    'user.profile',
    'assets/icons/features/profile/profile.svg',
    true
  ),
  generateProfileMenuItems(
    '/profile/verification',
    'verification',
    'assets/icons/features/profile/verification.svg',
    true
  ),
  generateProfileMenuItems(
    '/profile/secret-card',
    'secret_card',
    'assets/icons/features/profile/secretcard.svg'
  ),
  generateProfileMenuItems(
    '/profile/cashiers',
    'cash_desks',
    'assets/icons/features/profile/cashdesks.svg',
    true
  )
];
