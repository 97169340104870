import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of, switchMap, timer } from 'rxjs';
import { take } from 'rxjs/operators';

import { AuthFacade, CaptchaFacade, LoginPopupFacade } from '../facade';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private readonly authFacade: AuthFacade,
    private readonly loginGuardFacade: LoginPopupFacade,
    private readonly captchaFacade: CaptchaFacade
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkAuth();
  }

  private checkAuth(): Observable<boolean> {
    return timer(75).pipe(
      switchMap((_) =>
        this.authFacade.isLoggedIn$().pipe(
          take(1),
          switchMap((isLoggedIn) => {
            if (!isLoggedIn) {
              this.captchaFacade.closeCaptchaModal();
              this.loginGuardFacade.openLoginPopup();
              return this.loginGuardFacade.getLoginResult$();
            } else {
              return of(isLoggedIn);
            }
          })
        )
      )
    );
  }
}
