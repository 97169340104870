import { inject, Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { filter, of, shareReplay } from 'rxjs';
import { MiniFacade, MiniGames } from '../';
import { PragmaticCasinoIntegrationService } from '../../';

@Injectable({
  providedIn: 'root'
})
export class PragmaticMiniGamesIntegrationsFacade {
  private readonly pragmaticCasinoIntegrationService: PragmaticCasinoIntegrationService =
    inject(PragmaticCasinoIntegrationService);
  private readonly miniFacade: MiniFacade = inject(MiniFacade);
  public getPragmaticPlayFastGamesMap$() {
    return this.miniFacade.getAllMiniGames().pipe(
      filter((fastGameList) => !!fastGameList),
      map(({ games: fastGameList }) =>
        fastGameList
          .filter((fastGame) => {
            const lowerCaseProvider = fastGame.provider.toLowerCase();
            return ['pragmatic', 'casino'].every((str) =>
              lowerCaseProvider.includes(str)
            );
          })
          .map((fastGame) => String(fastGame.remoteId))
      ),
      switchMap((remoteIdList: MiniGames['remoteId'][]) => {
        return this.pragmaticCasinoIntegrationService.getPragmaticCasinoFeedByGameIds$(
          remoteIdList
        ).dynamicDataMap$;
      }),
      filter((map) => {
        return !!map.size;
      }),
      shareReplay(),
      catchError(() => of(null))
    );
  }
}
