import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatePlay, PatePlayOdometerConfigI, patePlayOdometerCount } from '@crc/components';

@Component({
  selector: 'crc-shared-pate-play-odometer-container',
  templateUrl: './pate-play-odometer-container.component.html'
})
export class PatePlayOdometerContainerComponent {
  @Input() disableLogo: boolean;
  @Input() vertical: boolean;
  @Input() largeIcon: boolean;
  @Input() odometerConfig: PatePlayOdometerConfigI[];
  @Input() odometers;
  @Input() jackpot: PatePlay[];
  @Input() odometerData;
  @Input() isMainPage: boolean;

  @Output() refreshOdometer = new EventEmitter<unknown>();
  protected readonly patePlayOdometerCount = patePlayOdometerCount;
}
