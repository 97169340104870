import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { from, Observable } from 'rxjs';
import { LandingsNavConfigFacade } from '@crc/shared';
import { Router } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { FetchBannerService } from './fetch-banner';
import { ImageSlideUnion } from '../swiper';

@Component({
  selector: 'crc-custom-player',
  templateUrl: './custom-player.component.html',
  styleUrls: ['./custom-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPlayerComponent implements OnChanges {
  svgContent: Observable<SafeHtml>;
  @Input() image: ImageSlideUnion;
  @Input() loginName: string;
  @Output() clicked = new EventEmitter<unknown>();

  constructor(
    private readonly router: Router,
    private readonly service: FetchBannerService,
    private readonly landingsNavConfigFacade: LandingsNavConfigFacade
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.image && this.image) {
      this.loadSvgContent();
    }
  }
  private loadSvgContent(): void {
    this.svgContent = this.service.getCustomBannerSvg(
      this.image?.imageSet?.svg,
      this.loginName
    );
  }
  onClick() {
    if (this.image.routerLink) {
      from(
        this.router.navigate([this.image.routerLink], {
          queryParams: this.image.routerQueryParams
        })
      ).subscribe();
    } else if (this.image.link) {
      this.landingsNavConfigFacade.redirectFromBanner(
        this.image.link,
        this.image.target
      );
    }
    this.clicked.emit(this.image.callBack);
  }
}
