/* tslint:disable */

import { Injectable } from '@angular/core';
import { LanguageFacade } from '../../translate/services/language.facade';
import { subStructDate } from '../index';
import { from, mergeMap, Observable, switchMap, take, toArray } from 'rxjs';
import { map } from 'rxjs/operators';
interface NgSelectItem {
  id: string;
  key: string;
  value: string;
}

const months: Array<{ key: number; value: string }> = [
  { key: 0, value: 'January' },
  { key: 1, value: 'February' },
  { key: 2, value: 'March' },
  { key: 3, value: 'April' },
  { key: 4, value: 'May' },
  { key: 5, value: 'June' },
  { key: 6, value: 'July' },
  { key: 7, value: 'August' },
  { key: 8, value: 'September' },
  { key: 9, value: 'October' },
  { key: 10, value: 'November' },
  { key: 11, value: 'December' }
];

const MonthName = {
  fullMonthNames: {
    1: 'dates.january',
    2: 'dates.february',
    3: 'dates.march',
    4: 'dates.april',
    5: 'dates.may',
    6: 'dates.june',
    7: 'dates.july',
    8: 'dates.august',
    9: 'dates.september',
    10: 'dates.october',
    11: 'dates.november',
    12: 'dates.december'
  },
  shortMonthNames: [
    'calendar_short_month_Jan',
    'calendar_short_month_Feb',
    'calendar_short_month_Mar',
    'calendar_short_month_Apr',
    'calendar_short_month_May',
    'calendar_short_month_Jun',
    'calendar_short_month_Jul',
    'calendar_short_month_Aug',
    'calendar_short_month_Sep',
    'calendar_short_month_Oct',
    'calendar_short_month_Nov',
    'calendar_short_month_Dec'
  ]
};

@Injectable({
  providedIn: 'root'
})
export class DateService {
  private yesterday = subStructDate(1, 'DAYS');
  private days = this.yesterday.getDate();
  private months = this.yesterday.getMonth() + 1;
  private currYear = this.yesterday.getFullYear();
  private maxValidYear = this.currYear - 18;
  private minValidYear = this.maxValidYear - 100;
  private maxDaysPerMonth = 31;
  private validYear = this.currYear - 18;
  private maxMonthsPerYear = 12;

  monthNames = MonthName.fullMonthNames;

  constructor(private readonly languageFacade: LanguageFacade) {}

  public monthToIndex(month: { key: number; value: string }): number {
    return months.findIndex((m) => m.key === month.key);
  }

  public getDaysInMonth(
    month: { key: number; value: string },
    year: { key: number; value: number }
  ): number {
    return new Date(year.key, this.monthToIndex(month) + 1, 0).getDate();
  }

  public getVariables() {
    return {
      yesterday: this.yesterday,
      days: this.days,
      months: this.months,
      currYear: this.currYear,
      maxValidYear: this.maxValidYear,
      minValidYear: this.minValidYear,
      maxDaysPerMonth: this.maxDaysPerMonth,
      validYear: this.validYear,
      maxMonthsPerYear: this.maxMonthsPerYear
    };
  }

  public getAvailableMonths(): any[] {
    const changedMonths = [];
    for (let i = 1; i <= 12; i++) {
      changedMonths.push({
        id: '' + i,
        key: '' + i,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value: this.languageFacade.translateInstant(this.monthNames[i])
      });
    }
    return changedMonths;
  }
  public getAvailableMonths$(): Observable<NgSelectItem[]> {
    const changedMonths: NgSelectItem[] = new Array<NgSelectItem>(12);
    changedMonths.fill(null);
    Object.assign(
      changedMonths,
      changedMonths.map((element, index) => {
        return {
          id: '' + (index + 1),
          key: '' + (index + 1),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value: this.monthNames?.[`${index + 1}`]
        };
      })
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.languageFacade.current$.pipe(
      switchMap(() =>
        from(changedMonths).pipe(
          mergeMap((month) => {
            return this.languageFacade.translateStream(month.value).pipe(
              take(1),
              map((name: string) => {
                return {
                  ...month,
                  value: name
                };
              })
            );
          }),
          toArray()
        )
      )
    );
  }

  getAvailableDays(year: number, month: number, days?: number): any[] {
    const changedDays = [];

    if (!year && !month && !days) {
      for (let i = 1; i <= 31; i++) {
        changedDays.push({ id: i, key: '' + i, value: '' + i });
      }
      return changedDays;
    }

    if (!days) {
      month = month - 1;
      days = this.getDaysInMonth(
        { key: month, value: month.toString() },
        { key: month, value: month }
      );
    }

    for (let i = 1; i <= days; i++) {
      changedDays.push({ id: i, key: '' + i, value: '' + i });
    }
    return changedDays;
  }

  public getAvailableYears(validYear: number, minValidYear: number): any[] {
    const changedYears = [];
    for (let i = validYear; i > minValidYear; i--) {
      changedYears.push({ id: i, key: '' + i, value: '' + i });
    }
    return changedYears;
  }
}
