import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AsyncSubject,
  map,
  Observable,
  switchMap,
  takeWhile,
  tap,
  timer
} from 'rxjs';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { SharedModule } from '../shared.module';
import { NgSyncDirective } from '@crc/shared';

@Component({
  selector: 'crc-m-otp-rate-limit-alert',
  templateUrl: './otp-rate-limit-alert.component.html',
  styleUrls: ['./otp-rate-limit-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, NgIf, AsyncPipe, SharedModule, NgSyncDirective],
  exportAs: 'innerData'
})
export class OtpRateLimitAlertComponent implements OnInit {
  @Input() public secondsLeft!: number;
  @Output() public readonly timeUp = new EventEmitter<void>();
  private readonly $init = new AsyncSubject<void>();
  protected readonly remainingSecondsFallBackValue = 60 as const;
  public readonly countDown$ = this.getCountDownLogic$();
  public ngOnInit(): void {
    this.$init.next();
    this.$init.complete();
  }
  private getCountDownLogic$(): Observable<number> {
    return this.$init.asObservable().pipe(
      map(() => this.secondsLeft),
      switchMap((initialSeconds: number) => {
        return timer(0, 1000).pipe(
          map((elapsedSeconds) => initialSeconds - elapsedSeconds),
          takeWhile((secondsRemaining) => secondsRemaining >= 0),
          tap((secondsLeft: number) => {
            if (secondsLeft === 0) {
              this.timeUp.emit();
            }
          })
        );
      })
    );
  }
}
