import { Injectable, NgZone } from '@angular/core';
import {
  FBPixelCommands,
  GoogleAnalyticArgs,
  GoogleAnalyticsCommands,
  GtagCustomEvent
} from './analytics.type';
import { getWindow } from '../../functions';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private ngZone: NgZone) {}

  private static ga(
    command: GoogleAnalyticsCommands,
    ...args: GoogleAnalyticArgs[]
  ) {
    const windowRef = getWindow() as { [key: string]: any };
    if (windowRef['ga']) {
      windowRef['ga'](command, ...args);
    }
  }

  private static gtag(command: GtagCustomEvent) {
    const windowRef = getWindow() as { [key: string]: any };
    if (windowRef['dataLayer']) {
      windowRef['dataLayer'].push(command);
    }
  }

  private fbq(command: FBPixelCommands, eventName: string, params?: any) {
    const windowRef = getWindow() as { [key: string]: any };
    if (windowRef['fbq']) {
      windowRef['fbq'](command, eventName, params);
    }
  }

  public setUserId(userId: string | number) {
    // eslint-disable-next-line camelcase
    AnalyticsService.gtag({ ['user_id']: userId });
  }

  public track(customEvent: GtagCustomEvent) {
    this.ngZone.runOutsideAngular(() => {
      AnalyticsService.gtag(customEvent);
      this.fbq('trackCustom', customEvent.action);
    });
  }

  public trackLogin() {
    this.ngZone.runOutsideAngular(() => {
      AnalyticsService.ga('send', 'event', {
        eventCategory: 'Login',
        eventAction: 'Login',
        eventValue: 1
      });
    });
  }
}
