import { PragmaticFactoryOutputMapInterface } from './interfaces';
import { nextTick } from '@crc/shared';
import {
  AsyncSubject,
  BehaviorSubject,
  concatMap,
  defer,
  finalize,
  from,
  Observable,
  share
} from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PragmaticFeedDtoAllTypes, PragmaticLiveFeedTypeEnum } from '@crc/core';

export class PragmaticLiveCasinoFactory {
  public webSocket: WebSocket;
  private tryToConnect = true;
  private readonly $destroy: AsyncSubject<void> = new AsyncSubject();
  // private readonly $data: Subject<PragmaticFeedDtoInterface> = new Subject();
  // public readonly data$: Observable<PragmaticFeedDtoInterface> = this.$data
  //   .asObservable()
  //   .pipe(
  //     filter((data) => !data?.['tableKey']),
  //     finalize(() => {
  //       console.log('finalized');
  //       this.onDestroy();
  //     }),
  //     share(),
  //     takeUntil(this.$destroy)
  //   );
  private readonly $dynamicDataMap: BehaviorSubject<PragmaticFactoryOutputMapInterface> =
    new BehaviorSubject(new Map());
  public readonly dynamicDataMap$: Observable<PragmaticFactoryOutputMapInterface> =
    this.$dynamicDataMap.asObservable().pipe(
      finalize(() => {
        // console.log('finalized');
        this.onDestroy();
      }),
      share(),
      takeUntil(this.$destroy)
    );
  constructor(
    public readonly wsURL: string,
    public readonly casinoId: string,
    public readonly tableId: string[],
    readonly connectOnInit: boolean = true
  ) {
    if (connectOnInit) {
      this.initialize();
    }
  }
  private initialize(reInit = false): void {
    // console.log(this.wsURL, this.casinoId, this.tableId);
    if (reInit) {
      // console.log('connecting to ', this.wsURL);
      this.tryToConnect = true;
      if (this.webSocket !== null && this.webSocket?.readyState !== 3) {
        this.webSocket?.close?.();
        // console.log('Socket open closing it');
      }
    }
    this.webSocket = new WebSocket(this.wsURL);
    this.webSocket.onopen = this.onWsOpen.bind(this);
    this.webSocket.onclose = this.onWsClose.bind(this);
    this.webSocket.onmessage = this.onWsMessage.bind(this);
    this.webSocket.onerror = this.onWsError.bind(this);
  }
  // public
  public onDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
    this.tryToConnect = false;
    this.webSocket.close();
    // console.log('Disconnected');
  }
  private multiSubscribe(
    casinoId: string,
    tableIds: string[],
    currency = 'GEL'
  ) {
    from(tableIds)
      .pipe(
        concatMap((tableId) => {
          return defer(
            () =>
              new Promise<string>((resolve) => nextTick(() => resolve(tableId)))
          );
        }),
        tap((tableId: string) => {
          this.subscribe(casinoId, tableId, currency);
        }),
        takeUntil(this.$destroy)
      )
      .subscribe();
  }
  // public
  public subscribe(casinoId: string, tableId: string, currency = 'GEL'): void {
    const subscribeMessage = {
      type: 'subscribe',
      key: tableId,
      casinoId: casinoId,
      currency: currency
    };
    // console.log('subscribing' + tableId);
    this.doWsSend(JSON.stringify(subscribeMessage));
  }

  private available(casinoId: string): void {
    const availableMessage = {
      type: 'available',
      casinoId: casinoId
    };
    // console.log('checking availability');
    this.doWsSend(JSON.stringify(availableMessage));
  }

  private onWsOpen(): void {
    // console.log('Connected to wss server');
    this.available(this.casinoId);
    nextTick(() => {
      this.multiSubscribe(this.casinoId, this.tableId);
    });
  }

  private onWsClose(): void {
    // console.log('DISCONNECTED');
    if (this.tryToConnect === true) {
      // console.log('RECONNECTING');
      this.initialize(true);
    }
  }

  private onWsMessage(evt: { data: string }): void {
    const data: PragmaticFeedDtoAllTypes = JSON.parse(evt.data);
    if (data?.tableId) {
      if (data?.tableType === PragmaticLiveFeedTypeEnum.TYPE_5) {
        // console.log('spaceman');
      } else {
        if (data?.last20Results?.['length']) {
          data.last6Results = data.last20Results?.['slice'](0, 6);
        }
      }

      const dynamicDataMap = this.$dynamicDataMap.getValue();
      dynamicDataMap.set(data.tableId, data);
      this.$dynamicDataMap.next(dynamicDataMap);
    }
  }

  private onWsError(evt: { data: unknown }): void {
    console.log('ERROR: ' + evt.data);
  }

  private ping(): void {
    const pingMessage = {
      type: 'ping',
      pingTime: Date.now().toString()
    };
    this.doWsSend(JSON.stringify(pingMessage));
  }

  private doWsSend(message: string): void {
    // console.log('SENT: ' + message);
    this.webSocket.send(message);
  }
}
