import { Virtual, VirtualCat } from '../entity/virtual.interface';
import {
  generateVirtualCategory,
  generateVirtualCategoryMenu
} from '../functions/functions';
import { FilterItemView, generateFilterItemViews } from '@crc/components';

export const VIRTUAL_ITEMS: FilterItemView[] = [
  generateFilterItemViews(
    '1',
    'all',
    'assets/icons/features/virtual/icons/home.svg',
    13,
    ''
  ),
  generateFilterItemViews(
    '1',
    'BETRADAR',
    'assets/icons/features/virtual/icons/betradar.svg',
    10,
    'betradar'
  ),
  generateFilterItemViews(
    '1',
    'GOLDEN RACE',
    'assets/icons/features/virtual/icons/goldenrace.svg',
    3,
    'goldenRace'
  )
];

export const SECONDARY_CATS: VirtualCat[] = [
  generateVirtualCategoryMenu(
    '',
    'all',
    'assets/icons/features/virtual/icons/all.svg',
    []
  ),
  generateVirtualCategoryMenu(
    'football',
    'sport.football',
    'assets/icons/features/virtual/icons/football.svg',
    []
  ),
  generateVirtualCategoryMenu(
    'basketball',
    'sport.basketball',
    'assets/icons/features/virtual/icons/basketball.svg',
    []
  ),
  generateVirtualCategoryMenu(
    'dog',
    'sport.dog_racing',
    'assets/icons/features/virtual/icons/dog.svg',
    []
  ),
  generateVirtualCategoryMenu(
    'horse',
    'sport.horse_race',
    'assets/icons/features/virtual/icons/horse.svg',
    []
  ),
  generateVirtualCategoryMenu(
    'moto',
    'sport.moto_racing',
    'assets/icons/features/virtual/icons/moto.svg',
    []
  ),
  // generateVirtualCategoryMenu(
  //   'baseball',
  //   'sport.baseball',
  //   'assets/icons/features/virtual/icons/baseball.svg',
  //   []
  // ),
  generateVirtualCategoryMenu(
    'tennis',
    'sport.tennis',
    'assets/icons/features/virtual/icons/tennis.svg',
    []
  ),
  generateVirtualCategoryMenu(
    'speedway',
    'sport.speedway',
    'assets/icons/features/virtual/icons/speedway.svg',
    []
  ),
  generateVirtualCategoryMenu(
    'keno',
    'sport.keno',
    'assets/icons/features/virtual/icons/keno.svg',
    []
  ),
  generateVirtualCategoryMenu(
    'spin2Win',
    'sport.spin2Win',
    'assets/icons/features/virtual/icons/spin2Win.svg',
    []
  )
];

export const VIRTUAL: Virtual[] = [
  generateVirtualCategory(
    'sport.football_league',
    'assets/icons/features/virtual/images/betradar-football.webp',
    'vflm',
    'betradar',
    'football'
  ),
  generateVirtualCategory(
    'sport.bundesliga',
    'assets/icons/features/virtual/images/betradar-bundesliga.webp',
    'vfb',
    'betradar',
    'football'
  ),
  generateVirtualCategory(
    'sport.champions_league',
    'assets/icons/features/virtual/images/betradar-champions-cup.webp',
    'vfcc',
    'betradar',
    'football'
  ),
  generateVirtualCategory(
    'sport.europe_champions',
    'assets/icons/features/virtual/images/betradar-euro-cup.webp',
    'vfec',
    'betradar',
    'football'
  ),
  generateVirtualCategory(
    'sport.world_cup',
    'assets/icons/features/virtual/images/bet-radar-world-cup.webp',
    'vfwc',
    'betradar',
    'football'
  ),
  generateVirtualCategory(
    'sport.tennis',
    'assets/icons/features/virtual/images/betradar-tennis.webp',
    'vti',
    'betradar',
    'tennis'
  ),
  // generateVirtualCategory(
  //   'sport.baseball',
  //   'assets/icons/features/virtual/images/betradar-baseball.webp',
  //   'vbi',
  //   'betradar',
  //   'baseball'
  // ),
  generateVirtualCategory(
    'sport.dog_racing',
    'assets/icons/features/virtual/images/betradar-dog.webp',
    'vdk',
    'betradar',
    'dog'
  ),
  generateVirtualCategory(
    'sport.horse_race',
    'assets/icons/features/virtual/images/bet-radar-horse.webp',
    'vhk',
    'betradar',
    'horse'
  ),
  generateVirtualCategory(
    'sport.basketball',
    'assets/icons/features/virtual/images/betradar-basketball.webp',
    'vbl',
    'betradar',
    'basketball'
  ),
  generateVirtualCategory(
    'sport.moto_racing',
    'assets/icons/features/virtual/images/golden-race-moto.webp',
    'glrc?profileByGame=motorbikes',
    'goldenRace',
    'moto'
  ),
  generateVirtualCategory(
    'sport.dog_racing',
    'assets/icons/features/virtual/images/golden-race-dog.webp',
    'glrc?profileByGame=dogs',
    'goldenRace',
    'dog'
  ),
  generateVirtualCategory(
    'sport.horse_racing',
    'assets/icons/features/virtual/images/golden-race-horse.webp',
    'glrc?profileByGame=horses',
    'goldenRace',
    'horse'
  ),
  generateVirtualCategory(
    'sport.football_league_glrc',
    'assets/icons/features/virtual/images/glrc-Football-leagues.webp',
    'glrc?profileByGame=footballLeague',
    'goldenRace',
    'football'
  ),
  generateVirtualCategory(
    'sport.world_cup_glrc',
    'assets/icons/features/virtual/images/glrc-world_cup.webp',
    'glrc?profileByGame=tournaments',
    'goldenRace',
    'football'
  ),
  generateVirtualCategory(
    'sport.keno',
    'assets/icons/features/virtual/images/glrc-keno.webp',
    'glrc?profileByGame=keno',
    'goldenRace',
    'keno'
  ),
  generateVirtualCategory(
    'sport.spin2Win',
    'assets/icons/features/virtual/images/glrc-spin2Win.webp',
    'glrc?profileByGame=spin2Win',
    'goldenRace',
    'spin2Win'
  ),
  generateVirtualCategory(
    'sport.speedway',
    'assets/icons/features/virtual/images/glrc-speedway.webp',
    'glrc?profileByGame=speedway',
    'goldenRace',
    'speedway'
  )
];
