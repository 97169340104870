import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseUrl, HttpService, Platform } from '@crc/shared';

import { Provider } from '../../shared';
import {
  CasinoGame,
  CasinoGameSearch,
  CategoryCasinoGames,
  ProviderCasinoGames
} from '../entity';

@Injectable({
  providedIn: 'root'
})
export class CasinoService {
  constructor(private readonly apiService: HttpService) {}

  getProvidersWithCasinos(
    platform: string,
    customerId: number
  ): Observable<ProviderCasinoGames[]> {
    const type = 'casino';
    let path = `/integrations/v2/${type}/providers?platform=${platform}&include=games`;
    if (customerId) {
      path += `&customerId=${customerId}`;
    }
    const baseUrl: BaseUrl = 'cmsApiUrl';

    return this.apiService
      .get$<{ data: ProviderCasinoGames[] }>({ path, baseUrl })
      .pipe(map((d) => d.data));
  }

  getProviderGameStats<T>(): Observable<T[]> {
    const type = 'casino';
    const path = `/integrations/v2/${type}/providers/games/stats`;
    const baseUrl: BaseUrl = 'cmsApiUrl';

    return this.apiService
      .get$<{ data: T[] }>({ path, baseUrl })
      .pipe(map((d) => d.data));
  }

  searchCasinos(
    platform: Platform,
    searchInput: string
  ): Observable<CasinoGameSearch[]> {
    const path = `/integrations/search?type=casino&platform=${platform}&search=${searchInput}`;

    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: CasinoGameSearch[] }>({ path, baseUrl })
      .pipe(map((data) => data.data));
  }

  getHistory(): Observable<CasinoGame[]> {
    const path = '/integrations/v2/casino/user/history';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: { game: CasinoGame; provider: Provider }[] }>({
        path,
        baseUrl
      })
      .pipe(
        map((casino) =>
          casino.data.map((casino) => {
            return { ...casino.game, providerName: casino.provider.name };
          })
        ),
        catchError((err) => of([]))
      );
  }

  getFavorites(): Observable<CasinoGame[]> {
    const path = '/integrations/v2/casino/user/favorites';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: { game: CasinoGame; provider: Provider }[] }>({
        path,
        baseUrl
      })
      .pipe(
        map((casino) =>
          casino.data.map((casino) => {
            return { ...casino.game, providerName: casino.provider.name };
          })
        ),
        catchError((err) => of([]))
      );
  }

  addToHistory(providerId: string, gameId: string) {
    const path = `/integrations/v2/casino/user/history?providerId=${providerId}&gameId=${gameId}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.post$({ path, baseUrl, body: {} });
  }

  getCasinoProviders(
    platform: Platform,
    customerId: number
  ): Observable<Provider[]> {
    let path = `/integrations?type=casino&platform=${platform}`;
    if (customerId) {
      path += `&customerId=${customerId}`;
    }

    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<any>({ path, baseUrl })
      .pipe(map((result) => result.data));
  }

  getCategoriesAndCasinos(
    platform: Platform
  ): Observable<CategoryCasinoGames[]> {
    const path = `/integrations/v2/casino/categories?include=games&platform=${platform}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: CategoryCasinoGames[] }>({ path, baseUrl })
      .pipe(map(({ data }) => data));
  }

  addToFavorites(providerId: string, gameId: string) {
    const path = `/integrations/v2/casino/user/favorites?providerId=${providerId}&gameId=${gameId}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.post$({ path, baseUrl, body: {} });
  }

  removeFromFavorites(providerId: string, gameId: string) {
    const path = `/integrations/v2/casino/user/favorites?providerId=${providerId}&gameId=${gameId}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.delete$({ path, baseUrl, body: {} });
  }
}
