<ng-container *ngSync="SMS_OFF_KEY | translate as translatedKey">
  <div class="sms-off__description">
    {{ translatedKey }}
  </div>
  <div class="sms-off__phone-number">{{ mobileNumber$ | async }}</div>
  <div class="sms-off__buttons">
    <crc-m-shared-button
      (clicked)="
      userAction.emit({
        status: true,
        text: translatedKey
      })
    "
      type="active"
    >{{ 'agree' | translate }}</crc-m-shared-button
    >
    <crc-m-shared-button
      (clicked)="
      userAction.emit({
        status: false,
        text: translatedKey
      })
    "
      type="primary"
    >{{ 'disagree' | translate }}</crc-m-shared-button
    >
  </div>

</ng-container>
