import { filter, finalize, Observable, startWith, Subject, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { getWindow } from './window.functions';
import { EnvironmentConfig } from '../environment';

export function getIntersectionListener$(
  targetEl: HTMLElement,
  options: IntersectionObserverInit & {
    trackVisibility?: boolean;
    delay?: number;
  } = {
    root: document,
    threshold: 0.0000000001,
    trackVisibility: true,
    delay: 100
  }
): Observable<IntersectionObserverEntry[]> {
  const subscriber: Subject<IntersectionObserverEntry[]> = new Subject();
  const intObs: IntersectionObserver = new IntersectionObserver((entry) => {
    subscriber.next(entry);
  }, options);
  return subscriber.asObservable().pipe(
    startWith(
      ((): null => {
        intObs.observe(targetEl);
        return null;
      })()
    ),
    filter((val) => val !== null),
    finalize(() => {
      intObs?.disconnect?.();
    })
  );
}
export function getWasSessionManipulated$(
  targetElement: HTMLElement,
  interval: number = 3000
) {
  const { zIndex: originalZIndex } =
    getWindow().getComputedStyle(targetElement);
  return timer(0, interval).pipe(
    switchMap(() => {
      return getIntersectionListener$(targetElement);
    }),
    map((entry) => {
      const {
        intersectionRect,
        rootBounds,
        intersectionRatio,
        isIntersecting
      } = entry?.[0] ?? {};
      const { pointerEvents, zIndex } =
        getWindow().getComputedStyle(targetElement);
      const widthDiff = Math.abs(
        (intersectionRect?.width ?? 21) - (rootBounds?.width ?? 0)
      );
      const heightDiff = Math.abs(
        (intersectionRect?.height ?? 21) - (intersectionRect?.height ?? 0)
      );
      const finalChecker =
        isIntersecting &&
        Number(intersectionRatio) === 1 &&
        widthDiff < 20 &&
        heightDiff < 20 &&
        pointerEvents === 'auto' &&
        originalZIndex === zIndex;
      return !finalChecker;
    })
  );
}
export function getEnvironmentConfigFromLocalStorage(): EnvironmentConfig {
  return JSON.parse(getWindow().localStorage.getItem('env'));
}
