import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject
} from '@angular/core';
interface NgSyncContextInterface {
  ngSync: unknown;
  $implicit: unknown;
  [key: string | number | symbol]: unknown;
}
@Directive({
  selector: '[ngSync]',
  standalone: true
})
export class NgSyncDirective implements OnInit {
  @Input() private set ngSync(value: unknown) {
    Object.assign(this.context, {
      ...(typeof value === 'object' ? value : {}),
      ngSync: value,
      $implicit: value
    });
  }
  private readonly context: NgSyncContextInterface = {
    ngSync: null,
    $implicit: null
  };
  private readonly templateRef: TemplateRef<NgSyncContextInterface> =
    inject(TemplateRef);
  private readonly viewRef: ViewContainerRef = inject(ViewContainerRef);
  public ngOnInit(): void {
    this.viewRef.createEmbeddedView(this.templateRef, this.context);
  }
}
