<form
  [formGroup]="form"
  class="otp-auth-form"
  [class.otp-auth-form--state-hidden]="isCaptchaActive"
  *ngIf="
    !smsStatus || smsStatus?.error === 'WRONG_USERNAME' || smsStatus?.success;
    else UserActionResult
  "
>
  <div class="otp-auth-form__control otp-auth-form__control-username">
    <crc-m-shared-input
      [placeholder]="'username_full' | translate"
      [class.error]="false"
      [errorsTexts]="[
        { type: 'required', text: 'fill_required_fields' | translate },
        { type: 'pattern', text: 'use_only_latin_letters' | translate },
        { type: 'wrongUsername', text: 'otp.wrong_user_name' | translate }
      ]"
      autoComplete="username"
      name="username"
      type="text"
      data-cy="username"
      formControlName="userName"
    ></crc-m-shared-input>
  </div>
  <div class="otp-auth-form__control otp-auth-form__control-otp">
    <crc-m-shared-input
      [placeholder]="'sms_code' | translate"
      [errorsTexts]="[
        { type: 'required', text: 'fill_required_fields' | translate },
        { type: 'pattern', text: 'use_only_latin_letters' | translate },
        { type: 'wrongCode', text: 'sms_code_invalid' | translate }
      ]"
      [class.error]="false"
      mask="0000000000"
      autoComplete="one-time-code"
      formControlName="passwordOTP"
      type="text"
      inputMode="decimal"
      data-cy="one-time-password"
      name="one-time-password"
    ></crc-m-shared-input>
    <crc-m-timer-button
      [timeAmount]="60"
      [textInput]="'login.receive_otp_again' | translate"
      [initiateStartOnInit]="true"
      [disabled]="form.get('userName').invalid"
      [reset]="{
        status: timerResetTrigger
      }"
      (timerStarted)="sendSMS.emit()"
      class="otp-auth-form__button--count_down"
    ></crc-m-timer-button>
  </div>
  <div class="errorText" *ngIf="form.hasError('blockedByUnknownEntity') && !isCaptchaActive">
    <span>{{'login.blocked-by-unknown-entity' | translate}}</span>
  </div>
  <div class="otp-auth-form__extra-field">
    <crc-m-login-otp-button
      [isActive]="true"
      class="otp-auth-form__extra-field-item"
    ></crc-m-login-otp-button>
    <span
      (click)="returnToDefaultProtocol.emit()"
      class="otp-auth-form__extra-field-item"
      >{{ 'login.with_password' | translate }}</span
    >
  </div>
  <crc-m-shared-button
    *ngIf="{
      isDisabled: form.invalid || isLoading || submitStatus?.success
    } as params"
    [type]="form.valid ? 'active' : 'secondary'"
    [loader]="isLoading"
    (click)="formSubmit.emit(params.isDisabled)"
    class="otp-auth-form__button--submit"
    >{{ 'login' | translate }}</crc-m-shared-button
  >
</form>
<div *ngIf="isCaptchaActive" class="otp-auth-form">
  <crc-m-shared-captcha
    [captcha]="captchaCode || submitStatus?.captcha"
    [enteredCaptchaValid]="isCaptchaValid"
    (captchaValueEntered$)="captchaValueEntered.emit($event)"
    (captchaUpdated$)="updateCaptcha.emit()"
    (captchaValidValueEntered$)="captchaValidValueEnter.emit()"
  >
  </crc-m-shared-captcha>
</div>
<ng-template #UserActionResult>
  <div class="otp-auth--errored">
    <crc-m-shared-user-action-result
      (ok)="returnToDefaultProtocol.emit()"
      [failText]="
        'otp.' +
          (
            smsStatus?.errorCode ||
            (smsStatus?.error ?? 'otp.default_error_message')
          )?.toLowerCase()
          | translate : { seconds: smsStatus?.retryAfter ?? 60 }
      "
      [success]="false"
      [buttonText]="'verification.okay' | translate"
    ></crc-m-shared-user-action-result>
  </div>
</ng-template>
