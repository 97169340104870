import { Injectable } from '@angular/core';
import { BaseUrl, HttpService } from '@crc/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Promotion } from '../entity/promotion.model';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {
  constructor(private readonly apiService: HttpService) {}

  isPromotionActive(tokenId: string): Observable<Promotion> {
    const path = `/ui/toggles/${tokenId}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: Promotion }>({ path, baseUrl })
      .pipe(map((payload) => payload.data));
  }
}
