export enum SlotCasinoGameTypeEnum {
  SLOT = 1,
  CASINO = 2
}

export type SlotCasinoGameType =
  | SlotCasinoGameTypeEnum.SLOT
  | SlotCasinoGameTypeEnum.CASINO;

export enum SplitScreenTypesEnum {
  OneFrame,
  TwoFrames,
  FourFrames
}

export type SplitScreenTypes = 0 | 1 | 2;

export interface PlayInterface {
  gameName: string;
  providerName: string;
  gameId: string;
  gameType: SlotCasinoGameType;
}

export enum GameFrameEnum {
  One = 'game1',
  Two = 'game2',
  Three = 'game3',
  Four = 'game4'
}
