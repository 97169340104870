import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { fadeInAndOutBottomTop } from '@crc/shared';
import { PragmaticDigitalOdometerConfig } from '../../entity/odometer.interface';
import { PragmaticJackpot } from '../../entity';

@Component({
  selector: 'crc-pragmatic-digital-odometer-presentation',
  templateUrl: './pragmatic-digital-odometer-presentation.component.html',
  styleUrls: ['./pragmatic-digital-odometer-presentation.component.scss'],
  animations: [fadeInAndOutBottomTop(200)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PragmaticDigitalOdometerPresentationComponent {
  @Input() odometerData: any;
  @Input() odometerCount: number;
  @Input() odometers: PragmaticDigitalOdometerConfig[];
  @Input() jackpot: PragmaticJackpot[];
  @Input() disableLogo: boolean;
  @Input() showPopupInfo: boolean;
  @Input() vertical: boolean;
  @Input() largeIcon: boolean;
  @Input() isMainPage: boolean;

  @Output() refreshOdometer: EventEmitter<string> = new EventEmitter();

  isOverlayOpen: boolean[] = [];

  emitRefreshOdometer() {
    this.refreshOdometer.emit();
  }
}
