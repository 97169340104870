<div *ngIf="(passcodeState | async) === 'ask'" class="activate">
  <img class="activate__img" src="assets/icons/lock.svg" alt="*" />
  <p class="activate__text top-margin">
    {{ 'want_passcode-activation' | translate }}
  </p>
  <div class="activate__button top-margin">
    <crc-m-shared-button
      (clicked)="setPasscodeState('accepted')"
      type="active"
      >{{ 'yes' | translate }}</crc-m-shared-button
    >
  </div>
</div>
<div *ngIf="(passcodeState | async) !== 'ask'" class="accepted">
  <!--  TODO  BEFORE RETURN AVATAR-->
  <!--  <img class="activate__img" src="assets/icons/lock.svg" alt="*" />-->
  <!--  <p class="activate__text top-margin">{{ 'choose-passcode' | translate }}</p>-->
  <div>
    <crc-m-masked-input
      [passcode]="passcode | async"
      [numberOfItems]="4"
    ></crc-m-masked-input>
  </div>
  <div class="top-margin">
    <crc-m-keyboard
      (keyboardClick)="onKeyboardClick($event, 'active')"
      offKey="off"
    ></crc-m-keyboard>
  </div>
  <div
    class="activate__button"
    [class.disabled]="(passcode | async)?.length !== 4"
  >
    <crc-m-shared-button
      (clicked)="savePasscode()"
      [type]="(passcode | async)?.length === 4 ? 'active' : 'secondary'"
      >{{ 'activate' | translate }}</crc-m-shared-button
    >
  </div>
</div>
