import { ModuleWithProviders, NgModule } from '@angular/core';
import { provideServiceWorker } from '@angular/service-worker';
@NgModule()
export class ServiceWorkerEntryModule {
  public static forRoot(
    isProduction: boolean
  ): ModuleWithProviders<ServiceWorkerEntryModule> {
    return isProduction
      ? {
          ngModule: ServiceWorkerEntryModule,
          providers: [
            provideServiceWorker('ngsw-worker.js', {
              enabled: true,
              registrationStrategy: 'registerWhenStable:30000'
            })
          ]
        }
      : { ngModule: ServiceWorkerEntryModule, providers: [] };
  }
}
