import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { SearchGameViewData } from './entity/search-game-view-data';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'crc-game-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent {
  @Input() searchGameData: SearchGameViewData[] = [];
  @Input() gameContentVisibility: boolean;
  @Input() contentHeight: 'sm' | 'md' | 'lg' = 'sm';
  @Input() private restrictFirstCharToBeSpace: boolean;
  @Input() outsideSearchState: boolean;
  @Output() keyUp: EventEmitter<string> = new EventEmitter();
  @Output() selectGame = new EventEmitter();
  @Output() focusEvent = new EventEmitter<FocusEvent>();
  @Output() blurEvent = new EventEmitter<Event>();
  @Output() clearClick = new EventEmitter();

  inputValue = new UntypedFormControl('');
  iconVisibility = true;

  constructor() {
    this.inputValue.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap((value) => {
          if (value.match(/^ *$/) !== null) {
            this.keyUp.emit('');
          } else {
            if (value.length >= 3 && this.searchGameData.length) {
              this.outsideSearchState = true;
            }
            this.keyUp.emit(value);
          }
          this.iconVisibility = value.length === 0 || value === '';
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  onGameSelect(gameData: SearchGameViewData) {
    this.selectGame.emit(gameData.callBack);
    this.onClearAll();
  }

  onClearAll() {
    this.inputValue.reset('');
    this.iconVisibility = true;
    this.gameContentVisibility = false;
    this.clearClick.emit();
  }
  public handleKeyDown(event: KeyboardEvent): void {
    if (this.restrictFirstCharToBeSpace) {
      if (event.keyCode === 32 && !(event.target as HTMLInputElement).value) {
        event.preventDefault();
      }
    }
  }

  onMouseLeave() {
    this.gameContentVisibility = false;
    this.outsideSearchState = null;
  }

  onMouseEnter() {
    this.gameContentVisibility = true;
  }
}
