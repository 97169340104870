import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Platform } from '../entity/live-big-raffle.interface';
import { LiveBigRaffleService } from '../service/live-big-raffle.service';

@Injectable({
  providedIn: 'root'
})
export class LiveBigRaffleFacade {
  constructor(private raffleService: LiveBigRaffleService) {}

  public getVideoLink(address: string): Observable<string> {
    return this.raffleService.getVideoLink(address);
  }

  public getRaffleSchedule(): Observable<string[]> {
    return this.raffleService.getRaffleSchedule();
  }

  public getLiveButtonData<T>(platform: Platform): Observable<T[]> {
    return this.raffleService.getLiveButtonData<T>(platform);
  }
}
