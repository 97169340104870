<div
  class="input-container"
  [class.error]="
    control?.touched &&
    ((control?.statusChanges | async) === 'INVALID' || control.invalid) &&
    (control?.value || control?.hasError('forceRequired'))
  "
>
  <img class="flag" src="/assets/icons/flags/{{ flag }}.svg" alt="" />
  <div class="prefix">{{ prefix }}</div>

  <input
    [id]="name + '_input'"
    [(ngModel)]="value"
    (input)="onChange(value)"
    (keyup)="keyUpped.emit($event)"
    (focus)="focused.emit($event)"
    (blur)="onTouched(); blurred.emit($event)"
    [autocomplete]="autoComplete"
    [type]="type"
    [mask]="mask"
    [name]="name"
    [disabled]="disabled"
    class="input form-control form-control-p"
    [attr.inputmode]="inputMode"
    required
  />

  <label class="input-label" [for]="name + '_input'">
    <ng-container
      *ngIf="
        control.value &&
          control?.touched &&
          ((control?.statusChanges | async) === 'INVALID' || control.invalid);
        else placeHolder
      "
    >
      <span
        class="error"
        *ngIf="currentDisplayedError | async as error; else placeHolder"
        >{{ error }}</span
      >
    </ng-container>

    <ng-template #placeHolder>{{ placeholder }}</ng-template>
  </label>
</div>
