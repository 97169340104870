@if (isNewLabelEnabled) {
<span class="label-tooltip--new-blue">{{ 'new' | translate }}</span>
}
<div
  class="deposit-mode-btn"
  [class.active]="activeMode === DepositActiveModeEnum.CREDIT_CARD"
  (click)="handleBtnClick(DepositActiveModeEnum.CREDIT_CARD)"
>
  <img
    ngSrc="assets/icons/features/payments/credit_card_toggle-symbol.svg"
    width="55"
    height="14"
    alt="Credit Card"
  />
</div>
<div
  class="deposit-mode-btn"
  [class.active]="activeMode === DepositActiveModeEnum.APPLE_PAY"
  (click)="handleBtnClick(DepositActiveModeEnum.APPLE_PAY)"
>
  <img
    ngSrc="assets/icons/features/payments/apple_pay_toggle_symbol.svg"
    width="46"
    height="20"
    alt="Apple Pay"
  />
</div>
