import { FilterAmount } from './filter.interface';

export type CasinoFilterType = 'category' | 'provider';

export type CasinoFilterTypeItem = {
  filterType: CasinoFilterType;
  filter: string;
};

export const CASINO_DEFAULT_RANGE = {
  minStart: 0.1,
  minEnd: 50000
};
export const CASINO_EGT_LOBBY_RANGE = {
  value: 'FreeChips',
  ...CASINO_DEFAULT_RANGE,
  minStart: 0,
  games: []
};
export const CasinoFilterItems: FilterAmount[] = [
  {
    value: 'all',
    minStart: 0,
    minEnd: 50000,
    isActive: true,
    games: []
  },
  {
    value: '0-20 ₾',
    minStart: 0,
    minEnd: 20,
    games: []
  },
  {
    value: '21-50 ₾',
    minStart: 21,
    minEnd: 50,
    games: []
  },
  {
    value: '51-500 ₾',
    minStart: 51,
    minEnd: 500,
    games: []
  },
  {
    value: '500+ ₾',
    minStart: 500,
    minEnd: 50000,
    games: []
  },
  { ...CASINO_EGT_LOBBY_RANGE }
];
