import { Injectable } from '@angular/core';

import { Cookie, CookieService, EnvironmentService } from '@crc/shared';

import { AuthStorage } from './auth-storage';

@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly cookieService: CookieService,
    private readonly authStorageService: AuthStorage
  ) {}

  getToken(): string {
    return this.cookieService.get(
      this.environmentService.config.authCookieName
    );
  }

  signOut() {
    this.cookieService.delete(this.environmentService.config.authCookieName);
    this.authStorageService.clearLoginData();
  }

  setToken(token: string) {
    if (token) {
      const params: Cookie = {
        name: 'X-ODDS-SESSION',
        value: token,
        expires: 3,
        SameSite: 'strict',
        HttpOnly: true
      };
      this.cookieService.set(params);
    }
  }
}
