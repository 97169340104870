import { Inject, Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvironmentService } from '../../environment';
import { CATEGORY_CONFIG } from './category-config-injection-token';

@Injectable()
export class HttpCustomLoader implements TranslateLoader {
  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
    @Inject(CATEGORY_CONFIG) private readonly category: string
  ) {}

  public getTranslation(lang: string): Observable<any> {
    return this.http
      .get(
        `${this.env.config.cmsApiUrl}/ui/strings/i18n/${lang}?category=${this.category}`
      )
      .pipe(
        map((res: any) => {
          return res?.data;
        })
      );
  }
}
