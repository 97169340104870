import { Injectable } from '@angular/core';
import { BonusItem, BonusMenu, BonusUpdate } from '../entity/bonuses.interface';
import { BONUS_MENU_ITEMS, CRM_TYPES } from '../entity/menu-items';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CrmService } from '../services/crm.service';
import {
  EnvironmentService,
  LanguageFacade,
  nextTick,
  StorageService
} from '@crc/shared';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { filterBonuses } from '../functions/functions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { registerLocaleData } from '@angular/common';
import localeKa from '@angular/common/locales/ka';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import localeTr from '@angular/common/locales/tr';
const KEY = 'crm_read_bonuses';
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class CrmBonusesFacade extends StorageService<string> {
  private readonly BonusMenuItems: Array<BonusMenu> = BONUS_MENU_ITEMS;
  private readonly $amountOfActiveBonuses = new BehaviorSubject<number>(0);
  private readonly $updateBonuses = new BehaviorSubject<BonusUpdate>({
    page: 0,
    count: 10
  });
  private readonly $filter = new BehaviorSubject<string>('');
  private readonly $cashedBonuses = new Subject<BonusItem[]>();
  bonuses$: Observable<BonusItem[]>;
  $bonuses = new Subject<BonusItem[]>();
  $bonusesLength = new Subject<number>();
  bonusesArray: BonusItem[] = [];
  public readonly readBonuses: {
    ids: Set<string>;
  } = {
    ids: new Set()
  };
  constructor(
    private readonly crmService: CrmService,
    private readonly languageFacade: LanguageFacade,
    private readonly environmentService: EnvironmentService
  ) {
    super();
    this.bonuses$ = this.languageFacade.current$.pipe(
      switchMap(() => {
        return this.$updateBonuses.pipe(
          debounceTime(300),
          switchMap((updateBonus: BonusUpdate) =>
            this.crmService.getBonuses(updateBonus).pipe(
              map((bonuses) => {
                return bonuses.items.map((bonus: BonusItem) => {
                  return {
                    ...(bonus.type !== CRM_TYPES.FREESPIN
                      ? this.routeToBonusGame(bonus)
                      : this.routeToSlotGame(bonus)),
                    isRead: this.readBonuses.ids.has(bonus._id)
                  };
                });
              }),
              tap((items: BonusItem[]) => {
                if (items.length) {
                  this.countActiveBonus(items);
                }
                if (updateBonus.page === 0) {
                  this.bonusesArray = [];
                }
              })
            )
          )
        );
      })
    );

    this.listenToCurrentLang().pipe(untilDestroyed(this)).subscribe();
    this.readReadBonusesFromCache();
  }

  listenToCurrentLang() {
    return this.languageFacade.current$.pipe(
      tap((lang) => {
        if (lang === 'ka') {
          registerLocaleData(localeKa, lang);
        } else if (lang === 'en') {
          registerLocaleData(localeEn, lang);
        } else if (lang === 'ru') {
          registerLocaleData(localeRu, lang);
        } else if (lang === 'tr') {
          registerLocaleData(localeTr, lang);
        }
      })
    );
  }

  routeToSlotGame(bonus: BonusItem) {
    return {
      ...bonus,
      route: this.getRoute(),
      queryParams: {
        slot: bonus.action?.gameName,
        provider: bonus.action?.providerId
      }
    };
  }

  routeToBonusGame(bonus: BonusItem) {
    return {
      ...bonus,
      route: this.detectRouteLink(bonus.category)
    };
  }

  updateBonuses(data: BonusUpdate) {
    this.$updateBonuses.next(data);
  }

  private getRoute() {
    return this.environmentService.config.platform === 'mobile'
      ? 'launcher'
      : '/slots/play';
  }

  getFilteredBonuses(): Observable<BonusItem[]> {
    return this.bonuses$.pipe(
      map((bonuses) => {
        const bonusesLength = [];
        bonuses.filter((data) => {
          if (
            Number(new Date(data.completesAt)) > Number(new Date()) &&
            !data.isRead
          ) {
            bonusesLength.push(data);
          }
        });

        this.bonusesArray = this.bonusesArray.concat(bonuses);
        this.$cashedBonuses.next(this.bonusesArray);

        this.$bonusesLength.next(bonusesLength.length);
        return filterBonuses(this.bonusesArray);
      })
    );
  }

  getBonusMenuItems(): BonusMenu[] {
    return this.BonusMenuItems;
  }

  countActiveBonus(bonuses: BonusItem[]) {
    const activeBonuses = bonuses.filter((x) => {
      return (
        Number(new Date(x.completesAt)) > Number(new Date()) &&
        !(x.isRead || this.readBonuses.ids.has(x._id))
      );
    });
    this.$amountOfActiveBonuses.next(activeBonuses.length);
    this.$bonusesLength.next(activeBonuses.length);
  }

  detectRouteLink(category: string) {
    switch (category) {
      case CRM_TYPES.UFOSPIN:
        return '/ufo';
      case CRM_TYPES.FREEBET:
        return '/sport';
      case CRM_TYPES.FREESPIN:
        return '/slots';
      case CRM_TYPES.AMOUNT:
        return '/';
    }
  }

  setAmountOfActiveBonuses(activeBonuses: number) {
    this.$amountOfActiveBonuses.next(activeBonuses);
  }

  getAmountOfBonuses$(): Observable<number> {
    return this.$amountOfActiveBonuses.asObservable();
  }

  getBonuses$(): Observable<BonusItem[]> {
    return this.$cashedBonuses.pipe(
      switchMap((data) =>
        this.$filter.pipe(
          map((filteredBy) => {
            return filteredBy
              ? data.filter((d) => d.category === filteredBy)
              : data;
          })
        )
      )
    );
  }

  setFilterItem(item: string) {
    this.$filter.next(item);
  }
  public cacheReadBonuses(readBonuses: BonusItem[]): void {
    return void 0;
    //for time being
    this.readBonuses.ids = new Set([
      ...JSON.parse(this.get(KEY) ?? '[]'),
      ...readBonuses.map((value) => value._id)
    ]);
    nextTick(() => {
      this.countActiveBonus(readBonuses);
      this.commitCache();
    });
  }
  private readReadBonusesFromCache(): void {
    this.readBonuses.ids = new Set(JSON.parse(this.get(KEY) ?? '[]'));
  }
  public commitCache(): void {
    this.set(KEY, JSON.stringify(Array.from(this.readBonuses.ids)));
  }
}
