import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PaymentType } from '../entity/deposit.interface';
import { CustomValidators } from '@crc/components';

@Injectable({
  providedIn: 'root'
})
export class PaymentFormHelperService {
  constructor(private readonly fb: UntypedFormBuilder) {}

  initPaymentForm(
    paymentType: PaymentType,
    withdrawMin: number,
    depositMin: number
  ): UntypedFormGroup {
    return this.fb.group({
      transactionAmount: new UntypedFormControl(null, [
        Validators.min(paymentType === 'withdraw' ? withdrawMin : depositMin),
        Validators.required,
        CustomValidators.notEnoughBalanceValidator
      ])
    });
  }

  setPaymentFormValidations(
    paymentForm: UntypedFormGroup,
    isWithdraw: boolean,
    maxValidator: number,
    isBog: boolean,
    withdrawMinBog: number,
    depositMinBog: number,
    withdrawMinTbc: number,
    depositMinTbc: number
  ) {
    paymentForm
      .get('transactionAmount')
      .setValidators([
        Validators.max(maxValidator),
        Validators.min(
          isWithdraw
            ? isBog
              ? withdrawMinBog
              : withdrawMinTbc
            : isBog
            ? depositMinBog
            : depositMinTbc
        ),
        Validators.required
      ]);
  }
}
