import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpResponseCode } from '@crc/shared';

import { ResetPasswordService } from '../services/reset-password.service';
import {
  ResetPassword,
  ResetPasswordVerify
} from '../entity/reset-password.interface';
import { ResetPasswordSteps } from '../entity/reset-password-steps.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ResetPasswordFacade {
  private readonly $showResetPassword = new BehaviorSubject<boolean>(false);
  private readonly step$ = new BehaviorSubject<ResetPasswordSteps>(
    ResetPasswordSteps.ENTER_MOBILE
  );

  constructor(private readonly resetPasswordService: ResetPasswordService) {}

  getShowState$() {
    return this.$showResetPassword.asObservable();
  }

  getStep$() {
    return this.step$.asObservable();
  }

  resetStep() {
    this.step$.next(ResetPasswordSteps.ENTER_MOBILE);
  }

  open() {
    this.$showResetPassword.next(true);
  }

  close() {
    this.$showResetPassword.next(false);
    this.resetStep();
  }

  resetPassword(body: ResetPassword): Observable<{
    success: boolean;
    retryAfter?: number;
  }> {
    return this.resetPasswordService.resetPassword(body).pipe(
      tap(({ success: status, retryAfter }) => {
        if (status) {
          this.step$.next(ResetPasswordSteps.VERIFY_CODE);
          return;
        }
        if (!retryAfter) {
          this.step$.next(ResetPasswordSteps.FAIL);
        }
      })
    );
  }

  resetPasswordVerify(body: ResetPasswordVerify): Observable<boolean> {
    return this.resetPasswordService.resetPasswordVerify(body).pipe(
      catchError((err: HttpErrorResponse) => of(err.error)),
      map(({ code, data }) => {
        return code === HttpResponseCode.OK && data;
      }),
      tap((status: boolean) => {
        if (status) {
          this.step$.next(ResetPasswordSteps.SUCCESS);
        } else {
          this.step$.next(ResetPasswordSteps.FAIL);
        }
      })
    );
  }
}
