<div class="title" data-cy="title">
  {{ 'captcha.enter_security_code' | translate }}
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="captcha" data-cy="captcha" [innerHTML]="captchaSafe"></div>

  <div class="code" data-cy="code">
    <div class="code__input" data-cy="code-input">
      <crc-m-shared-input
        [class.error]="enteredCaptchaValid === false || captchaUpdateRequested"
        [inputMode]="'numeric'"
        placeholder="{{ 'code' | translate }}"
        formControlName="captcha"
        autoComplete="off"
        data-cy="form-captcha"
      >
        <div class="status__icon" data-cy="status-icon">
          <crc-m-shared-status-icon
            *ngIf="enteredCaptchaValid && !captchaUpdateRequested"
            [success]="true"
            path="/assets/icons/checked.svg"
            alt="ok"
          >
          </crc-m-shared-status-icon>
        </div>
      </crc-m-shared-input>
    </div>

    <div class="code__icon" data-cy="code-icon">
      <crc-m-shared-icon-button
        (clicked)="onCaptchaUpdated()"
        path="/assets/icons/refresh.svg"
        alt="refresh"
      >
      </crc-m-shared-icon-button>
    </div>
  </div>

  <crc-m-shared-button
    [disable]="form.invalid || !enteredCaptchaValid || captchaUpdateRequested"
    [type]="
      form.valid && enteredCaptchaValid && !captchaUpdateRequested
        ? 'active'
        : 'secondary'
    "
    style="width: 100%"
    >{{ 'confirm' | translate }}
  </crc-m-shared-button>
</form>
