import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SlotsStateFacade } from '../../features/slots/state';

@Component({
  selector: 'crc-m-games-title',
  templateUrl: './games-title.component.html',
  styleUrls: ['./games-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GamesTitleComponent {
  @Input() title: string;
  @Input() imageUrl: string;
  @Input() dataCy: string;
  @Input() border: boolean;
  @Input() largeImg: boolean;
  @Input() isNew: boolean;
  @Input() localSVGParameters: {
    name: string;
    color?: string;
    maxWidth?: string;
    maxHeight?: string;
    fullSize?: boolean;
    size?: string;
  };
  @Input() set iconBgColor(value: boolean) {
    value === undefined
      ? (this.IconBgColor = true)
      : (this.IconBgColor = value);
  }
  isBuyBonusActive$ = this.slotStateFacade.isBuyBonusActive();
  IconBgColor: boolean;
  constructor(private readonly slotStateFacade: SlotsStateFacade) {}
}
