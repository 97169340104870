import { inject, Injectable } from '@angular/core';
import {
  EnvironmentService,
  NavigationItem,
  nextTick,
  SecondaryFilterDTOInterface,
  StorageService
} from '@crc/shared';
import { MiniService } from '../services';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  Observable,
  of,
  shareReplay,
  switchMap,
  timer
} from 'rxjs';
import { catchError, debounceTime, filter, map, tap } from 'rxjs/operators';
import { Mini, MiniGames, MiniGameSearch } from '../entity';
import { AccountFacade, AuthFacade } from '../../../auth';
import { ActivatedRoute, Params } from '@angular/router';
import {
  generateUfoAllGameNavigationItems,
  generateUfoAllGameNavigationItemsForWeb
} from '../helper/functions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterItemView } from '@crc/components';
import { Provider } from '../../shared';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class MiniFacade extends StorageService<'miniGamesLocalFavoritesList'> {
  private miniService: MiniService = inject(MiniService);
  private env: EnvironmentService = inject(EnvironmentService);
  private readonly accountFacade: AccountFacade = inject(AccountFacade);
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly $miniGameUrl = new BehaviorSubject<string>(null);
  private readonly $triggerFavoritesSyncWithBackEnd: BehaviorSubject<void> =
    new BehaviorSubject(void 0);
  public readonly $searchValue: BehaviorSubject<string> = new BehaviorSubject(
    ''
  );

  private favoriteMiniGames$: Observable<Map<string, MiniGames>> | null = null;
  constructor() {
    super();
    this.listenToLogout();
  }
  public readonly miniProvidersMap$: Observable<
    Map<Provider['provider'], Provider>
  > = this.accountFacade.customerId$.pipe(
    switchMap((customerId) => {
      return this.miniService.getBaseProviders$(
        this.env.config.platform,
        customerId
      );
    }),
    map(
      (providers) =>
        new Map(
          providers.map((provider) => {
            return [provider.provider, provider];
          })
        )
    ),
    shareReplay()
  );

  miniWithGames$ = this.accountFacade.customerId$.pipe(
    switchMap((customerId) =>
      this.miniService.getProvidersWithGames(
        this.env.config.platform,
        customerId
      )
    ),
    map((providers) => {
      return providers.sort((a, b) => a.order - b.order);
    }),
    shareReplay(1)
  );

  setMiniGameUrl(gameUrl: string) {
    this.$miniGameUrl.next(gameUrl);
  }

  getMiniGameUrl() {
    return this.$miniGameUrl.value;
  }

  getMiniGames(): Observable<Mini> {
    return this.getFilterFromParams$().pipe(
      switchMap(({ filter: categoryFilter, providerFilter }) =>
        this.miniWithGames$.pipe(
          filter((data) => !!data?.length),
          map((data) => {
            const games = [];
            data
              .sort((a, b) => a.order - b.order)
              .map((item) => {
                games.push(...item.games);
              });
            const allMiniGames: MiniGames[] = games.sort(
              (a, b) => a.order - b.order
            );
            const filteredFilteredMiniGames: MiniGames[] =
              this._filterMiniGamesOnProvider(
                this._filterMiniGamesOnCategory(categoryFilter, allMiniGames),
                providerFilter
              );
            return {
              iframeH: data[0].iframeH,
              iframeW: data[0].iframeW,
              name: data[0].name,
              order: data[0].order,
              provider: data[0].provider,
              tags: data[0].tags,
              totalGames: data[0].totalGames,
              type: data[0].type,
              vendor: data[0].vendor,
              games: filteredFilteredMiniGames
            };
          })
        )
      )
    );
  }
  getAllMiniGames(): Observable<Mini> {
    return this.miniWithGames$.pipe(
      filter((data) => !!data?.length),
      map((data) => {
        const games = [];
        data
          .sort((a, b) => a.order - b.order)
          .map((item) => {
            games.push(...item.games);
          });
        return {
          iframeH: data[0].iframeH,
          iframeW: data[0].iframeW,
          name: data[0].name,
          order: data[0].order,
          provider: data[0].provider,
          tags: data[0].tags,
          totalGames: data[0].totalGames,
          type: data[0].type,
          vendor: data[0].vendor,
          games: games
        };
      })
    );
  }
  public getUfoAllGamesNavigationItems$<T = NavigationItem>(): Observable<T[]> {
    return this.getFilterFromParams$().pipe(
      switchMap(({ filter }) => {
        return this.authFacade.isLoggedIn$().pipe(
          distinctUntilChanged(),
          map((loginStatus) => {
            return generateUfoAllGameNavigationItems(
              loginStatus,
              this.env.config.platform
            ).map((navItem) => {
              navItem.isActive = filter === navItem.id;
              return navItem as unknown as T;
            });
          })
        );
      })
    );
  }
  public getUfoAllGamesNavigationItemsForWeb<T = FilterItemView>(): Observable<
    T[]
  > {
    return this.getFilterFromParams$().pipe(
      switchMap(({ filter }) => {
        return this.authFacade.isLoggedIn$().pipe(
          distinctUntilChanged(),
          map((loginStatus) => {
            return generateUfoAllGameNavigationItemsForWeb(
              loginStatus,
              this.env.config.platform
            ).map((navItem) => {
              navItem.active = filter === navItem.filter;
              return navItem as unknown as T;
            });
          })
        );
      })
    );
  }
  public getUfoAllGamesProviderNavigationItems$<
    T = NavigationItem
  >(): Observable<T[]> {
    return this.getFilterFromParams$().pipe(
      switchMap(({ providerFilter }) => {
        return combineLatest([
          this.miniWithGames$,
          this.miniProvidersMap$
        ]).pipe(
          map(([providerWithGames, providersMap]) => {
            const homeIcon =
              providerFilter === 'home'
                ? '/assets/icons/features/mini-games/navigation/providers/navigation_home_icon.svg'
                : '../assets/icons/features/shared/navigation/home.svg';
            return [
              {
                name: 'main',
                image: homeIcon,
                iconUrl: homeIcon,
                route: '/mini-games',
                filter: 'home',
                navType: 'MINI',
                active: providerFilter === 'home'
              } as T
            ].concat(
              providerWithGames.map((provider) => {
                return {
                  name: provider.name,
                  image: providersMap.get(provider.provider)?.logo,
                  iconUrl: providersMap.get(provider.provider)?.logo,
                  filter: provider.provider,
                  navType: 'MINI',
                  active: providerFilter === provider.provider,
                  route: '/mini-games',
                  ...(this.env.config.platform === 'mobile'
                    ? { isMobNew: provider.tags?.includes('new') }
                    : { isNew: provider.tags?.includes('new') })
                };
              }) as unknown as T[]
            );
          })
        );
      })
    );
  }
  public getUfoAllGamesProviderNavigationItemsForWeb$(): Observable<
    SecondaryFilterDTOInterface[]
  > {
    return this.getUfoAllGamesProviderNavigationItems$().pipe(
      map((providersWithHome) => {
        return providersWithHome.map(
          (provider): SecondaryFilterDTOInterface => {
            return {
              id: provider.filter,
              title: provider.name,
              isActive: provider.active,
              prefixIconSrc: provider.image
            };
          }
        );
      })
    );
  }
  public getFilterFromParams$(): Observable<{
    filter: string;
    providerFilter: string;
  }> {
    return this.route.queryParams.pipe(
      map((params: Params) => {
        return {
          filter: params?.filter || 'home',
          providerFilter: params?.providerFilter || 'home'
        };
      }),
      distinctUntilChanged()
    );
  }
  public addToFavorites$(miniGame: MiniGames) {
    return this.miniService
      .addToFavorites(miniGame.provider, miniGame.game_id)
      .pipe(tap(() => this.$triggerFavoritesSyncWithBackEnd.next()));
  }

  public removeFromFavorites$(miniGame: MiniGames) {
    return this.miniService
      .removeFromFavorites(miniGame.provider, miniGame.game_id)
      .pipe(tap(() => this.$triggerFavoritesSyncWithBackEnd.next()));
  }
  public toggleLocalFavoritesState(
    isFavorite: boolean,
    gameHash: string
  ): void {
    if (isFavorite) {
      this.set(
        'miniGamesLocalFavoritesList',
        JSON.stringify(
          (
            JSON.parse(
              this.get('miniGamesLocalFavoritesList') || '[]'
            ) as string[]
          ).filter((item) => item !== gameHash)
        )
      );
    } else {
      this.set(
        'miniGamesLocalFavoritesList',
        JSON.stringify(
          (
            JSON.parse(
              this.get('miniGamesLocalFavoritesList') || '[]'
            ) as string[]
          ).concat([gameHash])
        )
      );
    }
    nextTick(() => {
      this.$triggerFavoritesSyncWithBackEnd.next();
    });
  }

  public getFavoriteMiniGames$(): Observable<Map<string, MiniGames>> {
    if (!this.favoriteMiniGames$) {
      this.favoriteMiniGames$ = this.syncFavoriteMiniGames().pipe(
        shareReplay()
      );
    }
    return this.favoriteMiniGames$;
  }

  private syncFavoriteMiniGames(): Observable<Map<string, MiniGames>> {
    return this.$triggerFavoritesSyncWithBackEnd.asObservable().pipe(
      debounceTime(304),
      switchMap(() => this.miniService.getMiniGameFavorites$()),
      map((miniGames) => {
        return new Map<string, MiniGames>(
          miniGames
            .map((miniGame): [string, MiniGames] => {
              return [
                JSON.stringify({
                  provider: miniGame.provider,
                  ['game_id']: miniGame.game_id
                }),
                miniGame
              ];
            })
            .concat(
              (
                JSON.parse(
                  this.get('miniGamesLocalFavoritesList') || '[]'
                ) as string[]
              ).map((key) => [key, {} as MiniGames])
            )
        );
      }),
      catchError(() => of(new Map<string, MiniGames>()))
    );
  }

  public getSuitRunnerLatestCrashes$(reFetchAfter: number) {
    return timer(0, reFetchAfter * 1000).pipe(
      switchMap(() =>
        this.miniService.getSuitRunnerLatestCrashes().pipe(
          map((response) => {
            const multipliers = response?.map((item, index) => ({
              ...item,
              multiplier: Number(item.multiplier.toFixed(2)),
              isLast: index === 0
            }));
            return { Multipliers: multipliers };
          })
        )
      )
    );
  }

  public getSearchResult$(): Observable<[string, MiniGameSearch[]]> {
    return this.$searchValue.asObservable().pipe(
      debounceTime(307),
      switchMap((searchKey) =>
        this.miniService
          .searchMiniGames$(searchKey)
          .pipe(
            map(
              (searchedGames: MiniGameSearch[]): [string, MiniGameSearch[]] => [
                searchKey,
                searchedGames
              ]
            )
          )
      )
    );
  }
  private listenToLogout() {
    this.authFacade
      .isLoggedIn$()
      .pipe(
        distinctUntilChanged(),
        distinctUntilChanged((prev, cur) => {
          if (prev && !cur) {
            this.set('miniGamesLocalFavoritesList', '[]');
            nextTick(() => {
              this.$triggerFavoritesSyncWithBackEnd.next();
            });
          }
          return prev === cur;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
  private _filterMiniGamesOnCategory(
    filter: string,
    allMiniGames: MiniGames[]
  ): MiniGames[] {
    if (filter === 'home') {
      return allMiniGames;
    }
    if (filter === 'with-bonus') {
      return allMiniGames.filter((miniGame) =>
        miniGame.tags?.includes('bonus')
      );
    }
    if (filter === 'new-games') {
      return allMiniGames.filter((miniGame) => miniGame.tags?.includes('new'));
    }
    if (filter === 'favorite-games') {
      return allMiniGames;
      //is filtered in template using map
    }
    return allMiniGames;
  }
  private _filterMiniGamesOnProvider(
    allMiniGames: MiniGames[],
    providerFilter: string
  ): MiniGames[] {
    return allMiniGames.filter((miniGame) => {
      return miniGame.provider === providerFilter || providerFilter === 'home';
    });
  }
}
