export function isUrlAllowed(url: string, allowedDomains: string[] = []) {
  try {
    const parsedUrl = new URL(url);
    return allowedDomains.some(
      (allowedDomain: string) => parsedUrl.origin === allowedDomain
    );
  } catch (e) {
    return false;
  }
}
