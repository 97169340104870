import { Injectable } from '@angular/core';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProfileShowBalanceFacade } from '@crc/facade';

import {
  setBalanceVisible,
  setBalanceVisibleSuccess
} from '../actions/layout.actions';
import { State } from '../reducers/state';
import { selectHeaderIsBalanceVisible } from '../selectors/layout.selectors';

@Injectable()
export class LayoutEffects {
  resetCaptchaCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setBalanceVisible),
      withLatestFrom(this.store.select(selectHeaderIsBalanceVisible)),
      switchMap(([payload, state]) => {
        this.profileShowBalanceFacade.setToggleBalanceState(state);
        return [setBalanceVisibleSuccess()];
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<State>,
    private readonly profileShowBalanceFacade: ProfileShowBalanceFacade
  ) {}
}
