import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
type eventTypes =
  | 'formSubmit'
  | 'formRegister'
  | 'sendSMS'
  | 'loginAfterSuccess'
  | 'checkUsernameUniqueness'
  | string;
type eventValueTypes = void | unknown;
@Injectable({
  providedIn: 'root'
})
export class RegisterEventManagerService {
  private readonly formSubmit: EventEmitter<void> = new EventEmitter();
  private readonly formRegister: EventEmitter<void> = new EventEmitter();
  private readonly sendSMS: EventEmitter<void> = new EventEmitter();
  private readonly loginAfterSuccess: EventEmitter<void> = new EventEmitter();
  private readonly checkUsernameUniqueness: EventEmitter<void> =
    new EventEmitter();
  public emit$(key: string, value?: eventValueTypes): void {
    this?.[key]?.emit(value ?? void 0);
  }
  public get$<T = eventValueTypes>(key: eventTypes): Observable<T> {
    return this?.[key]?.asObservable<T>() ?? of(null);
  }
}
