<button
  [disabled]="disabled"
  type="button"
  *ngIf="time | async as time"
  class="timer"
  (click)="timerClicked()"
>
  <div
    *ngIf="!disabled || time !== timeAmount"
    class="timer__mask"
    [class.timer__mask-bonus]="bgColor === 'bonus'"
    [ngStyle]="{ width: (time / timeAmount) * 100 + '%' }"
  ></div>
  <img
    *ngIf="time < timeAmount"
    class="timer__img"
    src="/assets/icons/shapes/timer.svg"
    alt=""
  />
  <span *ngIf="time < timeAmount" class="timer__time">{{ time }}</span>
  <p *ngIf="time >= timeAmount" class="timer__text">{{ textInput }}</p>
</button>
