import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { combineLatest, first, Observable, race, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AccountFacade } from '../facade';

@Injectable({
  providedIn: 'root'
})
export class VerifiedGuard implements CanActivate {
  constructor(
    private readonly accountFacade: AccountFacade,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return combineLatest([
      race(
        this.accountFacade.isVerified$.pipe(take(1)),
        timer(800).pipe(map(() => this.accountFacade.isVerified()))
      ),
      this.accountFacade.getVerificationActive$()
    ]).pipe(
      first(),
      map(([status, isVerificationActive]) => {
        if (isVerificationActive) {
          return true;
        }
        if (status) {
          return this.router.createUrlTree(['/']);
        }
        return true;
      })
    );
  }
}
