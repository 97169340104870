import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  delayWhen,
  from,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
  timer
} from 'rxjs';
import { Store } from '@ngrx/store';

import { LoginOtpService } from '../services';
import { authActions } from '../store/actions';
import {
  OTPAuthResponseInterface,
  OTPSendResponseInterface,
  Restricted
} from '../entity';
import { SignedInFeatureState } from '../store/state';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AuthFacade } from './auth.facade';

@Injectable({
  providedIn: 'root'
})
export class LoginOtpFacade {
  private readonly $captchaActiveState: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly OTPActiveState$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  private readonly $restartTimer: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly $resetTimer: Subject<void> = new Subject<void>();
  private abortController: AbortController | null = null;
  public setCaptchaActiveState(state: boolean): void {
    this.$captchaActiveState.next(state);
  }
  public getCaptchaActiveState$(): Observable<boolean> {
    return this.$captchaActiveState.asObservable();
  }
  public getCaptchaActiveState(): boolean {
    return this.$captchaActiveState.getValue();
  }
  public getResetTimerState$(): Observable<void> {
    return this.$resetTimer.asObservable();
  }
  public triggerResetTimerState(): void {
    this.$resetTimer.next();
  }
  public getOTPState$(): Observable<boolean> {
    return this.OTPActiveState$.asObservable();
  }
  public setOTPState(state: boolean): void {
    this.OTPActiveState$.next(state);
  }
  public getOTPState(): boolean {
    return this.OTPActiveState$.getValue();
  }
  public setRestartTimer(state: boolean): void {
    this.$restartTimer.next(state);
  }
  public getRestartTimerState$(): Observable<boolean> {
    return this.$restartTimer.asObservable().pipe(
      switchMap((status) => {
        return status
          ? from([true, false]).pipe(
              delayWhen((status) => {
                return status ? of(null) : timer(0);
              })
            )
          : of(status);
      })
    );
  }
  public initiateOTPLogin$(
    formData
  ): Observable<OTPAuthResponseInterface | null> {
    return this.loginOtpService
      .initiateAuth$({
        login: formData.userName || formData.login,
        loginCode: formData.passwordOTP || formData.smsCode,
        password: ''
      })
      .pipe(
        tap((payload: OTPAuthResponseInterface | null) => {
          if (payload?.success && payload?.data) {
            this.store$.dispatch(
              authActions.signInPostCheck({
                payload
              })
            );
          } else if (String(payload) === Restricted.BLOCKED_BY_RS_GE) {
            this.authFacade.setRestrictedStatus(Restricted.BLOCKED_BY_RS_GE);
          }else if (String(payload) === Restricted.BELOW_LEGAL_AGE) {
            this.authFacade.setRestrictedStatus(Restricted.BELOW_LEGAL_AGE);
          }
        })
      );
  }
  public sendSMS$(
    userName: string
  ): Observable<OTPSendResponseInterface | null> {
    return this.loginOtpService.sendSMS$(userName);
  }
  createAbortController(): void {
    this.abortController = new AbortController();
  }

  abortAndNullifyAbortController(): void {
    this.abortController?.abort();
    this.abortController = null;
  }

  getOneTimePasswordLogic$(control: AbstractControl): Observable<string> {
    return from(
      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: this.abortController.signal
      } as CredentialRequestOptions)
    ).pipe(
      map((OTP) => {
        control.reset();
        control.setValue((OTP as any)?.code ?? '', {
          onlySelf: true,
          emitEvent: false
        });
        control.updateValueAndValidity();
        return (OTP as any)?.code ?? '';
      }),
      catchError((_) => {
        return 'T';
      })
    );
  }
  constructor(
    private readonly store$: Store<SignedInFeatureState>,
    private readonly loginOtpService: LoginOtpService,
    private readonly authFacade: AuthFacade
  ) {}
}
