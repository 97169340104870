import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  addQueryParamToUrl,
  CookieService,
  EnvironmentService
} from '@crc/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { PersonalData } from '../../../auth';
import { UserInfoResponse } from '../entity/sport.interface';

@Injectable({
  providedIn: 'root'
})
export class SportFacade {
  public readonly SPORT_META_DATA_KEY_FOR_COOKIE = 'sportMetaData';
  sportUrl = this.environmentService.config.sportUrl;
  sportVirtualUrl = this.environmentService.config.sportVirtualUrl;
  private $isOpen = new BehaviorSubject<boolean>(false);
  private $iframeSrc: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  private readonly $customHeaderContentMob = new BehaviorSubject<string | null>(
    null
  );
  private readonly triggerNavigationInSport$ = new BehaviorSubject<
    string | number | null
  >(null);

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cookieService: CookieService
  ) {}

  getIframeSrc$(): Observable<string> {
    return this.$iframeSrc.asObservable();
  }

  setIframeSrc(src: string): void {
    this.$iframeSrc.next(src);
  }

  modifyIframeUrl(
    urlPrefix: string,
    lang: string,
    versionId: string,
    isVirtual = false
  ) {
    this.$iframeSrc.next(
      addQueryParamToUrl(
        (isVirtual ? this.sportVirtualUrl : this.sportUrl) + urlPrefix,
        [
          { key: 'lang', value: lang },
          { key: 'v', value: versionId }
        ]
      )
    );
  }

  redirectToVerification() {
    this.router.navigate(['/profile/verification']).then();
  }

  redirectToVirtualSport() {
    this.router.navigate(['/virtual']).then();
  }

  redirectToFantasy() {
    this.router.navigate(['/free-sports/fantasy-games']).then();
  }

  mapUserInfo(userInfo: PersonalData): UserInfoResponse {
    return {
      currencyCode: userInfo.currencyCode,
      email: userInfo.email,
      balance: userInfo.accountBalance,
      firstName: userInfo.firstName,
      language: userInfo.language,
      lastBalanceCheck: userInfo.lastBalanceCheck,
      lastName: userInfo.lastName,
      login: userInfo.login,
      mobile: userInfo.mobile,
      pinCode: userInfo.pinCode,
      userId: userInfo.userId
    };
  }

  public setCustomHeaderContentMob(content: string | null): void {
    this.$customHeaderContentMob.next(content);
  }

  public getCustomHeaderContentMob$(): Observable<string | null> {
    return this.$customHeaderContentMob.asObservable();
  }

  public getNavigationTriggerInSport$(): Observable<string | number | null> {
    return this.triggerNavigationInSport$.asObservable();
  }

  public setNavigationTriggerInSport$(value: number | string | null): void {
    this.triggerNavigationInSport$.next(value);
  }

  public getSportIsOpen$(): Observable<boolean> {
    return this.$isOpen.asObservable();
  }

  public setSportIsOpen(state: boolean): void {
    this.$isOpen.next(state);
  }
  public handleSynchronizeToggleTicketPosition(): void {
    window.localStorage.setItem(
      'sportMetaData',
      this.cookieService.get(this.SPORT_META_DATA_KEY_FOR_COOKIE)
    );
    this.cookieService.delete(this.SPORT_META_DATA_KEY_FOR_COOKIE);
  }
}
