export const TransactionsStatus = {
  statuses: {
    INI: 'transaction.pending',
    IPR: 'transaction.pending',
    SCS: 'transaction.success',
    DEC: 'transaction.rejected',
    FLD: 'transaction.rejected',
    ERR: 'transaction.rejected'
  }
};

export const TransactionCurrencies = {
  currencies: {
    GEL: '₾',
    USD: '$',
    EUR: '€'
  }
};
