import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { egtOdometerCount } from '../../../jackpot';
import { JackpotInstance } from '../../entity';
import {
  EgtDigitalOdometerConfig,
  EgtDigitalOdometerData
} from '../../entity/odometer.interface';

@Component({
  selector: 'crc-egt-digital-odometer-container',
  templateUrl: './egt-digital-odometer-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EgtDigitalOdometerContainerComponent {
  readonly EGT_ODOMETER_COUNT = egtOdometerCount;

  @Input() disableLogo: boolean;
  @Input() vertical: boolean;
  @Input() type: string;
  @Input() odometerConfig: EgtDigitalOdometerConfig[];
  @Input() jackpot: JackpotInstance[];
  @Input() odometerData: EgtDigitalOdometerData[];
  @Input() showPopupInfo = true;
  @Input() isMainPage: boolean;

  @Output() refresh = new EventEmitter();
}
