import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginPopupFacade {
  private readonly logInCanceled$ = new Subject<boolean>();
  private readonly loginResult$ = new Subject<boolean>();
  private readonly showLoginPopup$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private closeLoginModal() {
    this.showLoginPopup$.next(false);
  }

  getShowLoginPopupState$(): Observable<boolean> {
    return this.showLoginPopup$.asObservable();
  }

  getShowLoginPopupState(): boolean {
    return this.showLoginPopup$.getValue();
  }

  openLoginPopup() {
    this.showLoginPopup$.next(true);
  }

  getLoginResult$() {
    return this.loginResult$.asObservable();
  }

  getLogInCanceled$() {
    return this.logInCanceled$.asObservable();
  }

  loginSuccess() {
    this.loginResult$.next(true);
    this.closeLoginModal();
  }

  loginCancel() {
    this.loginResult$.next(false);
    this.logInCanceled$.next(true);
    this.closeLoginModal();
  }
}
