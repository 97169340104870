import { Injectable } from '@angular/core';
import { of, switchMap, tap } from 'rxjs';

import { FavoriteSlotGame } from '../entity';
import { AccountFacade } from '../../../auth';
import { SlotsFavoriteFacade } from './slots-favorite.facade';
import { SlotsService } from '../services/slots.service';

@Injectable()
export class SlotsFavoriteDataFacade {
  constructor(
    private accountFacade: AccountFacade,
    private slotsService: SlotsService,
    private slotsFavoriteFacade: SlotsFavoriteFacade
  ) {
    this.accountFacade.customerId$
      .pipe(
        switchMap((customerId) =>
          customerId ? this.slotsService.getFavorites() : of([])
        ),
        tap((games) => {
          this.slotsFavoriteFacade.updateFavorites(games as FavoriteSlotGame[]);
        })
      )
      .subscribe();
  }
}
