import {
  Component,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  ViewChild
} from '@angular/core';
import { InputBaseComponent } from '../input-base/input-base.component';

@Component({
  selector: 'crc-shared-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  exportAs: 'public_content'
})
export class InputComponent extends InputBaseComponent<string> {
  @Input() type: string;
  @Input() placeholder: string;
  @Input() mask: string;
  @Input() autoComplete: string;
  @Input() hideIcon: boolean;
  @Input() name: string;
  @Input() material: boolean;
  @Input() currency: boolean;
  @Input() touched: boolean;
  @Input() tooltipToggle: boolean;
  @Input() errorsTexts: { type: string; text: string }[];
  @Input() public prefix = '';
  @Input() protected id: string | number;
  @Output() mouseOver: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() mouseOut: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() eyeButtonClick: EventEmitter<void> = new EventEmitter<void>();
  hideToggled: boolean;
  @ViewChild('inputElement', {
    read: ElementRef<HTMLInputElement>
  })
  public inputRef: ElementRef<HTMLInputElement>;
}
