import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LandingsFacade {
  private readonly showStreamPopupState$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private readonly $reload: Subject<boolean> = new Subject<boolean>();

  setReloadState(value: boolean) {
    this.$reload.next(value);
  }
  getReloadState(): Observable<boolean> {
    return this.$reload.asObservable();
  }
  getShowStreamPopupState$(): Observable<boolean> {
    return this.showStreamPopupState$.asObservable();
  }

  setShowStreamPopupState$(state: boolean) {
    this.showStreamPopupState$.next(state);
  }

  getUrlFromRoutingOrQuery(params: Params, queryParams: Params) {
    const landingId = params['landingId'];
    if (!landingId) {
      return queryParams['url'];
    }

    const hashingType = params['hashingType'];
    const landingType = params['landingType'];
    const suffix = hashingType === 'hashed' ? '/#' : '';
    return `https://${landingType}.crocobet.com/${params['landingId']}${suffix}`;
  }
}
