export interface ScriptLoadInterface {
  status: ScriptLoadStatusEnum;
  loaded: boolean;
  script: string;
}

export enum ScriptLoadStatusEnum {
  Loaded = 'Loaded',
  NotLoaded = 'Not Loaded',
  AlreadyLoaded = 'Already Loaded'
}
