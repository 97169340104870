import { Injectable } from '@angular/core';
import { Language } from '@crc/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { FaqService } from '../services/faq.service';
import { FaqCategory, FaqContent, FaqSelection } from '../entity/faq.interface';

@Injectable({ providedIn: 'root' })
export class FaqFacade {
  private readonly showFaqDialogState$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly selectedCategory$: BehaviorSubject<FaqSelection> =
    new BehaviorSubject<FaqSelection>({} as FaqSelection);

  constructor(private readonly faqService: FaqService) {}

  getShowFaqDialogState$(): Observable<boolean> {
    return this.showFaqDialogState$.asObservable();
  }

  setShowFaqDialogState(state: boolean) {
    this.showFaqDialogState$.next(state);
  }

  selectFaq(category: FaqSelection) {
    this.selectedCategory$.next({ ...category });
  }

  getFaqSelection(): Observable<FaqSelection> {
    return this.selectedCategory$.asObservable();
  }

  getFaqCategories$(lang: Language): Observable<FaqCategory[]> {
    return this.faqService.getFaqCategories$(lang);
  }

  getFaqContent$(
    categoryId: string,
    subCategoryId: string,
    lang: Language
  ): Observable<FaqContent[]> {
    return this.faqService.getContent$(categoryId, subCategoryId, lang);
  }
}
