import { Injectable } from '@angular/core';

import {
  Footer,
  FooterCards,
  FooterLang,
  FooterMessenger
} from '../entity/footer.interface';
import {
  FOOTER_CARDS,
  FOOTER_LICENSE_ITEMS,
  FULL_CONTACT_MENU,
  HELPER_CONTACT_MENU,
  LANG_MENU,
  MENU_ITEMS,
  MESSENGER_MENU
} from '../entity/footer-menu-items';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  switchMap
} from 'rxjs';
import { map } from 'rxjs/operators';
import { LanguageFacade } from '@crc/shared';
import { NgSelectItem } from '@crc/components';
import { DomSanitizer } from '@angular/platform-browser';
import { PromotionsFacade, PromotionsKeys } from '../../../features';

@Injectable({
  providedIn: 'root'
})
export class FooterFacade {
  private readonly menuItems: Footer[] = MENU_ITEMS;
  private readonly langMenu: FooterLang[] = LANG_MENU;
  private readonly messengerMenu: FooterMessenger[] = MESSENGER_MENU;
  private readonly fullContactMenu: FooterMessenger[] = FULL_CONTACT_MENU;
  private readonly helperContactMenu: FooterMessenger[] = HELPER_CONTACT_MENU;
  private readonly footerCards: FooterCards[] = FOOTER_CARDS;
  private readonly footerLicenseItems: FooterCards[] = FOOTER_LICENSE_ITEMS;
  private readonly $hasNoViber = new Subject<boolean>();
  private readonly $isLinkBeingOpened = new BehaviorSubject<boolean>(false);
  constructor(
    private readonly languageFacade: LanguageFacade,
    private readonly sanitizer: DomSanitizer,
    private readonly promotionService: PromotionsFacade
  ) {}

  turkishLangState() {
    return this.promotionService.isPromoActive(PromotionsKeys.turkishLangKey);
  }

  getMenuItems(): Footer[] {
    return this.menuItems;
  }

  getLangItems(): FooterLang[] {
    return this.langMenu;
  }

  getMessengerMenu(): FooterMessenger[] {
    return this.messengerMenu;
  }

  getFullContactMenu(): FooterMessenger[] {
    return this.fullContactMenu;
  }

  getHelperContactMenu(): FooterMessenger[] {
    return this.helperContactMenu;
  }

  getFooterCards(): FooterCards[] {
    return this.footerCards;
  }

  getFooterLicenseItems(): FooterCards[] {
    return this.footerLicenseItems;
  }

  getHasNoViber(): Observable<boolean> {
    return this.$hasNoViber.asObservable();
  }

  clickOnLink(link: string) {
    if (this.$isLinkBeingOpened.value && link.includes('viber://')) {
      return;
    }

    this.$isLinkBeingOpened.next(true);
    this.checkViberInstalled(link);
    setTimeout(() => this.closeTooltip(), 2000);
  }

  checkViberInstalled(link: string) {
    let focus = true;
    const viberUri = 'viber://pa?chatURI=CROCOBETcom';

    if (link.includes('viber://')) {
      location.href = viberUri;

      window.addEventListener('focus', function (): void {
        focus = true;
      });

      window.addEventListener('blur', function (): void {
        focus = false;
      });

      setTimeout(() => {
        if (focus) {
          this.$hasNoViber.next(true);
        } else {
          location.href;
        }
      }, 600);
    } else {
      this.openLink(link);
    }
  }

  openLink(link: string) {
    window.open(link, '_blank');
  }

  closeTooltip() {
    this.$hasNoViber.next(false);
    this.$isLinkBeingOpened.next(false);
  }

  public getLangItems$(): Observable<NgSelectItem[]> {
    return of(this.getLangItems()).pipe(
      switchMap((items) =>
        this.languageFacade.current$.pipe(
          map((current) => {
            return items.map((i) => {
              return { ...i, active: i.key === current };
            });
          }),
          map((items) => {
            return items.map((item) => {
              return {
                id: item.key,
                value: item.title,
                icon: item.img,
                key: item.key,
                disabled: item.active,
                name: item.name
              };
            });
          })
        )
      )
    );
  }
}
