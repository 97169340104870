<div class="timeline">
  <span
    *ngFor="let bullet of counter(numberOfBullets); let i = index"
    [class.timeline__bullets--active]="i < numberOfActive"
    class="timeline__bullets"
  ></span>
  <div class="timeline__line">
    <div
      class="timeline__line__active"
      [ngStyle]="{
        width: ((numberOfActive - 1) / (numberOfBullets - 1)) * 100 + '%'
      }"
    ></div>
  </div>
</div>
