import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';

import { ChangePasswordService } from '../services';
import {
  ChangePasswordModel,
  ChangePasswordResponseModel,
  CustomErrorDtoInterface
} from '../entity';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { CustomValidators, matchControlsValidator } from '@crc/components';
import { handleRemovingRequiredError, nextTick } from '@crc/shared';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordFacade {
  constructor(
    private readonly changePasswordService: ChangePasswordService,
    private readonly fb: UntypedFormBuilder
  ) {}
  private readonly $customErrorDto: BehaviorSubject<CustomErrorDtoInterface> =
    new BehaviorSubject({
      message: 'change_password.fail',
      control: ''
    });
  private readonly $timerResetTrigger: Subject<{ status: boolean }> =
    new Subject();
  public getCustomErrorDto$(): Observable<CustomErrorDtoInterface> {
    return this.$customErrorDto.asObservable();
  }
  public setCustomErrorDto(res: CustomErrorDtoInterface): void {
    return void this.$customErrorDto.next(res);
  }
  public getTimerResetTrigger$(): Observable<{ status: boolean }> {
    return this.$timerResetTrigger.asObservable();
  }
  public setTimerResetTrigger(status: { status: boolean }): void {
    return void this.$timerResetTrigger.next(status);
  }

  buildForm() {
    const passwordValidations: ValidatorFn[] = [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(20),
      CustomValidators.patternValidator(/\d/, { number: true }),
      CustomValidators.patternValidator(/[A-Z]/, { upperCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { lowerCase: true }),
      CustomValidators.patternValidator(/^[ -~]+$/, {
        latin: true
      })
    ];

    const form = this.fb.group(
      {
        newValue: new UntypedFormControl('', passwordValidations),
        oldValue: new UntypedFormControl('', [Validators.required]),
        confirmNewValue: new UntypedFormControl('', Validators.required),
        verificationCode: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.pattern(/^[0-9]+$/)
        ])
      },
      {
        validators: [
          matchControlsValidator(
            { controlName: 'newValue', addError: false },
            {
              controlName: 'confirmNewValue',
              addError: true
            }
          )
        ]
      }
    );

    return form;
  }
  public changePasswordNew(
    body: ChangePasswordModel
  ): Observable<ChangePasswordResponseModel> {
    return this.changePasswordService.changePasswordNew(body);
  }
  public sendSms$(): Observable<{
    status: unknown | string | null;
  }> {
    return this.changePasswordService.sendSmsToUser$().pipe(
      tap((res) => {
        if (res.status !== 'SUCCESS') {
          const errorCode = res.status?.['payload']?.['errorCode'];
          if (errorCode === 'RATE_LIMIT') {
            this.setCustomErrorDto({
              control: 'verificationCode',
              message: `change_password.${errorCode.toLowerCase()}`,
              retryAfter: res.status?.['payload']?.['retryAfter'] || 60
            });
          } else {
            this.setCustomErrorDto({
              control: 'verificationCode',
              message: ''
            });
          }
          this.setTimerResetTrigger({
            status: true
          });
          nextTick(() => {
            this.setTimerResetTrigger({
              status: false
            });
          });
        }
      })
    );
  }
  public handleRateLimitErrorRemoval(control: AbstractControl): void {
    setTimeout(() => {
      control.reset();
      control.setValue('');
      control.markAsUntouched();
      handleRemovingRequiredError(control);
    }, 3000);
  }
}
