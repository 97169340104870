import { EnvironmentBase } from './environment.base';

export const environment = {
  ...EnvironmentBase,
  sportUrl: `https://msport.crocobet.com`,
  sportVirtualUrl: `https://msport.crocobet.com`,
  widgetsUrl: 'https://widget-m.crocobet.com/',
  fastTrackApi: 'https://cms.crocobet.com/marketing/events',
  production: true
};
