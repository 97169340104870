export const freeSportsIcon = {
  data:
    '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">\n' +
    '<path d="M3.32603 12.2167L2.05937 10.95C1.54271 10.4334 1.54271 9.58335 2.05937 9.06668L3.32603 7.8C3.5427 7.58333 3.7177 7.15833 3.7177 6.85833V5.06664C3.7177 4.3333 4.31771 3.73333 5.05104 3.73333H6.8427C7.1427 3.73333 7.5677 3.55835 7.78437 3.34168L9.05103 2.075C9.5677 1.55833 10.4177 1.55833 10.9344 2.075L12.2011 3.34168C12.4177 3.55835 12.8427 3.73333 13.1427 3.73333H14.9344C15.6677 3.73333 16.2677 4.3333 16.2677 5.06664V6.85833C16.2677 7.15833 16.4427 7.58333 16.6594 7.8L17.926 9.06668C18.4427 9.58335 18.4427 10.4334 17.926 10.95L16.6594 12.2167C16.4427 12.4334 16.2677 12.8584 16.2677 13.1584V14.95C16.2677 15.6833 15.6677 16.2834 14.9344 16.2834H13.1427C12.8427 16.2834 12.4177 16.4583 12.2011 16.675L10.9344 17.9417C10.4177 18.4584 9.5677 18.4584 9.05103 17.9417L7.78437 16.675C7.5677 16.4583 7.1427 16.2834 6.8427 16.2834H5.05104C4.31771 16.2834 3.7177 15.6833 3.7177 14.95V13.1584C3.7177 12.85 3.5427 12.425 3.32603 12.2167Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M5.49783 13.8129L4.21094 10.9219L6.40411 9.94561L6.74368 10.7085L5.42604 11.295L5.55338 11.5811L6.84068 11.008L7.18025 11.7709L5.89295 12.3439L6.37336 13.4231L5.49783 13.8129Z" fill="#ffffff"/>\n' +
    '<path d="M9.65358 11.963L8.84965 11.2303L8.53325 11.3711L8.93842 12.2814L8.06288 12.6711L6.77599 9.78008L8.31901 9.09322C8.47216 9.02505 8.62259 8.98579 8.77032 8.97542C8.92093 8.96378 9.06031 8.98136 9.18848 9.02817C9.31824 9.07081 9.43534 9.14332 9.53977 9.24571C9.64581 9.34391 9.73291 9.46959 9.80108 9.62274C9.97858 10.0215 9.93512 10.3905 9.67069 10.7298L10.6505 11.5193L9.65358 11.963ZM8.7268 10.371C8.80482 10.3363 8.86292 10.2879 8.9011 10.2259C8.94346 10.1655 8.94663 10.0948 8.91061 10.0139C8.87717 9.9388 8.82255 9.89387 8.74675 9.87914C8.67641 9.8689 8.60224 9.88115 8.52422 9.91588L7.99109 10.1532L8.19368 10.6083L8.7268 10.371Z" fill="#ffffff"/>\n' +
    '<path d="M11.0004 11.3635L9.71352 8.47248L11.9067 7.49622L12.2463 8.25906L10.9286 8.84559L11.056 9.13166L12.3433 8.55863L12.6828 9.32148L11.3955 9.8945L11.5364 10.2109L12.854 9.62438L13.1936 10.3872L11.0004 11.3635Z" fill="#ffffff"/>\n' +
    '<path d="M13.5824 10.2142L12.2955 7.32315L14.4887 6.34689L14.8282 7.10973L13.5106 7.69626L13.6379 7.98233L14.9252 7.4093L15.2648 8.17215L13.9775 8.74517L14.1184 9.06158L15.436 8.47505L15.7756 9.23789L13.5824 10.2142Z" fill="#ffffff"/>\n' +
    '  </svg>',
  name: 'free-sports' as const
};
