import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'crc-m-masked-input',
  templateUrl: './masked-input.component.html',
  styleUrls: ['./masked-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaskedInputComponent implements OnInit {
  @Input() passcode: string;
  @Input() invalidPasscode: boolean;
  @Input() numberOfItems: number;
  iterable: number[] = [];

  ngOnInit() {
    this.iterable = Array.from(Array(this.numberOfItems).keys());
  }
}
