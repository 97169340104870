import { createReducer, on } from '@ngrx/store';
import { getRouletteStatsSuccess } from './roulette.actions';

export interface IRouletteStatResponse {
  gameId: string;
  providerId: string;
  stats: string[];
}

export interface IRouletteStatItem {
  number: number | number[];
  color: string;
}
export interface IRouletteStat {
  gameId: string;
  providerId: string;
  isDouble: boolean;
  stats: IRouletteStatItem[];
}

export interface RouletteState {
  stats: IRouletteStat[];
}

export const initialState: RouletteState = {
  stats: []
};

export const rouletteReducer = createReducer(
  initialState,
  on(getRouletteStatsSuccess, (state, { payload }) => {
    return {
      ...state,
      stats: payload
    };
  })
);
