import { Injectable } from '@angular/core';

import { MENU_ITEMS } from '../constants/menu-items';
import { MenuItem } from '../entity/menu-items.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuItemsFacade {
  private readonly menuItems: Array<MenuItem> = MENU_ITEMS;

  private readonly $isLoading = new BehaviorSubject<boolean>(false);
  private readonly $menuUpdateTrigger: Subject<string> = new Subject();
  getLoadingState$(): Observable<boolean> {
    return this.$isLoading.asObservable();
  }
  setLoadingState$(state: boolean) {
    this.$isLoading.next(state);
  }

  getMenuItems(): MenuItem[] {
    return this.menuItems;
  }
  public triggerMenuUpdate(route: string): void {
    this.$menuUpdateTrigger.next(route);
  }
  public getMenuUpdateTrigger$(): Observable<string> {
    return this.$menuUpdateTrigger.asObservable();
  }
}
