import { Directive, HostListener, inject } from '@angular/core';
import { FantasyGamesFacade } from './';
import { Router } from '@angular/router';
import { distinctUntilChanged, Observable } from 'rxjs';
import { Provider } from '../shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthFacade } from '../../auth';
@UntilDestroy()
@Directive()
export class FantasyPlayViewBase {
  private readonly fantasyGamesFacade: FantasyGamesFacade =
    inject(FantasyGamesFacade);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  protected readonly router: Router = inject(Router);
  protected readonly heroPickProviderData$: Observable<Provider> =
    this.fantasyGamesFacade.heroPickProviderData$;
  protected readonly fantasyFootballGameData$ =
    this.fantasyGamesFacade.getFantasyFootballGameData$();
  protected readonly $fantasyIsLoaded = this.fantasyGamesFacade.$fantasyIsLoaded;
  @HostListener('window:message', ['$event'])
  async onMessage(event: MessageEvent) {
    if (event?.data === 'hardClose') {
      // await this.router.navigateByUrl('');
    }
  }
  constructor() {
    this.authFacade
      .isLoggedIn$()
      .pipe(
        distinctUntilChanged((prev, cur) => {
          if (prev && !cur) {
            this.router.navigateByUrl('').then().catch();
          }
          return prev === cur;
        }),
        untilDestroyed(this)
      )
      .subscribe();
    // this.actions
    //   .pipe(ofType(authActions.signInSuccess), untilDestroyed(this))
    //   .subscribe(() => {
    //     console.log('logout');
    //   });
  }
}
