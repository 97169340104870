import { InjectionToken } from '@angular/core';
import { Platform } from '../types/platform';
import { IMatomoConfiguration } from '../utils';
import { EnvNameEnumUnion } from './helper/utils';

export interface EnvironmentConfig {
  apiUrl: string;
  authApiUrl: string;
  authCookieName: string;
  cmsApiUrl: string;
  cardsApiUrl: string;
  cookieDomain: string;
  identomatUrl: string;
  platform: Platform;
  production: boolean;
  appVersion: string;
  widgetsUrl: string;
  sportUrl: string;
  sportVirtualUrl?: string;
  sportBookParticipants?: string;
  enableAuthTestApi?: boolean;
  fastTrackApi?: string | boolean;
  pragmaticLiveFeedUrl: string;
  pragmaticLiveCasinoId: string;
  matomoConfiguration: IMatomoConfiguration;
  handleCrocobetUrl: string;
  sentryDsn?: string;
  environment: EnvNameEnumUnion;
}

export const ENV_CONFIG = new InjectionToken<EnvironmentConfig>('ENV_CONFIG');
