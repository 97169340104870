<div #registerRef class="wrapper">
  <ng-container *ngIf="registrationStep !== 'finished'; else resultRef">
    <div class="register">
      <p class="register__title">
        {{
          (registrationStep === 'personalData'
            ? 'personal_information'
            : 'account_details'
          ) | translate
        }}
      </p>
      <form
        *ngIf="registrationStep === 'personalData'"
        [formGroup]="personalInfo"
      >
        <crc-m-personal-info-presentation
          (submit)="onRegisterStepChange('accountData')"
          [cities]="cities"
          [cdaShowError]="cdaShowError"
          [cdaNationalityShowError]="cdaNationalityShowError"
          [countries]="countries"
          [days]="days"
          [months]="months"
          [years]="years"
        >
        </crc-m-personal-info-presentation>
      </form>
      <form
        *ngIf="registrationStep === 'accountData'"
        (ngSubmit)="onRegisterSubmit()"
        [formGroup]="accountInfo"
      >
        <crc-m-account-info-presentation
          (sendSms)="onSendSms()"
          (submit)="onRegisterSubmit()"
          (policyClicked)="
            policyClicked.emit([[$event, personalInfo], registerRef.scrollTop])
          "
          [cachedForm]="cachedForm"
          [selectedCountry]="this.cachedPersonalInfoRawValue?.['countryCode'] || personalInfo.get('countryCode').value"
          (triggerScroll)="scrollIfCached()"
          [isLoading]="$isLoading | async"
          [rateLimitErrorDataSource]="rateLimitErrorDataSource"
        >
        </crc-m-account-info-presentation>
      </form>
    </div>
    <div class="timeline-wrapper">
      <crc-m-timeline
        [numberOfBullets]="2"
        [numberOfActive]="registrationStep === 'personalData' ? 1 : 2"
      >
      </crc-m-timeline>
    </div>
  </ng-container>

  <ng-template #resultRef>
    <div class="result">
      <crc-m-result
        (buttonClicked)="resultClosed.emit()"
        [textBody]="'register.success' | translate"
        [buttonTitle]="'verification' | translate"
        [success]="true"
      >
      </crc-m-result>
    </div>
  </ng-template>
</div>
