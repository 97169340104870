import { EgtJackpot, OdometerData, offsetLevels } from '@crc/components';

export function processJackpot(
  jackpot: EgtJackpot,
  dataRefreshRate: number
): OdometerData[] {
  const initialStartNumberLevelI: number = Math.max(
    jackpot.currentLevelI - offsetLevels.LEVEL_ONE_OFFSET,
    0
  );
  const startNumberLevelI: number = initialStartNumberLevelI;
  const endNumberLevelI: number = jackpot.currentLevelI;
  const digitCountLevelI = 10;

  const odometerDataLevelI: OdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelI,
    startNumber: startNumberLevelI,
    endNumber: endNumberLevelI
  };

  const initialStartNumberLevelII: number = Math.max(
    jackpot.currentLevelII - offsetLevels.LEVEL_TWO_OFFSET,
    0
  );
  const startNumberLevelII: number = initialStartNumberLevelII;
  const endNumberLevelII: number = jackpot.currentLevelII;
  const digitCountLevelII = 10;

  const odometerDataLevelII: OdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelII,
    startNumber: startNumberLevelII,
    endNumber: endNumberLevelII
  };

  const initialStartNumberLevelIII: number = Math.max(
    jackpot.currentLevelIII - offsetLevels.LEVEL_THREE_OFFSET,
    0
  );
  const startNumberLevelIII: number = initialStartNumberLevelIII;
  const endNumberLevelIII: number = jackpot.currentLevelIII;
  const digitCountLevelIII = 10;

  const odometerDataLevelIII: OdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelIII,
    startNumber: startNumberLevelIII,
    endNumber: endNumberLevelIII
  };

  const initialStartNumberLevelIV: number = Math.max(
    jackpot.currentLevelIV - offsetLevels.LEVEL_FOUR_OFFSET,
    0
  );
  const startNumberLevelIV: number = initialStartNumberLevelIV;
  const endNumberLevelIV: number = jackpot.currentLevelIV;
  const digitCountLevelIIII = 10;

  const odometerDataLevelIV: OdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelIIII,
    startNumber: startNumberLevelIV,
    endNumber: endNumberLevelIV
  };

  const newOdometerData: Array<OdometerData> = [];
  newOdometerData.push(odometerDataLevelI);
  newOdometerData.push(odometerDataLevelII);
  newOdometerData.push(odometerDataLevelIII);
  newOdometerData.push(odometerDataLevelIV);
  return newOdometerData;
}
