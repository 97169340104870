import { Injectable } from '@angular/core';
import { Script } from '../interfaces/script-loader.interface';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScriptLoaderService {
  private scripts: { [name: string]: Script } = {};

  public isScriptLoaded(name: string): boolean {
    return this.scripts[name]?.isLoaded === true;
  }

  public loadScript(name: string, src: string): Observable<void> {
    if (this.scripts[name]) {
      const existingScript = this.scripts[name];
      if (existingScript.src === src) {
        return existingScript.load$.asObservable();
      }
    }

    this.scripts[name] = {
      isLoaded: false,
      src,
      callbacks: [],
      load$: new ReplaySubject<void>(1)
    };

    const scriptElement = document.createElement('script');

    scriptElement.src = src;
    scriptElement.async = true;
    scriptElement.onload = () => {
      this.scripts[name].load$.next();
      this.scripts[name].load$.complete();
      this.executeCallbacks(name);
    };

    scriptElement.onerror = () => this.scripts[name].load$.error('Error loading script');
    document.body.appendChild(scriptElement);

    return this.scripts[name].load$.asObservable();
  }

  public getScriptLoadInstance(name: string): Observable<void> {
    if (!this.scripts[name]) {
      this.initializeDefaultScriptLoading(name);
    }

    return this.scripts[name]?.load$.asObservable();
  }

  public addCallback<T extends unknown[]>(name: string, callback: (...args: unknown[]) => void, ...params: T): void {
    if (!this.scripts[name]) {
      this.initializeDefaultScriptLoading(name);
    }
    this.scripts[name].callbacks.push({ callback, params });
  }

  private executeCallbacks(src: string): void {
    if (this.scripts[src]?.callbacks) {
      this.scripts[src]?.callbacks.forEach(({ callback, params }) => callback(...params));
      this.scripts[src].callbacks = [];
    }
  }

  private initializeDefaultScriptLoading(name: string): void {
    this.scripts[name] = {
      isLoaded: false,
      src: '',
      callbacks: [],
      load$: new ReplaySubject<void>(1)
    };
  }
}
