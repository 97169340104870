import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AuthFacade,
  CaptchaFacade,
  LoginBasePresentationComponent,
  LoginPopupFacade,
  ResetPasswordFacade
} from '@crc/facade';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { LoginOtpFacade } from '@crc/facade';
import { handleSettingRequiredError } from '@crc/shared';
@Component({
  selector: 'crc-m-login-presentation',
  templateUrl: './login-presentation.component.html',
  styleUrls: ['./login-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPresentationComponent extends LoginBasePresentationComponent {
  passwordVisibility = false;
  constructor(
    authFacade: AuthFacade,
    loginGuardFacade: LoginPopupFacade,
    captchaFacade: CaptchaFacade,
    resetPasswordFacade: ResetPasswordFacade,
    fb: UntypedFormBuilder,
    private readonly loginOTPFacade: LoginOtpFacade
  ) {
    super(authFacade, loginGuardFacade, captchaFacade, resetPasswordFacade, fb);
    // authFacade
    //   .getCustomerId()
    //   .pipe(
    //     untilDestroyed(this),
    //     distinctUntilChanged(),
    //     filter((result) => !!result),
    //     tap((_) => {
    //       this.loginOTPFacade.createAbortController();
    //     }),
    //     mergeMap((_result) => {
    //       return this.loginOTPFacade.getOneTimePasswordLogic$(
    //         this.form.get('smsCode')
    //       );
    //     })
    //   )
    //   .subscribe();
  }
  // ngOnDestroy() {
  //   this.loginOTPFacade.abortAndNullifyAbortController();
  // }

  changeInputType() {
    this.passwordVisibility = !this.passwordVisibility;
  }
  public handleOtpClick(control: AbstractControl): void {
    control.valid
      ? this.switchedLoginModeToOtp.emit({
          userName: control.value
        })
      : (() => {
          control.markAsTouched();
          handleSettingRequiredError(control);
        })();
  }
}
