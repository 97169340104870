import { Injectable } from '@angular/core';

import { StorageService } from '@crc/shared';

import { SignedInResponse, UserDocument } from '../entity';

@Injectable({
  providedIn: 'root'
})
export class AuthStorage extends StorageService<'loginData' | 'documentData'> {
  constructor() {
    super();
  }
  setLoginData(data: SignedInResponse) {
    this.set('loginData', JSON.stringify(data));
  }

  clearLoginData() {
    this.clearItem('loginData');
    this.clearDocumentData();
  }

  getLoginData(): string | null {
    return this.get('loginData');
  }

  setDocumentData(data: UserDocument) {
    this.set('documentData', JSON.stringify(data));
  }

  getDocumentData(): UserDocument {
    return JSON.parse(this.get('documentData') || '{}') as UserDocument;
  }

  private clearDocumentData() {
    this.clearItem('documentData');
  }
}
