<div
  class="wrapper"
  [class.wrapper--error]="
    (((control.statusChanges | async) === 'INVALID' && control.touched) ||
      forceError) &&
    (control?.value || control?.hasError('forceRequired'))
  "
  [class.language]="languageSelect"
>
  <span
    class="wrapper__name"
    [class.wrapper__name--error]="
      (((control.statusChanges | async) === 'INVALID' && control.touched) ||
        forceError) &&
      (control?.value || control?.hasError('forceRequired'))
    "
    >{{ placeholder }}</span
  >
  <img
    *ngIf="languageSelect && icon"
    class="wrapper__lang-img"
    alt="*"
    src="assets/icons/layout/footer/flags/new/{{ icon }}.svg"
    width="16"
    height="16"
  />
  <label [for]="placeholder" aria-label="Select" class="select-label">
    <select
      [formControl]="control"
      class="wrapper__select select"
      (change)="changeSelected($event)"
      [id]="placeholder"
      [name]="placeholder"
    >
      <option
        *ngIf="hideFirst"
        disabled
        selected
        style="display: none"
      ></option>
      <option
        *ngFor="let item of items; let i = index; trackBy: trackItems"
        [value]="item[bindValue]"
        class="select__option"
      >
        {{ item[bindLabel] }}
      </option>
    </select>
  </label>
  <img class="wrapper__img" src="/assets/icons/arrow.svg" alt="" />
</div>
