import { inject, Injectable, NgZone } from '@angular/core';
import { getWindow } from '@crc/shared';
import { distinctUntilChanged, forkJoin, startWith } from 'rxjs';
import { AuthFacade } from '../../auth';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { DepositFacade, RegisterFacade } from '../../features';

@Injectable({
  providedIn: 'root'
})
export class ExternalScriptBridgeFacade {
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly depositFacade: DepositFacade = inject(DepositFacade);
  private readonly registerFacade: RegisterFacade = inject(RegisterFacade);
  private readonly ngZone: NgZone = inject(NgZone);
  public OnInit(): void {
    this.initializeConnectionWithCrazyEgg();
  }
  public initializeConnectionWithCrazyEgg(): void {
    const window = getWindow();
    const authFacade: AuthFacade = this.authFacade;
    const depositFacade: DepositFacade = this.depositFacade;
    const registerFacade: RegisterFacade = this.registerFacade;
    const ngZone: NgZone = this.ngZone;
    (window.CE_API || (window.CE_API = [])).push((): void => {
      ngZone.run(() => {
        forkJoin([
          authFacade.isLoggedIn$().pipe(
            startWith(null),
            distinctUntilChanged(),
            filter((val) => val !== null),
            debounceTime(304),
            tap((isLoggedIn: boolean) => {
              window.CE2.set(1, `Authenticated: ${isLoggedIn}`);
            })
          ),
          depositFacade.$isDepositPopupOpenCurrently.asObservable().pipe(
            startWith(null),
            distinctUntilChanged(),
            tap((status) => {
              if (status !== null) {
                window.CE2.set(2, `IsDepositWizardOpen: ${status}`);
              }
            })
          ),
          registerFacade.$isRegistrationWizardOpen.asObservable().pipe(
            switchMap((isOpen) => {
              return registerFacade.registrationStep.asObservable().pipe(
                tap((step) => {
                  if (!isOpen) {
                    window.CE2.set(3, `IsRegistrationWizardOpen: ${isOpen};`);
                    return;
                  }
                  if (step === 'personalData') {
                    window.CE2.set(
                      3,
                      `IsRegistrationWizardOpen: ${isOpen}; RegistrationStep: 1st - ${step};`
                    );
                    return;
                  }
                  if (step === 'accountData') {
                    window.CE2.set(
                      3,
                      `IsRegistrationWizardOpen: ${isOpen}; RegistrationStep: 2nd - ${step};`
                    );
                    return;
                  }
                  if (step === 'finished') {
                    window.CE2.set(
                      3,
                      `IsRegistrationWizardOpen: ${isOpen}; RegistrationStep: 3rd - ${step};`
                    );
                  }
                })
              );
            })
          )
        ]).subscribe();
      });
    });
  }
}
