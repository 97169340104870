import { Injectable } from '@angular/core';
import { addDays, isBefore } from 'date-fns';
import {
  BehaviorSubject,
  delay,
  Observable,
  of,
  take,
  tap,
  combineLatest,
  from,
  catchError
} from 'rxjs';
import { PasscodeService } from './passcode.service';

import { switchMap } from 'rxjs/operators';
import { KeyboardData } from '../../../shared/keyboard/entity/data';
import {
  PasscodeState,
  PasscodeVariables
} from '../../../shared/passcode/entity/passcode-state.type';
import { CryptoUtils } from './passcode.crypto-utils';
import {
  AccountFacade,
  ApiResponse,
  AuthFacade,
  AuthResponse,
  AvatarsFacade,
  Restricted
} from '@crc/facade';

@Injectable({
  providedIn: 'root'
})
export class PasscodeFacade {
  private passcodeState: BehaviorSubject<PasscodeState> =
    new BehaviorSubject<PasscodeState>(null);

  private passcode: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private readonly $invalidPasscode = new BehaviorSubject<boolean>(false);
  private readonly $isVisiblePopup = new BehaviorSubject<boolean>(false);

  private readonly $suggestPopup = new BehaviorSubject<boolean>(false);

  private getPasscodeStorage(): PasscodeState {
    return this.getLocalStorage(
      PasscodeVariables.passcodeState
    ) as PasscodeState;
  }

  constructor(
    private readonly passcodeService: PasscodeService,
    private readonly accountFacade: AccountFacade,
    private readonly authFacade: AuthFacade,
    private readonly avatarsFacade: AvatarsFacade
  ) {
    this.initPasscode();
  }

  checkSuggestPopup() {
    if (
      !this.getLocalStorage('passcodeState') ||
      this.getLocalStorage('passcodeState') === 'ask'
    ) {
      this.setLocalStorage('passcodeState', 'temporaryDenied');
    }
  }

  getSuggestPopupState(): boolean {
    this.suggestPasscodePopup();
    return this.$suggestPopup.getValue();
  }

  getInvalidPasscode$(): Observable<boolean> {
    return this.$invalidPasscode.asObservable();
  }

  getIsVisibleAPopup$(): boolean {
    return this.$isVisiblePopup.getValue();
  }

  getInvalidPasscode(): boolean {
    return this.$invalidPasscode.getValue();
  }

  getPasscodeState(): Observable<PasscodeState> {
    return this.passcodeState as Observable<PasscodeState>;
  }

  getPasscodeStateValue(): PasscodeState {
    return this.passcodeState.value;
  }

  setPasscodeState(state: PasscodeState) {
    this.setLocalStorage(PasscodeVariables.passcodeState, state);
    this.passcodeState.next(state);
  }

  getPasscode(): Observable<string> {
    return this.passcode as Observable<string>;
  }

  getPasscodeValue(): string {
    return this.passcode.value;
  }

  setPasscode(passcode: string) {
    this.passcode.next(passcode);
  }

  setInvalidPasscode(state: boolean) {
    this.$invalidPasscode.next(state);
  }

  setSuggestPopupState(state: boolean) {
    this.$suggestPopup.next(state);
  }

  setIsVisibleAPopup(state: boolean) {
    this.$isVisiblePopup.next(state);
  }

  initPasscode() {
    if (!this.getPasscodeStorage()) {
      this.setPasscodeState('ask');
    } else {
      this.passcodeState.next(this.getPasscodeStorage());
    }
  }

  savePasscode(): Observable<ApiResponse> {
    return combineLatest([
      this.accountFacade.personalData$,
      this.avatarsFacade.activeAvatar$,
      this.generateDeviceId$()
    ]).pipe(
      tap(([userData, avatarUrl, deviceId]) => {
        this.setLocalStorage(PasscodeVariables.firstName, userData.firstName);
        this.setLocalStorage(PasscodeVariables.avatarUrl, avatarUrl);
        this.setLocalStorage(PasscodeVariables.deviceId, deviceId);
      }),
      take(1),
      switchMap(([userData, _, deviceId]) => {
        return this.getAuthorizationCode$(deviceId).pipe(
          switchMap((authCode: string) =>
            this.passcodeService
              .savePasscode({
                login: userData.loginName,
                password: this.passcode.value,
                code: authCode
              })
              .pipe(
                tap(() => {
                  this.setPasscode('');
                  this.setPasscodeState('activated');
                })
              )
          )
        );
      })
    );
  }

  checkPasscode(): Observable<ApiResponse> {
    return this.getAuthorizationCode$(
      this.getLocalStorage(PasscodeVariables.deviceId)
    ).pipe(
      switchMap((authCode: string) => {
        return this.passcodeService
          .checkPasscode({
            deviceCode: authCode,
            login: '',
            passcode: ''
          })
          .pipe(
            delay(200),
            catchError((e) => {
              if (e.status === 403) {
                this.setInvalidPasscode(true);
                setTimeout(() => {
                  this.authFacade.setRestrictedStatus(
                    Restricted.BELOW_LEGAL_AGE
                  );
                }, 1500);
                return authCode;
              } else return authCode;
            }),
            tap((response: AuthResponse) => {
              if (response.success) {
                this.authFacade.signInWithPasscode(response);
              } else {
                this.setInvalidPasscode(true);
              }
            })
          );
      })
    );
  }

  manipulatePasscode(event: KeyboardData) {
    if (event === 'off') {
      return;
    }
    if (event === 'backspace') {
      this.setPasscode(this.getPasscodeValue().slice(0, -1));
    } else if (event === 'forgot') {
      this.setPasscodeState('denied');
      const removeArray = [
        PasscodeVariables.firstName,
        PasscodeVariables.avatarUrl,
        PasscodeVariables.deviceId,
        PasscodeVariables.passcodeDate,
        PasscodeVariables.passcodeState
      ];

      this.removeLocalStorage(removeArray);
    } else if (this.getPasscodeValue().length < 4) {
      this.setPasscode(this.getPasscodeValue() + event);
    } else if (
      this.getPasscodeValue().length === 4 &&
      this.getInvalidPasscode()
    ) {
      this.setPasscode(this.getPasscodeValue().slice(0, 0));
      this.setPasscode(this.getPasscodeValue() + event);
    }
    this.setInvalidPasscode(false);
  }

  generateDeviceId$(): Observable<string> {
    const randomBytes = window.crypto.getRandomValues(new Uint8Array(32));
    return of(CryptoUtils.bufferToHex(randomBytes.buffer));
  }

  getAuthorizationCode$(deviceId: string): Observable<string> {
    return from(
      CryptoUtils.sha256(this.passcode.value).then((passcodeHash) =>
        CryptoUtils.sha256(`${deviceId}${passcodeHash}`)
      )
    );
  }

  suggestPasscodePopup() {
    if (
      this.getLocalStorage(PasscodeVariables.passcodeState) === 'activated' ||
      this.getLocalStorage(PasscodeVariables.passcodeState) ===
        'temporaryDenied'
    ) {
      this.setSuggestPopupState(false);
      return;
    }
    const passcodeDate = this.getLocalStorage(PasscodeVariables.passcodeDate);

    if (passcodeDate) {
      if (isBefore(new Date(passcodeDate), new Date())) {
        this.setPasscodeState('ask');
        this.setSuggestPopupState(true);
        this.setLocalStorage(
          PasscodeVariables.passcodeDate,
          addDays(new Date(), 1).toISOString()
        );
      }
    } else {
      this.setPasscodeState('ask');
      this.setSuggestPopupState(true);
      this.setLocalStorage(
        PasscodeVariables.passcodeDate,
        addDays(new Date(), 1).toISOString()
      );
    }
  }

  setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getLocalStorage(key: string): string {
    return localStorage.getItem(key);
  }

  removeLocalStorage(items: string[]) {
    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        localStorage.removeItem(items[i]);
      }
    }
  }
}
