import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  AccountFacade,
  LiveCallerFacade,
  PwaService,
  ResetPasswordFacade
} from '@crc/facade';
import { Observable, tap } from 'rxjs';
import {
  EnvironmentService,
  LanguageFacade,
  MATOMO_SRC_NAME,
  ScriptLoaderService
} from '@crc/shared';
import { Title } from '@angular/platform-browser';
import { LoginPopupActiveTab } from './features/auth/containers';
import { LayoutStateFacade } from './layout/state';
import { SEOService } from '@crc/core';

@Component({
  selector: 'crc-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  loginPopupActiveTab$: Observable<LoginPopupActiveTab> =
    this.layoutStateFacade.getLoginPopupActiveTab();

  showResetPassword$ = this.resetPasswordFacade.getShowState$();

  constructor(
    private readonly resetPasswordFacade: ResetPasswordFacade,
    private readonly layoutStateFacade: LayoutStateFacade,
    private readonly accountFacade: AccountFacade,
    private readonly languageFacade: LanguageFacade,
    private readonly liveCallerFacade: LiveCallerFacade,
    private readonly titleService: Title,
    private readonly pwaService: PwaService,
    private readonly env: EnvironmentService,
    private readonly scriptLoaderService: ScriptLoaderService,
    private readonly seoService: SEOService
  ) {
    window['appVersion'] = env.config.appVersion;
  }

  ngOnInit() {
    this.pwaService.checkUserAgent();
    this.pwaService.trackStandalone();
    window.addEventListener('beforeinstallprompt', (e) => {
      // show the add button
      this.pwaService.setCanInstall(true);
      this.pwaService.promptIntercepted = true;
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // no matter what, the snack-bar shows in 68 (06/16/2018 11:05 AM)
      e.preventDefault();
      // Stash the event so it can be displayed when the user wants.
      this.pwaService.deferredPrompt = e;
      this.pwaService.promptSaved = true;
      this.pwaService.removeLocalStorageInstalled();
    });
    window.addEventListener('appinstalled', () => {
      this.pwaService.trackInstalled();
      // hide the add button
      this.pwaService.setLocalStorageInstalled();
      this.pwaService.promptIntercepted = false;
    });
    if (window.location !== window.parent.location) {
      window.parent.postMessage('hardClose', '*');
    }
    this.accountFacade.checkIfSessionIsAlive();
    this.liveCallerFacade.subscribeToUserData();
    this.languageFacade
      .translateStream('title')
      .pipe(
        tap((data) => {
          if (this.seoService.tempVars.isChangeLocked) {
            return;
          }
          this.titleService.setTitle(data);
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.scriptLoaderService
      .loadScript(
        MATOMO_SRC_NAME,
        this.env.config.matomoConfiguration.matomoScriptUrl
      )
      .subscribe();
  }
}
