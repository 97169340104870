import { Injectable } from '@angular/core';
import { VerificationNavItem } from '../entity/verification-nav-item.interface';
import { VERIFICATION_NAV } from '../constants/verification-nav.constant';
import { BehaviorSubject, from, Observable, tap, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VerificationFacade {
  private readonly verificationNav: VerificationNavItem[] = VERIFICATION_NAV;

  private readonly $shakeVerification = new BehaviorSubject<boolean>(false);

  private blockedShakeAnimation = false;

  getShakeVerification$(): Observable<boolean> {
    return this.$shakeVerification.asObservable();
  }

  setShakeVerification(state: boolean) {
    if (!this.blockedShakeAnimation) {
      this.blockedShakeAnimation = true;
      return this.shakeReference(state);
    } else {
      return from([]);
    }
  }

  private shakeReference(state: boolean) {
    this.$shakeVerification.next(state);
    return timer(1000).pipe(
      tap(() => {
        this.blockedShakeAnimation = false;
        this.$shakeVerification.next(false);
      })
    );
  }

  getVerificationNav(): VerificationNavItem[] {
    return this.verificationNav;
  }
}
