<div class="wrap">
  <ng-container *ngIf="historyData?.length; else empty">
    <span class="wrap__header">{{
      'bonus.coin_exchange_history' | translate
    }}</span>
    <table class="wrap__table">
      <thead class="wrap__table-header">
        <tr class="wrap__table-header-tr">
          <th>{{ 'date' | translate }}</th>
          <th>{{ 'bonus.quantity' | translate }}</th>
          <th>{{ 'bonus.promotion' | translate }}</th>
        </tr>
      </thead>
      <tbody
        class="wrap__table-body"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="5"
        (scrolled)="nextPage.emit()"
        infiniteScrollContainer=".wrap__table-body"
        [fromRoot]="true"
      >
        <tr
          class="wrap__table-body-tr"
          *ngFor="let event of historyData; trackBy: trackById; let i = index"
        >
          <td>{{ event?.date }}</td>
          <td>{{ event.quantity }} {{ 'bonus.coin' | translate }}</td>
          <td>{{ event.promoName | translate }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-template #empty>
    <p class="wrap__empty">
      {{ 'bonus.empty_history' | translate }}
    </p>
  </ng-template>
</div>
