import { formatDate } from '@angular/common';

export type DateUnit = 'DAYS' | 'MONTHS' | 'YEARS';
export function subStructDate(
  amount: number,
  unit: DateUnit,
  date = new Date()
): Date {
  const subDate = new Date(date);

  switch (unit) {
    case 'DAYS': {
      subDate.setDate(subDate.getDate() - amount);
      break;
    }
    case 'MONTHS': {
      subDate.setMonth(subDate.getMonth() - amount);
      break;
    }
    case 'YEARS': {
      subDate.setMonth(subDate.getMonth() - amount * 12);
      break;
    }
    default: {
      throw Error('Invalid DateUnit: ' + unit);
    }
  }

  return subDate;
}

export function getToday(): Date {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0,
    0,
    0
  );
}

export function getTomorrow(): Date {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1,
    0,
    0,
    0,
    0
  );
}

export function isToday(date: Date): boolean {
  const formattedDate = formatDate(new Date(date), 'yyyy-MM-dd', 'en_US');
  const today = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
  return formattedDate === today;
}

export function isDate(date: string): boolean {
  return !Array.isArray(date) && isNaN(Number(date)) && !!Date.parse(date);
}

export function parseExactDate(dateString: string): Date {
  const dateParts = dateString.split('T')[0].split('-');
  return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2], 0, 0, 0);
}

export function dayCountDueTo(date: string): number {
  return Math.floor(
    (new Date(date).getTime() - getToday().getTime()) / dayInMilliseconds
  );
}

export function timeLeftDueTo(date: string): number {
  return Math.floor(
    removeTimeZone(new Date(date)).getTime() - new Date().getTime()
  );
}

export function removeTimeZone(date: Date) {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
}

export function isNumber(val: number): boolean {
  return typeof !isNaN(val) && typeof val === 'number';
}

export function getWeekNumber() {
  const currentDate = new Date();
  const oneJan = new Date(currentDate.getFullYear(), 0, 1);
  const numberOfDays = Math.floor(
    (currentDate.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000)
  );
  return Math.ceil((currentDate.getDay() + 1 + numberOfDays) / 7);
}

const minuteInMilliseconds = 1000 * 60;
const hourInMilliseconds = minuteInMilliseconds * 60;
const dayInMilliseconds = hourInMilliseconds * 24;
