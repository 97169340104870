import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Output
} from '@angular/core';
import { SharedModule } from '../shared.module';
import { Store } from '@ngrx/store';
import { accountSelectors } from '@crc/facade';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgSyncDirective } from '@crc/shared';

@Component({
  selector: 'crc-m-sms-off-reconfirm',
  standalone: true,
  templateUrl: './sms-off-reconfirm.component.html',
  styleUrls: ['./sms-off-reconfirm.component.scss'],
  imports: [SharedModule, NgSyncDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmsOffReconfirmComponent {
  protected readonly SMS_OFF_KEY = 'sms-off.text';
  @Output() protected readonly userAction: EventEmitter<{
    status: boolean;
    text: string;
  }> = new EventEmitter();
  protected readonly mobileNumber$: Observable<string> = inject(Store)
    .select(accountSelectors.selectPhoneNumber)
    .pipe(
      map((val) => {
        return val?.includes?.(':') ? val?.split?.(':')?.[1] ?? '' : val;
      })
    );
}
