import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, distinctUntilChanged, finalize, Observable, of } from 'rxjs';
import { CrocoCardService } from '../services/croco-card.service';
import { CardCountData, CardPageName, CardPages, CardStatus, OrderCard } from '../entity/croco-card';
import { UntypedFormGroup } from '@angular/forms';
import { NgSelectItem } from '@crc/components';

@Injectable({
  providedIn: 'root'
})
export class CrocoCardFacade {
  private readonly $selectedPage = new BehaviorSubject(CardPages.CROCO_CARD);
  private readonly $pageName = new BehaviorSubject<string>(CardPageName.CROCO_CARD);
  private readonly $cardCount = new BehaviorSubject<CardCountData>({
    count: 0,
    last: ''
  });
  private readonly $cardStatusResponse = new BehaviorSubject<CardStatus>(null);
  readonly $processCard = new BehaviorSubject<{ state: boolean, action: string }>({
    state: false,
    action: 'PROCESS'
  });
  readonly $userNotAllowed = new BehaviorSubject<boolean>(false);
  readonly $tryAgainState = new BehaviorSubject<boolean>(false);
  $loading = new BehaviorSubject(true);

  constructor(
    private readonly crocoCardService: CrocoCardService
  ) {
  }

  setSelectedPage(page: CardPages): void {
    this.$selectedPage.next(page);
  }

  getSelectedPage$(): Observable<string> {
    return this.$selectedPage.asObservable();
  }

  setPageName(pageName: string): void {
    this.$pageName.next(pageName);
  }

  getPageName$(): Observable<string> {
    return this.$pageName.asObservable();
  }

  setCardCount(cardCount: CardCountData): void {
    this.$cardCount.next(cardCount);
  }

  getCardCount$(): Observable<CardCountData> {
    return this.$cardCount.asObservable();
  }

  getCardCountValue(): CardCountData {
    return this.$cardCount.getValue();
  }

  setCardStatusResponse(cardStatus: CardStatus): void {
    this.$cardStatusResponse.next(cardStatus);
  }

  getCardStatusResponse$(): Observable<CardStatus> {
    return this.$cardStatusResponse.asObservable();
  }

  getCardStatusResponseValue(): CardStatus {
    return this.$cardStatusResponse.getValue();
  }

  setProcessCardState(state: boolean, action: string): void {
    this.$processCard.next({
      state,
      action
    });
  }

  getProcessCard$(): Observable<{ state: boolean, action: string }> {
    return this.$processCard.asObservable();
  }

  setUserNotAllowed(state: boolean): void {
    this.$userNotAllowed.next(state);
  }

  getUserNotAllowed$(): Observable<boolean> {
    return this.$userNotAllowed.asObservable().pipe(
      distinctUntilChanged()
    );
  }

  setTryAgainState(state: boolean): void {
    this.$tryAgainState.next(state);
  }

  getTryAgainState$(): Observable<boolean> {
    return this.$tryAgainState.asObservable();
  }

  // Get the count of cards
  getCardsCount(): Observable<CardCountData> {
    return this.crocoCardService.getCardsCount().pipe(
      map((response) => response.data),
      finalize(() => this.$loading.next(false)),
      catchError(() =>
        of({
          hasError: true
        } as CardCountData)
      )
    );
  }

  // Check card status
  checkCardStatus(): Observable<CardStatus> {
    return this.crocoCardService.checkCardStatus().pipe(
      finalize(() => this.$loading.next(false)),
      catchError(() =>
        of({
          hasError: true
        } as CardStatus)
      )
    );
  }

  // Order a card
  orderCard(form: UntypedFormGroup): Observable<OrderCard> {
    return this.crocoCardService.orderCard(form).pipe(
      catchError((err) => of(err?.error))
    );
  }

  // Add a second card
  addSecondCard(form: UntypedFormGroup): Observable<OrderCard> {
    return this.crocoCardService.addSecondCard(form).pipe(
      catchError((err) => of(err?.error))
    );
  }

  // Send SMS code
  sendSmsCode() {
    return this.crocoCardService.sendSmsCode().pipe(
      catchError((err) => of(err?.error))
    );
  }

  // Get Card Cities
  getCardCities$(lang: string): Observable<NgSelectItem[]> {
    return this.crocoCardService.getCardCities(lang).pipe(
      map((response) => {
        return Object.keys(response).map((key, index) => ({
          key: key.toLowerCase(),
          id: index + 1,
          value: response[key]
        }));
      }),
      catchError(() => of([]))
    );
  }
}
