import { JackpotOdometerData, PragmaticJackpot } from '@crc/components';

const digitalOffsetLevels = {
  LEVEL_ONE_OFFSET: 206,
  LEVEL_TWO_OFFSET: 180,
  LEVEL_THREE_OFFSET: 170,
  LEVEL_FOUR_OFFSET: 140
};

export function processPragmaticJackpot(
  jackpot: PragmaticJackpot,
  dataRefreshRate: number
): JackpotOdometerData[] {
  let tier0: number = 0;
  let tier1: number = 0;
  let tier2: number = 0;
  let tier3: number = 0;

  // Updated logic to format amount using toFixed and replace
  jackpot.jackpots[0].tiers.forEach((data) => {
    const formattedAmount = parseFloat(data.amount.toFixed(2).replace('.', ''));

    if (data.tier === 0) {
      tier0 = formattedAmount;
    } else if (data.tier === 1) {
      tier1 = formattedAmount;
    } else if (data.tier === 2) {
      tier2 = formattedAmount;
    } else if (data.tier === 3) {
      tier3 = formattedAmount;
    }
  });

  // Generate odometer data for all levels
  const startNumberLevelI: number = tier0 - digitalOffsetLevels.LEVEL_ONE_OFFSET;
  const odometerDataLevelI: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: 10,
    startNumber: startNumberLevelI,
    endNumber: tier0,
  };

  const startNumberLevelII: number = tier1 - digitalOffsetLevels.LEVEL_TWO_OFFSET;
  const odometerDataLevelII: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: 10,
    startNumber: startNumberLevelII,
    endNumber: tier1,
  };

  const startNumberLevelIII: number = tier2 - digitalOffsetLevels.LEVEL_THREE_OFFSET;
  const odometerDataLevelIII: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: 10,
    startNumber: startNumberLevelIII,
    endNumber: tier2,
  };

  const startNumberLevelIV: number = tier3 - digitalOffsetLevels.LEVEL_FOUR_OFFSET;
  const odometerDataLevelIV: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: 10,
    startNumber: startNumberLevelIV,
    endNumber: tier3,
  };

  // Return the processed data
  return [odometerDataLevelI, odometerDataLevelII, odometerDataLevelIII, odometerDataLevelIV];
}
