<div class="title" [attr.data-cy]="dataCy" [class.large-img]="largeImg">
  <div [class.img-wrapper]="border" [class.icon-bg-color]="IconBgColor">
    <ng-container *ngIf="!localSVGParameters; else localSVG">
      <img
        *ngIf="imageUrl"
        [attr.data-cy]="dataCy + 'img'"
        class="title__image"
        [src]="imageUrl"
        alt="Header Image"
      />
    </ng-container>
    <ng-template #localSVG>
      <crc-svg-icon
        [name]="localSVGParameters.name"
        [size]="localSVGParameters.size ? localSVGParameters.size : 'M'"
        [color]="localSVGParameters.color ? localSVGParameters.color : 'white'"
        [maxWidthHeight]="
          localSVGParameters.maxWidth || localSVGParameters.maxHeight
            ? {
                width: localSVGParameters?.maxWidth,
                height: localSVGParameters?.maxHeight
              }
            : { width: '100%', height: '100%' }
        "
      ></crc-svg-icon>
    </ng-template>
  </div>

  <span
    crcSharedUppercase
    class="title__title"
    [attr.data-cy]="dataCy + '-title'"
    >{{ title }}<span *ngIf="isBuyBonusActive$ | async"> BUY BONUS</span></span
  >

  <span *ngIf="isNew" class="new">{{ 'new' | translate }}</span>
  <ng-content></ng-content>
</div>
