import { Params } from '@angular/router';
import {
  everymatrixDefaultHeight,
  everymatrixDefaultWidth,
  igrosoftDefaultRatio
} from '../entity';

export const slotUntilChanged = (prev: Params, cur: Params) => {
  return (
    prev.slot !== undefined &&
    cur.slot !== undefined &&
    prev.slot === cur.slot &&
    prev.provider === cur.provider
  );
};

export const casinoUntilChanged = (prev: Params, cur: Params) => {
  return (
    prev.casino !== undefined &&
    cur.casino !== undefined &&
    prev.casino === cur.casino &&
    prev.provider === cur.provider
  );
};

export const formatSlotCasinoName = (name: string): string => {
  return name ? name.replace(/\s+/g, '').toLowerCase() : '';
};

export const getSlotDimensions = (provider: any): [number, number] => {
  let dimensions = [provider.iframeW, provider.iframeH] as [number, number];
  if (provider.vendor === 'everymatrix') {
    dimensions = [everymatrixDefaultWidth, everymatrixDefaultHeight];
  }
  return dimensions;
};

export const getSlotDimensionsRatio = (provider: any): number => {
  if (provider.provider === 'igrosoft') {
    return igrosoftDefaultRatio;
  }
  const [width, height] = getSlotDimensions(provider);
  return height / width;
};
