import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ResetPassword } from '@crc/facade';
import { catchError, Observable, of, tap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { handleSettingRequiredError } from '@crc/shared';

@Component({
  selector: 'crc-m-reset-password-enter-phone-number-presentation',
  templateUrl: './reset-password-phone-presentation.component.html',
  styleUrls: ['./reset-password-phone-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'innerData'
})
export class ResetPasswordPhonePresentationComponent implements OnInit {
  public controlValueChanges$!: Observable<string>;
  @Input() form!: UntypedFormGroup;
  submitted = false;
  isActive = false;

  @Output() submit$ = new EventEmitter<ResetPassword>();
  public ngOnInit() {
    this.controlValueChanges$ = this.form.get('mobile').valueChanges.pipe(
      debounceTime(200),
      tap((value) => {
        if (this.form.status === 'VALID') {
          this.submit$.emit({
            mobile: value
          });
          this.isActive = false;
        }
      }),
      catchError(() => {
        this.isActive = true;
        return of(null);
      })
    );
  }

  @Input() set resetPasswordEffect(result: {
    success: boolean;
    retryAfter?: number;
  }) {
    if (result?.retryAfter) {
      this.form.setErrors({
        ...this.form.errors,
        rateLimit: result.retryAfter
      });
    }
  }

  public onSubmit() {
    this.submitted = true;
    if (this.form.valid && this.isActive) {
      this.submit$.emit(this.form.value);
    }
    if ((this.form.touched || this.submitted) && this.form.invalid) {
      handleSettingRequiredError(this.form.get('mobile'));
    }
    setTimeout(() => {
      this.isActive = true;
    }, 500);
  }
}
