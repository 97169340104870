import { Injectable } from '@angular/core';
import { HttpService } from '@crc/shared';
import { catchError, Observable, of, take } from 'rxjs';
import {
  OTPAuthRequestInterface,
  OTPAuthResponseInterface,
  OTPSendRequestInterface,
  OTPSendResponseInterface
} from '../entity';

@Injectable({
  providedIn: 'root'
})
export class LoginOtpService {
  constructor(private readonly httpService: HttpService) {}
  public sendSMS$(
    userName: string
  ): Observable<OTPSendResponseInterface | null> {
    return this.httpService
      .post$<OTPSendResponseInterface, OTPSendRequestInterface>({
        baseUrl: 'authApiUrl',
        path: '/login-code/send',
        body: {
          login: userName
        }
      })
      .pipe(
        catchError(() => of(null)),
        take(1)
      );
  }
  public initiateAuth$(
    body: OTPAuthRequestInterface
  ): Observable<OTPAuthResponseInterface | null> {
    return this.httpService
      .post$<OTPAuthResponseInterface, OTPAuthRequestInterface>({
        baseUrl: 'authApiUrl',
        path: '/login',
        body
      })
      .pipe(
        catchError((err) => of(err.error.errorCode)),
        take(1)
      );
  }
}
