import { Injectable } from '@angular/core';
import {
  CashierWeekdays,
  getCashierState,
  getException,
  getHolidayType,
  getMinutesBeforeClosing,
  HolidayTypes,
  OpenStatus
} from '../entity';
import { BehaviorSubject, filter, interval, Observable, of, tap } from 'rxjs';
import { CashierPlace } from '../index';

@Injectable({
  providedIn: 'root'
})
export class CashierItemFacade {
  private exception$: BehaviorSubject<CashierWeekdays> =
    new BehaviorSubject<CashierWeekdays>(null);
  private workingHours$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  private exceptionTime$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  private holidayType$: BehaviorSubject<HolidayTypes> =
    new BehaviorSubject<HolidayTypes>(HolidayTypes.None);
  private openStatus$: BehaviorSubject<OpenStatus> =
    new BehaviorSubject<OpenStatus>(OpenStatus.Closed);
  private closesInMinutes$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  private closesInInterval: any;

  getException(): Observable<CashierWeekdays> {
    return this.exception$;
  }

  getWorkingHours(): Observable<string> {
    return this.workingHours$;
  }

  getExceptionTime(): Observable<string> {
    return this.exceptionTime$;
  }

  getHolidayType(): Observable<HolidayTypes> {
    return this.holidayType$;
  }

  getOpenStatus(): Observable<OpenStatus> {
    return this.openStatus$;
  }

  setOpenStatus(openStatus: OpenStatus): void {
    this.openStatus$.next(openStatus);
  }

  getClosesInMinutes(): Observable<string> {
    return this.closesInMinutes$.pipe(filter((v) => v !== null));
  }

  getClosesInInterval(): any {
    return this.closesInInterval;
  }

  setWorkingHours(cashier: CashierPlace) {
    if (cashier.openHours && cashier.openHours.length) {
      const generalHours = cashier.openHours.find(
        (hours) => hours.weekday === CashierWeekdays.AllWeek
      );
      this.workingHours$.next(
        generalHours ? `${generalHours.from} - ${generalHours.until}` : ''
      );
    }
    this.holidayType$.next(getHolidayType(cashier));
  }

  setExceptions(cashier: CashierPlace) {
    this.exception$.next(getException(cashier));

    if (this.exception$.value) {
      const exceptionTime = cashier.openHours.find(
        (hours) => hours.weekday === this.exception$.value
      );
      this.exceptionTime$.next(
        `${exceptionTime.from} - ${exceptionTime.until}`
      );
    }
  }

  setCashierState(cashier: CashierPlace): Observable<any> {
    this.openStatus$.next(getCashierState(cashier));

    if (this.openStatus$.value === OpenStatus.ClosesSoon) {
      this.setMinutesBeforeClosing(cashier);
      return this.setClosesInInterval(cashier);
    }

    return of(null);
  }

  setMinutesBeforeClosing(cashier: CashierPlace) {
    this.closesInMinutes$.next(getMinutesBeforeClosing(cashier));
  }

  setClosesInInterval(cashier: CashierPlace) {
    return interval(1000 * 60).pipe(
      tap(() => this.setMinutesBeforeClosing(cashier))
    );
  }
}
