import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AuthFacade, LoginPopupFacade, Restricted } from '@crc/facade';
import { MCashiersFacade } from '../../../cashiers/facade';
import { map, of, switchMap, timer } from 'rxjs';

@Component({
  selector: 'crc-m-login-restriction-container',
  templateUrl: './login-restriction-container.component.html',
  styleUrls: ['./login-restriction-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginRestrictionContainerComponent {
  @Input() status: Restricted;
  noScrollClass$ = of(false).pipe(
    switchMap(() => timer(500).pipe(map(() => true)))
  );

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly mCashierFacade: MCashiersFacade,
    private readonly loginPopupFacade: LoginPopupFacade
  ) {
    this.loginPopupFacade.loginCancel();
    this.authFacade.setLoginBarState(false);
  }

  close() {
    this.authFacade.setRestrictedStatus(null);
    this.authFacade.setLoginBarState(true);
  }

  onClickCashiers() {
    this.close();
    this.mCashierFacade.setCashierState(true);
  }
}
