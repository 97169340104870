import { EgtOdometerConfig } from './odometer.interface';

export const EgtOdometerConfigs: EgtOdometerConfig[] = [
  {
    index: 3,
    icon: 'IV.webp',
    listenToAnimation: true,
    level: 'LevelIV'
  },
  {
    index: 2,
    icon: 'III.webp',
    listenToAnimation: false,
    level: 'LevelIII'
  },
  {
    index: 1,
    icon: 'II.webp',
    listenToAnimation: false,
    level: 'LevelII'
  },
  {
    index: 0,
    icon: 'I.webp',
    listenToAnimation: false,
    level: 'LevelI'
  }
];

export const EgtOdometerConfigsNew: EgtOdometerConfig[] = [
  {
    index: 3,
    icon: 'new/IV.svg',
    listenToAnimation: true,
    level: 'LevelIV'
  },
  {
    index: 2,
    icon: 'new/III.svg',
    listenToAnimation: false,
    level: 'LevelIII'
  },
  {
    index: 1,
    icon: 'new/II.svg',
    listenToAnimation: false,
    level: 'LevelII'
  },
  {
    index: 0,
    icon: 'new/I.svg',
    listenToAnimation: false,
    level: 'LevelI'
  }
];
