<div class="header" data-cy="header">
  <crc-m-games-title
    [imageUrl]="image"
    [border]="border"
    [title]="title"
    [dataCy]="dataCy"
    [largeImg]="largeImg"
    [isNew]="isNew"
    [iconBgColor]="iconBgColor"
    [class.bg-icons]="bgIcon"
    [localSVGParameters]="localSVGParameters"
  >
    <span data-cy="header--new-status--icon" class="header--new-status__icon">
      {{ 'new' | translate }}
    </span>
  </crc-m-games-title>

  <span
    *ngIf="actionTitle && !buyBonus"
    #anchorRef
    (click)="onMoreClick()"
    [queryParams]="queryParams"
    [routerLink]="routeLink ? routeLink : undefined"
    [queryParamsHandling]="'merge'"
    class="header__more"
    [attr.data-cy]="dataCy + '-more'"
  >
    <span crcSharedUppercase [attr.data-cy]="dataCy + '-all'">{{
      actionTitle
    }}</span>
    <img
      src="assets/icons/features/main_page/carousel/right-vector.svg"
      alt="right vector"
      width="4"
      height="7"
    />
  </span>
  <crc-m-buy-bonus *ngIf="buyBonus"></crc-m-buy-bonus>
</div>
