import { Injectable } from '@angular/core';
import { Observable, tap, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { UfoService } from '../services/ufo.service';
import { LanguageFacade, replaceLanguageKeyInString } from '@crc/shared';
import { SpaceTaxi } from '../entity/ufo.interface';

@Injectable({
  providedIn: 'root'
})
export class UfoFacade {
  constructor(
    private readonly ufoService: UfoService,
    private readonly languageFacade: LanguageFacade
  ) {}

  replaceLanguage(url: string, supportTr: boolean = true): string {
    const lang = this.languageFacade.current;
    const modifiedLang = lang === 'tr' ? 'en' : lang;

    if (supportTr) {
      return replaceLanguageKeyInString(url, 'ka', lang);
    } else {
      return replaceLanguageKeyInString(url, 'ka', modifiedLang);
    }
  }

  getUfoLink(): Observable<string> {
    return this.ufoService.getUfoLink().pipe(
      map((res) => {
        try {
          JSON.parse(res);
          return null;
        } catch (e) {
          return res;
        }
      })
    );
  }

  getLatestCrashes$(reFetchAfter: number) {
    return timer(0, reFetchAfter * 1000).pipe(
      switchMap(() => {
        return this.ufoService.getLatestCrashes().pipe(
          tap((data) => {
            const firstMultiplier = data?.Multipliers?.[0];
            if (firstMultiplier) {
              firstMultiplier.isLast = true;
            }
          }),
          map((response) => {
            return {
              Multipliers: response?.Multipliers?.sort(
                (el1, el2) => (+el2.GameNo ?? 0) - (+el1.GameNo ?? 0)
              )
            };
          })
        );
      })
    );
  }

  getSpaceTaxiLatestCrashes$(reFetchAfter: number) {
    return timer(0, reFetchAfter * 1000).pipe(
      switchMap(() => {
        return this.ufoService.getSpaceTaxiLastCrashes().pipe(
          tap((data) => {
            const firstMultiplier = data?.Multipliers?.[0];
            if (firstMultiplier) {
              firstMultiplier.isLast = true;
            }
          }),
          map((response) => {
            return {
              Multipliers: response?.Multipliers
            };
          })
        );
      })
    );
  }

  getSpaceTaxiLink(params: SpaceTaxi): Observable<string> {
    return this.languageFacade.current$.pipe(
      switchMap(() => {
        return this.ufoService.getSpaceTaxiLink(params);
      }),
      map((res) => {
        try {
          JSON.parse(res);
          return null;
        } catch (e) {
          return res;
        }
      })
    );
  }
}
