import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {
  BonusBalanceData,
  BonusSteps,
  ExchangeResult,
  ExchangeType,
  HistoryData
} from '../../types/step.type';
import { Router } from '@angular/router';

@Component({
  selector: 'crc-shared-bonus-coin',
  templateUrl: './bonus-coin.component.html',
  styleUrls: ['./bonus-coin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusCoinComponent implements OnDestroy {
  @Input() isMobile: boolean;
  @Input() bonusSteps: BonusSteps = 'action';
  @Input() bonusBalanceData: BonusBalanceData;
  @Input() exchangeResult: ExchangeResult;
  @Input() historyData: HistoryData[];
  @Input() activeBalanceId: string = null;
  @Input() resultType: boolean;

  @Output() nextPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeStepClicked = new EventEmitter<BonusSteps>();
  @Output() SwiperCardClick = new EventEmitter<{
    balanceType: string;
    landingUrl: string;
  }>();
  @Output() landingUrl = new EventEmitter<string>();
  @Output() reload = new EventEmitter<void>();
  @Output() bonusExchangeClicked = new EventEmitter<{
    resultAmount: number;
    fromBalanceId: string;
    body: ExchangeType;
  }>();
  disableButton: boolean;
  rate: number = null;
  bonusType = 'SPIN';
  private selectedExchangeData: ExchangeType;
  private fromBalanceId: string;
  constructor(private readonly router: Router) {}
  ngOnDestroy(): void {
    this.changeStepClicked.emit('action');
  }
  toBalanceID(selectedBalanceData: {
    fromBalanceId: string;
    toBalanceID: string;
  }) {
    this.fromBalanceId = selectedBalanceData.fromBalanceId;
    this.selectedExchangeData = {
      toBalanceId: selectedBalanceData.toBalanceID,
      toAmount: null
    };
  }

  onBonusExchange(amount: { amount: number; resultAmount: number }) {
    this.selectedExchangeData = {
      ...this.selectedExchangeData,
      toAmount: amount.amount
    };
    setTimeout(() => {
      this.bonusExchangeClicked.emit({
        resultAmount: amount.resultAmount,
        fromBalanceId: this.fromBalanceId,
        body: this.selectedExchangeData
      });
    }, 307);
  }

  onPlayClick(landingUrl: string) {
    this.router
      .navigate(['landings'], {
        queryParams: { url: landingUrl + '/#' }
      })
      .then(() => {
        this.reload.emit();
      });
  }

  onSwiperCardClick(event: { balanceType: string; landingUrl: string }) {
    if (event.balanceType) {
      this.bonusType = event.balanceType;
    }
    this.SwiperCardClick.emit(event);
  }
}
