import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { getWasSessionManipulated$, reload, slideInAndOut } from '@crc/shared';
import { filter, tap, timer } from 'rxjs';
import { LayoutStateFacade } from '../../layout/state';
import { NavigationEnd, Router } from '@angular/router';
import { BonusSteps, PopupNavigation } from '@crc/facade';
import { ProfileNavigationFacade } from '../../features/profile/profile-navigation.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
const animationDuration = 300;
@UntilDestroy()
@Component({
  selector: 'crc-m-shared-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [slideInAndOut(animationDuration, 'Y', 100, 0)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupComponent implements AfterViewInit, OnChanges {
  @ViewChild('fadeRef', {
    read: ElementRef<HTMLDivElement>
  })
  private readonly __fadeRef__: ElementRef<HTMLDivElement>;
  homeIconState = this.layoutStateFacade.getHomeIconVisibleState();
  isUserBarVisible$ = this.layoutStateFacade.getUserBarVisibility();
  isUfoOpen: boolean;
  isGameOpen: boolean;
  isPokerOpen: boolean;
  _closed = false;

  @Input() tabTitles: string[] | string;
  @Input() set closeDisabled(value: boolean) {
    if (value) {
      this.isCloseDisabled = value;
      this.popupAction = 'none';
    }
  }
  @Input() activeTab: string;
  @Input() buttonText: string;
  @Input() buttonHighlight: boolean;
  @Input() withoutHeader = false;
  @Input() withoutBlur = false;
  @Input() closeIfActiveGame = false;
  @Input() isMaxContentActive: boolean;
  @Input() activeOnlyOneTab = false;
  @Input() zIndex = 100;
  @Input() popupAction: PopupNavigation = 'close';
  @Input() customClose: 'close';
  @Input() bonusSpace = false;
  @Input() bonusSpaceAction: BonusSteps;
  @Input() heightIncrease = false;
  @Input() disableCloseSelfState: boolean;
  @Input() private strictMode = false;
  @Input() protected optionalTitlePrefixIconSrc!: string;
  @Input() public optionalHeaderEmbeddedView!: TemplateRef<null>;
  @Input() public dataCyPrefix!: string;

  @Output() activeTabChange = new EventEmitter<string>();
  @Output() closedSelf = new EventEmitter<boolean>();
  @Output() logOut = new EventEmitter<void>();
  @Output() closedSelfAction = new EventEmitter<boolean>();
  @Output() buttonClicked = new EventEmitter<string>();
  @Output() changeStep = new EventEmitter<void>();
  @Output() bonusBack = new EventEmitter<void>();
  @Output() closeIconClicked = new EventEmitter<boolean>();
  isCloseDisabled = false;
  constructor(
    private readonly layoutStateFacade: LayoutStateFacade,
    private readonly router: Router,
    private readonly profileNavigationFacade: ProfileNavigationFacade
  ) {
    this.profileNavigationFacade.setCurrentNav(null);
    this.isUfoOpen = this.router.url.includes('mini-games');
    this.isPokerOpen = this.router.url.includes('poker');
    this.isGameOpen = this.router.url.includes('launcher');
    if (this.router.url.includes('profile/verification/result')) {
      return;
    }
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => {
          if (!this.disableCloseSelfState) {
            this.closeSelf();
          }
          if (this.closeIfActiveGame) {
            this.hidePopupOnActiveGame();
          }
        })
      )
      .subscribe();
  }
  get isSetantaRoute(): boolean {
    return this.router.url.includes('/setanta');
  }

  @Input() set close(close) {
    if (close) {
      this.closeSelf();
    }
  }

  hidePopupOnActiveGame() {
    const isUfoOpen = this.router.url.startsWith('/mini-games/');
    const isGameOpen = this.router.url.startsWith('/launcher');
    const isFantasyOpen = this.router.url.startsWith('/free-sports');

    if (isUfoOpen || isGameOpen || isFantasyOpen) {
      this.closeSelf();
    }
  }

  closeSelf() {
    if (
      this.popupAction === 'close' ||
      (this.popupAction === 'back' &&
        (!this.isCloseDisabled || this.activeTab !== 'sms_identification'))
    ) {
      this._closed = true;
      timer(animationDuration)
        .pipe(
          tap(() => {
            this.closedSelf.emit(true);
          })
        )
        .subscribe();
    } else {
      this.closedSelfAction.emit();
    }
  }

  closeWithIcon() {
    this.closeIconClicked.emit();
    this.closeSelf();
  }
  public ngAfterViewInit(): void {
    if (this.strictMode) {
      this.addListenerToSessionManipulation$();
    }
  }
  private addListenerToSessionManipulation$(): void {
    getWasSessionManipulated$(this.__fadeRef__.nativeElement)
      .pipe(
        untilDestroyed(this),
        tap((status) => {
          if (status) {
            reload();
          }
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['closeIfActiveGame'] &&
      changes['closeIfActiveGame'].currentValue === true
    ) {
      this.hidePopupOnActiveGame();
    }
  }
}
