import { Injectable } from '@angular/core';
import { ChangeNumberService } from '../services/change-number.service';
import {
  BehaviorSubject,
  delayWhen,
  from,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  timer
} from 'rxjs';
import {
  changeNumberSteps,
  customerNumberSmsResInterface,
  customerNumberValidityVerifyResInterface
} from '../interfaces';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgSelectItem } from '@crc/components';
import {
  DateService,
  handleRemovingRequiredError,
  handleSettingRequiredError
} from '@crc/shared';

@Injectable({
  providedIn: 'root'
})
export class ChangeNumberFacade {
  private readonly $changeNumberSteps: BehaviorSubject<changeNumberSteps> =
    new BehaviorSubject('form');
  private readonly $timerResetState: Subject<void> = new Subject();
  private readonly $changeNumberPopupShowState: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  private readonly $isLoading: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private readonly $timerTempHider: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  private readonly $notFormState: Subject<boolean> = new Subject();
  private readonly $numberChangedState: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  constructor(
    private readonly changeNumberService: ChangeNumberService,
    private readonly dateService: DateService
  ) {}
  public sendSMS$(formValue: {
    personalNumber: string;
    year: string;
    phoneNumber: string;
    smsCode: string;
  }): Observable<customerNumberSmsResInterface | null> {
    const { personalNumber, year, phoneNumber } = formValue;
    return this.changeNumberService.sendSMSToNewNumber$(
      phoneNumber,
      personalNumber,
      year
    );
  }

  public submitForm$(formValue: {
    personalNumber: string;
    year: string;
    phoneNumber: string;
    smsCode: string;
  }): Observable<customerNumberValidityVerifyResInterface | null> {
    const { personalNumber, year, phoneNumber, smsCode } = formValue;
    return this.changeNumberService.verifyNewNumberValidity$(
      phoneNumber,
      smsCode,
      personalNumber,
      year
    );
  }

  getNotFormState$(): Observable<boolean> {
    return this.$notFormState.asObservable();
  }

  setNotFormState(state: boolean) {
    this.$notFormState.next(state);
  }

  public getChangeNumberPopupShowState$(): Observable<boolean> {
    return this.$changeNumberPopupShowState.asObservable();
  }
  public setChangeNumberPopupShowState(state: boolean): void {
    this.$changeNumberPopupShowState.next(state);
  }
  public getIsNumberPopupOpen(): boolean {
    return this.$changeNumberPopupShowState.getValue();
  }
  public generateChangeNumberForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      personalNumber: new UntypedFormControl(null, {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      year: new UntypedFormControl('', {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      phoneNumber: new UntypedFormControl(null, {
        validators: [Validators.required, Validators.pattern(/^[5][0-9]{8}$/)],
        updateOn: 'change'
      }),
      smsCode: new UntypedFormControl(null, {
        validators: [Validators.required],
        updateOn: 'change'
      })
    });
  }
  public initializeYears(): NgSelectItem[] {
    const { minValidYear, validYear } = this.dateService.getVariables();
    return this.dateService.getAvailableYears(validYear, minValidYear);
  }
  public triggerTimerReset(): void {
    this.$timerResetState.next();
  }
  public getTimerResetActions$(): Observable<boolean> {
    return this.$timerResetState.asObservable().pipe(
      startWith(null),
      switchMap(() => from([true, false])),
      delayWhen((res) => (res ? of(null) : timer(0)))
    );
  }
  public handleSubmitErrorResponse(
    res: customerNumberValidityVerifyResInterface | null,
    cnForm: UntypedFormGroup
  ): void {
    const numberControl = cnForm.get('phoneNumber');
    const smsCodeControl = cnForm.get('smsCode');
    const yearControl = cnForm.get('year');
    const personalNumberControl = cnForm.get('personalNumber');
    if (res?.error) {
      const error: customerNumberSmsResInterface['payload']['errorCode'] =
        res?.payload?.errorCode;
      const generatedError = {
        manualError: `cn.${error?.toLowerCase() ?? 'default_error'}`
      };
      cnForm.setErrors({
        ...cnForm.errors,
        ...generatedError
      });
      if (error === 'INVALID_VERIFICATION_CODE') {
        smsCodeControl.setErrors({
          ...smsCodeControl.errors,
          ...generatedError
        });
        return;
      }
      if (error === 'CUSTOMER_RESTRICTED') {
        return;
      }
      if (error === 'INVALID_PERSONAL_DATA') {
        const fieldNames = res?.payload?.fieldNames;
        if (fieldNames?.length) {
          personalNumberControl.setErrors({
            ...personalNumberControl.errors,
            ...generatedError
          });
          yearControl.setErrors({
            ...yearControl.errors,
            ...generatedError
          });
        }
        return;
      }
      if (error === 'RATE_LIMIT') {
        smsCodeControl.setErrors({
          ...smsCodeControl.errors,
          ...generatedError
        });
        return;
      }
      if (error === 'REMOTE_ERROR') {
        this.setChangeNumberStep('error');
        return;
      }
      if (error === 'PHONE_NUMBER_TAKEN') {
        numberControl.setErrors({
          ...numberControl.errors,
          ...generatedError
        });
        smsCodeControl.setValue('');
        smsCodeControl.markAsPristine();
        smsCodeControl.markAsUntouched();
        handleRemovingRequiredError(smsCodeControl);
        this.$changeNumberSteps.next('extraError');
        return;
      }
      if (error === 'VERIFICATION_CODE_EXPIRED') {
        smsCodeControl.setErrors({
          ...smsCodeControl.errors,
          ...generatedError
        });
        return;
      }
      this.setChangeNumberStep('error');
    }
  }
  public handleSmsErrorRes(
    res: customerNumberSmsResInterface | null,
    cnForm: UntypedFormGroup
  ): void {
    const numberControl = cnForm.get('phoneNumber');
    const smsCodeControl = cnForm.get('smsCode');
    if (res?.error) {
      setTimeout(() => {
        this.triggerTimerReset();
      }, 300);
      const error: customerNumberSmsResInterface['payload']['errorCode'] =
        res?.payload?.errorCode;
      const generatedError = {
        manualError: `cn.${error?.toLowerCase() ?? 'default_error'}`
      };
      cnForm.setErrors({
        ...cnForm.errors,
        ...generatedError
      });
      if (error === 'RATE_LIMIT') {
        smsCodeControl.setErrors({
          ...smsCodeControl.errors,
          ...generatedError
        });
        smsCodeControl.markAsTouched();
        smsCodeControl.markAsDirty();
        handleSettingRequiredError(smsCodeControl);
        return;
      }
      if (error === 'PHONE_NUMBER_TAKEN') {
        smsCodeControl.setValue('');
        smsCodeControl.markAsPristine();
        smsCodeControl.markAsUntouched();
        this.setChangeNumberStep('extraError');
        numberControl.setErrors({
          ...numberControl.errors,
          ...generatedError
        });
        handleRemovingRequiredError(smsCodeControl);
      }
    }
  }
  public setChangeNumberStep(step: changeNumberSteps): void {
    this.$changeNumberSteps.next(step);
  }
  public selectChangeNumberStep$(): Observable<changeNumberSteps> {
    return this.$changeNumberSteps.asObservable();
  }

  public setNumberChangeState(data: boolean) {
    this.$numberChangedState.next(data);
  }

  public getNumberChangeState(): Observable<boolean> {
    return this.$numberChangedState.asObservable();
  }

  public getLoadingState$(): Observable<boolean> {
    return this.$isLoading.asObservable();
  }
  public setLoadingState(state: boolean): void {
    this.$isLoading.next(state);
  }
  public setTimerTempHiddenState(state: boolean): void {
    this.$timerTempHider.next(state);
  }
  public getTimerTempHiddenState$(): Observable<boolean> {
    return this.$timerTempHider.asObservable();
  }
}
