<div
  class="odometer-container"
  [class.vertical]="vertical"
  data-cy="odometer-container"
>
  <ng-container *ngIf="odometerData && odometerData?.length === odometerCount">
    <div
      *ngFor="let odometer of odometers; let i = index"
      [ngClass]="'odometer--level--' + odometer.level.replace('Level', '')"
      class="odometer odometer--position"
      data-cy="odometer"
    >
      <div
        class="odometer__icon odometer__icon--position"
        data-cy="odometer-icon"
      >
        <img
          alt=""
          src="assets/images/croco-roulette/{{ odometer.icon }}"
          data-cy="odometer-icon"
        />

        <div *ngIf="odometer.level === 'LevelI'" class="odometer-text-logo" [class.odometer-text-silver]="odometer.level === 'LevelI'">
          <img src="assets/images/croco-roulette/silver-star.svg" alt="Star">
          <span class="odometer-text">SILVER</span>
        </div>

        <div *ngIf="odometer.level === 'LevelIV'" class="odometer-text-logo" [class.odometer-text-gold]="odometer.level === 'LevelIV'">
          <img src="assets/images/croco-roulette/gold-star.svg" alt="Star">
          <span class="odometer-text">GOLD</span>
        </div>
      </div>

      <div
        class="odometer__wrapper odometer__wrapper--position not-selectable"
        data-cy="odometer-wrapper"
      >
        <crc-digital-odometer-presentation
          (animationFinished)="
            odometer.listenToAnimation && emitRefreshOdometer()
          "
          [type]="type"
          [data]="odometerData[odometer.index]"
          [roulette]="{
            isRoulette: type === 'crocoRoulette',
            index: odometer?.index
          }"
        >
        </crc-digital-odometer-presentation>

        <div class="digit digit--position currency-gel" data-cy="digit">
          <p class="text-gradient" data-cy="text-white">
            <span *ngFor="let letter of letters" class="el" [attr.data-letter]="letter">{{ letter }}</span>
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
