import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'crc-m-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input() borderColor: 'green' | 'primary';
}
