export enum PromotionsKeys {
  liveBigRaffleKey = '61e557277ce8c509abc0ad7a',
  grandRaffleButtonKey = '62022c437ce8c509abc0ad83',
  springRaffleButtonKey = '625e76b1e88fed09a5636a09',
  widgetsApp = '629f17533ffa5c099b169587',
  summerLiveRaffleButtonKey = '6397323a6865d00ca110d38f',
  depositVipRaffleButtonKey = '63ad4525ba79d40c7f09ae3f',
  kvaraNewYearKey = '63aad7feba79d40c7f09ae3e',
  bonusSpaceKey = '63ce3f65b70d410c85663b49',
  giftTollTipKey = '66c5d1021fce60bc84e0880e',
  ufoVideoKeyMOB = '65f82974476825cff3fd7d66',
  ufoVideoKeyWEB = '65f8294855348066e708b701',
  turkishLangKey = '6790aa5ba7035138d5cb0aac',
  streamIconToken = '679878b524d48b60a9220959'
}
