<crc-m-reset-password-enter-phone-number-presentation
  [form]="phoneNumberForm"
  (submit$)="resetPassword($event)"
  [resetPasswordEffect]="resetPasswordEffect"
  *ngIf="step === ResetPasswordSteps.ENTER_MOBILE"
></crc-m-reset-password-enter-phone-number-presentation>

<crc-m-reset-password-verify-presentation
  (submit$)="resetPasswordVerify($event)"
  (sendSms$)="resetPassword(phoneNumberForm.value)"
  *ngIf="step === ResetPasswordSteps.VERIFY_CODE"
></crc-m-reset-password-verify-presentation>

<crc-m-shared-user-action-result
  (ok)="cancel.emit()"
  *ngIf="
    step === ResetPasswordSteps.SUCCESS || step === ResetPasswordSteps.FAIL
  "
  [failText]="'reset_password.fail' | translate"
  [successText]="'reset_password.success' | translate"
  [success]="step === ResetPasswordSteps.SUCCESS"
>
</crc-m-shared-user-action-result>
