import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { EgtJackpot, EgtOdometerConfig } from '../../entity';
import { fadeInAndOutBottomTop } from '@crc/shared';

export type jackpot = 'largestWinDate';

@Component({
  selector: 'crc-jackpot-odometer-presentation',
  templateUrl: './jackpot-odometer-presentation.component.html',
  styleUrls: ['./jackpot-odometer-presentation.component.scss'],
  animations: [fadeInAndOutBottomTop(200)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JackpotOdometerPresentationComponent {
  @Input() odometerData: any;
  @Input() odometerCount: number;
  @Input() odometers: EgtOdometerConfig[];
  @Input() jackpot: EgtJackpot;
  @Input() showPopupInfo: boolean;

  @Output() refreshOdometer: EventEmitter<string> = new EventEmitter();

  emitRefreshOdometer() {
    this.refreshOdometer.emit();
  }
}
