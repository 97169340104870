<div
  class="status"
  data-cy="status"
  [class.success]="success"
  [class.error]="!success"
>
  <img
    *ngIf="success"
    src="/assets/icons/checked.svg"
    alt="scss"
    data-cy="success"
  />
  <img
    *ngIf="!success"
    src="/assets/icons/close.svg"
    alt="error"
    data-cy="error"
  />
</div>
