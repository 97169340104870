<div class="button-otp">
  <img
    class="button-otp__img"
    src="assets/icons/features/auth/auth_otp_icon{{ (isActive || '') && '-active' }}.svg"
    alt=""
    (click)="clicked.emit($event)"
  />
  <span
    class="button-otp__text"
    [class.button-otp__text-active]="isActive"
    (click)="clicked.emit($event)"
  >
    {{ 'auth.login_by_otp' | translate }}
  </span>
</div>
