import { CashierHours, CashierPlace, nearCashier } from '../index';
import { CashierWeekdays, HolidayTypes, OpenStatus } from './cashier-item.enum';

export const getHolidayType = (cashier: CashierPlace): HolidayTypes => {
  const isSaturdayHoliday = isDayHoliday(CashierWeekdays.Saturday, cashier);
  const isSundayHoliday = isDayHoliday(CashierWeekdays.Sunday, cashier);
  const isMondayHoliday = isDayHoliday(CashierWeekdays.Monday, cashier);
  const isTuesdayHoliday = isDayHoliday(CashierWeekdays.Tuesday, cashier);
  const isWednesdayHoliday = isDayHoliday(CashierWeekdays.Wednesday, cashier);
  const isThursdayHoliday = isDayHoliday(CashierWeekdays.Thursday, cashier);
  const isFridayHoliday = isDayHoliday(CashierWeekdays.Friday, cashier);

  switch (true) {
    case isSaturdayHoliday && isSundayHoliday:
      return HolidayTypes.Weekend;
    case isSaturdayHoliday:
      return HolidayTypes.Saturday;
    case isSundayHoliday:
      return HolidayTypes.Sunday;
    case isMondayHoliday:
      return HolidayTypes.Monday;
    case isTuesdayHoliday:
      return HolidayTypes.Tuesday;
    case isWednesdayHoliday:
      return HolidayTypes.Wednesday;
    case isThursdayHoliday:
      return HolidayTypes.Thursday;
    case isFridayHoliday:
      return HolidayTypes.Friday;
    default:
      return HolidayTypes.None;
  }
};

export const getException = (cashier: CashierPlace): CashierWeekdays => {
  const exception = cashier.openHours.find(
    (hr) => hr.weekday !== CashierWeekdays.AllWeek && !hr.closed
  );

  return exception ? exception.weekday : null;
};

export const getCashierState = (cashier: CashierPlace): OpenStatus => {
  const openHours: CashierHours = getOpenHours(cashier);

  if (cashier.t13n) {
    return OpenStatus.Open;
  } else if (openHours === null) {
    return OpenStatus.Closed;
  } else {
    return getCashierStateByDate(openHours);
  }
};

export const getMinutesBeforeClosing = (cashier: CashierPlace): string => {
  const openHours: CashierHours = getOpenHours(cashier);
  const now = new Date();
  const mNow = now.getHours() * 60 + now.getMinutes();
  const mFrom = minutesFromTimestamp(openHours.from);
  const mUntil = minutesFromTimestamp(openHours.until);
  let dUntil = mUntil - mNow;

  if (dUntil < 0 && mUntil < mFrom) {
    dUntil = 24 * 60 + dUntil;
  }
  return `${Math.floor(dUntil % 60)}`;
};

const getOpenHours = (cashier: CashierPlace): CashierHours => {
  const weekday = new Date().getDay();
  const openHours = cashier.openHours.find((item) => item.weekday === weekday);

  if (openHours) {
    return openHours;
  }
  return cashier.openHours.find(
    (item) => item.weekday === CashierWeekdays.AllWeek
  );
};

const getCashierStateByDate = (openHours: CashierHours): OpenStatus => {
  const now = new Date();
  const mNow = now.getHours() * 60 + now.getMinutes();
  const mFrom = minutesFromTimestamp(openHours.from);
  const mUntil = minutesFromTimestamp(openHours.until);
  const dFrom = mNow - mFrom;
  let dUntil = mUntil - mNow;

  if (dUntil < 0 && mUntil < mFrom) {
    dUntil = 24 * 60 + dUntil;
  }
  if (dFrom > 0 && dUntil > 0) {
    if (dUntil < 30) {
      return OpenStatus.ClosesSoon;
    } else {
      return OpenStatus.Open;
    }
  } else {
    return OpenStatus.Closed;
  }
};

const isDayHoliday = (weekday: number, cashier: CashierPlace): boolean => {
  const hours = cashier.openHours.find((hr) => hr.weekday === weekday);
  return !cashier.t13n && hours && hours.closed;
};

const minutesFromTimestamp = (str: string): number => {
  const [hours, minutes] = str.split(':');
  return Number(hours) * 60 + Number(minutes);
};

export const filterNearCashier = (
  cashierPlace: CashierPlace,
  isNearCashier: nearCashier
): boolean => {
  const resultKm = calculateDistance(
    isNearCashier.latitude,
    isNearCashier.longitude,
    cashierPlace.coordinates.lat,
    cashierPlace.coordinates.lon
  );

  if (isNearCashier.status) {
    return resultKm < 2;
  } else {
    return true;
  }
};

const calculateDistance = (
  latitude1: number,
  longitude1: number,
  latitude2: number,
  longitude2: number
) => {
  const p = 0.017453292519943295; //Math.PI / 180
  const c = Math.cos;
  const a =
    0.5 -
    c((latitude2 - latitude1) * p) / 2 +
    (c(latitude1 * p) *
      c(latitude2 * p) *
      (1 - c((longitude2 - longitude1) * p))) /
      2;
  const R = 6371; //The Earth's radius;
  return 2 * R * Math.asin(Math.sqrt(a));
};
