import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input
} from '@angular/core';
import {
  JsonPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase
} from '@angular/common';
import {
  PragmaticFeedDtoAllTypes,
  PragmaticLiveFeedTypeEnum,
  PragmaticLiveFeedTypeEnumInterface
} from '@crc/core';
import { BigBassColorInterpreter } from './helper/big-bass-color-interpreter.pipe';
import { MegaWheelInterpreterPipe } from './helper/mega-wheel-interpreter.pipe';
import {
  DirectivesModule,
  EnvironmentService,
  NgSyncDirective
} from '@crc/shared';

import { TranslateModule } from '@ngx-translate/core';
import { MiniGamesInterface } from './types/mini-games.interface';
import { SpaceManColorInterpreterPipe } from './helper/space-man-color-interpreter.pipe';

@Component({
  selector: 'crc-pragmatic-casino-feed-cover',
  templateUrl: './pragmatic-casino-feed-cover.component.html',
  styleUrls: ['./pragmatic-casino-feed-cover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgForOf,
    NgIf,
    JsonPipe,
    NgSwitch,
    NgSwitchCase,
    BigBassColorInterpreter,
    MegaWheelInterpreterPipe,
    NgSyncDirective,
    DirectivesModule,
    TranslateModule,
    SpaceManColorInterpreterPipe,
    NgClass
  ],
  standalone: true
})
export class PragmaticCasinoFeedCoverComponent {
  @Input() protected pragmaticCasinoFeedDto: PragmaticFeedDtoAllTypes;
  @Input() protected type: PragmaticLiveFeedTypeEnumInterface;
  @Input() protected game: MiniGamesInterface;
  @Input() protected carousel: boolean;
  protected readonly PragmaticLiveFeedTypeEnum: typeof PragmaticLiveFeedTypeEnum =
    PragmaticLiveFeedTypeEnum;
  protected readonly isMobile: boolean =
    inject(EnvironmentService).config.platform === 'mobile';
}
