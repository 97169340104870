import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, switchMap, tap } from 'rxjs';
import { FaqCategory, FaqFacade, FaqSelection } from '@crc/facade';
import { Language, LanguageFacade } from '@crc/shared';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MobileFaqFacade {
  private readonly faqItem$ = new BehaviorSubject<FaqCategory>(
    {} as FaqCategory
  );
  private readonly selectFaqItem$ = new Subject<FaqSelection>();
  private readonly activeFaqCategory$ = new Subject<string>();
  private readonly arrowValue$ = new Subject<string>();
  private readonly hasSubCategory$ = new Subject<boolean>();

  selectedFaqItem: FaqCategory;

  constructor(
    private readonly faqFacade: FaqFacade,
    private readonly languageFacade: LanguageFacade
  ) {}

  getActiveFaqCategoryTitle(): Observable<string> {
    return this.activeFaqCategory$.asObservable();
  }

  setActiveFaqCategoryTitle(title: string) {
    this.activeFaqCategory$.next(title);
  }

  getArrowValue(): Observable<string> {
    return this.arrowValue$.asObservable();
  }

  setArrowValue(value: string) {
    this.arrowValue$.next(value);
  }

  getSubCategoryState(): Observable<boolean> {
    return this.hasSubCategory$.asObservable();
  }

  setSubCategoryState(state: boolean) {
    this.hasSubCategory$.next(state);
  }

  getCategories(): Observable<FaqCategory[]> {
    return this.languageFacade.current$.pipe(
      switchMap((lang: Language) => this.faqFacade.getFaqCategories$(lang)),
      map((categories) =>
        categories.filter((c) => c?.subcategories?.length > 0)
      )
    );
  }

  getFaqItem(): Observable<FaqCategory> {
    return this.faqItem$.asObservable().pipe(
      tap((item) => {
        if (item.categoryId) {
          this.selectedFaqItem = item;
          this.setSelectedFaq({
            categoryId: item.categoryId,
            subcategoryId: item.subcategories[0]?.subcategoryId
          });
        }
      })
    );
  }

  setFaqItem(item: FaqCategory) {
    this.faqItem$.next(item);
  }

  getSelectedFaq(): Observable<FaqSelection> {
    return this.selectFaqItem$.asObservable();
  }

  setSelectedFaq(item: FaqSelection) {
    this.selectFaqItem$.next(item);
  }

  getAllMarked() {
    return document.querySelectorAll('#faqView mark.active');
  }
}
