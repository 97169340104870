import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SwiperModule } from './swiper';
import { CaptchaModule } from './captcha';
import { FiltersModule } from './filters';
import { SearchComponent } from './game-search';
import { PopupComponent } from './popup';
import { TimerCountdownComponent } from './timer-countdown';
import { DateRangePickerComponent } from './date-range-picker';
import { PasswordValidatorModule } from './password-validator';
import { DirectivesModule, SharedModule } from '@crc/shared';
import {
  JackpotOdometerComponent,
  JackpotOdometerPresentationComponent,
  OdometerPresentationComponent
} from './jackpot';
import { NumberChangeFormErrorPresentationComponent } from './number-change/number-change-form-error-presentation/number-change-form-error-presentation.component';
import { BonusCoinModule } from './bonus-coin';
import { LottieLoadingComponent } from './lottie-loading';
import { LoadingComponent } from './loading';
import {
  EgtDigitalOdometerContainerComponent,
  EgtDigitalOdometerPresentationComponent,
  DigitalOdometerPresentationComponent
} from './egt-digital-jackpot';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  PragmaticDigitalOdometerContainerComponent,
  PragmaticDigitalOdometerPresentationComponent,
  PragmaticOdometerPresentationComponent
} from './pragmatic-jackpot';
import { CustomPlayerComponent } from './custom-player/custom-player.component';
import { NgxDaterangepickerMd } from '@crc/date-range-picker';
import {
  CrocoRouletteOdometerContainerComponent,
  CrocoRouletteOdometerPresentationComponent
} from './croco-roulette-jackpot';
import { PatePlayOdometerContainerComponent, PatePlayOdometerPresentationComponent } from './pate-play-jackpot';
import {
  PatePlayOdometerComponent
} from './pate-play-jackpot/presentations/pate-play-odometer/pate-play-odometer.component';
const modules = [
  SwiperModule,
  CaptchaModule,
  FiltersModule,
  PasswordValidatorModule,
  BonusCoinModule
];

@NgModule({
  exports: [
    ...modules,
    SearchComponent,
    PopupComponent,
    TimerCountdownComponent,
    LoadingComponent,
    DateRangePickerComponent,
    JackpotOdometerComponent,
    EgtDigitalOdometerContainerComponent,
    PragmaticDigitalOdometerContainerComponent,
    NumberChangeFormErrorPresentationComponent,
    CrocoRouletteOdometerContainerComponent,
    LottieLoadingComponent,
    CustomPlayerComponent,
    PatePlayOdometerContainerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
    OverlayModule,
    NgxDaterangepickerMd.forRoot(),
    PatePlayOdometerComponent
  ],
  declarations: [
    LoadingComponent,
    SearchComponent,
    PopupComponent,
    TimerCountdownComponent,
    DateRangePickerComponent,
    JackpotOdometerComponent,
    JackpotOdometerPresentationComponent,
    OdometerPresentationComponent,
    NumberChangeFormErrorPresentationComponent,
    LottieLoadingComponent,
    EgtDigitalOdometerContainerComponent,
    EgtDigitalOdometerPresentationComponent,
    DigitalOdometerPresentationComponent,
    PragmaticDigitalOdometerContainerComponent,
    PragmaticDigitalOdometerPresentationComponent,
    PragmaticOdometerPresentationComponent,
    CustomPlayerComponent,
    CrocoRouletteOdometerContainerComponent,
    CrocoRouletteOdometerPresentationComponent,
    PatePlayOdometerContainerComponent,
    PatePlayOdometerPresentationComponent
  ]
})
export class ComponentsModule {}
