import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';

import { layoutActions, layoutSelectors } from '../state/index';
import { userBarActiveItem, userBarPosition } from '../containers';
import { State } from './reducers/state';
import {
  AuthFacade,
  BonusCoinFacade,
  LoginPopupFacade,
  ProfileShowBalanceFacade
} from '@crc/facade';
import { LoginPopupActiveTab } from '../../features/auth/containers';
import { map } from 'rxjs/operators';
import { ProfileNavigationFacade } from '../../features/profile/profile-navigation.facade';

@Injectable({
  providedIn: 'root'
})
export class LayoutStateFacade {
  private readonly isMenuOpen$ = this.store.select(
    layoutSelectors.selectBurgerState
  );

  private readonly isHeaderShow$ = this.store.select(
    layoutSelectors.selectHeaderState
  );

  private readonly isHeaderBalanceVisible$ = this.store.select(
    layoutSelectors.selectHeaderIsBalanceVisible
  );

  private readonly isFooterShow$ = this.store.select(
    layoutSelectors.selectFooterState
  );

  private readonly isActiveUserBarItem$ = this.store.select(
    layoutSelectors.selectUserBarActiveIcon
  );

  private readonly isUserBarVisible$ = this.store.select(
    layoutSelectors.selectUserBarVisibility
  );

  private readonly isVisibleHomeIcon$ = this.store.select(
    layoutSelectors.selectHomeIconState
  );

  private readonly userBarPosition = this.store.select(
    layoutSelectors.selectUserBarPosition
  );
  private readonly loginPopupActiveTab$ = this.store.select(
    layoutSelectors.selectLoginPopupActiveTab
  );
  private readonly layoutPageReload$ = this.store.select(
    layoutSelectors.selectLayoutReload
  );

  constructor(
    private readonly store: Store<State>,
    private readonly profileShowBalanceFacade: ProfileShowBalanceFacade,
    private readonly profileNavigationFacade: ProfileNavigationFacade,
    private readonly loginPopupFacade: LoginPopupFacade,
    private readonly authFacade: AuthFacade,
    private readonly bonusCoinFacade: BonusCoinFacade
  ) {
    this.setBalanceVisible(
      this.profileShowBalanceFacade.getToggleBalanceState()
    );
  }

  getLayoutPageReload$(): Observable<boolean> {
    return this.layoutPageReload$;
  }

  getMenuState(): Observable<boolean> {
    return this.isMenuOpen$;
  }

  getFooterVisibleState(): Observable<boolean> {
    return this.isFooterShow$;
  }

  getHeaderVisibleState(): Observable<boolean> {
    return this.isHeaderShow$;
  }

  getHeaderBalanceVisible(): Observable<boolean> {
    return this.isHeaderBalanceVisible$;
  }

  getHomeIconVisibleState(): Observable<boolean> {
    return this.isVisibleHomeIcon$;
  }

  getActiveUserBarItem(): Observable<userBarActiveItem> {
    return this.isActiveUserBarItem$;
  }

  getUserBarVisibility(): Observable<boolean> {
    return this.isUserBarVisible$;
  }

  getUserBarPosition(): Observable<userBarPosition> {
    return this.userBarPosition;
  }
  getLoginPopupActiveTab(): Observable<LoginPopupActiveTab> {
    return combineLatest([
      this.loginPopupActiveTab$,
      this.loginPopupFacade.getShowLoginPopupState$()
    ]).pipe(
      map(([loginPopupActiveTab, getShowLoginPopupState]) => {
        if (getShowLoginPopupState) {
          this.authFacade.setLoginBarState(false);
          if (!loginPopupActiveTab) {
            loginPopupActiveTab = 'login';
          }
        } else {
          if (!this.authFacade.getRestrictedCurrentStatus()) {
            this.authFacade.setLoginBarState(true);
          }
          loginPopupActiveTab = null;
        }
        return loginPopupActiveTab;
      })
    );
  }

  toggleMenu() {
    this.store.dispatch(layoutActions.toggleBurgerMenu());
  }
  setBalanceVisible(isVisible: boolean) {
    this.store.dispatch(layoutActions.setBalanceVisible({ isVisible }));
  }

  setHeaderVisible(isVisible: boolean) {
    this.store.dispatch(layoutActions.isVisibleHeader({ isVisible }));
  }

  setFooterVisible(isVisible: boolean) {
    this.store.dispatch(layoutActions.isVisibleFooter({ isVisible }));
  }

  setHomeIconState(isVisible: boolean) {
    this.store.dispatch(layoutActions.isVisibleHomeIcon({ isVisible }));
  }

  setActiveUserBarItem(isActive: userBarActiveItem) {
    this.store.dispatch(layoutActions.setUserBarActiveItem({ isActive }));
  }

  setUserBarVisibility(visible: boolean) {
    this.store.dispatch(layoutActions.setUserBarVisible({ visible }));
  }

  setUserBarPosition(position: userBarPosition) {
    this.store.dispatch(layoutActions.setUserBarPosition({ position }));
  }

  setLoginPopupActiveTab(activeTab: LoginPopupActiveTab) {
    this.store.dispatch(layoutActions.loginPopupActiveTab({ activeTab }));
  }

  setLayoutPageReload(reload: boolean) {
    this.store.dispatch(layoutActions.layoutPageReload({ reload }));
  }
  setBonusSpaceActive(balanceID: string) {
    this.bonusCoinFacade.setActiveBonusBalanceId(balanceID);
    this.setActiveUserBarItem('profile');
    setTimeout(() => {
      this.profileNavigationFacade.select('user.bonus_coin');
    });
  }
}
