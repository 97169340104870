import { VerificationNavItem } from '../entity/verification-nav-item.interface';

export function generateVerificationNavItems(
  link: string,
  label: string,
  text1: string,
  text2: string,
  image: string
): VerificationNavItem {
  return { link, label, text1, text2, image };
}
