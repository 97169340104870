import {
  Footer,
  FooterCards,
  FooterLang,
  FooterMessenger
} from './footer.interface';
import {
  generateFooterCards,
  generateLangMenu,
  generateMenuItems,
  generateMessengerMenu
} from '../functions/functions';

export const MENU_ITEMS: Footer[] = [
  generateMenuItems('about_us', [
    {
      nameKey: 'general_rules',
      isBtn: true,
      categoryId: 'general',
      subcategoryId: 'general'
    },
    {
      nameKey: 'sport_rules',
      isBtn: true,
      categoryId: 'sport',
      subcategoryId: 'general'
    },
    {
      nameKey: 'casino_regulations',
      isBtn: true,
      categoryId: 'casino',
      subcategoryId: 'testqve'
    },
    {
      nameKey: 'restricted_countries',
      isBtn: true,
      categoryId: 'countries',
      subcategoryId: 'cat3'
    },
    {
      nameKey: '25+',
      isBtn: true,
      categoryId: '18plus',
      subcategoryId: '18plus'
    }
  ]),
  generateMenuItems('personal_information', [
    { nameKey: 'verification', link: '/profile/verification' },
    { nameKey: 'change_password', link: '/profile/personal-info' },
    {
      nameKey: 'security_options',
      isBtn: true,
      categoryId: 'security',
      subcategoryId: 'security'
    }
  ]),
  generateMenuItems('user.balance', [
    { nameKey: 'user.deposit', link: '/profile/deposit' },
    { nameKey: 'user.withdraw', link: '/profile/withdraw' },
    { nameKey: 'secret_card', link: '/profile/secret-card' }
  ]),
  generateMenuItems('cash_desks', [
    { nameKey: 'addresses', link: '/profile/cashiers' }
  ]),
  generateMenuItems('contact_info', [
    { nameKey: '*0707', link: '#' },
    { nameKey: '(032) 2597777', link: '#' },
    { nameKey: 'support@crocobet.com', link: '#' }
  ])
];

export const LANG_MENU: Array<FooterLang> = [
  generateLangMenu('ge', 'GE', 'ka', 'ქართული'),
  generateLangMenu('en', 'EN', 'en', 'English'),
  generateLangMenu('ru', 'RU', 'ru', 'Русский'),
  generateLangMenu('tr', 'TR', 'tr', 'Turkish')
];

export const MESSENGER_MENU: Array<FooterMessenger> = [
  generateMessengerMenu('https://www.m.me/crocobet', 'messenger'),
  generateMessengerMenu('viber://pa?chatURI=CROCOBETcom', 'viber'),
  generateMessengerMenu('https://CROCOBETCOM_bot.t.me', 'telegram')
];

export const PRIMARY_CONTACT_NUMBER = generateMessengerMenu(
  'tel: 0322597777',
  'phone'
);

export const TELEGRAM_SUPPORT_ITEM = generateMessengerMenu(
  'https://t.me/CROCOBETCOM_bot?start',
  'telegram'
);

export const SUPPORT_ITEMS: Array<FooterMessenger> = [
  generateMessengerMenu('mailto: support@crocobet.com', 'email'),
  generateMessengerMenu('https://www.m.me/crocobet', 'messenger'),
  generateMessengerMenu('viber://pa?chatURI=CROCOBETcom', 'viber')
];

export const FULL_CONTACT_MENU: Array<FooterMessenger> = [
  ...SUPPORT_ITEMS,
  TELEGRAM_SUPPORT_ITEM
];
export const HELPER_CONTACT_MENU: Array<FooterMessenger> = [
  ...SUPPORT_ITEMS,
  TELEGRAM_SUPPORT_ITEM
];

export const FOOTER_CARDS: Array<FooterCards> = [
  generateFooterCards('bog'),
  generateFooterCards('tbc'),
  generateFooterCards('liberty'),
  generateFooterCards('visa')
];

export const FOOTER_LICENSE_ITEMS: Array<FooterCards> = [
  generateFooterCards('25')
];
