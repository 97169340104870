import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseUrl, HttpResponseCode, HttpService } from '@crc/shared';
import { ApiResponse } from '../../../shared/types/types';
import { catchError, map } from 'rxjs/operators';
import {
  IPreRegisterErrorResponse,
  Register
} from '../entity/register.interface';
import { HttpErrorResponse } from '@angular/common/http';

interface VerificationCode {
  messageBroadcastType: string;
  messageTypeName: string;
  mobile: string;
}

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private readonly apiService: HttpService) {}

  checkFieldUniqueness$(field: string, value: string): Observable<boolean> {
    const list = ['mobile', 'passport-number'];

    if (list.includes(field)) {
      value = `${field}/${value}`;
      field = 'value';
    }
    const firstSlashIndex = value.indexOf('/', 'passport-number'.length);
    const firstPart = value.slice(0, firstSlashIndex);
    const secondPart = value.slice(firstSlashIndex + 1).replace(/[\\/#]/g, '');
    const result = `${firstPart}/${secondPart}`;
    const replacedString = value.includes('passport-number') ? result : value;
    const path = `/customers/account/is-${field}-unique/${replacedString}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.get$<ApiResponse>({ path, baseUrl }).pipe(
      map((res) => !!res.data),
      catchError(() => of(true))
    );
  }

  sendVerificationCode$(
    mobile: string
  ): Observable<ApiResponse<boolean> | IPreRegisterErrorResponse> {
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = '/customers/account/preregister';
    const body: VerificationCode = {
      messageBroadcastType: 'sms',
      messageTypeName: 'VERIFICATION',
      mobile
    };

    return this.apiService
      .post$<ApiResponse<boolean>, VerificationCode>({ path, baseUrl, body })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return of(err.error as IPreRegisterErrorResponse);
        })
      );
  }

  register$(body: Register): Observable<[boolean, unknown]> {
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = '/customers/account/register';

    return this.apiService
      .post$<ApiResponse, Register>({ path, baseUrl, body })
      .pipe(
        map((res: ApiResponse) => {
          const success = res.code === HttpResponseCode.OK && res.data;
          return [success, res.data] as [boolean, unknown];
        }),
        catchError((err: unknown) => {
          return of([false, err] as [boolean, unknown]);
        })
      );
  }
}
