import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptchaPromptComponent } from './captcha-prompt/captcha-prompt.component';
import { FormsModule } from '../forms';

import { ReactiveFormsModule } from '@angular/forms';
import { SharedTranslateModule } from '@crc/shared';

const components = [CaptchaPromptComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedTranslateModule
  ],
  exports: [...components]
})
export class CaptchaModule {}
