import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { rouletteReducer } from './state/roulette/roulette.reducers';
import { RouletteEffects } from './state/roulette/roulette.effects';

@NgModule({
  imports: [
    EffectsModule.forRoot([RouletteEffects]),
    StoreModule.forRoot({ roulette: rouletteReducer }),
    HttpClientModule,
    HttpClientJsonpModule
  ]
})
export class FacadeModule {}
