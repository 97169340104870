<div class="checkbox" data-cy="checkbox">
  <div class="checkbox__container" data-cy="checkbox-container">
    <input
      [(ngModel)]="value"
      (change)="onChange(value)"
      [id]="inputId"
      class="checkbox__input"
      data-cy="checkbox-input"
      type="checkbox"
    />
    <label
      [for]="labelFor"
      class="checkbox__label"
      data-cy="checkbox-label"
    ></label>
  </div>

  <div class="checkbox__text" data-cy="checkbox-text">
    {{ checkboxText }}
  </div>
</div>
