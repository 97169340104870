import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  connection = (navigator as any).connection;

  isSlowConnection(): boolean {
    if (this.connection) {
      const type = this.connection.effectiveType;
      return type === 'slow-2g' || type === '2g' || type === '3g';
    }
    return true;
  }
}
