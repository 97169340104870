import { Injectable } from '@angular/core';
import { CrmMenuItem } from '../entity/crm.interface';
import { CRM_HEADER_MENU_ITEMS, CRM_MENU_ITEMS } from '../entity/menu-items';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrmFacade {
  private readonly menuItems: Array<CrmMenuItem> = CRM_MENU_ITEMS;
  private readonly headerMenuItems: Array<CrmMenuItem> = CRM_HEADER_MENU_ITEMS;
  private readonly $loaderActive = new BehaviorSubject<boolean>(false);

  getMenuItems(): CrmMenuItem[] {
    return this.menuItems;
  }

  getHeaderMenuItems(): CrmMenuItem[] {
    return this.headerMenuItems;
  }

  getLoaderActiveState$(): Observable<boolean> {
    return this.$loaderActive.asObservable();
  }

  setLoaderActiveState(state: boolean) {
    this.$loaderActive.next(state);
  }
}
