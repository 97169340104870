import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { LanguageFacade } from '@crc/shared';
import { AvatarsService } from '../services/avatars.service';
import { Avatar } from '../entity/avatars.interface';
import { Store } from '@ngrx/store';
import { SignedInFeatureState } from '../../../../auth/store/state';
import { accountSelectors } from '../../../../auth/store/selectors';
import { accountActions } from '../../../../auth/store/actions';

@Injectable({
  providedIn: 'root'
})
export class AvatarsFacade {
  avatars$: Observable<Avatar[]>;
  activeAvatar$: Observable<string>;

  temporaryActiveAvatar$ = new Subject<string>();

  constructor(
    private readonly avatarService: AvatarsService,
    private readonly languageService: LanguageFacade,
    private readonly store: Store<SignedInFeatureState>
  ) {
    this.avatars$ = this.languageService.current$.pipe(
      switchMap(() => this.avatarService.getAvatars())
    );

    this.activeAvatar$ = this.store.select(accountSelectors.selectAvatar);
  }

  setUserAvatar$(avatar: string): Observable<Avatar> {
    return this.avatarService.setUserAvatar({ avatar });
  }

  updateActiveAvatar() {
    this.store.dispatch(accountActions.getUserAvatar());
  }

  setTemporaryActiveAvatar(avatar: string) {
    this.temporaryActiveAvatar$.next(avatar);
  }

  getTemporaryActiveAvatar(): Observable<string> {
    return this.temporaryActiveAvatar$.asObservable();
  }
}
