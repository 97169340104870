import { Injectable } from '@angular/core';
import { BaseUrl, HttpService, LanguageFacade } from '@crc/shared';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../../shared/types/types';
import { map } from 'rxjs/operators';
import { Cashier } from '../entity/cashiers.interface';
import { HttpClient } from '@angular/common/http';
import { GOOGLE_MAPS_API_KEY } from '../entity/map.constants';
import { mapOldDtoToNew } from '../functions/old-dto-to-new-mapping';

@Injectable({ providedIn: 'root' })
export class CashiersService {
  constructor(
    private readonly apiService: HttpService,
    private readonly http: HttpClient,
    private readonly languageService: LanguageFacade
  ) {}

  getCashiers(): Observable<Cashier[]> {
    const path = `/cashdesk/cities?lang=${this.languageService.current}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<ApiResponse<Cashier[]>>({
        path,
        baseUrl
      })
      .pipe(map(mapOldDtoToNew));
  }

  loadMap(): Observable<any> {
    return this.http.jsonp(
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`,
      'callback'
    );
  }
}
