import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseUrl, HttpService, LanguageFacade } from '@crc/shared';
import { CtaResponse } from '../entity/cta.interface';

@Injectable({
  providedIn: 'root'
})
export class CtaService {
  constructor(
    private readonly http: HttpService,
    private readonly languageFacade: LanguageFacade
  ) {}

  getUserCta(): Observable<CtaResponse> {
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = `/marketing/ctas?language=${this.languageFacade.current}`;
    return this.http.get$({ path, baseUrl });
  }
  getMobUserCta(): Observable<CtaResponse> {
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = `/marketing/ctas?language=${this.languageFacade.current}`;
    return this.http.get$({ path, baseUrl });
  }
}
