import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { PasscodeFacade } from '../../../features/auth/services/passcode.facade';
import { Observable, tap } from 'rxjs';
import { KeyboardData, KeyboardState } from '../../keyboard/entity/data';
import { PasscodeState } from '../entity/passcode-state.type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'crc-m-activate-passcode',
  templateUrl: './activate-passcode.component.html',
  styleUrls: ['./activate-passcode.component.scss']
})
export class ActivatePasscodeComponent implements OnDestroy {
  @Output() closePopup: EventEmitter<void> = new EventEmitter<void>();

  passcodeState: Observable<PasscodeState> =
    this.passcodeFacade.getPasscodeState();
  passcode: Observable<string> = this.passcodeFacade.getPasscode();

  constructor(private readonly passcodeFacade: PasscodeFacade) {}

  ngOnDestroy() {
    if (
      this.passcodeFacade.getPasscodeStateValue() === 'ask' ||
      this.passcodeFacade.getPasscodeStateValue() === 'accepted'
    ) {
      this.passcodeFacade.setPasscodeState('denied');
    }
    this.passcodeFacade.setPasscode('');
  }

  setPasscodeState(state: PasscodeState) {
    this.passcodeFacade.setPasscodeState(state);
  }

  onKeyboardClick(event: KeyboardData, place?: KeyboardState) {
    if (event === 'off' && place === 'active') {
      this.closePopup.emit();
    }
    this.passcodeFacade.manipulatePasscode(event);
  }

  savePasscode() {
    if (this.passcodeFacade.getPasscodeValue()?.length !== 4) {
      return;
    }
    this.passcodeFacade
      .savePasscode()
      .pipe(untilDestroyed(this))
      .pipe(
        tap(() => {
          this.closePopup.emit();
        })
      )
      .subscribe();
  }
}
