import { Injectable } from '@angular/core';
import { BaseUrl, HttpService } from '@crc/shared';
import { Observable, of } from 'rxjs';
import { Leaderboard, Tournament } from '../entity/games.interface';
import { catchError, map } from 'rxjs/operators';
import {
  GameType,
  P2pAndSpinGamesInterfaceResponse
} from '../entity/p2p-game.types';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GamesService {
  constructor(
    private readonly apiService: HttpService,
    private readonly http: HttpClient
  ) {}

  getGameLink(
    provider: string,
    customerId: number,
    gameId?: number
  ): Observable<string> {
    const path = `/rest/integrations/ig/${provider}/get_game?customerid=${customerId}&gameid=${gameId}`;
    const baseUrl: BaseUrl = 'apiUrl';
    return this.apiService.get$<string>({
      path,
      baseUrl,
      responseType: 'text'
    });
  }

  getGameTournaments(gameType: GameType): Observable<Tournament[]> {
    const path = `/tournaments?gameType=${gameType}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: Tournament[] }>({ path, baseUrl })
      .pipe(map((result) => result.data));
  }

  getLeaderBoard(game: string, round: number): Observable<Leaderboard[]> {
    return this.http
      .get<any>(
        `https://${game}.crocobet.com/api/LeaderBoard/getall?round=${round}`
      )
      .pipe(map((leaderboard) => leaderboard.TopScores));
  }
  public getP2PgameConfig(): Observable<P2pAndSpinGamesInterfaceResponse[]> {
    const path = `/integrations/v2/p2p/config`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: P2pAndSpinGamesInterfaceResponse[] }>({
        path,
        baseUrl
      })
      .pipe(
        map((result) => result.data),
        catchError(() => of([]))
      );
  }

  public getGameLinkUrlNewMethod(url: string): Observable<string> {
    return this.http.get<string>(url, { responseType: 'text' as 'json' }).pipe(
      map((response) => response),
      catchError(() => of(''))
    );
  }
}
