import { Pipe, PipeTransform } from '@angular/core';
import { ICountdown } from './entity/live-raffle-interfaces';
import {
  fillWithZeros,
  toCountdownData
} from './functions/live-big-raffle.utils';

@Pipe({
  name: 'liveRaffleCountdown'
})
export class LiveRaffleCountdownPipe implements PipeTransform {
  private value: ICountdown = { seconds: null, minutes: null, hours: null };

  transform(value: number, args?: any): string {
    if (!value) {
      return '';
    }
    this.allocateTimeUnits(value);
    return this.dateToString();
  }

  private dateToString(): string {
    const seconds = fillWithZeros(this.value.seconds, 2);
    const minutes = fillWithZeros(this.value.minutes, 2);
    const hours = fillWithZeros(this.value.hours, 2);

    return this.value.hours
      ? `${hours}:${minutes}:${seconds}`
      : `${minutes}:${seconds}`;
  }

  private allocateTimeUnits(timeDifference: number): void {
    this.value = toCountdownData(timeDifference);
  }
}
