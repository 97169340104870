<crc-m-register-presentation
  (resultClosed)="resultClosed()"
  (changeRegistrationStep)="onChangeRegistrationStep($event)"
  (register)="onRegister($event)"
  (monthChanged)="onMonthsChanged($event)"
  (sendSms)="onSendSms($event)"
  (policyClicked)="policyHandler($event)"
  (countryChanged)="reInitYears($event)"
  [registrationStep]="registrationStep | async"
  [fieldsAvailable]="$fieldsAvailable | async"
  [cdaShowError]="cdaShowError$ | async"
  [cdaNationalityShowError]="cdaNationalityShowError$ | async"
  [rateLimitErrorDataSource]="rateLimitErrorDataSource$ | async"
  [countries]="countries$ | async"
  [cities]="cities$ | async"
  [years]="years"
  [months]="months$ | async"
  [days]="days"
  [registrationEffect$]="registrationEffect$ | async"
>
</crc-m-register-presentation>
