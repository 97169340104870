import { Injectable } from '@angular/core';
import { PromocodeService } from '../services/promocode.service';
import { PromoCodeRedeemResponse } from '../entity/promocode.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromocodeFacade {
  constructor(private readonly promoCodeService: PromocodeService) {}

  redeemCode(promoCode: string): Observable<PromoCodeRedeemResponse> {
    return this.promoCodeService.redeemPromoCode(promoCode);
  }
}
