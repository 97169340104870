import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MiniGameSearch } from '../entity';
import { MiniService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class MiniGamesSearchFacade {
  private $miniGames = new BehaviorSubject<MiniGameSearch[]>([]);
  miniGames$: Observable<MiniGameSearch[]> = this.$miniGames.asObservable();

  constructor(private readonly miniService: MiniService) {}

  requestMiniGames(filter: string) {
    this.miniService
      .searchMiniGames$(filter)
      .pipe(
        map((gamesFromAPI) => [...gamesFromAPI]),
        tap((mergedGames: MiniGameSearch[]) =>
          this.$miniGames.next(mergedGames)
        )
      )
      .subscribe();
  }

  resetMiniGames() {
    this.$miniGames.next([]);
  }
}
