import { inject, Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { LiveCallerFacade } from '../facade/livecaller.facade';
import { nextTick } from '@crc/shared';

@Injectable({
  providedIn: 'root'
})
export class LiveCallerResolver implements Resolve<boolean> {
  private readonly router: Router = inject(Router);
  private readonly liveCallerFacade: LiveCallerFacade =
    inject(LiveCallerFacade);
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const isChatAction = route.queryParams['action'] === 'open_chat';
    if (isChatAction) {
      nextTick(() => {
        this.handleChatAction(route, state);
      });
    }
    return of(isChatAction);
  }
  private handleChatAction(
    { queryParams }: ActivatedRouteSnapshot,
    { url }: RouterStateSnapshot
  ): void {
    this.liveCallerFacade.triggerLiveChatInit();
    this.router
      .navigate([url.substring(0, url.indexOf('?'))], {
        queryParams: { ...queryParams, action: null },
        queryParamsHandling: 'merge'
      })
      .then()
      .catch();
  }
}
