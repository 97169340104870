import { Injectable } from '@angular/core';
import { of, switchMap } from 'rxjs';
import { AccountFacade } from '../../../auth';
import { CasinoService } from '../services/casino.service';
import { CasinoFavoriteFacade } from './casino-favorite.facade';
import { FavoriteCasinoGame } from '../entity/favorite-casino-game';
import { replaceImage } from '@crc/shared';
import { CasinoGame } from '../entity';
import { map } from 'rxjs/operators';
import { CasinoFacade } from './casino.facade';

@Injectable()
export class CasinoFavoriteDataFacade {
  constructor(
    private accountFacade: AccountFacade,
    private readonly casinoService: CasinoService,
    private readonly casinoFavoriteFacade: CasinoFavoriteFacade,
    private readonly casinoFacade: CasinoFacade
  ) {
    this.accountFacade.customerId$
      .pipe(
        switchMap((customerId) =>
          customerId ? this.casinoService.getFavorites() : of([])
        ),
        map((games) => {
          games.forEach((favoriteGame) => {
            favoriteGame.image = replaceImage<CasinoGame>(
              'casino',
              favoriteGame.provider,
              favoriteGame
            ).image;
          });
          this.casinoFavoriteFacade.updateFavorites(
            games as FavoriteCasinoGame[]
          );
        })
      )
      .subscribe();
  }
}
