import { NgModule } from '@angular/core';
import { ToStringPipe } from './to-string/to-string.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { CustomCurrencyPipe } from './custom-currency/custom-currency.pipe';
import { UniversalPipe } from './universal/universal.pipe';
import { CashierHolidaysPipe } from './cashier-holidays/cashier-holidays.pipe';
import { CompareDatePipe } from './compare-date/compare-date.pipe';
import { LiveRaffleCountdownPipe } from './live-raffle-countdown/live-raffle-countdown.pipe';
import { LocalizedDatePipe } from './localized-date-pipe/localized-date.pipe';
import { CreditCardMaskPipe } from './creadit-card-mask-pipe/credit-card-mask.pipe';
import { FormControlNormalizePipe } from './form-control-normalize-pipe/form-control-normalize.pipe';

const pipes = [
  CustomCurrencyPipe,
  ToStringPipe,
  SafeUrlPipe,
  UniversalPipe,
  CashierHolidaysPipe,
  CompareDatePipe,
  LiveRaffleCountdownPipe,
  LocalizedDatePipe,
  CreditCardMaskPipe,
  FormControlNormalizePipe
];

@NgModule({
  imports: [],
  declarations: [...pipes],
  exports: [...pipes]
})
export class PipesModule {}
