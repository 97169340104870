import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MoreGamesFacade {
  private readonly $popupState = new BehaviorSubject<boolean>(false);
  private readonly $filterCategory = new BehaviorSubject<string>(null);
  private readonly $casinoProvider = new BehaviorSubject<string>(null);

  setPopupState(state: boolean) {
    this.$popupState.next(state);
  }

  getPopupStateValue() {
    return this.$popupState.value;
  }

  setFilterCategory(filter: string) {
    this.$filterCategory.next(filter);
  }

  getFilterCategoryValue() {
    return this.$filterCategory.value;
  }

  setCasinoProvider(provider: string) {
    this.$casinoProvider.next(provider);
  }

  getCasinoProviderValue() {
    return this.$casinoProvider.value;
  }
}
