import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Restricted } from '@crc/facade';

@Component({
  selector: 'crc-m-login-restriction-presentation',
  templateUrl: './login-restriction-presentation.component.html',
  styleUrls: ['./login-restriction-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginRestrictionPresentationComponent {
  @Input() status: Restricted;
  @Output() clicked = new EventEmitter<MouseEvent>();

  Restricted = Restricted;
}
