import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

import { BaseUrl, HttpService } from '@crc/shared';
import { map } from 'rxjs/operators';
import {
  PromoCodeError,
  PromoCodeRedeemResponse
} from '../entity/promocode.interface';

@Injectable({
  providedIn: 'root'
})
export class PromocodeService {
  constructor(private readonly apiService: HttpService) {}

  redeemPromoCode(promoCode: string): Observable<PromoCodeRedeemResponse> {
    const path = `/campaigns/promo-codes/redeem`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const body: { code: string } = { code: `${promoCode}` };

    return this.apiService
      .post$<{ data: PromoCodeRedeemResponse }, { code: string }>({
        path,
        baseUrl,
        body
      })
      .pipe(
        map((result) => result.data),
        catchError((err) => {
          if (err?.status === 429) {
            return of({
              success: false,
              errorCode: err.error?.error || PromoCodeError.TOO_MANY_REQUESTS,
              retryAfter: err.error?.retryAfter || 0
            });
          }
          return of(
            err?.error?.data ?? {
              success: false,
              errorCode: PromoCodeError.SERVICE_UNAVAILABLE
            }
          );
        })
      );
  }
}
