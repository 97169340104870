import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { sizeSettings } from './entity/popup-types';
import { getWasSessionManipulated$, reload, scaleInAndOut } from '@crc/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'crc-shared-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [scaleInAndOut(100)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('contRef', {
    read: ElementRef<HTMLDivElement>
  })
  private readonly __contRef__: ElementRef<HTMLDivElement>;
  @Input() title?: string;
  @Input() size?: sizeSettings;
  @Input() content?: TemplateRef<any>;
  @Input() headerIcon?: string;
  @Input() private strictMode = false;
  @Input() set closeDisabled(value: boolean) {
    if (value) this.popupAction = 'none';
  }
  @Input() popupAction: 'close' | 'back' | 'logout' | 'none' = 'close';
  @Input() bonusSpace = false;
  @Input() bonusSpaceAction: 'action' | 'result' | 'history';

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();
  @Output() logOut = new EventEmitter<void>();
  @Output() bonusBack = new EventEmitter<void>();
  public isTooltipVisibility = false;
  ngOnInit() {
    document.body.classList.add('overflow-hidden');
  }

  ngOnDestroy() {
    document.body.classList.remove('overflow-hidden');
  }

  onCloseModal() {
    this.closed.emit();
  }
  public ngAfterViewInit(): void {
    if (this.strictMode) {
      this.addListenerToSessionManipulation$();
    }
  }
  private addListenerToSessionManipulation$(): void {
    getWasSessionManipulated$(this.__contRef__.nativeElement)
      .pipe(
        untilDestroyed(this),
        tap((status) => {
          if (status) {
            reload();
          }
        })
      )
      .subscribe();
  }
}
