import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { map } from 'rxjs/operators';
import { RegisterFacade } from '../facade/register.facade';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterValidators {
  cachedData = new Map();
  fieldAsyncValidator = (
    registerFacade: RegisterFacade,
    field
  ): AsyncValidatorFn => {
    return (control: AbstractControl) => {
      if (this.cachedData.has(control.value)) {
        return of(this.cachedData.get(control.value));
      }
      return registerFacade
        .checkFieldUniqueness$(
          field,
          (field === 'mobile' ? '995:' : '') + control.value
        )
        .pipe(
          map((isAvailable) => {
            const result = isAvailable === false ? { used: true } : null;
            this.cachedData.set(control.value, result);
            return result;
          })
        );
    };
  };
}
