import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';

import {
  AuthState,
  EnteredCaptchaModel,
  LoginModeChangeDTO,
  SignInPayload,
  USERNAME_VALIDATORS
} from '../entity';
import { AuthFacade, CaptchaFacade, LoginPopupFacade } from '../facade';
import { ResetPasswordFacade } from '../../features';
import { loginModeChangeHelper } from '../functions';
import { handleSettingRequiredError, nextTick } from '@crc/shared';

@UntilDestroy()
@Directive()
export abstract class LoginBasePresentationComponent implements OnChanges {
  @Input() captcha: string;
  @Input() enteredCaptchaValid: boolean;
  @Input() loginPopupShowState: boolean;
  @Input() customerId: number;
  @Input() smsCodeVerified: boolean;
  @Input() buttonLoaderState: boolean;
  @Input() loginFailed: boolean;
  @Input() loginBlockedByUnknownEntity: boolean;
  @Input() inputState: boolean;
  @Input() private set loginModeChangeDTO(
    loginModeChangeDTO: LoginModeChangeDTO
  ) {
    loginModeChangeHelper(this.form.get('login'), loginModeChangeDTO);
  }
  @Output() public switchedLoginModeToOtp: EventEmitter<LoginModeChangeDTO> =
    new EventEmitter<LoginModeChangeDTO>();
  submitted$: BehaviorSubject<boolean | undefined> = new BehaviorSubject<
    boolean | undefined
  >(undefined);

  buttonLoaderState$: Observable<boolean> =
    this.authFacade.getButtonLoaderState$();
  captchaState$: Observable<boolean> = this.authFacade.getCaptchaState$();

  form: UntypedFormGroup;
  passwordC = new UntypedFormControl('', Validators.required);
  usernameC = new UntypedFormControl('', USERNAME_VALIDATORS);
  smsCodeC = new UntypedFormControl('');

  loginProcessing: boolean;
  private readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected constructor(
    private readonly authFacade: AuthFacade,
    private readonly loginPopupFacade: LoginPopupFacade,
    private readonly captchaFacade: CaptchaFacade,
    private readonly resetPasswordFacade: ResetPasswordFacade,
    private readonly fb: UntypedFormBuilder
  ) {
    this.generateForm();
    this.resetSmsCodeVerified();
    this.resetLoginFailed();

    this.form
      .get('smsCode')
      .valueChanges.pipe(
        untilDestroyed(this),
        tap((value) => {
          if (value?.length === 4) {
            this.onSubmit();
          }
        })
      )
      .subscribe();

    this.setUsernameFromStorage();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authFacade
      .getLoginFailed$()
      .pipe(
        untilDestroyed(this),
        tap((res) => (this.loginFailed = res))
      )
      .subscribe();
    if (changes.loginPopupShowState) {
      if (this.loginPopupShowState) {
        this.reset();
      }
    }

    if (changes.customerId) {
      if (changes.customerId.currentValue) {
        this.usernameC.disable();
      } else {
        this.usernameC.enable();
      }
    }

    if (changes.inputState) {
      this.reset();
      this.authFacade.setIsRestrictedState(false);
    }
  }

  onSubmit() {
    if (this.loginProcessing) {
      return;
    }
    this.authFacade.setLoginStatus$(AuthState.LOGGED_OUT);
    this.loginProcessing = true;
    this.usernameC.markAsTouched();
    this.passwordC.markAsTouched();
    handleSettingRequiredError(this.usernameC);
    handleSettingRequiredError(this.passwordC);

    if (this.form.valid) {
      if (!this.captcha?.length) {
        this.resetCaptchaControl();
      }
      const { login, password, captcha, smsCode } = this.form.getRawValue();
      const signInPayload: SignInPayload = {
        login,
        password,
        captcha
      };

      if (this.customerId) {
        this.smsCodeC.setValidators([Validators.required]);
        this.form.updateValueAndValidity();
        if (smsCode) {
          const verifySmsCodePayload: SignInPayload = this.form.getRawValue();
          this.authFacade.verifySmsCode(verifySmsCodePayload);
        } else {
          this.smsCodeC.markAsDirty();
          this.smsCodeC.markAsTouched();
          this.smsCodeC.setErrors({ required: true });
          handleSettingRequiredError(this.smsCodeC);
        }
      } else {
        this.authFacade.signIn(signInPayload);
        if (captcha) {
          this.onCaptchaClosed();
        }
      }
    }
    this.submitted$.next(true);

    timer(1500)
      .pipe(
        untilDestroyed(this),
        tap((_) => (this.loginProcessing = false))
      )
      .subscribe();
  }

  onEnterCaptchaValidValue(captcha: string) {
    const control: AbstractControl | null = this.form.get('captcha');
    control?.patchValue(captcha);
    control?.addValidators(Validators.required);
    this.form.updateValueAndValidity();
    this.onResetLoginFailed();
    this.onSubmit();
  }

  onCaptchaClosed() {
    this.captchaFacade.closeCaptchaModal();
    this.form.get('captcha')?.removeValidators(Validators.required);
  }

  onCaptchaUpdated() {
    const { login } = this.form.getRawValue();
    this.captchaFacade.updateCaptchaCode(login);
  }

  onCheckCaptchaValidity(captcha: string) {
    const payload: EnteredCaptchaModel = {
      login: this.form.get('login')?.value,
      captcha
    };
    this.form.get('captcha')?.patchValue(captcha);
    this.form.updateValueAndValidity();
    this.captchaFacade.checkCaptchaValidity(payload);

    setTimeout(() => {
      if (this.enteredCaptchaValid) {
        this.onEnterCaptchaValidValue(captcha);
      }
    }, 300);
  }

  onResetPassword() {
    const userName = this.usernameC.value;
    this.reset();
    this.usernameC.setValue(userName, {
      emitEvent: false
    });
    this.usernameC.setErrors(null);
    this.usernameC.updateValueAndValidity();
    this.onCaptchaClosed();
    this.resetPasswordFacade.open();
    this.loginPopupFacade.loginCancel();
    nextTick(() => {
      this.authFacade.setLoginBarState(false);
    });
  }

  onResetLoginFailed() {
    this.authFacade.setLoginStatus$(AuthState.LOGGED_OUT);
  }

  private resetCaptchaControl() {
    const control: AbstractControl | null = this.form.get('captcha');
    control?.reset();
    control?.removeValidators(Validators.required);
    control?.updateValueAndValidity();
  }

  private reset() {
    this.form.reset();
    this.onResetLoginFailed();
    this.submitted$.next(false);
  }

  private generateForm() {
    this.form = this.fb.group({
      login: this.usernameC,
      password: this.passwordC,
      captcha: new UntypedFormControl(''),
      smsCode: this.smsCodeC
    });
  }

  private resetSmsCodeVerified() {
    this.smsCodeC.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          if (this.smsCodeVerified === false) {
            this.authFacade.setSmsCodeVerified(null);
          }
        })
      )
      .subscribe();
  }

  private resetLoginFailed() {
    combineLatest([this.usernameC.valueChanges, this.passwordC.valueChanges])
      .pipe(
        untilDestroyed(this),
        tap(() => {
          if (this.loginFailed) {
            this.loginFailed = false;
          }
          if (this.loginBlockedByUnknownEntity) {
            this.loginBlockedByUnknownEntity = false;
            this.cdr.markForCheck();
          }
          if (this.captcha) {
            this.onCaptchaClosed();
          }
        })
      )
      .subscribe();
  }

  private setUsernameFromStorage() {
    const username = this.authFacade.getUsernameFromStorage();
    if (username && this.form.get('login')) {
      this.form.get('login')?.setValue(username);
    }
  }
}
