import { Injectable } from '@angular/core';
import { LanguageFacade } from '@crc/shared';

@Injectable({
  providedIn: 'root'
})
export class DateRangeTranslateService {
  constructor(private language: LanguageFacade) {}

  getTranslatedDays(): string[] {
    return [
      this.language.translateInstant('dates.sun'),
      this.language.translateInstant('dates.mon'),
      this.language.translateInstant('dates.tue'),
      this.language.translateInstant('dates.wed'),
      this.language.translateInstant('dates.thu'),
      this.language.translateInstant('dates.fri'),
      this.language.translateInstant('dates.sat')
    ];
  }

  getTranslatedMonths(): string[] {
    return [
      this.language.translateInstant('dates.january'),
      this.language.translateInstant('dates.february'),
      this.language.translateInstant('dates.march'),
      this.language.translateInstant('dates.april'),
      this.language.translateInstant('dates.may'),
      this.language.translateInstant('dates.june'),
      this.language.translateInstant('dates.july'),
      this.language.translateInstant('dates.august'),
      this.language.translateInstant('dates.september'),
      this.language.translateInstant('dates.october'),
      this.language.translateInstant('dates.november'),
      this.language.translateInstant('dates.december')
    ];
  }
}
