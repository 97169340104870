import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseUrl, HttpService } from '@crc/shared';
import { Avatar } from '../entity/avatars.interface';
import { ApiResponse } from '../../../../shared/types';

@Injectable({
  providedIn: 'root'
})
export class AvatarsService {
  constructor(private readonly apiService: HttpService) {}

  getAvatars(): Observable<Avatar[]> {
    const path = `/customers/profile/avatars`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<ApiResponse>({ path, baseUrl })
      .pipe(map((response) => response.data));
  }

  getUserAvatar(): Observable<Avatar> {
    const path = `/customers/profile`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<ApiResponse>({ path, baseUrl })
      .pipe(map((response) => response.data));
  }

  setUserAvatar(body: Avatar): Observable<Avatar> {
    const path = `/customers/profile`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.put$({ path, baseUrl, body });
  }
}
