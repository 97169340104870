import { Injectable, Injector, Signal } from '@angular/core';

import { combineLatest, take } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { Params, Router } from '@angular/router';
import { EnvironmentService } from '../../../environment';
import { CookieService } from '../../../utils';
import { LinkTarget } from '../../../types';
import { getWindow, openLink } from '../../../functions';
import { Language } from '../../../translate';

@Injectable({
  providedIn: 'root'
})
export class LandingsNavConfigFacade {
  public static readonly BONUS_UNIVERSE_DIRECTORY_NAME =
    'bonus-universe' as const;
  public static readonly BONUS_UNIVERSE_HOSTNAME =
    `${LandingsNavConfigFacade.BONUS_UNIVERSE_DIRECTORY_NAME}.crocobet.com` as const;
  public readonly PROMOTIONS_URL = 'https://promotions.crocobet.com';
  constructor(
    private readonly injector: Injector,
    private readonly router: Router,
    private readonly environmentService: EnvironmentService,
    private readonly cookieService: CookieService
  ) {}
  public redirectToPromotionsPage(
    directoryPath: string,
    target: LinkTarget = '_self'
  ): void {
    const rootRedirectUrl = this.parseUrl(
      `https://${getWindow().location.host}`
    );
    if (rootRedirectUrl) {
      rootRedirectUrl.pathname = `/${directoryPath}`;
      const rootRedirectUrlAsString: string = this.addHashLocationToUrl(
        rootRedirectUrl.toString()
      );
      openLink(rootRedirectUrlAsString, target);
    }
  }
  public defaultRedirectionFromBanner(
    rootUrl: string,
    target: LinkTarget = '_self'
  ): void {
    const urlAfterQuestionMark = rootUrl.split('?')[1];
    openLink(
      `https://${getWindow().location.host}/#/landings?${urlAfterQuestionMark}`,
      target
    );
  }
  public addHashLocationToUrl(url: string | null): string | null {
    if (!url) return url;

    try {
      const parsedUrl = new URL(url);
      const origin = parsedUrl.origin;
      const pathname = parsedUrl.pathname;
      const search = parsedUrl.search;

      return `${origin}/#${pathname}${search}`;
    } catch (error) {
      console.error('Invalid URL:', error);
      return url;
    }
  }
  public parseUrl(url: string | null): URL | null {
    if (url) {
      url = url.replace('/#', '');
    }
    try {
      return new URL(url);
    } catch (_) {
      return null;
    }
  }
  public removeSlashes(str: string | null): string | null {
    return str?.replace?.(/\//g, '') || str;
  }
  public checkIfUrlIsBonusUniverse(url: string | null): boolean {
    return (
      this.parseUrl(url)?.hostname ===
      LandingsNavConfigFacade.BONUS_UNIVERSE_HOSTNAME
    );
  }
  public checkIfUrlIsPromotion(url: string | null): boolean {
    const urlHostname = this.parseUrl(url)?.hostname;
    return (
      urlHostname === this.parseUrl(this.PROMOTIONS_URL)?.hostname &&
      Boolean(urlHostname)
    );
  }
  public redirectFromWidget(
    url: string,
    $userId$: Signal<number>,
    $langToken$: Signal<Language | 'ge'>
  ): void {
    /* Leaderboard type promotions should redirect on new window
     Leaderboard promotions urls starts with https://promo.crocobet.com/
     others starts with https://promotions.crocobet.com/ */
    if (url.includes('promo.')) {
      combineLatest([
        toObservable($userId$, {
          injector: this.injector
        }),
        toObservable($langToken$, {
          injector: this.injector
        })
      ])
        .pipe(take(1))
        .subscribe(([userId, lang]) => {
          const xOddsSession = this.cookieService.get(
            this.environmentService.config.authCookieName
          );
          openLink(
            `${url}/${lang}?id=${userId}&tk=${btoa(xOddsSession)}`,
            '_blank'
          );
        });
      return;
    }
    this.redirectFromWidgetBasicScenario(url);
  }
  public redirectFromWidgetBasicScenario(url: string): void {
    if (this.checkIfUrlIsBonusUniverse(url)) {
      this.router
        .navigate([
          `landings/${LandingsNavConfigFacade.BONUS_UNIVERSE_DIRECTORY_NAME}`
        ])
        .then();
      return;
    }
    if (this.checkIfUrlIsPromotion(url)) {
      const parsedUrl = this.parseUrl(url);
      if (!parsedUrl) {
        return this.defaultRedirectionFromWidget(url);
      }
      const dirParam = parsedUrl.searchParams.get('dir');
      if (dirParam) {
        const pathChunks: string[] = dirParam //parsedUrl.pathname
          .split('/')
          .filter(Boolean);
        const fullDirectoryPath = pathChunks.join('/');
        this.router.navigate([`landings/${fullDirectoryPath}`]).then();
        return;
      }
      this.defaultRedirectionFromWidget(url);
      return;
    }
    this.defaultRedirectionFromWidget(url);
  }
  public defaultRedirectionFromWidget(url: string): void {
    this.router
      .navigate(['landings'], {
        queryParams: {
          url
        }
      })
      .then();
  }
  public redirectFromBanner(
    rootUrl: string,
    target: LinkTarget = '_self'
  ): void {
    const url: URL | null = this.parseUrl(rootUrl);
    try {
      if (!url) {
        return this.defaultRedirectionFromBanner(rootUrl, target);
      }
      if (!url?.pathname?.startsWith('/landings')) {
        return openLink(rootUrl, target);
      }
      if (this.removeSlashes(url?.pathname) === 'landings') {
        const promoUrl = url.searchParams.get('url');
        if (this.checkIfUrlIsBonusUniverse(promoUrl)) {
          return this.redirectToPromotionsPage(
            `landings/${LandingsNavConfigFacade.BONUS_UNIVERSE_DIRECTORY_NAME}`,
            target
          );
        }
        if (this.checkIfUrlIsPromotion(promoUrl)) {
          const parsedUrl = this.parseUrl(promoUrl);
          if (!parsedUrl) {
            return this.defaultRedirectionFromBanner(rootUrl, target);
          }
          return this.defaultRedirectionFromBanner(rootUrl, target);
          // const pathChunks: string[] = parsedUrl.pathname.split('/').filter(Boolean);
          // const fullDirectoryPath = pathChunks.join('/');
          // return this.redirectToPromotionsPage(fullDirectoryPath, target);
        }
        return this.defaultRedirectionFromBanner(rootUrl, target);
      }
      const pathChunks: string[] = url.pathname.split('/').filter(Boolean);
      const fullDirectoryPath = pathChunks.join('/');
      return this.redirectToPromotionsPage(fullDirectoryPath, target);
    } catch (_) {
      return this.defaultRedirectionFromBanner(rootUrl, target);
    }
  }
  // private addHashLocationToUrl(url: string | null) {
  //   console.log('url', url);
  //   const parsedUrl = this.parseUrl(url);
  //   if (parsedUrl) {
  //     parsedUrl.hash = '/#';
  //   }
  //   return parsedUrl?.toString() || url || null;
  // }
  public getUrlFromRoutingOrQuery(
    params: Params,
    queryParams: Params
  ): string | null {
    const finalUrl = this._getUrlFromRoutingOrQuery(params, queryParams);
    return finalUrl;
    // return this.addHashLocationToUrl(finalUrl);
  }
  private _getUrlFromRoutingOrQuery(
    params: Params,
    queryParams: Params
  ): string | null {
    const normalizedUrlFromQuery = queryParams?.['url'] ?? null;
    const promotionLandingUrlFromParams =
      this.generatePromotionLandingUrlFromParams(params);
    if (!promotionLandingUrlFromParams) {
      return normalizedUrlFromQuery;
    }
    return promotionLandingUrlFromParams;
  }
  private generatePromotionLandingUrlFromParams(params: Params): string | null {
    const directoryPath = this.generateFullLandingDirectoryFromParams(params);
    if (!directoryPath) {
      return null;
    }
    if (
      directoryPath === LandingsNavConfigFacade.BONUS_UNIVERSE_DIRECTORY_NAME
    ) {
      return `https://${LandingsNavConfigFacade.BONUS_UNIVERSE_HOSTNAME}/#`;
    }
    const isUnderCrc = directoryPath.startsWith('crc/');
    const suffixWithHash = directoryPath?.endsWith('/') ? '#' : '/#';
    return `${this.PROMOTIONS_URL}/${directoryPath}${
      isUnderCrc ? suffixWithHash : ''
    }`;
  }
  private generateFullLandingDirectoryFromParams(params: Params): string {
    return [
      params?.['landingUrlPart1'],
      params?.['landingUrlPart2'],
      params?.['landingUrlPart3']
    ]
      .filter(Boolean)
      .join('/');
  }
}
