import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import lottiePlayer from 'lottie-web-light';
@Component({
  selector: 'crc-shared-lottie-loading',
  templateUrl: './lottie-loading.component.html',
  styleUrls: ['./lottie-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LottieLoadingComponent {
  @ViewChild('animationCont') private set handleAnimationContLoad(
    animationCont: ElementRef | null
  ) {
    if ((animationCont?.nativeElement ?? null) === null) {
      return;
    }
    const elRef: HTMLDivElement = animationCont.nativeElement;
    lottiePlayer.loadAnimation({
      autoplay: true,
      container: elRef,
      path: this.path
    });
  }
  @Input() path: string;
}
