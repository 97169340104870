import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { fromEvent, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Directive({
  selector: '[crcSharedSwipeX]'
})
export class SwipeXDirective implements OnInit {
  @Input() isSwipeForbidden = false;

  @Output() minimize = new EventEmitter<void>();

  private targetElementElementReference: HTMLElement;
  public currentTranslateX: number | null = null;
  private initialPosition: number;
  private latest: number | null = null;
  private initialWidthOfElement: number;
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly elementRef: ElementRef
  ) {}
  ngOnInit() {
    this.targetElementElementReference = this.elementRef
      .nativeElement as HTMLElement;
    fromEvent(this.viewContainerRef.element.nativeElement, 'touchstart')
      .pipe(
        untilDestroyed(this),
        tap((element) => {
          this.initialPosition = 0;
          this.initialWidthOfElement =
            this.targetElementElementReference.offsetWidth;
          this.latest = (element as TouchEvent).targetTouches[0].clientX;
          this.currentTranslateX = this.initialPosition;
        })
      )
      .subscribe();
    fromEvent(this.viewContainerRef.element.nativeElement, 'touchmove')
      .pipe(
        untilDestroyed(this),
        tap((element) => this.touchMove(element as TouchEvent))
      )
      .subscribe();
    fromEvent(this.viewContainerRef.element.nativeElement, 'touchend')
      .pipe(
        untilDestroyed(this),
        tap(() => this.touchEnd())
      )
      .subscribe();
  }
  touchMove(event: TouchEvent) {
    if (this.currentTranslateX < -this.initialWidthOfElement / 12)
      this.targetElementElementReference.style.overflowY = 'hidden';
    if (this.isSwipeForbidden || this.latest === null) return;
    if (this.latest) {
      const diff = event.targetTouches[0].clientX - this.latest;
      this.targetElementElementReference.style.transform = `translateX(${
        diff <= this.initialPosition
          ? (this.currentTranslateX = this.initialPosition + diff)
          : this.initialPosition
      }px)`;
    }
  }
  touchEnd() {
    this.targetElementElementReference.style.overflowY = 'auto';
    if (this.isSwipeForbidden) return;
    if (this.currentTranslateX) {
      if (this.currentTranslateX < -this.initialWidthOfElement / 10) {
        this.minimize.emit();
      } else {
        this.targetElementElementReference.style.transform = null;
      }
    }
  }
}
