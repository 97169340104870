import { Pipe, PipeTransform } from '@angular/core';
const colorMap: Map<number, string> = new Map([
  [1, '#FFEFB8'],
  [2, '#FFB800'],
  [5, '#55a3fe'],
  [8, '#cb41d5'],
  [10, '#fe7654'],
  [15, '#fe8d2d'],
  [20, '#1cffb2'],
  [30, '#7f61fb'],
  [40, '#f8caff']
]);
const contingencyColor = '#5d5577';
@Pipe({
  name: 'megaWheelColorInterpreter',
  pure: true,
  standalone: true
})
export class MegaWheelInterpreterPipe implements PipeTransform {
  transform(value: number): string {
    return colorMap.get(value) || contingencyColor;
  }
}
