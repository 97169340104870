<crc-egt-digital-odometer-presentation
  [type]="type"
  [odometerData]="odometerData"
  [odometerCount]="EGT_ODOMETER_COUNT"
  [odometers]="odometerConfig"
  [jackpot]="jackpot"
  [vertical]="vertical"
  [disableLogo]="disableLogo"
  [showPopupInfo]="showPopupInfo"
  [isMainPage]="isMainPage"
  (refreshOdometer)="refresh.emit()"
>
</crc-egt-digital-odometer-presentation>
