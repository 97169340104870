import { createReducer, on } from '@ngrx/store';
import * as LayoutActions from '../actions/layout.actions';
import { initialState } from './state';

export const reducer = createReducer(
  initialState,
  on(LayoutActions.toggleBurgerMenu, (state) => {
    return {
      ...state,
      isBurgerOpen: !state.isBurgerOpen
    };
  }),
  on(LayoutActions.isVisibleHeader, (state, action) => {
    return {
      ...state,
      isVisibleHeader: action.isVisible
    };
  }),
  on(LayoutActions.isVisibleFooter, (state, action) => {
    return {
      ...state,
      userBarState: {
        ...state.userBarState,
        isFooterVisible: action.isVisible
      }
    };
  }),
  on(LayoutActions.isVisibleHomeIcon, (state, action) => {
    return {
      ...state,
      userBarState: {
        ...state.userBarState,
        isHomeIconVisible: action.isVisible
      }
    };
  }),
  on(LayoutActions.setUserBarActiveItem, (state, action) => {
    return {
      ...state,
      userBarState: {
        ...state.userBarState,
        userBarActiveItem: action.isActive
      }
    };
  }),
  on(LayoutActions.setUserBarPosition, (state, action) => {
    return {
      ...state,
      userBarState: {
        ...state.userBarState,
        userBarPosition: action.position
      }
    };
  }),
  on(LayoutActions.setUserBarVisible, (state, action) => {
    return {
      ...state,
      userBarState: {
        ...state.userBarState,
        isUserBarVisible: action.visible
      }
    };
  }),
  on(LayoutActions.setBalanceVisible, (state, action) => {
    return {
      ...state,
      isBalanceVisible: action.isVisible
    };
  }),
  on(LayoutActions.loginPopupActiveTab, (state, action) => {
    return {
      ...state,
      loginPopupActiveTab: action.activeTab
    };
  }),
  on(LayoutActions.layoutPageReload, (state, action) => {
    return {
      ...state,
      layoutPageReload: action.reload
    };
  })
);
