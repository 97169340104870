import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy
} from '@angular/core';
import { LoginPopupActiveTab } from '../index';
import {
  AuthFacade,
  CaptchaFacade,
  LoginPopupFacade,
  RegisterFacade,
  ResetPasswordFacade
} from '@crc/facade';
import { LayoutStateFacade } from '../../../../layout/state';
import { LoginOtpFacade } from '@crc/facade';

@Component({
  selector: 'crc-m-login-register-popup-container',
  templateUrl: './login-register-popup-container.component.html',
  styleUrls: ['./login-register-popup-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginRegisterPopupContainerComponent implements OnDestroy {
  @Input() activeTab: LoginPopupActiveTab;

  closeResetPasswordPopup = false;
  closeLoginRegisterPopup$ = this.authFacade.getSessionStatus$();

  showLoginPopup$ = this.loginPopupFacade.getShowLoginPopupState$();
  showResetPassword$ = this.resetPasswordFacade.getShowState$();

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly captchaFacade: CaptchaFacade,
    private readonly loginPopupFacade: LoginPopupFacade,
    private readonly resetPasswordFacade: ResetPasswordFacade,
    private readonly layoutStateFacade: LayoutStateFacade,
    private readonly loginOtpFacade: LoginOtpFacade,
    private readonly registerFacade: RegisterFacade
  ) {}

  // reset sms code state if user cancelled login
  resetCustomer() {
    if (!this.authFacade.getIsLoggedIn()) {
      this.authFacade.setCustomerId(null);
      this.captchaFacade.closeCaptchaModal();
      this.authFacade.setCustomerId(null);
    }
  }

  close() {
    if(!this.registerFacade.getAccountInfo()){
    this.resetRegistration()
    }
    this.resetCustomer();
    this.loginPopupFacade.loginCancel();
    if (this.loginOtpFacade.getOTPState()) {
      this.loginOtpFacade.setOTPState(false);
    }
  }

  onResetPasswordClose() {
    this.authFacade.setLoginBarState(true);
    this.resetPasswordFacade.close();
    this.closeResetPasswordPopup = false;
  }

  resetRegistration() {
    this.registerFacade.setPersonalInfo(null);
    this.registerFacade.registrationStep.next('personalData');
  }

  ngOnDestroy(): void {
    this.layoutStateFacade.setLoginPopupActiveTab(null);
    if (this.loginOtpFacade.getOTPState()) {
      this.loginOtpFacade.setOTPState(false);
    }
  }
}
