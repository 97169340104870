<ng-container *ngIf="{ val: isActive$ | async } as isActive">
  <div
    (click)="toggleBonusBuyState(isActive.val)"
    [class.buy-bonus--active]="isActive$ | async"
    class="buy-bonus"
    data-cy="buy-bonus"
  >
    <img
      class="buy-bonus__img"
      src="./assets/icons/slots/buy-bonus.svg"
      alt="*"
    />
    <span class="buy-bonus__span" data-cy="buy-bonus-span">BUY BONUS</span>
  </div>
</ng-container>
