<div class="pragmatic-feed-cover__header">
  <!--  <span-->
  <!--    *ngIf="pragmaticCasinoFeedDto?.dealer?.name as name"-->
  <!--    class="pragmatic-feed-cover__header__item dealer-info"-->
  <!--  >-->
  <!--    {{ name }}-->
  <!--  </span>-->
  <span class="pragmatic-feed-cover__header__left-side">
    <span
      [ngClass]="{
        'person_count-bigBass': game?.game_id === '1294' && !carousel
      }"
      class="pragmatic-feed-cover__header__item person_count"
    >
      <img
        [attr.src]="'/assets/icons/features/profile/profile-custom-1.svg'"
        alt=""
      />
      <ng-container
        *ngIf="
          pragmaticCasinoFeedDto?.tableType !==
            PragmaticLiveFeedTypeEnum.TYPE_2;
          else blackJackInfo
        "
      >
        {{ pragmaticCasinoFeedDto?.totalSeatedPlayers }}
      </ng-container>
      <ng-template #blackJackInfo>
        {{ 7 - pragmaticCasinoFeedDto['availableSeats'] }}/{{ 7 }}
      </ng-template>
    </span>
    <div
      class="pragmatic-feed-cover__title pragmatic-feed-cover__title--mini"
    ></div>
    <!--    <span *ngIf="pragmaticCasinoFeedDto?.tableOpen" class="online-button">-->
    <!--      Online</span-->
    <!--    >-->
  </span>
</div>
<!--{{((pragmaticCasinoFeedDto?.['last8Results']?.slice?.(7)) || pragmaticCasinoFeedDto) | json}}-->
<!--{{ pragmaticCasinoFeedDto.tableType }}-->
<ng-container [ngSwitch]="pragmaticCasinoFeedDto.tableType">
  <div
    class="pragmatic-feed-cover__footer type--1"
    *ngSwitchCase="PragmaticLiveFeedTypeEnum.TYPE_1"
  >
    <div
      *ngFor="
      let item of pragmaticCasinoFeedDto?.['last6Results'] || [];
      let i = index
    "
      class="pragmatic-feed-cover__footer__item"
      [class.golden-bd]="i === 0 || (item?.['resultMultiplier'] > 25 || (!item?.['resultMultiplier'] && item?.['multiplier']))"
      [class.golden-bd--v2]="i === 0 && (item?.['resultMultiplier'] > 25 || (!item?.['resultMultiplier'] && item?.['multiplier']))"
      [class.red]="item.color === 'red'"
      [class.green]="item.color === 'green'"
    >
      {{ item.result }}
      <span
        class="golden-bd__banner"
        *ngIf="item?.['resultMultiplier'] > 25 && item?.['resultMultiplier'] as multiplier; else bannerV2;"
        >{{ multiplier }}x</span
      >
      <ng-template #bannerV2>
        <span
          class="golden-bd__banner"
          *ngIf="!item?.['resultMultiplier'] && item?.['multiplier'] as multiplier;"
          >{{ multiplier }}x</span
        >
      </ng-template>
    </div>
  </div>
  <!--  ==========================================================-->
  <div
    class="pragmatic-feed-cover__footer type--2"
    *ngSwitchCase="PragmaticLiveFeedTypeEnum.TYPE_2"
  >
    <!--      *ngIf="pragmaticCasinoFeedDto['betbehind']; else betBehindButton"-->
    <div
      *ngFor="let item of [].constructor(7); let i = index"
      class="pragmatic-feed-cover__footer__item"
      [class.active]="pragmaticCasinoFeedDto?.['seat' + (i+1)]"
    >
      <img
        [attr.src]="'/assets/icons/features/profile/profile-custom-2.svg'"
        alt=""
      />
    </div>
  </div>
  <!--  =============================-->
  <div
    class="pragmatic-feed-cover__footer type--3"
    *ngSwitchCase="PragmaticLiveFeedTypeEnum.TYPE_3"
  >
    <div
      *ngFor="
        let item of pragmaticCasinoFeedDto?.['last6Results'] || [];
        let i = index
      "
      [style.--mega-wheel-color]="item?.result | megaWheelColorInterpreter"
      class="pragmatic-feed-cover__footer__item"
      [class.bonus]="item?.['multiplier'] && item?.['result'] === item?.['slot']"
      [class.with-gap]="i === 0"
    >
      <span
        class="bonus-banner"
        *ngIf="item?.['result'] === item?.['slot'] && item?.['multiplier'] as multiplier"
        >x{{ multiplier }}</span
      >
      <span
        class="extra-layer"
        [style.color]="item?.result <= 2 ? '#2A1E63' : '#ffffff'"
      >
        {{ item?.result }}
      </span>
    </div>
  </div>
  <!--  =======================================-->
  <div
    class="pragmatic-feed-cover__footer type--5"
    *ngSwitchCase="PragmaticLiveFeedTypeEnum.TYPE_5"
  >
    <ng-container
      *ngSync="{
        fontCoefficient: isMobile ? 1 : 1.5,
        width1: isMobile ? 52 / 1.38 : 52,
        width2: isMobile ? 38 / 1.38 : 38,
        height1: 20,
        height2: 16
      } as spaceParams"
    >
      <div
        *ngFor="
        let item of pragmaticCasinoFeedDto?.['gameResult'] || [];
        let i = index
      "
        class="pragmatic-feed-cover__footer__item"
      >
        <span
          *ngIf="i < 5 && spaceParams.fontCoefficient as coefficient"
          [style.font-size]="coefficient * (i === 0 ? 10 : 8) + 'px'"
          class="result"
          >{{ item?.result }}x</span
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="i < 5 && spaceParams.fontCoefficient as coefficient"
          [style.width]="
            (i === 0
              ? coefficient * spaceParams.width1
              : coefficient * spaceParams.width2) + 'px'
          "
          [style.height]="
            (i === 0
              ? coefficient * spaceParams.height1
              : coefficient * spaceParams.height2) + 'px'
          "
          viewBox="0 0 52 18"
          fill="none"
        >
          <mask
            id="path-1-inside-1_8934_136133"
            [attr.fill]="i === 0 ? 'white' : 'none'"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 17.7563C4.08897 16.8143 7.13033 13.2514 7.13033 9C7.13033 4.74861 4.08897 1.18571 0 0.243705V0H43C47.9706 0 52 4.02944 52 9C52 13.9706 47.9706 18 43 18H0V17.7563Z"
            />
          </mask>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 17.7563C4.08897 16.8143 7.13033 13.2514 7.13033 9C7.13033 4.74861 4.08897 1.18571 0 0.243705V0H43C47.9706 0 52 4.02944 52 9C52 13.9706 47.9706 18 43 18H0V17.7563Z"
            [attr.fill]="
              item?.result && game?.game_id === '1294'
                ? (item?.result | bigBassColorInterpreter)
                : (item?.result | spaceManColorInterpreter)
            "
          />
          <path
            d="M0 17.7563L-0.224497 16.7818L-1 16.9605V17.7563H0ZM0 0.243705H-1V1.03952L-0.224497 1.21818L0 0.243705ZM0 0V-1H-1V0H0ZM0 18H-1V19H0V18ZM6.13033 9C6.13033 12.7552 3.44083 15.9374 -0.224497 16.7818L0.224497 18.7308C4.73712 17.6912 8.13033 13.7475 8.13033 9H6.13033ZM-0.224497 1.21818C3.44083 2.06259 6.13033 5.24476 6.13033 9H8.13033C8.13033 4.25246 4.73712 0.308837 0.224497 -0.73077L-0.224497 1.21818ZM1 0.243705V0H-1V0.243705H1ZM0 1H43V-1H0V1ZM43 1C47.4183 1 51 4.58172 51 9H53C53 3.47715 48.5228 -1 43 -1V1ZM51 9C51 13.4183 47.4183 17 43 17V19C48.5228 19 53 14.5228 53 9H51ZM43 17H0V19H43V17ZM1 18V17.7563H-1V18H1Z"
            [attr.fill]="i === 0 ? 'white' : 'none'"
            mask="url(#path-1-inside-1_8934_136133)"
          />
        </svg>
      </div>
    </ng-container>
  </div>
</ng-container>
