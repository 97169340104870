export const DepositActiveModeEnum = {
  CREDIT_CARD: 0,
  APPLE_PAY: 1
} as const;
export type DepositActiveModeUnionType =
  (typeof DepositActiveModeEnum)[keyof typeof DepositActiveModeEnum];
export interface PaymentProvider {
  depositDefaultTransferAmounts: number[];
  displayIconMediaId: string;
  displayName: string;
  providerCode: string;
  providerType: string;
  supportedDepositCurrencies: Currencies[];
  supportedDepositMethods: string[];
  supportedDirections: string[];
  supportedWithdrawMethods: string[];
  supportedWithdrawalCurrencies: Currencies[];
  withdrawDefaultTransferAmounts: number[];
  logo?: string;
  withdrawAmountLimits: PaymentProviderLimit;
  depositAmountLimits: PaymentProviderLimit;
  methodType?: string;
}

export enum Currencies {
  GEL = 'GEL',
  USD = 'USD'
}
// OTHER CURRENCIES TO BO ADDED
export interface PaymentProviderLimit {
  [transactionMethod: string]: {
    [currency: string]: {
      min: number;
      max: number;
    };
  };
}
export const ApplePayStateEnum = {
  PRISTINE: -1,
  INITIALIZED: 0,
  SUCCESS: 1,
  FAILED: 2
} as const;
export type IApplePayFeatureStateEnum =
  (typeof ApplePayStateEnum)[keyof typeof ApplePayStateEnum];

export interface TransferRequest {
  providerType: ProviderTypes;
  providerCode: string;
  currency: string;
  amount: number;
  commission: number;
  destinationCurrency: string;
  method: PaymentMethodTypes;
  saveMethod?: boolean;
  playerMethodId?: number;
  verificationCode?: string;
}
export enum ProviderTypes {
  WALLET = 'WALLET',
  PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
  INSTANT_DEPOSIT = 'INSTANT_DEPOSIT',
  INSTANT_PAYMENT = 'INSTANT_PAYMENT',
  CASHIER = 'CASHIER',
  CONSOLE = 'CONSOLE'
}
export enum PaymentMethodTypes {
  BANK_CARD = 'BANK_CARD',
  DIGITAL_WALLET = 'DIGITAL_WALLET',
  INSTANT_TRANSFER = 'INSTANT_TRANSFER',
  CASHIER = 'CASHIER',
  CONSOLE = 'CONSOLE'
}

export interface TransferDepositResponse {
  details: TransactionDetails;
  content: TransactionContent;
  externalRequestUrl?: string;
  reasonCode?: string;
}
export interface TransactionDetails {
  transferId: string;
  providerType: ProviderTypes;
  provider: string;
  direction: TransactionTypes;
  method: PaymentMethodTypes;
  playerId: string;
  amount: number;
  currency: string;
  saveMethod: boolean;
  playerConfirmationStatus: string;
  approvalStatus: ApprovalStatuses;
}
export interface TransactionContent {
  type: string;
  content: string;
}
export enum TransactionTypes {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW'
}
export enum ApprovalStatuses {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED'
}
export enum BankType {
  Tbc = 'UFC',
  Bog = 'GEORGIAN_CARD',
  Fondy = 'CROCO_TRANSFER_FONDY'
}

export const DefaultFondyDigitalProviderConfig: PaymentProvider = {
  depositAmountLimits: {
    DIGITAL_WALLET: { USD: { min: 0, max: 0 }, GEL: { min: 1, max: 100000 } }
  },

  depositDefaultTransferAmounts: [10, 20, 50, 100, 500],

  displayIconMediaId: 'FONDY',

  displayName: 'FONDY',

  providerCode: 'CROCO_TRANSFER_FONDY',

  providerType: 'PAYMENT_GATEWAY',

  supportedDepositCurrencies: [Currencies.GEL, Currencies.USD],

  supportedDepositMethods: ['DIGITAL_WALLET'],

  supportedDirections: ['DEPOSIT'],

  withdrawDefaultTransferAmounts: [10, 20, 50, 100, 500]
} as unknown as PaymentProvider;
