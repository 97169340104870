export function addQueryParamToUrl(
  url: string,
  params: { value: string; key: string }[]
) {
  params.forEach((param) => {
    url = `${url}${url.includes('?') ? '&' : '?'}${param.key}=${param.value}`;
  });

  return url;
}
