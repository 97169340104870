<div class="wrap">
  <div *ngIf="!bonusCards?.length">
    <div>
      <div class="wrap__swiper-skeleton"></div>
    </div>
  </div>
  <ng-template #slide let-bonusCards let-index>
    <div
      *ngIf="!bonusCards?.length"
      (click)="
        onSwiperCard(
          bonusCards?.balanceId,
          bonusCards?.rate,
          {
            fromBalanceId: bonusCards?.fromBalanceId,
            toBalanceID: bonusCards?.balanceId
          },
          bonusCards?.visualDetails?.iconType,
          bonusCards?.promotionDetails?.landingUrl
        )
      "
      class="wrap__card"
      [class.active]="activeBalanceId === bonusCards?.balanceId"
      [class.active__mobile-top]="
        activeBalanceId === bonusCards?.balanceId && isMobile
      "
    >
      <ng-container *ngIf="activeBalanceId === bonusCards?.balanceId">
        <img
          class="active__check"
          src="/assets/icons/features/bonus-coin/verified.svg"
          alt="check"
        />
      </ng-container>
      <img
        class="wrap__card-background"
        [src]="bonusCards?.visualDetails?.bannerImage"
        alt="background"
      />
      <ng-container [ngSwitch]="bonusCards?.visualDetails?.iconType">
        <div *ngSwitchCase="'SPIN'" class="wrap__card-bonus-count">
          <img src="assets/icons/features/bonus-coin/spin.svg" alt="spin" />
          <span class="wrap__card-bonus-count-num">{{
            bonusCards?.amount
          }}</span>
        </div>
        <div *ngSwitchCase="'KEY'" class="wrap__card-bonus-count">
          <img src="assets/icons/features/bonus-coin/promo.svg" alt="promo" />
          <span class="wrap__card-bonus-count-num">{{
            bonusCards?.amount
          }}</span>
        </div>
        <div *ngSwitchCase="'heart'" class="wrap__card-bonus-count">
          <img src="assets/icons/features/bonus-coin/heart.svg" alt="heart" />
          <div class="wrap__card-bonus-count-num-heart">
            {{ bonusCards?.amount }}
          </div>
        </div>
      </ng-container>
      <div class="wrap__card-fade"></div>
    </div>
  </ng-template>
  <crc-shared-swiper
    *ngIf="bonusCards"
    [isMobile]="isMobile"
    class="background-transparent scrollable-slider2"
    [class.bonus-arrow]="!isMobile"
    [pagination]="{ single: false }"
    [navigation]="{ single: !isMobile }"
    [carousel]="true"
    [autoPlay]="false"
    [borderRadius]="false"
    [cssMode]="isMobile"
    [breakpoints]="{
      '320': {
        slidesPerView: 2
      },
      '369': {
        slidesPerView: 2.5
      },
      '400': {
        slidesPerView: 2.6
      },
      '470': {
        slidesPerView: 2.7
      },
      '520': {
        slidesPerView: 3
      },
      '560': {
        slidesPerView: 2.7
      }
    }"
    [loop]="false"
    [freeMode]="true"
    [scrollbar]="{ draggable: true }"
    [templateRef]="{ template: slide, context: bonusCards }"
  ></crc-shared-swiper>
</div>
