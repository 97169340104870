import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'crc-m-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultComponent {
  @Input() success: boolean;
  @Input() textHeader: string;
  @Input() textBody: string;
  @Input() prize = '';
  @Input() buttonTitle: string;
  @Input() hideImg: boolean;
  @Output() buttonClicked = new EventEmitter<void>();
}
