import {Injectable} from '@angular/core';
import {PersonalInfoService} from '../services/personal-info.service';
import {SecurityOptions} from '../entity/security-options';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PersonalInfoFacade {
  security$ = this.personalInfoService.getSubscriptions();

  constructor(private readonly personalInfoService: PersonalInfoService) {}

  updateSubscriptions(options: SecurityOptions): Observable<void> {
    return this.personalInfoService.updateSubscriptions(options);
  }
}
