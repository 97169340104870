<div class="dropdown dropdown--absolute" *ngIf="dropdownVisible">
  <div class="dropdown__header">
    {{ 'captcha.title' | translate }}
    <span class="dropdown__close" (click)="onCloseDropdown()">
      <img src="assets/icons/shapes/close.svg" alt="Close" />
    </span>
  </div>
  <div class="dropdown__body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="dropdown__text">
        {{ 'captcha.enter_security_code' | translate }}
      </div>
      <div
        class="dropdown__captcha dropdown__captcha--margin"
        [innerHTML]="captchaSafe"
      ></div>
      <div class="d-flex flex--nowrap">
        <crc-shared-input
          [class.error]="enteredCaptchaValid === false || captchaUpdateRequested"
          class="show-icon captcha"
          type="text"
          placeholder="{{ 'code' | translate }}"
          formControlName="captcha"
          data-cy="form-captcha"
        >
        </crc-shared-input>
        <div
          class="captcha-reload"
          data-cy="captcha-reload"
          (click)="onCaptchaUpdated()"
        >
          <img src="/assets/icons/shapes/reset.svg" alt="reset" />
        </div>
      </div>
      <button
        [ngClass]="{
          'btn--success': enteredCaptchaValid && !captchaUpdateRequested,
          'btn--dark': !enteredCaptchaValid || captchaUpdateRequested
        }"
        class="btn btn--spacing"
        type="submit"
        data-cy="captcha-submit"
        [disabled]="
          form.invalid || !enteredCaptchaValid || captchaUpdateRequested
        "
      >
        {{ 'confirm' | translate }}
      </button>
    </form>
  </div>
</div>
