import { Injectable } from '@angular/core';

import { ProfileSideMenuItem } from '../entity/profile-side-menu-item';
import { ProfileMenuItem } from '../entity/profile-menu-item';
import { PROFILE_SIDE_MENU_ITEMS } from '../constants/profile-side-menu-items';
import { PROFILE_MENU_ITEMS } from '../constants/profile-menu-items';

@Injectable({
  providedIn: 'root'
})
export class ProfileMenuFacade {
  getProfileSideMenuItems(): ProfileSideMenuItem[] {
    return PROFILE_SIDE_MENU_ITEMS;
  }

  getProfileMenuItems(): ProfileMenuItem[] {
    return PROFILE_MENU_ITEMS;
  }
}
