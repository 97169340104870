import { NgModule } from '@angular/core';

import { ComponentsModule, IconComponent } from '@crc/components';
import { SharedModule as LibSharedModule } from '@crc/shared';
import { NgxMaskModule } from 'ngx-mask';

import { CarouselComponent } from './carousel/carousel.component';
import { BuyBonusComponent } from './buy-bonus/buy-bonus.component';
import { CardHeaderComponent } from './card-header/card-header.component';
import { PopupComponent } from './popup/popup.component';
import { GamesTitleComponent } from './games-title/games-title.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { CaptchaComponent } from './captcha/captcha.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { StatusIconComponent } from './status-icon/status-icon.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { StatusIconResultComponent } from './status-icon-result/status-icon-result.component';
import { ToggleComponent } from './toggle/toggle.component';
import { UserActionResultComponent } from './user-action-result/user-action-result.component';
import { MSelectComponent } from './m-select/m-select.component';
import { TimerButtonComponent } from './timer-button/timer-button.component';
import { SearchComponent } from './search/search.component';
import { TimelineComponent } from './timeline/timeline.component';
import { ResultComponent } from './result/result.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { CopyPopupComponent } from './copy-popup/copy-popup.component';
import { KeyboardComponent } from './keyboard/keyboard.component';
import { MaskedInputComponent } from './masked-input/masked-input.component';
import { ActivatePasscodeComponent } from './passcode/activate-passcode/activate-passcode.component';
import { UsePasscodeComponent } from './passcode/use-passcode/use-passcode.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { LoaderComponent } from './loader/loader.component';
import { NewBadgeComponent } from './new-badge/new-badge.component';

const components = [
  CardHeaderComponent,
  PopupComponent,
  CarouselComponent,
  BuyBonusComponent,
  InputComponent,
  ButtonComponent,
  GamesTitleComponent,
  FavoriteComponent,
  CaptchaComponent,
  IconButtonComponent,
  StatusIconComponent,
  StatusIconResultComponent,
  ToggleComponent,
  CheckboxComponent,
  UserActionResultComponent,
  MSelectComponent,
  TimerButtonComponent,
  SearchComponent,
  TimelineComponent,
  ResultComponent,
  PhoneInputComponent,
  CopyPopupComponent,
  KeyboardComponent,
  MaskedInputComponent,
  ActivatePasscodeComponent,
  UsePasscodeComponent,
  LoaderComponent,
  TooltipComponent,
  NewBadgeComponent
];

@NgModule({
  declarations: [...components],
  exports: [LibSharedModule, ComponentsModule, ...components],
  imports: [LibSharedModule, ComponentsModule, NgxMaskModule, IconComponent]
})
export class SharedModule {}
