import { NgModule } from '@angular/core';
import {
  TranslateLoader,
  TranslateModule as NgxTranslateModule
} from '@ngx-translate/core';

import { LanguageFacade } from './services/language.facade';
import { HttpCustomLoader } from './loaders/http-custom-loader';
import { CATEGORY_CONFIG } from './loaders/category-config-injection-token';

@NgModule({
  exports: [NgxTranslateModule],
  imports: [
    NgxTranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useExisting: HttpCustomLoader
      }
    })
  ],
  providers: [
    { provide: CATEGORY_CONFIG, useValue: 'global' },
    HttpCustomLoader
  ]
})
export class TranslateModule {
  constructor(private readonly languageFacade: LanguageFacade) {
    this.languageFacade.setCurrent(this.languageFacade.current);
  }
}
