import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  filter,
  from,
  lastValueFrom,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
  timer,
  toArray
} from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import {
  AnalyticsService,
  Language,
  LanguageFacade,
  MatomoAnalyticsService,
  nextTick
} from '@crc/shared';
import { NgSelectItem } from '@crc/components';
import { RegisterService } from '../services/register.service';
import { AuthFacade, SignInPayload } from '../../../auth';
import {
  citiesDropDownData,
  countriesDropDownData,
  formatDateOfBirth
} from '../functions/functions';
import { CatalogFacade } from '../../catalogs';
import {
  customRegisterFormSubmitInterface,
  DocumentIdParams,
  IPreRegisterErrorResponse,
  Register
} from '../entity/register.interface';
import { RegisterEventManagerService } from '../services/register-event-manager.service';
import { FastTrackFacade } from '../../fast-track';
import { ApiResponse } from '../../../shared';

@Injectable({
  providedIn: 'root'
})
export class RegisterFacade {
  public readonly SB_RESPONSE_EXCEPTION = 'SB_RESPONSE_EXCEPTION' as const;
  public readonly CUSTOMER_INCORRECT_EXCEPTION = 'CUSTOMER_INCORRECT' as const;
  public readonly COUNTRY_INCORRECT_EXCEPTION = 'COUNTRY_INCORRECT' as const;
  $verificationCodeSent = new Subject<boolean>();
  $registerSuccess = new Subject<[boolean, unknown]>();
  $registerSuccessShowPopup = new Subject<boolean>();
  $countDown = new Subject<number>();
  $successBtnWidth = new Subject<number>();
  public readonly $timerButtonManualResetAction = new Subject<void>();
  public readonly $isLoading: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public readonly $isLoadingMob: BehaviorSubject<{ status: boolean }> =
    new BehaviorSubject({
      status: false
    });
  private signInPayload: SignInPayload;
  private accountInfoFormCache: UntypedFormGroup;
  private personalInfoFormCache: UntypedFormGroup;
  private personalInfoFormValue: unknown;
  private scrollCache: number;
  private agreementText = 'register.sms-on.text';
  private agreementType = 'SMSON';

  registrationStep = new BehaviorSubject<
    'personalData' | 'accountData' | 'finished'
  >(this.getAccountInfo() ? 'accountData' : 'personalData');
  $cdaShowError = new Subject<string | boolean>();
  $cdaErrorCode = new Subject<string>();
  $cdaNationalityShowError = new Subject<string>();
  $rateLimitErrorShowRemainingTime = new BehaviorSubject<number>(0);
  public readonly $isRegistrationWizardOpen: Subject<boolean> = new Subject();

  constructor(
    private readonly registerService: RegisterService,
    private readonly analyticsService: AnalyticsService,
    private readonly matomoAnalyticsService: MatomoAnalyticsService,
    private readonly catalogFacade: CatalogFacade,
    private readonly authFacade: AuthFacade,
    private readonly router: Router,
    private readonly languageFacade: LanguageFacade,
    private readonly registerEventManagerService: RegisterEventManagerService,
    private readonly fastTrackFacade: FastTrackFacade
  ) {}
  public manuallyResetRegistrationStep(): void {
    nextTick(() => {
      this.setPersonalInfo(null);
      this.registrationStep.next('personalData');
    });
  }

  checkFieldUniqueness$(field: string, value: string): Observable<boolean> {
    return this.registerService.checkFieldUniqueness$(field, value);
  }

  getSignInPayload(): SignInPayload {
    return this.signInPayload;
  }

  setCdaShowError(error: string | boolean): void {
    this.$cdaShowError.next(error);
  }

  getCdaShowError$(): Observable<string | boolean> {
    return this.$cdaShowError.asObservable();
  }

  setCdaNationalityShowError(error: string): void {
    this.$cdaNationalityShowError.next(error);
  }

  getCdaNationalityShowError$(): Observable<string> {
    return this.$cdaNationalityShowError.asObservable();
  }

  sendVerificationCode$(mobile: string): Observable<boolean | null | number> {
    // Google Analytics event tracking for SMS sent
    return this.registerService.sendVerificationCode$(mobile).pipe(
      switchMap((res: ApiResponse<boolean> | IPreRegisterErrorResponse) => {
        this.checkAndHandlePreRegisterRateLimitError(res);
        const successStatus = !!res?.['data'];
        this.$verificationCodeSent.next(successStatus);
        if (successStatus) {
          this.$rateLimitErrorShowRemainingTime.next(0);
          return this.countDownTimer();
        } else {
          return of(null);
        }
      })
    );
  }
  private checkAndHandlePreRegisterRateLimitError(
    res: ApiResponse<boolean> | IPreRegisterErrorResponse
  ): void {
    if (res?.['payload']?.errorCode === 'RATE_LIMIT') {
      const remainingTime =
        (res as IPreRegisterErrorResponse).payload?.['retryAfter'] || 60;
      this.$rateLimitErrorShowRemainingTime.next(remainingTime);
    }
  }

  register$(registerBody: Register): Observable<[boolean, unknown]> {
    registerBody = {
      ...registerBody,
      agreementType: this.agreementType,
      agreementText: this.languageFacade.translateInstant(this.agreementText)
    };
    return this.registerService.register$(registerBody).pipe(
      map(([res, data]: [boolean, unknown]) => {
        if (
          data['error'] &&
          data['error']?.payload &&
          data['error']?.payload?.errorCode
        ) {
          const errorCode = data['error']?.payload?.errorCode;
          if (errorCode) {
            this.$cdaErrorCode.next(errorCode);
          }

          if (errorCode !== this.SB_RESPONSE_EXCEPTION) {
            this.setCdaShowError(errorCode);
            this.setCdaNationalityShowError(errorCode);
            this.registrationStep.next('personalData');
          }

          this.$isLoading.next(false);
          return;
        }

        this.$isLoading.next(false);
        this.$registerSuccess.next([res, data]);
        if (res) {
          this.signInPayload = {
            login: registerBody.loginName,
            password: registerBody.password
          };

          this.$registerSuccessShowPopup.next(true);
        }
        return [res, data];
      })
    );
  }

  getCities$(): Observable<NgSelectItem[]> {
    return this.languageFacade.current$.pipe(
      switchMap((lang: Language) =>
        this.catalogFacade.getData$('nationality', lang)
      ),
      map((cities) => citiesDropDownData(cities))
    );
  }

  getCountries$(): Observable<NgSelectItem[]> {
    return this.languageFacade.current$.pipe(
      switchMap((lang: Language) =>
        this.catalogFacade.getData$('country', lang)
      ),
      map((countries) => countriesDropDownData(countries)),
      map((data) =>
        [...data].sort((a, b) => (a.key === 'GE' ? -1 : b.key === 'GE' ? 1 : 0))
      )
    );
  }

  countDownTimer(): Observable<number> {
    const COUNTDOWN_TIME = 60;

    return timer(0, 1000).pipe(
      take(COUNTDOWN_TIME + 1),
      map((i) => COUNTDOWN_TIME - i),
      tap((res) => this.$successBtnWidth.next((100 / 60) * (60 - res))),
      tap((res) => {
        this.$countDown.next(res);
      })
    );
  }

  getCdaErrorCode$(): Observable<string> {
    return this.$cdaErrorCode.asObservable();
  }

  setAccountInfo(form: FormGroup): void {
    this.accountInfoFormCache = form;
  }

  getAccountInfo(): UntypedFormGroup {
    return this.accountInfoFormCache;
  }

  setPersonalInfo(form: UntypedFormGroup): void {
    this.personalInfoFormCache = form;
    this.personalInfoFormValue = form?.getRawValue();
  }

  getPersonalInfo(): UntypedFormGroup {
    return this.personalInfoFormCache;
  }

  getPersonalInfoValue(): unknown {
    return this.personalInfoFormValue;
  }

  setScrollCache(cache: number): void {
    this.scrollCache = cache;
  }

  getScrollCache(): number {
    return this.scrollCache;
  }

  //======================================================== new web registration below this
  public initialSubmit$(data: {
    [key: string]: string;
  }): Observable<{ [key: string]: boolean }> {
    return from(Object.keys(data)).pipe(
      mergeMap((key: string) => {
        return this.registerService.checkFieldUniqueness$(key, data[key]).pipe(
          take(1),
          map((res) => {
            return { [key]: res };
          })
        );
      }),
      toArray(),
      map((arr: { [key: string]: boolean }[]) => {
        return arr.reduce((accumulator, currentValue) => {
          return {
            ...accumulator,
            ...currentValue
          };
        }, {});
      })
    );
  }

  public renewedSendVerificationCode$(
    number: string
  ): Observable<ApiResponse<boolean> | IPreRegisterErrorResponse> {
    // Google Analytics Event Tracking for SMS
    // this.analyticsService.track('SMS', 'SmsSent', 0);
    return this.registerService.sendVerificationCode$(number);
  }

  public renewedFinalSubmit$(
    formValue: Register
  ): Observable<[boolean, unknown]> {
    formValue = {
      ...formValue,
      agreementType: this.agreementType,
      agreementText: this.languageFacade.translateInstant(this.agreementText)
    };

    return this.registerService.register$(formValue);
  }

  public renewedLoginAfterRegisterSuccess$(
    signInPayload: SignInPayload
  ): Observable<boolean> {
    this.authFacade.signIn(signInPayload);
    return this.authFacade.isLoggedIn$().pipe(
      take(1),
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return from(this.router.navigateByUrl('profile/verification')).pipe(
            map(() => isLoggedIn)
          );
        }
        return of(isLoggedIn);
      })
    );
  }

  public getFormSubmit$(
    form: FormGroup
  ): Observable<customRegisterFormSubmitInterface> {
    return this.registerEventManagerService.get$<void>('formSubmit').pipe(
      switchMap(() => {
        this.$isLoading.next(true);
        const data: { [key: string]: string } = {
          login: form.get('loginName').value,
          ['passport-number']: form.get('documentId').value?.toUpperCase?.(),
          email: form.get('email').value,
          mobile: `995${form.get('phoneNumber').value}`
        };
        return this.initialSubmit$(data);
      }),
      switchMap(({ login, email, mobile, ['passport-number']: passport }) => {
        if (!email) {
          this.setErrorToControl(form, 'email', 'emailTaken', true);
        }
        if (!mobile) {
          this.setErrorToControl(form, 'phoneNumber', 'mobileTaken', true);
        }
        if (!login) {
          this.setErrorToControl(form, 'loginName', 'loginNameTaken', true);
        }
        if (!passport) {
          this.setErrorToControl(form, 'documentId', 'idTaken', true);
        }
        const documentParams: DocumentIdParams = {
          phoneNumber: '995' + form.get('phoneNumber').value,
          isForeignCitizen: form.get('countryCode').value !== 'GE',
          documentIdParams: {
            documentNumber: form.get('documentId').value?.toUpperCase?.(),
            birthYear: form.get('year').value,
            citizenship: form.get('nationality').value
          }
        };
        if (
          !Object.values({
            login,
            email,
            mobile,
            passport
          }).every((status) => status)
        ) {
          return of({
            sequenceFailed: true
          });
        }
        if (documentParams.isForeignCitizen) {
          //success
          return of({
            goToSmsVerification: true,
            sequenceFailed: false
          });
        }

        return of({
          goToSmsVerification: true,
          sequenceFailed: false
        });
      }),
      tap((res) => {
        if (res?.['goToSmsVerification']) {
          const verifySmsCodeControl: AbstractControl = new FormControl('', [
            Validators.required,
            Validators.pattern(/^[0-9]{6}$/)
          ]);
          form.setControl('verifySmsCodeControl', verifySmsCodeControl);
        }
        this.$isLoading.next(false);
      })
    );
  }

  public getFormRegister$(
    form: FormGroup
  ): Observable<{ result: boolean | null }> {
    return this.registerEventManagerService.get$('formRegister').pipe(
      switchMap(() => {
        this.$isLoading.next(true);

        return this.renewedFinalSubmit$(this.getRegisterData(form));
      }),
      map(([res, data]: [boolean, unknown]) => {
        if (
          data['error'] &&
          data['error']?.payload &&
          data['error']?.payload?.errorCode
        ) {
          this.handleFormErrors(form, data['error']?.payload?.errorCode);
          this.$isLoading.next(false);
          return;
        }

        if (!res) {
          if (data?.['some']?.((obj) => obj?.propertyName === 'email')) {
            this.setErrorToControl(form, 'email', 'pattern', true);
          } else {
            this.setErrorToControl(
              form,
              'verifySmsCodeControl',
              'wrongCode',
              true
            );
          }
          this.analyticsService.track({
            action: 'Register',
            event: 'registration_submitted',
            registrationSubmitted: 'fail'
          });
          // Matomo Analytics Event Tracking for Registration
          this.matomoAnalyticsService.selectTrackingEvent(
            'registrationSubmittedFail'
          );

          lastValueFrom(
            this.fastTrackFacade.track('registration').pipe(map(() => res))
          )
            .then()
            .catch();
        } else {
          this.$timerButtonManualResetAction.next();
          // Google Analytics Event Tracking for Registration
          this.analyticsService.track({
            action: 'Register',
            event: 'registration_submitted',
            registrationSubmitted: 'success'
          });
        }

        this.$isLoading.next(false);
        return {
          result: res
        };
      })
    );
  }

  handleFormErrors(form: FormGroup, errorCode: string): void {
    form.setErrors({
      ...form.errors,
      cdaError: errorCode
    });

    form.get('documentId')?.setErrors({
      cdaError: true
    });
  }

  //===============================
  //=================================
  public getLoginAfterSuccess$(form: FormGroup): Observable<boolean> {
    return this.registerEventManagerService.get$('loginAfterSuccess').pipe(
      switchMap(() => {
        return this.renewedLoginAfterRegisterSuccess$({
          login: form.get('loginName').value,
          password: form.get('password').value
        });
      }),
      switchMap((res) => {
        return of(res);
      })
    );
  }

  public getSendSMS$(
    form: FormGroup
  ): Observable<ApiResponse<boolean> | IPreRegisterErrorResponse> {
    return this.registerEventManagerService.get$('sendSMS').pipe(
      switchMap(() => {
        form.get('verifySmsCodeControl')?.reset();
        return this.renewedSendVerificationCode$(
          '995:' + form.get('phoneNumber').value
        );
      })
    );
  }

  //==================================
  //====================================
  private getRegisterData(form: FormGroup): Register {
    const year = form.get('year').value;
    const month = form.get('month').value;
    const day = form.get('day').value;
    const promoCodeWeb = form.get('promoCodeWeb').value;
    const value: Register = {
      ...form.getRawValue(),
      verificationCode: form.get('verifySmsCodeControl').value,
      address: promoCodeWeb ? promoCodeWeb : 'test',
      phoneNumber: '995:' + form.get('phoneNumber').value,
      dateOfBirth: formatDateOfBirth(year, month, String(day))
    };
    delete value?.['promoCodeWeb'];
    value.documentId = value.documentId?.toUpperCase?.() || value.documentId;
    return value;
  }

  private setErrorToControl(
    form: FormGroup,
    controlName: string,
    key: string,
    value: string | null | boolean
  ): void {
    const control: AbstractControl | FormControl = form.get(controlName);
    control.setErrors({
      ...control.errors,
      [key]: value
    });
  }

  public getIsUsernameUnique$(form: FormGroup): Observable<{
    status: boolean;
  }> {
    return this.registerEventManagerService
      .get$('checkUsernameUniqueness')
      .pipe(
        map(() => {
          return form.get('loginName').value;
        }),
        debounceTime(107),
        filter((curVal) => !!curVal && !/\s/.test(curVal)),
        switchMap((curVal) => {
          return this.registerService.checkFieldUniqueness$('login', curVal);
        }),
        map((status) => {
          if (!status) {
            this.setErrorToControl(form, 'loginName', 'loginNameTaken', true);
            this.analyticsService.track({
              event: 'registerLoginName',
              registerLoginName: 'NOT_UNIQUE'
            });
            this.matomoAnalyticsService.selectTrackingEvent(
              'registerLoginNameINVALID'
            );
          }
          return {
            status
          };
        })
      );
  }
  public getRateLimitErrorDataSource$(): Observable<number> {
    return this.$rateLimitErrorShowRemainingTime.asObservable().pipe(
      switchMap((time) => {
        if (time) {
          return timer(0, 1000).pipe(
            take(time + 1),
            map((i) => time - i)
          );
        }
        return of(0);
      })
    );
  }
}
