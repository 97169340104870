import { CrmNotification, NotificationResponse } from '../entity/crm.interface';
import { BonusItem } from '../entity/bonuses.interface';
import { FastTrackNotification } from '../../fast-track';

export function generateCrmMenu(
  langKey: string,
  imgUrl: string,
  route?: string,
  isAvailable?: boolean
) {
  return { langKey, imgUrl, route, isAvailable };
}

export function scrollToActive(data: NotificationResponse) {
  const element = data.event.target as HTMLTextAreaElement;

  if (element) {
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }, 200);
  }
}

export function filterNotifications(notifications: CrmNotification[]) {
  const unreadNotifications = [];
  const filteredNotifications = [];
  notifications.filter((notification) => {
    notification.status === 'SENT'
      ? unreadNotifications.push(notification)
      : filteredNotifications.push(notification);
  });
  return unreadNotifications.concat(filteredNotifications);
}

export function filterNotificationsByDate(
  notifications: FastTrackNotification[]
) {
  return notifications.filter((n) => !n.IsRead);
}

export function filterBonuses(bonuses: BonusItem[]) {
  const newBonuses = [];
  const filteredBonuses = [];
  bonuses.filter((notification) => {
    Number(new Date(notification.completesAt)) > Number(new Date())
      ? newBonuses.push(notification)
      : filteredBonuses.push(notification);
  });

  return newBonuses.concat(filteredBonuses).splice(0, 5);
}

export function toggleNotification(data: NotificationResponse) {
  const notifications = data.items[data.index];
  data.item.toggle
    ? (data.item.toggle = false)
    : (notifications.isActive = !notifications.isActive);
}

export function toggleAccordion(data: NotificationResponse) {
  data.items
    .filter((menu, i) => i !== data.index && menu.isActive)
    .forEach((menu) => (menu.isActive = !menu.isActive));
}
