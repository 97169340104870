import { Injectable } from '@angular/core';
import { EnvironmentService } from '@crc/shared';

import { FavoriteSlotGame, SlotGame } from '../entity';
import { BehaviorSubject, distinctUntilChanged, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SlotsService } from '../services/slots.service';
import { AccountFacade, AuthFacade } from '../../../auth';

@Injectable({ providedIn: 'root' })
export class SlotsFavoriteFacade {
  private readonly $favorites = new BehaviorSubject<FavoriteSlotGame[]>([]);
  readonly favorites$ = this.$favorites
    .asObservable()
    .pipe(distinctUntilChanged());

  readonly totalCount$ = this.favorites$.pipe(
    map((favorites) => favorites.length)
  );

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly slotsService: SlotsService,
    private readonly accountFacade: AccountFacade,
    private readonly authFacade: AuthFacade
  ) {}

  private addSlotToState(slot: FavoriteSlotGame) {
    this.$favorites.next([
      slot,
      ...this.$favorites.value
    ] as FavoriteSlotGame[]);
  }

  private removeSlotFromState(slot: FavoriteSlotGame) {
    this.$favorites.next([
      ...this.$favorites.value?.filter((s) => s.game_id !== slot.game_id)
    ] as FavoriteSlotGame[]);
  }

  private addSlotToFavorites(slot: FavoriteSlotGame) {
    this.addSlotToState(slot);

    this.slotsService
      .addToFavorites(slot.provider, slot.game_id)
      .pipe(
        catchError((error) => {
          this.removeSlotFromState(slot);
          return of(error);
        })
      )
      .subscribe();
  }

  private removeSlotFromFavorites(slot: FavoriteSlotGame) {
    this.removeSlotFromState(slot);
    this.slotsService
      .removeFromFavorites(slot.provider, slot.game_id)
      .pipe(
        catchError((error) => {
          this.addSlotToState(slot);
          return of(error);
        })
      )
      .subscribe();
  }

  toggleFavorite(slot: FavoriteSlotGame) {
    if (this.authFacade.getIsLoggedIn()) {
      this.isSlotInFavorites(slot)
        ? this.removeSlotFromFavorites(slot)
        : this.addSlotToFavorites(slot);
    }
  }

  isSlotInFavorites(game: SlotGame): boolean {
    return (
      this.$favorites.value?.filter((s) => s.game_id === game.game_id)?.length >
      0
    );
  }

  updateFavorites(slots: FavoriteSlotGame[]) {
    this.$favorites.next(slots);
  }
}
