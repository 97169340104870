import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LayoutState } from '../reducers/state';

export const selectLayoutState = createFeatureSelector<LayoutState>('layout');

export const selectBurgerState = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.isBurgerOpen
);

export const selectHeaderState = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.isVisibleHeader
);

export const selectHeaderIsBalanceVisible = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.isBalanceVisible
);

export const selectFooterState = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.userBarState.isFooterVisible
);

export const selectHomeIconState = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.userBarState.isHomeIconVisible
);

export const selectUserBarPosition = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.userBarState.userBarPosition
);

export const selectUserBarActiveIcon = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.userBarState.userBarActiveItem
);

export const selectUserBarVisibility = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.userBarState.isUserBarVisible
);

export const selectLoginPopupActiveTab = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.loginPopupActiveTab
);

export const selectLayoutReload = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.layoutPageReload
);
