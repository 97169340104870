import { inject, Injectable } from '@angular/core';
import { PragmaticLiveCasinoFactory } from './entities';
import { EnvironmentService } from '@crc/shared';
@Injectable({
  providedIn: 'root'
})
export class PragmaticCasinoIntegrationService {
  private readonly envService: EnvironmentService = inject(EnvironmentService);
  public getPragmaticCasinoFeedByGameIds$(
    gameIds: string[]
  ): PragmaticLiveCasinoFactory {
    return new PragmaticLiveCasinoFactory(
      this.envService.config.pragmaticLiveFeedUrl,
      this.envService.config.pragmaticLiveCasinoId,
      gameIds,
      true
    );
  }
}
