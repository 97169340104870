import { Injectable } from '@angular/core';
import { EnvironmentService, replaceSlotImage } from '@crc/shared';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  from,
  Observable,
  share,
  shareReplay,
  switchMap,
  tap
} from 'rxjs';
import { map, take } from 'rxjs/operators';

import { SlotsService } from '../services/slots.service';
import { CategorySlotGames, ProviderSlotGames, SlotGame } from '../entity';
import { AccountFacade } from '../../../auth';

@Injectable({ providedIn: 'root' })
export class SlotsFacade {
  private readonly $providersWithSlots = new BehaviorSubject<
    ProviderSlotGames[]
  >([]);
  // TODO this should not be used in searching
  readonly mainPopularSlots$: Observable<SlotGame[]>;
  readonly categoriesWithSlots$: Observable<CategorySlotGames[]> =
    this.slotsService
      .getCategoriesAndSlots(this.env.config.platform)
      .pipe(shareReplay(1));
  public readonly categoriesFilteredByPlatformWithSlots$: Observable<
    CategorySlotGames[]
  > = this.categoriesWithSlots$.pipe(
    map((categories) => {
      const platform = this.env.config.platform;
      return categories.filter((category) => {
        const categoryName = category?.category?.toLowerCase() ?? '';
        const groupName = category?.group?.toLowerCase() ?? '';
        const searchKeyWord = platform === 'desktop' ? 'web' : 'mob';
        return (
          category.active &&
          ((category?.platform &&
            (category?.platform === platform ||
              category?.platform === 'all')) ||
            (categoryName.includes(':') &&
              categoryName.split(':')[0].includes(searchKeyWord)) ||
            categoryName.startsWith(searchKeyWord) ||
            (groupName.includes(':') &&
              groupName.split(':')[0].includes(searchKeyWord)) ||
            groupName.startsWith(searchKeyWord)) &&
          category?.games?.length
        );
      });
    }),
    shareReplay(1)
  );
  readonly providersWithSlots$: Observable<ProviderSlotGames[]> =
    this.$providersWithSlots.pipe(distinctUntilChanged());

  readonly providers$ = this.accountFacade.customerId$.pipe(
    switchMap((customerId) =>
      this.slotsService
        .getSlotProviders(this.env.config.platform, customerId)
        .pipe(
          map((providers) => providers.filter((p) => p.provider !== 'bonus')),
          share()
        )
    )
  );

  $selectedGame = new BehaviorSubject<SlotGame>(null);
  selectedGame$ = this.$selectedGame.asObservable();

  constructor(
    private readonly slotsService: SlotsService,
    private readonly env: EnvironmentService,
    private readonly accountFacade: AccountFacade
  ) {
    this.mainPopularSlots$ = this.getSlotsByCategory(
      env.config.platform === 'desktop'
        ? 'web:popular_main'
        : 'mobile:popular_main'
    );
  }

  getMainPopulars(image2: boolean) {
    return this.mainPopularSlots$.pipe(
      map((topGames: SlotGame[]) => {
        topGames.forEach(
          (slot) =>
            (slot.image = replaceSlotImage(
              'slot',
              slot.provider,
              image2 ? slot.image2 || slot.image : slot.image
            ))
        );

        return topGames;
      })
    );
  }

  getProviderWithSlots(
    provider: string,
    type = 'slot'
  ): Observable<ProviderSlotGames> {
    this.requestProviderWithSlots(provider, type);
    return this.providersWithSlots$.pipe(
      switchMap((providers) =>
        from(providers).pipe(
          filter((p) => p.provider === provider),
          take(1)
        )
      )
    );
  }

  getSlotsByProvider(provider: string): Observable<SlotGame[]> {
    return this.getProviderWithSlots(provider).pipe(
      map((p) => {
        return p.games
          ?.map((slot) => {
            return { ...slot, providerName: p.name };
          })
          .filter(
            (g) => !g.platform || g.platform === this.env.config.platform
          );
      })
    );
  }

  getSlotsByCategory(category: string): Observable<SlotGame[]> {
    return this.categoriesWithSlots$.pipe(
      map((c) => c.filter((c) => c.category === category)[0]?.games ?? [])
    );
  }

  getSlotGameFrameUrl(url: string): Observable<string | null> {
    return this.slotsService.getSlotGameFrameUrl(
      url,
      this.env.config.platform === 'mobile'
    );
  }

  private requestProviderWithSlots(provider: string, type = 'slot') {
    if (!this.$providersWithSlots.value.find((p) => p.provider === provider)) {
      this.accountFacade.customerId$
        .pipe(
          take(1),
          switchMap((customerId) =>
            this.slotsService
              .getProviderWithSlots(
                provider,
                this.env.config.platform,
                customerId,
                type
              )
              .pipe(
                tap((providerWithSlots) => {
                  this.$providersWithSlots.next([
                    ...this.$providersWithSlots.value,
                    providerWithSlots
                  ]);
                })
              )
          )
        )
        .subscribe();
    }
  }
}
