export enum GameTypeById {
  backgammonGameId = 1,
  dominoGameId = 2,
  buraGameId = 3
}

export enum GameType {
  domino = 'DOMINO',
  backgammon = 'BACKGAMMON',
  bura = 'BURA',
  poker = 'POKER'
}

export enum GameClass {
  domino = 'domino',
  backgammon = 'backgammon',
  bura = 'bura'
}

export enum PrizeType {
  freeSpin = 'FreeSpin',
  cash = 'Cash',
  ufoFreeSpin = 'UfoFreeSpin'
}
export interface P2pAndSpinGamesInterfaceResponse {
  gameType?: 'spin' | 'default';
  game: string
  translateKey?: string;
  lottieFileUrl?: string;
  url?: string;
  withNewMethod?: boolean;
}
