import { Action, createReducer, on } from '@ngrx/store';

import { authActions, captchaActions } from '../actions';
import { CaptchaState } from '../state';
import * as AppState from '../../../state/app.state';

interface State extends AppState.State {
  captcha: CaptchaState;
}

const reducer = createReducer(
  {} as CaptchaState,
  on(captchaActions.updateCaptchaCode, (state, action) => ({
    ...state,
    captchaCode: action.payload,
    isCaptchaCheckedSuccess: null
  })),
  on(captchaActions.closeCaptchaModal, (state) => ({
    ...state,
    captchaCode: '',
    isCaptchaCheckedSuccess: null
  })),
  on(captchaActions.checkCaptchaValiditySuccess, (state, action) => ({
    ...state,
    isCaptchaCheckedSuccess: action.payload
  })),
  on(captchaActions.removeCaptchaValue, (state) => {
    return {
      ...state,
      captchaCode: '',
      isCaptchaCheckedSuccess: null
    };
  }),
  on(authActions.signOut, (state, action) => {
    return {} as CaptchaState;
  })
);

export function getCaptchaReducer(state: CaptchaState, action: Action) {
  return reducer(state, action);
}
