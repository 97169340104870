import { MatomoEvent } from './analytics.type';

export const matomoEventTree: {
  [key: string]: MatomoEvent;
} = {
  registrationStarted: {
    Category: 'Registration Started',
    Action: 'registration button click',
    Name: 'registrationStarted',
    Value: 0
  },

  registrationSubmittedFail: {
    Category: 'Registration Submitted',
    Action: 'Registration Submittion',
    Name: 'registrationSubmitted Fail',
    Value: 8
  },

  registrationSubmittedSuccess: {
    Category: 'Registration Submitted',
    Action: 'Registration Submittion',
    Name: 'registrationSubmitted Success',
    Value: 8
  },
  registerDocumentIdVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerDocumentId VALID',
    Value: 2
  },
  registerDocumentIdINVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerDocumentId INVALID',
    Value: 2
  },
  registerCitizenshipVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerCitizenship VALID',
    Value: 2
  },
  registerCitizenshipINVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerCitizenship INVALID',
    Value: 2
  },
  registerEmailVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerEmail VALID',
    Value: 2
  },
  registerEmailINVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerEmail INVALID',
    Value: 2
  },
  registerFirstNameVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerFirstName VALID',
    Value: 2
  },
  registerFirstNameINVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerFirstName INVALID',
    Value: 2
  },
  registerLastNameVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerLastName VALID',
    Value: 2
  },
  registerLastNameINVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerLastName INVALID',
    Value: 2
  },
  registerLoginNameVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerLoginName VALID',
    Value: 2
  },
  registerLoginNameINVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerLoginName INVALID',
    Value: 2
  },
  registerPasswordVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerPassword VALID',
    Value: 2
  },
  registerPasswordINVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerPassword INVALID',
    Value: 2
  },
  registerPhoneNumberVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerPhoneNumber VALID',
    Value: 2
  },
  registerPhoneNumberINVALID: {
    Category: 'Registration Form',
    Action: 'Registration Form started',
    Name: 'registerPhoneNumber INVALID',
    Value: 2
  },
  depositTotalSuccess: {
    Category: 'Deposit',
    Action: 'Deposit Total',
    Name: 'depositTotal Success',
    Value: 5
  },
  depositTotalFail: {
    Category: 'Deposit',
    Action: 'Deposit Total',
    Name: 'depositTotal Fail',
    Value: 5
  },
  depositStarted: {
    Category: 'Deposit',
    Action: 'Deposit Total',
    Name: 'depositStarted',
    Value: 5
  },
  depositButton1: {
    Category: 'Deposit',
    Action: 'Deposit Total',
    Name: 'deposit button 1',
    Value: 5
  },
  depositButton2: {
    Category: 'Deposit',
    Action: 'Deposit Total',
    Name: 'deposit button 2',
    Value: 5
  },
  depositButton3: {
    Category: 'Deposit',
    Action: 'Deposit Total',
    Name: 'deposit button 3',
    Value: 5
  },
  depositButton4: {
    Category: 'Deposit',
    Action: 'Deposit Total',
    Name: 'deposit button 4',
    Value: 5
  },
  depositButton5: {
    Category: 'Deposit',
    Action: 'Deposit Total',
    Name: 'deposit button 5',
    Value: 5
  },
  loginTotalSuccess: {
    Category: 'Login',
    Action: 'Login Total',
    Name: 'loginTotal Success',
    Value: 0.15
  },
  loginTotalFail: {
    Category: 'Login',
    Action: 'Login Total',
    Name: 'loginTotal Fail',
    Value: 0.15
  },
  logoutClick: {
    Category: 'Logout',
    Action: 'Log Out',
    Name: 'Logout Click',
    Value: 0
  },
  notification: {
    Category: 'Notification Button Click',
    Action: 'Notification Click',
    Name: 'Notification',
    Value: 0
  },
  bonuses: {
    Category: 'Notification Button Click',
    Action: 'Notification Click',
    Name: 'Bonuses',
    Value: 0
  },
  missions: {
    Category: 'Notification Button Click',
    Action: 'Notification Click',
    Name: 'Missions',
    Value: 0
  },
  notificationIcon: {
    Category: 'Notification Button Click',
    Action: 'Notification Click',
    Name: 'Notification Icon',
    Value: 0
  },
  verificationTotalSuccess: {
    Category: 'Verification Total',
    Action: 'Verification Submitted',
    Name: 'verificationTotal Success',
    Value: 5
  },
  verificationTotalFail: {
    Category: 'Verification Total',
    Action: 'Verification Submitted',
    Name: 'verificationTotal Fail',
    Value: 5
  }
};
