export const PragmaticLiveFeedTypeEnum = {
  TYPE_1: 'ROULETTE',
  TYPE_2: 'BLACKJACK',
  TYPE_3: 'MEGAWHEEL',
  TYPE_4: 'DRAGONTIGER',
  TYPE_5: 'SPACEMAN',
  TYPE_6: 'ONEBJ',
  TYPE_7: 'SicBO',
  TYPE_8: 'BACCARAT',
  TYPE_9: 'TREASUREADVENTURE'
} as const;
export type PragmaticLiveFeedTypeEnumInterface =
  (typeof PragmaticLiveFeedTypeEnum)[keyof typeof PragmaticLiveFeedTypeEnum];
// export interface PragmaticFeedDtoInterfaceSource
//==========================================================================================
export interface PragmaticFeedDtoBase {
  totalSeatedPlayers?: number | string;
  tableId: string;
  tableName: string;
  newTable: boolean;
  languageSpecificTableInfo: string | unknown;
  tableImage: string | null;
  tableLimits: {
    ranges: number[]; //0-1
    minBet: number; //0-1
    maxBet: number;
    maxPlayers: number;
  };
  dealer: {
    name: string;
  };
  tableOpen: boolean;
  tableType: string;
  tableSubtype?: string;
  currency: string;
  [key: string | symbol | number]: string | unknown | number | null | undefined;
}
interface Type1ResultInterface {
  time: string;
  result: string | number;
  color: string;
  gameId: string;
  powerUpList: unknown[];
  powerUpMultipliers: unknown[];
  resultMultiplier: number;
}
export interface PragmaticFeedDtoType1 extends PragmaticFeedDtoBase {
  last20Results: Type1ResultInterface[];
  // last8Results: Type1ResultInterface[];
  last6Results: Type1ResultInterface[];
}
export interface PragmaticFeedDtoType2 extends PragmaticFeedDtoBase {
  gameResult: {
    // ! latestFirst
    gameId: string;
    time: string;
    rc: number;
    gameResult: string;
    bonusGame: boolean;
    gemsPayout: {
      [key: number | string]: number;
    };
  }[];
  //=====================
}
export interface PragmaticFeedDtoType3 extends PragmaticFeedDtoBase {
  shuffle: false;
  gameResult: {
    // !!!!!!latestLast
    time: string;
    player: number;
    banker: number;
    winner: string;
    gameId: string;
  }[];
  // statistics: '[["--1","---","---","---","---","---"]]';
  statistics: string;
  baccaratShoeSummary: {
    totalGames: string;
    bankerWinCounter: string;
    playerWinCounter: string;
    tieCounter: string;
    bankerPairCounter: string;
    playerPairCounter: string;
  };
  goodRoadsMap: {
    bankerSandwich: boolean;
    playerPingPong: boolean;
    playerStreak: boolean;
    playerSandwich: boolean;
    oneTwoPlayer: boolean;
    oneTwoBanker: boolean;
    bankerPingPong: boolean;
    bankerStreak: boolean;
    separatedPlayer: boolean;
    switchBanker: boolean;
    separatedBanker: boolean;
    switchPlayer: boolean;
  };
  goodRoadsDepthMap: {
    oneTwoBankerDepth: number;
    switchPlayerDepth: number;
    playerSandwichDepth: number;
    separatedPlayerDepth: number;
    playerPingPongDepth: number;
    playerStreakDepth: number;
    oneTwoPlayerDepth: number;
    switchBankerDepth: number;
    bankerSandwichDepth: number;
    bankerPingPongDepth: number;
    separatedBankerDepth: number;
    bankerStreakDepth: number;
  };
  grTableCount: number;
  gameLoaderKey?: string;
}
export interface PragmaticFeedDtoType4 extends PragmaticFeedDtoBase {
  // tableType: 'ONEBJ';
  // tableSubtype: 'OneBJ';
  // tableVariant: 'bj2';
  tableVariant: string;
}
export interface PragmaticFeedDtoType5 extends PragmaticFeedDtoBase {
  seat1: boolean;
  seat2: boolean;
  seat3: boolean;
  seat4: boolean;
  seat5: boolean;
  seat6: boolean;
  seat7: boolean;
  playerCount: number;
  availableSeats: number;
  multiseatLimit: number;
  betbehind: boolean;
  multiseat: boolean;
  sidebets: boolean;
  currentUserId: string;
}
export interface PragmaticFeedDtoType6 extends PragmaticFeedDtoBase {
  gameResult: {
    // !!!!! latest first
    // result: '1.17';
    gameId: string;
    result: string;
    time: string;
  }[];
}
export type PragmaticFeedDtoAllTypes =
  | PragmaticFeedDtoType1
  | PragmaticFeedDtoType2
  | PragmaticFeedDtoType3
  | PragmaticFeedDtoType4
  | PragmaticFeedDtoType5
  | PragmaticFeedDtoType6;
