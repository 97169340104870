import { inject, Injectable } from '@angular/core';
import { SlotsFacade } from '../slots';
import { map, switchMap } from 'rxjs/operators';
import {
  BehaviorSubject,
  distinctUntilChanged,
  finalize,
  of,
  share
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FantasyGamesFacade {
  private readonly slotsFacade: SlotsFacade = inject(SlotsFacade);
  public readonly $fantasyIsLoaded = new BehaviorSubject(false);

  public readonly heroPickProviderData$ = this.slotsFacade.providers$.pipe(
    map((items) =>
      items.find(({ name, provider }) => {
        return [provider.toLowerCase(), name.toLowerCase()].some(
          (str) => str.startsWith('hero') && str.includes('picks')
        );
      })
    ),
    distinctUntilChanged(
      (previous, current) => previous?.provider === current?.provider
    ),
    share()
  );
  public readonly heroPicksSlots$ = this.heroPickProviderData$.pipe(
    switchMap((provider) => {
      return this.slotsFacade.getSlotsByProvider(provider.provider);
    }),
    share()
  );
  public getFantasyFootballGameData$() {
    return this.heroPicksSlots$.pipe(
      map(
        (slots) =>
          slots.find(
            (slot) => slot?.name?.toLowerCase?.() === 'fantasy football'
          ) || slots[0]
      ),
      switchMap((game) => {
        if (!game?.url) {
          this.$fantasyIsLoaded.next(true);
          return of(null);
        }
        return this.slotsFacade.getSlotGameFrameUrl(game.url).pipe(
          map((url) => ({ ...game, url })),
          finalize(() => {
            this.$fantasyIsLoaded.next(true);
          })
        );
      })
    );
  }
}
