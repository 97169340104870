import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CashiersService } from '../services/cashiers.service';
import {
  Cashier,
  CashierHours,
  CashierPlace,
  CITY_ZOOM,
  filterNearCashier,
  MAP_STYLES,
  nearCashier
} from '../entity';
import { map } from 'rxjs/operators';
import { NgSelectItem } from '@crc/components';

@Injectable({ providedIn: 'root' })
export class CashiersFacade {
  private cachedCashiers$: BehaviorSubject<Cashier[]> = new BehaviorSubject<
    Cashier[]
  >(null);
  private cachedCoordinates$: BehaviorSubject<Record<string, any>> =
    new BehaviorSubject<Record<string, any>>(null);

  private city$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private isAlwaysOpen$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private isNearCashier$: BehaviorSubject<nearCashier> =
    new BehaviorSubject<nearCashier>({
      status: false,
      latitude: 0,
      longitude: 0
    });

  private cashierPlaces$: BehaviorSubject<CashierPlace[]> = new BehaviorSubject<
    CashierPlace[]
  >(null);

  private mapOptions$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private selectItems$: BehaviorSubject<NgSelectItem[]> = new BehaviorSubject<
    NgSelectItem[]
  >([]);

  constructor(private readonly cashiersService: CashiersService) {}

  getCashiers(): Observable<Cashier[]> {
    return this.cashiersService.getCashiers();
  }

  loadMap(): Observable<boolean> {
    return this.cashiersService.loadMap().pipe(map(() => true));
  }

  getSelectItems(): Observable<NgSelectItem[]> {
    return this.selectItems$.asObservable();
  }

  setMapOptions(options: any): void {
    this.mapOptions$.next(options);
  }

  getMapOptions(): Observable<any> {
    return this.mapOptions$.asObservable();
  }

  setIsAlwaysOpen(isAlwaysOpen: boolean): void {
    this.isAlwaysOpen$.next(isAlwaysOpen);
    this.updateCashiers();
  }

  setIsNearCashier(isNearCashier: nearCashier): void {
    this.isNearCashier$.next(isNearCashier);
    this.updateCashiers();
  }

  getIsAlwaysOpen(): Observable<boolean> {
    return this.isAlwaysOpen$.asObservable();
  }

  getCashiersPlaces(): Observable<CashierPlace[]> {
    return this.cashierPlaces$.asObservable().pipe(
      map((res: CashierPlace[]) => {
        res &&
          res.forEach((item: CashierPlace) => {
            if (!item.t13n && item.openHours) {
              if (this.checkAllWeekOpenHours(item.openHours)) {
                item.allWeek = {
                  from: item.openHours[0]?.from,
                  until: item.openHours[0]?.until
                };
              }
            }
          });
        return res;
      })
    );
  }

  checkAllWeekOpenHours(openHours: CashierHours[]): boolean {
    if (openHours.length === 1) {
      return false;
    }
    let countMatch = 1;
    openHours.reduce((previousValue, currentValue) => {
      if (
        currentValue &&
        previousValue &&
        !currentValue.closed &&
        currentValue.from === previousValue.from &&
        currentValue.until &&
        previousValue.until
      ) {
        countMatch++;
      }
      return currentValue;
    }, {} as CashierHours);
    return countMatch === openHours.length;
  }

  setCashiersPlaces(cashiersPlaces: CashierPlace[]): void {
    this.cashierPlaces$.next(cashiersPlaces);
  }

  setCity(city: string): void {
    this.city$.next(city);
    this.updateCashiers();
  }

  getCity(): Observable<string> {
    return this.city$.asObservable();
  }

  setCachedCashiers(cashiers: Cashier[]): void {
    this.cachedCashiers$.next(cashiers);
  }

  setCachedCoordinates(cashiers: any): void {
    const coordinates = this.getCityCoordinates(cashiers);
    this.cachedCoordinates$.next(coordinates);
  }

  filterSelectItems(cashiers: Cashier[]): void {
    const citySelectItems = [] as NgSelectItem[];
    cashiers.map(({ name }) => {
      citySelectItems.push({
        value: name,
        id: name,
        key: name
      });
    });
    this.selectItems$.next(citySelectItems);
  }

  getCityCoordinates(cashiers: Cashier[]): Record<string, any> {
    const coordinates = {} as any;
    cashiers.forEach((e) => {
      coordinates[e.places[0]?.city] = {
        lng: e.places[0]?.coordinates?.lon,
        lat: e.places[0]?.coordinates?.lat
      };
    });
    return coordinates;
  }

  updateCashiers(): void {
    const cashiers = this.filterCashiers(
      this.cachedCashiers$.getValue(),
      this.city$.getValue(),
      this.isAlwaysOpen$.getValue(),
      this.isNearCashier$.getValue()
    );
    this.setCashiersPlaces(this.currentCashierPlaces(cashiers));
    const center: any = this.cachedCoordinates$.getValue()[this.city$.value];
    this.initializeMapOptions(center);
  }

  filterCashiers(
    cashiers: Cashier[],
    cashierName: string,
    isAlwaysOpen = false,
    isNearCashier = null
  ): Cashier[] {
    return cashiers.map((cashier: Cashier) => {
      return {
        ...cashier,
        places: this.filterCashierPlaces(
          cashier,
          cashierName,
          isAlwaysOpen,
          isNearCashier
        )
      };
    });
  }

  currentCashierPlaces(cashiers: Cashier[]): CashierPlace[] {
    return cashiers
      .map((cashier) => cashier.places)
      .reduce((sum, curr) => [...sum, ...(curr || [])], []);
  }

  filterCashierPlaces(
    cashier: Cashier,
    cashierName: string,
    isAlwaysOpen = false,
    isNearCashier: nearCashier = null
  ): CashierPlace[] {
    if (cashier?.name?.includes(cashierName)) {
      return cashier.places?.filter?.(
        (place: CashierPlace) =>
          this.checkHours(place, isAlwaysOpen) &&
          filterNearCashier(place, isNearCashier)
      );
    }
    return cashier.places?.filter?.(
      (place: CashierPlace) =>
        this.checkAddress(place, cashierName) &&
        this.checkHours(place, isAlwaysOpen) &&
        filterNearCashier(place, isNearCashier)
    );
  }

  checkAddress(cashierPlace: CashierPlace, cashierName: string): boolean {
    return !cashierName || cashierPlace.address.includes(cashierName);
  }

  checkHours(cashierPlace: CashierPlace, isAlwaysOpen = false): boolean {
    return !isAlwaysOpen || cashierPlace.t13n;
  }

  private initializeMapOptions(center: any, zoom = CITY_ZOOM) {
    this.mapOptions$.next({
      center,
      zoom,
      styles: MAP_STYLES,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControl: false,
      scrollwheel: true
    });
  }
}
