<div class="form-control-suggests">
  <p
    class="form-control-suggest message"
    [class.success]="!(errors?.upperCase || errors?.lowerCase || errors?.required)"
    data-cy="big-and-small-symbols"
  >
    {{ 'password.letters_required' | translate }}
  </p>
  <p
    class="form-control-suggest message"
    [class.success]="!(errors?.required || errors?.number)"
    data-cy="number-symbols"
  >
    {{ 'password.digit_required' | translate }}
  </p>
  <p
    class="form-control-suggest message"
    [class.success]="!(errors?.required || errors?.minlength || errors?.maxlength)"
    data-cy="min-and-max-symbols"
  >
    {{ 'password.symbols_count' | translate }}
  </p>
  <p *ngIf="showUserNameAndPasswordMatchValidation"
    [class.success]="!(errors?.required || errors?.containsValue || userNameAndPasswordMatch)"
    class="form-control-suggest message"
    data-cy="min-and-max-symbols"
  >
    {{ 'password.user_not_same' | translate }}
  </p>
</div>
