<form
  *ngIf="!captcha?.length"
  class="form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div class="control">
    <crc-m-shared-input
      [placeholder]="'username_full' | translate"
      [class.error]="
      (loginBlockedByUnknownEntity && form.valid && !captcha) ||
        (loginFailed && form.valid && !captcha) ||
        ((submitted$ | async) && !form.valid && usernameC.errors?.required) ||
        usernameC.errors?.pattern
      "
      [errorsTexts]="[
        { type: 'required', text: 'fill_required_fields' | translate },
        { type: 'pattern', text: 'use_only_latin_letters' | translate }
      ]"
      autoComplete="username"
      name="username"
      formControlName="login"
      type="text"
      data-cy="username"
    >
    </crc-m-shared-input>
  </div>

  <ng-container *ngIf="customerId; else passwordField">
    <div class="control control--password">
      <crc-m-shared-input
        [class.error]="smsCodeVerified === false || smsCodeC.errors"
        [placeholder]="'sms_code' | translate"
        [errorsTexts]="[
          { type: 'required', text: 'fill_required_fields' | translate }
        ]"
        [inputMode]="'decimal'"
        autoComplete="one-time-code"
        formControlName="smsCode"
        data-cy="form-smsCode"
        mask="0000000000"
      >
      </crc-m-shared-input>
    </div>

    <div
      *ngIf="!smsCodeC.errors && smsCodeVerified === false"
      class="errorText"
    >
      <span>{{ 'sms_code_invalid' | translate }}</span>
    </div>

    <div class="info animate__enter-height" *ngIf="customerId">
      <div class="info__checked">
        <crc-m-shared-status-icon
          path="/assets/icons/checked.svg"
          alt="ok"
          [success]="true"
        >
        </crc-m-shared-status-icon>
      </div>
      <span>{{ 'sms_code_sent' | translate }}</span>
    </div>
  </ng-container>

  <ng-template #passwordField>
    <div class="control control--password">
      <crc-m-shared-input
        [class.error]="
        (loginBlockedByUnknownEntity && form.valid && !captcha) ||
          (loginFailed && form.valid && !captcha) ||
          ((submitted$ | async) && !form.valid && passwordC.errors)
        "
        [errorsTexts]="[
          { type: 'required', text: 'fill_required_fields' | translate }
        ]"
        [name]="'password'"
        [autoComplete]="'current-password'"
        [placeholder]="'password' | translate"
        [type]="passwordVisibility ? 'text' : 'password'"
        formControlName="password"
        data-cy="form-password"
      >
        <div class="refresh__icon">
          <img
            (click)="changeInputType()"
            src="/assets/icons/layout/header/eye-{{
              passwordVisibility ? 'off' : 'on'
            }}.svg"
            alt=""
          />
        </div>
      </crc-m-shared-input>
      <div class="errorText" *ngIf="loginFailed && form.valid && !captcha">
        <span>{{ 'login.incorrect_credentials' | translate }}</span>
      </div>
      <div class="errorText" *ngIf="loginBlockedByUnknownEntity && form.valid && !captcha">
        <span>{{'login.blocked-by-unknown-entity' | translate}}</span>
      </div>
      <div class="control__extra_field">
        <crc-m-login-otp-button
          class="control__extra_field-item"
          (clicked)="handleOtpClick(form.get('login'))"
        ></crc-m-login-otp-button>
        <div
          class="control__extra_field-item control__extra_field__password-recovery"
        >
          <span (click)="onResetPassword()">{{
            'password_recovery' | translate
          }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <crc-m-shared-button
    style="width: 100%"
    [type]="form.valid ? 'active' : 'secondary'"
    [loader]="buttonLoaderState"
    >{{ 'login' | translate }}</crc-m-shared-button
  >
</form>

<crc-m-shared-captcha
  *ngIf="captcha?.length"
  (captchaValidValueEntered$)="onEnterCaptchaValidValue($event)"
  (captchaValueEntered$)="onCheckCaptchaValidity($event)"
  (captchaClosed$)="onCaptchaClosed()"
  (captchaUpdated$)="onCaptchaUpdated()"
  [captcha]="captcha"
  [enteredCaptchaValid]="enteredCaptchaValid"
  class="center login-captcha"
>
</crc-m-shared-captcha>
