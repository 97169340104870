export class EventNames {
  public static readonly VIRTUAL_SPORT = 'virtualSport';
  public static readonly APPLICATION_LOADED = 'applicationLoaded';
  public static readonly LOGIN = 'login';
  public static readonly REGISTER = 'register';
  public static readonly HEIGHT_CHANGE = 'height';
  public static readonly INNER_HEIGHT = 'innerHeight';
  public static readonly VERIFICATION = 'verification';
  public static readonly SPIN = 'spin';
  public static readonly REDIRECT_TO = 'redirectTo';
  public static readonly LINK = 'link';
  public static readonly OPEN_TAB = 'openTab';
  public static readonly DEPOSIT = 'deposit';
  public static readonly TOKEN_REFRESH = 'tokenRefresh';
  public static readonly USER_DATA = 'userData';
  public static readonly LOGOUT = 'logout';
  public static readonly REQUIRE_LOGIN = 'requireLogin';
  public static readonly BALANCE_UPDATE = 'balanceUpdate';
  public static readonly CLOSE_POPUP = 'closePopup';
  public static readonly SWITCH_HEADER_NAV_ITEM = 'switchHeaderNavItem';
  public static readonly NAVIGATE_FROM_HEADER = 'navigateFromHeader';
  public static readonly NAVIGATE_BACK_FROM_VIRTUAL = 'navigateBackFromVirtual';
  public static readonly NAVIGATION_CALLBACK = 'navigationCallBack';
  public static readonly SCROLL_TO = 'scrollTo';
  public static readonly FANTASY = 'FANTASY';
  public static readonly LOGIN_CANCELED = 'loginCancelled';
}
