import { Component, EventEmitter, Output } from '@angular/core';
import { InputBaseComponent } from '@crc/components';

@Component({
  selector: 'crc-m-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent extends InputBaseComponent<string> {
  @Output() detectKeyboardEvent = new EventEmitter<KeyboardEvent>();
  @Output() clickedCleanup = new EventEmitter<MouseEvent>();
}
