import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  ResetPasswordFormHelperFacade,
  ResetPasswordVerify
} from '@crc/facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { LoginOtpFacade } from '@crc/facade';
@UntilDestroy()
@Component({
  selector: 'crc-m-reset-password-verify-presentation',
  templateUrl: './reset-password-confirm-presentation.component.html',
  styleUrls: ['./reset-password-confirm-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordConfirmPresentationComponent {
  form: UntypedFormGroup;
  submitted = false;
  isPassword = true;
  iconType: 'closedEye' | 'openEye' = 'closedEye';
  @Input() mobileNumber: string;
  @Output() submit$ = new EventEmitter<ResetPasswordVerify>();
  @Output() sendSms$ = new EventEmitter<void>();
  isSubmitAlreadyClicked = false;
  constructor(
    private readonly formHelper: ResetPasswordFormHelperFacade,
    private readonly loginOtpFacade: LoginOtpFacade
  ) {
    // this.loginOtpFacade.createAbortController();
    this.form = this.formHelper.buildSubmitFormControls();
    this.form.statusChanges
      .pipe(
        untilDestroyed(this),
        tap((status) => {
          if ((status as string) === 'VALID') {
            this.isSubmitAlreadyClicked = false;
          }
        })
      )
      .subscribe();
  }
  onSubmit() {
    this.isSubmitAlreadyClicked = true;
    this.submitted = true;
    if (!this.form.invalid) {
      const body: ResetPasswordVerify = {
        mobile: '',
        password: this.form.get('password').value,
        verificationCode: this.form.get('verificationCode').value
      };

      this.submit$.emit(body);
    }
  }
  onFocus(event: Event) {
    (
      (event.target as HTMLElement).offsetParent as HTMLElement
    ).scrollIntoView();
  }
  // ngAfterViewInit(): void {
  //   this.loginOtpFacade
  //     .getOneTimePasswordLogic$(this.form.get('verificationCode'))
  //     .pipe(untilDestroyed(this))
  //     .subscribe();
  // }
  // ngOnDestroy() {
  //   this.loginOtpFacade.abortAndNullifyAbortController();
  // }

  protected onTimerStart(
    isInitialAutoAction: boolean,
    isDisabled: boolean
  ): void {
    if (!isDisabled && !isInitialAutoAction) {
      this.sendSms$.emit();
    }
  }
}
