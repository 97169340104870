<div class="restriction-content" crcSharedClassForward="no-scroll" to="body">
  <p
    *ngIf="status === Restricted.BLOCKED_BY_RS_GE"
    class="restriction-content__text"
    [attr.data-cy]="'restricted-user-reason'"
  >
    {{ 'login.restricted_user' | translate }}
  </p>

  <p
    *ngIf="status === Restricted.BELOW_LEGAL_AGE"
    class="restriction-content__text"
    [attr.data-cy]="'restricted-user-reason'"
  >
    {{ 'login.under_age' | translate }}
  </p>

  <h2
    class="restriction-content__cashiers-text"
    [attr.data-cy]="'restricted-user-visit-cash-desk-text'"
  >
    {{ 'cash_desks.visit' | translate }}
  </h2>

  <div
    [attr.data-cy]="'restricted-user-visit-cash-desk-button-wrapper'"
    class="restriction-content__cashiers"
  >
    <crc-m-shared-button
      dataCy="restricted-user-visit-cash-desk"
      type="active"
      (clicked)="clicked.emit()"
    >
      {{ 'cash_desks' | translate }}
    </crc-m-shared-button>
  </div>
</div>
