import { Observable, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICountdown } from '../entity/live-big-raffle.interface';

export const RaffleDates: string[] = [
  'Jan 20 2022 15:55:00', // Test
  'Jan 20 2022 15:57:00', // test
  'Jan 22 2022 15:30:00',
  'Jan 23 2022 15:30:00',
  'Jan 23 2022 19:05:00',
  'Jan 24 2022 15:30:00',
  'Jan 25 2022 15:30:00',
  'Jan 26 2022 15:30:00',
  'Jan 27 2022 15:30:00',
  'Jan 28 2022 15:30:00',
  'Jan 29 2022 15:30:00',
  'Jan 30 2022 15:30:00',
  'Jan 30 2021 19:05:00', // weekly raffle
  'Jan 31 2022 15:30:00',
  'Feb 1 2022 15:30:00',
  'Feb 2 2022 15:30:00',
  'Feb 3 2022 15:30:00',
  'Feb 4 2022 15:30:00',
  'Feb 5 2022 15:30:00',
  'Feb 6 2022 15:30:00',
  'Feb 6 2022 19:05:00', // weekly raffle
  'Feb 7 2022 15:30:00',
  'Feb 8 2022 15:30:00',
  'Feb 9 2022 15:30:00',
  'Feb 10 2022 15:30:00',
  'Feb 11 2022 15:30:00',
  'Feb 12 2022 15:30:00',
  'Feb 13 2022 15:30:00',
  'Feb 13 2022 19:05:00', // weekly raffle
  'Feb 14 2022 15:30:00',
  'Feb 15 2022 15:30:00',
  'Feb 16 2022 15:30:00',
  'Feb 17 2022 15:30:00',
  'Feb 18 2022 15:30:00',
  'Feb 19 2022 15:30:00',
  'Feb 20 2022 15:30:00',
  'Feb 20 2022 19:05:00', // weekly raffle
  'Feb 21 2022 15:30:00',
  'Feb 22 2022 15:30:00',
  'Feb 23 2022 15:30:00',
  'Feb 24 2022 15:30:00',
  'Feb 25 2022 15:30:00',
  'Feb 26 2022 15:30:00',
  'Feb 27 2022 15:30:00',
  'Feb 27 2022 19:05:00', // Weekly raffle
  'Feb 27 2022 23:00:00' // Final raffle
];

// fills number with zeros to digit length and converts to string
export function fillWithZeros(value: number | string, digits: number) {
  return `${value}`.padStart(digits, '0');
}

// returns hours, minutes, seconds from milliseconds
export function toCountdownData(time: number): ICountdown {
  return {
    seconds: Math.floor((time / 1000) % 60),
    minutes: Math.floor((time / (1000 * 60)) % 60),
    hours: Math.floor((time / (1000 * 60 * 60)) % 24)
  };
}

export function countdown$(deadline: number): Observable<number> {
  let difference;

  return interval(1000).pipe(
    map(() => {
      difference = deadline - new Date().getTime();

      // 35 minute = 2 100 000 ms
      if (difference < -2_100_000) {
        return null;
      }

      if (difference < 0) {
        return 0;
      }

      return difference;
    })
  );
}

export function formatButtonDates(datesArr: string[]) {
  return datesArr.map((item) => {
    const date = new Date(item);
    const monthNumber = addZerro(date.getMonth() + 1);

    return `${monthNumber}-${date.getDate()}-${date.getFullYear()} ${addZerro(
      date.getHours()
    )}:${addZerro(date.getMinutes())}`;
  });
}

function addZerro(par) {
  return `${par}`.length < 2 ? `0${par}` : par;
}

export function isTimeMoreThan24hrTillRaffle(actionDate: Date): boolean {
  const currentDate: Date = new Date();
  const difference = Math.abs(actionDate.getTime() - currentDate.getTime()); // difference in milliseconds
  const hours = difference / (1000 * 60 * 60);
  return hours > 24;
}

export function generateMonthName(
  translatedMonthNames: string[],
  lang: string,
  date: Date
): Promise<string[]> {
  return new Promise((resolve) => {
    const nextRaffledate = date;
    const monthName = translatedMonthNames[nextRaffledate.getMonth()];
    const hoursAndMinutes = `${addZerro(nextRaffledate.getHours())}:${addZerro(
      nextRaffledate.getMinutes()
    )}`;
    const updatedMonth =
      lang === 'en'
        ? `ON ${monthName} `.toUpperCase()
        : lang === 'ru'
        ? ` ${monthName}`.toUpperCase()
        : ` ${
            monthName !== 'აგვისტო'
              ? monthName.slice(0, monthName.length - 1)
              : monthName
          }ს`;
    const firstRow =
      lang !== 'en'
        ? `${nextRaffledate.getDate()}${updatedMonth}`
        : `${updatedMonth}${nextRaffledate.getDate()}`;

    const secondRow =
      lang === 'en'
        ? `AT ${hoursAndMinutes}`
        : lang === 'ru'
        ? `В ${hoursAndMinutes}`
        : `${hoursAndMinutes} საათზე`;
    resolve([firstRow, secondRow]);
  });
}
