import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import {
  AnalyticsService,
  FormBase,
  GtagEvent,
  handleMarkingControlsAsTouched,
  handleRemovingRequiredError,
  MatomoAnalyticsService
} from '@crc/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { LoginOtpFacade, RegisterFacade } from '@crc/facade';

@UntilDestroy()
@Component({
  selector: 'crc-m-account-info-presentation',
  templateUrl: './account-info-presentation.component.html',
  styleUrls: ['./account-info-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountInfoPresentationComponent
  extends FormBase
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() cachedForm: UntypedFormGroup | undefined;
  @Input() selectedCountry: string | null;
  @Input() protected isLoading: {
    status: boolean;
  };
  @Input() rateLimitErrorDataSource!: number;
  @Output() sendSms = new EventEmitter<void>();
  @Output() policyClicked: EventEmitter<UntypedFormGroup> =
    new EventEmitter<UntypedFormGroup>();
  @Output() triggerScroll = new EventEmitter<void>();
  timerResetState = false;
  form: UntypedFormGroup;
  showMessage: boolean;
  timerTouched: boolean;
  cdaErrorCode$ = this.registerFacade.getCdaErrorCode$();

  analyticsService: AnalyticsService = inject(AnalyticsService);
  matomoAnalyticsService: MatomoAnalyticsService = inject(
    MatomoAnalyticsService
  );

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly registerFacade: RegisterFacade,
    private readonly loginOtpFacade: LoginOtpFacade
  ) {
    super();
  }

  ngOnInit(): void {
    this.cdaErrorCode$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data && data === this.registerFacade.SB_RESPONSE_EXCEPTION) {
        this.form.get('verificationCode').setErrors({ wrongCode: true });
      }
    });
    // this.loginOtpFacade.createAbortController();
    this.triggerScroll.emit();
    this.form = this.controlContainer.control as UntypedFormGroup;
    this.subscribeToMobile();
    const codeControl = this.form.get('verificationCode');
    codeControl.reset();
    codeControl.updateValueAndValidity();
  }

  ngAfterViewInit(): void {
    this.triggerScroll.emit();
    // this.loginOtpFacade
    //   .getOneTimePasswordLogic$(this.form.get('verificationCode'))
    //   .pipe(
    //     untilDestroyed(this),
    //     takeWhile((result) => result !== 'T'),
    //     tap((OTP) => {
    //       this.form.get('verificationCode').setValue(OTP);
    //     })
    //   )
    //   .subscribe();
  }

  ngOnDestroy() {
    this.form.get('verificationCode').reset();
    // this.loginOtpFacade.abortAndNullifyAbortController();
  }

  onTimerStart() {
    this.timerResetState = false;
    this.sendSms.emit();
    this.showMessage = true;
    this.timerTouched = true;

    setTimeout(() => {
      this.showMessage = false;
    }, 2000);
  }

  subscribeToMobile() {
    this.form
      .get('phoneNumber')
      .valueChanges.pipe(
        tap(() => {
          const control = this.form.get('phoneNumber');
          if (!control.value) {
            control.markAsUntouched();
            control.updateValueAndValidity();
            handleRemovingRequiredError(control);
          }
        }),
        tap(() => {
          this.handleVerificationCodeReset();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public markFormControlsAsTouched(): void {
    handleMarkingControlsAsTouched(this.form);
  }

  handleLoginNameChange() {
    const loginNameControl = this.form.get('loginName');
    if (loginNameControl.hasError('used')) {
      const { used, ...errors } = loginNameControl.errors;
      if (Object.keys(errors).length === 0) {
        loginNameControl.setErrors(null);
      } else {
        loginNameControl.setErrors(errors);
      }
    }
  }

  trackAnalytics(event: GtagEvent, value: string) {
    this.analyticsService.track({
      event,
      [event]: value
    });
  }

  trackMatomoAnalytics(name: string): void {
    this.matomoAnalyticsService.selectTrackingEvent(name);
  }

  //Todo simplify this
  controlBlurred($event: Event, controlName: string) {
    if (!this.form.get(controlName).value) {
      return;
    }
    if (controlName === 'documentId') {
      this.trackAnalytics(
        'registerDocumentId',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerDocumentId${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'firstName') {
      this.trackAnalytics(
        'registerFirstName',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerFirstName${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'lastName') {
      this.trackAnalytics(
        'registerLastName',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerLastName${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'phoneNumber') {
      this.trackAnalytics(
        'registerPhoneNumber',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerPhoneNumber${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'email') {
      this.trackAnalytics('registerEmail', this.form.get(controlName).status);
    }
    this.trackMatomoAnalytics(
      `registerEmail${this.form.get(controlName).status}`
    );

    if (controlName === 'loginName') {
      this.trackAnalytics(
        'registerLoginName',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerLoginName${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'nationality') {
      this.trackAnalytics(
        'registerCitizenship',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerCitizenship${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'password') {
      this.trackAnalytics(
        'registerPassword',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerPassword${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'confirmPassword') {
      this.trackAnalytics(
        'registerConfirmPassword',
        this.form.get(controlName).status
      );
    }
  }

  public handleVerificationCodeReset(): void {
    this.timerResetState = true;
    this.form.get('verificationCode').reset();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['rateLimitErrorDataSource']?.currentValue) {
      this.timerResetState = true;
      this.form.setErrors({
        ...(this.form.errors || {}),
        rateLimit: this.rateLimitErrorDataSource
      });
    }
  }
}
