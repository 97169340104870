<div class="cda">
  <div class="cda__img">
    <crc-m-status-icon-result [success]="false"/>
  </div>
  <p class="cda__text" *ngSync="countDown$ | async as countDown">
    {{ 'otp.rate_limit'
    | translate
    : {
      seconds: countDown || remainingSecondsFallBackValue
    } }}
  </p>
</div>
