import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Params } from '@angular/router';
import { scrollTop } from '@crc/shared';

@Component({
  selector: 'crc-m-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardHeaderComponent {
  @Input() title: string;
  @Input() image: string;
  @Input() routeLink: string;
  @Input() queryParams: Params;
  @Input() actionTitle: string;
  @Input() buyBonus: boolean;
  @Input() border: boolean;
  @Input() noScroll: boolean;
  @Input() iconBgColor: boolean;
  @Input() dataCy: string;
  @Input() largeImg: boolean;
  @Input() bgIcon = false;
  @Input() isNew: boolean;
  @Input() localSVGParameters: {
    name: string;
    color?: string;
    maxWidth?: string;
    maxHeight?: string;
    fullSize?: boolean;
    size?: string;
  };
  @ViewChild('anchorRef', {
    read: ElementRef<HTMLAnchorElement>
  })
  private readonly anchorRef: ElementRef<HTMLAnchorElement>;
  @Output() moreClicked: EventEmitter<void> = new EventEmitter<void>();

  onMoreClick() {
    this.moreClicked.emit();

    if (!this.noScroll) {
      scrollTop(0, 'auto');
    }
  }
}
