import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'crc-m-shared-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() type:
    | 'primary'
    | 'secondary'
    | 'active'
    | 'active2'
    | 'secondary-light'
    | 'danger'
    | 'bonus';
  @Input() disable: boolean;
  @Input() loader = false;
  @Input() opacity = 1;
  @Input() dataCy!: string;

  @Output() clicked = new EventEmitter();
}
