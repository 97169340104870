import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
@Directive({
  selector: '[crcSharedProhibitionStatus]'
})
export class ProhibitionStatusDirective implements AfterViewInit {
  @Input() prohibitionStatus: boolean;
  constructor(private readonly elRef: ElementRef) {}
  /**
   * @description
   * This directive is used to disable the button when the prohibitionStatus is true.
   */
  ngAfterViewInit() {
    if (this.prohibitionStatus) {
      this.elRef.nativeElement.style.pointerEvents = 'none';
      this.elRef.nativeElement.style.opacity = '.5';
    }
  }
}
