import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'crc-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordValidatorComponent {
  @Input() errors: ValidationErrors | null;
  @Input() showUserNameAndPasswordMatchValidation = true;
  @Input() userNameAndPasswordMatch = false;
}
