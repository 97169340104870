import { Injectable } from '@angular/core';
import { AccountFacade } from '../../../auth';
import { CasinoHistoryFacade } from './casino-history.facade';
import { CasinoService } from '../services/casino.service';
import { of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { replaceImage } from '@crc/shared';
import { CasinoGame } from '../entity';
import { CasinoFacade } from './casino.facade';

@Injectable()
export class CasinoHistoryDataFacade {
  constructor(
    private accountFacade: AccountFacade,
    private casinoService: CasinoService,
    private casinoHistoryFacade: CasinoHistoryFacade,
    private casinoFacade: CasinoFacade
  ) {
    this.accountFacade.customerId$
      .pipe(
        switchMap((customerId) =>
          customerId ? this.casinoService.getHistory() : of([])
        ),
        map((casinoGames) => {
          casinoGames.forEach((historyGame) => {
            historyGame.image = replaceImage<CasinoGame>(
              'casino',
              historyGame.provider,
              historyGame
            ).image;
          });
          this.casinoHistoryFacade.updateHistory(casinoGames);
        })
      )
      .subscribe();
  }
}
