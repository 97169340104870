import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
@Component({
  selector: 'crc-m-copy-popup',
  templateUrl: './copy-popup.component.html',
  styleUrls: ['./copy-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyPopupComponent {
  @Input() valueIsHidden: boolean;
  @Input() cbContent: string;
  @Input() imgSize: number;
}
