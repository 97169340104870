import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { addQueryParamToUrl, BaseUrl, HttpService } from '@crc/shared';
import { catchError } from 'rxjs/operators';
import {
  SpaceTaxi,
  SpaceTaxiLatestCrashesResponse,
  UfoLatestCrashesResponse
} from '../entity/ufo.interface';

@Injectable({
  providedIn: 'root'
})
export class UfoService {
  constructor(private readonly apiService: HttpService) {}

  getUfoLink(): Observable<string> {
    const path = `/rest/integrations/ig/ufo/get_game`;
    const baseUrl: BaseUrl = 'apiUrl';
    return this.apiService.get$<string>({
      path,
      baseUrl,
      responseType: 'text'
    });
  }

  getSpaceTaxiLink(params: SpaceTaxi): Observable<string> {
    const url = `/rest/integrations/ig/ufo/get_game`;
    const baseUrl: BaseUrl = 'apiUrl';
    const path = addQueryParamToUrl(url, [
      {
        value: String(params.customerid),
        key: 'customerid'
      },
      {
        value: String(params.gameid),
        key: 'gameid'
      }
    ]);

    return this.apiService.get$<string>({
      path,
      baseUrl,
      responseType: 'text'
    });
  }

  getLatestCrashes(): Observable<UfoLatestCrashesResponse | null> {
    return this.apiService
      .get$<UfoLatestCrashesResponse>({
        customBaseUrl: 'https://ufoleaderboard.crocobet.com',
        path: '/api/games/getlastcrashes'
      })
      .pipe(catchError(() => of(null)));
  }

  getSpaceTaxiLastCrashes(): Observable<SpaceTaxiLatestCrashesResponse | null> {
    return this.apiService
      .get$<SpaceTaxiLatestCrashesResponse>({
        customBaseUrl: 'https://spacetaxi.crocobet.com',
        path: '/Leaderboard/api/Games/GetLastCrashes'
      })
      .pipe(catchError(() => of(null)));
  }
}
