export class EventCodes {
  public static readonly APPLICATION_LOADED = 999;
  public static readonly VIRTUAL_SPORT = 1000;
  public static readonly LOGIN = 1001;
  public static readonly REGISTER = 1002;
  public static readonly HEIGHT_CHANGE = 1003;
  public static readonly VERIFICATION = 1004;
  public static readonly SPIN = 1005;
  public static readonly REDIRECTION = 1007;
  public static readonly INNER_HEIGHT = 1008;
  public static readonly LINK = 1010;
  public static readonly OPEN_TAB = 1011;
  public static readonly DEPOSIT = 1012;
  public static readonly TOKEN_REFRESH = 1013;
  public static readonly USER_INFO = 1014;
  public static readonly LOGOUT = 1021;
  public static readonly REQUIRE_LOGIN = 1020;
  public static readonly BALANCE_UPDATE = 1022;
  public static readonly CLOSE_POPUP = 1023;
  public static readonly SWITCH_HEADER_NAV_ITEM = 1024;
  public static readonly NAVIGATE_FROM_HEADER = 1025;
  public static readonly NAVIGATE_BACK_FROM_VIRTUAL = 1026;
  public static readonly OPEN_BONUS_COIN = 1027;
  public static readonly UPDATE_BONUS_COIN = 1028;
  public static readonly NAVIGATION_CALLBACK = 1029;
  public static readonly P2P_OPEN = 1030;
  public static readonly SCROLL_TO = 1031;
  public static readonly FANTASY = 1032;
  public static readonly LOGIN_CANCELED = 1033;
}
