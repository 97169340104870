import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, interval, mergeMap, of, startWith, throttleTime } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { HttpResponseCode } from '@crc/shared';

import { AuthService, AuthStorage } from '../../services';
import { AccountFacade, AuthFacade } from '../../facade';
import { AuthState } from '../../entity';
import { SignedInFeatureState } from '../state';
import { accountActions, authActions } from '../actions';
import { Avatar } from '../../../features/profile/avatars/entity/avatars.interface';
import { AvatarsService } from '../../../features/profile/avatars/services/avatars.service';
import { ChangeNumberFacade } from '../../../features/profile/change-number/facades/change-number.facade';
import {
  identificationDataFromPersonalInfo,
  IdentificationFacade,
  IdentificationTypes,
  SmsIdentificationService
} from '../../../features/sms-identification';
import { isNonNullOrUndefined } from '../../../features/bonus-coin';
import {
  UserRestrictionsFacade,
  UserRestrictionsInterface
} from '../../../features/user-restrictions';
@Injectable()
export class AccountEffect {
  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.updateUser),
      tap((data) => {
        this.authStorageService.setLoginData(data.payload);
      }),
      map(() => accountActions.updateUserSuccess())
    );
  });

  getUserInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.getUserInfo),
      throttleTime(1000),
      switchMap(() => {
        const getUserInfoStream = this.authService.getUser$().pipe(
          mergeMap(({ data, code }) => {
            if (data.mobileNumber?.includes('null')) {
              this.identificationFacade.setNotNavPopupClosing(true);
              this.changeNumberFacade.setNumberChangeState(true);
            }

            if (code === HttpResponseCode.OK) {
              const loginData = this.authStorageService.getLoginData();
              const payload = {
                ...JSON.parse(loginData || '{}'),
                ...data
              };
              const identificationData: identificationDataFromPersonalInfo = {
                smsVerificationExpiresAt: payload.smsVerificationExpiresAt,
                smsVerificationDate: payload.smsVerificationDate,
                mobileNumber: payload.mobileNumber,
                smsVerificationDaysLeft: payload.smsVerificationDaysLeft
              };
              this.store$.dispatch(
                accountActions.identificationCheck({
                  payload: identificationData
                })
              );
              this.authFacade.setLoginStatus$(AuthState.LOGGED_IN);
              this.userRestrictionsFacade.setLatestUserRestrictionsDTO([
                ...(data?.restrictions || []),
                ...(data?.agreements || [])
              ]);
              return [
                accountActions.getUserInfoSuccess({ payload }),
                accountActions.getUserAvatar()
              ];
            } else {
              this.authFacade.setLoginStatus$(AuthState.TOKEN_INVALID);
              return [accountActions.getUserInfoError()];
            }
          })
        );

        return getUserInfoStream.pipe(
          tap(() => {
            this.store$.dispatch(accountActions.getUserDocument());
          })
        );
      }),
      catchError(() => of(accountActions.getUserInfoError()))
    );
  });

  getIdentificationStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.identificationCheck),
      throttleTime(1000),
      filter(isNonNullOrUndefined),
      switchMap((payload) => {
        const personalData = { ...payload }.payload;
        return this.smsIdentificationService
          .checkIdentification(personalData)
          .pipe(
            filter(isNonNullOrUndefined),
            map((status) => {
              //TODO remove this dumb logic
              return accountActions.getIdentificationStatus({
                payload:
                  localStorage.getItem('IdentificationState') ===
                    IdentificationTypes.DoNotShow &&
                  status === IdentificationTypes.Warning
                    ? IdentificationTypes.DoNotShow
                    : status
              });
            }),
            tap((status) => {
              this.accountFacade.setVerificationActive(
                status.payload !== IdentificationTypes.Passed
              );
            })
          );
      }),
      catchError(() =>
        of(accountActions.getIdentificationStatus({ payload: null }))
      )
    );
  });

  getUserAvatar$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.getUserAvatar),
      throttleTime(0),
      switchMap(() => {
        return this.avatarsService.getUserAvatar().pipe(
          map((avatar: Avatar) => {
            const noAvatar: Avatar = {
              avatar: 'crocobet'
            };
            const payload: Avatar = avatar || noAvatar;
            return accountActions.getUserAvatarSuccess({ payload });
          }),
          catchError(() => of(accountActions.getUserAvatarError()))
        );
      })
    );
  });

  getUserDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.getUserDocument),
      throttleTime(1000),
      switchMap(() =>
        this.authService.getUserDocument().pipe(
          map(({ data, code }) => {
            if (code === HttpResponseCode.OK) {
              this.authStorageService.setDocumentData(data);
              return accountActions.getUserDocumentSuccess({
                payload: data
              });
            } else {
              return accountActions.getUserDocumentError();
            }
          })
        )
      ),
      catchError(() => of(accountActions.getUserDocumentError()))
    );
  });

  updateBalanceEvery5Second$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.updateBalanceEvery5Second),
      switchMap(() =>
        interval(60 * 1000).pipe(
          startWith(0),
          takeUntil(this.authFacade.getSignedOutCalled$()),
          switchMap(() => [accountActions.updateBalance()])
        )
      )
    );
  });

  updateBalance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.updateBalance),
      throttleTime(10),
      switchMap(() => {
        return this.authService.getBalance().pipe(
          mergeMap(({ data, code }) => {
            if (code === HttpResponseCode.OK) {
              return [accountActions.updateBalanceSuccess({ payload: data })];
            } else {
              return [accountActions.updateBalanceError()];
            }
          })
        );
      }),
      catchError(() => of(accountActions.updateBalanceError()))
    );
  });

  findIfSessionIsAlive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountActions.findIfSessionAlive),
      throttleTime(100),
      switchMap(() => {
        return this.authService.getUser$().pipe(
          switchMap(({ code }) => {
            if (code === HttpResponseCode.OK) {
              return [
                authActions.signInSuccess(),
                accountActions.getUserInfo()
              ];
            } else {
              return [authActions.signOutSuccess()];
            }
          })
        );
      }),
      catchError(() => of(authActions.signOutSuccess()))
    );
  });

  constructor(
    private readonly store$: Store<SignedInFeatureState>,
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly authFacade: AuthFacade,
    private readonly avatarsService: AvatarsService,
    private readonly authStorageService: AuthStorage,
    private readonly changeNumberFacade: ChangeNumberFacade,
    private readonly smsIdentificationService: SmsIdentificationService,
    private readonly identificationFacade: IdentificationFacade,
    private readonly accountFacade: AccountFacade,
    private readonly userRestrictionsFacade: UserRestrictionsFacade
  ) {}
}
