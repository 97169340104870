import {AbstractControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';

type ContainsControlsValidatorCallback = (
  formControls: UntypedFormGroup
) => ValidationErrors | null;

export function containsValueValidator(
  controlName: string,
  controlName2: string
): ContainsControlsValidatorCallback {
  return (formControls: UntypedFormGroup) => {
    const control: AbstractControl = formControls?.get(controlName);
    const control2: AbstractControl = formControls?.get(controlName2);

    if (control && control2) {
      const value = control?.value.toLowerCase();
      const value2 = control2?.value.toLowerCase();

      const includes = value?.includes(value2) || value2?.includes(value);
      if (value && value2 && includes) {
        control2.setErrors({ ...control2.errors, containsValue: true });
        control.setErrors({ ...control.errors, containsValue: true });
      } else {
        removeError(control, 'containsValue');
        removeError(control2, 'containsValue');
      }
    }

    return null;
  };
}

export function removeError(control: AbstractControl, errorName: string) {
  if (control.hasError(errorName)) {
    delete control.errors[errorName];
    control.updateValueAndValidity();
  }
}
