import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  ResetPassword,
  ResetPasswordFacade,
  ResetPasswordVerify
} from '@crc/facade';
import { Observable, Subject, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'crc-m-reset-password-container',
  templateUrl: './reset-password-container.component.html',
  styleUrls: ['./reset-password-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordContainerComponent {
  private mobileNumber: string;
  private readonly $resetPasswordAction = new Subject<ResetPassword>();
  protected readonly resetPasswordEffect$ = this.getResetPasswordRequest$();
  step$ = this.resetPasswordFacade.getStep$();

  @Output() closePopup = new EventEmitter();

  constructor(private readonly resetPasswordFacade: ResetPasswordFacade) {}

  onResetPasswordVerifyRequest(body: ResetPasswordVerify) {
    body.mobile = this.mobileNumber;
    this.resetPasswordFacade
      .resetPasswordVerify(body)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onResetPasswordRequest(body: ResetPassword) {
    this.$resetPasswordAction.next(body);
  }
  private getResetPasswordRequest$(): Observable<{
    success: boolean;
    retryAfter?: number;
  }> {
    return this.$resetPasswordAction.asObservable().pipe(
      switchMap((body: ResetPassword) => {
        body.mobile = `995:${body.mobile}`;
        this.mobileNumber = body.mobile;
        return this.resetPasswordFacade.resetPassword(body);
      })
    );
  }
}
