import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'crc-m-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent extends InputComponent {
  @Input() flag = 'ge';
  @Input() prefix = '+995';
  @Input() inputMode: 'tel' | 'numeric';

  @Output() blurred: EventEmitter<any> = new EventEmitter<any>();
}
