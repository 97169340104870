import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable, shareReplay } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { getWindow, LanguageFacade } from '@crc/shared';
import { TopMatchesService } from '../services/top-matches.service';
import { SportMatches } from '../entity';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TopMatchesFacade {
  topMatchEvents$: Observable<SportMatches[]>;
  topMatchesLoader = new BehaviorSubject(true);

  constructor(
    private readonly topMatchesService: TopMatchesService,
    private readonly languageService: LanguageFacade
  ) {
    const today = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.topMatchEvents$ = this.languageService.current$.pipe(
      switchMap(() =>
        this.topMatchesService
          .getTopMatchEvents(today)
          .pipe(finalize(() => this.topMatchesLoader.next(false)))
      ),
      shareReplay(1)
    );
  }

  topMatchesLoaderValue(): Observable<boolean> {
    return this.topMatchesLoader.asObservable();
  }

  getOutcomeOdds(topMatches: SportMatches[]) {
    return topMatches
      .filter(
        (data) =>
          data?.participants?.length >= 2 &&
          data?.participants.every((data) => data?.['localId'] && data?.name)
      )
      .map((tp) => ({
        ...tp,
        firstTeamName: this.getTeamNames(tp, 0),
        secondTeamName: this.getTeamNames(tp, 1),
        firstOutcomeOdd: this.getOutcome(tp, '1'),
        secondOutcomeOdd: this.getOutcome(tp, 'X'),
        thirdOutcomeOdd: this.getOutcome(tp, '2')
      }));
  }

  getTeamNames(topMatch: SportMatches, gameSide: number) {
    return topMatch.eventName.split('-')[gameSide];
  }

  getOutcome(topMatch: SportMatches, find: string) {
    return topMatch.eventGames[0].outcomes.filter(
      (o) => o.outcomeName === find
    )[0];
  }

  public getActiveOutComesSet(): Set<number> {
    return new Set<number>(
      JSON.parse(getWindow().localStorage.getItem('sportMetaData') || '[]')
    );
  }
}
