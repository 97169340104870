export enum ChangePasswordSteps {
  ENTER_DETAILS = 1,
  SUCCESS = 2,
  FAIL = 3
}

export enum ChangePasswordResponse {
  SUCCESS = 1,
  OLD_PASSWORD_INCORRECT = 2,
  FAIL = 3
}
export enum ChangePasswordErrorMessages {
  PASSWORD_VALIDATION_FAILED = 1,
  INVALID_VERIFICATION_CODE = 2,
  VERIFICATION_CODE_EXPIRED = 3,
  OLD_PASSWORD_VERIFICATION_FAILED = 4
}

export enum ChangePasswordErrorReasons {
  CONFIRMATION_CHECK_FAILED = 0,
  STRENGTH_CHECK_FAILED = 1,
  SIMILARITY_CHECK_FAILED = 2
}
