import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DirectivesModule,
  nextTick,
  SecondaryFilterDTOInterface
} from '@crc/shared';
import { NgClass, NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'crc-secondary-filter-base',
  standalone: true,
  templateUrl: './secondary-filter-base.component.html',
  styleUrls: ['./secondary-filter-base.component.scss'],
  imports: [DirectivesModule, NgForOf, TranslateModule, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecondaryFilterBaseComponent implements OnChanges {
  @ViewChild('nawWrapper') protected readonly nawWrapperRef!: ElementRef;
  @Input() protected filterItems: SecondaryFilterDTOInterface[];
  @Input() protected scrollIntoItOnActiveChange: boolean = true;
  @Output()
  protected readonly selectedItem: EventEmitter<SecondaryFilterDTOInterface> =
    new EventEmitter();

  constructor(
    protected readonly router: Router,
    protected readonly route: ActivatedRoute
  ) {}
  public ngOnChanges(changes: SimpleChanges): void {
    const currVal: SecondaryFilterDTOInterface[] =
      changes['filterItems']?.currentValue;
    const el = this.nawWrapperRef?.nativeElement as HTMLDivElement;
    if (currVal && this.scrollIntoItOnActiveChange) {
      nextTick(() => {
        (
          el?.querySelector('.secondary-filter__btn-active') as HTMLDivElement
        )?.scrollIntoView({
          inline: 'center',
          block: 'nearest',
          behavior: 'smooth'
        });
      }, 100);
    }
  }

  public handleFilter(data: SecondaryFilterDTOInterface, event: Event): void {
    const el: HTMLDivElement = event.target as HTMLDivElement;
    if (this.scrollIntoItOnActiveChange) {
      nextTick(() => {
        el.scrollIntoView({
          inline: 'center',
          block: 'nearest',
          behavior: 'smooth'
        });
      });
    }
    this.selectedItem.emit(data);
  }
  protected trackFilterItems(
    index: number,
    item: SecondaryFilterDTOInterface
  ): string | number {
    return item.id;
  }
}
