<div class="search">
  <img
    class="search__magnifier"
    src="assets/icons/footer-popup/search.svg"
    alt=""
  />
  <input
    (keydown)="detectKeyboardEvent.emit($event)"
    (input)="onChange(value)"
    [(ngModel)]="value"
    [disabled]="disabled"
    placeholder="{{ 'search' | translate }}"
    type="text"
  />
  <img
    *ngIf="value?.length > 0"
    (click)="clickedCleanup.emit()"
    class="search__clear"
    src="assets/icons/footer-popup/close.svg"
    alt=""
  />
</div>
