import { Injectable } from '@angular/core';
import { forkJoin, Observable, retry } from 'rxjs';
import {
  ScriptLoadInterface,
  ScriptLoadStatusEnum
} from '../entity/script-load.interface';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '@crc/shared';

@Injectable({
  providedIn: 'root'
})
export class PromotionsLoaderService {
  scripts: Record<string, boolean> = {};

  constructor(private environmentService: EnvironmentService) {}

  load$(): Observable<boolean> {
    const widgetsUrl = this.environmentService.config.widgetsUrl;

    const main = `${widgetsUrl}/main.js`;
    const polyfills = `${widgetsUrl}/polyfills.js`;
    const runtime = `${widgetsUrl}/runtime.js`;

    return forkJoin([
      this.loadScript$(main),
      this.loadScript$(runtime),
      this.loadScript$(polyfills)
    ]).pipe(
      map(([main, runtime, polyfills]: ScriptLoadInterface[]) => {
        return main.loaded && runtime.loaded && polyfills.loaded;
      })
    );
  }

  loadScript$(scriptUrl: string) {
    return new Observable<ScriptLoadInterface>((subscriber) => {
      if (!this.scripts[scriptUrl]) {
        this.scripts[scriptUrl] = false;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptUrl;

        script.onload = () => {
          this.scripts[scriptUrl] = true;
          subscriber.next({
            script: scriptUrl,
            loaded: true,
            status: ScriptLoadStatusEnum.Loaded
          });
          subscriber.complete();
        };

        script.onerror = () => {
          subscriber.error({
            script: scriptUrl,
            loaded: false,
            status: ScriptLoadStatusEnum.NotLoaded
          });
          subscriber.complete();
        };

        document.body.appendChild(script);
      } else {
        subscriber.next({
          script: scriptUrl,
          loaded: true,
          status: ScriptLoadStatusEnum.AlreadyLoaded
        });
        subscriber.complete();
      }
    }).pipe(retry(2));
  }
}
