export const dominoIcon = {
  data: `<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#a)" fill="#fff">
    <path
      d="M12.375 3.25a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM12.375 11.125a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM12.375 13.625a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM2.375 16h5a1.877 1.877 0 0 0 1.875-1.875V1.875A1.877 1.877 0 0 0 7.375 0h-5A1.877 1.877 0 0 0 .5 1.875v12.25C.5 15.159 1.341 16 2.375 16Zm5-1.25h-5a.626.626 0 0 1-.625-.625v-5.5H8v5.5c0 .345-.28.625-.625.625Zm-5-13.5h5c.345 0 .625.28.625.625v5.5H1.75v-5.5c0-.345.28-.625.625-.625Z" />
    <path
      d="M6.375 14.133a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM3.375 14.133a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM6.375 12.43a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM3.375 12.43a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM6.375 10.75a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM3.375 10.75a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM13.624 14.75H10.91a.625.625 0 1 0 0 1.25h2.714a1.877 1.877 0 0 0 1.875-1.875V1.875A1.877 1.877 0 0 0 13.624 0H10.91a.625.625 0 1 0 0 1.25h2.714c.345 0 .625.28.625.625v5.5h-3.125a.625.625 0 0 0 0 1.25h3.125v5.5c0 .345-.28.625-.625.625ZM3.375 6.125a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM6.375 6.125a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25Z" />
    <path
      d="M12.375 6.125a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM6.375 3.25a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM3.375 3.25a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM4.875 4.75a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25Z" />
  </g>
  <defs>
    <clipPath id="a">
      <path fill="#fff" d="M0 0h16v16H0z" />
    </clipPath>
  </defs>
</svg>
`,
  name: 'domino' as const
};
