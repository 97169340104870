import { Injectable } from '@angular/core';
import { Language } from '@crc/shared';
import { Observable } from 'rxjs';
import { CatalogService } from '../service/catalog.service';

@Injectable({ providedIn: 'root' })
export class CatalogFacade {
  constructor(private readonly dropdownDataService: CatalogService) {}

  getData$(type: string, lang: Language): Observable<Record<string, string>> {
    return this.dropdownDataService.getData$(type, lang);
  }
}
