<div class="checkbox" data-cy="checkbox">
  <div class="checkbox__container" data-cy="checkbox-container">
    <input
      [(ngModel)]="value"
      (change)="onChange(value)"
      [id]="elementId"
      class="checkbox__input"
      data-cy="checkbox-input"
      type="checkbox"
    />
    <label
      [for]="elementId"
      class="checkbox__label"
      data-cy="checkbox-label"
    ></label>
  </div>
  <div (click)="onTextTrigger()" class="checkbox__text" data-cy="checkbox-text">
    {{ labelFor }}
    <a *ngIf="Suffix" (click)="anchorClicked.emit()">
      {{ Suffix | translate }}
    </a>
  </div>
</div>
