import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InputBaseComponent } from '@crc/components';

@Component({
  selector: 'crc-m-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent extends InputBaseComponent<boolean> {
  @Input() elementId: string;
}
