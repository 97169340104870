export class CryptoUtils {
  static compileByteToHexMap(): string[] {
    const byteToHex: string[] = [];
    for (let n = 0; n <= 0xff; ++n) {
      const hexOctet = n.toString(16).padStart(2, '0');
      byteToHex.push(hexOctet);
    }

    return byteToHex;
  }

  static bufferToHex(buffer: ArrayBufferLike): string {
    const byteToHex = CryptoUtils.compileByteToHexMap();
    const bytes = new Uint8Array(buffer);
    const hexOctets = [];

    for (let i = 0; i < bytes.length; ++i) {
      hexOctets.push(byteToHex[bytes[i]]);
    }

    return hexOctets.join('');
  }

  static sha256(message: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);

    return window.crypto.subtle
      .digest('SHA-256', data)
      .then(CryptoUtils.bufferToHex);
  }
}
