import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  AppInitFacade,
  AuthFacadeModule,
  FacadeModule,
  PromotionsLoaderService
} from '@crc/facade';
import {
  AnimationsModule,
  GlobalErrorHandler,
  ServiceWorkerEntryModule,
  SharedModule,
  TranslateModule,
  SentryStaticService,
  CustomReuseStrategy
} from '@crc/shared';
import { environment } from '../environments/environment';
import { StoreDevModules } from './store/config/devtool';
import { StoreModule } from '@ngrx/store';
import { reducer } from './layout/state/reducers/layout.reducer';
import { IconComponent } from '@crc/components';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { IconsConfig } from '@crc/components';
import { layoutFeatureKey } from './layout/state/reducers/state';
import { EffectsModule } from '@ngrx/effects';
import { LayoutEffects } from './layout/state/effects/layout.effects';
import { AuthModule } from './features/auth/auth.module';
import { lastValueFrom } from 'rxjs';
import { RouteReuseStrategy } from '@angular/router';

const facadeModules = [FacadeModule, AuthFacadeModule];
const libModules = [SharedModule, AnimationsModule, TranslateModule];

export function loadPromotionsScripts(
  promotionsLoaderService: PromotionsLoaderService
) {
  return () => lastValueFrom(promotionsLoaderService.load$());
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    IconComponent,
    AppRoutingModule,
    ...facadeModules,
    ...libModules,
    StoreModule.forFeature(layoutFeatureKey, reducer),
    EffectsModule.forFeature([LayoutEffects]),
    StoreModule.forFeature('reducer', reducer),
    StoreDevModules,
    AuthModule,
    ServiceWorkerEntryModule.forRoot(environment.production)
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadPromotionsScripts,
      deps: [PromotionsLoaderService],
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ...SentryStaticService.provideSentryEntities(),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (init) => () => init.init(),
      deps: [AppInitFacade],
      multi: true
    },
    provideSvgIconsConfig({
      sizes: {
        XS: '16px',
        S: '18px',
        M: '20px',
        L: '24px',
        XL: '28PX',
        XXL: '34px',
        XXXL: '48px',
        half: '50vw'
      },
      defaultSize: 'M',
      icons: IconsConfig
    }),
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
