import { Injectable } from '@angular/core';
import { BaseUrl, HttpService, Language } from '@crc/shared';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../../shared/types/types';
import { map } from 'rxjs/operators';
import { FaqCategory, FaqContent } from '../entity/faq.interface';

@Injectable({ providedIn: 'root' })
export class FaqService {
  constructor(private readonly apiService: HttpService) {}

  getFaqCategories$(lang: Language): Observable<FaqCategory[]> {
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = `/ui/policy/${lang}/categories`;

    return this.apiService
      .get$<ApiResponse>({ path, baseUrl })
      .pipe(map((response) => response.data));
  }

  getContent$(
    categoryId: string,
    subCategoryId: string,
    lang: Language
  ): Observable<FaqContent[]> {
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = `/ui/policy/${lang}/categories/${categoryId}/${subCategoryId}`;

    return this.apiService
      .get$<ApiResponse>({ path, baseUrl })
      .pipe(map((response) => response.data));
  }
}
