import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  distinctUntilChanged,
  finalize,
  Observable,
  tap
} from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LanguageStorage } from './language.storage';
import { Language, LanguageList } from '../types/language';
import { reload } from '../../functions';
import { defaultLanguage } from '../constants/default-language';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class LanguageFacade {
  private $current = new BehaviorSubject<Language>(this.languageFromStorage);

  current$ = this.$current.pipe(
    distinctUntilChanged(),
    filter((lang) => !!lang)
  );

  currentGeIfKa$ = this.current$.pipe(
    map((lang) => {
      return lang === 'ka' ? 'ge' : lang;
    }),
    map((lang) => (lang === 'tr' ? 'en' : lang))
  );

  currentGeIfKaForPromos$ = this.current$.pipe(
    map((lang) => {
      return lang === 'ka' ? 'ge' : lang;
    })
  );

  get languageFromStorage() {
    const lang = this.languageStorage.getLang();
    return lang ? lang : defaultLanguage;
  }

  get current(): Language {
    return this.$current.getValue();
  }

  get currentGeIfKa(): string {
    return this.current === 'ka' ? 'ge' : this.current;
  }

  constructor(
    private readonly translateService: TranslateService,
    private readonly languageStorage: LanguageStorage,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    this.listenToLangMutationFromHtml$().pipe(untilDestroyed(this)).subscribe();
  }

  translateInstant(str: string): string {
    return this.translateService.instant(str);
  }

  translateStream(str: string): Observable<string> {
    return this.translateService.stream(str);
  }

  setCurrent(language: Language) {
    if (language) {
      this.translateService.use(language);
      this.languageStorage.setLang(language);

      if (this.router.url.includes('/profile/verification')) {
        reload(); // TODO FIX verification page can reload itself
      } else {
        this.$current.next(language);
      }
    }
  }
  private listenToLangMutationFromHtml$(
    ref: Document = this._document
  ): Observable<string> {
    let mutationObserver: MutationObserver;
    const mutationObservable$ = new Observable<string>((subscriber) => {
      mutationObserver = new MutationObserver(() => {
        subscriber.next(ref.documentElement.lang);
      });
      mutationObserver?.observe(ref.documentElement, {
        attributeFilter: ['lang']
      });
    });
    return mutationObservable$.pipe(
      tap((incomingLanguage) => {
        const currentLanguage = this.current;
        if (currentLanguage !== incomingLanguage) {
          if (
            incomingLanguage &&
            LanguageList.includes(incomingLanguage as Language)
          ) {
            this.setCurrent(incomingLanguage as Language);
          } else {
            this.setCurrent('en');
          }
        }
      }),
      finalize(() => {
        mutationObserver?.disconnect();
      })
    );
  }
}
