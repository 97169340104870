import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  AuthFacade,
  CaptchaFacade,
  LoginBaseContainerComponent
} from '@crc/facade';
import { PasscodeFacade } from '../../services/passcode.facade';
import { combineLatest, Observable } from 'rxjs';
import { PasscodeState } from '../../../../shared/passcode/entity/passcode-state.type';
import { LoginOtpFacade } from '@crc/facade';
import { map } from 'rxjs/operators';
type ActiveLoginProtocolType = 'default' | 'passcode' | 'OTP';
@Component({
  selector: 'crc-m-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginContainerComponent
  extends LoginBaseContainerComponent
  implements OnDestroy {
  buttonLoaderState$: Observable<boolean> =
    this.authFacade.getButtonLoaderState$();
  public readonly loginMode$: Observable<ActiveLoginProtocolType> =
    combineLatest([
      this.passcodeFacade.getPasscodeState(),
      this.loginOTPFacade.getOTPState$()
    ]).pipe(
      map(
        ([passCodeState, OTPState]: [
          PasscodeState,
          boolean
        ]): ActiveLoginProtocolType => {
          if (OTPState) {
            return 'OTP';
          }
          if (passCodeState !== 'activated') {
            return 'default';
          }
          return 'passcode';
        }
      )
    );

  constructor(
    readonly authFacade: AuthFacade,
    protected readonly captchaFacade: CaptchaFacade,
    private readonly passcodeFacade: PasscodeFacade,
    protected readonly loginOTPFacade: LoginOtpFacade
  ) {
    super(authFacade, captchaFacade, loginOTPFacade);
  }

  ngOnDestroy(): void {
    this.authFacade.setButtonLoaderState(false);
  }
}
