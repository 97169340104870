import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@UntilDestroy()
export class CookieConsentFacade {
  private readonly consentKey = 'cookieConsent';
  private readonly consentAcceptedKey = 'cookieConsentAccepted';
  private readonly consentDuration = 24 * 60 * 60 * 1000;

  private consentStatusSubject = new BehaviorSubject<boolean>(false);
  consentStatus$: Observable<boolean> =
    this.consentStatusSubject.asObservable();

  private readonly $cookiePopupHeight = new BehaviorSubject<number>(0);

  constructor() {
    this.initializeConsentStatus();
  }

  getCookiePopupHeight(): Observable<number> {
    return this.$cookiePopupHeight.asObservable();
  }

  setCookiePopupHeight(height: number) {
    this.$cookiePopupHeight.next(height);
  }

  private initializeConsentStatus(): void {
    const consentAccepted = localStorage.getItem(this.consentAcceptedKey);
    const lastConsentTime = localStorage.getItem(this.consentKey);
    const currentTime = Date.now();

    if (consentAccepted && lastConsentTime) {
      const timeSinceLastConsent = currentTime - parseInt(lastConsentTime, 10);

      if (timeSinceLastConsent > this.consentDuration) {
        this.clearConsentStatus();
        this.consentStatusSubject.next(false);
      } else {
        this.consentStatusSubject.next(true);
      }
    } else {
      this.consentStatusSubject.next(false);
    }
  }

  acceptConsent(): void {
    localStorage.setItem(this.consentKey, Date.now().toString());
    localStorage.setItem(this.consentAcceptedKey, 'true');
    this.consentStatusSubject.next(true);
  }

  declineConsent(): void {
    this.clearConsentStatus();
    this.consentStatusSubject.next(false);
  }

  private clearConsentStatus(): void {
    localStorage.removeItem(this.consentKey);
    localStorage.removeItem(this.consentAcceptedKey);
  }

  isConsentRequired(): boolean {
    return !this.consentStatusSubject.value;
  }
}
