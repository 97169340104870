import { ChangeDetectorRef, Directive } from '@angular/core';
import { concatMap, delay, interval, tap } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AnalyticsService,
  ConnectionService,
  LanguageFacade,
  MatomoAnalyticsService
} from '@crc/shared';
import { VerificationService } from '../services/verification.service';
import { AccountFacade, VerificationStatus } from '../../../auth';
import {
  CompleteData,
  MessageCodes,
  ResultModel,
  stateLinks,
  VerificationType
} from '../entity/verification.interface';
import { FastTrackFacade } from '../../fast-track';
import { Router } from '@angular/router';
import { IdentificationFacade } from '../../sms-identification';

@Directive()
@UntilDestroy()
export class VerificationBaseComponent {
  private readonly intervalTime = 60000 * 5;
  loading: boolean;
  iframeSrc: string;
  iframeHeight: number;
  tokenId: string;
  verificationCompleted: boolean;
  get skipVerificationForIdentification() {
    return this.identificationFacade?.verificationActiveValue || false;
  }
  constructor(
    private readonly verificationService: VerificationService,
    protected readonly accountFacade: AccountFacade,
    private readonly languageFacade: LanguageFacade,
    private readonly cdr: ChangeDetectorRef,
    private readonly connService: ConnectionService,
    private readonly analyticsService: AnalyticsService,
    private readonly matomoAnalyticsService: MatomoAnalyticsService,
    private readonly fastTrackFacade: FastTrackFacade,
    protected readonly router: Router,
    private readonly identificationFacade: IdentificationFacade
  ) {}

  protected initializeBegin(
    verificationType: VerificationType,
    skip: boolean = false
  ) {
    if (this.connService.isSlowConnection()) {
      this.loading = true;
    }

    this.verificationService
      .getTokenId$()
      .pipe(
        tap((token) => {
          this.iframeSrc = `https://widget.identomat.com?session_token=${token}`;
          this.tokenId = token;
          this.cdr.markForCheck();
        }),
        tap((_) => (this.loading = false))
      )
      .subscribe();
    this.verificationService.getIdentomatToken(verificationType, skip);
  }

  protected filterMessages(
    event: Record<string, any>,
    verificationType: VerificationType
  ) {
    const { height, code } = event.data;
    if (height) {
      this.iframeHeight = height;
    } else if (code === MessageCodes.tryAgain) {
      this.initializeBegin(verificationType);
    } else if (code === MessageCodes.resizeHeight) {
      this.iframeHeight = height;
    } else if (code === MessageCodes.finishVerification) {
      this.router.navigateByUrl('/').then();
    }
    this.cdr.markForCheck();
  }

  protected completeVerification(selfie?: boolean) {
    if (!this.verificationCompleted) {
      this.verificationService
        .completeVerification$()
        .pipe(
          tap(({ data }) => {
            if (data) {
              this.redirectToStatus(data.result, selfie, data?.message ?? '');
            }
          })
        )
        .subscribe();

      this.verificationCompleted = true;
    }
  }

  protected redirectToStatus(
    status: CompleteData['result'],
    selfie?: boolean,
    message?: string
  ) {
    this.accountFacade.getUserData();
    this.accountFacade.updateUserDocument();
    if (
      status === ResultModel.manualCheck ||
      (selfie && status === ResultModel.approved)
    ) {
      this.accountFacade.setDynamicCustomVerificationStatus({
        status,
        message
      });
      this.subscribeToVerificationStatus(message);

      // Google Analytics Event Tracking for Verification
      // this.analyticsService.track('Verification', 'Verification', 10, 'Manual');
      this.analyticsService.track({
        event: 'verification_total',
        action: 'Verification',
        verificationTotal: 'success'
      });
      this.matomoAnalyticsService.selectTrackingEvent(
        'verificationTotalSuccess'
      );
    } else if (status === ResultModel.rejected) {
      this.accountFacade.setDynamicCustomVerificationStatus({
        status,
        message
      });
      this.fastTrackFacade.track('Verification_failed').subscribe();
      this.analyticsService.track({
        event: 'verification_total',
        action: 'Verification',
        verificationTotal: 'fail'
      });
      this.matomoAnalyticsService.selectTrackingEvent('verificationTotalFail');
    } else if (status === ResultModel.approved) {
      this.identificationFacade.setIdentification$('Passed');
      this.accountFacade.setVerificationActive(false);
      this.iframeSrc = stateLinks.success + `/?lang=${this.getEnIfTurkey()}`;

      // Google Analytics Event Tracking for Verification
      // this.analyticsService.track(
      //   'Verification',
      //   'Verification',
      //   10,
      //   'Automatic'
      // );
      this.analyticsService.track({
        event: 'verification_total',
        action: 'Verification',
        verificationTotal: 'success'
      });
      this.matomoAnalyticsService.selectTrackingEvent(
        'verificationTotalSuccess'
      );
    }
    this.cdr.markForCheck();
  }

  protected subscribeToVerificationStatus(message?: string) {
    const interval$ = interval(this.intervalTime);
    const intervalSub = interval$
      .pipe(
        untilDestroyed(this),
        tap(() => this.verificationService.getVerificationStatus()),
        delay(5000),
        concatMap(() => this.accountFacade.verificationStatus$),
        tap((status) => {
          if (
            status === VerificationStatus.REJECTED ||
            status === VerificationStatus.UNVERIFIED
          ) {
            this.accountFacade.setDynamicCustomVerificationStatus({
              status,
              message
            });
          } else if (status === VerificationStatus.VERIFIED) {
            this.identificationFacade.setIdentification$('Passed');
            this.accountFacade.setVerificationActive(false);
            this.iframeSrc =
              stateLinks.success + `/?lang=${this.getEnIfTurkey()}`;
          }
          this.cdr.markForCheck();
        })
      )
      .subscribe(() => {
        intervalSub.unsubscribe();
      });
  }

  private getEnIfTurkey() {
    const lang = this.languageFacade.current;
    return lang === 'tr' ? 'en' : this.languageFacade.current;
  }
}
