import { Injectable } from '@angular/core';
import { EnvironmentService } from '@crc/shared';
import { AccountFacade, AuthFacade } from '../../../auth';
import { CasinoGame, FavoriteCasinoGame } from '../entity';
import { CasinoService } from '../services/casino.service';
import { BehaviorSubject, distinctUntilChanged, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CasinoFavoriteFacade {
  private readonly $favorites = new BehaviorSubject<FavoriteCasinoGame[]>([]);
  readonly favorites$ = this.$favorites.asObservable().pipe(
    distinctUntilChanged(),
    map((data) => {
      const games = [];
      data.map((d) => {
        if (d?.game_id) {
          games.push(d);
        }
      });
      return games;
    })
  );

  readonly totalCount$ = this.favorites$.pipe(
    map((favorites) => favorites.length)
  );

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly casinoService: CasinoService,
    private readonly accountFacade: AccountFacade,
    private readonly authFacade: AuthFacade
  ) {}

  private addCasinoToState(casino: FavoriteCasinoGame) {
    this.$favorites.next([
      casino,
      ...this.$favorites.value
    ] as FavoriteCasinoGame[]);
  }

  private removeCasinoFromState(casino: FavoriteCasinoGame) {
    this.$favorites.next([
      ...this.$favorites.value?.filter((s) => s.game_id !== casino.game_id)
    ] as FavoriteCasinoGame[]);
  }

  private addCasinoToFavorites(casino: FavoriteCasinoGame) {
    this.addCasinoToState(casino);

    this.casinoService
      .addToFavorites(casino.provider, casino.game_id)
      .pipe(
        catchError((error) => {
          this.removeCasinoFromState(casino);
          return of(error);
        })
      )
      .subscribe();
  }

  private removeCasinoFromFavorites(casino: FavoriteCasinoGame) {
    this.removeCasinoFromState(casino);
    this.casinoService
      .removeFromFavorites(casino.provider, casino.game_id)
      .pipe(
        catchError((error) => {
          this.addCasinoToState(casino);
          return of(error);
        })
      )
      .subscribe();
  }

  isEmpty() {
    return this.$favorites.value?.length === 0;
  }

  toggleFavorite(casino: FavoriteCasinoGame) {
    if (this.authFacade.getIsLoggedIn()) {
      this.isCasinoInFavorites(casino)
        ? this.removeCasinoFromFavorites(casino)
        : this.addCasinoToFavorites(casino);
    }
  }

  isCasinoInFavorites(game: CasinoGame): boolean {
    return (
      this.$favorites.value?.filter((s) => s.game_id === game.game_id)?.length >
      0
    );
  }

  updateFavorites(slots: FavoriteCasinoGame[]) {
    this.$favorites.next(slots);
  }
}
