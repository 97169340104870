import { CasinoGame } from './casino-game';

export interface FilterAmount {
  value: 'FreeChips' | string;
  minStart: number;
  minEnd: number;
  isActive?: boolean;
  games?: CasinoGame[];
  hasGame?: boolean;
}
export class EgtLobbyGameInterface {
  //for future reference
  public static readonly gameId?: 'evofreespin0001' = 'evofreespin0001';
  public static readonly ['game_id']?: 'evofreespin0001' = 'evofreespin0001';
  public static readonly ['group_name']?: 'other' | unknown = 'other';
  public static readonly limits?: { min: '1'; max: '1' } | unknown;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public static readonly name?: 'FreeChips Lobby';
  public static readonly order?: number | unknown;
  public static readonly platform?: '' | unknown;
  public static readonly provider?: 'evolution@evolution' =
    'evolution@evolution';
  public static readonly tags?: unknown;
  public static readonly url?:
    | 'https://cms.crocobet.com/integrations/v2/casino/providers/evolution@evolution/games/evofreespin0001/get-url'
    | unknown;
}
