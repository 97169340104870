import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'crc-m-shared-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {
  @Input() path: string;
  @Input() alt: string;
  @Input() rotate = false;

  @Output() clicked = new EventEmitter();
  @Input() dataCy!: string;
}
