import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { fromEvent, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[crcSharedSwipeY]'
})
export class SwipeYDirective implements OnInit, OnDestroy {
  @Input() isMinHeightActive = false;
  @Input() isSwipeForbidden = false;
  @Input() navbarHeight = 64;
  @Output() minimize = new EventEmitter<void>();

  private targetElementElementReference: HTMLElement;
  private currentHeight: number | null = null;
  private initialHeight: number;
  private latest: number | null = null;
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.targetElementElementReference =
      (this.elementRef.nativeElement as HTMLElement).offsetParent !== null
        ? ((this.elementRef.nativeElement as HTMLElement)
            .offsetParent as HTMLElement)
        : (this.elementRef.nativeElement as HTMLElement);
    fromEvent(this.viewContainerRef.element.nativeElement, 'touchstart')
      .pipe(
        untilDestroyed(this),
        tap((element) => {
          this.initialHeight = this.targetElementElementReference.offsetHeight;
          this.latest = (element as TouchEvent).targetTouches[0].clientY;
          this.currentHeight = this.initialHeight;
        })
      )
      .subscribe();
    fromEvent(this.viewContainerRef.element.nativeElement, 'touchmove')
      .pipe(
        untilDestroyed(this),
        tap((element) => this.moveTouch(element as TouchEvent))
      )
      .subscribe();
    fromEvent(this.viewContainerRef.element.nativeElement, 'touchend')
      .pipe(
        untilDestroyed(this),
        tap(() => this.endTouch())
      )
      .subscribe();
  }

  moveTouch(event: TouchEvent) {
    if (this.isSwipeForbidden || this.latest === null) return;
    if (this.targetElementElementReference) {
      const offset = event.targetTouches[0].clientY;
      let height: number | string = getComputedStyle(
        this.targetElementElementReference
      ).height;
      height = Number(height.substring(0, height.length - 2));
      const diff = height - offset + this.latest;
      this.targetElementElementReference.style.height =
        diff <= this.initialHeight ? diff + 'px' : this.initialHeight + 'px';
      if (this.isMinHeightActive) {
        this.targetElementElementReference.style.minHeight =
          diff < this.initialHeight ? 'auto' : this.initialHeight + 'px';
      }
      if (height <= 14) {
        this.targetElementElementReference.style.bottom = '0';
      } else {
        this.targetElementElementReference.style.bottom = null;
      }
      this.currentHeight = height;
      this.latest = offset;
    }
  }
  endTouch() {
    if (this.isSwipeForbidden) return;
    if (this.currentHeight < this.initialHeight / 2 + this.navbarHeight) {
      this.minimize.emit();
    } else {
      if (this.targetElementElementReference) {
        this.targetElementElementReference.style.height = null;
        if (this.isMinHeightActive)
          this.targetElementElementReference.style.minHeight = null;
      }
    }
    this.currentHeight = this.initialHeight;
  }

  ngOnDestroy(): void {
    this.targetElementElementReference = null;
    this.minimize.emit();
  }
}
