import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  ResetPassword,
  ResetPasswordFormHelperFacade,
  ResetPasswordSteps,
  ResetPasswordVerify
} from '@crc/facade';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'crc-m-reset-password-presentation',
  templateUrl: './reset-password-presentation.component.html',
  styleUrls: ['./reset-password-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordPresentationComponent {
  constructor(private readonly formHelper: ResetPasswordFormHelperFacade) {}
  @Input() step: ResetPasswordSteps;

  @Output() resetPassword$: EventEmitter<ResetPassword> =
    new EventEmitter<ResetPassword>();
  @Output() resetPasswordVerify$: EventEmitter<ResetPasswordVerify> =
    new EventEmitter<ResetPasswordVerify>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  ResetPasswordSteps = ResetPasswordSteps;
  @Input() resetPasswordEffect!: {
    success: boolean;
    retryAfter?: number;
  } | null;
  phoneNumberForm: UntypedFormGroup = this.formHelper.buildPhoneFormControls();

  resetPassword(body: ResetPassword) {
    this.resetPassword$.emit(body);
  }

  resetPasswordVerify(body: ResetPasswordVerify) {
    this.resetPasswordVerify$.emit(body);
  }
}
