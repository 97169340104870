import { Injectable } from '@angular/core';
import {
  combineLatest,
  filter,
  from,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs';
import { GamesService } from '../services/games.service';
import { LanguageFacade, replaceLanguageKeyInString } from '@crc/shared';
import {
  GameType,
  GameTypeById,
  P2pAndSpinGamesInterfaceResponse
} from '../entity/p2p-game.types';
import { map } from 'rxjs/operators';
import {
  AccountFacade,
  AuthFacade,
  LoginPopupFacade,
  NotVerifiedGuard
} from '../../../auth';

@Injectable({
  providedIn: 'root'
})
export class GamesFacade {
  verificationStatus: number;

  constructor(
    private readonly gamesService: GamesService,
    private readonly languageFacade: LanguageFacade,
    private readonly accountFacade: AccountFacade,
    private readonly authFacade: AuthFacade,
    private readonly loginGuardFacade: LoginPopupFacade,
    private readonly notVerifiedGuard: NotVerifiedGuard
  ) {}

  replaceLanguage(url: string, lang: string): string {
    const pokerPrefix = 'games.';
    const replacedLang = this.languageFacade.current;
    return replaceLanguageKeyInString(
      url,
      lang === 'tr' && !url.includes(pokerPrefix) ? 'en' : lang,
      replacedLang === 'tr' && !url.includes(pokerPrefix) ? 'en' : replacedLang
    );
  }

  getGameLink(
    provider: string,
    customerId: number,
    gameId?: number
  ): Observable<string> {
    return this.gamesService.getGameLink(provider, customerId, gameId).pipe(
      map((res) => {
        try {
          JSON.parse(res);
          return null;
        } catch (e) {
          return res;
        }
      })
    );
  }
  private getGameLinkNewMethod(url: string): Observable<string | null> {
    return this.gamesService.getGameLinkUrlNewMethod(url).pipe(
      map((res) => {
        try {
          JSON.parse(res);
          return null;
        } catch (e) {
          return res;
        }
      })
    );
  }

  getP2PgameConfig(): Observable<P2pAndSpinGamesInterfaceResponse[]> {
    return this.gamesService.getP2PgameConfig();
  }

  getTournament(gameType: GameType) {
    return this.gamesService.getGameTournaments(gameType).pipe(
      shareReplay(1),
      map((tournaments) =>
        tournaments.map((tournament) => {
          return {
            ...tournament,
            startDate: new Date(tournament.startDate)
          };
        })
      )
    );
  }

  getLeaderboard(game: string, round: number) {
    return this.gamesService.getLeaderBoard(game, round).pipe(shareReplay(1));
  }

  onGoToGame(
    gameType: GameTypeById,
    target: string,
    mobile: boolean,
    isSpin: boolean,
    url?: string,
    withNewMethod?: boolean
  ) {
    return this.authFacade.isLoggedIn$().pipe(
      take(1),
      switchMap((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.loginGuardFacade.openLoginPopup();
          return this.loginGuardFacade.getLoginResult$().pipe(take(1));
        } else {
          return of(isLoggedIn);
        }
      }),
      switchMap((loggedIn) => {
        return this.accountFacade.isVerified$.pipe(
          tap((status) => {
            status
              ? (this.verificationStatus = 1)
              : (this.verificationStatus = 2);
          }),
          take(1),
          map((verified) => [loggedIn, verified])
        );
      }),
      switchMap(([loggedIn, verified]) => {
        if (loggedIn) {
          if (verified) {
            const isNewMethod = !!(withNewMethod && url);
            const gameLink$ = isNewMethod
              ? this.getGameLinkUrlNewMethod(url).pipe(
                  map((url) => (url ? this.replaceLanguage(url, 'ka') : url)),
                  take(1)
                )
              : this.getGameLinkUrl(gameType).pipe(
                  map((url) => (url ? this.replaceLanguage(url, 'ka') : url))
                );
            return combineLatest([gameLink$, this.getUfoLink(gameType)]).pipe(
              tap(([gameLink, ufoLink]) => {
                if (gameLink && ufoLink) {
                  const ufoToken = ufoLink.split('/')[6];
                  const finalUrl = `${
                    mobile ? gameLink.replace('web', 'mobile') : gameLink
                  }${
                    isSpin ? '/?target=Spin' : '?'
                  }&ufo-token=${ufoToken}&old=${this.verificationStatus}`;

                  const win = window.open(finalUrl, target);
                  win.focus();
                }
              })
            );
          } else {
            return from(this.notVerifiedGuard.redirectToVerification());
          }
        } else {
          return of({});
        }
      })
    );
  }

  getGameLinkUrl(gameType): Observable<string> {
    return this.accountFacade.personalData$.pipe(
      filter((pData) => !!pData.customerId),
      take(1),
      switchMap((item) => {
        return this.getGameLink('lambda', item.customerId, gameType);
      })
    );
  }

  getGameLinkUrlNewMethod(url: string): Observable<string | null> {
    return this.getGameLinkNewMethod(url);
  }

  getUfoLink(gameType): Observable<string> {
    return this.accountFacade.personalData$.pipe(
      filter((pData) => !!pData.customerId),
      take(1),
      switchMap((item) => {
        return this.getGameLink('ufo', item.customerId, gameType);
      })
    );
  }
}
