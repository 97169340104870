import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseUrl, HttpService } from '@crc/shared';
import { PaymentProcess, WithdrawForm } from '../entity/deposit.interface';
import {
  BankProvidersData,
  PaymentProviderResponse,
  PaymentRequestObj
} from '../..';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../../shared/types';
import { ButtonInterface } from '../entity/button.interface';

@Injectable({
  providedIn: 'root'
})
export class DepositService {
  constructor(
    private readonly apiService: HttpService,
    private readonly http: HttpClient
  ) {}

  paymentAmountButtons(type: string): Observable<ButtonInterface> {
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = `/payments/presets/${type}`;
    return this.apiService
      .get$<{ data: ButtonInterface }>({ path, baseUrl })
      .pipe(map((res) => res.data));
  }

  initialPaymentRequest(
    requestBody: PaymentRequestObj | WithdrawForm
  ): Observable<PaymentProviderResponse> {
    const path = `/customers/payments/initialize`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const body = requestBody;

    return this.apiService
      .post$<ApiResponse, PaymentRequestObj | WithdrawForm>({
        path,
        baseUrl,
        body
      })
      .pipe(map((res) => res.data));
  }

  processPaymentRequest(
    requestBody: PaymentRequestObj | PaymentProcess
  ): Observable<PaymentProviderResponse> {
    const path = `/customers/payments/process`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const body = requestBody;

    return this.apiService
      .post$<ApiResponse, PaymentRequestObj | PaymentProcess>({
        path,
        baseUrl,
        body
      })
      .pipe(map((res) => res.data));
  }

  getBankProviders(): Observable<BankProvidersData[]> {
    const path = '/customers/payments/providers/active';
    const baseUrl: BaseUrl = 'cmsApiUrl';

    return this.apiService
      .get$<{ data: BankProvidersData[] }>({ path, baseUrl })
      .pipe(map((result) => result.data));
  }
  // startDeposit(body: TransferRequest): Observable<TransferDepositResponse> {
  //   const path = `/teg/transfer/api/player/start-deposit`;
  //   const baseUrl: BaseUrl = 'apiUrl';
  //
  //   const headers: Record<string, string> = {
  //     'Accept-Language': 'ka',
  //     channel: 'MOBILE'
  //   };
  //
  //   return this.apiService.post$<TransferDepositResponse, TransferRequest>({
  //     path,
  //     baseUrl,
  //     body,
  //     headers
  //   });
  // }
  startDeposit(body: any): Observable<any> {
    const path = `/teg/transfer/api/player/start-deposit`;
    const baseUrl: BaseUrl = 'apiUrl';

    const headers: Record<string, string> = {
      'Accept-Language': 'ka',
      channel: 'MOBILE'
    };

    return this.apiService.post$<any, any>({
      path,
      baseUrl,
      body,
      headers
    });
  }
}
