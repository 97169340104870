import { cashIcon } from './icons/CASH';
import { emptyIcon } from './icons/empty';
import { freebetIcon } from './icons/FREEBET';
import { freespinIcon } from './icons/FREESPIN';
import { ufospinIcon } from './icons/UFOSPIN';
import { blackjackIcon } from './icons/blackjack';
import { baccaratActiveIcon } from './icons/baccarat_active';
import { blackjackActiveIcon } from './icons/blackjack_active';
import { homeIcon } from './icons/home';
import { homeActiveIcon } from './icons/home_active';
import { pokerActiveIcon } from './icons/poker_active';
import { rouletteIcon } from './icons/roulette';
import { rouletteActiveIcon } from './icons/roulette_active';
import { showgamesIcon } from './icons/showgames';
import { showgamesActiveIcon } from './icons/showgames_active';
import { egtIcon } from './icons/egt';
import { elkIcon } from './icons/elk';
import { evolutionEvolutionIcon } from './icons/evolutionEvolution';
import { evolutionIcon } from './icons/evolution';
import { ezgIcon } from './icons/ezg';
import { favoritesIcon } from './icons/favorites';
import { gamesIcon } from './icons/games';
import { historyIcon } from './icons/history';
import { liveIcon } from './icons/live';
import { newBuyBonusIcon } from './icons/new-buy-bonus';
import { newSlotsIcon } from './icons/new-slots';
import { otherIcon } from './icons/other';
import { prgBetConstructIcon } from './icons/prgbet-construct';
import { roulettecrocoIcon } from './icons/roulettecroco';
import { slotsIcon } from './icons/slots';
import { sportIcon } from './icons/sport';
import { topGamesIcon } from './icons/top-games';
import { ufoIcon } from './icons/ufo';
import { vgsIcon } from './icons/vgs';
import { vgsBetConstructIcon } from './icons/VGSbet-construct';
import { vigIcon } from './icons/vig';
import { vigBetConstructIcon } from './icons/VIGbet-construct';
import { virtualIcon } from './icons/virtual';
import { vlgIcon } from './icons/vlg';
import { pokerIcon } from './icons/poker';
import { jackpotIcon } from './icons/jackpot';
import { carouselIcon } from './icons/carousel';
import { topMatchesIcon } from './icons/top-matches';
import { dominoIcon } from './icons/domino';
import { backgammonIcon } from './icons/backgammon';
import { buraIcon } from './icons/bura';
import { pokerSecondIcon } from './icons/poker-second';
import { phone02Icon } from './icons/phone_02';
import { chatIcon } from './icons/chat';
import { emailIcon } from './icons/email';
import { messengerIcon } from './icons/messenger';
import { viberIcon } from './icons/viber';
import { telegramIcon } from './icons/telegram';
import { Icon25Age } from './icons/icon25Age';
import { moreGamesIcon } from './icons/user-bar/more_games';
import { moreGamesCasinoIcon } from './icons/user-bar/more_games_casino';
import { youtubeIcon } from './icons/youtube';
import { facebookIcon } from './icons/facebook';
import { instagramIcon } from './icons/instagram';
import { userBarIcons } from './icons/user-bar';
import { promotionsIcon } from './icons/user-bar/promotions';
import { profileIcon } from './icons/user-bar/profile';
import { depositIcon } from './icons/user-bar/deposit';
import { searchIcon } from './icons/user-bar/search';
import { notificationIcon } from './icons/user-bar/notification';
import { casinoIcons } from './icons/casino/index';
import { casinoIcon } from './icons/casino';
import { baccaratIcon } from './icons/casino/baccarat';
import { promosIcon } from './icons/promos';
import { authOtpIconIcon } from './icons/web-header/auth_otp_icon';
import { eyeOnIcon } from './icons/web-header/eye-on';
import { eyeOffIcon } from './icons/web-header/eye-off';
import { passwordIcon } from './icons/web-header/password';
import { userIcon } from './icons/web-header/user';
import { resetPasswordIcon } from './icons/web-header/reset-password';
import { sportIconWEB } from './icons/web-navigation/sport';
import { casinoIconWEB } from './icons/web-navigation/casino';
import { gamesIconWEB } from './icons/web-navigation/games';
import { liveIconWEB } from './icons/web-navigation/live';
import { pokerIconWEB } from './icons/web-navigation/poker';
import { promosIconWEB } from './icons/web-navigation/promos';
import { slotsIconWEB } from './icons/web-navigation/slots';
import { ufoIconWEB } from './icons/web-navigation/ufo';
import { virtualIconWEB } from './icons/web-navigation/virtual';
import { alertWebIcon } from './icons/crm-web/alertWeb';
import { promotionHeaderWebIcon } from './icons/main-page-web/promotion_header_web';
import { topGamesWebIcon } from './icons/main-page-web/topGamesWeb';
import { logoIcon } from './icons/logo/logo';
import { fastGameIcon } from './icons/fast-game';
import { fantasyIcon } from './icons/fantasy';
import { fantasyIconWEB } from './icons/web-navigation/fantasy';
import { freeSportsIcon } from './icons/free-sports';
import { stream } from './icons/stream';

const icons = [
  fastGameIcon.name,
  cashIcon.name,
  emptyIcon.name,
  freebetIcon.name,
  freespinIcon.name,
  ufospinIcon.name,
  casinoIcons[baccaratIcon.name],
  casinoIcons[blackjackIcon.name],
  casinoIcons[baccaratActiveIcon.name],
  casinoIcons[blackjackActiveIcon.name],
  casinoIcons[homeIcon.name],
  casinoIcons[homeActiveIcon.name],
  casinoIcons[pokerActiveIcon.name],
  casinoIcons[pokerIcon.name],
  casinoIcons[rouletteIcon.name],
  casinoIcons[rouletteActiveIcon.name],
  casinoIcons[showgamesIcon.name],
  casinoIcons[showgamesActiveIcon.name],
  casinoIcon.name,
  egtIcon.name,
  elkIcon.name,
  evolutionIcon.name,
  ezgIcon.name,
  favoritesIcon.name,
  gamesIcon.name,
  historyIcon.name,
  liveIcon.name,
  newBuyBonusIcon.name,
  newSlotsIcon.name,
  otherIcon.name,
  userBarIcons[promotionsIcon.name],
  evolutionEvolutionIcon.name,
  prgBetConstructIcon.name,
  roulettecrocoIcon.name,
  slotsIcon.name,
  sportIcon.name,
  stream.name,
  topGamesIcon.name,
  ufoIcon.name,
  vgsIcon.name,
  vgsBetConstructIcon.name,
  vigIcon.name,
  vigBetConstructIcon.name,
  virtualIcon.name,
  fantasyIcon.name,
  freeSportsIcon.name,
  vlgIcon.name,
  jackpotIcon.name,
  carouselIcon.name,
  topMatchesIcon.name,
  dominoIcon.name,
  backgammonIcon.name,
  buraIcon.name,
  pokerSecondIcon.name,
  phone02Icon.name,
  chatIcon.name,
  emailIcon.name,
  messengerIcon.name,
  viberIcon.name,
  telegramIcon.name,
  Icon25Age.name,
  youtubeIcon.name,
  facebookIcon.name,
  instagramIcon.name,
  userBarIcons[profileIcon.name],
  moreGamesIcon.name,
  moreGamesCasinoIcon.name,
  userBarIcons[depositIcon.name],
  userBarIcons[searchIcon.name],
  userBarIcons[notificationIcon.name],
  userBarIcons[moreGamesIcon.name],
  userBarIcons[moreGamesCasinoIcon.name],
  promosIcon.name,
  authOtpIconIcon.name,
  eyeOnIcon.name,
  eyeOffIcon.name,
  passwordIcon.name,
  userIcon.name,
  resetPasswordIcon.name,
  sportIconWEB.name,
  casinoIconWEB.name,
  gamesIconWEB.name,
  liveIconWEB.name,
  pokerIconWEB.name,
  promosIconWEB.name,
  slotsIconWEB.name,
  sportIconWEB.name,
  ufoIconWEB.name,
  virtualIconWEB.name,
  fantasyIconWEB.name,
  alertWebIcon.name,
  gamesIconWEB.name,
  promotionHeaderWebIcon.name,
  topGamesWebIcon.name,
  logoIcon.name
] as const;
export type IconName = (typeof icons)[number];

export const IconsConfig = [
  fastGameIcon,
  cashIcon,
  emptyIcon,
  freebetIcon,
  freespinIcon,
  ufospinIcon,
  blackjackIcon,
  baccaratActiveIcon,
  blackjackActiveIcon,
  homeIcon,
  homeActiveIcon,
  pokerActiveIcon,
  rouletteIcon,
  rouletteActiveIcon,
  showgamesIcon,
  showgamesActiveIcon,
  casinoIcon,
  egtIcon,
  elkIcon,
  evolutionIcon,
  ezgIcon,
  favoritesIcon,
  gamesIcon,
  historyIcon,
  liveIcon,
  newBuyBonusIcon,
  newSlotsIcon,
  otherIcon,
  evolutionEvolutionIcon,
  prgBetConstructIcon,
  roulettecrocoIcon,
  slotsIcon,
  sportIcon,
  stream,
  topGamesIcon,
  ufoIcon,
  vgsIcon,
  vgsBetConstructIcon,
  vigIcon,
  vigBetConstructIcon,
  virtualIcon,
  fantasyIcon,
  freeSportsIcon,
  vlgIcon,
  pokerIcon,
  jackpotIcon,
  carouselIcon,
  topMatchesIcon,
  dominoIcon,
  backgammonIcon,
  buraIcon,
  pokerSecondIcon,
  phone02Icon,
  chatIcon,
  emailIcon,
  messengerIcon,
  viberIcon,
  telegramIcon,
  Icon25Age,
  youtubeIcon,
  facebookIcon,
  instagramIcon,
  promotionsIcon,
  promosIcon,
  profileIcon,
  moreGamesIcon,
  moreGamesCasinoIcon,
  depositIcon,
  searchIcon,
  notificationIcon,
  baccaratIcon,
  authOtpIconIcon,
  eyeOffIcon,
  eyeOnIcon,
  passwordIcon,
  userIcon,
  resetPasswordIcon,
  sportIconWEB,
  casinoIconWEB,
  gamesIconWEB,
  liveIconWEB,
  pokerIconWEB,
  promosIconWEB,
  slotsIconWEB,
  sportIconWEB,
  ufoIconWEB,
  virtualIconWEB,
  fantasyIconWEB,
  alertWebIcon,
  gamesIconWEB,
  promotionHeaderWebIcon,
  topGamesWebIcon,
  logoIcon
];
