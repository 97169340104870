import { BOG_BIN, TBC_BIN } from '../entity/cards-info';

export const getBankIconName = (bin: number) => {
  if (TBC_BIN.includes(bin)) {
    return 'tbc';
  } else if (BOG_BIN.includes(bin)) {
    return 'bog';
  } else {
    return 'another-bank';
  }
};
