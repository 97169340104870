import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { egtOdometerCount } from '../../../jackpot';
import {
  PragmaticDigitalOdometerConfig,
  PragmaticDigitalOdometerData,
  PragmaticJackpot
} from '../../entity';

@Component({
  selector: 'crc-pragmatic-digital-odometer-container',
  templateUrl: './pragmatic-digital-odometer-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PragmaticDigitalOdometerContainerComponent {
  readonly EGT_ODOMETER_COUNT = egtOdometerCount;

  @Input() disableLogo: boolean;
  @Input() vertical: boolean;
  @Input() largeIcon: boolean;
  @Input() odometerConfig: PragmaticDigitalOdometerConfig[];
  @Input() jackpot: PragmaticJackpot[];
  @Input() odometerData: PragmaticDigitalOdometerData[];
  @Input() showPopupInfo = true;
  @Input() isMainPage: boolean;

  @Output() refresh = new EventEmitter();
}
