import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { InputBaseComponent } from '@crc/components';

@Component({
  selector: 'crc-m-shared-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  exportAs: 'public_content'
})
export class InputComponent
  extends InputBaseComponent<string>
  implements OnInit
{
  @Input() type: string;
  @Input() inputMode: 'none' | 'text' | 'tel' | 'decimal' | 'numeric';
  @Input() name: string;
  @Input() placeholder: string;
  @Input() mask: string;
  @Input() autoComplete: string;
  @Input() suffix = '';
  @Input() prefix = '';

  @Input() errorsTexts: { type: string; text: string }[];
  @ViewChild('inputElement', {
    read: ElementRef<HTMLInputElement>
  })
  public inputRef: ElementRef<HTMLInputElement>;
  currentDisplayedError: Observable<string>;
  ngOnInit() {
    super.ngOnInit();
    this.updateErrorToDisplay();
  }

  handleInputChange(value: string) {
    this.value = value;
    this.onChange?.(value);  // Call registered change function
    this.updateErrorToDisplay();
    this.triggerReflow(); // Fix layout issues on older devices
    this.cdr.detectChanges(); // Ensure change detection
  }

  handleBlur(event: FocusEvent) {
    this.onTouched?.(); // Call registered touched function
    this.updateErrorToDisplay();
    this.blurred.emit(event);
    this.cdr.detectChanges(); // Ensure blur state updates
  }

  private triggerReflow() {
    // Force layout reflow to fix WebView rendering issues
    setTimeout(() => {
      const input = this.inputRef.nativeElement;
      input.style.display = 'none';
      input.offsetHeight; // Trigger reflow
      input.style.display = '';
    }, 0);
  }

  updateErrorToDisplay() {
    this.currentDisplayedError = of(null).pipe(
      map((_) => {
        let errorText = '';
        this.errorsTexts?.forEach((error) => {
          if (
            this.control.errors[error.type] &&
            (this.control?.value || this.control?.hasError('forceRequired'))
          ) {
            errorText = error.text;
          }
        });

        return errorText ?? '';
      })
    );
  }
}
