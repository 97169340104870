<button
  [class]="type"
  [disabled]="disable"
  (click)="clicked.emit()"
  [attr.data-cy]="(dataCy && dataCy + '-button') || 'button'"
  [style.--button-opacity]="opacity"
>
  <crc-m-loader
    [attr.data-cy]="(dataCy && dataCy + '-button-loader') || 'button-loader'"
    class="loading"
    *ngIf="loader; else content"
    [borderColor]="'primary'"
  ></crc-m-loader>
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</button>
