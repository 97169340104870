import { Injectable } from '@angular/core';
import { of, switchMap, tap } from 'rxjs';

import { AccountFacade } from '../../../auth';
import { SlotsService } from '../services/slots.service';
import { SlotsHistoryFacade } from './slots-history.facade';

@Injectable()
export class SlotsHistoryDataFacade {
  constructor(
    private accountFacade: AccountFacade,
    private slotsService: SlotsService,
    private slotsHistoryFacade: SlotsHistoryFacade
  ) {
    this.accountFacade.customerId$
      .pipe(
        switchMap((customerId) =>
          customerId ? this.slotsService.getHistory() : of([])
        ),
        tap((games) => this.slotsHistoryFacade.updateHistory(games))
      )
      .subscribe();
  }
}
