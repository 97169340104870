import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl, HttpService } from '@crc/shared';
import { ApiResponse } from '../../../shared';
import { map } from 'rxjs/operators';
import { MissionItems, MissionUpdate } from '../entity/missions.interface';
import { BonusItems, BonusUpdate } from '../entity/bonuses.interface';

@Injectable({
  providedIn: 'root'
})
export class CrmService {
  constructor(private readonly apiService: HttpService) {}

  getMissions(updateMissions: MissionUpdate): Observable<MissionItems> {
    const path = `/marketing/missions?page=${updateMissions.page}&size=${updateMissions.count}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.get$<ApiResponse>({ path, baseUrl }).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getBonuses(updateBonuses: BonusUpdate): Observable<BonusItems> {
    const path = `/marketing/rewards?page=${updateBonuses.page}&size=${updateBonuses.count}&sort=[%22createdAt%22,0]&filter={"category":${updateBonuses.category}}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<ApiResponse>({ path, baseUrl })
      .pipe(map((res) => res.data));
  }
}
