<div class="carousel" data-cy="carousel">
  <ng-template #slide let-carouselItem>
    <div
      *ngIf="(carouselItem | json) === '{}'; else notLastChild"
      (click)="onMoreClick()"
      [routerLink]="routeLink ? routeLink : undefined"
      [queryParams]="queryParams"
      class="last-card"
      data-cy="last-card"
    >
      <span>
        {{ 'see_more' | translate }}
      </span>
      <span *ngIf="count" class="last-card__count"> ({{ count }}) </span>
    </div>
    <ng-template #notLastChild>
      <a
        (click)="itemClicked.emit(carouselItem); $event.preventDefault()"
        class="carousel__game"
        data-cy="carousel-game"
        href="#"
      >
        <crc-m-new-badge
          *ngIf="carouselItem?.tags?.includes('new')"
        ></crc-m-new-badge>
        <ng-container *ngIf="!carouselItem.iframeUrl; else iframe">
          <ng-container
            *ngIf="
              carouselItem?.imageSet &&
                carouselItem?.imageSet?.original &&
                carouselItem?.imageSet?.webp;
              else noImgSet
            "
          >
            <picture>
              <source
                type="image/webp"
                [srcset]="carouselItem?.imageSet?.webp"
                [attr.data-srcset]="carouselItem?.imageSet?.webp"
              />
              <img
                class="carousel__img"
                data-cy="carousel-img"
                alt="Casino Image"
                [src]="carouselItem?.imageSet?.original"
                [attr.data-src]="carouselItem?.imageSet?.original"
                loading="lazy"
                (error)="addThumbnail($event)"
                width="100%"
                height="86"
              />
            </picture>
          </ng-container>
          <ng-template #noImgSet>
            <img
              *ngIf="!carouselItem.video"
              [src]="carouselItem.image"
              class="carousel__img"
              data-cy="carousel-img"
              alt="Casino Image"
              loading="lazy"
              (error)="addThumbnail($event)"
              width="100%"
              height="86"
            />
            <div *ngIf="carouselItem.video" class="carousel-video">
              <video
                (loadeddata)="func($event)"
                [muted]="true"
                [autoplay]="true"
                [loop]="true"
                [poster]="null"
                preload="auto"
                playsinline
                data-object-fit="cover"
              >
                <source
                  [src]="carouselItem.webm"
                  type="video/webm"
                  autostart="true"
                />
                <source
                  [src]="carouselItem.video"
                  type="video/mp4"
                  autostart="true"
                />
              </video>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #iframe>
          <div class="carousel__animation">
            <iframe
              *ngIf="carouselItem.iframeUrl as url"
              [src]="url | safeUrl"
              width="100%"
              height="100%"
              title="iframe"
              class="carousel__animation-iframe"
            ></iframe>
          </div>
        </ng-template>
      </a>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="extraContent; context: { $implicit: carouselItem }"
    ></ng-container>
  </ng-template>
  <crc-shared-swiper
    class="background-transparent scrollable-slider2"
    [pagination]="{ single: false }"
    [navigation]="{ single: false }"
    [carousel]="true"
    [autoPlay]="false"
    [cssMode]="true"
    [breakpoints]="{
      '100': {
        slidesPerView: fullView ? 2 : 2.4
      },
      '460': {
        slidesPerView: fullView ? 2.4 : 3
      },
      '550': {
        slidesPerView: fullView ? 3 : 3.6
      }
    }"
    [loop]="false"
    [freeMode]="true"
    [templateRef]="{ template: slide, context: carouselItems }"
    [scrollbar]="{ draggable: true }"
  >
  </crc-shared-swiper>
</div>
