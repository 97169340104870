import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { lastValueFrom, take, tap } from 'rxjs';

export function handleSettingRequiredError(
  control: FormControl | AbstractControl | FormGroup,
  emitEvent = true
): void {
  if (control.hasError('required')) {
    control.setErrors(
      {
        ...control.errors,
        forceRequired: true
      },
      {
        emitEvent: emitEvent
      }
    );
    (async () => {
      return await lastValueFrom(
        control.valueChanges.pipe(
          take(1),
          tap(() => control.updateValueAndValidity())
        )
      );
    })();
  }
}
export function handleRemovingRequiredError(
  control: FormControl | AbstractControl | FormGroup
): void {
  if (control?.hasError('forceRequired')) {
    delete control.errors.forceRequired;
  }
}
export function handleMarkingControlsAsTouched({ controls }: FormGroup) {
  for (const controlI in controls) {
    const control = controls[controlI];
    control.markAsTouched();
    handleSettingRequiredError(control);
  }
}
export function handleMarkingControlsAsTouchedAndDirty({
  controls
}: FormGroup) {
  for (const controlI in controls) {
    const control = controls[controlI];
    control.markAsTouched();
    control.markAsDirty();
    handleSettingRequiredError(control);
  }
}
