export const EnvironmentBase = {
  production: false,
  enableAuthTestApi: false,
  apiUrl: 'https://apim.crocobet.com',
  authApiUrl: 'https://proxylogin.crocobet.com/v3',
  authCookieName: 'X-ODDS-SESSION',
  cmsApiUrl: 'https://cms.crocobet.com',
  cookieDomain: '.crocobet.com',
  identomatUrl: 'https://customers01.crocobet.com',
  cardsApiUrl: 'https://cards.crocobet.com/api',
  sportUrl: `https://sporttest-mredesign.crocobet.com`,
  sportVirtualUrl: `https://sporttest-mredesign.crocobet.com`,
  widgetsUrl: `https://mwidget-test.crocobet.com/`,
  fastTrackApi: 'https://cms.crocobet.com/marketing/events',
  appVersion: '4.9.0'
};
