import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { LanguageFacade } from '../../translate';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap, tap, timer } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[crcSharedUppercase]'
})
export class UppercaseDirective {
  constructor(
    private readonly lang: LanguageFacade,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.lang.current$
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          return timer(0).pipe(
            tap(() => {
              if (/[ა-ჰ]/.test(this.el.nativeElement?.textContent)) {
                this.el.nativeElement.style.textTransform = 'none';
              } else {
                this.el.nativeElement.style.textTransform = 'uppercase';
              }
            })
          );
        })
      )
      .subscribe();
  }
}
