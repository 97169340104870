import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgSelectItem } from '@crc/components';
import { DropDownData } from '../entity/register.interface';
import { SimpleChanges } from '@angular/core';
import {
  handleMarkingControlsAsTouched,
  handleSettingRequiredError
} from '@crc/shared';

export function formatDateOfBirth(
  year: string,
  month: string,
  day: string
): string {
  year = '' + year;
  month = addLeadingZero(month);
  day = addLeadingZero(day);
  return `${year}-${month}-${day}`;
}

export const sortFn = (
  accessFunction: any
): ((a: number, b: number) => number) => {
  return (a, b) => {
    if (accessFunction(a) > accessFunction(b)) {
      return 1;
    }
    if (accessFunction(a) < accessFunction(b)) {
      return -1;
    }
    return 0;
  };
};

export const nonEmpty = (obj: Record<string, string>) =>
  Object.entries(obj)
    .filter(([key, value]) => key && value)
    .reduce((res, [key, value]) => ({ ...res, [key]: value }), {});

export const formatLocations = (
  obj: Record<string, string>,
  sortFn: any
): NgSelectItem[] => {
  const rawLocations = nonEmpty({ ...obj });
  return Object.entries(rawLocations)
    .map(([key, value], index) => ({ key, value, id: index }))
    .sort(sortFn);
};

export const addLeadingZero = (str: string): string => {
  return (str.length === 1 ? '0' : '') + str;
};

export const phoneCodeIsGE = (code: string): boolean => {
  return code === '995';
};

export const validateAllFormFields = (formGroup: UntypedFormGroup) => {
  handleMarkingControlsAsTouched(formGroup);
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.markAsDirty({ onlySelf: true });
      handleSettingRequiredError(control);
    } else if (control instanceof UntypedFormGroup) {
      validateAllFormFields(control);
    }
  });
};

export const citiesDropDownData = (cities: Record<string, string>) => {
  const sortCitiesFn = sortFn((a: DropDownData) => Number(a.key));
  return formatLocations(cities, sortCitiesFn);
};

export const countriesDropDownData = (countries: Record<string, string>) => {
  const sortCountriesFn = sortFn((a: DropDownData) => a.value);
  return formatLocations(countries, sortCountriesFn);
};
export function handleRegistrationValidationOnChange(
  changes: SimpleChanges,
  accountDetailsForm: UntypedFormGroup,
  personalInfoForm: UntypedFormGroup,
  verifySmsCodeControl: AbstractControl | UntypedFormControl
): void {
  if (changes?.['loginNameTaken']) {
    if (changes?.['loginNameTaken'].currentValue === false) {
      const loginControl = accountDetailsForm.get('loginName');
      loginControl.setErrors({
        ...loginControl.errors,
        tempError: 'loginNameTaken'
      });
    }
  }
  if (changes?.['mobileTaken']) {
    if (changes?.['mobileTaken'].currentValue === false) {
      const numberControl = personalInfoForm.get('phoneNumber').get('value');
      numberControl.setErrors({
        ...numberControl.errors,
        tempError: 'mobileTaken'
      });
    }
  }
  if (changes?.['emailTaken']) {
    if (changes?.['emailTaken'].currentValue === false) {
      const mailControl = personalInfoForm.get('email');
      mailControl.setErrors({
        ...mailControl.errors,
        tempError: 'emailTaken'
      });
    }
  }
  if (changes?.['passportNumberTaken']) {
    if (changes?.['passportNumberTaken'].currentValue === false) {
      const passportControl = personalInfoForm.get('documentId');
      passportControl.setErrors({
        ...passportControl.errors,
        tempError: 'passportNumberTaken'
      });
    }
  }
  if (changes?.['cdaInfo']) {
    const cdaInfo = changes['cdaInfo'].currentValue;
    if (
      cdaInfo &&
      (!cdaInfo?.isAdult || !cdaInfo?.isReal || cdaInfo?.isBlocked)
    ) {
      personalInfoForm.setErrors({
        ...personalInfoForm.errors,
        tempError: 'cdaError'
      });
    }
  }
  if (changes?.['registerFailed']?.currentValue) {
    verifySmsCodeControl.setErrors({
      ...verifySmsCodeControl,
      tempError: 'registerFailed'
    });
  }
}
