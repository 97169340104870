import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { ErrorHandler, Provider } from '@angular/core';
import { getEnvironmentConfigFromLocalStorage } from '../../../functions';
import { EnvironmentConfig } from '../../../environment';

export abstract class SentryStaticService {
  public static provideSentryEntities(): Provider[] {
    return SentryStaticService.shouldInitSentry
      ? [
          SentryStaticService.provideSentryTraceService(),
          SentryStaticService.provideSentryErrorhandler()
        ]
      : [];
  }
  private static provideSentryTraceService(): Provider {
    return {
      provide: Sentry.TraceService,
      deps: [Router]
    };
  }
  private static provideSentryErrorhandler(): Provider {
    return {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    };
  }
  private static get environment(): EnvironmentConfig {
    return getEnvironmentConfigFromLocalStorage();
  }
  private static get shouldInitSentry() {
    const env = SentryStaticService.environment;
    return env?.sentryDsn;
  }
  public static initSentry(): void {
    if (!SentryStaticService.shouldInitSentry) {
      return;
    }
    Sentry.init({
      dsn: SentryStaticService.environment.sentryDsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
      ],
      ignoreErrors: [
        /crazyegg/,
        /403 Error/,
        /401 Error/,
        /401 OK/,
        /Unexpected identifier/,
        /JSON Parse error/,
        /ILLUMINATO/,
        /422/
      ],
      allowUrls: [/https?:\/\/(www\.)?(.{0,10})?crocobet\.com/], //NOSONAR
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/mobile\.crocobet\.com/,
        /^https:\/\\dev-mfront\.crocobet\.com/
      ],
      // Performance Monitoring
      tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}
