import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  Observable,
  of,
  switchMap
} from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FilterItemView } from '@crc/components';

import { SLOTS_CATEGORY_FILTER_ITEMS } from '../constants/slot-category-filter-items';
import { SlotFilterType, SlotFilterTypeItem, SlotGame } from '../entity';
import { SlotsService } from '../services/slots.service';
import { SlotsFacade } from './slots.facade';
import { Provider } from '../../shared';
import { DEFAULT_SLOT_CATEGORY_FILTER_TYPE_ITEM } from '../constants/default-slot-category-filter';
import { SlotsFavoriteFacade } from './slots-favorite.facade';
import { SLOTS_CATEGORY_SIDEBAR_FILTER_ITEMS } from '../constants/slot-category-sidebar-filter-items';
import { SlotsHistoryFacade } from './slots-history.facade';
import { AccountFacade } from '../../../auth';
import { ActivatedRoute, Router } from '@angular/router';
import { isNonNullOrUndefined } from '../../bonus-coin';
import { ENV_CONFIG, EnvironmentConfig, reload } from '@crc/shared';
import { patePlay } from '../../slot&casino';

@Injectable({
  providedIn: 'root'
})
export class SlotsFiltersFacade {
  private readonly $activeFilter = new BehaviorSubject<SlotFilterTypeItem>(
    DEFAULT_SLOT_CATEGORY_FILTER_TYPE_ITEM
  );
  readonly activeFilter$ = this.$activeFilter.asObservable();
  readonly filteredSlots$: Observable<SlotGame[]>;
  providerLogos = [];
  $providerLogos = new BehaviorSubject<string[]>(null);
  $providerFilter = new BehaviorSubject<string>(null);
  private readonly tempVars = {
    providerMenuToggleState: false,
    categoryMenuToggleState: false
  };
  constructor(
    private readonly slotsService: SlotsService,
    private readonly slotsFacade: SlotsFacade,
    private readonly slotsFavoriteFacade: SlotsFavoriteFacade,
    private readonly slotsHistoryFacade: SlotsHistoryFacade,
    private readonly accountFacade: AccountFacade,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    @Inject(ENV_CONFIG) private readonly __env__: EnvironmentConfig
  ) {
    //this.synchronizeAppStateWithUrl();
    this.filteredSlots$ = this.activeFilter$.pipe(
      switchMap((filterItem: SlotFilterTypeItem) => {
        this.$providerFilter.next(filterItem.filter);
        if (filterItem.filterType === 'category') {
          switch (filterItem.filter) {
            case 'slot:favorites':
              return this.slotsFavoriteFacade.favorites$;
            case 'slot:history':
              return this.slotsHistoryFacade.history$.pipe(
                map((data) =>
                  this.router.url.includes('play?slot') &&
                  filterItem.filter === 'slot:history'
                    ? data.slice(1)
                    : data
                )
              );
            case 'web:popular':
            case 'web:new_games':
            case 'web:buy_bonus':
            default:
              return this.slotsFacade.getSlotsByCategory(filterItem.filter);
          }
        } else {
          if (filterItem.filter) {
            return this.slotsFacade.getSlotsByProvider(filterItem.filter);
          } else {
            return of(null);
          }
        }
      }),
      map((items) => items ?? [])
    );

    this.accountFacade.customerId$
      .pipe(
        tap((customerId) => {
          if (
            !customerId &&
            this.activeFilter.filterType === 'category' &&
            ['slot:favorites', 'slot:history'].includes(
              this.activeFilter.filter
            )
          ) {
            this.resetFilter();
          }
        })
      )
      .subscribe();
  }
  getProviderLogos(): Observable<string[]> {
    return this.$providerLogos.pipe(
      filter(isNonNullOrUndefined),
      distinctUntilChanged()
    );
  }
  get activeFilter() {
    return this.$activeFilter.value;
  }

  selectFilter(filterTypeItem: SlotFilterTypeItem) {
    if (this.smartRoutingPermittingCondition) {
      (async (): Promise<void> => {
        await this.router.navigate(['/slots'], {
          queryParams: {
            filterType: filterTypeItem.filterType,
            filter: filterTypeItem.filter
          },
          queryParamsHandling: 'merge'
        });
        if (filterTypeItem.filterType === 'provider') {
          await this.changeSlotQueryParamValue('isCategoryMenuDropped', false);
        } else {
          await this.changeSlotQueryParamValue('isProviderMenuDropped', false);
        }
      })();
    }
    this.$activeFilter.next(filterTypeItem);
  }

  resetFilter() {
    this.selectFilter(DEFAULT_SLOT_CATEGORY_FILTER_TYPE_ITEM);
  }

  getCategoryFilters(): Observable<FilterItemView[]> {
    return combineLatest([
      of(SLOTS_CATEGORY_FILTER_ITEMS),
      this.accountFacade.customerId$,
      this.slotsHistoryFacade.totalCount$,
      this.slotsFavoriteFacade.totalCount$
    ]).pipe(
      map(([categories, customerId, historyCount, favoriteCount]) => {
        const filteredCategories = customerId
          ? categories
          : categories.filter(
              (c) =>
                !['slot:favorites', 'slot:history'].includes(c.filter ?? '')
            );

        return [filteredCategories, historyCount, favoriteCount];
      }),
      switchMap(([categories, historyCount, favoriteCount]) =>
        this.slotsFacade.categoriesWithSlots$.pipe(
          map((slots) => {
            return (<FilterItemView[]>categories).map((c) => {
              return {
                ...c,
                totalCount:
                  c.filter === 'slot:favorites'
                    ? (favoriteCount as number)
                    : c.filter === 'slot:history'
                    ? (historyCount as number)
                    : slots.find((s) => s.category === c.filter)?.totalGames
              };
            });
          })
        )
      ),
      switchMap((items: FilterItemView[]) =>
        this.activeFilter$.pipe(
          map((active) => {
            return items.map((item: FilterItemView) => {
              return {
                ...item,
                active:
                  active.filterType === 'category' &&
                  item.filter === active.filter
              } as FilterItemView;
            });
          })
        )
      )
    );
  }

  getSidebarCategoryFilters(): Observable<FilterItemView[]> {
    return of(SLOTS_CATEGORY_SIDEBAR_FILTER_ITEMS).pipe(
      switchMap((items: FilterItemView[]) =>
        this.activeFilter$.pipe(
          map((active) => {
            return items.map((item: FilterItemView) => {
              return {
                ...item,
                active: item.filter === active.filter
              } as FilterItemView;
            });
          })
        )
      )
    );
  }

  getProviderFilterViewItems(addHome = false) {
    return this.slotsFacade.providers$.pipe(
      map((providers) => {
        return (providers || []).filter((provider) => {
          return ![
            provider.provider.toLowerCase(),
            provider.provider.toLowerCase()
          ].some((str) => str.startsWith('hero') && str.includes('picks'));
        });
      }),
      map((items) => {
        return items
          .filter((i) => i.enabled && i.gamesCount)
          .map((item: Provider) => {
            this.providerLogos.push(item.logo);
            return {
              name: item.name,
              filter: item.provider,
              logo: item.logo,
              isNew: item.tags?.includes('new'),
              route: 'games'
            } as FilterItemView;
          });
      }),
      tap((providers) => {
        this.$providerLogos.next(this.providerLogos);
        if (this.activeFilter.filter) {
          const activeFilterNotExists =
            providers.findIndex(
              (provider) => provider.filter === this.activeFilter.filter
            ) === -1 && this.$activeFilter.getValue().filterType !== 'category';
          if (activeFilterNotExists) {
            this.selectFilter(DEFAULT_SLOT_CATEGORY_FILTER_TYPE_ITEM);
          }
        }
      }),
      switchMap((items: FilterItemView[]) =>
        this.activeFilter$.pipe(
          map((active) => {
            return items.map((item: FilterItemView) => {
              return {
                ...item,
                active:
                  active.filterType === 'provider' &&
                  item.filter === active.filter
              } as FilterItemView;
            });
          })
        )
      )
    );
  }

  redirectMain(filterType: SlotFilterType, filter: string) {
    this.router.navigate(['/slots']).then(() => {
      this.selectFilter({
        filterType: filterType,
        filter: filter
      });
    });
  }
  public synchronizeAppStateWithUrl(): void {
    const {
      filter = null,
      filterType = null,
      isProviderMenuDropped = null,
      isCategoryMenuDropped = null
    } = this.route.snapshot.queryParams || {};
    if (filter && filterType) {
      this.$activeFilter.next({
        filter,
        filterType
      });
    } else {
      // this.resetFilter();
      this.$activeFilter.next(DEFAULT_SLOT_CATEGORY_FILTER_TYPE_ITEM);
    }
  }
  public setProviderMenuToggleState(status: boolean): void {
    Object.assign(this.tempVars, {
      providerMenuToggleState: status
    });
    if (this.smartRoutingPermittingCondition) {
      this.changeSlotQueryParamValue('isProviderMenuDropped', status)
        .then()
        .catch();
    }
  }
  public setCategoryMenuToggleState(status: boolean): void {
    Object.assign(this.tempVars, {
      categoryMenuToggleState: status
    });
    if (this.smartRoutingPermittingCondition) {
      this.changeSlotQueryParamValue('isCategoryMenuDropped', status)
        .then()
        .catch();
    }
  }
  public getProviderMenuToggleState(): boolean {
    return this.tempVars.providerMenuToggleState;
  }
  public getCategoryMenuToggleState(): boolean {
    return this.tempVars.categoryMenuToggleState;
  }
  private get smartRoutingPermittingCondition(): boolean {
    return (
      this.__env__.platform === 'desktop' &&
      this.router.url.startsWith('/slots') &&
      !this.router.url.startsWith('/slots/')
    );
  }
  private async changeSlotQueryParamValue(
    param: string,
    value: string | boolean
  ): Promise<boolean> {
    return await this.router.navigate(['/slots'], {
      queryParams: {
        [param]: value
      },
      queryParamsHandling: 'merge'
    });
  }
  public handlePlayNGoMessageEvent(data: {
    type?: string;
    eventType?: string;
  }) {
    (async (): Promise<void> => {
      if (data?.type === 'backToLobby' || data?.eventType === 'backToLobby') {
        if (this.__env__.enableAuthTestApi) {
          return void (await this.router.navigate(['']));
        }
        return void (await this.router.navigate(
          [`/slots${this.__env__.platform === 'desktop' ? '' : '/games'}`],
          {
            queryParams: {
              filter: 'playngo'
            }
          }
        ));
      }
      if (data?.type === 'reloadGame' || data?.eventType === 'reloadGame') {
        reload();
      }
    })();
  }

  public handlePatePlayMessageEvent(data: any) {
    (async (): Promise<void> => {
      if (data?.event === 'exit') {
        return void (await this.router.navigate(
          [`/slots${this.__env__.platform === 'desktop' ? '' : '/games'}`],
          {
            queryParams: {
              filter: patePlay
            }
          }
        ));
      }
      if (data?.event === 'reload') {
        reload();
      }
    })();
  }
}
