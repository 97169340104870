import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { boardGame, Game } from '@crc/facade';
import { Params, Router } from '@angular/router';
import { scrollTop } from '@crc/shared';
import {
  BehaviorSubject,
  bufferCount,
  combineLatest,
  fromEvent,
  skipWhile,
  tap,
  timer
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'crc-m-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent {
  @Input() extraContent: TemplateRef<unknown>;
  @Input() carouselItems: Game[] | boardGame[];
  @Input() routeLink: string;
  @Input() queryParams: Params;
  @Input() count: number;
  @Input() fullView: boolean;

  @Output() itemClicked = new EventEmitter<Game | boardGame>();
  @Output() moreClicked: EventEmitter<void> = new EventEmitter<void>();
  private readonly $loadData = new BehaviorSubject<HTMLVideoElement>(null);
  constructor(private router: Router) {
    combineLatest([fromEvent(window, 'focus'), this.$loadData.asObservable()])
      .pipe(
        untilDestroyed(this),
        skipWhile(([focus, videoElements]) => videoElements === null),
        bufferCount(2),
        tap((arrayOfFocusAndVideoElement) => {
          const video = arrayOfFocusAndVideoElement?.[1][1] as HTMLVideoElement;
          video.pause();
          video.currentTime = 0;
          timer(100)
            .pipe(
              tap(() => {
                video?.play()?.then((r) => r);
              })
            )
            .subscribe();
        })
      )
      .subscribe();
  }
  onMoreClick() {
    scrollTop(0, 'auto');
    this.moreClicked.emit();
  }
  addThumbnail(event: Event) {
    event.stopImmediatePropagation();
    if (this.router.url.includes('slot')) {
      (event.target as HTMLImageElement).src =
        '../assets/images/slot_not_found.webp';
    } else if (this.router.url.includes('casino')) {
      (event.target as HTMLImageElement).src =
        '../assets/images/casino_not_found.webp';
    } else {
      (event.target as HTMLImageElement).src =
        '../assets/images/casino_not_found.webp';
    }
  }
  func(event: Event) {
    this.$loadData.next(event.target as HTMLVideoElement);
  }
}
