import { Injectable } from '@angular/core';
import { Observable, switchMap, tap } from 'rxjs';
import { NavigationItem } from '@crc/shared';
import { Virtual, VirtualCat } from '../entity/virtual.interface';
import {
  SECONDARY_CATS,
  VIRTUAL,
  VIRTUAL_ITEMS
} from '../constants/virtual-cats';
import { map } from 'rxjs/operators';
import { VirtualFilterFacade } from './virtual-filter.facade';
import { FilterItemView } from '@crc/components';

@Injectable({
  providedIn: 'root'
})
export class VirtualFacade {
  constructor(private readonly virtualFilterFacade: VirtualFilterFacade) {}

  getFilterItems(): Observable<NavigationItem[]> {
    return this.filter(this.virtualFilterFacade.getFilter$(), VIRTUAL_ITEMS);
  }

  getSecondaryCats(): Observable<VirtualCat[]> {
    return this.filter(
      this.virtualFilterFacade.getCategoryFilter$(),
      SECONDARY_CATS
    );
  }

  getItems(): Observable<Virtual[]> {
    return this.virtualFilterFacade.getFilter$().pipe(
      map((filterBy) => this.filterByMainCategory(filterBy)),
      tap((data) => this.pushSecondaryCatGames(data)),
      switchMap((items) => this.filterBySecondCategory(items))
    );
  }

  filterByMainCategory(filterBy: string) {
    SECONDARY_CATS.filter((c) => (c.games.length = 0));
    return VIRTUAL.filter((v) => !filterBy || v.cat === filterBy);
  }

  pushSecondaryCatGames(data: Virtual[]) {
    SECONDARY_CATS.forEach((c) =>
      c.games.push(...data.filter((d) => d.filter === c.filter))
    );
  }

  filterBySecondCategory(items: Virtual[]) {
    return this.virtualFilterFacade
      .getCategoryFilter$()
      .pipe(
        map((filter) => items.filter((i) => !filter || i.filter === filter))
      );
  }

  filter(filter: Observable<string>, data: FilterItemView[] | VirtualCat[]) {
    return filter.pipe(
      map((category) =>
        data.map((d) => ({ ...d, active: d.filter === category }))
      )
    );
  }
}
