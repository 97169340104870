import { AbstractControl } from '@angular/forms';
import { LoginModeChangeDTO } from '../entity';
import { handleSettingRequiredError } from '@crc/shared';

export function loginModeChangeHelper(
  control: AbstractControl,
  loginModeChangeDTO: LoginModeChangeDTO
) {
  if (Object.keys(loginModeChangeDTO).length) {
    control.setValue(loginModeChangeDTO.userName ?? '');
    control.markAsTouched();
    control.markAsDirty();
    handleSettingRequiredError(control);
  }
}
