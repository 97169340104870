<div class="status">
  <div *ngIf="!hideImg" class="status__icon">
    <crc-m-status-icon-result [success]="success"></crc-m-status-icon-result>
  </div>

  <div class="status__text">
    <p>
      {{ success ? textHeader : textHeader }}
    </p>
    <p *ngIf="textBody">{{ success ? textBody : textBody }} {{ prize }}</p>
  </div>
  <div class="status__button">
    <crc-m-shared-button
      (clicked)="buttonClicked.emit()"
      [type]="success ? 'active' : 'secondary'"
    >
      {{ buttonTitle }}
    </crc-m-shared-button>
  </div>
</div>
