import {
  AfterViewInit,
  Directive,
  inject,
  Input,
  OnDestroy
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { nextTick } from '../../functions';

@Directive({
  selector: '[crcSharedClassForward]',
  standalone: true
})
export class ClassForwardDirective implements AfterViewInit, OnDestroy {
  private readonly __doc__: Document = inject(DOCUMENT);
  @Input('crcSharedClassForward')
  className: string;
  //eslint-disable-next-line
  @Input('to')
  selector: string;
  @Input() private delay: number = null;

  ngOnDestroy(): void {
    this.__doc__
      .querySelector(this.selector)
      ?.classList?.remove(this.className);
  }

  ngAfterViewInit(): void {
    if ((this.delay ?? null) !== null) {
      return void nextTick(this.addClass.bind(this), this.delay);
    }
    this.addClass();
  }
  private addClass(): void {
    this.__doc__.querySelector(this.selector)?.classList?.add(this.className);
  }
}
