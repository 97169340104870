import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  EgtJackpot,
  EgtOdometerConfig,
  egtOdometerCount,
  OdometerData
} from '../entity';

@Component({
  selector: 'crc-shared-jackpot-odometer',
  templateUrl: './jackpot-odometer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JackpotOdometerComponent {
  readonly EGT_ODOMETER_COUNT = egtOdometerCount;

  @Input() odometerConfig: EgtOdometerConfig[];
  @Input() jackpot: EgtJackpot;
  @Input() odometerData: OdometerData[];
  @Input() showPopupInfo = true;

  @Output() refresh = new EventEmitter();
}
