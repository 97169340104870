import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { COMMON_KEYBOARD_DATA, KeyboardData } from './entity/data';

@Component({
  selector: 'crc-m-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyboardComponent {
  @Input() offKey: 'off' | 'forgot';

  @Output() keyboardClick: EventEmitter<KeyboardData> =
    new EventEmitter<KeyboardData>();

  commonKeyboard = COMMON_KEYBOARD_DATA;
}
