import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EgtDigitalOdometerConfig } from '../../../egt-digital-jackpot/entity/odometer.interface';
import { JackpotInstance } from '../../../egt-digital-jackpot';

@Component({
  selector: 'crc-shared-croco-roulette-odometer-presentation',
  templateUrl: './croco-roulette-odometer-presentation.component.html',
  styleUrl: './croco-roulette-odometer-presentation.component.scss'
})
export class CrocoRouletteOdometerPresentationComponent {
  @Input() type: string;
  @Input() odometerData: any;
  @Input() odometerCount: number;
  @Input() odometers: EgtDigitalOdometerConfig[];
  @Input() jackpot: JackpotInstance[];
  @Input() disableLogo: boolean;
  @Input() showPopupInfo: boolean;
  @Input() vertical: boolean;
  @Input() isMainPage: boolean;

  @Output() refreshOdometer: EventEmitter<string> = new EventEmitter();

  letters = 'GEL'.split('');

  isOverlayOpen: boolean[] = [];

  emitRefreshOdometer() {
    this.refreshOdometer.emit();
  }
}
