import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseUrl, HttpResponseCode, HttpService } from '@crc/shared';
import {
  ResetPassword,
  ResetPasswordVerify
} from '../entity/reset-password.interface';
import { ApiResponse } from '../../../../shared';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ResetPasswordRetryAfterHeaderKey } from '../';

@Injectable({ providedIn: 'root' })
export class ResetPasswordService {
  constructor(private readonly apiService: HttpService) {}

  resetPassword(body: ResetPassword): Observable<{
    success: boolean;
    retryAfter?: number;
  }> {
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = '/customers/account/reset-password';
    return this.apiService
      .rawPost$<ApiResponse, ResetPassword>({ path, body, baseUrl })
      .pipe(
        catchError((err: HttpErrorResponse) => of(err)),
        map((response: HttpResponse<ApiResponse> | HttpErrorResponse) => {
          const retryAfterConfig =
            this.getRetryAfterConfigFromResponse(response);
          if (response instanceof HttpResponse) {
            const { code, data } = response.body as ApiResponse;
            return {
              success: !!(data && code === HttpResponseCode.OK),
              ...retryAfterConfig
            };
          }
          return {
            success: false,
            ...retryAfterConfig
          };
        })
      );
  }

  resetPasswordVerify(body: ResetPasswordVerify): Observable<ApiResponse> {
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = '/customers/account/reset-password-verify';
    return this.apiService.post$<ApiResponse, ResetPasswordVerify>({
      baseUrl,
      path,
      body
    });
  }
  private getRetryAfterConfigFromResponse(
    response: HttpResponse<ApiResponse> | HttpErrorResponse
  ) {
    const retryAfterCountDown = Number(
      response.headers.get(ResetPasswordRetryAfterHeaderKey)
    );
    return retryAfterCountDown
      ? {
          retryAfter: retryAfterCountDown
        }
      : {};
  }
}
