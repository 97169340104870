<form [formGroup]="form">
  <div class="info">
    <ng-container>
      <crc-m-shared-input
        [placeholder]="'firstname' | translate"
        [errorsTexts]="[
          { type: 'required', text: 'fill_required_fields' | translate },
          {
            type: 'minlength',
            text: 'enter_minimum_characters_count' | translate : { count: 2 }
          },
          {
            type: 'maxlength',
            text: 'enter_maximum_characters_count' | translate : { count: 30 }
          },
          {
            type: 'latin',
            text: 'use_only_latin_letters' | translate
          },
          {
            type: 'firstLetter',
            text: 'wrong_format' | translate
          }
        ]"
        formControlName="firstName"
        name="firstname"
        data-cy="firstname"
        autoComplete="false"
        type="text"
        (blurred)="controlBlurred($event, 'firstName')"
      >
      </crc-m-shared-input>
      <crc-m-shared-input
        [placeholder]="'lastname' | translate"
        [errorsTexts]="[
          { type: 'required', text: 'fill_required_fields' | translate },
          {
            type: 'minlength',
            text: 'enter_minimum_characters_count' | translate : { count: 2 }
          },
          {
            type: 'maxlength',
            text: 'enter_maximum_characters_count' | translate : { count: 30 }
          },
          {
            type: 'latin',
            text: 'use_only_latin_letters' | translate
          },
          {
            type: 'firstLetter',
            text: 'wrong_format' | translate
          }
        ]"
        formControlName="lastName"
        name="lastname"
        data-cy="lastname"
        autoComplete="false"
        type="text"
        (blurred)="controlBlurred($event, 'lastName')"
      ></crc-m-shared-input>
      <div class="info__address">
        <div class="info__address__country">
          <crc-m-select
            class="small-resolution"
            [class.error]="controlHasErrors('countryCode')"
            [items]="countries"
            [selected]="countries?.[0]?.key"
            [placeholder]="'country' | translate"
            [control]="form.get('countryCode')"
            [visibleSearch]="true"
            bindValue="key"
            bindLabel="value"
            data-cy="country-code"
          ></crc-m-select>
        </div>

        <div class="info__address__city">
          <crc-m-select
            (change)="changeCdaError()"
            class="small-resolution"
            [class.error]="controlHasErrors('nationality')"
            [items]="countries"
            [placeholder]="'nationality' | translate"
            [control]="form.get('nationality')"
            bindValue="key"
            bindLabel="value"
            data-cy="nationality"
            [forceError]="form.get('nationality')?.errors?.cda"
          ></crc-m-select>
        </div>
      </div>

      <!--      <crc-m-shared-input-->
      <!--        *ngIf="selectedCountry !== 'GE'"-->
      <!--        [placeholder]="'city' | translate"-->
      <!--        [class.error]="controlHasErrors('city')"-->
      <!--        [errorsTexts]="[-->
      <!--          { type: 'required', text: 'fill_required_fields' | translate }-->
      <!--        ]"-->
      <!--        formControlName="city"-->
      <!--        name="city"-->
      <!--        data-cy="city"-->
      <!--        autoComplete="false"-->
      <!--        type="text"-->
      <!--        (blurred)="controlBlurred($event,'city')"-->
      <!--      ></crc-m-shared-input>-->

      <crc-m-shared-input
        #personalNumberInnerContent="public_content"
        (change)="changeCdaError()"
        [placeholder]="'personal_number' | translate"
        [errorsTexts]="[
          { type: 'required', text: 'fill_required_fields' | translate },
          {
            type: 'pattern',
            text: 'register.personal_number_invalid' | translate
          },
          {
            type: 'used',
            text: 'is_already_in_use' | translate
          }
        ]"
        formControlName="documentId"
        name="documentId"
        autoComplete="false"
        data-cy="document-id"
        [class.GE]="selectedCountry === 'GE'"
        [inputMode]="selectedCountry === 'GE' ? 'numeric' : 'text'"
        [crcSharedCapitalizeInput]="form.controls.documentId"
        [restrictiveCondition]="{
          status: form.get('countryCode').value !== 'GE',
          inputRef: personalNumberInnerContent.inputRef
        }"
        (blurred)="controlBlurred($event, 'documentId')"
      ></crc-m-shared-input>

      <div class="info__birthday">
        <div class="info__birthday__days">
          <crc-m-select
            (change)="changeCdaError()"
            [items]="days"
            [control]="form.get('day')"
            [placeholder]="'dates.day' | translate"
            [hideFirst]="true"
            [forceError]="
              ((isYearErrored | async) === 'INVALID' &&
                (allDatesAreSelected$ | async)) ||
              (this.form.get('day').touched && !this.form.get('day').value)
            "
            bindValue="key"
            bindLabel="value"
            data-cy="day"
          ></crc-m-select>
        </div>
        <div class="info__birthday__month">
          <crc-m-select
            (change)="changeCdaError()"
            [items]="months"
            [control]="form.get('month')"
            [placeholder]="'dates.month' | translate"
            [hideFirst]="true"
            [forceError]="
              ((isYearErrored | async) === 'INVALID' &&
                (allDatesAreSelected$ | async)) ||
              (this.form.get('month').touched && !this.form.get('month').value)
            "
            bindValue="key"
            bindLabel="value"
            data-cy="month"
          ></crc-m-select>
        </div>
        <div class="info__birthday__years">
          <crc-m-select
            (change)="changeCdaError()"
            [items]="years"
            [control]="form.get('year')"
            [placeholder]="'dates.year' | translate"
            [hideFirst]="true"
            [forceError]="
              ((isYearErrored | async) === 'INVALID' &&
                (allDatesAreSelected$ | async)) ||
              (this.form.get('year').touched && !this.form.get('year').value)
            "
            bindValue="key"
            bindLabel="value"
          ></crc-m-select>
        </div>
      </div>
      <div
        class="info__birthday__error-message"
        *ngIf="
          (isYearErrored | async) === 'INVALID' &&
          (allDatesAreSelected$ | async)
        "
      >
        {{ 'register.not_adult' | translate }}
      </div>
      <ng-container *ngIf="cdaShowError !== 'SB_RESPONSE_EXCEPTION'">
        <div class="cda" *ngIf="cdaShowError">
          <div class="cda__img">
            <crc-m-status-icon-result
              [success]="false"
            ></crc-m-status-icon-result>
          </div>
          <p class="cda__text">
            {{ cdaShowError | translate }}
          </p>
        </div>
      </ng-container>

      <crc-m-shared-button
        [disable]="!form.valid || !!cdaShowError || !!cdaNationalityShowError"
        [type]="form.valid && !cdaShowError ? 'active' : 'secondary'"
      >
        <div
          class="button-guts"
          (click)="
            !form.valid || cdaShowError || cdaNationalityShowError
              ? markControlsAsTouched()
              : null
          "
        >
          {{ 'continue' | translate }}
        </div>
      </crc-m-shared-button>
    </ng-container>
  </div>
</form>
