import { Virtual } from '../entity/virtual.interface';

export function generateVirtualCategory(
  title: string,
  img: string,
  link: string,
  cat: string,
  filter: string
) {
  return { title, img, link, cat, filter };
}

export function generateVirtualCategoryMenu(
  filter: string,
  name: string,
  icon?: string,
  games?: Virtual[],
  active?: boolean
) {
  return { filter, name, icon, games, active };
}
