<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  *ngIf="{
    controlValueChanges: controlValueChanges$ | async
  } as params"
>
  <div class="control">
    <crc-m-shared-input
      [class.error]="
        (form.touched || submitted) &&
        form.invalid &&
        (form.get('mobile')?.value ||
          form.get('mobile')?.hasError('forceRequired'))
      "
      [placeholder]="'phone_number' | translate"
      [errorsTexts]="[{ type: 'pattern', text: 'wrong_format' | translate }]"
      formControlName="mobile"
      mask="000 00 00 00"
      [inputMode]="'decimal'"
    ></crc-m-shared-input>
  </div>
  <crc-m-otp-rate-limit-alert
    class="form-alert"
    *ngIf="form.getError('rateLimit') as rateLimit"
    [secondsLeft]="rateLimit"
    (timeUp)="form.updateValueAndValidity()"
  />

  <crc-m-shared-button [type]="form.valid ? 'active' : 'secondary'">
    {{ 'password_recovery' | translate }}
  </crc-m-shared-button>
</form>
