export const COMMON_KEYBOARD_DATA = [
  {
    display: '1',
    value: 1
  },
  {
    display: '2',
    value: 2
  },
  {
    display: '3',
    value: 3
  },
  {
    display: '4',
    value: 4
  },
  {
    display: '5',
    value: 5
  },
  {
    display: '6',
    value: 6
  },
  {
    display: '7',
    value: 7
  },
  {
    display: '8',
    value: 8
  },
  {
    display: '9',
    value: 9
  }
];

export type KeyboardData =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '0'
  | 'off'
  | 'forgot'
  | 'backspace';

export type KeyboardState = 'active' | 'use';
