import { Injectable } from '@angular/core';

import { BaseUrl, HttpService } from '@crc/shared';

import { LiveCallerUser } from '../entity';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private readonly apiService: HttpService) {}

  initLiveCaller(body: LiveCallerUser) {
    const path = '/livecaller/token';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.post$({ path, baseUrl, body });
  }
}
