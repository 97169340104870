<crc-shared-croco-roulette-odometer-presentation
  [type]="type"
  [odometerData]="odometerData"
  [odometerCount]="CROCO_ROULETTE_ODOMETER_COUNT"
  [odometers]="odometerConfig"
  [jackpot]="jackpot"
  [vertical]="vertical"
  [disableLogo]="disableLogo"
  (refreshOdometer)="refresh.emit()"
>
</crc-shared-croco-roulette-odometer-presentation>
