import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  delayWhen,
  from,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  timer
} from 'rxjs';

import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { CustomValidators, EMAIL_REGEXP, NgSelectItem } from '@crc/components';
import { DateService, handleSettingRequiredError } from '@crc/shared';

import { ChangeEmailService } from '../services/change-email.service';
import {
  changeEmailSteps,
  ICustomerEmailSmsResInterface,
  ICustomerEmailValidityVerifyResInterface
} from '../interfaces/request-resp.interface';

@Injectable({
  providedIn: 'root'
})
export class ChangeEmailFacade {
  private readonly $changeEmailSteps: BehaviorSubject<changeEmailSteps> =
    new BehaviorSubject('form');
  private readonly $timerResetState: Subject<void> = new Subject();

  private readonly $isLoading: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private readonly changeNumberService: ChangeEmailService,
    private readonly dateService: DateService
  ) {}

  public readonly months$: Observable<NgSelectItem[]> =
    this.dateService.getAvailableMonths$();

  public sendSMS$(): Observable<ICustomerEmailSmsResInterface | null> {
    return this.changeNumberService.sendSMSToNumber$();
  }

  public submitForm$(formValue: {
    personalNumber: string;
    email: string;
    year: string;
    month: string;
    smsCode: string;
  }) {
    const { personalNumber, email, month, year, smsCode } = formValue;
    return this.changeNumberService.editEmail$(
      smsCode,
      month,
      personalNumber,
      year,
      email
    );
  }

  public generateChangeEmailForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      personalNumber: new UntypedFormControl(null, [
        Validators.required,
        CustomValidators.patternValidator(/^[a-zA-Z0-9 -]+$/, {
          latin: true
        })
      ]),
      year: new UntypedFormControl('', {
        updateOn: 'change'
      }),
      month: new UntypedFormControl('', {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      email: new UntypedFormControl('', [
        Validators.required,

        CustomValidators.patternValidator(EMAIL_REGEXP, {
          pattern: true
        })
      ])
    });
  }

  public generateSmsCodeForm() {
    return new UntypedFormGroup({
      smsCode: new UntypedFormControl(null, {
        validators: [Validators.required],
        updateOn: 'change'
      })
    });
  }

  public initializeYears(): NgSelectItem[] {
    const { minValidYear, validYear } = this.dateService.getVariables();
    return this.dateService.getAvailableYears(validYear, minValidYear);
  }
  public triggerTimerReset(): void {
    this.$timerResetState.next();
  }
  public getTimerResetActions$(): Observable<boolean> {
    return this.$timerResetState.asObservable().pipe(
      startWith(null),
      switchMap(() => from([true, false])),
      delayWhen((res) => (res ? of(null) : timer(0)))
    );
  }
  public handleSubmitErrorResponse(
    res: ICustomerEmailValidityVerifyResInterface | null,
    cnForm: UntypedFormGroup,
    smsCodeForm: UntypedFormGroup
  ): void {
    const smsCodeControl = smsCodeForm.get('smsCode');
    const yearControl = cnForm.get('year');
    const emailControl = cnForm.get('email');
    const monthControl = cnForm.get('month');
    const personalNumberControl = cnForm.get('personalNumber');

    const setFormError = (
      control: AbstractControl | null,
      generatedError: { manualError: string }
    ) => {
      control?.setErrors({
        ...control.errors,
        ...generatedError
      });
    };

    if (!res?.error) return;

    const error = res?.error === 'TOO_MANY_REQUESTS' ? res?.error : res?.payload?.errorCode;
    const generatedError = {
      manualError: `ce.${error?.toLowerCase() ?? 'default_error'}`
    };

    cnForm.setErrors({
      ...cnForm.errors,
      ...generatedError
    });

    switch (error) {
      case 'VERIFICATION_CODE_EXPIRED':
      case 'INVALID_VERIFICATION_CODE': {
        setFormError(smsCodeControl, generatedError);
        setFormError(smsCodeForm, generatedError);
        return;
      }

      case 'CUSTOMER_RESTRICTED': {
        return;
      }

      case 'INVALID_PERSONAL_DATA': {
        const fieldNames = res.payload?.fieldNames;
        if (fieldNames?.length) {
          setFormError(personalNumberControl, generatedError);
          setFormError(yearControl, generatedError);
          setFormError(monthControl, generatedError);
        }
        this.setChangeEmailStep('form');
        return;
      }

      case 'TOO_MANY_REQUESTS': {
        const rateLimitError = {
          manualError: `cn.${error?.toLowerCase() ?? 'default_error'}`
        };
        setFormError(smsCodeControl, rateLimitError);
        setFormError(smsCodeForm, rateLimitError);
        return;
      }

      case 'REMOTE_ERROR': {
        this.setChangeEmailStep('error');
        return;
      }

      case 'EMAIL_TAKEN': {
        setFormError(emailControl, generatedError);
        setFormError(cnForm, generatedError);
        this.$changeEmailSteps.next('form');
        return;
      }

      default: {
        this.setChangeEmailStep('error');
      }
    }
  }

  public handleSmsErrorRes(
    res: ICustomerEmailSmsResInterface | null,
    cnForm: UntypedFormGroup
  ): void {
    const smsCodeControl = cnForm.get('smsCode');
    if (res?.error) {
      setTimeout(() => {
        this.triggerTimerReset();
      }, 300);
      const error = res?.error === 'TOO_MANY_REQUESTS' ? res?.error : res?.payload?.errorCode;
      const generatedError = {
        manualError: `cn.${error?.toLowerCase() ?? 'default_error'}`
      };
      cnForm.setErrors({
        ...cnForm.errors,
        ...generatedError
      });
      if (error === 'TOO_MANY_REQUESTS') {
        smsCodeControl.setErrors({
          ...smsCodeControl.errors,
          ...generatedError
        });
        smsCodeControl.markAsTouched();
        smsCodeControl.markAsDirty();
        handleSettingRequiredError(smsCodeControl);
      }
    }
  }
  public setChangeEmailStep(step: changeEmailSteps): void {
    this.$changeEmailSteps.next(step);
  }
  public selectChangeEmailStep$(): Observable<changeEmailSteps> {
    return this.$changeEmailSteps.asObservable();
  }

  public getLoadingState$(): Observable<boolean> {
    return this.$isLoading.asObservable();
  }
  public setLoadingState(state: boolean): void {
    this.$isLoading.next(state);
  }
}
