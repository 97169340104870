import { createAction, props } from '@ngrx/store';

import { AuthResponse, SignInPayload } from '../../entity';

const checkSms = createAction(
  '[CoreSession] Check Sms',
  props<{ payload: SignInPayload }>()
);
createAction('[CoreSession] Check Sms Error');
const sendSmsCode = createAction(
  '[CoreSession] Send Sms',
  props<{ payload: number }>()
);
const sendSmsCodeSuccess = createAction('[CoreSession] Send Sms Success');
const sendSmsCodeError = createAction('[CoreSession] Send Sms Error');
const verifySmsCode = createAction(
  '[CoreSession] Verify Sms Code',
  props<{ payload: SignInPayload }>()
);
const verifySmsCodeError = createAction('[CoreSession] Verify Sms Code Error');

const signInPostCheck = createAction(
  '[CoreSession] Sign In Post Check',
  props<{ payload: AuthResponse }>()
);
const signInError = createAction(
  '[CoreSession] Sign In Error',
  props<{ payload: AuthResponse }>()
);
const signInRestricted = createAction('[CoreSession] Sign in Restricted');
const signInSuccess = createAction('[CoreSession] Sign In Success');
const signOut = createAction('[CoreSession] Sign Out');
export const signOutSuccess = createAction('[CoreSession] Sign Out Success');

export const authActions = {
  signInPostCheck,
  signInSuccess,
  signInError,
  signInRestricted,
  signOut,
  signOutSuccess,
  checkSms,
  verifySmsCode,
  verifySmsCodeError,
  sendSmsCode,
  sendSmsCodeSuccess,
  sendSmsCodeError
};
