import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[crcSharedConvertCommaToDot]'
})
export class ConvertCommaToDotDirective {
  @HostListener('input', ['$event'])
  convertCommaToDot($event: any) {
    const item = $event.target;
    const value = item.value;
    const pos = item.selectionStart;

    if (value.indexOf(',') === -1) {
      return;
    }

    item.value = value.replace(',', '.');
    item.selectionStart = item.selectionEnd = pos;
  }
}
