import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, Subject } from 'rxjs';

import { CasinoGame } from '../entity';
import { PlayInterface, SlotCasinoGameTypeEnum } from '../../slot&casino';
import { CasinoHistoryFacade } from './casino-history.facade';
import { EnvironmentService } from '@crc/shared';
import { DepositFacade } from '../../deposit';

@Injectable({ providedIn: 'root' })
export class CasinoPlayFacade {
  private readonly $play = new Subject<PlayInterface>();
  play$ = this.$play.asObservable().pipe(filter((gameId) => !!gameId));

  constructor(
    private readonly router: Router,
    private readonly casinoHistoryFacade: CasinoHistoryFacade,
    private readonly environmentService: EnvironmentService,
    private readonly depositFacade: DepositFacade
  ) {
  }

  play(casinoGame: CasinoGame) {
    if (this.router.url.includes('slot-casino')) {
      this.$play.next({
        gameName: casinoGame.name,
        gameType: SlotCasinoGameTypeEnum.CASINO,
        gameId: casinoGame.game_id,
        providerName: casinoGame.provider
      });
      this.casinoHistoryFacade.addCasinoToHistory(casinoGame);
    } else {
      const route = this.getRoute();
      this.router
        .navigate([route], {
          queryParams: {
            casino: casinoGame.name,
            provider: casinoGame.provider,
            // eslint-disable-next-line camelcase
            game_id: casinoGame.game_id,
            filter: casinoGame.group_name
          }
        })
        .then((_) => this.casinoHistoryFacade.addCasinoToHistory(casinoGame));
    }

    this.depositFacade.setPaymentStateAfterClick(true);
  }

  private getRoute() {
    return this.environmentService.config.platform === 'mobile'
      ? 'launcher'
      : '/casino/play';
  }
}
