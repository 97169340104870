import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  EgtDigitalOdometerData
} from '../../../egt-digital-jackpot/entity/odometer.interface';
import { JackpotInstance } from '../../../egt-digital-jackpot';
import { crocoRouletteOdometerCount } from '../../../jackpot';
import { odometerConfig } from '../../entity';

@Component({
  selector: 'crc-shared-croco-roulette-odometer-container',
  templateUrl: './croco-roulette-odometer-container.component.html',
})
export class CrocoRouletteOdometerContainerComponent {
  readonly CROCO_ROULETTE_ODOMETER_COUNT = crocoRouletteOdometerCount;

  @Input() disableLogo: boolean;
  @Input() vertical: boolean;
  @Input() type: string;
  @Input() odometerConfig: odometerConfig[];
  @Input() jackpot: JackpotInstance[];
  @Input() odometerData: EgtDigitalOdometerData[];

  @Output() refresh = new EventEmitter();
}
