import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'crc-m-status-icon-result',
  templateUrl: './status-icon-result.component.html',
  styleUrls: ['./status-icon-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusIconResultComponent {
  @Input() success: boolean;
}
