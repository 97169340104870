import { getWindow } from './window.functions';

export function nextTick(
  cb: (...args: unknown[]) => void,
  delay: number = 0,
  ...args: unknown[]
): void {
  return void getWindow().setTimeout(cb, delay, ...args);
}
export function getObjFromArrayWithMinPropertyValue<T>(
  arr: T[],
  propertyName: keyof T
): T {
  let item: T = arr[0];
  for (const obj of arr) {
    if (obj[propertyName] < item[propertyName]) {
      item = obj;
    }
  }
  return item;
}
