import { Injectable } from '@angular/core';
import { PromotionService } from '../services/promotion.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PromotionsFacade {
  isPromoActive(promotionId: string): Observable<boolean> {
    return this.promotionsService.isPromotionActive(promotionId).pipe(
      map((promotion) => {
        return (
          promotion &&
          promotion.isActive &&
          Number(new Date(promotion.startDate)) < Number(new Date()) &&
          Number(new Date(promotion.endDate)) > Number(new Date())
        );
      })
    );
  }

  constructor(private promotionsService: PromotionService) {}
}
