import { JackpotOdometerData, LevelStats } from '@crc/components';

const digitalOffsetLevels = {
  LEVEL_ONE_OFFSET: 206,
  LEVEL_TWO_OFFSET: 180,
  LEVEL_THREE_OFFSET: 170,
  LEVEL_FOUR_OFFSET: 140
};

export function processEgtDigitalJackpot(
  jackpot: LevelStats[],
  dataRefreshRate: number,
  name: string
): JackpotOdometerData[] {
  let levelId1: number;
  let levelId2: number;
  let levelId3: number;
  let levelId4: number;

  jackpot.map((data) => {
    if (data.levelId === 1) {
      levelId1 = data.currentValue.map((data) => data.value)[0];
    } else if (data.levelId === 2) {
      levelId2 = data.currentValue.map((data) => data.value)[0];
    } else if (data.levelId === 3) {
      levelId3 = data.currentValue.map((data) => data.value)[0];
    } else if (data.levelId === 4) {
      levelId4 = data.currentValue.map((data) => data.value)[0];
    }
  });

  const startNumberLevelI: number =
    levelId1 - digitalOffsetLevels.LEVEL_ONE_OFFSET;
  const endNumberLevelI: number = levelId1;
  const digitCountLevelI = 10;

  const odometerDataLevelI: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelI,
    startNumber: startNumberLevelI,
    endNumber: endNumberLevelI
  };

  const startNumberLevelII: number =
    levelId2 - digitalOffsetLevels.LEVEL_TWO_OFFSET;
  const endNumberLevelII: number = levelId2;
  const digitCountLevelII = 10;

  const odometerDataLevelII: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelII,
    startNumber: startNumberLevelII,
    endNumber: endNumberLevelII
  };

  const startNumberLevelIII: number =
    name === 'Bell Link'
      ? 50000
      : name === 'High Cash'
      ? 35000
      : levelId3 - digitalOffsetLevels.LEVEL_THREE_OFFSET;
  const endNumberLevelIII: number = levelId3;
  const digitCountLevelIII = 10;

  const odometerDataLevelIII: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelIII,
    startNumber: startNumberLevelIII,
    endNumber: endNumberLevelIII
  };

  const startNumberLevelIV: number =
    name === 'Bell Link'
      ? 10000
      : name === 'High Cash'
      ? 18000
      : levelId4 - digitalOffsetLevels.LEVEL_FOUR_OFFSET;
  const endNumberLevelIV: number = levelId4;
  const digitCountLevelIIII = 10;

  const odometerDataLevelIV: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelIIII,
    startNumber: startNumberLevelIV,
    endNumber: endNumberLevelIV
  };

  const newOdometerData: Array<JackpotOdometerData> = [];
  newOdometerData.push(odometerDataLevelI);
  newOdometerData.push(odometerDataLevelII);
  newOdometerData.push(odometerDataLevelIII);
  newOdometerData.push(odometerDataLevelIV);

  return newOdometerData;
}
