/* TODO has extra knowledge */
const CROCO_SUB_DOMAINS_WITH_NO_NEED_OF_SESSION_ID = [
  'ufoleaderboard',
  'spacetaxi',
  'suit-runner'
] as const;
export const isCrcApi = (url: string): boolean => {
  const [subdomain, domain] = url.split('//')[1].split('.');
  return (
    (subdomain.includes('api') || subdomain.includes('test')) &&
    domain === 'crocobet' &&
    url.includes('rest')
  );
};

export const isAnyCrocobetApi = (url: string): boolean => {
  try {
    const { hostname } = new URL(url);
    return (
      hostname.includes('.crocobet.com') &&
      !CROCO_SUB_DOMAINS_WITH_NO_NEED_OF_SESSION_ID.some((domain) =>
        hostname.startsWith(domain)
      )
    );
  } catch (e) {
    return false;
  }
};

export const isCmsApi = (url: string): boolean => {
  const [subdomain, domain] = url.split('//')[1].split('.');
  return (
    (subdomain.includes('cms') &&
      domain === 'crocobet' &&
      (url.includes('campaigns') ||
        url.includes('user') ||
        url.includes('marketing') ||
        url.includes('notifications'))) ||
    url.includes('payments')
  );
};

export const isCrcAuthApi = (url: string): boolean => {
  const [subdomain, domain] = url.split('//')[1].split('.');

  return subdomain.includes('proxylogin') && domain === 'crocobet';
};

export const isCardsApi = (url: string): boolean => {
  const [subdomain, domain] = url.split('//')[1].split('.');

  return subdomain.includes('cards') && domain === 'crocobet';
};

export const isIdentomatApi = (url: string): boolean => {
  const [subdomain, domain] = url.split('//')[1].split('.');

  return subdomain.includes('customers01') && domain === 'crocobet';
};
