<crc-m-shared-popup
  *ngIf="showResetPassword$ | async"
  [tabTitles]="['password_recovery']"
  [activeTab]="'password_recovery'"
  [close]="closeResetPasswordPopup"
  (closedSelf)="onResetPasswordClose()"
>
  <crc-m-reset-password-container
    (closePopup)="closeResetPasswordPopup = true"
  ></crc-m-reset-password-container>
</crc-m-shared-popup>

<crc-m-shared-popup
  *ngIf="showLoginPopup$ | async"
  [tabTitles]="['login', 'register']"
  [activeTab]="activeTab"
  (activeTabChange)="activeTab = $event; resetCustomer()"
  [close]="closeLoginRegisterPopup$ | async"
  (closedSelf)="close()"
  class="login-landscape"
>
  <ng-container *ngIf="activeTab === 'login'">
    <crc-m-login-container></crc-m-login-container>
  </ng-container>
  <ng-container *ngIf="activeTab === 'register'">
    <crc-m-register-container></crc-m-register-container>
  </ng-container>
</crc-m-shared-popup>
