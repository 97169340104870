import { inject, Injectable } from '@angular/core';
import {
  UserRestrictions,
  UserRestrictionsInterface,
  UserRestrictionsType
} from '../interface/user-restrictions-interfaces';
import {
  BehaviorSubject,
  fromEvent,
  lastValueFrom,
  Observable,
  tap
} from 'rxjs';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UserRestrictionsService } from '../services/user-restrictions.service';
import { getWindow } from '@crc/shared';

@Injectable({
  providedIn: 'root'
})
export class UserRestrictionsFacade {
  //======= deps
  private readonly __doc__: Document = inject(DOCUMENT);
  private readonly router: Router = inject(Router);
  private readonly userRestrictionsService: UserRestrictionsService = inject(
    UserRestrictionsService
  );
  //========================
  private readonly $latestUserRestrictionsDTO: BehaviorSubject<
    UserRestrictionsType[]
  > = new BehaviorSubject([]);

  public getLatestUserRestrictionsDTO$(): Observable<UserRestrictionsType[]> {
    return this.$latestUserRestrictionsDTO.asObservable();
  }

  public setLatestUserRestrictionsDTO(types: UserRestrictionsType[]): void {
    this.handleRestrictionChange(types);
    this.$latestUserRestrictionsDTO.next(types);
  }

  public getLatestUserRestrictionsDTO(): UserRestrictionsType[] {
    return this.$latestUserRestrictionsDTO.getValue();
  }

  private handleRestrictionChange(types: UserRestrictionsType[]): void {
    if (
      types.includes(UserRestrictionsInterface.UPDATE_PASSWORD_REQUIRED) ||
      types.includes(UserRestrictionsInterface.PROMPT_ABOUT_SMS_OFF) ||
      types.includes(UserRestrictionsInterface.PROMPT_ABOUT_SMS_ON) ||
      types.includes(UserRestrictionsInterface.PROMPT_ABOUT_SMS_ON_V2)
    ) {
      this.navigate('mandatory-action-zone');
    } else {
      if (this.router.url.includes('mandatory-action-zone')) {
        this.navigate('/');
      }
    }
  }

  private navigate(path: string): void {
    return void (async (): Promise<void> =>
      void (await this.router.navigateByUrl(path)))();
  }

  //===============================================
  public preventClicks$(elRef1: HTMLElement) {
    return fromEvent<MouseEvent>(this.__doc__, 'click', {
      capture: true
    }).pipe(
      tap((event) => {
        const elRef: HTMLElement = <HTMLElement>event.target;
        if (elRef) {
          const ngHostName = [
            ...elRef
              .getAttributeNames()
              .filter((att) => att.startsWith('_ngcontent')),
            ...elRef
              .getAttributeNames()
              .filter((att) => att.startsWith('_nghost'))
          ][0];
          const el =
            elRef.className.includes('livecaller') ||
            elRef1.querySelector(`[${ngHostName}]`);
          if (!el) {
            event?.stopImmediatePropagation?.();
            event?.preventDefault?.();
          }
        }
      })
    );
  }

  public handleStoreSmsOffUserResponse(agreed: boolean, text: string): void {
    (async () => {
      const userResponse = agreed ? 'ACCEPT' : 'REJECT';
      const serverResponse = await lastValueFrom(
        this.userRestrictionsService.storeSmsOffUserResponse$(
          userResponse,
          text
        )
      );
      return serverResponse;
    })();
  }

  public handleStoreSmsOnUserResponse(agreed: boolean, text: string): void {
    (async () => {
      const userResponse = agreed ? 'ACCEPT' : 'REJECT';
      return await lastValueFrom(
        this.userRestrictionsService.storeSmsOnUserResponse$(userResponse, text)
      );
    })();
  }

  public handleStoreSmsOnUserFromVerification(
    text: string,
    agreed: boolean
  ): void {
    (async () => {
      return await lastValueFrom(
        this.userRestrictionsService.storeSmsOnUserResponse$(
          'WAITING_FOR_VERIFICATION',
          text,
          agreed,
          'verification'
        )
      );
    })();
  }
  public handleStoreSmsOnUserFromProfile(text: string, agreed: boolean): void {
    (async () => {
      const userResponse = agreed ? 'ACCEPT' : 'REJECT';
      return await lastValueFrom(
        this.userRestrictionsService.storeSmsOnUserResponse$(
          userResponse,
          text,
          agreed,
          'profile'
        )
      );
    })();
  }
  public handleGetStoreSmsOnUserResponse$(): Observable<UserRestrictions> {
    return this.userRestrictionsService.getSmsOnUserResponse$();
  }

  public handleStoreSmsOnV2UserResponse(agreed: boolean, text: string): void {
    (async () => {
      const userResponse = agreed ? 'ACCEPT' : 'REJECT';
      return await lastValueFrom(
        this.userRestrictionsService.storeSmsOnV2UserResponse$(
          userResponse,
          text
        )
      );
    })();
  }

  public getClickPreventListenerExceptForChildrenUnderCertainNode$(node: Node) {
    return fromEvent<MouseEvent>(getWindow(), 'click', {
      capture: true
    }).pipe(
      tap((event: MouseEvent) => {
        const targetElement = event.target;
        if (
          node.contains(targetElement as Node) ||
          (
            Array.from(this.__doc__.body.children).find((element) => {
              return Array.from(element.classList).some((className) => {
                const lowerClassName = className.toLowerCase();
                return (
                  lowerClassName?.includes('cdk') &&
                  lowerClassName?.includes('overlay')
                );
              });
            }) as Node
          )?.contains(targetElement as Node)
        ) {
          return;
        }
        event.stopImmediatePropagation();
        event.preventDefault();
      })
    );
  }
}
