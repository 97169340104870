import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  mergeMap,
  Observable,
  of,
  pairwise,
  takeWhile,
  tap
} from 'rxjs';
import { LegalPopupService } from '../services/legal-popup.service';
import {
  LegalPopupConsentDefinitionsObject,
  LegalPopupConsentResponseInterface,
  LegalPopupConsentResponseStatusLocalScopeState
} from '../entities/legal-popup.interface';

import { AuthFacade } from '../../../auth';
import { StorageService } from '@crc/shared';
import { map } from 'rxjs/operators';
import { legalConsentsConstants } from '../entities/config';

@Injectable({
  providedIn: 'root'
})
export class LegalPopupFacade extends StorageService<string> {
  private readonly storageKey: string =
    legalConsentsConstants.get('STORAGE_KEY');
  private readonly maxNeglectCount: number = Number(
    legalConsentsConstants.get('USER_NEGLECTION_MAX_COUNT')
  );
  private readonly legalAlertState$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private isSavedInBackEndStatus = false;
  public getLegalAlertState$(): Observable<boolean> {
    return this.legalAlertState$.asObservable();
  }
  public getLegalAlertState(): boolean {
    return this.legalAlertState$.getValue();
  }
  public setAlertState(state: boolean): void {
    this.legalAlertState$.next(state);
  }
  constructor(
    private readonly legalPopupService: LegalPopupService,
    private readonly authFacade: AuthFacade
  ) {
    super();
    this.listenToLogin$().subscribe();

    this.fullyFledgedConsentCheck$()
      .pipe(
        tap((res) => {
          if (res === 'already_set') {
            this.isSavedInBackEndStatus = true;
            if (this.getLegalAlertState()) {
              this.setAlertState(false);
            }
          }
        })
      )
      .subscribe();
  }
  public saveConsent(): void {
    this.set(this.storageKey, String(this.maxNeglectCount));
    this.fullyFledgedConsentAdd$(true)
      .pipe(
        map((res) => {
          if (res === 'already_set') {
            this.set(this.storageKey, '0');
            this.isSavedInBackEndStatus = true;
          }
          return null;
        })
      )
      .subscribe();
  }
  public fullyFledgedConsentCheck$(): Observable<LegalPopupConsentResponseStatusLocalScopeState> {
    return this.legalPopupService.checkByContainerName().pipe(
      mergeMap((definitionArr) => {
        const definitionId =
          definitionArr?.data?.['PROMOTIONS']?.reduce(
            (max: number, curr: LegalPopupConsentDefinitionsObject) => {
              return Math.max(max, curr?.definitionId ?? 0);
            },
            0
          ) ?? null;
        return definitionId === null
          ? of(null)
          : this.legalPopupService.checkCustomConsent(definitionId);
      }),
      map((response: LegalPopupConsentResponseInterface) => {
        if (response === null) {
          return null;
        }
        if (response?.code === 200 && typeof response?.data === 'boolean') {
          return 'already_set';
        }
        if (
          response?.code === 400 &&
          response?.description?.includes(
            legalConsentsConstants.get('CONSENT_NOT_SET_DESCRIPTION')
          )
        ) {
          return 'not_set';
        }
        return null;
      })
    );
  }
  public fullyFledgedConsentAdd$(
    consentStatus: boolean = true
  ): Observable<LegalPopupConsentResponseStatusLocalScopeState> {
    return this.legalPopupService.checkByContainerName().pipe(
      mergeMap((definitionArr) => {
        const definitionId =
          definitionArr?.data?.['PROMOTIONS']?.reduce(
            (max: number, curr: LegalPopupConsentDefinitionsObject) => {
              return Math.max(max, curr?.definitionId ?? 0);
            },
            0
          ) ?? null;
        return definitionId === null
          ? of(null)
          : this.legalPopupService.addCustomerConsent(
              definitionId,
              consentStatus
            );
      }),
      map((response: LegalPopupConsentResponseInterface) => {
        if (response === null) {
          return null;
        }
        if (response?.code === 200 && typeof response?.data === 'boolean') {
          return 'already_set';
        }
        if (
          response?.code === 400 &&
          response?.description?.includes(
            legalConsentsConstants.get('CONSENT_CAN_BE_SET_ONCE_DESCRIPTION')
          )
        ) {
          return 'already_set';
        }
        return null;
      })
    );
  }
  private listenToLogin$(): Observable<null> {
    return this.authFacade.isLoggedIn$().pipe(
      takeWhile(() => !this.isSavedInBackEndStatus),
      pairwise(),
      mergeMap(([prevValue, currValue]: boolean[]) => {
        if (!prevValue && currValue) {
          return this.fullyFledgedConsentCheck$().pipe(
            mergeMap((res) => {
              if (res === 'already_set') {
                this.isSavedInBackEndStatus = true;
              } else if (res === 'not_set') {
                const count = Number(this.get(this.storageKey) || 0);
                if (count < this.maxNeglectCount) {
                  this.setAlertState(true);
                  this.set(this.storageKey, String(count + 1));
                } else if (count === this.maxNeglectCount) {
                  return this.fullyFledgedConsentAdd$(false).pipe(
                    map((res) => {
                      if (res === 'already_set') {
                        this.isSavedInBackEndStatus = true;
                        this.set(this.storageKey, '0');
                      }
                      return null;
                    })
                  );
                }
              }
              return of(null);
            })
          );
        } else if (prevValue && !currValue) {
          this.setAlertState(false);
        }
        return of(null);
      }),
      catchError(() => of(null))
    );
  }
}
