import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { HistoryData } from '../../types/step.type';

@Component({
  selector: 'crc-shared-bonus-history',
  templateUrl: './bonus-history.component.html',
  styleUrls: ['./bonus-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusHistoryComponent {
  @Input() historyData: HistoryData[];
  @Output() nextPage: EventEmitter<void> = new EventEmitter<void>();
  trackById(index: number, historyData: HistoryData) {
    return historyData.id;
  }
}
