<ng-container *ngSync="SMS_ON_KEY_DESCRIPTION | translate as translatedKey">
  <ng-container *ngIf="mainBlockIsVisible$ | async; else disagreeBlock">
    <div class="sms-on__description">
      {{ translatedKey }}
    </div>
    <div class="sms-on__phone-number">{{ mobileNumber$ | async }}</div>
    <div class="sms-on__buttons">
      <crc-m-shared-button
        (clicked)="
          userAction.emit({
            status: true,
            text: translatedKey
          })
        "
        type="active"
        >{{ SMS_ON_KEY_AGREE_BUTTON | translate }}</crc-m-shared-button
      >
      <crc-m-shared-button (clicked)="onFirstdesagree()" type="primary">{{
        SMS_ON_KEY_DISAGREE_BUTTON | translate
      }}</crc-m-shared-button>
    </div>
  </ng-container>
  <ng-template #disagreeBlock>
    <ng-container
      *ngSync="
        SMS_ON_KEY_DISAGREE_DESCRIPTION | translate as translatedKeyOnDisagree
      "
    >
      <div class="sms-on__description">
        {{ translatedKeyOnDisagree }}
      </div>

      <div class="sms-on__buttons sms-on__buttons--disagree">
        <crc-m-shared-button
          (click)="
            userAction.emit({
              status: false,
              text: translatedKey
            })
          "
          type="danger"
        >
          {{ 'yes' | translate }}
        </crc-m-shared-button>
        <crc-m-shared-button (click)="onSecondDisagree()" type="active">
          {{ 'no' | translate }}
        </crc-m-shared-button>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
