import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import { delay, map, merge, Observable, of, switchMap, timer } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { DaterangepickerDirective } from '@crc/date-range-picker';

import { LanguageFacade } from '@crc/shared';
import { DateRangeTranslateService } from './services/date-range-translate.service';
import { DateRangePickerInterface } from './entity/date-range-picker.interface';
//TODO find alternative for date-range-picker and get rid of dayjs once and for all
@Component({
  selector: 'crc-shared-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent {
  @ViewChild(DaterangepickerDirective) picker: DaterangepickerDirective;
  @ViewChild('inputPicker') inputPicker: ElementRef;
  @Output() filterDate = new EventEmitter<DateRangePickerInterface>();
  selected = { startDate: dayjs().subtract(6, 'days'), endDate: dayjs() };
  ranges$: Observable<{ [key: string]: [dayjs.Dayjs, dayjs.Dayjs] }>;
  locale$: Observable<any>;

  constructor(
    private languageFacade: LanguageFacade,
    private dateRangeTranslate: DateRangeTranslateService
  ) {
    this.locale$ = this.languageFacade.current$.pipe(
      delay(300),
      switchMap((_) =>
        of({
          applyLabel: this.languageFacade.translateInstant('show'),
          format: 'DD.MM.YYYY',
          daysOfWeek: [...this.dateRangeTranslate.getTranslatedDays()],
          monthNames: [...this.dateRangeTranslate.getTranslatedMonths()]
        })
      ),
      switchMap((locales) => merge(of(null), timer(100).pipe(mapTo(locales))))
    );

    this.ranges$ = this.languageFacade.current$.pipe(
      map((_) => {
        return this.getRanges();
      }),
      switchMap((ranges) => merge(of(null), timer(100).pipe(mapTo(ranges))))
    );
  }

  getRanges() {
    const ranges: { [key: string]: [dayjs.Dayjs, dayjs.Dayjs] } = {};

    ranges[this.languageFacade.translateInstant('dates.today')] = [
      dayjs(),
      dayjs()
    ];
    ranges[this.languageFacade.translateInstant('dates.last_week')] = [
      dayjs().subtract(6, 'day'),
      dayjs()
    ];
    ranges[this.languageFacade.translateInstant('dates.last_month')] = [
      dayjs().subtract(1, 'month'),
      dayjs()
    ];

    return ranges;
  }

  choseData(event: DateRangePickerInterface): void {
    if (!dayjs(this.picker.picker.endDate).isValid()) {
      this.picker.picker.setEndDate(dayjs(this.picker.picker.startDate));
      setTimeout(() => {
        this.picker.picker.clickApply(); // TODO FIND BETTER SOLUTION FOR INVALID DATES
      }, 0);
      return;
    }
    this.filterDate.emit({
      startDate: dayjs(event.startDate).format('YYYY-MM-DD'),
      end: dayjs(event.end).format('YYYY-MM-DD')
    });
  }

  openDatepicker(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const el = this.inputPicker.nativeElement;
      el.addEventListener('click', (e: Event) => {
        e.stopPropagation();
      });
      el.click();
    });
  }
}
