import { Injectable } from '@angular/core';
import { BaseUrl, HttpService } from '@crc/shared';
import { Observable } from 'rxjs';
import { ApiResponse } from '@crc/facade';

interface PasscodeData {
  deviceCode: string;
  login: string;
  passcode: string;
}

@Injectable({
  providedIn: 'root'
})
export class PasscodeService {
  constructor(private readonly apiService: HttpService) {}

  savePasscode(loginData: {
    login: string;
    password: string;
    code: string;
  }): Observable<ApiResponse> {
    const body = {
      login: loginData.login,
      password: loginData.password,
      code: loginData.code
    };
    const path = `/device-code/save`;
    const baseUrl: BaseUrl = 'authApiUrl';

    return this.apiService.post$({
      path,
      baseUrl,
      body
    });
  }

  checkPasscode(passcodeData: PasscodeData): Observable<ApiResponse> {
    const body = {
      login: passcodeData.login,
      password: passcodeData.passcode,
      deviceCode: passcodeData.deviceCode
    };
    const path = `/login`;
    const baseUrl: BaseUrl = 'authApiUrl';

    return this.apiService.post$({
      path,
      baseUrl,
      body
    });
  }
}
