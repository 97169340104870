import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

@Component({
  selector: 'crc-m-login-otp-button',
  templateUrl: './login-otp-button.component.html',
  styleUrls: ['./login-otp-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginOtpButtonComponent {
  @Input() public isActive = false;
  @Output() public clicked: EventEmitter<Event> = new EventEmitter<Event>();
}
