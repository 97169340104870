import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CtaPopup } from '../entity/cta.interface';
import { CtaService } from '../services/cta.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CtaFacade {
  isVisible: boolean;

  openRoutes: string[] = [
    '/',
    '/sport',
    '/live',
    '/slots',
    '/slots/games',
    '/casino',
    '/casino/providers',
    '/poker',
    '/games',
    '/virtual',
    '/virtual/games',
    '/promotions'
  ];

  constructor(private ctaPopupService: CtaService) {}

  getCtaData(): Observable<CtaPopup> {
    return this.ctaPopupService
      .getUserCta()
      .pipe(map((payload) => payload.data[0]));
  }

  getMobCtaData(): Observable<CtaPopup> {
    return this.ctaPopupService
      .getMobUserCta()
      .pipe(map((payload) => payload.data[0]));
  }
}
