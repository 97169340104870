import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  from,
  Observable,
  of,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import { map, toArray } from 'rxjs/operators';

// eslint-disable-next-line @nx/nx/enforce-module-boundaries
import { FilterItemView } from '@crc/components';

import { Provider } from '../../shared';
import { CasinoService } from '../services/casino.service';
import { DEFAULT_CASINO_PROVIDER_FILTER } from '../constants/default-casino-provider-filter';
import { CasinoFacade } from './casino.facade';
import {
  CASINO_DEFAULT_RANGE,
  CasinoFilterItems,
  CasinoGame,
  EgtLobbyGameInterface,
  FilterAmount,
  ProviderCasinoGames
} from '../entity';
import { CASINO_ALL_PROVIDER_FILTER_ITEM } from '../constants/all-provider-filter-item';
import { CASINO_GROUPS, sortByMinMaxLimits } from '../constants';
import { DEFAULT_CASINO_GROUP_FILTER_TYPE_ITEM } from '../constants/default-casino-group-filter';
import { CASINO_ALL_GROUP_FILTER_ITEM } from '../constants/all-group-filter-item';
import { TOP_CASINOS_FILTER_ITEM } from '../constants/top-casinos-filter-item';
import { AccountFacade } from '../../../auth';
import { CasinoFavoriteFacade } from './casino-favorite.facade';
// eslint-disable-next-line @nx/nx/enforce-module-boundaries
import { ENV_CONFIG, EnvironmentConfig, replaceImage } from '@crc/shared';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CasinoFiltersFacade {
  $isFiltered = new BehaviorSubject<boolean>(false);
  readonly filteredCasinos$: Observable<CasinoGame[]>;
  readonly filteredCasinosOnlyByGroup$: Observable<CasinoGame[]>;
  private readonly $activeGroupFilter = new BehaviorSubject<string>(
    DEFAULT_CASINO_GROUP_FILTER_TYPE_ITEM
  );

  private readonly $minMaxEgtLobbyFilterCasino = new BehaviorSubject<{
    minStart: number;
    minEnd: number;
  }>(CASINO_DEFAULT_RANGE);
  private readonly $filteredRanges = new Subject<FilterAmount[]>();
  private readonly $filteredRangeData = new Subject<CasinoGame[]>();
  private readonly $filterVisibility = new BehaviorSubject<boolean>(true);
  readonly activeGroupFilter$ = this.$activeGroupFilter
    .asObservable()
    .pipe(distinctUntilChanged());
  private readonly $activeProviderFilter = new BehaviorSubject<string>(
    DEFAULT_CASINO_PROVIDER_FILTER
  );
  readonly activeProviderFilter$ = this.$activeProviderFilter
    .asObservable()
    .pipe(distinctUntilChanged());
  readonly $casinoIsFiltered = new BehaviorSubject<boolean>(false);
  private readonly $isMobile = new BehaviorSubject<boolean>(false);
  private readonly tempVars: {
    filteredRangesCache: FilterAmount[] | null;
  } = {
    filteredRangesCache: null
  };
  private get smartRoutingPermittingCondition(): boolean {
    return (
      this.__env__.platform === 'desktop' &&
      this.router.url.startsWith('/casino') &&
      !this.router.url.startsWith('/casino/play')
    );
  }
  filter = {
    category: this.route.snapshot.queryParams?.category,
    filter: this.route.snapshot.queryParams?.filter
  };
  public getActiveProvider() {
    return this.$activeProviderFilter.getValue();
  }
  constructor(
    private readonly casinoService: CasinoService,
    private readonly casinoFacade: CasinoFacade,
    private readonly accountFacade: AccountFacade,
    private readonly casinoFavoriteFacade: CasinoFavoriteFacade,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    @Inject(ENV_CONFIG) private readonly __env__: EnvironmentConfig
  ) {
    this.filteredCasinosOnlyByGroup$ = this.accountFacade.customerId$.pipe(
      switchMap(() => this.activeGroupFilter$),
      switchMap((group) =>
        group === 'web:popular'
          ? this.casinoFacade.popularCasinos
          : (group === 'favorite_games'
              ? this.casinoFavoriteFacade.favorites$
              : of(null)
            ).pipe(
              switchMap((f) => {
                return this.casinoFacade.allCasinosByProviders$.pipe(
                  map((casinos) => {
                    CasinoFilterItems.filter((data) => {
                      data.games.length = 0;
                    });
                    let filteredCasinos: CasinoGame[] = [...casinos];
                    if (group) {
                      // TODO duplicated logic
                      if (
                        CASINO_GROUPS.includes(group) ||
                        (this.__env__.platform === 'mobile' &&
                          group === 'showgames')
                      ) {
                        filteredCasinos = filteredCasinos.filter(
                          (g) =>
                            g.group_name?.includes(group) ||
                            g.game_id
                              ?.toLowerCase()
                              ?.includes(
                                EgtLobbyGameInterface.gameId?.toLowerCase()
                              )
                        );
                      } else if (group === 'favorite_games') {
                        filteredCasinos = filteredCasinos.filter((g) => {
                          return this.casinoFavoriteFacade.isCasinoInFavorites(
                            g
                          );
                        });
                      } else {
                        filteredCasinos = filteredCasinos.filter((g) => {
                          return !CASINO_GROUPS.reduce((res, cur) => {
                            return this.getCasinoIsMobileValue()
                              ? res && g.group_name?.includes(cur)
                              : res || g.group_name?.includes(cur);
                          }, false);
                        });
                      }
                    }

                    filteredCasinos = filteredCasinos.sort((a, b) =>
                      this.casinoFavoriteFacade.isCasinoInFavorites(a)
                        ? -1
                        : this.casinoFavoriteFacade.isCasinoInFavorites(b)
                        ? 1
                        : 0
                    );

                    return filteredCasinos;
                  }),
                  tap((casinos) => this.getCasinoFilterRange(casinos))
                );
              }),
              map((items) => {
                return (items ?? []).sort(sortByMinMaxLimits);
              })
            )
      )
    );

    this.filteredCasinos$ = this.filteredCasinosOnlyByGroup$.pipe(
      switchMap((casinos) => {
        return this.activeProviderFilter$.pipe(
          switchMap((provider) =>
            this.getCasinoIsFiltered().pipe(
              map(() => {
                CasinoFilterItems.filter((data) => {
                  data.games.length = 0;
                });

                if (provider) {
                  return casinos.filter((p) => {
                    if (p.provider === provider) {
                      return this.getFilteredByMinMaxAndEgtLobby(p);
                    }
                  });
                }
                return casinos.filter((casino) =>
                  this.getFilteredByMinMaxAndEgtLobby(casino)
                );
              })
            )
          ),
          tap((casinos) => {
            this.getCasinoFilterRange(casinos);
          })
        );
      })
    );
  }

  getCasinoFilterRange(casinos: CasinoGame[]) {
    const items = [];
    casinos.filter((game) => {
      CasinoFilterItems.filter((item) => {
        if (
          Number(game?.limits?.min) >= item.minStart &&
          Number(game?.limits?.min) <= item.minEnd &&
          (item.value === 'FreeChips'
            ? game.game_id === EgtLobbyGameInterface.gameId
            : true)
        ) {
          item.games.push(game);
        }

        if (
          !(
            this.router.url.includes('/casino/games') ||
            (this.__env__.platform === 'mobile' &&
              this.router.url.includes('/casino') &&
              this.route.snapshot.queryParams?.baseCategory === 'games')
          )
        ) {
          item.hasGame = false;
        }
      });
    });

    CasinoFilterItems.filter((item) => {
      items.push(item);
    });
    this.setFilteredRange(items);
  }

  getFilteredByMinMaxAndEgtLobby(casino: CasinoGame) {
    return (
      (Number(casino?.limits?.min) >=
        this.getFilterMinMaxValueCasino()?.minStart &&
        Number(casino?.limits?.min) <=
          this.getFilterMinMaxValueCasino()?.minEnd &&
        (this.getFilterMinMaxValueCasino()?.['value'] === 'FreeChips'
          ? casino?.['game_id'] === EgtLobbyGameInterface['game_id']
          : true)) ||
      Object.keys(casino.limits || {}).length === 0
    );
  }

  setCasinoIsMobile(data: boolean) {
    this.$isMobile.next(data);
  }

  getCasinoIsMobileValue(): boolean {
    return this.$isMobile.value;
  }

  setCasinoIsFiltered(data: boolean) {
    this.$casinoIsFiltered.next(data);
  }

  setFilteredRange(data: FilterAmount[]) {
    Object.assign(this.tempVars, {
      filteredRangesCache: data
    });
    this.$filteredRanges.next(data);
  }

  setFilterVisibility(data: boolean) {
    this.$filterVisibility.next(data);
  }

  getFilterVisibility(): Observable<boolean> {
    return this.$filterVisibility.asObservable().pipe(distinctUntilChanged());
  }

  setFilteredRangeData(data: CasinoGame[]) {
    this.$filteredRangeData.next(data);
  }

  getFilteredRangeData(): Observable<CasinoGame[]> {
    return this.$filteredRangeData.asObservable().pipe(distinctUntilChanged());
  }

  getFilteredRange(): Observable<FilterAmount[]> {
    return this.$filteredRanges.asObservable().pipe(distinctUntilChanged());
  }
  public getCurrentFilteredRange(): FilterAmount[] | null {
    return this.tempVars.filteredRangesCache;
  }
  getCasinoIsFiltered(): Observable<boolean> {
    return this.$casinoIsFiltered.asObservable().pipe(distinctUntilChanged());
  }

  getCasinoIsFilteredValue(): boolean {
    return this.$casinoIsFiltered.value;
  }

  setFilteredStatus(isFiltered: boolean) {
    this.$isFiltered.next(isFiltered);
  }

  getFilteredStatus() {
    return this.$isFiltered.value;
  }

  setFilterMinMaxEgtLobbyCasino(data: {
    minStart: number;
    minEnd: number;
    value?: string;
  }) {
    this.$minMaxEgtLobbyFilterCasino.next(data);
    this.setCasinoIsFiltered(false);
  }

  getFilterMinMaxValueCasino() {
    return this.$minMaxEgtLobbyFilterCasino.value;
  }

  selectCategoryFilter(filter: string) {
    if (this.__env__.platform === 'desktop') {
      this.filter.category = filter ? filter : 'all';
      this.filter.filter = 'all';
      if (this.smartRoutingPermittingCondition) {
        /* Here is casino category navigation with queryParamsHandling which merges routes*/
        this.changeRoute();
      }
      this.$activeGroupFilter.next(filter);
      this.$activeProviderFilter.next('');
    } else {
      if (this.$activeGroupFilter.getValue() !== filter) {
        this.selectProviderFilter('');
        this.$activeGroupFilter.next(filter);
      }
    }
  }

  selectProviderFilter(filter: string, replaceState = false) {
    if (this.__env__.platform === 'desktop') {
      this.filter.filter = filter ? filter : 'all';
      if (this.smartRoutingPermittingCondition) {
        /* Here is casino filter navigation */
        this.changeRoute(replaceState);
      }
    }
    this.$activeProviderFilter.next(filter);
  }

  changeRoute(replaceState = false) {
    this.router
      .navigate(['/casino'], {
        queryParams: {
          ...this.filter
        },
        queryParamsHandling: 'merge',
        replaceUrl: replaceState
      })
      .then();
  }

  getActiveGroupFilter() {
    return this.$activeGroupFilter.value;
  }

  resetFilter() {
    this.$activeProviderFilter.next(DEFAULT_CASINO_PROVIDER_FILTER);
    this.$activeGroupFilter.next(DEFAULT_CASINO_GROUP_FILTER_TYPE_ITEM);
  }

  getFilteredCasinoGames(): Observable<CasinoGame[]> {
    return this.filteredCasinos$.pipe(
      switchMap((data: CasinoGame[]) =>
        from(data).pipe(
          map((item: CasinoGame) => {
            replaceImage<CasinoGame>('casino', item.provider, item).image;
            return { ...item };
          }),
          toArray()
        )
      )
    );
  }

  getGroupFilters(addHome: boolean): Observable<FilterItemView[]> {
    return combineLatest([
      this.casinoFacade.popularCasinos,
      this.casinoFacade.groups$
    ]).pipe(
      map(([popularItems, groupItems]) => {
        return !addHome
          ? [
              CASINO_ALL_GROUP_FILTER_ITEM,
              {
                ...TOP_CASINOS_FILTER_ITEM,
                totalCount: popularItems?.length
              },
              ...groupItems
            ]
          : groupItems;
      }),
      switchMap((items: FilterItemView[]) =>
        this.activeGroupFilter$.pipe(
          map((filter) => {
            return items.map((item: FilterItemView) => {
              return {
                ...item,
                active: item.filter === filter
              } as FilterItemView;
            });
          })
        )
      )
    );
  }

  getProviderFilters(skipAll = false): Observable<FilterItemView[]> {
    return combineLatest([
      this.filteredCasinosOnlyByGroup$,
      this.activeGroupFilter$,
      this.casinoFavoriteFacade.favorites$
    ]).pipe(
      switchMap(([casinos]) =>
        this.casinoFacade.providers$.pipe(
          switchMap((providers) =>
            this.getCasinoIsFiltered().pipe(
              map(() => {
                return providers
                  .filter((i) => i.enabled && i.gamesCount)
                  .map((item: Provider) => {
                    return {
                      name: item.name,
                      filter: item.provider,
                      isNew: item.tags?.includes('new')
                    } as FilterItemView;
                  });
              }),
              map((providers) => {
                return providers.filter((p) =>
                  casinos.find((c) => c.provider === p.filter)
                );
              }),
              // select default if customer has not provider
              tap((providers) => {
                if (this.$activeProviderFilter.value) {
                  const selectedNotExists =
                    providers.findIndex(
                      (p) => p.filter === this.$activeProviderFilter.value
                    ) < 0;
                  if (selectedNotExists) {
                    this.$activeProviderFilter.next(
                      DEFAULT_CASINO_PROVIDER_FILTER
                    );
                  }
                }
              }),
              map((items) => {
                return skipAll
                  ? items
                  : [CASINO_ALL_PROVIDER_FILTER_ITEM, ...items];
              }),
              switchMap((items: FilterItemView[]) =>
                this.activeProviderFilter$.pipe(
                  map((filter) => {
                    return items.map((item: FilterItemView) => {
                      return {
                        ...item,
                        active: item.filter === filter
                      } as FilterItemView;
                    });
                  })
                )
              )
            )
          )
        )
      )
    );
  }

  getGames(provider: string): Observable<ProviderCasinoGames> {
    return this.casinoFacade.getProviderWithCasinos(provider).pipe(
      tap((data) => {
        this.getCasinoFilterRange(data.games);
      })
    );
  }

  getProvidersObject(): Observable<FilterItemView[]> {
    return this.casinoFacade.providers$.pipe(
      map((items) => {
        return items
          .filter((i) => i.enabled && i.gamesCount)
          .map((item: Provider) => {
            return {
              name: item.name,
              filter: item.provider
            } as FilterItemView;
          });
      })
    );
  }
}
