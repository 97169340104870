import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  OTPAuthResponseInterface,
  OTPSendResponseInterface
} from '@crc/facade';

@Component({
  selector: 'crc-m-auth-otp-presentation',
  templateUrl: './auth-otp-presentation.component.html',
  styleUrls: ['./auth-otp-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthOtpPresentationComponent {
  @Input() public form: UntypedFormGroup;
  @Input() public smsStatus: OTPSendResponseInterface | null;
  @Input() public submitStatus: OTPAuthResponseInterface | null;
  @Input() public isLoading: boolean;
  // @Input() public autoOTPStatus: string;
  @Input() public timerResetTrigger: boolean;
  @Input() public isCaptchaValid: boolean;
  @Input() public captchaCode: string;
  @Input() public isCaptchaActive: boolean;
  @Output() public formSubmit: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public returnToDefaultProtocol: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() public sendSMS: EventEmitter<void> = new EventEmitter<void>();
  @Output() public captchaValueEntered: EventEmitter<string> =
    new EventEmitter();
  @Output() public updateCaptcha: EventEmitter<void> = new EventEmitter();
  @Output() public captchaValidValueEnter: EventEmitter<void> =
    new EventEmitter();
}
