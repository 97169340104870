import { Injectable } from '@angular/core';
import { PersonalData } from '../../../auth/entity';
import { accountActions } from '../../../auth/store/actions';
import { accountSelectors } from '../../../auth/store/selectors';
import {
  IdentificationCheckTypes,
  IdentificationSteps,
  IdentificationTypes
} from '../entity/identification-types';
import { State, Store } from '@ngrx/store';
import {
  BehaviorSubject,
  delayWhen,
  distinctUntilChanged,
  from,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  timer
} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdentificationFacade {
  private readonly $notNavPopupClosing = new BehaviorSubject<boolean>(false);
  private readonly $timerResetState: Subject<void> = new Subject();
  private readonly $obligedAndHasNoNumber = new BehaviorSubject<boolean>(false);
  private readonly $identificationSteps =
    new BehaviorSubject<IdentificationSteps>('action');

  public get obligedAndHasNoNumberStateValue(): boolean {
    return this.$obligedAndHasNoNumber.value;
  }
  public get identificationStateValue(): IdentificationCheckTypes {
    return this.state.value.account.identificationActive;
  }
  public get verificationActiveValue(): boolean {
    return this.state.value.account.verificationActive || false;
  }
  constructor(
    private readonly store: Store<PersonalData>,
    private readonly state: State<PersonalData>
  ) {}
  public setItemToStorage(
    key: string,
    value: string,
    storage: 'local' | 'session'
  ) {
    if (storage === 'local') {
      localStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, value);
    }
  }
  public removeItemFromStorage(key: string, storage: 'local' | 'session') {
    if (storage === 'local') {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }
  public triggerTimerReset(): void {
    this.$timerResetState.next();
  }
  setNotNavPopupClosing(data: boolean) {
    this.$notNavPopupClosing.next(data);
  }

  getNotNavPopupClosing(): Observable<boolean> {
    return this.$notNavPopupClosing.asObservable().pipe(distinctUntilChanged());
  }

  public getTimerResetActions$(): Observable<boolean> {
    return this.$timerResetState.asObservable().pipe(
      startWith(null),
      switchMap(() => from([true, false])),
      delayWhen((res) => (res ? of(null) : timer(0)))
    );
  }
  public setIdentificationStep(value: IdentificationSteps) {
    this.$identificationSteps.next(value);
  }

  public getIdentificationStep(): Observable<IdentificationSteps> {
    return this.$identificationSteps
      .asObservable()
      .pipe(distinctUntilChanged());
  }
  public setObligedAndHasNoNumberState(value: boolean) {
    this.$obligedAndHasNoNumber.next(value);
  }
  public getObligedAndHasNoNumberState(): Observable<boolean> {
    return this.$obligedAndHasNoNumber
      .asObservable()
      .pipe(distinctUntilChanged());
  }
  public getIdentification$(): Observable<IdentificationCheckTypes> {
    return this.store
      .select(accountSelectors.selectIdentificationStatus)
      .pipe(distinctUntilChanged());
  }
  public setIdentification$(value: IdentificationCheckTypes) {
    this.store.dispatch(
      accountActions.getIdentificationStatus({ payload: value })
    );
  }
  public isAccessForbidden$(): Observable<boolean> {
    return this.getIdentification$().pipe(
      map((state) => state === IdentificationTypes.Obliged)
    );
  }
}
