import {Injectable} from '@angular/core';

import {ProfileShowBalanceService} from '../services/profile-show-balance.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileShowBalanceFacade {
  constructor(private readonly userService: ProfileShowBalanceService) {}

  setToggleBalanceState(isBalanceVisible: boolean) {
    this.userService.setToggleBalanceStateToLocalStorage(isBalanceVisible);
  }

  getToggleBalanceState(): boolean {
    return this.userService.getToggleBalanceStateFromLocalStorage();
  }
}
