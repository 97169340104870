import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors
} from '@angular/forms';
import { removeError } from './contains-value.validator';
import { handleSettingRequiredError } from '@crc/shared';

export type FormGroupValidatorCallback = (
  formControls: UntypedFormGroup
) => ValidationErrors | null;

export const matchControlsValidator = (
  controlConf: { controlName: string; addError: boolean },
  matchingControlConf: { controlName: string; addError: boolean }
): FormGroupValidatorCallback => {
  return (formControls: UntypedFormGroup): ValidationErrors => {
    const controlsList = formControls.controls;
    const control: AbstractControl = controlsList[controlConf.controlName];
    const matchingControl: AbstractControl =
      controlsList[matchingControlConf.controlName];

    if (
      matchingControl.value &&
      control.value &&
      control.value !== matchingControl.value
    ) {
      if (controlConf.addError) {
        control.setErrors({ match: true });
      }
      if (matchingControlConf.addError) {
        matchingControl.setErrors({ match: true });
      }
    } else {
      removeError(control, 'match');
      removeError(matchingControl, 'match');
    }
    if (
      (control?.value as string)?.length <=
        (matchingControl?.value as string)?.length &&
      control?.value !== matchingControl?.value
    ) {
      matchingControl.setErrors({
        IsLongerThan: true
      });
      matchingControl.markAsTouched();
      handleSettingRequiredError(matchingControl);
    } else {
      removeError(matchingControl, 'IsLongerThan');
    }
    return null;
  };
};
