import { ButtonInterface, DepositAmount } from '..';

export const withdrawAmountButtons: ButtonInterface[] = [
  { amounts: [10, 20, 50, 100] }
];

export const DEFAULT_DEPOSIT_BUTTONS: ButtonInterface = {
  amounts: [20, 50, 100, 250, 500]
};

export const DepositAmountConstant: DepositAmount[] = [
  {
    value: '20 ₾',
    id: 20
  },
  {
    value: '50 ₾',
    id: 50
  },
  {
    value: '100 ₾',
    id: 100
  },
  {
    value: '500 ₾',
    id: 500
  }
];

export const WithdrawAmountConstant: DepositAmount[] = [
  {
    value: '10 ₾',
    id: 10
  },
  {
    value: '20 ₾',
    id: 20
  },
  {
    value: '50 ₾',
    id: 50
  },
  {
    value: '100 ₾',
    id: 100
  }
];
