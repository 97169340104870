import { getWindow } from './window.functions';
export function osIsIos(): boolean {
  function iosQuirkPresent() {
    const audio = new Audio();
    audio.volume = 0.5;
    return audio.volume === 1;
  }
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes('Macintosh');
  const isTouchScreen = navigator.maxTouchPoints >= 1;

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
}
function checkApplePayApiAvailability(): boolean {
  return getWindow()?.ApplePaySession?.canMakePayments?.();
}

export function isApplePayApplicable(): boolean {
  try {
    return osIsIos() && checkApplePayApiAvailability();
  } catch (_) {
    return false;
  }
}
