import { Injectable } from '@angular/core';

import { Language } from '../types/language';
import { CookieService } from '../../utils';

@Injectable({
  providedIn: 'root'
})
export class LanguageStorage {
  constructor(private readonly cookieService: CookieService) {}

  setLang(lang: Language) {
    this.cookieService.set({
      expires: 3,
      name: 'lang',
      value: lang
    });
  }

  getLang(): Language | null {
    return this.cookieService.get('lang') as Language;
  }
}
