import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LimitsFacade {
  private readonly $bogMaxDeposit = new BehaviorSubject<number>(null);
  private readonly $bogMaxWithdraw = new BehaviorSubject<number>(null);

  private readonly $bogMinDeposit = new BehaviorSubject<number>(null);
  private readonly $bogMinWithdraw = new BehaviorSubject<number>(null);

  private readonly $tbcMaxDeposit = new BehaviorSubject<number>(null);
  private readonly $tbcMaxWithdraw = new BehaviorSubject<number>(null);

  private readonly $tbcMinDeposit = new BehaviorSubject<number>(null);
  private readonly $tbcMinWithdraw = new BehaviorSubject<number>(null);

  private readonly $payoutFee = new BehaviorSubject<number>(null);
  setPayoutFee(fee: number) {
    this.$payoutFee.next(fee);
  }

  getPayoutFee$() {
    return this.$payoutFee.value;
  }

  setBogMax(max: number) {
    this.$bogMaxDeposit.next(max);
  }

  getBogMaxValue$() {
    return this.$bogMaxDeposit.value;
  }

  setBogMaxWithdraw(max: number) {
    this.$bogMaxWithdraw.next(max);
  }

  getBogMaxWithdrawValue$() {
    return this.$bogMaxWithdraw.value;
  }

  setTbcMax(max: number) {
    this.$tbcMaxDeposit.next(max);
  }

  getTbcMaxValue$() {
    return this.$tbcMaxDeposit.value;
  }

  setTbcMaxWithdraw(max: number) {
    this.$tbcMaxWithdraw.next(max);
  }

  getTbcMaxWithdrawValue$() {
    return this.$tbcMaxWithdraw.value;
  }

  setBogDepositMin(min: number) {
    this.$bogMinDeposit.next(min);
  }

  getBogDepositMin$() {
    return this.$bogMinDeposit.value;
  }

  setBogWithdrawMin(min: number) {
    this.$bogMinWithdraw.next(min);
  }

  getBogWithdrawMin$() {
    return this.$bogMinWithdraw.value;
  }

  setTbcDepositMin(min: number) {
    this.$tbcMinDeposit.next(min);
  }

  getTbcDepositMin$() {
    return this.$tbcMinDeposit.value;
  }

  setTbcWithdrawMin(min: number) {
    this.$tbcMinWithdraw.next(min);
  }

  getTbcWithdrawMin$() {
    return this.$tbcMinWithdraw.value;
  }
}
