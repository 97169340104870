<div class="secondary-filter" #nawWrapper>
<!--  [class.secondary-filter__filter-nav--margin]="
      route.snapshot.queryParams?.baseCategory === routerCategory
    "-->
  <div

    class="secondary-filter__filter-nav"
  >
    <div
      *ngFor="let filterItem of filterItems; trackBy: trackFilterItems;"
      class="div"
    >
      <div
        class="secondary-filter__btn"
        [class.secondary-filter__btn-active]="filterItem.isActive"
        (click)="handleFilter(filterItem, $event)"
      >
        <img class="secondary-filter__btn-icon" [attr.src]="filterItem.prefixIconSrc" alt="*"/>
        {{ filterItem.title | translate }}
      </div>
    </div>
  </div>
</div>
