import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { HttpService } from '@crc/shared';
import {
  ICustomerEmailValidityVerifyReqInterface,
  ICustomerEmailValidityVerifyResInterface,
  ICustomerVerifyEmailSmsResInterface
} from '../interfaces/request-resp.interface';

@Injectable({
  providedIn: 'root'
})
export class ChangeEmailService {
  constructor(private readonly httpService: HttpService) {}

  public sendSMSToNumber$(): Observable<unknown> {
    return this.httpService
      .post$({
        baseUrl: 'cmsApiUrl',
        path: '/customers/account/phone-number/send-verification-code',
        body: {}
      })
      .pipe(catchError((err) => of(err?.error ?? null)));
  }
  public sendSMSToEmail$(): Observable<unknown> {
    return this.httpService
      .post$({
        baseUrl: 'cmsApiUrl',
        path: '/customers/account/email/send-verification-code',
        body: {}
      })
      .pipe(catchError((err) => of(err?.error ?? null)));
  }

  public verifyEmail$(
    smsCode: string
  ): Observable<ICustomerVerifyEmailSmsResInterface | null> {
    return this.httpService
      .post$({
        baseUrl: 'cmsApiUrl',
        path: '/customers/account/email/verify',
        body: {
          oneTimeCode: `${smsCode}`
        }
      })
      .pipe(catchError((err) => of(err?.error ?? null)));
  }

  public editEmail$(
    smsCode: string,
    month: string,
    personalNumber: string,
    year: string,
    email: string
  ): Observable<ICustomerEmailValidityVerifyResInterface | null> {
    return this.httpService
      .post$<
        ICustomerEmailValidityVerifyResInterface,
        ICustomerEmailValidityVerifyReqInterface
      >({
        baseUrl: 'cmsApiUrl',
        path: '/customers/account/email',
        body: {
          month,
          verificationCode: `${smsCode}`,
          personalNumber,
          year,
          newEmail: email
        }
      })
      .pipe(catchError((err) => of(err?.error ?? null)));
  }
}
