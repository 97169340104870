import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  CrocoRoulette,
  CrocoRouletteDto,
  EgtDigitalInterface,
  EgtJackpot,
  PatePlay,
  PatePlayInterfaceDTO,
  PragmaticJackpot
} from '@crc/components';
import { BaseUrl, HttpService } from '@crc/shared';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class JackpotService {
  constructor(private http: HttpService) {
  }

  getJackpot(): Observable<EgtJackpot> {
    const path = `/integrations/v2/slot/providers/egt/jackpot`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.http
      .get$<EgtJackpot>({
        path,
        baseUrl
      })
      .pipe(map((payload) => payload['data']), catchError(() => of({} as EgtJackpot)));
  }

  getEgtDigitalJackpot(): Observable<EgtDigitalInterface> {
    const path = '/integrations/v2/slot/providers/egt-digital/jackpot';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.http
      .get$<EgtDigitalInterface>({
        path,
        baseUrl
      })
      .pipe(catchError(() => of({} as EgtDigitalInterface)));
  }

  getPragmaticJackpot(): Observable<PragmaticJackpot> {
    const path = '/integrations/v2/slot/providers/pragmatic/jackpot';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.http
      .get$<PragmaticJackpot>({
        path,
        baseUrl
      })
      .pipe(
        map((payload) => payload['data']),
        catchError(() => of({} as PragmaticJackpot))
      );
  }

  getCrocoRouletteJackpot(): Observable<CrocoRoulette> {
    const path = '/integrations/v2/slot/providers/roulette/jackpot';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.http
      .get$<CrocoRouletteDto>({
        path,
        baseUrl
      })
      .pipe(map((payload) => payload.data), catchError(() => of({} as CrocoRoulette))
      );
  }

  getPatePlayJackpot(): Observable<PatePlay> {
    const path = '/integrations/v2/slot/providers/pate-play/jackpot';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.http.get$<PatePlayInterfaceDTO>({
      path,
      baseUrl
    }).pipe(
      map((payload) => payload.data.result),
      catchError(() => of({} as PatePlay))
    );
  }
}
