<div
  class="wrap"
  [class.disabled]="disabled"
  [class.cursor-pointer]="!isMobile"
  (click)="clicked.emit(disabled)"
>
  <button
    type="button"
    class="wrap__button-cont"
    [class.cursor-pointer]="!isMobile"
    [disabled]="disabled"
  >
    {{ text }}
  </button>
</div>
