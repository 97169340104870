import { ElementRef, Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  interval,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeWhile,
  tap,
  throttleTime,
  timer
} from 'rxjs';

import { LanguageFacade } from '@crc/shared';
import { AccountFacade, AuthFacade, PersonalData } from '../../../auth';
import { initLiveCaller } from '@crc/components';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationStart } from '@angular/router';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class LiveCallerFacade {
  private readonly $manualLiveChatTrigger: Subject<void> = new Subject();
  userData$: Observable<PersonalData> = this.accountFacade.personalData$;
  private readonly $liveCallerState = new BehaviorSubject<boolean>(false);
  private readonly $liveCallerFooterState = new BehaviorSubject<boolean>(false);
  private readonly tempVars = {
    isFirstLiveCallerToggle: true
  };
  private readonly $chatIsDisabled = new BehaviorSubject<boolean>(false);
  private readonly isFirstLiveCallerToggle = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private readonly accountFacade: AccountFacade,
    private readonly languageFacade: LanguageFacade,
    private readonly authFacade: AuthFacade,
    @Inject(DOCUMENT) private readonly _doc_: Document
  ) {}

  subscribeToUserData() {
    this.userData$
      .pipe(
        delay(1000),
        filter((user) => !!user?.userId),
        distinctUntilChanged((pre, cur) => pre.userId === cur.userId),
        tap((user) => {
          this.initLiveCaller(user);
        })
      )
      .subscribe();
  }

  initLiveCaller(_user: PersonalData) {
    const decodedData = this.decodedData(_user);
    this.accountFacade
      .getUserLiveCaller(decodedData)
      .pipe(
        tap((res: any) => {
          if (window?.['LiveCaller']) {
            window['LiveCaller']?.$emit?.('user.login', res?.data);
          }
        }),
        catchError((err) => {
          console.error(err.stack);
          return of(null);
        })
      )
      .subscribe();
  }
  updateLiveCaller(_user) {
    const decodedData = this.decodedData(_user);
    this.accountFacade
      .getUserLiveCaller(decodedData)
      .pipe(
        tap((res: any) => {
          if (window?.['LiveCaller']) {
            window['LiveCaller']?.$emit?.('user.update', res?.data);
          }
        }),
        catchError((err) => {
          console.error(err.stack);
          return of(null);
        })
      )
      .subscribe();
  }
  decodedData(_user) {
    return {
      userId: _user.userId,
      firstName: _user.firstName,
      login: _user.login,
      pinCode: _user.pinCode,
      email: _user.email,
      mobile: _user.mobile
    };
  }

  liveCallerStarter(event: Event) {
    event.preventDefault();
    this.setLiveCallerState(true);
    if (this.tempVars.isFirstLiveCallerToggle) {
      this.tempVars.isFirstLiveCallerToggle = false;
      return this.getLiveCallerState()
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged(),
          switchMap((state: boolean) => {
            return this.initializeLiveCaller$().pipe(
              tap(() => this.toggleWidgetByState(state)),
              switchMap(() => {
                return timer(0, 300).pipe(
                  takeWhile((val) => {
                    const status =
                      !this._doc_.body.querySelector('.lc-frame-widget');
                    if (status && val > 14) {
                      this._doc_.body.classList.remove('no-scroll');
                    }
                    return status && val < 15;
                  }),
                  tap(() => this.toggleWidgetByState(state))
                );
              })
            );
          })
        )
        .subscribe();
    }
    window['LiveCaller']?.$emit?.('ui.widget.toggle');
  }

  toggleWidgetByState(state: boolean) {
    state
      ? window?.['LiveCaller']?.$emit?.('ui.widget.open')
      : window?.['LiveCaller']?.$emit?.('ui.widget.close');
  }

  liveCallerStateByNavigation(
    event: NavigationStart,
    identificationState: string
  ) {
    const liveCallerDiv = this._doc_.querySelectorAll('div[data-livecaller]');
    const liveCallerNewIcon = this._doc_.querySelectorAll('.livecaller');
    if (!this.accountFacade.isVerified() || identificationState !== 'Passed') {
      if (liveCallerDiv.length > 0) {
        if (!event.url.includes('verification')) {
          liveCallerDiv[0]['style'].display = 'block';
          liveCallerNewIcon[0]['style'].display = 'block';
        } else {
          liveCallerDiv[0]['style'].display = 'none';
          liveCallerNewIcon[0]['style'].display = 'none';
        }
      }
    }
  }

  public initializeLiveCaller$(): Observable<void> {
    return this.languageFacade.current$.pipe(
      map((lang) => {
        if (this.$liveCallerState.value || this.$liveCallerFooterState.value) {
          initLiveCaller(lang);
        }
        return void 0;
      }),
      delay(600)
    );
  }

  getLiveCallerState(): Observable<boolean> {
    return this.$liveCallerState.asObservable();
  }

  getFooterLiveCallerState(): Observable<boolean> {
    return this.$liveCallerFooterState.asObservable();
  }

  setChatIsDisabled(data: boolean) {
    this.$chatIsDisabled.next(data);
  }

  getChatIsDisabled$() {
    return this.$chatIsDisabled.asObservable();
  }

  setIsFirstLiveCallerToggle(data: boolean) {
    this.isFirstLiveCallerToggle.next(data);
  }

  getIsFirstLiveCallerToggleValue(): boolean {
    return this.isFirstLiveCallerToggle.value;
  }

  setLiveCallerState(data: boolean) {
    this.$liveCallerState.next(data);
  }

  setFooterLiveCallerState(data: boolean) {
    this.$liveCallerFooterState.next(data);
  }

  public getManualLiveChatTrigger$(): Observable<void> {
    return this.$manualLiveChatTrigger.asObservable();
  }
  public triggerLiveChatInit(): void {
    this.$manualLiveChatTrigger.next();
  }
  public getLiveCallerMutationObservable$(ref: Document) {
    let mutationObserver: MutationObserver;
    const mutationObservable$ = new Observable<void>((subscriber) => {
      mutationObserver = new MutationObserver(() => {
        subscriber.next();
      });
      mutationObserver?.observe(ref, {
        childList: true,
        subtree: true
      });
    });
    return mutationObservable$.pipe(
      tap(() => {
        const el = (ref?.body as HTMLElement)?.querySelector(
          '.close-icon-container'
        ) as HTMLElement;
        if (el) {
          el.style.display = 'none';
        }
      }),
      finalize(() => {
        mutationObserver?.disconnect();
      })
    );
  }
  public getMutationObserverInitializationLogic$(
    liveCallerWrapper: ElementRef<HTMLElement>
  ) {
    return interval(100).pipe(
      map(() => {
        const ref = (
          (liveCallerWrapper?.nativeElement as HTMLElement)?.querySelector(
            '.lc-frame-widget'
          ) as HTMLIFrameElement
        )?.contentDocument as Document;
        return [
          (ref?.children[0] as HTMLElement)?.querySelector(
            '.close-icon-container'
          ) as HTMLElement,
          ref
        ];
      }),
      distinctUntilChanged()
    );
  }
}
