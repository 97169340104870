import { Action, createReducer, on } from '@ngrx/store';

import { accountActions, authActions } from '../actions';
import { PersonalData } from '../../entity';
import * as AppState from '../../../state/app.state';

export interface State extends AppState.State {
  account: PersonalData;
}

const reducer = createReducer(
  {} as PersonalData,
  on(accountActions.getUserInfoSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    accountBalance: state.accountBalance
  })),
  on(accountActions.getIdentificationStatus, (state, action) => ({
    ...state,
    identificationActive: action.payload
  })),
  on(accountActions.getVerificationActive, (state, action) => ({
    ...state,
    verificationActive: action.payload
  })),
  on(accountActions.getUserDocumentSuccess, (state, action) => ({
    ...state,
    userDocument: action.payload
  })),
  on(authActions.signOut, (state, action) => {
    return {} as PersonalData;
  }),
  on(accountActions.updateBalanceSuccess, (state, action) => ({
    ...state,
    accountBalance: action.payload?.balance,
    lastBalanceCheck: action.payload?.lastBalanceCheck
  })),
  on(accountActions.getUserAvatarSuccess, (state, action) => ({
    ...state,
    ...action.payload
  }))
);

export function getAccountReducer(
  state: PersonalData | undefined,
  action: Action
) {
  return reducer(state, action);
}
