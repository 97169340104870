<crc-shared-pate-play-odometer-presentation
  [odometerData]="odometerData"
  [odometers]="odometerConfig"
  [odometerCount]="patePlayOdometerCount"
  [jackpot]="jackpot"
  [vertical]="vertical"
  [disableLogo]="disableLogo"
  [isMainPage]="isMainPage"
  (refreshOdometer)="refreshOdometer.emit()"
>
</crc-shared-pate-play-odometer-presentation>
