import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IconName } from '../svg';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'crc-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  @Input() name: IconName;
  @Input() color = 'white';
  @Input() fullSize = false;
  @Input() maxWidthHeight = { width: '100%', height: '100%' };
  @Input() styleObject: { [key: string]: string } | null;

  @Input() size: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL' | 'XXXL' | string = 'M';
}
