<div
  class="popup-container"
  [class.bonus-popup]="bonusSpace"
  data-cy="popup-container"
  #contRef
>
  <div
    [ngClass]="{
      'popup--sm': size === 'sm',
      'popup--lg': size === 'lg',
      'popup--custom-lg': size === 'custom-lg',
      'popup--xl': size === 'xl',
      'popup--custom-xl': size === 'custom-xl'
    }"
    class="popup"
    data-cy="popup"
    @scaleInAndOut
  >
    <div class="popup__header popup-header" data-cy="popup-header">
      <ng-container *ngIf="bonusSpace && bonusSpaceAction === 'history'">
        <button (click)="bonusBack.emit()" class="bonus-back-button">
          <img
            src="/assets/icons/features/crm/missions/arrow-left.svg"
            alt="arrow"
          />
        </button>
      </ng-container>
      <div class="popup-header__title" data-cy="popup-header-title">
        {{ title }}
        <ng-content select="[header]"></ng-content>
      </div>
      <ng-container [ngSwitch]="popupAction">
        <ng-container *ngIf="!bonusSpace; else bonusClose">
          <div
            *ngSwitchCase="'close'"
            (click)="onCloseModal()"
            class="popup-header__close"
            data-cy="popup-header-close"
          >
            <img
              alt="close button"
              class="popup-header__close--position"
              data-cy="popup-header-close-position"
              src="assets/icons/gameSearch/cancel.svg"
            />
          </div>
        </ng-container>
        <ng-template #bonusClose>
          <div class="icon-wrapper" (click)="onCloseModal()">
            <img src="/assets/icons/shapes/bonus-close.svg" alt="close" />
          </div>
        </ng-template>
        <div
          *ngSwitchCase="'logout'"
          (click)="logOut.emit(); isTooltipVisibility = false"
          (mouseenter)="isTooltipVisibility = true"
          (mouseleave)="isTooltipVisibility = false"
          class="popup-header__close"
          data-cy="popup-header-close"
        >
          <img
            alt="close button"
            class="popup-header__close--position"
            data-cy="popup-header-close-position"
            src="/assets/icons/popup/log-out.svg"
          />
          <div
            class="popup-header__close-tooltip"
            *ngIf="isTooltipVisibility"
            data-cy="profile-copy-tooltip"
          >
            <span>{{ 'user.log_out' | translate }}</span>
          </div>
        </div>
        <div
          *ngSwitchCase="'back'"
          class="popup-header__close popup-header__close--type-back"
          data-cy="popup-header-close"
        >
          <img
            alt="close button"
            class="popup-header__close--position"
            data-cy="popup-header-close-position"
            src="/assets/icons/popup/back-step.svg"
            (click)="back.emit(); isTooltipVisibility = false"
            (mouseenter)="isTooltipVisibility = true"
            (mouseleave)="isTooltipVisibility = false"
          />
          <div
            class="popup-header__close-tooltip"
            *ngIf="isTooltipVisibility && popupAction !== 'logout'"
            data-cy="profile-copy-tooltip"
          >
            <span>{{ 'user.back' | translate }}</span>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="popup__body popup-body"
      [class.bonus-body-fixed]="bonusSpaceAction === 'history'"
      data-cy="popup-body"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
