<div class="keyboard">
  <div
    (click)="keyboardClick.emit(keyboard.value)"
    *ngFor="let keyboard of commonKeyboard"
    class="keyboard__item"
  >
    {{ keyboard.display }}
  </div>
  <div
    (click)="keyboardClick.emit(offKey)"
    class="keyboard__item item-off"
    [class.small-item-off]="offKey === 'forgot'"
  >
    {{ offKey | translate }}
  </div>
  <div (click)="keyboardClick.emit('0')" class="keyboard__item">0</div>
  <div (click)="keyboardClick.emit('backspace')" class="keyboard__item">
    <img src="assets/icons/clear_icon.svg" alt="*" />
  </div>
</div>
