export type FastTrackNotificationType =
  | 'login'
  | 'Verification_failed'
  | 'failed_deposit'
  | 'success_deposit'
  | 'failed_withdrawal'
  | 'slot_page'
  | 'sport_page'
  | 'UFO_page'
  | 'Casino_page'
  | 'change_password'
  | 'favorite_leagues'
  | 'registration';

export type FastTrackMessageType = 'CUSTOM' | 'LOGIN_V2' | 'USER_CREATE_V2';

export const getFastTrackMessageType = (
  notificationType: FastTrackNotificationType
): FastTrackMessageType => {
  return notificationType === 'login'
    ? 'LOGIN_V2'
    : notificationType === 'registration'
    ? 'USER_CREATE_V2'
    : 'CUSTOM';
};

export interface FastTrackItem {
  notification_type?: 'site_click' | string;
  message_type: FastTrackMessageType;
  user_id: string;
  origin: string;
  timestamp: string;
  data?: {
    page: FastTrackNotificationType;
  };
}
export interface fastTrackData {
  Data: FastTrackNotification[];
  Success: boolean;
  Errors: Array<unknown>[];
}

export interface FastTrackNotification {
  MessageId: number;
  UserId: number;
  Event: string;
  Title: string;
  Message: string;
  Data: CommunicationStatus;
  CTAButtonLink: string;
  CTAButtonText: string;
  CTAButton2Link: string;
  CTAButton2Text: string;
  FooterText?: string;
  ImageUrl: string;
  IsRead: boolean;
  DisplayType: string;
  Date: Date;
  ActivityId: number;
  ActionGroupId: number;
  ActionId: number;
  TriggerHash: string;
  removed?: boolean;
  isActive?: boolean;
  swipe?: boolean;
  viewed?: boolean;
  url?: string;
  toggle?: boolean;
}

export interface CommunicationStatus {
  OverrideCommunicationStatus: boolean;
}

export interface FastTrackEnv {
  crmUrl: string;
  fusionUrl: string;
  prismaUrl: string;
  pusherKey: string;
  pusherRegion: string;
}

export interface LoginAuthToken {
  Data: LoginAuthTokenData;
  Success: boolean;
  Errors: Array<unknown>[];
}

export interface LoginAuthTokenData {
  JwtToken: string;
  Authentication: FtAuthentication;
  User: FtUser;
}

export interface FtAuthentication {
  'IsAuthenticated:': boolean;
  AuthToken: string;
  LoginIP: string;
}

export interface FtUser {
  UserId: number;
  BrandId: number;
  IsTestUser: boolean;
}
