import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CaptchaPromptBaseComponent } from '@crc/components';

@Component({
  selector: 'crc-m-shared-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaptchaComponent extends CaptchaPromptBaseComponent {}
