import { Injectable } from '@angular/core';
import { PragmaticCasinoIntegrationService } from './pragmatic-casino-integration.service';
import { inject } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of, shareReplay } from 'rxjs';
import { CasinoFacade } from '../../facade';
@Injectable({
  providedIn: 'root'
})
export class PragmaticCasinoIntegrationFacade {
  private readonly pragmaticCasinoIntegrationService: PragmaticCasinoIntegrationService =
    inject(PragmaticCasinoIntegrationService);
  //=========================================================
  private readonly casinoFacade: CasinoFacade = inject(CasinoFacade);
  public readonly pragmaticPlayCasinoGames$ =
    this.casinoFacade.providersWithCasinos$.pipe(
      map((providers) =>
        providers
          .filter(({ name, provider }) => {
            return [provider.toLowerCase(), name.toLowerCase()].some(
              (str) => str.startsWith('pragmatic') && str.includes('casino')
            );
          })
          .pop()
      ),
      switchMap((provider) => {
        return this.pragmaticCasinoIntegrationService.getPragmaticCasinoFeedByGameIds$(
          provider.games.map((game) => game.remoteId).map((val) => String(val))
        ).dynamicDataMap$;
      }),
      shareReplay(),
      catchError(() => of(null))
      // this one is for logging
      // tap((map) => {
      //   nextTick(() => {
      //     const anyMap: Map<any, any> = new Map();
      //     [...map.values()].forEach((value) => {
      //       anyMap.set(value?.tableType, [
      //         ...(anyMap.get(value?.tableType) || []),
      //         value
      //       ]);
      //     });
      //     console.log(anyMap);
      //     console.log('\n\n\n\n\n\n');
      //     console.log(map);
      //     console.log('\n\n\n\n');
      //   });
      // })
    );
}
