<ng-container [ngSwitch]="loginMode$ | async">
<div *ngSwitchCase="'default'" class="cont">
  <crc-m-login-presentation
    [loginFailed]="loginFailed$ | async"
    [loginBlockedByUnknownEntity]="loginBlockedByUnknownEntity$ | async"
    [captcha]="captchaCode$ | async"
    [enteredCaptchaValid]="enteredCaptchaValid$ | async"
    [loginPopupShowState]="showAuthModal$ | async"
    [customerId]="customerId$ | async"
    [smsCodeVerified]="smsCodeVerified$ | async"
    [buttonLoaderState]="buttonLoaderState$ | async"
    [loginModeChangeDTO]="loginModeChangeDTO"
    (switchedLoginModeToOtp)="switchOTPState(true, $event)"
  ></crc-m-login-presentation>
</div>

<div *ngSwitchCase="'passcode'" class="passcode">
  <crc-m-use-passcode></crc-m-use-passcode>
</div>
  <crc-m-auth-otp-container
    *ngSwitchCase="'OTP'"
    [loginModeChangeDTO]="loginModeChangeDTO"
    (returnToDefaultProtocol)="switchOTPState(false, $event)"
  ></crc-m-auth-otp-container>
</ng-container>
