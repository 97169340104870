import { generateFilterItemViews } from '@crc/components';

import { SlotFilterTypeItem } from '../entity';

export const DEFAULT_SLOT_CATEGORY_FILTER = generateFilterItemViews(
  '1',
  'top_games',
  'assets/icons/features/slots/filters/top-slots.svg',
  undefined,
  'web:popular'
);

export const DEFAULT_SLOT_CATEGORY_FILTER_TYPE_ITEM: SlotFilterTypeItem = {
  filter: DEFAULT_SLOT_CATEGORY_FILTER.filter ?? '',
  filterType: 'category'
};
