import { Injectable } from '@angular/core';
import { getWindow } from '../../functions';
import { EnvironmentService } from '../../environment';
import { IMatomoWindow } from './interfaces/matomo-window.interface';
import { MATOMO_SRC_NAME } from './constants/matomo-script.config';
import { ScriptLoaderService } from '../script-loader';

@Injectable({
  providedIn: 'root'
})
export class MatomoService {
  private matomoInstance = getWindow() as unknown as IMatomoWindow;
  private isMatomoInitialized = false;

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly scriptLoaderService: ScriptLoaderService
  ) {
    this.setUpMatomo();
  }

  public trackEvent(
    category: string,
    action: string,
    name: string,
    value: number
  ): void {
    try {
      if (this.isMatomoInitialized) {
        this.matomoInstance._paq.push([
          'trackEvent',
          category,
          action,
          name,
          value
        ]);
      } else {
        this.loadAndInitializeMatomo(() => {
          this.matomoInstance._paq.push([
            'trackEvent',
            category,
            action,
            name,
            value
          ]);
        });
      }
    } catch (err) {
      console.warn(err);
    }
  }

  public setUserId(userId: string | number): void {
    try {
      this.matomoInstance._paq.push(['setUserId', userId]);
    } catch (err) {
      console.warn(err);
    }
  }

  private setUpMatomo(): void {
    try {
      if (this.scriptLoaderService.isScriptLoaded(MATOMO_SRC_NAME)) {
        this.initializeMatomo();
      } else {
        this.loadAndInitializeMatomo();
      }
    } catch (err) {
      console.warn('Failed to set up Matomo:', err);
    }
  }

  private loadAndInitializeMatomo(callback?: () => void): void {
    try {
      this.scriptLoaderService
        .getScriptLoadInstance(MATOMO_SRC_NAME)
        .subscribe({
          next: () => {
            this.initializeMatomo();
            if (callback) callback();
          },
          error: (err: Error) => {
            console.error('Failed to load Matomo script:', err);
          }
        });
    } catch (err) {
      console.warn('Failed to load Matomo:', err);
    }
  }

  private initializeMatomo(): void {
    try {
      if (this.isMatomoInitialized) return;

      const { matomoTrackingUrl } =
        this.environmentService.config.matomoConfiguration;
      this.matomoInstance._paq = this.matomoInstance._paq || [];
      this.matomoInstance._mtm = this.matomoInstance._mtm || [];

      this.matomoInstance._paq.push(['setCookieDomain', '*.crocobet.com']);
      this.matomoInstance._paq.push(['setTrackerUrl', matomoTrackingUrl]);
      this.matomoInstance._paq.push([
        'setSiteId',
        this.environmentService.config.matomoConfiguration.matomoSiteId
      ]);
      this.matomoInstance._paq.push(['trackPageView']);
      this.matomoInstance._paq.push(['enableLinkTracking']);
      this.isMatomoInitialized = true;
    } catch (err) {
      console.warn('Failed to initialize Matomo:', err);
    }
  }
  public trackCustomEvent(event: string): void {
    try {
      if (this.isMatomoInitialized) {
        this.matomoInstance._mtm.push({ event });
      } else {
        this.loadAndInitializeMatomo(() => {
          this.matomoInstance._mtm.push({ event });
        });
      }
    } catch (err) {
      console.warn(err);
    }
  }
}
