import { Injectable } from '@angular/core';
import { HttpService } from '@crc/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  LiveBigRaffle,
  Platform,
  RaffleSchedule
} from '../entity/live-big-raffle.interface';

@Injectable({
  providedIn: 'root'
})
export class LiveBigRaffleService {
  constructor(private http: HttpService) {}

  public getVideoLink(address: string): Observable<string> {
    const path = `/twitch/get/${address}`;
    const baseUrl = 'cmsApiUrl';
    return this.http.get$<LiveBigRaffle>({ path, baseUrl }).pipe(
      map((e) => {
        if (e['data'] && e['data']['twitchUrl'] && e['data']['enabled']) {
          return e.data.twitchUrl + '?autoplay=1&mute=1';
        } else {
          return null;
        }
      })
    );
  }

  public getRaffleSchedule(): Observable<string[]> {
    const path = '/campaigns/live-tv-promo-210122/raffle-schedule';
    const baseUrl = 'cmsApiUrl';
    return this.http.get$<RaffleSchedule>({ path, baseUrl }).pipe(
      map((res) => {
        return res.data.reduce(
          (dates, item) => dates.concat(item.dates),
          [] as string[]
        );
      })
    );
  }

  public getLiveButtonData<T>(platform: Platform): Observable<T[]> {
    const path = `/twitch/live-buttons?platform=${platform}`;
    const baseUrl = 'cmsApiUrl';
    return this.http
      .get$<{ data: T[] }>({ path, baseUrl })
      .pipe(map((res) => res.data));
  }
}
