import { createReducer, on } from '@ngrx/store';
import * as AppState from '@crc/facade';
import { SlotGame } from '@crc/facade';
import * as SlotsActions from '../actions/slots.actions';

export const slotsFeatureKey = 'slots';

export interface State extends AppState.State {
  slotsState: SlotsState;
}

export interface SlotsState {
  isBuyBonusActive: boolean;
  buyBonusData: BuyBonusData[];
}

export interface BuyBonusData {
  name: string;
  value: SlotGame[];
}

export const initialState: SlotsState = {
  buyBonusData: null,
  isBuyBonusActive: false
};

export const reducer = createReducer(
  initialState,
  on(SlotsActions.loadBuyBonusSuccess, (state, action): SlotsState => {
    return {
      ...state,
      buyBonusData: action.providers
    };
  }),
  on(SlotsActions.setBonusBuyState, (state, action): SlotsState => {
    return {
      ...state,
      isBuyBonusActive: action.status
    };
  })
);
