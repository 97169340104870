<div class="odometer-container" data-cy="odometer-container">
  <ng-container *ngIf="odometerData && odometerData.length === odometerCount">
    <div
      *ngFor="let odometer of odometers"
      [ngClass]="'odometer--level--' + odometer.level.replace('Level', '')"
      class="odometer odometer--position"
      data-cy="odometer"
    >
      <div
        class="odometer__icon odometer__icon--position"
        data-cy="odometer-icon"
      >
        <img
          alt=""
          src="assets/icons/shared/jackpot/{{ odometer.icon }}"
          data-cy="odometer-icon"
          loading="lazy"
          width="100%"
          height="18"
        />
      </div>
      <div
        class="odometer__wrapper odometer__wrapper--position not-selectable"
        data-cy="odometer-wrapper"
      >
        <crc-odometer-presentation
          (animationFinished)="
            odometer.listenToAnimation && emitRefreshOdometer()
          "
          [data]="odometerData[odometer.index]"
        ></crc-odometer-presentation>
        <div class="digit digit--position currency-gel" data-cy="digit">
          <p class="text-white" data-cy="text-white">
            <span class="el">G</span><span class="el">E</span
            ><span class="el">L</span>
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
