import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'crc-shared-bonus-special-button',
  templateUrl: './bonus-special-button.component.html',
  styleUrls: ['./bonus-special-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusSpecialButtonComponent {
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() isMobile: boolean;
  @Output() clicked = new EventEmitter<boolean>();
}
