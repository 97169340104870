import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import {
  AnalyticsService,
  FormBase,
  GtagEvent,
  handleMarkingControlsAsTouched,
  MatomoAnalyticsService
} from '@crc/shared';
import { NgSelectItem } from '@crc/components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, shareReplay, Subject, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'crc-m-personal-info-presentation',
  templateUrl: './personal-info-presentation.component.html',
  styleUrls: ['./personal-info-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class PersonalInfoPresentationComponent
  extends FormBase
  implements OnInit
{
  @Input() countries: NgSelectItem[];
  @Input() cities: NgSelectItem[];
  @Input() days: NgSelectItem[];
  @Input() months: NgSelectItem[];
  @Input() years: NgSelectItem[];
  @Input() cdaShowError: string | boolean;
  @Input() cdaNationalityShowError: string | boolean;
  form: UntypedFormGroup;
  selectedCountry = 'GE';
  isYearErrored: Observable<string>;
  allDatesAreSelected$: Observable<boolean>;
  $whenChanged = new Subject<void>();

  analyticsService: AnalyticsService = inject(AnalyticsService);
  matomoAnalyticsService: MatomoAnalyticsService = inject(
    MatomoAnalyticsService
  );
  constructor(private readonly controlContainer: ControlContainer) {
    super();
  }

  ngOnInit(): void {
    this.form = this.controlContainer.control as UntypedFormGroup;
    this.form
      .get('countryCode')
      .valueChanges.pipe(
        untilDestroyed(this),
        tap((countryCode) => {
          this.selectedCountry = countryCode;
        })
      )
      .subscribe();

    if (this.cdaShowError) {
      setTimeout(() => {
        this.form.get('documentId').setErrors({ cda: true });
        // this.form.get('year').setErrors({ cda: true });
      });
    }

    if (this.cdaNationalityShowError) {
      //===
      setTimeout(() => {
        if (this.cdaNationalityShowError === 'CITIZENSHIP_INCORRECT') {
          this.form.get('nationality').setErrors({ cda: true });
        } else if (this.cdaNationalityShowError === 'COUNTRY_INCORRECT') {
          this.form.get('countryCode').setErrors({ cda: true });
        } else {
          this.form.get('nationality').setErrors({ cda: true });
          this.form.get('countryCode').setErrors({ cda: true });
        }
      });
    }
    this.selectedCountry = this.form.get('countryCode').value;
    this.isYearErrored = this.form
      .get('year')
      .statusChanges.pipe(untilDestroyed(this), shareReplay());
    this.allDatesAreSelected$ = this.$whenChanged.asObservable().pipe(
      map(() => {
        return Boolean(
          Number(Boolean(this.form.get('year').value)) &
            Number(Boolean(this.form.get('month').value)) &
            Number(Boolean(this.form.get('day').value))
        );
      }),
      shareReplay()
    );
  }

  changeCdaError() {
    this.$whenChanged.next();
    this.cdaShowError = false;
    this.cdaNationalityShowError = false;
    this.form.get('documentId').updateValueAndValidity();
    this.form.get('year').updateValueAndValidity();
    this.form.get('nationality').updateValueAndValidity();
  }
  public markControlsAsTouched(): void {
    handleMarkingControlsAsTouched(this.form);
  }

  trackAnalytics(event: GtagEvent, value: string) {
    this.analyticsService.track({
      event,
      [event]: value
    });
  }
  trackMatomoAnalytics(name: string): void {
    this.matomoAnalyticsService.selectTrackingEvent(name);
  }

  //Todo simplify this
  controlBlurred($event: Event, controlName: string) {
    if (!this.form.get(controlName).value) {
      return;
    }
    if (controlName === 'documentId') {
      this.trackAnalytics(
        'registerDocumentId',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerDocumentId${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'firstName') {
      this.trackAnalytics(
        'registerFirstName',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerFirstName${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'lastName') {
      this.trackAnalytics(
        'registerLastName',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerLastName${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'phoneNumber') {
      this.trackAnalytics(
        'registerPhoneNumber',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerPhoneNumber${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'email') {
      this.trackAnalytics('registerEmail', this.form.get(controlName).status);
      this.trackMatomoAnalytics(
        `registerEmail${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'loginName') {
      this.trackAnalytics(
        'registerLoginName',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerLoginName${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'nationality') {
      this.trackAnalytics(
        'registerCitizenship',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerCitizenship${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'password') {
      this.trackAnalytics(
        'registerPassword',
        this.form.get(controlName).status
      );
      this.trackMatomoAnalytics(
        `registerPassword${this.form.get(controlName).status}`
      );
    }

    if (controlName === 'confirmPassword') {
      this.trackAnalytics(
        'registerConfirmPassword',
        this.form.get(controlName).status
      );
    }
  }
}
