export interface PatePlayOdometerConfigI {
  index: number;
  icon: string;
  listenToAnimation: boolean;
  level: string;
  isOpen?: boolean;
}

export const PatePlayOdometerConfig: PatePlayOdometerConfigI[] = [
  {
    index: 0,
    icon: 'red.webp',
    listenToAnimation: true,
    level: 'LevelI'
  },
  {
    index: 1,
    icon: 'blue.webp',
    listenToAnimation: false,
    level: 'LevelIV'
  },
  {
    index: 2,
    icon: 'green.webp',
    listenToAnimation: false,
    level: 'LevelIII'
  },
  {
    index: 3,
    icon: 'purple.webp',
    listenToAnimation: false,
    level: 'LevelII'
  }
]
