import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'crc-m-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {
  @Output() closeClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() toolTipClick: EventEmitter<void> = new EventEmitter<void>();
}
