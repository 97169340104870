import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileNavigationFacade {
  private readonly $popupClosing = new Subject<void>();
  popupClosing$ = this.$popupClosing.asObservable();

  defaultNavigation = 'user_menu';
  customSessionHandling: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private readonly $currentNav = new BehaviorSubject<string>(null);
  private $selected = new BehaviorSubject<string>(this.defaultNavigation);
  selected$ = this.$selected.asObservable().pipe(distinctUntilChanged());

  select(nav: string) {
    this.$selected.next(nav);
  }

  setCurrentNav(data: string) {
    this.$currentNav.next(data);
  }

  getCurrentNavValue(): string {
    return this.$currentNav.value;
  }

  reset() {
    this.$selected.next(this.defaultNavigation);
  }

  popupClosing() {
    this.$popupClosing.next();
  }
}
