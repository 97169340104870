<div class="input-container" [class.material]="material">
  <span *ngIf="currency" class="currency-icon">₾</span>
  <ng-content select="unshifted-icon"></ng-content>
  <input
    #inputElement
    [(ngModel)]="value"
    (input)="onChange(value)"
    (keyup)="keyUpped.emit($event)"
    (focus)="focused.emit($event)"
    (blur)="onTouched(); blurred.emit($event)"
    [type]="hideIcon && hideToggled ? 'text' : type"
    [autocomplete]="autoComplete"
    [mask]="mask"
    [attr.name]="name"
    [attr.id]="id"
    [placeholder]="(!material && placeholder) || ''"
    [class.error]="
      control?.dirty &&
      control?.touched &&
      control?.invalid &&
      (control?.value || control?.hasError('forceRequired'))
    "
    [class.pr-40]="hideIcon"
    [class.material]="material"
    [disabled]="disabled"
    [prefix]="prefix"
    class="input form-control"
    required
  />
  <label *ngIf="material" class="input-label">{{ placeholder }}</label>

  <button
    *ngIf="hideIcon"
    (click)="this.hideToggled = !this.hideToggled; eyeButtonClick.emit()"
    (mouseover)="mouseOver.emit($event)"
    (mouseout)="mouseOver.emit($event)"
    tabindex="-1"
    class="show-hide"
    type="button"
  >
    <img
      alt="Show/hide"
      src="assets/icons/layout/header/{{
        hideToggled || tooltipToggle ? 'eye-off' : 'eye-on'
      }}.svg"
    />
  </button>
</div>

<div class="message" data-cy="message">
  <ng-container *ngFor="let error of errorsTexts">
    <div
      *ngIf="control?.dirty && (touched || control?.touched) && control?.invalid && control?.errors?.[error.type] && (control?.value || control?.hasError('forceRequired'))"
      class="mt-1"
      [attr.data-cy]="error.type"
    >
      {{ error.text }}
    </div>
  </ng-container>
</div>
