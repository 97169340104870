import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  addQueryParamToUrl,
  BaseUrl,
  HttpService,
  Platform
} from '@crc/shared';

import {
  CategorySlotGames,
  ProviderSlotGames,
  SlotGame,
  SlotGameSearch
} from '../entity';
import { Provider } from '../../shared';

@Injectable({ providedIn: 'root' })
export class SlotsService {
  constructor(
    private readonly apiService: HttpService,
    // todo remove
    private readonly http: HttpClient
  ) {}

  // TODO fix pass platform = desktop when new_games' platform will be fixed in cms
  getCategoriesAndSlots(platform: Platform): Observable<CategorySlotGames[]> {
    const path = `/integrations/v2/slot/categories?include=games${
      platform === 'mobile' ? '&platform=mobile' : ''
    }`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: CategorySlotGames[] }>({ path, baseUrl })
      .pipe(map(({ data }) => data));
  }

  getProviderWithSlots(
    provider: string,
    platform: string,
    customerId: number,
    type: string = 'slot'
  ): Observable<ProviderSlotGames> {
    let path = `/integrations/v2/${type}/providers/${provider}?platform=${platform}`;
    if (customerId) {
      path += `&customerId=${customerId}`;
    }
    const baseUrl: BaseUrl = 'cmsApiUrl';

    return this.apiService
      .get$<{ data: ProviderSlotGames }>({ path, baseUrl })
      .pipe(map((d) => d.data));
  }

  getSlotSearchGames(
    platform: Platform,
    searchInput: string
  ): Observable<SlotGameSearch[]> {
    const path = `/integrations/search?type=slot&platform=${platform}&search=${searchInput}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: SlotGameSearch[] }>({ path, baseUrl })
      .pipe(map((data) => data.data));
  }

  getSlotGameFrameUrl(
    url: string,
    isMobile: boolean
  ): Observable<string | null> {
    let urlPlat = url.replace('customerid={USERID}&', '').replace('www', 'api');
    if (isMobile) {
      urlPlat = addQueryParamToUrl(urlPlat, [
        { key: 'ismobile', value: 'true' }
      ]);
    }
    return this.http
      .get<string>(urlPlat, {
        responseType: 'text' as 'json',
        withCredentials: true
      })
      .pipe(
        map((res) => {
          try {
            JSON.parse(res);
            return null;
          } catch (e) {
            return res;
          }
        })
      );
  }

  getSlotProviders(
    platform: Platform,
    customerId: number
  ): Observable<Provider[]> {
    let path = `/integrations?type=slot&platform=${platform}`;
    if (customerId) {
      path += `&customerId=${customerId}`;
    }

    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<any>({ path, baseUrl })
      .pipe(map((result) => result.data));
  }

  getFavorites(): Observable<SlotGame[]> {
    const path = '/integrations/v2/slot/user/favorites';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: { game: SlotGame; provider: Provider }[] }>({
        path,
        baseUrl
      })
      .pipe(
        map((slots) =>
          slots.data.map((slot) => {
            return { ...slot.game, providerName: slot.provider.name };
          })
        ),
        catchError((err) => of([]))
      );
  }

  getHistory(): Observable<SlotGame[]> {
    const path = '/integrations/v2/slot/user/history';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: { game: SlotGame; provider: Provider }[] }>({
        path,
        baseUrl
      })
      .pipe(
        map((slots) =>
          slots.data.map((slot) => {
            return { ...slot.game, providerName: slot.provider.name };
          })
        ),
        catchError((err) => of([]))
      );
  }

  addToHistory(providerId: string, gameId: string) {
    const path = `/integrations/v2/slot/user/history?providerId=${providerId}&gameId=${gameId}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.post$({ path, baseUrl, body: {} });
  }

  addToFavorites(providerId: string, gameId: string) {
    const path = `/integrations/v2/slot/user/favorites?providerId=${providerId}&gameId=${gameId}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.post$({ path, baseUrl, body: {} });
  }

  removeFromFavorites(providerId: string, gameId: string) {
    const path = `/integrations/v2/slot/user/favorites?providerId=${providerId}&gameId=${gameId}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.delete$({ path, baseUrl, body: {} });
  }
}
