import { SplitScreenTypesEnum } from './game-type';

export const slotDefaultHeight = 600;

export const everymatrixDefaultHeight = 600;
export const everymatrixDefaultWidth = 953;

export const everymatrixDefaultRatio =
  everymatrixDefaultHeight / everymatrixDefaultWidth;

export const igrosoftDefaultRatio = 9 / 16;

export const egtLobbyMessage = 'com.egt-bg.exit';
export const egtReloadMessage = 'com.egt-bg.reload';
export const exitMessage = 'exit';
export const ctGaming = 'ct-gaming';
export const playNGo = 'playngo';
export const patePlay = 'PatePlay';
export const multiplayerHeaderHeight = 60;

export const filterViewItemDefault = [
  {
    name: 'slots',
    filter: '1'
  },
  {
    name: 'casino',
    filter: '2'
  }
];

export const multiplayerOptions = [
  {
    name: 'gamePlay.unary_fun',
    squares: new Array(1).fill(''),
    splitScreenType: SplitScreenTypesEnum.OneFrame
  },
  {
    name: 'gamePlay.double_fun',
    squares: new Array(2).fill(''),
    splitScreenType: SplitScreenTypesEnum.TwoFrames
  },
  {
    name: 'gamePlay.quadruple_fun',
    squares: new Array(4).fill(''),
    splitScreenType: SplitScreenTypesEnum.FourFrames
  }
];
