export enum OpenStatus {
  Open = 1,
  ClosesSoon = 2,
  Closed = 3
}

export enum HolidayTypes {
  None,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
  Weekend
}

export enum CashierWeekdays {
  AllWeek,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday
}
