<div class="copy-text-img">
  <img
    *ngIf="valueIsHidden"
    [cbContent]="cbContent"
    [style.width]="imgSize + 'px'"
    alt="copy"
    class="copy-button"
    data-cy="copy-button"
    ngxClipboard
    src="assets/icons/profile/profile_info/copy_icon.svg"
  />
</div>
