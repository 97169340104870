import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { of, throttleTime } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  AnalyticsService,
  Cookie,
  CookieService,
  MatomoAnalyticsService,
  nextTick
} from '@crc/shared';

import { accountActions, authActions, captchaActions } from '../actions';
import { AuthService, SessionService, UsernameStorage } from '../../services';
import { AccountFacade, AuthFacade, LoginPopupFacade } from '../../facade';
import {
  FastTrackFacade,
  IdentificationFacade,
  IdentificationTypes,
  SmsIdentificationService,
  UserRestrictionsFacade
} from '../../../features';
import { AuthState, PersonalData, Restricted } from '../../entity';
import { NotVerifiedGuard } from '../../guards';
import { SignedInFeatureState } from '../state';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class AuthEffects {
  checkSms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.checkSms),
      throttleTime(1000),
      switchMap(({ payload }) => {
        return this.authService.login$(payload).pipe(
          map((response) => {
            const condition =
              !response.success && response.errorCode === '2FA_CODE_REQUIRED';

            if (condition) {
              // TODO fix this
              this.authFacade.setCustomerId(1);
              return authActions.sendSmsCodeSuccess();
            } else {
              return authActions.signInPostCheck({ payload: response });
            }
          }),
          catchError((response) =>
            of(authActions.signInPostCheck({ payload: response.error }))
          )
        );
      })
    );
  });

  verifySmsCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.verifySmsCode),
      throttleTime(1000),
      concatLatestFrom(() => this.authFacade.getCustomerId()),
      switchMap(([action, customerId]) => {
        if (customerId) {
          return this.authService.loginWithSmsCode$(action.payload).pipe(
            map((response) => {
              this.authFacade.setSmsCodeVerified(response.success);

              return authActions.signInPostCheck({
                payload: { ...response, twoFA: true }
              });
            }),
            catchError((error) => {
              this.authFacade.setSmsCodeVerified(false);
              if (
                error?.error?.errorCode === Restricted.BLOCKED_BY_RS_GE ||
                error?.error?.errorCode === Restricted.BELOW_LEGAL_AGE
              ) {
                this.authFacade.setButtonLoaderState(false);
                this.authFacade.setSmsCodeVerified(true);
                setTimeout(() => {
                  this.authFacade.setRestrictedStatus(
                    Restricted.BLOCKED_BY_RS_GE
                  );
                }, 300);
              }
              return of(authActions.verifySmsCodeError());
            })
          );
        }
        return of(authActions.verifySmsCodeError());
      })
    );
  });

  signInPostCheck$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.signInPostCheck),
      // todo god knows how to fix it
      debounceTime(500),
      switchMap(({ payload }) => {
        const { success, data, twoFA } = payload;
        if (String(payload) === Restricted.BELOW_LEGAL_AGE) {
          this.authFacade.setButtonLoaderState(false);
          this.authFacade.setSmsCodeVerified(true);
          setTimeout(() => {
            this.authFacade.setRestrictedStatus(Restricted.BLOCKED_BY_RS_GE);
          }, 300);
        }
        if (success) {
          if (twoFA) {
            this.authFacade.setSmsCodeVerified(true);
          }

          const payload: PersonalData = data;
          this.analyticsService.setUserId(payload.userId);
          this.matomoAnalyticsService.setUserId(payload.userId);
          // Google Analytics Event Tracking for Login

          this.analyticsService.track({
            action: 'Login',
            event: 'login',
            ['login_status']: 'success'
          });
          // Matomo Analytics Event Tracking for Login
          this.matomoAnalyticsService.selectTrackingEvent('loginTotalSuccess');
          this.usernameStorage.setUsername(payload.login);
          this.fastTrackFacade.track('login').subscribe();

          return [
            authActions.signInSuccess(),
            accountActions.updateUser({ payload }),
            accountActions.getUserInfo(),
            captchaActions.closeCaptchaModal()
          ];
        } else {
          if (
            [Restricted.BELOW_LEGAL_AGE, Restricted.BLOCKED_BY_RS_GE].includes(
              payload.errorCode as Restricted
            )
          ) {
            this.authFacade.setButtonLoaderState(false);
            this.authFacade.setRestrictedStatus(
              payload.errorCode as Restricted
            );
            return [authActions.signInRestricted()];
          } else if (twoFA) {
            this.authFacade.setSmsCodeVerified(false);
            return of(authActions.verifySmsCodeError());
          } else {
            return [authActions.signInError({ payload: payload })];
          }
        }
      }),
      catchError((payload) => of(authActions.signInError(payload)))
    );
  });

  signInSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.signInSuccess),
      tap(() => this.authFacade.setLoginStatus$(AuthState.LOGGED_IN)),
      mergeMap(() => {
        if (this.loginGuardFacade.getShowLoginPopupState()) {
          this.loginGuardFacade.loginSuccess();

          const alreadyVisited: Cookie = {
            name: 'alreadyVisited',
            value: 'true'
          };
          this.cookieService.set(alreadyVisited);
        } else if (this.router.url.includes('register')) {
          this.router.navigateByUrl('/').then();
        }

        return this.accountFacade.isVerified$.pipe(
          take(1),
          mergeMap((isVerified) => {
            if (!isVerified) {
              this.verifiedGuard.redirectToVerification().then();
              return [];
            } else {
              this.identificationService.setPopupActionState('close');
              return [accountActions.updateBalanceEvery5Second()];
            }
          })
        );
      })
    );
  });

  signInError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.signInError),
      debounceTime(300),
      map((data) => {
        const payload = data?.payload;
        payload?.errorCode === 'CAPTCHA_REQUIRED'
          ? this.authFacade.setCaptchaState(true)
          : this.authFacade.setCaptchaState(false);
        if (data.payload?.errorCode === 'BLOCKED_BY_UNKNOWN_ENTITY') {
          this.authFacade.setLoginStatus$(AuthState.BLOCKED_BY_UNKNOWN_ENTITY);
        } else {
          this.authFacade.setLoginStatus$(AuthState.LOGIN_FAILED);
        }

        // Google Analytics Event Tracking for Login
        this.analyticsService.track({
          event: 'login',
          ['login_status']: 'fail'
        });
        // Matomo Analytics Event Tracking for Login
        this.matomoAnalyticsService.selectTrackingEvent('loginTotalFail');
        return captchaActions.updateCaptchaCode({
          payload: payload?.captcha || ''
        });
      })
    );
  });

  signOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.signOut),
      debounceTime(300),
      switchMap(() => {
        return this.authService.signOut$();
      }),
      tap(() => {
        this.authFacade.setLoginStatus$(AuthState.LOGGED_OUT);
        this.authFacade.setCustomerId(null);
        this.sessionService.signOut();
        localStorage.removeItem('IdentificationState');
        sessionStorage.removeItem('timerStartTime');
        this.fastTrackFacade.setFastTrackAllNotificationData(null);
        const loginRequiredRoutes = [
          '/profile/',
          '/mini-games/UFO',
          '/mini-games/SpaceTaxi',
          '/mini-games/plinko',
          '/mini-games/pascal-gaming',
          '/casino/play', // player in web
          '/slots/play', // player in web
          '/slot-casino', // multiplayer in web
          '/launcher' // player in mobile
        ];

        loginRequiredRoutes.forEach((route) => {
          if (this.router.url.includes(route)) {
            this.router.navigateByUrl('/').then();
          }
        });
      }),
      map(() => authActions.signOutSuccess()),
      tap(() => {
        this.identificationFacade.setIdentification$(IdentificationTypes.null);
        this.accountFacade.setVerificationActive(false);
        nextTick(() => {
          this.userRestrictionsFacade.setLatestUserRestrictionsDTO([]);
        });
      })
    );
  });

  constructor(
    private readonly store$: Store<SignedInFeatureState>,
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly authFacade: AuthFacade,
    private readonly loginGuardFacade: LoginPopupFacade,
    private readonly sessionService: SessionService,
    private readonly accountFacade: AccountFacade,
    private readonly verifiedGuard: NotVerifiedGuard,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly analyticsService: AnalyticsService,
    private readonly matomoAnalyticsService: MatomoAnalyticsService,
    private readonly cookieService: CookieService,
    private readonly usernameStorage: UsernameStorage,
    private readonly fastTrackFacade: FastTrackFacade,
    private readonly identificationService: SmsIdentificationService,
    private readonly identificationFacade: IdentificationFacade,
    private readonly userRestrictionsFacade: UserRestrictionsFacade
  ) {}
}
