import { Injectable } from '@angular/core';
import { BaseUrl, HttpService } from '@crc/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Card } from '../entity';

@Injectable({
  providedIn: 'root'
})
export class CardsService {
  constructor(private readonly apiService: HttpService) {}

  getCreditCards(providerID: number): Observable<Card[]> {
    const path = `/rest/payment/creditCard/list/${providerID}`;
    const baseUrl: BaseUrl = 'apiUrl';
    return this.apiService
      .get$<{ data: Card[] }>({ path, baseUrl })
      .pipe(map((result) => result.data));
  }

  removeCreditCard(creditCardId: number): Observable<Card[]> {
    const path = `/rest/payment/creditCard/update`;
    const baseUrl: BaseUrl = 'apiUrl';
    const body = {
      creditCardId,
      activeCard: false
    };

    return this.apiService.post$({ path, baseUrl, body });
  }
}
