export function getUfoCrashesByColors(
  multiplier: number | string | null,
  isLast: boolean
): string {
  if (typeof multiplier !== 'number' || multiplier > 9.99) {
    return isLast ? 'cont--color3 cont--color3--last' : 'cont--color3';
  }
  if (multiplier > 0.99 && multiplier < 1.5) {
    return isLast ? 'cont--color1 cont--color1--last' : 'cont--color1';
  }
  if (multiplier > 1.49 && multiplier < 10) {
    return isLast ? 'cont--color2 cont--color2--last' : 'cont--color2';
  }
}

export function getColorBySymbol(symbol: number, isLast: boolean): string {
  switch (symbol) {
    case 1:
      return isLast ? 'cont--color1 cont--color1--last' : 'cont--color1';
    case 2:
      return isLast ? 'cont--color2 cont--color2--last' : 'cont--color2';
    case 3:
      return isLast ? 'cont--color3 cont--color3--last' : 'cont--color3';
    case 4:
      return isLast ? 'cont--color4 cont--color4--last' : 'cont--color4';
    default:
      return isLast ? 'cont--color1 cont--color1--last' : 'cont--color1';
  }
}
