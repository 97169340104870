export const replaceLanguageKeyInString = (
  value: string,
  key1: string,
  key2: string
): string => {
  return value.replace(key1, key2);
};

export const replaceLastIndex = (
  value: string,
  key1: string,
  key2: string
): string => {
  const index = value.lastIndexOf(key1);

  return value.substr(0, index) + key2 + value.substr(index + key1.length);
};
