export const invalidSessionDescriptions = [
  'Invalid customer session',
  'The session is invalid or already expired.',
  'Invalid admin session'
];
export interface IInvalidSessionResponseBody {
  code: (typeof InvalidSessionResponseCodeEnum)[keyof typeof InvalidSessionResponseCodeEnum];
  description?: string;
  origin?: keyof typeof InvalidSessionResponseOriginEnum;
}
export const InvalidSessionResponseOriginEnum = {
  GATEWAY: 'GATEWAY'
} as const;
export const InvalidSessionResponseCodeEnum = {
  UNRECOVERABLE_ERROR: 456,
  MISDIRECTED_REQUEST: 421,
  INVALID_ATTRIBUTE: 476
} as const;
