import { createAction, props } from '@ngrx/store';
import { BuyBonusData } from '../reducers/slots.reducer';

export const loadBuyBonus = createAction('[Slots] Load Buy Bonus');

export const loadBuyBonusSuccess = createAction(
  '[Slots] Load Buy Bonus Success',
  props<{ providers: BuyBonusData[] }>()
);

export const setBonusBuyState = createAction(
  '[Slots] Set Bonus Buy State',
  props<{ status: boolean }>()
);
