<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="control" #smsCode>
    <crc-m-shared-input
      [class.error]="submitted && form.get('verificationCode').errors"
      [placeholder]="'sms_code' | translate"
      [errorsTexts]="[
        { type: 'required', text: 'fill_required_fields' | translate },
        {
          type: 'minlength',
          text: 'enter_minimum_characters_count' | translate: { count: 6 }
        },
        {
          type: 'maxlength',
          text: 'enter_maximum_characters_count' | translate: { count: 10 }
        }
      ]"
      (focused)="onFocus($event)"
      [inputMode]="'decimal'"
      autoComplete="one-time-code"
      name="smsCode"
      formControlName="verificationCode"
      data-cy="form-smsCode"
      mask="0000000000"
    >
      <div class="timer">
        <crc-m-timer-button
          [initiateStartOnInit]="true"
          *ngIf="{
            isDisabled: false
          } as timerParams"
          (timerStarted)="onTimerStart($event,timerParams.isDisabled)"
          [timeAmount]="61"
          [textInput]="
            'resend' | translate
          "
          [disabled]="timerParams.isDisabled"
        />
      </div>
    </crc-m-shared-input>
  </div>

  <div class="control">
    <crc-m-shared-input
      (focused)="onFocus($event)"
      [class.error]="submitted && form.get('password').errors"
      [errorsTexts]="[
        {
          type: 'latin',
          text: 'use_only_latin_letters' | translate
        }
      ]"
      [placeholder]="'password' | translate"
      formControlName="password"
      name="new-password"
      [type]="isPassword ? 'password' : 'text'"
      autoComplete="new-password"
    >
      <div (click)="isPassword = !isPassword" class="eye__icon">
        <crc-m-shared-status-icon
          path="/assets/icons/profile/{{
            isPassword ? 'openEye' : 'closedEye'
          }}.svg"
          alt="ref"
        >
        </crc-m-shared-status-icon>
      </div>
    </crc-m-shared-input>
  </div>

  <div class="validator">
    <crc-password-validator
      [errors]="form.get('password').errors"
      [showUserNameAndPasswordMatchValidation]="false"
    ></crc-password-validator>
  </div>

  <div class="control">
    <crc-m-shared-input
      (focused)="onFocus($event)"
      [class.error]="
        (submitted && form.get('confirmPassword').errors) ||
        form.get('confirmPassword').hasError('IsLongerThan')
      "
      [placeholder]="'repeat_password' | translate"
      [errorsTexts]="[
        { type: 'match', text: 'passwords_dont_match' | translate },
        { type: 'IsLongerThan', text: 'passwords_dont_match' | translate }
      ]"
      formControlName="confirmPassword"
      name="new-password-confirm"
      type="password"
      autoComplete="new-password"
    ></crc-m-shared-input>
  </div>

  <crc-m-shared-button
    [type]="form.valid ? 'active' : 'secondary'"
    [disable]="form.invalid || isSubmitAlreadyClicked"
  >
    {{ 'password_recovery' | translate }}
  </crc-m-shared-button>
</form>
