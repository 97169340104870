import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { CustomValidators, matchControlsValidator } from '@crc/components';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordFormHelperFacade {
  constructor(private readonly fb: UntypedFormBuilder) {}

  buildPhoneFormControls() {
    return this.fb.group({
      mobile: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.patternValidator(/^[5][0-9]{8}$/, {
          pattern: true
        })
      ])
    });
  }

  buildSubmitFormControls() {
    const passwordPatterns: ValidatorFn[] = [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(20),
      CustomValidators.patternValidator(/\d/, { number: true }),
      CustomValidators.patternValidator(/[A-Z]/, { upperCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { lowerCase: true }),
      CustomValidators.patternValidator(/^[ -~]+$/, {
        latin: true
      })
    ];

    return this.fb.group(
      {
        password: new UntypedFormControl('', Validators.compose(passwordPatterns)),
        confirmPassword: new UntypedFormControl('', Validators.required),
        verificationCode: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20)
        ])
      },
      {
        validators: [
          matchControlsValidator(
            { controlName: 'password', addError: false },
            { controlName: 'confirmPassword', addError: true }
          )
        ]
      }
    );
  }
}
