<div class="calculator" [class.web]="!isMobile" [class.shake]="activeShake">
  <div *ngIf="!isMobile" class="calculator__text">
    {{ 'bonus.choose_amount' | translate }}
  </div>
  <div class="calculator__points-container">
    <div
      class="calculator__points"
      [class.cursor-pointer]="!isMobile"
      *ngFor="let item of [].constructor(5); let i = index"
      [ngStyle]="{
        'background-image':
          activeIndex === i
            ? 'url(/assets/icons/features/bonus-coin/button-active.webp)'
            : 'url(/assets/icons/features/bonus-coin/button.webp)'
      }"
      [class.inactive]="balance < i + 1 || rate === null"
      (click)="onPointClick(i)"
    >
      <div class="calculator__points-inner-circle">
        {{ i + 1 }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="isMobile; else web">
    <div class="calculator__change">
      <ng-container *ngIf="activeIndex === null; else result">
        <div class="calculator__change-example">
          {{ 'bonus.example' | translate }}: {{ rate ? rate : 1 }}
          {{ bonusType | translate }} =
          {{ activeIndex + 1 }}
          {{ 'bonus.currency' | translate }}
        </div>
      </ng-container>
      <ng-template #result>
        <div class="calculator__change-example">
          {{ (activeIndex + 1) * rate }} {{ bonusType | translate }} =
          {{ activeIndex + 1 }} {{ 'bonus.currency' | translate }}
        </div>
      </ng-template>
      <crc-shared-bonus-special-button
        (clicked)="onSpecialButtonClick($event, activeIndex + 1)"
        [text]="'bonus.exchange' | translate"
        [disabled]="rate === null || activeIndex === null || disabledButton"
        [isMobile]="isMobile"
      ></crc-shared-bonus-special-button>
    </div>
  </ng-container>
  <ng-template #web>
    <div class="calculator__change change-web">
      <ng-container *ngIf="activeIndex === null; else result">
        <div class="calculator__change-example">
          {{ 'bonus.example' | translate }}: {{ rate ? rate : 1 }}
          {{ bonusType | translate }} =
          {{ activeIndex + 1 }}
          {{ 'bonus.currency' | translate }}
        </div>
      </ng-container>
      <ng-template #result>
        <div class="calculator__change-example">
          {{ (activeIndex + 1) * rate }} {{ bonusType | translate }} =
          {{ activeIndex + 1 }} {{ 'bonus.currency' | translate }}
        </div>
      </ng-template>
    </div>
    <crc-shared-bonus-special-button
      (clicked)="onSpecialButtonClick($event, activeIndex + 1)"
      [text]="'bonus.exchange' | translate"
      [disabled]="rate === null || activeIndex === null || disabledButton"
      [isMobile]="isMobile"
    ></crc-shared-bonus-special-button>
  </ng-template>
  <div *ngIf="!isMobile" class="calculator__history">
    <span (click)="changeStepClicked.emit('history')">{{
      'history' | translate
    }}</span>
  </div>
</div>
