import { Injectable } from '@angular/core';
import { DepositService } from '../services/deposit.service';
import {
  BehaviorSubject,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import {
  PaymentForm,
  PaymentFormCard,
  PaymentProcess,
  PaymentType,
  WithdrawForm
} from '../entity/deposit.interface';
import { DepositCardsStorage } from '../storage/deposit-cards.storage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BankProvider,
  BankProvidersData,
  PaymentProcessType,
  PaymentProviderResponse,
  PaymentProvidersById,
  PaymentProvidersByKey,
  PaymentRequestObj
} from '../entity/payment-providers.config';
import {
  AnalyticsService,
  EnvironmentService,
  isApplePayApplicable,
  LanguageFacade,
  MatomoAnalyticsService,
  replaceLastIndex
} from '@crc/shared';
import { PaymentFormHelperService } from '../services/payment-form-helper.service';
import { catchError, map } from 'rxjs/operators';
import { Card } from '../../cards';
import {
  BankProviderTypes,
  CreditCardTypeByProvider
} from '../entity/credit-card.types';
import { UntypedFormGroup } from '@angular/forms';
import { LimitsFacade } from './limits.facade';
import { ButtonInterface } from '../entity/button.interface';
import { DEFAULT_DEPOSIT_BUTTONS } from '../entity/deposit-amount.constant';
import { DepositActiveModeEnum, DepositActiveModeUnionType } from '@crc/core';
import { ApplePayDepositFacade } from './apple-pay-deposit.facade';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class DepositFacade {
  public readonly IS_APPLE_PAY_APPLICABLE: boolean = isApplePayApplicable();
  public readonly DEPOSIT_DEFAULT_ACTIVE_MODE: DepositActiveModeUnionType =
    DepositActiveModeEnum.CREDIT_CARD;
  public readonly $isDepositPopupOpenCurrently: Subject<boolean> =
    new Subject();
  private $showDepositDialogState: Subject<boolean> = new Subject<boolean>();
  private readonly $isCardsModified: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  private readonly $paymentIframe: Subject<string> = new Subject<string>();
  readonly currentLanguage$: Observable<string> = this.languageService.current$;
  public readonly handleCrocobetUrl = this.env.config.handleCrocobetUrl;
  showDepositDialogState$: Observable<boolean> =
    this.$showDepositDialogState.asObservable();
  private readonly $paymentProcessType: BehaviorSubject<PaymentProcessType> =
    new BehaviorSubject<PaymentProcessType>(null);
  private readonly $depositActiveMode =
    new BehaviorSubject<DepositActiveModeUnionType>(
      this.DEPOSIT_DEFAULT_ACTIVE_MODE
    );

  private readonly $invalidCard = new BehaviorSubject<boolean>(false);
  private readonly $invalidAmount = new BehaviorSubject<boolean>(false);
  private readonly $isSmsPopupState = new BehaviorSubject<boolean>(false);
  $bogIsDisabled = new BehaviorSubject(false);
  $tbcIsDisabled = new BehaviorSubject(false);
  private readonly $paymentStateAfterClick = new BehaviorSubject(false);

  constructor(
    private readonly depositService: DepositService,
    private readonly depositCardStorage: DepositCardsStorage,
    private readonly languageService: LanguageFacade,
    private readonly paymentFormHelper: PaymentFormHelperService,
    private readonly analyticsService: AnalyticsService,
    private readonly matomoAnalyticsService: MatomoAnalyticsService,
    private readonly languageFacade: LanguageFacade,
    private readonly limitsFacade: LimitsFacade,
    private readonly env: EnvironmentService,
    private readonly applePayDepositFacade: ApplePayDepositFacade
  ) {}

  setPaymentStateAfterClick(state: boolean): void {
    this.$paymentStateAfterClick.next(state);
  }

  getPaymentStateAfterClick$(): Observable<boolean> {
    return this.$paymentStateAfterClick.asObservable();
  }

  setSmsPopupState(state: boolean) {
    this.$isSmsPopupState.next(state);
  }

  getSmsPopupState(): Observable<boolean> {
    return this.$isSmsPopupState;
  }

  updateIsCardsModified(state: boolean) {
    this.$isCardsModified.next(state);
  }

  getIsCardModified$(): Observable<boolean> {
    return this.$isCardsModified.asObservable();
  }

  updatePaymentIframe(src: string) {
    this.$paymentIframe.next(src);
  }

  setPaymentType(type: PaymentProcessType) {
    this.$paymentProcessType.next(type);
  }

  getPaymentProcessTypeValue(): PaymentProcessType {
    return this.$paymentProcessType.value;
  }

  getPaymentIframe(): Observable<string> {
    return this.$paymentIframe.asObservable();
  }

  setShowDepositDialogState(state: boolean) {
    this.$showDepositDialogState.next(state);
  }

  initialPaymentRequest(
    requestBody: PaymentRequestObj | WithdrawForm
  ): Observable<PaymentProviderResponse> {
    return this.depositService.initialPaymentRequest(requestBody);
  }

  processPaymentRequest(
    requestBody: PaymentRequestObj | PaymentProcess
  ): Observable<PaymentProviderResponse> {
    return this.depositService.processPaymentRequest(requestBody);
  }

  getPaymentAmountButtons(type: string): Observable<ButtonInterface> {
    return this.depositService
      .paymentAmountButtons(type)
      .pipe(catchError(() => of(DEFAULT_DEPOSIT_BUTTONS)));
  }

  getBankProviders(): Observable<BankProvidersData[]> {
    return this.depositService.getBankProviders();
  }

  initPaymentForm(paymentType: PaymentType) {
    return this.paymentFormHelper.initPaymentForm(
      paymentType,
      this.getMathMinOfWithdrawMin(),
      this.getMathMinOfDepositMin()
    );
  }

  getMaxValidatorValue(
    isWithdraw: boolean,
    hasCardAttached: boolean,
    isBog: boolean,
    bogMaxWithdraw: number,
    tbcMaxWithdraw: number,
    bogMaxDeposit: number,
    tbcMaxDeposit: number
  ): number {
    return isWithdraw
      ? isBog
        ? bogMaxWithdraw
        : hasCardAttached
        ? tbcMaxWithdraw
        : bogMaxWithdraw
      : isBog
      ? bogMaxDeposit
      : hasCardAttached
      ? tbcMaxDeposit
      : bogMaxDeposit;
  }

  getMinValidatorValue(
    isWithdraw: boolean,
    isBog: boolean,
    withdrawMinBog: number,
    withdrawMinTbc: number,
    depositMinBog: number,
    depositMinTbc: number
  ): number {
    return isWithdraw
      ? isBog
        ? withdrawMinBog
        : withdrawMinTbc
      : isBog
      ? depositMinBog
      : depositMinTbc;
  }

  processWithdrawForm(
    selectedCard: Card,
    transactionAmount: number,
    isWithdraw = false
  ): WithdrawForm {
    return {
      accountId: selectedCard.creditCardId,
      isAnyBank: false,
      transactionCurrency: 'GEL',
      providerId:
        selectedCard?.paymentProvider === 'BANK_OF_GEORGIA_PAYOUT'
          ? PaymentProvidersById.BOG_WITHDRAW
          : PaymentProvidersById.TBC_WITHDRAW,
      transactionAmount: +transactionAmount,
      ...(isWithdraw ? { paymentType: 'WITHDRAW' } : {})
    };
  }

  processWithdraw(withdrawForm: WithdrawForm) {
    return this.initialPaymentRequest(withdrawForm).pipe(
      untilDestroyed(this),
      map(() => {
        const paymentProcess: PaymentProcess = {
          accountId: withdrawForm.accountId,
          callbackUrl:
            'https://www.crocobet.com/%23/profile/manage-money/deposit',
          providerId: withdrawForm.providerId,
          transactionCurrency: withdrawForm.transactionCurrency,
          transactionAmount: withdrawForm.transactionAmount,
          paymentType: 'WITHDRAW'
        };

        return paymentProcess;
      }),
      switchMap((paymentProcess) => {
        return this.processPaymentRequest(paymentProcess);
      }),
      tap(() => {
        // this.analyticsService.track('Withdraw', 'Withdraw', 0)
        // Google Analytics event for withdraw
        this.analyticsService.track({
          event: 'Withdraw',
          action: 'Withdraw'
        });
      })
    );
  }

  setPaymentFormValidations(
    paymentForm: UntypedFormGroup,
    isBog: boolean,
    isWithdraw: boolean,
    maxValidatorValue: number,
    withdrawMinBog: number,
    withdrawMinTbc: number,
    depositMinBog: number,
    depositMinTbc: number
  ) {
    this.paymentFormHelper.setPaymentFormValidations(
      paymentForm,
      isWithdraw,
      maxValidatorValue,
      isBog,
      withdrawMinBog,
      withdrawMinTbc,
      depositMinBog,
      depositMinTbc
    );
  }

  isBog(paymentProvider: BankProviderTypes) {
    return (
      paymentProvider === 'BANK_OF_GEORGIA_PAYOUT' ||
      paymentProvider === 'BANK_OF_GEORGIA_DEPOSIT'
    );
  }

  initDepositForm(transactionAmount: number, selectedCard: Card): PaymentForm {
    return selectedCard?.paymentProvider
      ? {
          providerId: CreditCardTypeByProvider[selectedCard?.paymentProvider],
          transactionAmount: transactionAmount,
          creditCardId: selectedCard?.creditCardId
        }
      : ({} as PaymentForm);
  }

  handleProviderListResponse(response: BankProvidersData[]) {
    const result: BankProvider[] = [];
    let tbcProvider: BankProvider = null;
    let bogProvider: BankProvider = null;

    response.forEach((provider) => {
      const {
        minAmount = 0,
        maxTotalAmountOnAttempts = 0,
        fee = 0
      } = provider.limits || {};

      switch (provider.providerId) {
        case PaymentProvidersById.TBC_DEPOSIT:
          tbcProvider = this.createProvider(
            PaymentProvidersByKey.TBC_DEPOSIT,
            'TBC',
            minAmount,
            maxTotalAmountOnAttempts,
            provider?.providerDisabled
          );
          result[1] = tbcProvider;
          break;

        case PaymentProvidersById.BOG_DEPOSIT:
          bogProvider = this.createProvider(
            PaymentProvidersByKey.BOG_DEPOSIT,
            'BOG',
            minAmount,
            maxTotalAmountOnAttempts,
            provider?.providerDisabled
          );
          result[2] = bogProvider;
          break;

        case PaymentProvidersById.BOG_WITHDRAW:
          this.setBogWithdrawLimits(minAmount, maxTotalAmountOnAttempts, fee);
          break;

        case PaymentProvidersById.TBC_WITHDRAW:
          this.setTbcWithdrawLimits(minAmount, maxTotalAmountOnAttempts);
          break;
      }
    });

    result[0] = this.createAnyDepositProvider(tbcProvider, bogProvider);

    this.updateDisabledStates(tbcProvider, bogProvider);
    this.updateLimits(tbcProvider, bogProvider);

    return result;
  }

  createProvider(
    key: string,
    logo: string,
    minAmount: number,
    maxTotalAmountOnAttempts: number,
    providerDisabled: boolean
  ) {
    return { key, logo, minAmount, maxTotalAmountOnAttempts, providerDisabled };
  }

  setBogWithdrawLimits(
    minAmount: number,
    maxTotalAmountOnAttempts: number,
    fee: number
  ) {
    this.limitsFacade.setBogMaxWithdraw(maxTotalAmountOnAttempts);
    this.limitsFacade.setBogWithdrawMin(minAmount);
    this.limitsFacade.setPayoutFee(fee);
  }

  setTbcWithdrawLimits(minAmount: number, maxTotalAmountOnAttempts: number) {
    this.limitsFacade.setTbcMaxWithdraw(maxTotalAmountOnAttempts);
    this.limitsFacade.setTbcWithdrawMin(minAmount);
  }

  createAnyDepositProvider(
    tbcProvider: BankProvider,
    bogProvider: BankProvider
  ) {
    if (tbcProvider?.providerDisabled && bogProvider?.providerDisabled) {
      return this.createProvider(
        PaymentProvidersByKey.ANY_DEPOSIT,
        'ANY',
        Math.min(tbcProvider.minAmount, bogProvider.minAmount),
        Math.min(
          tbcProvider.maxTotalAmountOnAttempts,
          bogProvider.maxTotalAmountOnAttempts
        ),
        true
      );
    } else if (tbcProvider?.providerDisabled) {
      return this.createProvider(
        PaymentProvidersByKey.BOG_DEPOSIT,
        'ANY',
        bogProvider.minAmount,
        bogProvider.maxTotalAmountOnAttempts,
        bogProvider?.providerDisabled
      );
    } else if (bogProvider?.providerDisabled) {
      return this.createProvider(
        PaymentProvidersByKey.TBC_DEPOSIT,
        'ANY',
        tbcProvider.minAmount,
        tbcProvider.maxTotalAmountOnAttempts,
        tbcProvider?.providerDisabled
      );
    } else {
      return this.createProvider(
        PaymentProvidersByKey.ANY_DEPOSIT,
        'ANY',
        Math.min(tbcProvider.minAmount, bogProvider.minAmount),
        Math.min(
          tbcProvider.maxTotalAmountOnAttempts,
          bogProvider.maxTotalAmountOnAttempts
        ),
        false
      );
    }
  }

  updateDisabledStates(tbcProvider: BankProvider, bogProvider: BankProvider) {
    this.$tbcIsDisabled.next(tbcProvider?.providerDisabled);
    this.$bogIsDisabled.next(bogProvider?.providerDisabled);
  }

  updateLimits(tbcProvider: BankProvider, bogProvider: BankProvider) {
    this.limitsFacade.setTbcMax(tbcProvider.maxTotalAmountOnAttempts);
    this.limitsFacade.setTbcDepositMin(tbcProvider.minAmount);

    this.limitsFacade.setBogMax(bogProvider.maxTotalAmountOnAttempts);
    this.limitsFacade.setBogDepositMin(bogProvider.minAmount);
  }

  processDepositPaymentObject(
    paymentForm: PaymentForm,
    savedCard?: boolean
  ): PaymentRequestObj {
    const form = paymentForm;
    const requestBody: PaymentFormCard = { card: {} };

    if (savedCard) {
      if (
        form.providerId === CreditCardTypeByProvider.BANK_OF_GEORGIA_DEPOSIT
      ) {
        requestBody.accountId = form.creditCardId;
      } else {
        requestBody.card.creditCardId = form.creditCardId;
      }
    } else {
      requestBody.card.activeCard = form.activateCard;
    }

    const language =
      this.languageFacade.current === 'tr' ? 'en' : this.languageFacade.current;

    return {
      ...requestBody,
      providerId: PaymentProvidersById[form.providerId],
      transactionAmount: form.transactionAmount,
      transactionCurrency: 'GEL',
      callbackUrl:
        `${this.handleCrocobetUrl}#/deposit-success?lang=${language}`.replace(
          '#',
          '%23'
        )
    };
  }

  processPayment(paymentRequestObj: PaymentRequestObj) {
    return this.initialPaymentRequest(paymentRequestObj).pipe(
      untilDestroyed(this),
      mergeMap((response) => {
        if (response['externalRequestUrl']) {
          return of(response);
        } else {
          return this.processPaymentRequest(paymentRequestObj);
        }
      }),
      tap((response) => {
        let iframeSrc = response?.externalRequestUrl
          ? replaceLastIndex(response.externalRequestUrl, 'success', 'error')
              ?.replace('page1', 'page1/')
              ?.replace('ka', this.languageFacade.current)
          : `https://ecommerce.ufc.ge/ecomm2/ClientHandler?trans_id=${response.remoteTransactionId}`;

        if (!response?.success) {
          iframeSrc = `https://handle.crocobet.com/#/deposit-error?lang=${
            this.languageFacade.current === 'tr'
              ? 'en'
              : this.languageFacade.current
          }`;
        }

        this.updatePaymentIframe(iframeSrc);
      }),
      tap(() => {
        // Google Analytics event for deposit
        this.analyticsService.track({
          event: 'deposit_started',
          action: 'Deposit'
        });
        //matomo analytics for deposit started
        this.matomoAnalyticsService.selectTrackingEvent('depositStarted');
      })
    );
  }

  getMathMinOfMaxDeposit() {
    return Math.min(
      this.limitsFacade.getBogMaxValue$(),
      this.limitsFacade.getTbcMaxValue$()
    );
  }

  getMathMinOfDepositMin() {
    return Math.min(
      this.limitsFacade.getTbcDepositMin$(),
      this.limitsFacade.getBogDepositMin$()
    );
  }

  getMathMinOfWithdrawMin() {
    return Math.min(
      this.limitsFacade.getTbcWithdrawMin$(),
      this.limitsFacade.getBogWithdrawMin$()
    );
  }

  getInvalidCard$(): Observable<boolean> {
    return this.$invalidCard.asObservable();
  }

  setInvalidCard(state: boolean) {
    this.$invalidCard.next(state);
  }

  getInvalidAmount$(): Observable<boolean> {
    return this.$invalidAmount.asObservable();
  }

  setInvalidAmount(state: boolean) {
    this.$invalidAmount.next(state);
  }
  public getDepositActiveMode$() {
    return this.$depositActiveMode.asObservable();
  }
  public getDepositActiveMode() {
    return this.$depositActiveMode.getValue();
  }
  public setDepositActiveMode(mode: DepositActiveModeUnionType) {
    this._interceptDepositActiveModeChange(mode);
    this.$depositActiveMode.next(mode);
  }
  private _interceptDepositActiveModeChange(
    inComing: DepositActiveModeUnionType
  ): void {
    const current = this.getDepositActiveMode();
    if (
      inComing !== DepositActiveModeEnum.APPLE_PAY &&
      current === DepositActiveModeEnum.APPLE_PAY
    ) {
      this.applePayDepositFacade.resetApplePayState$();
    }
  }
}
