<section class="bonus" [class.history]="bonusSteps === 'history'">
  <ng-container [ngSwitch]="bonusSteps">
    <div *ngSwitchCase="'action'">
      <div class="bonus__header">
        <div class="bonus__header-coin">
          <img src="/assets/icons/features/bonus-coin/coin.webp" alt="coin" />
        </div>
        <p class="bonus__header-text">{{ 'bonus.balance' | translate }}</p>
        <div class="bonus__header-number">{{ bonusBalanceData?.balance }}</div>
        <img
          src="/assets/icons/features/bonus-coin/bonus-coin.svg"
          alt="bonus-coin"
        />
      </div>
      <div class="bonus__swiper web-margin">
        <div class="bonus__text swiper--text">
          {{ 'bonus.choose_promotion' | translate }}
        </div>
        <crc-shared-bonus-coin-swiper
          [isMobile]="isMobile"
          [cards]="bonusBalanceData?.exchangeOptions"
          [activeBalanceId]="activeBalanceId"
          (rateChange)="rate = $event"
          (selectedBalance)="toBalanceID($event)"
          (SwiperCardClick)="onSwiperCardClick($event)"
          class="bonus__swiper-ref"
        >
        </crc-shared-bonus-coin-swiper>
      </div>
      <div class="bonus__calculator">
        <div *ngIf="isMobile" class="bonus__text">
          {{ 'bonus.choose_amount' | translate }}
        </div>
        <crc-shared-bonus-calculator
          (changeStepClicked)="changeStepClicked.emit($event)"
          [balance]="bonusBalanceData?.balance"
          [rate]="rate"
          [isMobile]="isMobile"
          [disabledButton]="disableButton"
          [bonusType]="bonusType"
          (bonusExchangeClicked)="onBonusExchange($event)"
        ></crc-shared-bonus-calculator>
      </div>
      <div *ngIf="isMobile" class="bonus__history">
        <span (click)="changeStepClicked.emit('history')">{{
          'history' | translate
        }}</span>
      </div>
    </div>
    <div *ngSwitchCase="'result'">
      <ng-container *ngIf="resultType; else incorrect">
        <div class="bonus__content-box">
          <div class="bonus__result-circle success">
            <img
              src="/assets/icons/features/bonus-coin/suc-checked.svg"
              alt="success"
            />
          </div>
          <p class="bonus__content-box-inner-text">
            {{
              'bonus.reward_in_bonus'
                | translate
                  : {
                      count: exchangeResult?.coinAmount ?? '',
                      reward: exchangeResult?.coinType ?? ''
                    }
            }}
            <br />
            "{{ exchangeResult?.promotionName | translate }}"
          </p>
          <p class="bonus__content-box-under-text">
            {{
              'bonus.time_to_use_bonus'
                | translate
                  : {
                      time:
                        exchangeResult?.expireDate
                        | date : 'dd-MM-yyyy hh:mm' ?? ''
                    }
            }}
          </p>
          <crc-shared-bonus-special-button
            class="bonus__btn-success"
            [text]="'play' | translate"
            [isMobile]="isMobile"
            (clicked)="onPlayClick(exchangeResult?.landingUrl)"
          ></crc-shared-bonus-special-button>
        </div>
      </ng-container>
      <ng-template #incorrect>
        <div class="bonus__content-box">
          <div class="bonus__result-circle error">
            <img
              src="/assets/icons/features/bonus-coin/failed.svg"
              alt="success"
            />
          </div>
          <p class="bonus__content-box-inner-text">
            <ng-container *ngIf="resultType === null; else otherError">
              {{ 'bonus.account_limit_error' | translate }}
            </ng-container>
            <ng-template #otherError>
              {{ 'bonus.unsuccessful_operation' | translate }}
            </ng-template>
          </p>
          <button
            (click)="changeStepClicked.emit('action'); disableButton = false"
            class="bonus__btn-success"
          >
            {{ 'verification.try_again' | translate }}
          </button>
        </div>
      </ng-template>
    </div>
    <div *ngSwitchCase="'history'">
      <crc-shared-bonus-history
        (nextPage)="nextPage.emit()"
        [historyData]="historyData"
      ></crc-shared-bonus-history>
    </div>
  </ng-container>
</section>
