export type PaymentType = 'deposit' | 'withdraw' | 'depositPopup';

export enum PaymentTypeEnum {
  deposit = 'deposit',
  withdraw = 'withdraw',
  popup = 'depositPopup'
}

export type PaymentSteps = 'externalForm' | 'internalForm' | 'validateCardSave';

export interface CardModel {
  name: string;
  id: number;
}

export interface DepositAmount {
  value: string;
  id: number;
}

export interface PaymentForm {
  providerId: string | any;
  transactionAmount: number;
  transactionCurrency?: string;
  callbackUrl?: string;
  activateCard?: boolean;
  creditCardId?: number;
  paymentType?: 'WITHDRAW';
}

export interface PaymentFormCard {
  accountId?: number;
  card?: {
    activeCard?: boolean;
    creditCardId?: number;
  };
}

export interface WithdrawForm {
  accountId: number;
  isAnyBank: boolean;
  providerId: number;
  transactionAmount: number;
  transactionCurrency: string;
  paymentType?: 'WITHDRAW';
}

export interface PaymentProcess {
  accountId: number;
  callbackUrl: string;
  providerId: number;
  transactionCurrency: string;
  transactionAmount: number;
  paymentType?: 'WITHDRAW';
}
