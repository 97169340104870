import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  finalize,
  forkJoin,
  from,
  Observable,
  of,
  switchMap
} from 'rxjs';

import {
  fastTrackData,
  FastTrackItem,
  FastTrackNotification,
  FastTrackNotificationType,
  getFastTrackMessageType
} from '../entity/fast-track-item';
import { FastTrackService } from '../services/fast-track.service';
import {
  AccountFacade,
  AuthFacade,
  AuthStorage,
  SignedInResponse
} from '../../../auth';
import { map, take, tap } from 'rxjs/operators';
import { EnvironmentService } from '@crc/shared';
import { formatRFC3339 } from 'date-fns';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IFasttrackCrm } from '../entity/fast-track-crm';
import { NoopFastTrack } from '../classes/noop-fast-track';
import { EventTypes } from '../entity/crm-items';

declare const window: {
  sid: string;
  FasttrackCrm: IFasttrackCrm;
} & Window;

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class FastTrackFacade {
  private fastTrackPromise: Promise<IFasttrackCrm> | null = null;
  private readonly $ftAllData = new BehaviorSubject<fastTrackData>(null);
  private readonly $ftUnreadDataLength = new BehaviorSubject<number>(null);

  private readonly $loading = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly fastTrackService: FastTrackService,
    private readonly accountFacade: AccountFacade,
    private readonly env: EnvironmentService,
    private readonly authFacade: AuthFacade,
    private readonly authStorage: AuthStorage
  ) {
    this.handleSessionChange();
  }

  track(type: FastTrackNotificationType): Observable<void> {
    return this.env.config.fastTrackApi
      ? this.accountFacade.customerId$.pipe(
          filter((customerId) => !!customerId),
          take(1),
          switchMap((customerId) => {
            const fastTrackMessageType = getFastTrackMessageType(type);
            const trackItem: FastTrackItem = {
              // eslint-disable-next-line camelcase
              notification_type: 'site_click',
              // eslint-disable-next-line camelcase
              message_type: fastTrackMessageType,
              timestamp: formatRFC3339(new Date()),
              // eslint-disable-next-line camelcase
              user_id: customerId?.toString(),
              origin: window.location.origin,
              data: {
                page: type
              }
            };
            if (fastTrackMessageType !== 'CUSTOM') {
              delete trackItem.data;
              delete trackItem.notification_type;
            }
            return this.fastTrackService.track(trackItem);
          })
        )
      : of(void 0);
  }

  private handleSessionChange(): void {
    this.authFacade
      .isLoggedIn$()
      .pipe(
        untilDestroyed(this),
        filter((isLoggedIn: boolean) => isLoggedIn),
        switchMap(() =>
          combineLatest([
            of(JSON.parse(this.authStorage.getLoginData())),
            from(this.getFastTrack())
          ])
        ),
        filter(
          ([loginData, fastTrackInstance]: [SignedInResponse, IFasttrackCrm]) =>
            !!loginData && !!fastTrackInstance
        ),
        switchMap(
          ([loginData, fastTrackInstance]: [
            SignedInResponse,
            IFasttrackCrm
          ]) => {
            window.sid = loginData?.sessionHash;
            this.$loading.next(true);
            return this.fastTrackService.getFTData(window.sid).pipe(
              switchMap((loginAuth) =>
                this.getFastTrackData(
                  loginAuth?.Data?.Authentication?.AuthToken
                ).pipe(
                  tap(([unreadData, allData]) => {
                    this.$ftUnreadDataLength.next(
                      this.notificationsFilterByEvent(unreadData?.Data)?.length
                    );
                    this.$ftAllData.next(allData);
                    fastTrackInstance?.init();
                  })
                )
              ),
              finalize(() => this.$loading.next(false))
            );
          }
        )
      )
      .subscribe();
  }

  notificationsFilterByEvent(
    notifications: FastTrackNotification[]
  ): FastTrackNotification[] {
    return notifications?.filter(
      (data) =>
        data.Event !== EventTypes.SHOUTOUT && data.Event !== EventTypes.MESSAGE
    );
  }

  getFastTrackData(token: string): Observable<[fastTrackData, fastTrackData]> {
    const url = this.fastTrackService.getFusionUrl();
    return forkJoin([
      this.fastTrackService.getNotifications(url, token, true),
      this.fastTrackService.getNotifications(url, token, false)
    ]);
  }

  isLoading$(): Observable<boolean> {
    return this.$loading.asObservable();
  }

  deleteFtMessage(id: number) {
    return this.fastTrackService.deleteFtMessage(id);
  }

  markAsRead(id: number) {
    return this.fastTrackService.markAsRead(id);
  }

  setFastTrackAllNotificationData(data: fastTrackData) {
    this.$ftAllData.next(data);
  }

  getFastTrackAllNotificationData(): Observable<FastTrackNotification[]> {
    return this.$ftAllData.asObservable().pipe(map((data) => data?.Data || []));
  }

  getFastTrackUnreadNotificationData(): Observable<number> {
    return this.$ftUnreadDataLength.asObservable();
  }

  setFastTrackUnreadNotificationsDataValue(value: number) {
    this.$ftUnreadDataLength.next(value);
  }

  private getFastTrack(): Promise<IFasttrackCrm> {
    if (this.fastTrackPromise) {
      return this.fastTrackPromise;
    }

    if (window?.FasttrackCrm) {
      return (this.fastTrackPromise = Promise.resolve(window?.FasttrackCrm));
    }

    this.fastTrackPromise = new Promise<IFasttrackCrm>((resolve) => {
      window.addEventListener('load', function handleWindowLoad() {
        resolve(window?.FasttrackCrm || new NoopFastTrack());
      });
    });

    return this.fastTrackPromise;
  }
}
