<div
  class="odometer-container"
  [class.odometer-container__right]="!isMainPage"
  [class.vertical]="vertical"
  data-cy="odometer-container"
>
  <ng-container *ngIf="odometerData && odometerData.length === odometerCount">
    <div
      *ngFor="let odometer of odometers; let i = index"
      [ngClass]="'odometer--level--' + odometer.level.replace('Level', '')"
      class="odometer odometer--position"
      data-cy="odometer"
    >
      <div
        class="odometer__wrapper odometer__wrapper--position not-selectable"
        data-cy="odometer-wrapper"
      >
        <crc-pragmatic-odometer-presentation
          (animationFinished)="
            odometer.listenToAnimation && emitRefreshOdometer()
          "
          [data]="odometerData[odometer.index]"
        >
        </crc-pragmatic-odometer-presentation>

        <div class="digit digit--position currency-gel" data-cy="digit">
          <p class="text-white" data-cy="text-white">
            <span class="el">G</span><span class="el">E</span
            ><span class="el">L</span>
          </p>
        </div>
      </div>

      <div
        class="odometer__icon odometer__icon--position"
        data-cy="odometer-icon"
      >
        <img
          [class.small]="!largeIcon"
          src="assets/images/pragmatic/{{
            largeIcon ? odometer.icon : odometer.iconSmall
          }}"
          data-cy="odometer-icon"
          alt="odometer icon"
          loading="lazy"
          width="100%"
          height="18"
        />
      </div>

      <div class="odometer__images">
        <img
          *ngIf="i === 0"
          src="assets/images/pragmatic/grand.svg"
          alt="Grand"
          loading="lazy"
          width="35"
          height="7"
        />
        <img
          *ngIf="i === 1"
          src="assets/images/pragmatic/mega.svg"
          alt="Mega"
          loading="lazy"
          width="30"
          height="7"
        />
        <img
          *ngIf="i === 2"
          src="assets/images/pragmatic/major.svg"
          alt="Major"
          loading="lazy"
          width="33"
          height="7"
        />
        <img
          *ngIf="i === 3"
          src="assets/images/pragmatic/minor.svg"
          alt="Minor"
          loading="lazy"
          width="32"
          height="7"
        />
      </div>
    </div>
  </ng-container>

  <img
    *ngIf="!disableLogo"
    class="odometer-container__logo"
    src="assets/images/jackpot/pragmatic-logo.webp"
    alt="jackpot logo"
    loading="lazy"
  />
</div>
