<a
  (click)="onClick(); $event.preventDefault()"
  data-cy="swiper-slide-link"
  [target]="image.target"
  class="link"
  href="#"
  [attr.aria-label]="image?.alt ? image.alt : 'swiper link'"
>
  <ng-container
    *ngIf="
      image?.imageSet && image?.imageSet?.original && image?.imageSet?.webp;
      else noImgSet
    "
  >
    <picture>
      <source
        type="image/webp"
        [srcset]="lazy ? '' : image?.imageSet?.webp"
        [attr.data-srcset]="lazy ? image?.imageSet?.webp : ''"
      />
      <img
        data-cy="swiper-slide-image"
        class="link__img"
        [src]="lazy ? '' : image?.imageSet?.original"
        [attr.data-src]="lazy ? image?.imageSet?.original : ''"
        [class.link__img--fill]="imageFill"
        [class.swiper-lazy]="lazy"
        alt="swiper image"
        loading="lazy"
      />
    </picture>
  </ng-container>
  <ng-template #noImgSet>
    <img
      data-cy="swiper-slide-image"
      class="link__img"
      [src]="lazy ? '' : image?.src"
      [attr.data-src]="lazy ? image?.src : ''"
      [class.link__img--fill]="imageFill"
      [class.swiper-lazy]="lazy"
      alt="swiper image"
      loading="lazy"
    />
  </ng-template>
</a>
