import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  from,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
  toArray
} from 'rxjs';
import {
  BankProvider,
  DepositCardsStorage,
  PaymentProvidersById,
  PaymentType,
  PaymentTypeEnum
} from '../../deposit';
import { catchError, map } from 'rxjs/operators';
import { Card } from '../entity';
import { CardsService } from '../service/cards.service';
import { getBankIconName } from '../functions/card-functions';

@Injectable({
  providedIn: 'root'
})
export class CardsFacade {
  private readonly $selectedCard: BehaviorSubject<Card> =
    new BehaviorSubject<Card>(null);
  private readonly $selectedCardPopup: BehaviorSubject<Card> =
    new BehaviorSubject<Card>(null);
  private readonly $cards: Subject<Card[]> = new Subject<Card[]>();
  private readonly $depositCards: BehaviorSubject<Card[]> = new BehaviorSubject<
    Card[]
  >(null);
  private readonly $depositCardsPopup: Subject<Card[]> = new Subject<Card[]>();
  private readonly $withdrawCards: BehaviorSubject<Card[]> =
    new BehaviorSubject<Card[]>(null);
  private $selectedNewCard = new BehaviorSubject(null);
  private readonly $turnedCard: BehaviorSubject<Card> =
    new BehaviorSubject<Card>(null);
  constructor(
    private readonly cardsService: CardsService,
    private readonly depositCardStorage: DepositCardsStorage
  ) {}

  cardsUpdate(cards: Card[], type: PaymentType) {
    if (cards.length) {
      cards.forEach((card) => {
        if (card.cardNumber) {
          card.bankName = getBankIconName(+card.cardNumber?.slice(0, 6));
        }
      });
    }

    if (type === PaymentTypeEnum.deposit) {
      this.$depositCards.next(cards);
    } else if (type === PaymentTypeEnum.withdraw) {
      this.$withdrawCards.next(cards);
    } else if (type === PaymentTypeEnum.popup) {
      this.$depositCardsPopup.next(cards);
    }
  }

  setDepositCards$(card: Card[]) {
    this.$depositCards.next(card);
  }

  getDepositCards$(): Observable<Card[]> {
    return this.$depositCards.asObservable();
  }

  getDepositCardsPopup$(): Observable<Card[]> {
    return this.$depositCardsPopup.asObservable();
  }

  getWithdrawCards$(): Observable<Card[]> {
    return this.$withdrawCards.asObservable();
  }

  updateCards(cards: Card[]) {
    if (cards.length) {
      cards.forEach((card) => {
        if (card.cardNumber) {
          card.bankName = getBankIconName(+card.cardNumber?.slice(0, 6));
        }
      });
    }
    this.$cards.next(cards);
  }

  getCards$(): Observable<Card[]> {
    return this.$cards.asObservable();
  }

  updateSelectedCardByType(card: Card, type: PaymentType) {
    if (type === PaymentTypeEnum.deposit || type === PaymentTypeEnum.withdraw) {
      this.$selectedCard.next(card);
    } else if (type === PaymentTypeEnum.popup) {
      this.$selectedCardPopup.next(card);
    }
  }

  updateSelectedCard(card: Card) {
    this.$selectedCard.next(card);
  }

  getSelectedCard$(): Observable<Card> {
    return this.$selectedCard.asObservable();
  }

  updateSelectedCardPopup(card: Card) {
    this.$selectedCardPopup.next(card);
  }

  getSelectedCardPopup$(): Observable<Card> {
    return this.$selectedCardPopup.asObservable();
  }

  getModifiedCards(type: PaymentType): Observable<Card[]> {
    return this.getCardsByProviders(type).pipe(
      toArray(),
      map((cards) => {
        if (cards && cards.length) {
          cards = cards.sort((a, b) => b.creditCardId - a.creditCardId);
        }
        if (
          type === PaymentTypeEnum.deposit ||
          type === PaymentTypeEnum.popup
        ) {
          cards.unshift({} as Card);
        }
        return cards;
      })
    );
  }

  getCardsByProviders(type: PaymentType) {
    return type === PaymentTypeEnum.deposit || type === PaymentTypeEnum.popup
      ? merge(
          this.getBankProviderCards(PaymentProvidersById.BOG_DEPOSIT).pipe(
            switchMap((cards) => from(cards))
          ),
          this.getBankProviderCards(PaymentProvidersById.TBC_DEPOSIT).pipe(
            switchMap((cards) => from(cards))
          )
        )
      : merge(
          this.getBankProviderCards(PaymentProvidersById.BOG_WITHDRAW).pipe(
            switchMap((cards) => from(cards))
          ),
          this.getBankProviderCards(PaymentProvidersById.TBC_WITHDRAW).pipe(
            switchMap((cards) => from(cards))
          )
        );
  }

  getWithdrawCards(): Observable<Card[]> {
    return this.getWithdrawCards$().pipe(
      tap((cards) => this.updateCard(cards, 0))
    );
  }

  getDepositCards(): Observable<Card[]> {
    return this.getDepositCards$().pipe(
      tap((cards) => this.updateCard(cards, 1))
    );
  }

  updateCard(cards: Card[], index: number) {
    return this.updateSelectedCard(cards && cards.length ? cards[index] : null);
  }

  removeCreditCard(creditCardId: number): Observable<Card[]> {
    return this.cardsService.removeCreditCard(creditCardId);
  }

  getBankProviderCards(
    paymentProviderId: PaymentProvidersById
  ): Observable<Card[]> {
    return this.cardsService.getCreditCards(paymentProviderId);
  }

  updateCardName(name: string, creditCardID: number) {
    this.depositCardStorage.setCardName(name, creditCardID);
  }

  removeCardName(creditCardID: number) {
    this.depositCardStorage.removeCardName(creditCardID);
  }

  updateSelectedNewCard(provider: BankProvider) {
    this.$selectedNewCard.next(provider);
  }

  getSelectedNewCard$(): Observable<BankProvider> {
    return this.$selectedNewCard.asObservable();
  }
  updateTurnedCard(card: Card) {
    this.$turnedCard.next(card);
  }
  getTurnedCard(): Observable<Card> {
    return this.$turnedCard.asObservable();
  }
}
