import { inject, Injectable } from '@angular/core';
import { BaseUrl, HttpService } from '@crc/shared';
import { Observable } from 'rxjs';
import {
  UserRestrictions,
  UserRestrictionsDto
} from '../interface/user-restrictions-interfaces';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserRestrictionsService {
  private readonly apiService: HttpService = inject(HttpService);
  public storeSmsOffUserResponse$(
    status: 'ACCEPT' | 'REJECT',
    agreementText: string
  ): Observable<unknown> {
    const path = '/customers/agreements?agreement=SMSOFF';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.put$<
      unknown,
      { status: string; agreementText: string }
    >({
      path,
      baseUrl,
      body: {
        status,
        agreementText
      }
    });
  }
  public storeSmsOnUserResponse$(
    status: 'ACCEPT' | 'REJECT' | 'WAITING_FOR_VERIFICATION',
    agreementText: string,
    agreementCheckbox?: boolean,
    agreementFrom?: string
  ): Observable<unknown> {
    const path = '/customers/agreements?agreement=SMSON';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.put$<
      unknown,
      {
        status: string;
        agreementText: string;
        agreementCheckbox?: boolean;
        agreementFrom?: string;
      }
    >({
      path,
      baseUrl,
      body: {
        status,
        agreementText,
        agreementCheckbox,
        agreementFrom
      }
    });
  }

  public getSmsOnUserResponse$(): Observable<UserRestrictions> {
    const path = '/customers/agreements';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.get$<UserRestrictionsDto>({ path, baseUrl }).pipe(
      map((payload) => {
        return payload.data;
      })
    );
  }
  public storeSmsOnV2UserResponse$(
    status: 'ACCEPT' | 'REJECT',
    agreementText: string
  ): Observable<unknown> {
    const path = '/customers/agreements?agreement=SMSON_V2';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.put$<
      unknown,
      { status: string; agreementText: string }
    >({
      path,
      baseUrl,
      body: {
        status,
        agreementText
      }
    });
  }
}
