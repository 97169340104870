import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  Observable,
  switchMap
} from 'rxjs';

import { CookieService, EnvironmentService, LanguageFacade } from '@crc/shared';

import { SignedInFeatureState } from '../store/state';
import { AccountService, AuthStorage, SessionService } from '../services';
import { accountActions } from '../store/actions';
import { accountSelectors } from '../store/selectors';
import {
  AuthState,
  LiveCallerUser,
  PersonalData,
  personalInfo,
  VerificationStatus
} from '../entity';
import { map, tap } from 'rxjs/operators';
import { CatalogFacade, verificationStatusInterface } from '../../features';
import { AuthFacade } from './auth.facade';

export type protocolModel = 'selfie' | 'live' | null;
@Injectable({ providedIn: 'root' })
export class AccountFacade {
  private readonly $isVerified = new BehaviorSubject<boolean>(false);
  private readonly customVerificationStatusEmitter$ =
    new BehaviorSubject<verificationStatusInterface | null>(null);
  private readonly latestVerificationProtocol$ =
    new BehaviorSubject<protocolModel>(null);
  readonly personalData$: Observable<PersonalData> = this.store$.select(
    accountSelectors.selectPersonalData
  );
  readonly selectAccountBalance$: Observable<number> = this.store$.select(
    accountSelectors.selectAccountBalance
  );
  readonly selectPhoneNumber = this.store$.selectSignal(accountSelectors.selectPhoneNumber);
  readonly selectPhoneNumber$: Observable<string> = this.store$.select(
    accountSelectors.selectPhoneNumber
  );
  readonly selectEmail$: Observable<string> = this.store$.select(
    accountSelectors.selectEmail
  );
  readonly selectEmail = this.store$.selectSignal(accountSelectors.selectEmail);
  readonly verificationStatus$: Observable<VerificationStatus | undefined> =
    this.store$.select(accountSelectors.selectVerificationStatus).pipe(
      filter((v) => v !== undefined),
      distinctUntilChanged()
    );
  readonly isVerified$: Observable<boolean> = this.verificationStatus$.pipe(
    map((status) => status === VerificationStatus.VERIFIED),
    tap((status: boolean) => {
      this.$isVerified.next(status);
    })
  );
  readonly pinCode$: Observable<string> = this.store$.select(
    accountSelectors.selectPinCode
  );
  readonly customerId$: Observable<number> = this.store$.select(
    accountSelectors.selectCustomerId
  );
  readonly userName$: Observable<string> = this.store$.select(
    accountSelectors.selectUserName
  );
  readonly personalInfo$: Observable<personalInfo> =
    this.languageFacade.current$.pipe(
      switchMap((lang) =>
        combineLatest([
          this.isVerified$,
          this.personalData$,
          this.catalogFacade.getData$('country', lang)
        ]).pipe(
          map(([verification, personalData, countries]) => {
            const personalInfo: personalInfo = {
              username: personalData.loginName,
              pinCode: verification ? personalData.pinCode : '******',
              firstName: personalData.firstName,
              lastName: personalData.lastName,
              country: countries?.[personalData.countryCode],
              nationality: personalData?.nationality
                ? countries?.[personalData?.nationality]
                  ? countries?.[personalData?.nationality]
                  : personalData?.nationality
                : '',
              birthDate: personalData.dateOfBirthAsString,
              passportNumber: personalData.passportNumber,
              phoneNumber: '+ ' + personalData.mobileNumber?.replace(':', ' '),
              smsVerificationDate: personalData.smsVerificationDate,
              smsVerificationExpiresAt: personalData.smsVerificationExpiresAt,
              smsVerificationDaysLeft: personalData.smsVerificationDaysLeft
            };
            return personalInfo;
          })
        )
      )
    );

  constructor(
    private readonly store$: Store<SignedInFeatureState>,
    private readonly cookieService: CookieService,
    private readonly environmentService: EnvironmentService,
    private readonly accountService: AccountService,
    private readonly authStorage: AuthStorage,
    private readonly authFacade: AuthFacade,
    private readonly sessionService: SessionService,
    private readonly languageFacade: LanguageFacade,
    private readonly catalogFacade: CatalogFacade
  ) {}

  checkIfSessionIsAlive() {
    if (this.authStorage.getLoginData() && this.sessionService.getToken()) {
      this.authFacade.setLoginStatus$(AuthState.LOGGED_IN);
      const docData = this.authStorage.getDocumentData();
      if (docData) {
        this.store$.dispatch(
          accountActions.getUserDocumentSuccess({
            payload: docData
          })
        );
      }
      this.store$.dispatch(accountActions.findIfSessionAlive());
    }
  }

  updateUserDocument() {
    this.store$.dispatch(accountActions.getUserDocument());
  }

  getUserLiveCaller(body: LiveCallerUser) {
    return this.accountService.initLiveCaller(body);
  }

  getToken(): string {
    return this.sessionService.getToken();
  }

  getUserData() {
    this.store$.dispatch(accountActions.getUserInfo());
  }

  updateBalance() {
    this.store$.dispatch(accountActions.updateBalance());
  }

  getAlreadyVisitStatus() {
    const alreadyVisited = this.cookieService.get('alreadyVisited');
    return alreadyVisited === 'true';
  }

  isVerified(): boolean {
    return this.$isVerified.getValue();
  }

  getDynamicCustomVerificationStatus$(): Observable<verificationStatusInterface | null> {
    return this.customVerificationStatusEmitter$.asObservable();
  }

  getCurrentCustomVerificationStatus(): verificationStatusInterface | null {
    return this.customVerificationStatusEmitter$.getValue();
  }

  setDynamicCustomVerificationStatus(
    value: verificationStatusInterface | null
  ): void {
    this.customVerificationStatusEmitter$.next(value);
  }

  getCurrentLatestVerificationProtocol(): protocolModel {
    return this.latestVerificationProtocol$.getValue();
  }

  setDynamicLatestVerificationProtocol(value: protocolModel): void {
    this.latestVerificationProtocol$.next(value);
  }

  setVerificationActive(status: boolean): void {
    this.store$.dispatch(
      accountActions.getVerificationActive({
        payload: this.isVerified() ? status : false
      })
    );
  }

  getVerificationActive$(): Observable<boolean> {
    return this.store$.select(accountSelectors.selectVerificationActive);
  }
}
