import { Injectable } from '@angular/core';
import { CmsAnalyticsService } from '../services/cms-analytics.service';
import { getCurrentDateWithOldDeviceSupport } from '@crc/core';
import { EnvironmentService } from '@crc/shared';
import { ICMSAnalyticsDTO } from '../entities/interfaces';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CmsAnalyticsFacade {
  constructor(
    private readonly cmsAnalyticsService: CmsAnalyticsService,
    private readonly environmentService: EnvironmentService
  ) {}
  public trackPaymentAttempt$(
    data: Pick<ICMSAnalyticsDTO, 'method'> &
      Pick<ICMSAnalyticsDTO, 'amount'> &
      Pick<ICMSAnalyticsDTO, 'currency'>
  ) {
    const defaultPaymentCurrency = 'GEL';
    const platform =
      (this.environmentService.config.platform || 'mobile') === 'desktop'
        ? 'Web'
        : 'Mobile';
    return this.cmsAnalyticsService
      .trackPaymentAttempt$({
        ...data,
        currency: data.currency || defaultPaymentCurrency,
        platform,
        attemptTime: getCurrentDateWithOldDeviceSupport()
      })
      .pipe(take(1));
  }
}
