import { generateMenuItems } from '../functions/functions';
import { MenuItem } from '../entity/menu-items.interface';

export const MENU_ITEMS: MenuItem[] = [
  generateMenuItems('sport', 'sport', 'sport', false, true, false),
  generateMenuItems('live', 'live', 'live', false, true, false),
  generateMenuItems('slots', 'slots', 'slots', false, true),
  generateMenuItems('casino', 'casino', 'casino', false, true),
  generateMenuItems(
    'ufo',
    'fast-game',
    'mini-games',
    false,
    false,
    false,
    true
  ),
  generateMenuItems('games', 'games', 'games'),
  generateMenuItems('poker', 'poker', 'poker'),
  generateMenuItems('fantasy', 'free-sports', 'free-sports', true, true, true),
  generateMenuItems('promos', 'promos', 'promotions'),
  generateMenuItems('category.setanta', 'stream', 'setanta', true, true, false)
];
