import { CrocoRoulette, JackpotOdometerData } from '@crc/components';

const digitalOffsetLevels = {
  LEVEL_ONE_OFFSET: 186,
  LEVEL_TWO_OFFSET: 160,
  LEVEL_THREE_OFFSET: 150,
  LEVEL_FOUR_OFFSET: 120
};

export function processCrocoRouletteJackpot(jackpot: CrocoRoulette, dataRefreshRate: number): JackpotOdometerData[] {
  if (!jackpot || !jackpot.Body || jackpot.Body.length === 0) {
    return [];
  }

  const silver = jackpot.Body.find(data => data.PoolName === 'Silver');
  const gold = jackpot.Body.find(data => data.PoolName === 'Gold');

  const tier0: number = silver?.CollectedAmount ? parseFloat(silver?.CollectedAmount.toFixed(2).replace('.', '')) : 0;
  const tier1: number = gold?.CollectedAmount ? parseFloat(gold?.CollectedAmount.toFixed(2).replace('.', '')) : 0;

  const startNumberLevelI: number = Math.max(tier0 - digitalOffsetLevels.LEVEL_ONE_OFFSET, 0);
  const endNumberLevelI: number = tier0;
  const digitCountLevelI = 10;

  const odometerDataLevelI: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelI,
    startNumber: startNumberLevelI,
    endNumber: endNumberLevelI
  };

  const startNumberLevelII: number = Math.max(tier1 - digitalOffsetLevels.LEVEL_TWO_OFFSET, 0);
  const endNumberLevelII: number = tier1;
  const digitCountLevelII = 10;

  const odometerDataLevelII: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelII,
    startNumber: startNumberLevelII,
    endNumber: endNumberLevelII
  };

  const odometerDataLevelIII: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: 10,
    startNumber: 0,
    endNumber: 0
  };

  const odometerDataLevelIV: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: 10,
    startNumber: 0,
    endNumber: 0
  };

  const newOdometerData: Array<JackpotOdometerData> = [];
  newOdometerData.push(odometerDataLevelI);
  newOdometerData.push(odometerDataLevelII);
  newOdometerData.push(odometerDataLevelIII);
  newOdometerData.push(odometerDataLevelIV);

  return newOdometerData;
}
