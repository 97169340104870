<div
  [class.md-drppicker__wrapper--active]="picker?.picker?.isShown"
  class="md-drppicker__wrapper"
  data-cy="datepicker"
  *ngIf="locale$ | async as locale"
>
  <div class="input-wrapper" (click)="openDatepicker($event)">
    <img src="assets/icons/shapes/calendar.svg" alt="" />
    <input
      *ngIf="ranges$ | async as ranges"
      #inputPicker
      ngxDaterangepickerMd
      [(ngModel)]="selected"
      (ngModelChange)="choseData($event)"
      [ranges]="ranges"
      [locale]="locale"
      [alwaysShowCalendars]="true"
      [showRangeLabelOnInput]="true"
      class="md-drppicker__input"
      type="text"
      readonly
      endKey="end"
    />
  </div>
</div>
