<div class="my-tooltip" (click)="toolTipClick.emit()">
  <span class="my-tooltip__my-text">
    <div class="my-tooltip__my-text-gift">
      <img src="/assets/icons/giftbox.svg" alt="close" />
    </div>
    <span>
      {{ 'register.get_gift' | translate }}
      <strong>{{ 'register.free-spins' | translate }}!</strong></span
    >
    <div
      (click)="$event.stopPropagation(); closeClick.emit()"
      class="my-tooltip__my-text-close-image"
    >
      <img src="/assets/icons/close-image.svg" alt="close" />
    </div>
  </span>
  <span class="my-tooltip__triangle"></span>
</div>
