import { inject } from '@angular/core';
import { UserRestrictionsFacade } from '../facade/user-restrictions.facade';
import { UserRestrictionsInterface } from '../interface/user-restrictions-interfaces';

export function CanLeaveChangePasswordRestrictedZoneGuard(): boolean {
  const userRestrictionsFacade: UserRestrictionsFacade = inject(
    UserRestrictionsFacade
  );
  const latestUserRestrictionDTO =
    userRestrictionsFacade.getLatestUserRestrictionsDTO();
  return !(
    latestUserRestrictionDTO.includes(
      UserRestrictionsInterface.UPDATE_PASSWORD_REQUIRED
    ) ||
    latestUserRestrictionDTO.includes(
      UserRestrictionsInterface.PROMPT_ABOUT_SMS_OFF
    ) ||
    latestUserRestrictionDTO.includes(
      UserRestrictionsInterface.PROMPT_ABOUT_SMS_ON
    ) ||
    latestUserRestrictionDTO.includes(
      UserRestrictionsInterface.PROMPT_ABOUT_SMS_ON_V2
    )
  );
}
