import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'crc-shared-number-change-form-error-presentation',
  templateUrl: './number-change-form-error-presentation.component.html',
  styleUrls: ['./number-change-form-error-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberChangeFormErrorPresentationComponent {
  @Input() public errorText: string;
  @Input() public status = false;
}
