import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PasswordValidatorComponent} from './password-validator/password-validator.component';
import {SharedModule} from '@crc/shared';

const externalModules = [SharedModule];

@NgModule({
  declarations: [PasswordValidatorComponent],
  exports: [PasswordValidatorComponent],
  imports: [CommonModule, ...externalModules]
})
export class PasswordValidatorModule {}
