import { Pipe, PipeTransform } from '@angular/core';
import { workingDaysImp } from './entity/holiday-consts';

@Pipe({
  name: 'cashierHolidays',
  pure: false
})
export class CashierHolidaysPipe implements PipeTransform {
  transform(value: number, isException?: boolean): string {
    return workingDaysImp[value];
  }
}
