export enum VerificationStatus {
  REJECTED = 'VERIFICATION_STATUS_REJECTED',
  PENDING = 'VERIFICATION_STATUS_PENDING',
  VERIFIED = 'VERIFICATION_STATUS_VERIFIED',
  UNVERIFIED = 'VERIFICATION_STATUS_UNVERIFIED'
}

export const MarchFirst = 1614542400000;

export const JanuaryFirst2022 = 1640995200000;
