import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class VirtualFilterFacade {
  private readonly $filter = new BehaviorSubject<string>('');
  private readonly $categoryFilter = new BehaviorSubject<string>('');

  constructor(private router: Router, private route: ActivatedRoute) {}

  getFilterFromRoute() {
    return this.route.queryParams.pipe(
      tap((params) => {
        this.$filter.next(params['categoryFilter'] || '');
        this.$categoryFilter.next(params['filter'] || '');
      })
    );
  }

  setFilter(filter: string) {
    this.router
      .navigate([], {
        queryParams: { categoryFilter: filter },
        queryParamsHandling: 'merge'
      })
      .then(() => {
        this.$filter.next(filter);
        this.setCategoryFilter('');
      });
  }

  getFilter$(): Observable<string> {
    return this.$filter.asObservable();
  }

  setCategoryFilter(filter: string) {
    this.router
      .navigate([], {
        queryParams: { filter: filter },
        queryParamsHandling: 'merge'
      })
      .then(() => {
        this.$categoryFilter.next(filter);
      });
  }

  getCategoryFilter$(): Observable<string> {
    return this.$categoryFilter.asObservable();
  }

  selectCategoryFilter(filter: string) {
    this.setFilter(filter);
  }

  selectSecondaryCat(filter: string) {
    this.setCategoryFilter(filter);
  }
}
