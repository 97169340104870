import { Injectable } from '@angular/core';
import { addQueryParamToUrl, BaseUrl, HttpService } from '@crc/shared';
import {
  BalanceEventsResponse,
  BalanceType,
  ExchangeBody
} from '../entity/bonus.data';
import { filterQueryParamsInURL } from '../functions/functions';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ExchangeDataResponse } from '../entity/bonus.exchange';
import { BalanceState } from '../entity/bonus.state';

@Injectable({
  providedIn: 'root'
})
export class BonusCoinService {
  constructor(private readonly apiService: HttpService) {}

  getUserBonusBalance() {
    const path = `/campaigns/bonus-space/user/balances`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.get$<BalanceState>({ path, baseUrl }).pipe(
      catchError((err) => {
        return of(err.errorCode);
      })
    );
  }

  getUserBalanceByBalanceId(balanceId: string, period: string) {
    const url = `/campaigns/bonus-space/user/balances/${balanceId}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = addQueryParamToUrl(url, [
      {
        value: period,
        key: 'period'
      }
    ]);
    return this.apiService.get$<{ data: number }>({ path, baseUrl }).pipe(
      catchError((err) => {
        return of(err.errorCode);
      })
    );
  }

  getUserBalanceState(balanceId: string, period: string) {
    const url = `/campaigns/bonus-space/user/balances/${balanceId}/state`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = addQueryParamToUrl(url, [
      {
        value: period,
        key: 'period'
      }
    ]);
    return this.apiService.get$<BalanceState>({ path, baseUrl }).pipe(
      catchError((err) => {
        return of(err.errorCode);
      })
    );
  }

  getUserBonusEvents(
    balanceId: string,
    type?: BalanceType | null,
    page: number = 1,
    pageSize: number = 15,
    period: string = undefined
  ) {
    const url = `/campaigns/bonus-space/user/balances/${balanceId}/events`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const path = filterQueryParamsInURL(url, [
      {
        value: type,
        key: 'type'
      },
      {
        value: period,
        key: 'period'
      },
      {
        value: String(page),
        key: 'page'
      },
      {
        value: String(pageSize),
        key: 'size'
      }
    ]);
    return this.apiService.get$<BalanceEventsResponse>({ path, baseUrl }).pipe(
      catchError((err) => {
        return of(err.errorCode);
      })
    );
  }

  postBonusExchange(balanceId: string, body: ExchangeBody) {
    const path = `/campaigns/bonus-space/user/balances/${balanceId}/commands/exchange`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.post$<ExchangeDataResponse, unknown>({
      path,
      baseUrl,
      body
    });
  }
}
