import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { PasscodeFacade } from '../../../features/auth/services/passcode.facade';
import { KeyboardData } from '../../keyboard/entity/data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PasscodeVariables } from '../entity/passcode-state.type';

@UntilDestroy()
@Component({
  selector: 'crc-m-use-passcode',
  templateUrl: './use-passcode.component.html',
  styleUrls: ['./use-passcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsePasscodeComponent implements OnDestroy {
  passcode$: Observable<string> = this.passcodeFacade.getPasscode();
  isInvalidPasscode$ = this.passcodeFacade.getInvalidPasscode$();
  avatarUrl: string;
  userName: string;
  keyboardBlocked = false;

  constructor(private readonly passcodeFacade: PasscodeFacade) {
    this.avatarUrl =
      this.passcodeFacade.getLocalStorage(PasscodeVariables.avatarUrl) ||
      'crocobet';
    this.userName = this.passcodeFacade.getLocalStorage(
      PasscodeVariables.firstName
    );
  }

  ngOnDestroy(): void {
    this.passcodeFacade.setPasscode('');
    this.passcodeFacade.setInvalidPasscode(false);
  }

  onKeyboardClick(event: KeyboardData) {
    if (this.keyboardBlocked) {
      return;
    }
    this.passcodeFacade.manipulatePasscode(event);

    if (this.passcodeFacade.getPasscodeValue().length === 4) {
      this.keyboardBlocked = true;
      this.passcodeFacade
        .checkPasscode()
        .pipe(
          untilDestroyed(this),
          tap(() => {
            this.keyboardBlocked = false;
          })
        )
        .subscribe();
    }
  }
}
