<form [formGroup]="form">
  <div class="info">
    <div class="info__item">
      <crc-m-shared-input
        [placeholder]="'username_full' | translate"
        [errorsTexts]="[
          { type: 'required', text: 'fill_required_fields' | translate },
          {
            type: 'minlength',
            text: 'enter_minimum_characters_count' | translate : { count: 4 }
          },
          {
            type: 'maxlength',
            text: 'enter_maximum_characters_count' | translate : { count: 30 }
          },
          { type: 'used', text: 'is_already_in_use' | translate },
          { type: 'pattern', text: 'use_only_latin_letters' | translate },
          { type: 'onlyUnderscores', text: 'only_underscore_letters_error' | translate }
        ]"
        formControlName="loginName"
        name="loginName"
        data-cy="login-name"
        type="text"
        (blurred)="controlBlurred($event, 'loginName')"
        (keyUpped)="handleLoginNameChange()"
      >
      </crc-m-shared-input>
    </div>

    <div class="info__item">
      <crc-m-shared-input
        [placeholder]="'password' | translate"
        [errorsTexts]="[
          { type: 'required', text: 'fill_required_fields' | translate },
          {
            type: 'minlength',
            text: 'enter_minimum_characters_count' | translate : { count: 6 }
          },
          {
            type: 'maxlength',
            text: 'enter_maximum_characters_count' | translate : { count: 20 }
          },
          {
            type: 'number',
            text: 'password.digit_required' | translate : { count: 20 }
          },
          {
            type: 'upperCase',
            text: 'password.letters_required' | translate
          },
          {
            type: 'lowerCase',
            text: 'password.letters_required' | translate
          },
          {
            type: 'latin',
            text: 'use_only_latin_letters' | translate
          }
        ]"
        formControlName="password"
        name="password"
        data-cy="password"
        type="password"
        (blurred)="controlBlurred($event, 'password')"
      >
      </crc-m-shared-input>
      <crc-password-validator
        *ngIf="
          (form.get('password').statusChanges | async) === 'INVALID' &&
          form.get('password').touched
        "
        [errors]="form.get('password').errors"
        [showUserNameAndPasswordMatchValidation]="true"
      ></crc-password-validator>
    </div>

    <div class="info__item">
      <crc-m-shared-input
        [placeholder]="'repeat_password' | translate"
        [errorsTexts]="[
          { type: 'match', text: 'passwords_dont_match' | translate },
          { type: 'required', text: 'fill_required_fields' | translate },
          { type: 'IsLongerThan', text: 'passwords_dont_match' | translate }
        ]"
        formControlName="confirmPassword"
        name="confirmPassword"
        data-cy="confirm-password"
        type="password"
        (blurred)="controlBlurred($event, 'confirmPassword')"
      >
      </crc-m-shared-input>
    </div>

    <div class="info__item">
      <crc-m-shared-input
        [placeholder]="'email' | translate"
        [errorsTexts]="[
          { type: 'required', text: 'fill_required_fields' | translate },
          { type: 'pattern', text: 'wrong_format' | translate },
          { type: 'used', text: 'is_already_in_use' | translate }
        ]"
        formControlName="email"
        name="email"
        data-cy="email"
        (blurred)="controlBlurred($event, 'email')"
      >
      </crc-m-shared-input>
    </div>
    <div class="info__item">
      <crc-m-shared-input
        [placeholder]="'register.promo_code' | translate"
        [errorsTexts]="[
          { type: 'pattern', text: 'use_only_latin_letters' | translate },
          {
            type: 'minLength',
            text: 'enter_minimum_characters_count' | translate : { count: 2 }
          },
          {
            type: 'maxLength',
            text: 'enter_maximum_characters_count' | translate : { count: 40 }
          },
          { type: 'firstLetter', text: 'wrong_format' | translate }
        ]"
        formControlName="promoCode"
        name="PromoCode"
        data-cy="PromoCode"
      >
      </crc-m-shared-input>
    </div>
    <div class="info__item">
      <crc-m-phone-input
        [placeholder]="'phone_number' | translate"
        [errorsTexts]="[
          { type: 'required', text: 'fill_required_fields' | translate },
          { type: 'pattern', text: 'wrong_format' | translate },
          { type: 'used', text: 'is_already_in_use' | translate }
        ]"
        formControlName="phoneNumber"
        name="phoneNumber"
        autoComplete="false"
        mask="000 00 00 00"
        data-cy="phone-number"
        [inputMode]="'numeric'"
        (blurred)="controlBlurred($event, 'phoneNumber')"
      ></crc-m-phone-input>
    </div>

    <div class="info__item">
      <crc-m-shared-input
        [placeholder]="'sms_code' | translate"
        [errorsTexts]="[
          { type: 'required', text: 'fill_required_fields' | translate },
          { type: 'pattern', text: 'wrong_format' | translate },
          { type: 'wrongCode', text: 'sms_code_invalid' | translate }
        ]"
        class="short-right"
        formControlName="verificationCode"
        name="verificationCode"
        data-cy="verification-code"
        autoComplete="one-time-code"
        mask="000000"
        [inputMode]="'numeric'"
      >
        <div class="timer">
          <crc-m-timer-button
            (timerStarted)="onTimerStart()"
            [timeAmount]="60"
            [disabled]="
              (form.get('phoneNumber').statusChanges | async) !== 'VALID' &&
              form.get('phoneNumber').invalid
            "
            [textInput]="(timerTouched ? 'otp.resend' : 'send') | translate"
            [reset]="{
              status: timerResetState
            }"
          >
          </crc-m-timer-button>
        </div>
      </crc-m-shared-input>
    </div>
    <div class="cda" *ngIf="form.getError('rateLimit') && rateLimitErrorDataSource as countDown">
      <div class="cda__img">
        <crc-m-status-icon-result
          [success]="false"
        ></crc-m-status-icon-result>
      </div>
      <p class="cda__text">
        {{'otp.rate_limit' | translate : {seconds: countDown} }}
      </p>
    </div>
    <div class="checkbox">
      <crc-m-checkbox
        class="register"
        formControlName="ageAgree"
        [labelFor]="
          'age-agree' | translate : { age: selectedCountry === 'GE' ? 25 : 18 }
        "
        id="ageAgree"
      >
      </crc-m-checkbox>
    </div>
    <div class="checkbox">
      <crc-m-checkbox
        class="register"
        formControlName="agreementCheckbox"
        [labelFor]="'register.sms-on.text' | translate"
        id="agreementCheckbox"
      >
      </crc-m-checkbox>
    </div>

    <div class="checkbox">
      <crc-m-checkbox
        class="register"
        formControlName="iAgree"
        [labelFor]="'register.terms_and_conditions' | translate"
        [Suffix]="'register.conditions'"
        (anchorClicked)="policyClicked.emit(form)"
        id="iAgree"
      >
      </crc-m-checkbox>
    </div>

    <crc-m-shared-button
      *ngIf="!showMessage"
      [disable]="!form.valid"
      [type]="form.valid ? 'active' : 'secondary'"
      [loader]="isLoading?.status"
    >
      <div
        class="button-guts"
        (click)="!form.valid ? markFormControlsAsTouched() : null"
      >
        {{ 'register' | translate }}
      </div>
    </crc-m-shared-button>
  </div>

  <div class="sms-message" *ngIf="showMessage">
    <div class="sms-message__img">
      <crc-m-status-icon-result [success]="true"></crc-m-status-icon-result>
    </div>
    <p class="sms-message__text">{{ 'sms_code_sent' | translate }}</p>
  </div>
</form>
