import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BonusCoinComponent } from './components/bonus-coin/bonus-coin.component';
import { BonusCoinSwiperComponent } from './components/bonus-coin-swiper/bonus-coin-swiper.component';
import { SwiperModule } from '../swiper';
import { BonusCalculatorComponent } from './components/bonus-calculator/bonus-calculator.component';
import { BonusSpecialButtonComponent } from './components/bonus-special-button/bonus-special-button.component';
import { SharedTranslateModule } from '@crc/shared';
import { BonusHistoryComponent } from './components/bonus-history/bonus-history.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    BonusCoinComponent,
    BonusCoinSwiperComponent,
    BonusCalculatorComponent,
    BonusSpecialButtonComponent,
    BonusHistoryComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    SharedTranslateModule,
    InfiniteScrollModule
  ],
  exports: [BonusCoinComponent]
})
export class BonusCoinModule {}
