import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigBassColorInterpreter',
  standalone: true,
  pure: true
})
export class BigBassColorInterpreter implements PipeTransform {
  transform(value: number): string {
    return value >= 2 ? '#357848' : '#802C3E';
  }
}
