export type IdentificationSteps = 'action' | 'result';
export type PopupAction = 'close' | 'back' | 'logout';
export type IdentificationCheckTypes =
  | 'Obliged'
  | 'Warning'
  | 'Passed'
  | 'DoNotShow';

export interface IdentificationSMS {
  data: {
    success: boolean;
  };
}
export interface identificationDataFromPersonalInfo {
  smsVerificationExpiresAt: string | null;
  smsVerificationDate: string | null;
  smsVerificationDaysLeft: number;
  mobileNumber: string | null;
}
export interface SmsCodeResponse {
  status: boolean;
  error: boolean;
  retryAfter?: number;
}
export abstract class IdentificationTypes {
  public static readonly Obliged = 'Obliged';
  public static readonly Warning = 'Warning';
  public static readonly Passed = 'Passed';
  public static readonly DoNotShow = 'DoNotShow';
  public static readonly null = null;
}
