import * as AppState from '@crc/facade';
import { userBarActiveItem, userBarPosition } from '../../containers';
import { LoginPopupActiveTab } from '../../../features/auth/containers';

export const layoutFeatureKey = 'layout';

export interface State extends AppState.State {
  layoutFeatureKey: LayoutState;
}

interface UserBarState {
  isFooterVisible: boolean;
  isHomeIconVisible: boolean;
  userBarActiveItem: userBarActiveItem;
  userBarPosition: userBarPosition;
  isUserBarVisible: boolean;
}

const userBarInitialState: UserBarState = {
  isFooterVisible: true,
  isUserBarVisible: true,
  isHomeIconVisible: false,
  userBarActiveItem: null,
  userBarPosition: 'bottom'
};

export interface LayoutState {
  isBalanceVisible: boolean;
  isBurgerOpen: boolean;
  isVisibleHeader: boolean;
  layoutPageReload: boolean;
  loginPopupActiveTab: LoginPopupActiveTab;
  userBarState: UserBarState;
}

export const initialState: LayoutState = {
  isBurgerOpen: false,
  isBalanceVisible: true,
  isVisibleHeader: true,
  layoutPageReload: false,
  loginPopupActiveTab: 'login',
  userBarState: userBarInitialState
};
