import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputBaseComponent } from '@crc/components';

@Component({
  selector: 'crc-m-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends InputBaseComponent<boolean> {
  @Input() labelFor: string;
  @Input() elementId: string;
  @Input() Suffix;
  @Output() anchorClicked = new EventEmitter<void>();
  onTextTrigger() {
    this.writeValue(!this.value);
    this.onChange(this.value);
  }
}
