import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import SwiperCore, {
  Autoplay,
  FreeMode,
  Keyboard,
  Lazy,
  Navigation,
  Pagination,
  Scrollbar
} from 'swiper';
import { PaginationOptions } from 'swiper/types';

import {
  ImageSlide,
  SwiperViewMode,
  TemplateRefWithContext
} from './entity/types';
import { defaultSwiperSettings } from './entity/constants';
import { tap, timer } from 'rxjs';
import { EnvironmentService } from '@crc/shared';

SwiperCore.use([
  Pagination,
  Navigation,
  Autoplay,
  Keyboard,
  Scrollbar,
  Lazy,
  FreeMode
]);

@Component({
  selector: 'crc-shared-swiper',
  templateUrl: './swiper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwiperComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() view: SwiperViewMode = defaultSwiperSettings.view;
  @Input() autoPlay = true;
  @Input() borderRadius = true;
  @Input() isMobile = false;
  @Input() columnCount: number;
  @Input() navigation = defaultSwiperSettings.navigation;
  @Input() pagination = defaultSwiperSettings.pagination;
  @Input() header = defaultSwiperSettings.header;
  @Input() carousel = defaultSwiperSettings.carousel;
  @Input() switcher = defaultSwiperSettings.switcher;
  @Input() loop = defaultSwiperSettings.loop;
  @Input() slidesPerView = defaultSwiperSettings.slidesPerView;
  @Input() dragSwipe = defaultSwiperSettings.dragSwipe;
  @Input() scrollbar = defaultSwiperSettings.scrollbar;
  @Input() breakpoints = defaultSwiperSettings.breakpoints;
  @Input() lazyOptions = defaultSwiperSettings.lazyOptions;
  @Input() freeMode = defaultSwiperSettings.freeMode;
  @Input() cssMode = defaultSwiperSettings.cssMode;
  @Input() tooltipSupport: boolean;
  @Input() images: ImageSlide[] = [];
  @Input() templateRef: TemplateRefWithContext;
  @Input() templateRefGrid: TemplateRefWithContext;

  @Output() clicked = new EventEmitter();
  @Output() realIndex: EventEmitter<number> = new EventEmitter<number>();

  @ViewChildren('swiper') swiper: ElementRef;
  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly env: EnvironmentService
  ) {}

  showSwiper = true;
  defaultAutoPlay = defaultSwiperSettings.autoPlay;
  slidInProcess = false;
  gridImages: Array<Array<ImageSlide>> = [];
  paginationSettings: PaginationOptions = this.getPaginationSettings();

  uiHideNavigation: boolean;
  uiHidePagination: boolean;
  private updateUI() {
    this.uiHideNavigation = !(this.view === 'single'
      ? this.navigation.single
      : this.navigation.grid);

    this.uiHidePagination = !(this.view === 'single'
      ? this.pagination.single
      : this.pagination.grid);
  }

  private produceGridImages() {
    this.gridImages = this.arrangeForGridView<ImageSlide>(this.images);
  }

  private normalizeGridImagesForTemplateRef() {
    if (this.templateRefGrid) {
      this.templateRefGrid = {
        ...this.templateRefGrid,
        context: this.arrangeForGridView<any>(this.templateRefGrid.context)
      };
    }
  }

  private arrangeForGridView<T>(arr: Array<T>): Array<Array<T>> {
    const newArr: Array<Array<T>> = [];

    for (let i = 0; i < arr.length; i++) {
      const j = Math.floor(i / (this.columnCount ? this.columnCount : 4));

      if (newArr[j] === undefined) {
        newArr[j] = [];
      }
      newArr[j].push(arr[i]);
    }

    return newArr;
  }

  ngOnInit() {
    this.normalizeGridImagesForTemplateRef();
    this.updateUI();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.images && changes.images.currentValue) {
      if (this.switcher || this.view === 'grid') {
        this.produceGridImages();
      }
    }
  }

  switchView() {
    this.view = this.view === 'single' ? 'grid' : 'single';
    this.updateUI();
    this.showSwiper = false;
    this.paginationSettings = this.getPaginationSettings();
    timer(1)
      .pipe(
        tap((_) => {
          this.showSwiper = true;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  getPaginationSettings() {
    if (this.env.config.platform === 'mobile') {
      return {
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 6,
        renderBullet: () =>
          '<span class="swiper-pagination-bullet"><span class="bullet"></span></span>'
      };
    } else {
      return {
        clickable: true,
        renderBullet: () =>
          '<span class="swiper-pagination-bullet"><span class="bullet"></span></span>'
      };
    }
  }

  ngAfterViewInit(): void {
    if (this.cssMode)
      setTimeout(() => {
        const swiperRef = (
          this.swiper?.['last']?.['elementRef'] as ElementRef<HTMLElement>
        )?.nativeElement?.querySelector('.swiper-wrapper');
        swiperRef && ((swiperRef as HTMLElement).scrollLeft = 0);
      }, 500);
  }
}
