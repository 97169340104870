import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AnalyticsService,
  FormBase,
  handleRemovingRequiredError,
  MatomoAnalyticsService
} from '@crc/shared';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {
  containsValueValidator,
  CustomValidators,
  EMAIL_REGEXP,
  isAdultValidator,
  matchControlsValidator,
  NgSelectItem
} from '@crc/components';
import { Observable, startWith, tap, timer } from 'rxjs';
import {
  addLeadingZero,
  formatDateOfBirth,
  onlyUnderscoresValidator,
  Register,
  RegisterFacade,
  RegisterValidators
} from '@crc/facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayoutStateFacade } from '../../../../layout/state';
import { AccountInfoPresentationComponent } from './account-info-presentation/account-info-presentation.component';

@Component({
  selector: 'crc-m-register-presentation',
  templateUrl: './register-presentation.component.html',
  styleUrls: ['./register-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class RegisterPresentationComponent
  extends FormBase
  implements OnChanges, OnInit, OnDestroy
{
  @Input() countries: NgSelectItem[];
  @Input() cities: NgSelectItem[];
  @Input() months: NgSelectItem[];
  @Input() years: NgSelectItem[] = [];
  @Input() days: NgSelectItem[] = [];
  @Input() registrationStep: 'personalData' | 'accountData' | 'finished' =
    'personalData';
  @Input() cdaShowError: string | boolean;
  @Input() cdaNationalityShowError: string;
  @Input() fieldsAvailable: { mobileField: boolean; idField: boolean };

  @Output() register = new EventEmitter<Register>();
  @Output() sendSms = new EventEmitter<string>();
  @Output() resultClosed = new EventEmitter<void>();
  @Output() changeRegistrationStep = new EventEmitter<
    'personalData' | 'accountData' | 'finished'
  >();
  @Output() monthChanged = new EventEmitter<{ month: number; year: number }>();
  @Output() policyClicked: EventEmitter<(UntypedFormGroup[] | number)[]> =
    new EventEmitter<(UntypedFormGroup[] | number)[]>();
  @Output() private countryChanged: EventEmitter<string> = new EventEmitter();
  @ViewChild('registerRef') registerRef: ElementRef;
  @ViewChild(AccountInfoPresentationComponent)
  accountInfoRef: AccountInfoPresentationComponent;

  personalInfo: UntypedFormGroup = this.initPersonalForm();
  accountInfo: UntypedFormGroup = this.initAccountForm();
  cachedForm: UntypedFormGroup | undefined =
    this.registerFacade.getAccountInfo();
  protected readonly $isLoading: Observable<{ status: boolean }> =
    this.registerFacade.$isLoadingMob.asObservable();
  public readonly cachedPersonalForm = this.registerFacade.getPersonalInfo();
  public readonly cachedPersonalInfoRawValue =
    this.registerFacade.getPersonalInfoValue();
  @Input() rateLimitErrorDataSource!: number | null;
  @Input() set registrationEffect$(input: unknown) {
    if (input) {
      this.resetAccountInfoVerificationCodeConditionallyAfterSubmit();
    }
  }

  constructor(
    private readonly fb: UntypedFormBuilder,
    private registerValidators: RegisterValidators,
    private registerFacade: RegisterFacade,
    private layoutStateFacade: LayoutStateFacade,
    private analyticsService: AnalyticsService,
    private matomoAnalyticsService: MatomoAnalyticsService
  ) {
    super();
    this.subscribeRegisterScss();
    if (!!this.cachedPersonalForm){
      this.personalInfo = this.cachedPersonalForm;
    }
    if (!!this.cachedPersonalForm && !!this.cachedForm) {
      this.accountInfo = this.cachedForm;
      if (this.cachedPersonalInfoRawValue) {
        this.personalInfo
          .get('countryCode')
          .setValue(this.cachedPersonalInfoRawValue?.['countryCode'], {
            emitEvent: true,
            onlySelf: true
          });
        this.personalInfo
          .get('nationality')
          .setValue(this.cachedPersonalInfoRawValue?.['countryCode'], {
            emitEvent: true,
            onlySelf: true
          });
        this.personalInfo
          .get('documentId')
          .setValue(this.cachedPersonalInfoRawValue?.['documentId'], {
            emitEvent: true,
            onlySelf: true
          });
      }
      this.registerFacade.setAccountInfo(null as UntypedFormGroup);
      this.layoutStateFacade.setUserBarVisibility(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countries) {
      if (changes.countries.currentValue && !this.cachedPersonalInfoRawValue)
        this.personalInfo
          .get('countryCode')
          .setValue(changes.countries.currentValue[0]?.key);
      // this.personalInfo
      //   .get('city')
      //   .setValue(changes.countries.currentValue[0].key);
    } else if (changes.days) {
      const curr = changes.days.currentValue;
      const dayControl = this.personalInfo.get('day');
      if (+curr[curr.length - 1].value < +dayControl.value) {
        setTimeout(() => dayControl.reset());
      } else {
        setTimeout(() => dayControl.setValue(dayControl.value));
      }
    } else if (changes.fieldsAvailable) {
      const fields = changes.fieldsAvailable.currentValue;
      if (fields.mobileField === false) {
        this.accountInfo.get('phoneNumber').setErrors({
          used: true
        });
        this.accountInfoRef.handleVerificationCodeReset();
      } else if (fields.idField === false) {
        setTimeout(() => {
          this.changeRegistrationStep.emit('personalData');
        }, 0);
        setTimeout(() => {
          this.personalInfo.get('documentId').setErrors({
            used: true
          });
        }, 1);
      }
    }
  }

  ngOnInit() {
    this.analyticsService.track({
      event: 'registrationStarted',
      action: 'registrationStarted'
    });

    this.matomoAnalyticsService.selectTrackingEvent('registrationStarted');
    this.subscribeMonthChanges();
    this.subscribeYearChanges();
    setTimeout(() => {
      this.personalInfo.get('year').reset();
      if (this.cachedPersonalInfoRawValue) {
        this.personalInfo.get('year').setValue(
          this.cachedPersonalInfoRawValue?.['year']
        );
      }
    }, 200);
  }

  subscribeMonthChanges() {
    this.personalInfo
      .get('month')
      .valueChanges.pipe(
        untilDestroyed(this),
        tap((month) => {
          this.monthChanged.emit({
            month,
            year: this.personalInfo.get('year').value
          });
        })
      )
      .subscribe();
  }

  subscribeYearChanges() {
    this.personalInfo
      .get('year')
      .valueChanges.pipe(
        untilDestroyed(this),
        tap((year) => {
          this.monthChanged.emit({
            month: this.personalInfo.get('month').value,
            year: year
          });
        })
      )
      .subscribe();
  }

  subscribeRegisterScss() {
    this.registerFacade.$registerSuccess
      .pipe(
        untilDestroyed(this),
        tap(([scss, data]) => {
          if (scss === false) {
            if (data?.['some']?.((obj) => obj?.propertyName === 'email')) {
              const control = this.accountInfo.get('email');
              control.setErrors({
                pattern: true
              });
            } else {
              const control = this.accountInfo.get('verificationCode');
              control.setErrors({
                wrongCode: true
              });
            }
          }
        })
      )
      .subscribe();
  }

  initPersonalForm() {
    const form = this.fb.group(
      {
        firstName: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          CustomValidators.patternValidator(/^[a-zA-Z -]+$/, {
            latin: true
          }),
          CustomValidators.patternValidator(/^[^ -]/, {
            firstLetter: true
          })
        ]),
        lastName: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          CustomValidators.patternValidator(/^[a-zA-Z -]+$/, {
            latin: true
          }),
          CustomValidators.patternValidator(/^[^ -]/, {
            firstLetter: true
          })
        ]),
        countryCode: new UntypedFormControl('GE', {
          validators: [Validators.required],
          updateOn: 'change'
        }),
        nationality: new UntypedFormControl('GE', {
          validators: [Validators.required]
        }),
        documentId: new UntypedFormControl('', {
          updateOn: 'change'
        }),
        day: new UntypedFormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }),
        month: new UntypedFormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }),
        year: new UntypedFormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        })
      },
      { validators: [isAdultValidator('day', 'month', 'year', 'countryCode')] }
    );

    form
      .get('countryCode')
      .valueChanges.pipe(
        tap((value) => {
          this.countryChanged.emit(value);
          setTimeout(() => {
            const yearControl = form.get('year');
            yearControl.reset();
            yearControl.setErrors(null);
            yearControl.markAsUntouched();
            handleRemovingRequiredError(yearControl);
          }, 0);
        }),
        startWith('GE'),
        tap((value) => {
          const idControl = form.get('documentId');
          idControl.reset();
          idControl.clearValidators();
          idControl.setValidators([
            Validators.required,
            Validators.pattern(
              value === 'GE' ? /^(\d){11}$/ : /^[\dA-Za-z-/#]*$/
            )
          ]);
          idControl.updateValueAndValidity();
          const cityControl = form.get('nationality');
          cityControl.setValue(value);
          cityControl.markAsUntouched();
          handleRemovingRequiredError(cityControl);
        })
      )
      .subscribe();

    return form;
  }

  onlyUnderscoresValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const onlyUnderscores = /^_+$/.test(value);
    return onlyUnderscores ? { onlyUnderscores: true } : null;
  }

  initAccountForm() {
    return this.fb.group(
      {
        loginName: new UntypedFormControl('', {
          validators: [
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9_-]+$'),
            Validators.minLength(4),
            Validators.maxLength(30),
            onlyUnderscoresValidator
          ],
          asyncValidators: this.registerValidators.fieldAsyncValidator(
            this.registerFacade,
            'login'
          ),
          updateOn: 'blur'
        }),
        phoneNumber: new UntypedFormControl('', {
          validators: [
            Validators.required,
            CustomValidators.patternValidator(/^[5][0-9]{8}$/, {
              pattern: true
            })
          ],
          updateOn: 'change'
        }),
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          CustomValidators.patternValidator(/\d/, { number: true }),
          CustomValidators.patternValidator(/[A-Z]/, { upperCase: true }),
          CustomValidators.patternValidator(/[a-z]/, { lowerCase: true }),
          CustomValidators.patternValidator(/^[ -~]+$/, {
            latin: true
          })
        ]),
        verificationCode: new UntypedFormControl('', {
          validators: [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
            Validators.pattern(/^[0-9]+$/)
          ],
          updateOn: 'change'
        }),
        email: new UntypedFormControl('', {
          validators: [
            Validators.required,
            CustomValidators.patternValidator(EMAIL_REGEXP, {
              pattern: true
            })
          ],
          asyncValidators: this.registerValidators.fieldAsyncValidator(
            this.registerFacade,
            'email'
          ),
          updateOn: 'blur'
        }),
        confirmPassword: new UntypedFormControl('', Validators.required),
        iAgree: new UntypedFormControl(true, Validators.requiredTrue),
        ageAgree: new UntypedFormControl(true, Validators.requiredTrue),
        agreementCheckbox: new UntypedFormControl(true),
        promoCode: new UntypedFormControl('', {
          validators: [
            CustomValidators.patternValidator(/^[A-Za-z\d ]*$/, {
              pattern: true
            }),
            CustomValidators.patternValidator(/^[^ ]/, {
              firstLetter: true
            }),
            CustomValidators.patternValidator(/^.{2,}$/, {
              minLength: true
            }),
            CustomValidators.patternValidator(/^.{0,40}$/, {
              maxLength: true
            })
          ]
        })
      },
      {
        validators: [
          matchControlsValidator(
            { controlName: 'password', addError: false },
            { controlName: 'confirmPassword', addError: true }
          ),
          containsValueValidator('loginName', 'password')
        ]
      }
    );
  }

  onRegisterSubmit() {
    if (this.registerFacade.$isLoadingMob.getValue().status) {
      return;
    }
    this.registerFacade.$isLoadingMob.next({ status: true });
    const year = this.personalInfo.get('year').value;
    const month = this.personalInfo.get('month').value;
    const day = this.personalInfo.get('day').value;
    const promoCode = this.accountInfo.get('promoCode').value;
    const value: Register = {
      ...this.personalInfo.getRawValue(),
      ...this.accountInfo.getRawValue(),
      verificationCode: this.accountInfo.get('verificationCode').value,
      address: promoCode ? promoCode : 'test',
      pinCode: null,
      phoneNumber: `995:${this.accountInfo.get('phoneNumber').value}`,
      dateOfBirth: formatDateOfBirth(year, month, day),
      freeBetSpinChoice: 3,
      day: addLeadingZero(day)
    };
    delete (value as any).promoCode;
    value.documentId = value.documentId?.toUpperCase?.() || value.documentId;
    this.register.emit(value);
  }

  onRegisterStepChange(step: 'personalData' | 'accountData' | 'finished') {
    this.changeRegistrationStep.emit(step);
    this.registerRef.nativeElement.scrollTo(0, 0);
    this.registerFacade.setPersonalInfo(this.personalInfo);
  }

  onSendSms() {
    this.sendSms.emit(this.accountInfo.get('phoneNumber').value);
    this.accountInfo.get('verificationCode').setValue('');
  }

  scrollIfCached(): void {
    const fromTop = this.registerFacade.getScrollCache();
    if (fromTop) {
      timer(0)
        .pipe(
          tap(() => {
            this.registerRef?.nativeElement?.scrollTo(0, fromTop);
          })
        )
        .subscribe();
      this.registerFacade.setScrollCache(0);
    }
  }

  ngOnDestroy() {
    if (this.registrationStep === 'finished') {
      this.registerFacade.manuallyResetRegistrationStep();
    }
  }
  private resetAccountInfoVerificationCodeConditionallyAfterSubmit(): void {
    if (this.accountInfo.status === 'INVALID') {
      const codeControl = this.accountInfo.get('verificationCode');
      if (codeControl.hasError('wrongCode')) {
        return;
      }
      if (codeControl.value?.length === 5) {
        return;
      }
      codeControl.setValue('', { emitEvent: false });
    }
  }
}
