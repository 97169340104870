import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

import { DOCUMENT } from '@angular/common';
import { ISEOBaseConfig } from './seo.config';
import { LanguageFacade } from '@crc/shared';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  public readonly tempVars = {
    isChangeLocked: false
  };
  public static readonly TempModifiedLandingForSEO =
    'https://promotions.crocobet.com/croco-sponsor' as const;
  public static readonly DefaultTitle =
    'კროკობეთი - აზარტული თამაშების საუკეთესო საიტი' as const;
  public static readonly DefaultDescription =
    'ითამაშე სპორტი, EGT სლოტები, UFO, კაზინო, სამაგიდო თამაშები / Play sports, EGT slots, UFO, casino, board games / Играй в Спорт, слотами EGT, УФО, Казино, Настольные игры.' as const;
  public static readonly DefaultImgUrl =
    '/assets/new-images/shared/seo-image.svg' as const;
  // public static readonly DefaultKeywords = SEOService.DefaultDescription;
  constructor(
    private readonly titleService: Title,
    private readonly meta: Meta,
    @Inject(DOCUMENT) private readonly __doc__: Document,
    private readonly languageFacade: LanguageFacade
  ) {}
  public resetSEOTagsToDefaults(): void {
    this.updateSEOTags({
      title:
        this.languageFacade.translateInstant('title') ||
        SEOService.DefaultTitle,
      description: SEOService.DefaultDescription,
      img: SEOService.DefaultImgUrl
    });
    this.tempVars.isChangeLocked = false;
  }
  public updateSEOTags({
    title = this.languageFacade.translateInstant('title') ||
      SEOService.DefaultTitle,
    description = SEOService.DefaultDescription,
    img = SEOService.DefaultImgUrl
  }: ISEOBaseConfig): void {
    this.tempVars.isChangeLocked = true;
    console.error('####%%%%%%%', title, description, img, '_______');
    this.titleService.setTitle(title);
    const tagsToUpdate: MetaDefinition[] = [
      {
        property: 'og:title',
        content: title
      },

      // {
      //   property: 'og:type',
      //   content: 'website'
      // },
      {
        property: 'og:url',
        content: window.location.href
      },
      {
        property: 'og:description',
        content: description
      },
      {
        property: 'og:image',
        content: img
      },
      // {
      //   property: 'og:site_name',
      //   content: 'Crocobet'
      // },
      // { property: 'og:locale', content: 'en_US' },
      // { property: 'og:locale:alternate', content: 'en_US' },
      // { property: 'og:locale:alternate', content: 'ru_RU' },
      // { property: 'og:locale:alternate', content: 'tr_TR' },
      //==============
      // { property: 'twitter:card', content: 'summary_large_image' },
      {
        property: 'twitter:title',
        content: title
      },
      {
        property: 'twitter:description',
        content: description
      },
      {
        property: 'twitter:image',
        content: img
      },
      // { property: 'twitter:site', content: '@crocobet' },
      {
        content: description,
        name: 'description'
      },
      // {
      //   content: 'index, follow',
      //   name: 'robots'
      // },
      {
        property: 'keywords',
        content: description
      },
      {
        property: 'title',
        content: title
      }
    ];
    for (const tag of tagsToUpdate) {
      this.meta.updateTag(tag);
    }
  }
}
