export const fastGameIcon = {
  data: `<svg viewBox="0 0 80 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M66.32 42.5302C68.03 42.5302 69.41 41.1502 69.41 39.4402V24.9702L63.23 29.2802V39.4402C63.23 41.1402 64.61 42.5302 66.32 42.5302Z" fill="#fff"/>
<path d="M79.2901 15.7102C79.2901 14.3302 78.1701 13.2002 76.7801 13.2002H58.8601L54.8901 18.2102H63.2401V22.2902L69.4201 23.1502V18.2002H76.7901C78.1601 18.2202 79.2901 17.0902 79.2901 15.7102Z" fill="#fff"/>
<path d="M39.61 30.9501L32.77 29.7501L33.46 31.4801H25.09L29.27 21.0401L30.99 25.3201L35.67 21.6301L33.48 16.4401C32.82 14.8801 31.3 13.8701 29.61 13.8701H26.14L15.55 39.0001C14.71 40.9901 16.17 43.2001 18.33 43.2001C19.56 43.2001 20.67 42.4501 21.13 41.3001L23.23 36.0701H35.29L36.3 38.5901L40.66 33.5101L39.61 30.9501Z" fill="#fff"/>
<path d="M17.25 25.6804H6.18C6.18 24.1504 6.41 22.9004 6.88 21.9504C7.34 21.0004 7.94 20.2604 8.65 19.7404C9.37 19.2304 10.17 18.8804 11.06 18.7004C11.95 18.5204 12.84 18.4304 13.72 18.4304H20.89L23.03 13.4204H12.51C10.12 13.4204 8.13 13.7704 6.52 14.4804C4.91 15.1804 3.62 16.1604 2.66 17.4004C1.69 18.6404 1.01 20.1304 0.599998 21.8504C0.199998 23.5704 0 25.4604 0 27.5204V39.6604C0 41.3704 1.38 42.7504 3.09 42.7504C4.8 42.7504 6.18 41.3704 6.18 39.6604V30.5004H15.05L17.25 25.6804Z" fill="#fff"/>
<path d="M28.1101 51.34L46.2101 30.26L31.1401 27.62L66.2301 0L48.5001 22.37L66.1301 24.83L28.1101 51.34ZM35.5201 26.52L49.7201 29.01L38.9701 41.53L61.2301 26.01L45.0601 23.75L55.0501 11.14L35.5201 26.52Z" fill="#fff"/>
</svg>`,
  name: 'fast-game' as const
};
