<input
  class="toggle-input"
  [id]="elementId"
  [(ngModel)]="value"
  type="checkbox"
/>
<label
  class="toggle-label"
  (click)="onChange(!value)"
  [for]="elementId"
></label>
