import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  BehaviorSubject,
  interval,
  startWith,
  take,
  takeWhile,
  tap
} from 'rxjs';

@Component({
  selector: 'crc-m-timer-button',
  templateUrl: './timer-button.component.html',
  styleUrls: ['./timer-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimerButtonComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() timeAmount: number;
  @Input() textInput: string;
  @Input() disabled: boolean;
  @Input() reset: {
    status: boolean;
  } = {
    status: false
  };
  @Input() bgColor: 'default' | 'bonus' = 'default'
  @Input() showTimerFromSessionStorage = false;
  @Input() private initiateStartOnInit = false;
  @Output() timerStarted = new EventEmitter<boolean>();
  @Output() timerFinished = new EventEmitter<void>();
  @Output() timerAction = new EventEmitter<void>();

  time: BehaviorSubject<number> = new BehaviorSubject(null);

  ngOnInit() {
    const timerStartTime = sessionStorage.getItem('timerStartTime');
    if (this.showTimerFromSessionStorage && timerStartTime) {
      const elapsedSeconds = Math.floor(
        (new Date().getTime() - Number(timerStartTime)) / 1000
      );
      if (elapsedSeconds < this.timeAmount) {
        this.time.next(this.timeAmount - elapsedSeconds);
        this.startTimer();
      }
    } else {
      this.time.next(this.timeAmount);
    }
  }

  ngAfterViewInit() {
    if (this.initiateStartOnInit) {
      setTimeout(() => {
        this.timerClicked(true);
      });
    }
  }

  timerClicked(isInitialAutoAction = false) {
    if (this.time.value !== this.timeAmount) {
      return;
    }

    this.timerStarted.emit(isInitialAutoAction);
    if (this.showTimerFromSessionStorage) {
      sessionStorage.setItem('timerStartTime', new Date().getTime().toString());
    }
    this.startTimer();
  }
  private startTimer() {
    interval(1000)
      .pipe(
        takeWhile(() => {
          return !this.reset?.status && this.time.value !== this.timeAmount;
        }),
        startWith(this.timeAmount),
        take(this.timeAmount),
        tap(() => {
          this.timerAction.emit();
          this.time.next(this.time.value - 1);
          if (this.time.value === 0) {
            this.time.next(this.timeAmount);
            this.timerFinished.emit();
          }
        })
      )
      .subscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.reset) {
      if (this.reset?.status) {
        this.time.next(this.timeAmount);
      }
    }
  }
}
