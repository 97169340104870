import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { containerComponents, exportedContainerComponents } from './containers';
import { presentationComponents } from './presentations';
import { OtpRateLimitAlertComponent } from '../../shared/otp-rate-limit-alert/otp-rate-limit-alert.component';

@NgModule({
  declarations: [...containerComponents, ...presentationComponents],
  imports: [SharedModule, OtpRateLimitAlertComponent],
  exports: [...exportedContainerComponents]
})
export class AuthModule {}
