import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CasinoService } from '../../features/casino/services/casino.service';
import {
  getRouletteStats,
  getRouletteStatsError,
  getRouletteStatsSuccess
} from './roulette.actions';
import { catchError, of, switchMap, throttleTime } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRouletteStatResponse } from './roulette.reducers';
import { ROULETTE_HISTORY_NUMBERS } from '../../features';

@Injectable()
export class RouletteEffects {
  private actions$ = inject(Actions);
  private service = inject(CasinoService);

  getRouletteStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRouletteStats),
      throttleTime(100),
      switchMap(() =>
        this.service.getProviderGameStats<IRouletteStatResponse>().pipe(
          map((response: IRouletteStatResponse[]) => {
            const mappedResponse = response.map((provider) => {
              return {
                ...provider,
                isDouble: provider['stats'].some((stat) => stat.includes('|')),
                stats: (provider as unknown as IRouletteStatResponse[])[
                  'stats'
                ].map((stat) => {
                  if (stat.includes('|')) {
                    stat = stat.split('|');

                    return {
                      number: stat,
                      color: {
                        color1: ROULETTE_HISTORY_NUMBERS[stat[0]] || 'unknown',
                        color2: ROULETTE_HISTORY_NUMBERS[stat[1]] || 'unknown'
                      }
                    };
                  } else {
                    return {
                      number: [stat],
                      color: ROULETTE_HISTORY_NUMBERS[stat] || 'unknown'
                    };
                  }
                })
              };
            });

            return getRouletteStatsSuccess({ payload: mappedResponse });
          }),
          catchError((error) => {
            return of(getRouletteStatsError({ error }));
          })
        )
      )
    )
  );
}
