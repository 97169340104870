import { inject, Injectable } from '@angular/core';
import { BaseUrl, HttpService } from '@crc/shared';
import { Observable, of } from 'rxjs';
import {
  PaymentProvider,
  TransactionTypes,
  TransferDepositResponse,
  TransferRequest
} from '@crc/core';
import { catchError, map } from 'rxjs/operators';
import {
  APPLE_PAY_CMS_TOKEN,
  IApplePayCmsTokenResponseDTO
} from '../entity/cms-token.config';

@Injectable({
  providedIn: 'root'
})
export class ApplePayDepositService {
  private static readonly CUSTOM_BASE_URL = 'https://casino-api.crocobet.com';
  private readonly apiService: HttpService = inject(HttpService);

  public startDeposit(
    body: TransferRequest
  ): Observable<TransferDepositResponse> {
    const path = `/teg/transfer/api/player/start-deposit`;

    const headers: Record<string, string> = {
      'Accept-Language': 'ka',
      channel: 'MOBILE'
    };

    return this.apiService.post$<TransferDepositResponse, TransferRequest>({
      path,
      customBaseUrl: ApplePayDepositService.CUSTOM_BASE_URL,
      body,
      headers
    });
  }

  getBankProviders(): Observable<PaymentProvider[]> {
    const path = '/teg/transfer/api/player/active-providers';

    return this.apiService
      .get$<{ activeProviders: PaymentProvider[] }>({
        path,
        customBaseUrl: ApplePayDepositService.CUSTOM_BASE_URL
      })
      .pipe(map((result) => result.activeProviders));
  }

  calculateCommission(
    body: Partial<
      TransferRequest & { direction: TransactionTypes; isMaxBalance?: boolean }
    >
  ): Observable<{ commission: number; rate: number }> {
    const path = `/teg/transfer/api/player/calculate-commission`;

    return this.apiService
      .post$<
        { commission: number; rate: number },
        Partial<
          TransferRequest & {
            direction: TransactionTypes;
            isMaxBalance?: boolean;
          }
        >
      >({
        path,
        customBaseUrl: ApplePayDepositService.CUSTOM_BASE_URL,
        body
      })
      .pipe(catchError((_) => of({} as { commission: number; rate: number })));
  }

  calculateMaxWithdrawal(
    body: Partial<TransferRequest>
  ): Observable<{ commission: number; rate: number; amount: number }> {
    const path = `/teg/transfer/api/player/calculate-max-withdrawal`;

    return this.apiService.post$<
      { commission: number; rate: number; amount: number },
      Partial<TransferRequest>
    >({
      path,
      customBaseUrl: ApplePayDepositService.CUSTOM_BASE_URL,
      body
    });
  }

  public checkApplePayAvailabilityForUser$(): Observable<
    Partial<IApplePayCmsTokenResponseDTO>
  > {
    const path = `/ui/toggles/${APPLE_PAY_CMS_TOKEN}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: IApplePayCmsTokenResponseDTO }>({ path, baseUrl })
      .pipe(
        map((payload) => payload.data),
        catchError(() =>
          of({
            isActive: false
          })
        )
      );
  }
}
