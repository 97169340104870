import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { addYears, isBefore, parseISO } from 'date-fns';
type IsAdultValidator = (
  formControls: UntypedFormGroup
) => ValidationErrors | null;

export const isAdultValidator = (
  day: string,
  month: string,
  year: string,
  countryCode: string
): IsAdultValidator => {
  return (formControls: UntypedFormGroup): ValidationErrors => {
    const controlsList = formControls.controls;
    const dayControl = controlsList[day];
    const monthControl = controlsList[month];
    const yearControl = controlsList[year];
    const countryControl = controlsList[countryCode];
    if (yearControl.value && monthControl.value && dayControl.value) {
      const date =
        yearControl.value + '/' + monthControl.value + '/' + dayControl.value;
      const minAge = addYears(
        parseISO(new Date().toISOString()),
        countryControl.value === 'GE' ? -25 : -18
      );
      const formAge = parseISO(new Date(date).toISOString());
      const isAdult = isBefore(
        formAge,
        parseISO(new Date(minAge).toISOString())
      );
      if (!isAdult) {
        yearControl.setErrors({ notAdult: true });
        monthControl.setErrors({ notAdult: true });
        dayControl.setErrors({ notAdult: true });
        return null;
      }
      if (isAdult) {
        [yearControl, monthControl, dayControl].forEach((control) => {
          if (control.errors?.notAdult) {
            delete control.errors.notAdult;
            control.updateValueAndValidity();
          }
        });
      }
    }
    return null;
  };
};
