import { Injectable } from '@angular/core';

import { BaseUrl, HttpService } from '@crc/shared';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import {
  CardCity,
  CardCityResponse,
  CardCount,
  CardStatus,
  OrderCard,
  SendSms
} from '../entity/croco-card';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CrocoCardService {
  constructor(
    private readonly apiService: HttpService
  ) {
  }

  getCardsCount(): Observable<CardCount> {
    const path = '/croco-card/count';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const body = {};
    return this.apiService.post$({ path, baseUrl, body });
  }

  checkCardStatus(): Observable<CardStatus> {
    const path = '/croco-card/status';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const body = {};
    return this.apiService.post$({ path, baseUrl, body });
  }

  orderCard(form: UntypedFormGroup): Observable<OrderCard> {
    const path = '/croco-card';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.post$({ path, baseUrl, body: form });
  }

  addSecondCard(form: UntypedFormGroup): Observable<OrderCard> {
    const path = '/croco-card/add';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.post$({ path, baseUrl, body: form });
  }

  sendSmsCode(): Observable<SendSms> {
    const path = '/croco-card/sendsms';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const body = {};
    return this.apiService.post$({ path, baseUrl, body });
  }

  getCardCities(lang: string): Observable<CardCity> {
    const path = `/ui/strings/crococard_country/${lang}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.get$({ path, baseUrl }).pipe(
      map((response: CardCityResponse) => response.data)
    );
  }
}
