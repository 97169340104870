import { Injectable } from '@angular/core';
import { EnvironmentService } from '@crc/shared';
import { AccountFacade, AuthFacade } from '../../../auth';
import { CasinoService } from '../services/casino.service';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { CasinoGame } from '../entity';

const totalToSave = 50;

@Injectable({
  providedIn: 'root'
})
export class CasinoHistoryFacade {
  private readonly $history = new BehaviorSubject<CasinoGame[]>([]);
  readonly history$ = this.$history.asObservable().pipe(
    distinctUntilChanged(),
    map((data) => {
      const games = [];
      data.map((d) => {
        if (d?.game_id) {
          games.push(d);
        }
      });

      return games;
    })
  );

  readonly totalCount$ = this.$history.pipe(map((casino) => casino.length));

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly casinoService: CasinoService,
    private readonly accountFacade: AccountFacade,
    private readonly authFacade: AuthFacade
  ) {}

  addCasinoToHistory(casino: CasinoGame) {
    if (this.authFacade.getIsLoggedIn()) {
      this.casinoService
        .addToHistory(casino.provider, casino.game_id)
        .pipe(
          tap((_) => {
            this.removeCasinoFromState(casino);
            this.addCasinoToState(casino);
            this.removeLastFromStateIfFull();
          })
        )
        .subscribe();
    }
  }

  updateHistory(casino: CasinoGame[]) {
    this.$history.next(casino);
  }

  private addCasinoToState(casino: CasinoGame) {
    this.$history.next([casino, ...this.$history.value] as CasinoGame[]);
  }

  private removeCasinoFromState(casino: CasinoGame) {
    this.$history.next([
      ...this.$history.value?.filter((c) => c.game_id !== casino.game_id)
    ] as CasinoGame[]);
  }

  private removeLastFromStateIfFull() {
    const casino = this.$history.value;
    if (casino.length > totalToSave) {
      casino.pop();
      this.$history.next(casino);
    }
  }
}
