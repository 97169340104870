import {
  RouteReuseStrategy,
  DetachedRouteHandle,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Location } from '@angular/common';
import { inject } from '@angular/core';
export class CustomReuseStrategy implements RouteReuseStrategy {
  private readonly location: Location = inject(Location);

  private readonly routeKeyListToRevalidate: Set<string> = new Set([
    'sport',
    'live',
    'setanta'
  ]);
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    //NOSONAR
    return false;
  }

  store(
    route: ActivatedRouteSnapshot, //NOSONAR
    handle: DetachedRouteHandle | null //NOSONAR
  ): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    //NOSONAR
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    //NOSONAR
    return null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    const isSameRoute = future.routeConfig === curr.routeConfig;
    const state = this.routeState;

    if ((state as Record<'forceReuse', boolean>)?.forceReuse) {
      return isSameRoute;
    }

    return (
      isSameRoute &&
      (!this.routeKeyListToRevalidate.has(future.data?.key) ||
        !this.routeKeyListToRevalidate.has(curr.data?.key))
    );
  }
  private get routeState(): unknown {
    return this.location.getState();
  }
}
