<crc-m-shared-popup
  (closedSelf)="close()"
  [activeTab]="'attention'"
  [tabTitles]="['attention']"
  dataCyPrefix="restricted-user"
  crcSharedClassForward="no-bottom-space"
  to=".popup"
>
  <crc-m-login-restriction-presentation
    [status]="status"
    (clicked)="onClickCashiers()"
  >
    <div
      *ngIf="noScrollClass$ | async"
      crcSharedClassForward="noscroll"
      to="body"
    ></div>
  </crc-m-login-restriction-presentation>
</crc-m-shared-popup>
