import { Injectable } from '@angular/core';
import { filter, Subject } from 'rxjs';
import { SlotsHistoryFacade } from './slots-history.facade';
import { SlotGame } from '../entity';
import { Router } from '@angular/router';
import { PlayInterface, SlotCasinoGameTypeEnum } from '../../slot&casino';
import { EnvironmentService } from '@crc/shared';
import { DepositFacade } from '../../deposit';

@Injectable({ providedIn: 'root' })
export class SlotsPlayFacade {
  private readonly $play = new Subject<PlayInterface>();
  play$ = this.$play.asObservable().pipe(filter((game) => !!game));

  constructor(
    private readonly slotsHistoryFacade: SlotsHistoryFacade,
    private readonly router: Router,
    private readonly environmentService: EnvironmentService,
    private readonly depositFacade: DepositFacade
  ) {
  }

  play(slotGame: SlotGame) {
    if (this.router.url.includes('slot-casino')) {
      this.$play.next({
        gameName: slotGame.name,
        providerName: slotGame.provider,
        gameId: slotGame.game_id,
        gameType: SlotCasinoGameTypeEnum.SLOT
      });
      this.slotsHistoryFacade.addSlotToHistory(slotGame);
    } else {
      const route = this.getRoute();
      this.router
        .navigate([route], {
          queryParams: {
            slot: slotGame.name,
            provider: slotGame.provider,
            filter: slotGame.provider
          }
        })
        .then((_) => this.slotsHistoryFacade.addSlotToHistory(slotGame));
    }

    this.depositFacade.setPaymentStateAfterClick(true);
  }

  private getRoute() {
    return this.environmentService.config.platform === 'mobile'
      ? 'launcher'
      : '/slots/play';
  }
}
