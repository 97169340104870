import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { fromEvent, tap } from 'rxjs';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
interface RestrictiveConditionInterface {
  status: boolean;
  inputRef?: ElementRef<HTMLInputElement>;
}
@UntilDestroy()
@Directive({
  selector: '[crcSharedCapitalizeInput]'
})
export class CapitalizeInputDirective implements OnInit, OnChanges {
  @Input('crcSharedCapitalizeInput') private control:
    | AbstractControl
    | FormControl
    | FormGroup;
  @Input() private restrictiveCondition: {
    status: boolean;
    inputRef?: ElementRef<HTMLInputElement>;
  } = {
    status: true
  };
  public ngOnChanges(changes: SimpleChanges): void {
    const conditionPrev: RestrictiveConditionInterface =
      changes['restrictiveCondition']?.previousValue;
    const conditionCur: RestrictiveConditionInterface =
      changes['restrictiveCondition']?.currentValue;
    if (
      conditionCur?.inputRef &&
      conditionPrev?.status !== conditionCur?.status
    ) {
      conditionCur.inputRef.nativeElement.style.textTransform =
        conditionCur?.status ? 'uppercase' : 'none';
    }
  }
  ngOnInit() {
    const input: HTMLInputElement =
      this.restrictiveCondition.inputRef?.nativeElement ||
      (this.elRef.nativeElement as HTMLElement)?.querySelector?.('.input');
    fromEvent<KeyboardEvent>(input, 'keydown')
      .pipe(
        untilDestroyed(this),
        tap((event: KeyboardEvent) => {
          if (input.parentElement.parentElement.classList.contains('GE')) {
            this.filterKey(event);
          }
        })
      )
      .subscribe();
  }

  constructor(private readonly elRef: ElementRef) {}

  filterKey(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
      'Tab'
    ];
    const isNumericKey = event.key >= '0' && event.key <= '9';
    const isAllowedKey = allowedKeys.includes(event.key);

    if (!isNumericKey && !isAllowedKey && !event.ctrlKey) {
      event.preventDefault();
    }
  }
}
