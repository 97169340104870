import { Language } from '@crc/shared';
import {
  Category,
  Footer,
  FooterCards,
  FooterLang,
  FooterMessenger
} from '../entity/footer.interface';

export function generateMenuItems(
  menuTitleKey: string,
  categoryName: Category[],
  categoryId?: string,
  subcategoryId?: string
): Footer {
  return {
    menuTitleKey,
    categoryName,
    categoryId,
    subcategoryId
  };
}

export function generateLangMenu(
  img: string,
  title: string,
  key: Language,
  name: string
): FooterLang {
  return {
    img: img,
    title: title,
    key: key,
    name: name
  };
}

export function generateMessengerMenu(
  link: string,
  img: string
): FooterMessenger {
  return {
    link: link,
    img: img
  };
}

export function generateFooterCards(name: string): FooterCards {
  return {
    name
  };
}
