import {
  NavigationItem,
  Platform,
  SecondaryFilterDTOInterface
} from '@crc/shared';
import { SearchGameViewDataBase as SearchGameViewData } from '@crc/core';
const UfoAllGameNavigationItemBase = {
  navType: 'MINI',
  active: false,
  route: '/mini-games'
} as const;
function generateNavigationItem(
  name: string,
  filter: string,
  image?: string
): SecondaryFilterDTOInterface {
  const imgPath =
    image || typeof image === 'string'
      ? image
      : `assets/icons/features/mini-games/navigation/${filter}.svg`;
  return {
    title: name,
    id: filter,
    prefixIconSrc: imgPath,
    isActive: false
  };
}
function generateNavigationItemForWeb(
  name: string,
  filter: string,
  image?: string
): NavigationItem {
  const imgPath =
    image || typeof image === 'string'
      ? image
      : `assets/icons/features/mini-games/navigation/${filter}.svg`;
  return {
    ...UfoAllGameNavigationItemBase,
    name,
    filter,
    image: imgPath,
    iconUrl: imgPath
  };
}
const BaseUfoAllGameNavigationItems: SecondaryFilterDTOInterface[] = [
  generateNavigationItem('mini-games.with-bonus', 'with-bonus'),
  generateNavigationItem('new', 'new-games')
];
const BaseUfoAllGameNavigationItemsForWeb: NavigationItem[] = [
  generateNavigationItemForWeb('mini-games.with-bonus', 'with-bonus'),
  generateNavigationItemForWeb('new', 'new-games')
];
export function generateUfoAllGameNavigationItems(
  isAuthenticated: boolean,
  platform: Platform
): SecondaryFilterDTOInterface[] {
  const isMobile = platform === 'mobile';
  return [
    generateNavigationItem(
      'all',
      'home',
      isMobile ? 'assets/icons/features/shared/navigation/all.svg' : ''
    )
  ].concat(
    isAuthenticated
      ? BaseUfoAllGameNavigationItems.concat([
          generateNavigationItem('favorites', 'favorite-games')
        ])
      : BaseUfoAllGameNavigationItems
  );
}
export function generateUfoAllGameNavigationItemsForWeb(
  isAuthenticated: boolean,
  platform: Platform
): NavigationItem[] {
  const isMobile = platform === 'mobile';
  return [
    generateNavigationItemForWeb(
      'all',
      'home',
      isMobile ? 'assets/icons/features/shared/navigation/all.svg' : ''
    )
  ].concat(
    isAuthenticated
      ? BaseUfoAllGameNavigationItemsForWeb.concat([
          generateNavigationItemForWeb('favorites', 'favorite-games')
        ])
      : BaseUfoAllGameNavigationItemsForWeb
  );
}
export const LocalListOfSearchedMiniGames: SearchGameViewData[] = [
  {
    image: 'assets/images/ufo/other/ufo.jpg',
    providerName: 'UFO',
    gameName: 'UFO',
    callBack: {
      routeLink: '/mini-games/UFO'
    },
    provider: 'UFO',
    ['game_id']: 'UFO'
  } as SearchGameViewData,
  {
    image: 'assets/images/ufo/other/space_taxi.jpg',
    providerName: 'SpaceTaxi',
    gameName: 'SPACE TAXI',
    callBack: {
      routeLink: '/mini-games/SpaceTaxi'
    },
    provider: 'SpaceTaxi',
    ['game_id']: 'SPACE TAXI'
  } as SearchGameViewData
];
// provider: miniGame.provider,
//   ['game_id']: miniGame.game_id
