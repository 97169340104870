export const offsetLevels = {
  LEVEL_ONE_OFFSET: 206,
  LEVEL_TWO_OFFSET: 180,
  LEVEL_THREE_OFFSET: 170,
  LEVEL_FOUR_OFFSET: 140
};

export const odometerRefreshRate: Array<number> = [
  15000, 15000, 15000, 15000, 20000, 30000, 50000, 80000
];

export const egtOdometerRefreshRate: Array<number> = [60000];

export const pragmaticOdometerRefreshRate: Array<number> = [60000];

export const patePlayOdometerRefreshRate: Array<number> = [60000];

export const crocoRouletteOdometerRefreshRate: Array<number> = [60000];

export const egtOdometerCount = 4;
export const patePlayOdometerCount = 4;
export const crocoRouletteOdometerCount = 4;
