<div class="input-container" data-cy="input-container">
  <input
    #inputElement
    [id]="name + '_input'"
    [(ngModel)]="value"
    (input)="handleInputChange(value)"
    (keyup)="keyUpped.emit($event)"
    (focus)="focused.emit($event)"
    (blur)="handleBlur($event)"
    (change)="handleInputChange(value)"
    [autocomplete]="autoComplete"
    [type]="type"
    [mask]="mask"
    [name]="name"
    [class.error]="
      control.touched &&
      ((control?.statusChanges | async) === 'INVALID' || control.invalid) &&
      (control?.value || control?.hasError('forceRequired'))
    "
    [disabled]="disabled"
    [suffix]="suffix"
    [prefix]="prefix"
    [attr.inputmode]="inputMode ? inputMode : 'text'"
    class="input form-control"
    data-cy="input"
    required
  />

  <label class="input-label" data-cy="input-label" [for]="name + '_input'">
    <ng-container
      *ngIf="
        control.value &&
          control?.touched &&
          ((control?.statusChanges | async) === 'INVALID' || control.invalid) &&
          (control?.value || control?.hasError('forceRequired'));
        else placeHolder
      "
    >
      <span
        class="error"
        data-cy="error"
        *ngIf="currentDisplayedError | async as error; else placeHolder"
        >{{ error }}</span
      >
    </ng-container>

    <ng-template #placeHolder>{{ placeholder }}</ng-template>
  </label>

  <div class="content" data-cy="content">
    <ng-content> </ng-content>
  </div>
</div>
