import { Component } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { SlotsStateFacade } from '../../features/slots/state';
import { ActivatedRoute, Router } from '@angular/router';
import { MoreGamesFacade } from '@crc/facade';

@Component({
  selector: 'crc-m-buy-bonus',
  templateUrl: './buy-bonus.component.html',
  styleUrls: ['./buy-bonus.component.scss']
})
export class BuyBonusComponent {
  isActive$: Observable<boolean> = this.slotsStateFacade.isBuyBonusActive();

  constructor(
    private readonly slotsStateFacade: SlotsStateFacade,
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly moreGamesFacade: MoreGamesFacade
  ) {}

  toggleBonusBuyState(isActive: boolean) {
    if (this.moreGamesFacade.getPopupStateValue()) {
      this.slotsStateFacade.$customBuyBonus.next(!isActive);
    } else {
      this.slotsStateFacade.addBuyBonusStateIntoTheUrl(!isActive);
    }
    this.slotsStateFacade.setBuyBonusState(!isActive);
  }
}
