import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, Observable, of } from 'rxjs';
import { sub } from 'date-fns';
import {
  IdentificationCheckTypes,
  identificationDataFromPersonalInfo,
  IdentificationTypes,
  PopupAction
} from '../entity/identification-types';
import {
  AccountFacade,
  AuthFacade,
  PersonalData,
  VerificationStatus
} from '../../../auth';
import { Store } from '@ngrx/store';
import { accountSelectors } from '../../../auth/store/selectors';
import { catchError, map } from 'rxjs/operators';
import { IdentificationFacade } from '../facade/identification.facade';

@Injectable({
  providedIn: 'root'
})
export class SmsIdentificationService {
  private readonly isSmsIdentificationCorrect$ = new BehaviorSubject<boolean>(
    null
  );
  private readonly isCloseDisabled$ = new BehaviorSubject<PopupAction>('close');
  private readonly $numberSuccessChange = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly store: Store<PersonalData>,
    private readonly identificationFacade: IdentificationFacade,
    private readonly accountFacade: AccountFacade
  ) {}

  public setPopupActionState(value: PopupAction) {
    this.isCloseDisabled$.next(value);
  }

  getPopupActionState(): Observable<PopupAction> {
    return this.isCloseDisabled$.asObservable();
  }

  public setSmsIdentificationState(value: boolean) {
    this.isSmsIdentificationCorrect$.next(value);
  }

  public setNumberSuccessChange(data: boolean) {
    this.$numberSuccessChange.next(data);
  }

  public getNumberSuccessChange(): Observable<boolean> {
    return this.$numberSuccessChange.asObservable();
  }

  public getNumberSuccessChangeValue(): boolean {
    return this.$numberSuccessChange.value;
  }

  public getSmsIdentificationState$(): Observable<boolean> {
    return this.isSmsIdentificationCorrect$
      .asObservable()
      .pipe(filter((e) => e !== null));
  }

  // TODO remove store from service and use account facade
  public checkIdentification(
    personalInfo: identificationDataFromPersonalInfo
  ): Observable<IdentificationCheckTypes> {
    return combineLatest([
      this.authFacade.isLoggedIn$(),
      this.store.select(accountSelectors.selectVerificationStatus),
      this.accountFacade.getVerificationActive$()
    ]).pipe(
      map(
        ([loginState, verificationStatus, isVerificationActive]: [
          boolean,
          VerificationStatus | undefined,
          boolean
        ]) => {
          if (loginState && verificationStatus) {
            const realVerificationStatus =
              this.getUserVerificationStatus(personalInfo);
            if (realVerificationStatus === IdentificationTypes.Passed)
              return IdentificationTypes.Passed;
            let identificationState = realVerificationStatus;
            if (isVerificationActive) {
              return identificationState;
            }
            if (
              verificationStatus !== 'VERIFICATION_STATUS_VERIFIED' &&
              verificationStatus !== undefined
            ) {
              this.setItemToLocalStorage(
                'IdentificationState',
                IdentificationTypes.DoNotShow
              );
              return IdentificationTypes.DoNotShow;
            }

            if (
              realVerificationStatus === IdentificationTypes.Warning &&
              this.getIdentificationStateFromLocalStorage() ===
                IdentificationTypes.DoNotShow
            ) {
              identificationState = this.checkIdentificationStates();
            } else {
              if (
                personalInfo?.smsVerificationDate &&
                personalInfo?.smsVerificationExpiresAt
              ) {
                // const timeObliged = Number(
                //   new Date(personalInfo.smsVerificationExpiresAt)
                // );
                // const now = Date.now();
                // const timeWarning = sub(new Date(timeObliged), {
                //   days: 14
                // });
                identificationState = this.setIdentificationStateToLocalStorage(
                  personalInfo.smsVerificationDaysLeft
                );
              } else if (
                verificationStatus === 'VERIFICATION_STATUS_VERIFIED' &&
                !personalInfo?.smsVerificationExpiresAt
              ) {
                // Todo: check why?
                this.setItemToLocalStorage(
                  'IdentificationState',
                  IdentificationTypes.DoNotShow
                );
                return of(IdentificationTypes.Obliged);
              }
            }
            this.checkIdentificationExpiredAt(personalInfo);
            const phoneNumber = personalInfo?.mobileNumber?.replace(':', ' ');
            if (
              phoneNumber?.includes('null') &&
              identificationState === IdentificationTypes.Obliged
            ) {
              this.identificationFacade.setObligedAndHasNoNumberState(true);
            }

            return identificationState;
          }
        }
      ),
      catchError(() => of(IdentificationTypes.null))
    );
  }

  getUserVerificationStatus(
    personalInfo: identificationDataFromPersonalInfo
  ): IdentificationTypes {
    // const timeObliged = Number(new Date(personalInfo.smsVerificationExpiresAt));
    // const currentDate = Date.now();
    // const timeWarning = sub(new Date(timeObliged), {
    //   days: 14
    // });
    const daysLeft = Number(
      typeof personalInfo?.smsVerificationDaysLeft === 'number'
        ? personalInfo.smsVerificationDaysLeft
        : 0
    );
    if (daysLeft > 35) {
      return IdentificationTypes.Passed;
    }
    if (daysLeft > 10) {
      return IdentificationTypes.Warning;
    }
    return IdentificationTypes.Obliged;
    // if (daysLeft)
    //   if (
    //     currentDate >= Number(timeWarning) &&
    //     currentDate < Number(timeObliged)
    //   ) {
    //     return IdentificationTypes.Warning;
    //   } else if (currentDate > Number(timeObliged)) {
    //     return IdentificationTypes.Obliged;
    //   } else {
    //     return IdentificationTypes.Passed;
    //   }
  }

  checkIdentificationExpiredAt(
    personalInfo: identificationDataFromPersonalInfo
  ): void {
    const expiredDateFromIdentification = localStorage.getItem(
      'smsVerificationExpiresAt'
    );
    // const timeObliged = Number(new Date(personalInfo.smsVerificationExpiresAt));
    // const now = Date.now();
    // const timeWarning = sub(new Date(timeObliged), {
    //   days: 14
    // });
    if (expiredDateFromIdentification) {
      if (
        Number(new Date(expiredDateFromIdentification)) !==
        Number(new Date(personalInfo?.smsVerificationExpiresAt))
      ) {
        this.setItemToLocalStorage(
          'smsVerificationExpiresAt',
          personalInfo?.smsVerificationExpiresAt
        );
        this.setIdentificationStateToLocalStorage(
          personalInfo.smsVerificationDaysLeft
        );
      }
    } else {
      this.setItemToLocalStorage(
        'smsVerificationExpiresAt',
        personalInfo?.smsVerificationExpiresAt
      );
      this.setIdentificationStateToLocalStorage(
        personalInfo.smsVerificationDaysLeft
      );
    }
  }

  protected getIdentificationStateFromLocalStorage(): IdentificationCheckTypes {
    return localStorage.getItem(
      'IdentificationState'
    ) as IdentificationCheckTypes;
  }

  private setIdentificationStateToLocalStorage(
    daysLeft: number
  ): IdentificationCheckTypes {
    const daysLeftSanitized = Number(
      typeof daysLeft === 'number' ? daysLeft : 0
    );
    if (daysLeftSanitized > 35) {
      this.setItemToLocalStorage(
        'IdentificationState',
        IdentificationTypes.Passed
      );
      return IdentificationTypes.Passed;
    }
    if (daysLeftSanitized > 10) {
      this.setItemToLocalStorage(
        'IdentificationState',
        IdentificationTypes.Warning
      );
      return IdentificationTypes.Warning;
    }
    this.setItemToLocalStorage(
      'IdentificationState',
      IdentificationTypes.Obliged
    );
    return IdentificationTypes.Obliged;
  }

  private checkIdentificationStates() {
    const identificationState = this.getIdentificationStateFromLocalStorage();
    if (identificationState === IdentificationTypes.DoNotShow) {
      this.setItemToLocalStorage(
        'IdentificationState',
        IdentificationTypes.DoNotShow
      );
      return IdentificationTypes.DoNotShow;
    } else {
      return identificationState;
    }
  }

  setItemToLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }
}
