export const holidays: any = {
  0: 'dates.every_day',
  1: 'cashiers_saturday_holiday',
  2: 'dates.every_day_except_sunday',
  3: 'dates.working_days',
  6: 'dates.saturday',
  7: 'dates.sunday'
};

export const exception: any = {
  0: 'dates.every_day',
  1: 'dates.monday',
  2: 'dates.tuesday',
  3: 'dates.wednesday',
  4: 'dates.thursday',
  5: 'dates.friday',
  6: 'dates.saturday',
  7: 'dates.sunday'
};

export const workingDaysImp: any = {
  0: 'dates.working_days',
  6: 'dates.saturday',
  7: 'dates.sunday',
  8: 'dates.every_day'
};
