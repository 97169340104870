import { createAction, props } from '@ngrx/store';

import { PersonalData, UserDocument } from '../../entity';
import { Avatar } from '../../../features/profile/avatars/entity/avatars.interface';
import {
  IdentificationCheckTypes,
  identificationDataFromPersonalInfo
} from '../../../features';
const updateUser = createAction(
  '[CoreSession] Update User',
  props<{ payload: PersonalData }>()
);
const updateUserSuccess = createAction('[CoreSession] Update User Success');

const getUserInfo = createAction('[Account] Get User Info');
const getUserInfoSuccess = createAction(
  '[Account] Get User Info Success',
  props<{ payload: PersonalData }>()
);
const getUserInfoError = createAction('[Account] Get User Info Error');

const getUserDocument = createAction('[Account] Get User Document');
const getUserDocumentSuccess = createAction(
  '[Account] Get User Document Success',
  props<{ payload: UserDocument }>()
);
const getUserDocumentError = createAction('[Account] Get User Document Error');
const updateBalanceEvery5Second = createAction(
  '[Account] Update Balance Every 5 Second'
);
const updateBalance = createAction('[Account] Update Balance');
const updateBalanceSuccess = createAction(
  '[Account] Update Balance Success',
  props<{ payload: PersonalData }>()
);
const updateBalanceError = createAction('[Account] Update Balance Error');
const findIfSessionAlive = createAction('[Account] Find If Session Is Alive');

const getUserAvatar = createAction('[CoreSession] Get User Avatar');
const getUserAvatarSuccess = createAction(
  '[CoreSession] Get User Avatar Success',
  props<{ payload: Avatar }>()
);
const getUserAvatarError = createAction('[CoreSession] Get User Avatar Error');
const identificationCheck = createAction(
  '[Account] check identification status',
  props<{ payload: identificationDataFromPersonalInfo }>()
);
const getIdentificationStatus = createAction(
  '[Account] update identification status',
  props<{ payload: IdentificationCheckTypes }>()
);
const getVerificationActive = createAction(
  '[Account] update verification status',
  props<{ payload: boolean }>()
);
export const accountActions = {
  updateUser,
  updateUserSuccess,
  getUserInfo,
  getUserInfoSuccess,
  getUserInfoError,
  getUserDocument,
  getUserDocumentSuccess,
  getUserDocumentError,
  updateBalanceEvery5Second,
  updateBalance,
  updateBalanceSuccess,
  updateBalanceError,
  findIfSessionAlive,
  getUserAvatar,
  getUserAvatarSuccess,
  getUserAvatarError,
  getVerificationActive,
  identificationCheck,
  getIdentificationStatus
};
