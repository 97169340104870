import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PersonalData, VerificationStatus } from '../../entity';

const getAuthFeatureState = createFeatureSelector<PersonalData>('account');

const selectPersonalData = createSelector(
  getAuthFeatureState,
  (state) => state
);

const selectUserName = createSelector(
  getAuthFeatureState,
  (state) => state.loginName
);
const selectVerificationStatus = createSelector(
  getAuthFeatureState,
  (state) => {
    return verificationStatus(state);
  }
);
const selectVerificationActive = createSelector(
  getAuthFeatureState,
  (state) => state.verificationActive
);
const selectIdentificationStatus = createSelector(
  getAuthFeatureState,
  (state) => state.identificationActive
);
const selectPinCode = createSelector(
  getAuthFeatureState,
  (state) => state.pinCode
);

const selectCustomerId = createSelector(
  getAuthFeatureState,
  (state) => state.customerId
);

const selectAvatar = createSelector(
  getAuthFeatureState,
  (state) => state.avatar
);

const selectAccountBalance = createSelector(
  getAuthFeatureState,
  (state) => state.accountBalance
);
const selectPhoneNumber = createSelector(
  getAuthFeatureState,
  (state) => state.mobileNumber || state.phoneNumber
);
const selectEmail = createSelector(
  getAuthFeatureState,
  (state) => state.email
);

const verificationStatus = (state): VerificationStatus => {
  const { userDocument, registrationDate } = state;
  let accountVerificationStatus;

  if (userDocument) {
    const { verificationStatus, verificationDate } = userDocument;

    if (verificationStatus === 0) {
      accountVerificationStatus = VerificationStatus.UNVERIFIED;
      if (verificationDate) {
        accountVerificationStatus = VerificationStatus.REJECTED;
      } else {
        accountVerificationStatus = VerificationStatus.PENDING;
      }
    } else {
      accountVerificationStatus = VerificationStatus.VERIFIED;
    }
  }

  if (Array.isArray(userDocument) && !userDocument.length) {
    accountVerificationStatus = VerificationStatus.UNVERIFIED;
  }

  return accountVerificationStatus;
};

export const accountSelectors = {
  selectPersonalData,
  selectVerificationStatus,
  selectPinCode,
  selectUserName,
  selectCustomerId,
  selectAvatar,
  selectAccountBalance,
  selectVerificationActive,
  selectIdentificationStatus,
  selectPhoneNumber,
  selectEmail
};
