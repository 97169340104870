import { VerificationNavItem } from '../entity/verification-nav-item.interface';
import { generateVerificationNavItems } from '../functions/functions';

export const VERIFICATION_NAV: VerificationNavItem[] = [
  generateVerificationNavItems(
    'live',
    'verification_live',
    'ver.document_photo',
    'upload-video-selfie',
    'assets/icons/verification/liveness.svg'
  ),
  generateVerificationNavItems(
    'selfie',
    'verification_selfie',
    'ver.document_photo',
    'upload-selfie-with-document',
    'assets/icons/verification/selfie.svg'
  ),
  generateVerificationNavItems(
    'chat',
    'verification_chat',
    'ver.send_document_photo',
    'ver.selfie_and_document',
    'assets/icons/verification/chat.svg'
  )
];
