import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  EgtDigitalInterface,
  EgtJackpot,
  egtOdometerRefreshRate,
  pragmaticOdometerRefreshRate,
  JackpotInstance,
  OdometerData,
  odometerRefreshRate,
  PragmaticJackpot, CrocoRoulette, crocoRouletteOdometerRefreshRate, PatePlay, patePlayOdometerRefreshRate
} from '@crc/components';

import { isEmpty } from 'lodash';
import { JackpotService } from '../services/jackpot.service';
import { processJackpot } from '../functions/functions';
import { processEgtDigitalJackpot } from '../functions/processEgtDigitalJackpot';
import { processPragmaticJackpot } from '../functions/processPragmaticJackpot';
import { processPatePlayJackpot } from '../functions/processPatePlayJackpot';
import { processCrocoRouletteJackpot } from '../functions/processCrocoRouletteJackpot';

@Injectable({
  providedIn: 'root'
})
export class JackpotFacade {
  private readonly jackpot$ = new Subject<EgtJackpot>();
  private readonly egtDigitalJackpot$ = new Subject<EgtDigitalInterface>();
  private readonly pragmaticJackpot$ = new Subject<PragmaticJackpot>();
  private readonly patePlayJackpot$ = new Subject<PatePlay>();
  private readonly crocoRouletteJackpot$ = new Subject<CrocoRoulette>();

  private readonly requestUpdate$ = new Subject<void>();
  private readonly requestEgtDigitalUpdate$ = new Subject<void>();
  private readonly requestPragmaticUpdate$ = new Subject<void>();
  private readonly requestPatePlayUpdate$ = new Subject<void>();
  private readonly requestCrocoRouletteUpdate$ = new Subject<void>();

  private odometerRefreshRateIndex = 0;
  private egtDigitalOdometerRefreshRateIndex = 0;
  private pragmaticOdometerRefreshRateIndex = 0;
  private patePlayOdometerRefreshRateIndex = 0;
  private crocoRouletteOdometerRefreshRateIndex = 0;

  private readonly bellLink$ = new BehaviorSubject<JackpotInstance>(null);
  private readonly cloverChance$ = new BehaviorSubject<JackpotInstance>(null);
  private readonly highCash$ = new BehaviorSubject<JackpotInstance>(null);

  constructor(private readonly jackpotService: JackpotService) {}

  getJackpot$(): Observable<EgtJackpot | object> {
    return this.jackpot$.pipe(map((jackpot) => jackpot ?? ({} as EgtJackpot)));
  }

  getPragmaticJackpot$(): Observable<PragmaticJackpot> {
    return this.pragmaticJackpot$.pipe(
      map((jackpot) => jackpot ?? ({} as PragmaticJackpot))
    );
  }

  getPatePlayJackpot$(): Observable<PatePlay> {
    return this.patePlayJackpot$.pipe(map((jackpot) => jackpot ?? ({} as PatePlay)));
  }

  getCrocoRouletteJackpot$(): Observable<CrocoRoulette> {
    return this.crocoRouletteJackpot$.pipe(map((jackpot) => jackpot ?? ({} as CrocoRoulette)));
  }

  getBellJackpot$(): Observable<JackpotInstance[]> {
    return this.filterJackpot('Bell Link');
  }

  getCloverJackpot$(): Observable<JackpotInstance[]> {
    return this.filterJackpot('Clover Chance');
  }

  getHighCashJackpot$(): Observable<JackpotInstance[]> {
    return this.filterJackpot('High Cash');
  }

  filterJackpot(instanceName: string): Observable<JackpotInstance[]> {
    return this.egtDigitalJackpot$.pipe(
      map((payload) => {
        return (payload?.data?.jackpotInstancesStats?.['instanceStats']?.filter(
          (data: { instanceName?: string }) =>
            data?.instanceName === instanceName
        ) ?? []) as JackpotInstance[];
      })
    );
  }

  getBell(): Observable<OdometerData[]> {
    return this.getBellJackpot$().pipe(
      map((jackpot) =>
        this.getRefreshRate(jackpot, 'Bell Link', this.bellLink$)
      )
    );
  }

  getHighCash(): Observable<OdometerData[]> {
    return this.getHighCashJackpot$().pipe(
      map((jackpot) =>
        this.getRefreshRate(jackpot, 'High Cash', this.highCash$)
      )
    );
  }

  getClover(): Observable<OdometerData[]> {
    return this.getCloverJackpot$().pipe(
      map((jackpot) =>
        this.getRefreshRate(jackpot, 'Clover Chance', this.cloverChance$)
      )
    );
  }

  getPragmaticOdometer$(): Observable<OdometerData[]> {
    return this.getPragmaticJackpot$().pipe(
      map((jackpot) => {
        const dataRefreshRate: number =
          pragmaticOdometerRefreshRate[this.pragmaticOdometerRefreshRateIndex];
        if (isEmpty(jackpot)) {
          return [];
        }
        try {
          return (
            processPragmaticJackpot(jackpot, dataRefreshRate) ?? []
          ).filter((res) => !!res);
        } catch (error) {
          return [];
        }
      })
    );
  }

  getPatePlayOdometer$(): Observable<OdometerData[]> {
    return this.getPatePlayJackpot$().pipe(
      map(jackpot => {
        const dataRefreshRate: number = patePlayOdometerRefreshRate[this.patePlayOdometerRefreshRateIndex];
        if (isEmpty(jackpot)) {
          return [];
        }
        try {
          return (processPatePlayJackpot(jackpot, dataRefreshRate) ?? []).filter((res) => !!res);
        } catch (error) {
          return [];
        }
      })
    )
  }

   getCrocoRouletteOdometer$(): Observable<OdometerData[]> {
    return this.getCrocoRouletteJackpot$().pipe(
      map(jackpot => {
        const dataRefreshRate: number = crocoRouletteOdometerRefreshRate[this.crocoRouletteOdometerRefreshRateIndex];
        if (isEmpty(jackpot)) {
          return [];
        }
        try {
          return (processCrocoRouletteJackpot(jackpot, dataRefreshRate) ?? []).filter((res) => !!res);
        } catch (error) {
          return [];
        }
      })
    )
  }

  getRefreshRate(
    data: JackpotInstance[],
    name: string,
    instance: BehaviorSubject<JackpotInstance>
  ) {
    const dataRefreshRate: number =
      egtOdometerRefreshRate[this.egtDigitalOdometerRefreshRateIndex];

    data.map((data) => {
      if (data.instanceName === name) {
        instance.next(data);
      }
    });

    return processEgtDigitalJackpot(
      instance.value.levelStats,
      dataRefreshRate,
      name
    );
  }

  getOdometer$(): Observable<OdometerData[]> {
    return this.getJackpot$().pipe(
      map((jackpot) => {
        const dataRefreshRate: number =
          odometerRefreshRate[this.odometerRefreshRateIndex];
        return processJackpot(<EgtJackpot>jackpot, dataRefreshRate);
      })
    );
  }

  requestJackpotUpdate() {
    this.requestUpdate$.next();
  }

  requestEgtDigitalJackpotUpdate() {
    this.requestEgtDigitalUpdate$.next();
  }

  requestPragmaticJackpotUpdate() {
    this.requestPragmaticUpdate$.next();
  }

  requestPatePlayJackpotUpdate() {
    this.requestPatePlayUpdate$.next();
  }

  requestCrocoRouletteJackpotUpdate() {
    this.requestCrocoRouletteUpdate$.next();
  }

  calibrateRefreshRate() {
    this.odometerRefreshRateIndex = Math.min(
      odometerRefreshRate.length - 1,
      this.odometerRefreshRateIndex + 1
    );
  }

  calibrateEgtDigitalRefreshRate() {
    this.egtDigitalOdometerRefreshRateIndex = Math.min(
      egtOdometerRefreshRate.length - 1,
      this.egtDigitalOdometerRefreshRateIndex + 1
    );
  }

  calibratePragmaticRefreshRate() {
    this.pragmaticOdometerRefreshRateIndex = Math.min(
      pragmaticOdometerRefreshRate.length - 1,
      this.pragmaticOdometerRefreshRateIndex + 1
    );
  }

  calibratePatePlayRefreshRate() {
    this.patePlayOdometerRefreshRateIndex = Math.min(
      patePlayOdometerRefreshRate.length - 1,
      this.patePlayOdometerRefreshRateIndex + 1
    );
  }

  calibrateCrocoRouletteOdometerRefreshRate() {
    this.crocoRouletteOdometerRefreshRateIndex = Math.min(
      crocoRouletteOdometerRefreshRate.length - 1,
      this.crocoRouletteOdometerRefreshRateIndex + 1
    );
  }

  updateJackpot$() {
    return this.jackpotService
      .getJackpot()
      .pipe(tap((jackpot) => this.jackpot$.next(jackpot)));
  }

  updateEgtDigitalJackpot$() {
    return this.jackpotService
      .getEgtDigitalJackpot()
      .pipe(tap((jackpot) => this.egtDigitalJackpot$.next(jackpot)));
  }

  updatePragmaticDigitalJackpot$() {
    return this.jackpotService
      .getPragmaticJackpot()
      .pipe(tap((jackpot) => this.pragmaticJackpot$.next(jackpot)));
  }

  updatePatePlayJackpot$() {
    return this.jackpotService
      .getPatePlayJackpot()
      .pipe(tap((jackpot) => this.patePlayJackpot$.next(jackpot)));
  }

  updateCrocoRouletteJackpot$() {
    return this.jackpotService
      .getCrocoRouletteJackpot()
      .pipe(tap((jackpot) => this.crocoRouletteJackpot$.next(jackpot)));
  }
}
