import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, retry, share, switchMap, throwError, timer } from 'rxjs';
import { BaseUrl, EnvironmentService, HttpService } from '@crc/shared';

import { AuthResponse, EnteredCaptchaModel, SignInPayload } from '../entity';
import { IdentificationSMS } from '../../features';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly enableTest = this.env.config.enableAuthTestApi
    ? '?env=test'
    : '';
  private readonly getUser = this.apiService
    .get$<AuthResponse>({
      path: '/api-gateway/customer/account/personal-data',
      baseUrl: 'cmsApiUrl'
    })
    .pipe(
      switchMap((response: AuthResponse) => {
        if (response.code === 418) {
          return throwError(() => new Error());
        } else {
          return of(response);
        }
      }),
      retry(1),
      share({
        connector: () => new ReplaySubject(1),
        resetOnComplete: () => timer(1000)
      })
    );
  private readonly getDocument = this.apiService.get$<AuthResponse>({
    baseUrl: 'cmsApiUrl',
    path: '/api-gateway/customer/account/get-document-scan/1'
  });

  constructor(
    private readonly apiService: HttpService,
    private readonly env: EnvironmentService
  ) {
  }

  getUser$(): Observable<AuthResponse> {
    return this.getUser;
  }

  getUserDocument(): Observable<AuthResponse> {
    return this.getDocument;
  }

  login$(body: SignInPayload): Observable<AuthResponse> {
    const path = '/login' + this.enableTest;
    const baseUrl: BaseUrl = 'authApiUrl';

    return this.apiService.post$({ path, baseUrl, body });
  }

  loginWithSmsCode$(body: SignInPayload): Observable<AuthResponse> {
    const path = '/login' + this.enableTest;
    const baseUrl: BaseUrl = 'authApiUrl';

    return this.apiService.post$({ path, baseUrl, body });
  }

  signOut$(): Observable<unknown> {
    const path = '/logout' + this.enableTest;
    const baseUrl: BaseUrl = 'authApiUrl';
    const body = {};
    return this.apiService.post$({ path, baseUrl, body });
  }

  checkCaptchaValidity$(body: EnteredCaptchaModel): Observable<AuthResponse> {
    const path = '/captcha/check';
    const baseUrl: BaseUrl = 'authApiUrl';
    return this.apiService.post$({ body, path, baseUrl });
  }

  resetCaptchaCode$(login: string): Observable<AuthResponse> {
    const path = '/captcha/reload' + this.enableTest;
    const baseUrl: BaseUrl = 'authApiUrl';
    const body = { login };
    return this.apiService.post$({ path, baseUrl, body });
  }

  sendVerificationCode(): Observable<unknown> {
    const path = '/customers/account/phone-number/send-verification-code';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const body = {};
    return this.apiService.post$({ path, baseUrl, body });
  }

  sendPhoneNumberCodeVerify(code: string): Observable<IdentificationSMS> {
    const path = '/customers/account/phone-number/verify';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    const body = { verificationCode: code };
    return this.apiService.post$({ path, baseUrl, body });
  }

  getBalance(): Observable<AuthResponse> {
    const path = '/api-gateway/customer/session/customer-data/false';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.get$({ path, baseUrl });
  }
}
