<div
  class="game-search"
  (mouseleave)="onMouseLeave()"
  (mouseenter)="onMouseEnter()"
>
  <div class="game-search__input">
    <input
      (focus)="focusEvent.emit($event)"
      (blur)="blurEvent.emit($event)"
      (keydown)="handleKeyDown($event)"
      class="game-search__text"
      [formControl]="inputValue"
      [placeholder]="'search' | translate"
      [ngClass]="{
        'game-search__text--restyle':
          gameContentVisibility && searchGameData?.length !== 0
      }"
      type="text"
      data-cy="game-search__text"
    />
    <img
      *ngIf="iconVisibility"
      class="game-search__img"
      src="assets/icons/gameSearch/search.svg"
      alt="Game Search"
    />

    <img
      *ngIf="!iconVisibility"
      class="game-search__img close"
      src="assets/icons/gameSearch/cancel.svg"
      alt="Cancel"
      (click)="onClearAll()"
    />
  </div>

  <div
    *ngIf="outsideSearchState || gameContentVisibility && searchGameData?.length !== 0"
    class="game-search__content"
    [class.none]="!searchGameData?.length"
    [ngClass]="contentHeight"
    (mousedown)="$event.preventDefault()"
  >
    <div
      *ngFor="let gameData of searchGameData"
      class="game-search__content--item"
      (mouseup)="onGameSelect(gameData)"
    >
      <img
        class="game-search__content--img"
        [src]="gameData.image"
        alt="Game Icon"
      />
      <div class="game-search__content--titles">
        <span class="game-search__provider">{{ gameData.providerName }}</span>
        <span class="game-search__name">{{ gameData?.gameName }}</span>
      </div>
      <div class="game-search__arrow">
        <img src="assets/icons/gameSearch/arrow.svg" alt="Game Search Arrow" />
      </div>
    </div>
  </div>
</div>
