import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Output
} from '@angular/core';
import { SharedModule } from '../shared.module';
import { NgSyncDirective } from '@crc/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { accountSelectors } from '@crc/facade';
import { map } from 'rxjs/operators';
@Component({
  selector: 'crc-sms-on-v2-reconfirm',
  standalone: true,
  imports: [SharedModule, NgSyncDirective],
  templateUrl: './sms-on-reconfirm-v2.component.html',
  styleUrls: ['./sms-on-reconfirm-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmsOnReconfirmV2Component {
  private mainBlockIsVisible: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );
  protected readonly mainBlockIsVisible$ =
    this.mainBlockIsVisible.asObservable();
  protected readonly SMS_ON_KEY_DESCRIPTION = 'sms-on-v2-description.text';
  protected readonly SMS_ON_KEY_AGREE_BUTTON = 'sms-on-v2-agree-button.text';
  protected readonly SMS_ON_KEY_DISAGREE_BUTTON =
    'sms-on-v2-disagree-button.text';
  protected readonly SMS_ON_KEY_DISAGREE_DESCRIPTION =
    'sms-on-v2-disagreeblock-description.text';

  @Output() protected readonly userAction: EventEmitter<{
    status: boolean;
    text: string;
  }> = new EventEmitter();

  protected readonly mobileNumber$: Observable<string> = inject(Store)
    .select(accountSelectors.selectPhoneNumber)
    .pipe(
      map((val) => {
        return val?.includes?.(':') ? val?.split?.(':')?.[1] ?? '' : val;
      })
    );

  protected onFirstdesagree(): void {
    this.mainBlockIsVisible.next(false);
  }
  protected onSecondDisagree(): void {
    this.mainBlockIsVisible.next(true);
  }
}
