import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  OdometerData, PatePlay, PatePlayOdometerConfigI
} from '@crc/components';

@Component({
  selector: 'crc-shared-pate-play-odometer-presentation',
  templateUrl: './pate-play-odometer-presentation.component.html',
  styleUrl: './pate-play-odometer-presentation.component.scss'
})
export class PatePlayOdometerPresentationComponent {
  @Input() odometerData: OdometerData[];
  @Input() odometerCount: number;
  @Input() odometers: PatePlayOdometerConfigI[];
  @Input() jackpot: PatePlay[];
  @Input() disableLogo: boolean;
  @Input() vertical: boolean;
  @Input() isMainPage: boolean;

  @Output() refreshOdometer: EventEmitter<string> = new EventEmitter();

  emitRefreshOdometer() {
    this.refreshOdometer.emit();
  }
}
