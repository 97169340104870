import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { EgtDigitalOdometerConfig } from '../../entity/odometer.interface';
import { fadeInAndOutBottomTop } from '@crc/shared';
import { JackpotInstance } from '../../entity';

@Component({
  selector: 'crc-egt-digital-odometer-presentation',
  templateUrl: './egt-digital-odometer-presentation.component.html',
  styleUrls: ['./egt-digital-odometer-presentation.component.scss'],
  animations: [fadeInAndOutBottomTop(200)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EgtDigitalOdometerPresentationComponent {
  @Input() type: string;
  @Input() odometerData: any;
  @Input() odometerCount: number;
  @Input() odometers: EgtDigitalOdometerConfig[];
  @Input() jackpot: JackpotInstance[];
  @Input() disableLogo: boolean;
  @Input() showPopupInfo: boolean;
  @Input() vertical: boolean;
  @Input() isMainPage: boolean;

  @Output() refreshOdometer: EventEmitter<string> = new EventEmitter();

  isOverlayOpen: boolean[] = [];

  emitRefreshOdometer() {
    this.refreshOdometer.emit();
  }
}
