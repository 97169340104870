import { MiniGames } from './mini.interface';

export const SPACE_TAXI_GAME_CONFIG: Pick<MiniGames, 'game_id' | 'name'> = {
  ['game_id']: '1698',
  ['name']: 'SpaceTaxi'
} as const;

export const UFO_GAME_CONFIG: Pick<MiniGames, 'game_id' | 'name'> = {
  ['game_id']: '1702',
  ['name']: 'UFO'
} as const;

export function isMiniGameUfo(miniGame: MiniGames): boolean {
  return (
    String(miniGame?.['game_id']) === UFO_GAME_CONFIG['game_id'] &&
    miniGame?.name === UFO_GAME_CONFIG.name
  );
}

export function isMiniGameSpaceTaxi(miniGame: MiniGames): boolean {
  return (
    String(miniGame?.['game_id']) === SPACE_TAXI_GAME_CONFIG['game_id'] &&
    miniGame?.name === SPACE_TAXI_GAME_CONFIG.name
  );
}
