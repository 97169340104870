import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  Observable,
  of,
  switchMap,
  tap
} from 'rxjs';

import {
  loadBuyBonus,
  selectBuyBonusActive,
  selectBuyBonusData,
  setBonusBuyState,
  SlotsState
} from '../index';
import { map } from 'rxjs/operators';
import {
  MoreGamesFacade,
  slotProviderIcon,
  SlotsFiltersFacade
} from '@crc/facade';

@Injectable({
  providedIn: 'root'
})
export class SlotsStateFacade {
  private readonly $providerIcon = new BehaviorSubject<slotProviderIcon>({
    icon: null,
    iconBgColor: false
  });
  private readonly $isActiveGreen = new BehaviorSubject<boolean>(false);
  public $customBuyBonusUrl = new BehaviorSubject<string>(null);
  public $customBuyBonus = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly store: Store<SlotsState>,
    private readonly route: ActivatedRoute,
    private readonly slotsFiltersFacade: SlotsFiltersFacade,
    private readonly moreGamesFacade: MoreGamesFacade,
    private readonly router: Router
  ) {}

  setActiveGreen(state: boolean) {
    this.$isActiveGreen.next(state);
  }
  get activeGreen$(): Observable<boolean> {
    return this.$isActiveGreen.pipe(distinctUntilChanged());
  }

  loadBuyBonus() {
    this.store.dispatch(loadBuyBonus());
  }

  getBuyBonusProviders() {
    return this.store.select(selectBuyBonusData).pipe(map((e) => (e ? e : [])));
  }

  getHasBuyBonus(): Observable<boolean> {
    return this.store.select(selectBuyBonusData).pipe(
      switchMap((providers) =>
        this.$customBuyBonusUrl.pipe(
          switchMap((url) => {
            return this.route.queryParams.pipe(
              map((params) => {
                if (!providers) {
                  return false;
                }

                if (this.moreGamesFacade.getPopupStateValue()) {
                  return !!providers[url];
                } else {
                  return !!providers[params.filter];
                }
              })
            );
          })
        )
      )
    );
  }

  getActiveBuyBonus() {
    return combineLatest([
      this.route.queryParams,
      this.store.select(selectBuyBonusData),
      this.slotsFiltersFacade.getProviderLogos()
    ]).pipe(
      tap(([params, _, providerLogos]) => {
        if (!this.moreGamesFacade.getPopupStateValue()) {
          let doesAnyLogoMatch = false;
          providerLogos?.map((logo) => {
            if (params?.filter && logo?.includes(`${params?.filter}/`)) {
              doesAnyLogoMatch = true;
              this.setProviderIcon(logo, false);
            }
          });
          if (!doesAnyLogoMatch) {
            this.setProviderIcon(null, false);
          }
        }
      }),
      map(([params, providers]) => {
        if (!providers || !params) {
          return of(null);
        }

        if (this.moreGamesFacade.getPopupStateValue()) {
          return providers[this.moreGamesFacade.getFilterCategoryValue()];
        } else {
          return providers[params.filter];
        }
      })
    );
  }

  isBuyBonusActive() {
    return this.store.select(selectBuyBonusActive).pipe(
      switchMap(() => {
        return this.$customBuyBonus.pipe(
          switchMap((state) => {
            if (this.moreGamesFacade.getPopupStateValue()) {
              return of(state);
            } else {
              return this.route.queryParams.pipe(
                map((queryParams) => {
                  return queryParams.buyBonus === 'true';
                })
              );
            }
          })
        );
      })
    );
  }

  setBuyBonusState(status: boolean) {
    this.store.dispatch(setBonusBuyState({ status }));
  }

  setProviderIcon(icon: string | null, iconBgColor?: boolean) {
    this.$providerIcon.next({
      icon: icon,
      iconBgColor: iconBgColor
    });
  }

  getProviderIcon$(): Observable<slotProviderIcon> {
    return this.$providerIcon.asObservable();
  }

  addBuyBonusStateIntoTheUrl(state) {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: {
          buyBonus: state
        },
        queryParamsHandling: 'merge'
      })
      .then((r) => r);
  }
}
