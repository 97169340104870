import { inject } from '@angular/core';
import { UserRestrictionsInterface } from '../interface/user-restrictions-interfaces';
import { UserRestrictionsFacade } from '../facade/user-restrictions.facade';
import { Router } from '@angular/router';
import { ENV_CONFIG, EnvironmentConfig, nextTick } from '@crc/shared';
import { DOCUMENT } from '@angular/common';

export function CanEnterChangePasswordRestrictedZoneGuard(): boolean {
  const userRestrictionsFacade: UserRestrictionsFacade = inject(
    UserRestrictionsFacade
  );
  const latestUserRestrictionDTO =
    userRestrictionsFacade.getLatestUserRestrictionsDTO();
  const needsToBeActive =
    latestUserRestrictionDTO.includes(
      UserRestrictionsInterface.UPDATE_PASSWORD_REQUIRED
    ) ||
    latestUserRestrictionDTO.includes(
      UserRestrictionsInterface.PROMPT_ABOUT_SMS_OFF
    ) ||
    latestUserRestrictionDTO.includes(
      UserRestrictionsInterface.PROMPT_ABOUT_SMS_ON
    ) ||
    latestUserRestrictionDTO.includes(
      UserRestrictionsInterface.PROMPT_ABOUT_SMS_ON_V2
    );
  const __doc__: Document = inject(DOCUMENT);
  if (!needsToBeActive) {
    const router: Router = inject(Router);
    const __env__: EnvironmentConfig = inject(ENV_CONFIG);
    nextTick(async (): Promise<void> => {
      // const layoutTag = __env__.platform === 'mobile' ? 'crc-m-layout-container' : 'crc-layout';
      if (
        (router.url === '/' || router.url === '') &&
        !(__env__.platform === 'mobile'
          ? false
          : __doc__.body.querySelector('crc-layout'))
      ) {
        return void (await router.navigateByUrl('', {
          onSameUrlNavigation: 'reload'
        }));
      }
    }, 1500);
  }
  return needsToBeActive;
}
