import {
  Directive,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AsyncSubject, switchMap, takeWhile, timer } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
@UntilDestroy()
@Directive({
  selector: '[crcSharedCountDown]',
  standalone: true,
  exportAs: 'countDownInnerData'
})
export class CountDownDirective implements OnInit {
  private readonly $asyncSubject = new AsyncSubject<void>();
  @Input({
    alias: 'crcSharedCountDown'
  })
  startTimeInSeconds!: number;
  @Output() timerEnd = new EventEmitter();

  public readonly countUp$ = toSignal(
    this.$asyncSubject.asObservable().pipe(
      switchMap(() => {
        return timer(0, 1000).pipe(
          takeWhile((time) => {
            return time <= this.startTimeInSeconds;
          })
        );
      })
    ),
    {
      initialValue: 0
    }
  );
  public ngOnInit(): void {
    this.$asyncSubject.next();
    this.$asyncSubject.complete();
  }

  constructor() {
    effect(() => {
      if (this.countUp$() >= this.startTimeInSeconds) {
        this.timerEnd.emit();
      }
    });
  }
}
