<!--  [autoOTPStatus]="autoOTPStatus$ | async"-->
<crc-m-auth-otp-presentation
  [form]="form"
  [smsStatus]="sendSMS$ | async"
  [submitStatus]="formSubmit$ | async"
  [isLoading]="isLoading"
  [timerResetTrigger]="timerResetTrigger$ | async"
  [isCaptchaValid]="isCaptchaValid$ | async"
  [isCaptchaActive]="isCaptchaActive$ | async"
  [captchaCode]="captchaCode$ | async"
  (captchaValueEntered)="handleCaptchaValueEnter($event)"
  (updateCaptcha)="handleUpdateCaptcha()"
  (captchaValidValueEnter)="handleCaptchaValidValueEnter()"
  (returnToDefaultProtocol)="handleReturnToDefaultProtocol()"
></crc-m-auth-otp-presentation>
<div class="alert-info" *ngIf="smsSentAlert$ | async">
  <div class="alert-info__img">
    <img src="/assets/icons/shapes/checked.svg" alt="" />
  </div>
  <span class="alert-info__text">{{ 'sms_code_sent' | translate }}</span>
</div>
