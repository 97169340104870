import { JackpotOdometerData, PatePlay } from '@crc/components';

const digitalOffsetLevels = {
  LEVEL_ONE_OFFSET: 206,
  LEVEL_TWO_OFFSET: 180,
  LEVEL_THREE_OFFSET: 170,
  LEVEL_FOUR_OFFSET: 140
};

export function processPatePlayJackpot(jackpot: PatePlay, dataRefreshRate: number): JackpotOdometerData[] {
  if (!jackpot || !jackpot.pools || jackpot.pools.length === 0) {
    return [];
  }

  const tier0: number = jackpot.pools[0]?.amount ? parseFloat(Number(jackpot.pools[0].amount).toFixed(2).replace('.', '')) : 0;
  const tier1: number = jackpot.pools[1]?.amount ? parseFloat(Number(jackpot.pools[1].amount).toFixed(2).replace('.', '')) : 0;
  const tier2: number = jackpot.pools[2]?.amount ? parseFloat(Number(jackpot.pools[2].amount).toFixed(2).replace('.', '')) : 0;
  const tier3: number = jackpot.pools[3]?.amount ? parseFloat(Number(jackpot.pools[3].amount).toFixed(2).replace('.', '')) : 0;

  const startNumberLevelI: number = Math.max(tier0 - digitalOffsetLevels.LEVEL_ONE_OFFSET, 0);
  const endNumberLevelI: number = tier0;
  const digitCountLevelI = 10;

  const odometerDataLevelI: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelI,
    startNumber: startNumberLevelI,
    endNumber: endNumberLevelI
  };

  const startNumberLevelII: number = Math.max(tier1 - digitalOffsetLevels.LEVEL_TWO_OFFSET, 0);
  const endNumberLevelII: number = tier1;
  const digitCountLevelII = 10;

  const odometerDataLevelII: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelII,
    startNumber: startNumberLevelII,
    endNumber: endNumberLevelII
  };

  const startNumberLevelIII: number = Math.max(tier2 - digitalOffsetLevels.LEVEL_THREE_OFFSET, 0);
  const endNumberLevelIII: number = tier2;
  const digitCountLevelIII = 10;

  const odometerDataLevelIII: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelIII,
    startNumber: startNumberLevelIII,
    endNumber: endNumberLevelIII
  };

  const startNumberLevelIV: number = Math.max(tier3 - digitalOffsetLevels.LEVEL_FOUR_OFFSET, 0);
  const endNumberLevelIV: number = tier3;
  const digitCountLevelIV = 10;

  const odometerDataLevelIV: JackpotOdometerData = {
    dataRefreshRate,
    digitCount: digitCountLevelIV,
    startNumber: startNumberLevelIV,
    endNumber: endNumberLevelIV
  };

  const newOdometerData: Array<JackpotOdometerData> = [];
  newOdometerData.push(odometerDataLevelI);
  newOdometerData.push(odometerDataLevelII);
  newOdometerData.push(odometerDataLevelIII);
  newOdometerData.push(odometerDataLevelIV);

  return newOdometerData;
}
