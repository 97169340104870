import { Injectable } from '@angular/core';
import { merge, Observable, of, switchMap, take, timer } from 'rxjs';
import { debounceTime, map, mapTo } from 'rxjs/operators';

import { EnvironmentService, LanguageFacade, Platform } from '@crc/shared';

import { BannerService } from '../services/banner.service';
import { BannerType, BannerView } from '../entity';
import { AccountFacade } from '../../../auth';
import { mapBannersToBannersView } from '../functions/functions';

@Injectable({
  providedIn: 'root'
})
export class BannerFacade {
  constructor(
    private readonly service: BannerService,
    private readonly languageService: LanguageFacade,
    private readonly env: EnvironmentService,
    private readonly accountFacade: AccountFacade
  ) {}
  logiName$ = this.accountFacade.userName$;

  getBanners(
    bannerType: BannerType,
    platform: Platform = this.env.config.platform
  ): Observable<BannerView[]> {
    return this.accountFacade.customerId$.pipe(
      debounceTime(200),
      switchMap((customerId) =>
        this.languageService.current$.pipe(
          switchMap((lang) =>
            this.service.getBanners(platform, bannerType, lang, customerId)
          ),
          switchMap((banners) =>
            this.accountFacade.customerId$.pipe(
              take(1),
              map((customerId) => {
                return mapBannersToBannersView(
                  banners,
                  customerId,
                  this.accountFacade.getToken(),
                  platform
                );
              }),
              switchMap((banners) =>
                merge(of(null), timer(1).pipe(mapTo(banners)))
              )
            )
          )
        )
      )
    );
  }
}
