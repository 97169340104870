import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ExchangeOption } from '../../types/step.type';

@Component({
  selector: 'crc-shared-bonus-coin-swiper',
  templateUrl: './bonus-coin-swiper.component.html',
  styleUrls: ['./bonus-coin-swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusCoinSwiperComponent implements OnChanges {
  @Input() isMobile: boolean;
  @Input() set cards(value: ExchangeOption[]) {
    this.bonusCards = value;
    this.cdr.markForCheck();
  }
  @Input() activeBalanceId: string = null;

  @Output() rateChange = new EventEmitter<number>();
  @Output() SwiperCardClick = new EventEmitter<{
    balanceType: string;
    landingUrl: string;
  }>();
  @Output() selectedBalance = new EventEmitter<{
    fromBalanceId: string;
    toBalanceID: string;
  }>();
  bonusCards: ExchangeOption[] = [];
  constructor(private readonly cdr: ChangeDetectorRef) {}
  //TODO REFACTOR
  onSwiperCard(
    balanceID: string,
    rate: number,
    selectedBalance: { fromBalanceId: string; toBalanceID: string },
    balanceType: string,
    landingUrl: string
  ) {
    this.selectedBalance.emit(selectedBalance);
    this.rateChange.emit(rate);
    this.activeBalanceId = balanceID;
    this.SwiperCardClick.emit({
      balanceType: balanceType,
      landingUrl: landingUrl
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.activeBalanceId?.currentValue) {
      this.selectedBalance.emit({
        fromBalanceId: 'BONUS_COIN',
        toBalanceID: this.activeBalanceId
      });
      this.rateChange.emit(1);
    }
    if (this.bonusCards) {
      const activePromo = this.bonusCards.filter(
        (res) => res.balanceId === this.activeBalanceId
      );
      this.SwiperCardClick.emit({
        balanceType: activePromo[0]?.visualDetails?.iconType,
        landingUrl: activePromo[0]?.promotionDetails?.landingUrl
      });
    }
  }
}
