import { odometerConfig } from '@crc/components';

export const CrocoRouletteOdometerConfig: odometerConfig[] = [
  {
    index: 0,
    icon: 'silver.svg',
    iconSmall: 'silver.svg',
    listenToAnimation: true,
    level: 'LevelI',
    image: ''
  },
  {
    index: 1,
    icon: 'gold.svg',
    iconSmall: 'gold.svg',
    listenToAnimation: false,
    level: 'LevelIV',
    image: ''
  }
];
