import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  containsValueValidator,
  CustomValidators,
  EMAIL_REGEXP,
  isAdultValidator,
  matchControlsValidator
} from '@crc/components';

@Injectable({
  providedIn: 'root'
})
export class RegisterFormHelper {
  constructor(private readonly fb: UntypedFormBuilder) {}

  public readonly countryCodeInitialValue = 'GE';

  //=================================================================================
  public generatePersonalInfoControls(phoneControlConfigProperty: boolean): {
    [key: string]: AbstractControl;
  } {
    return {
      firstName: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        CustomValidators.patternValidator(/^[a-zA-Z -]+$/, {
          latin: true
        }),
        CustomValidators.patternValidator(/^[^ -]/, {
          firstLetter: true
        })
      ]),
      lastName: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        CustomValidators.patternValidator(/^[a-zA-Z -]+$/, {
          latin: true
        }),
        CustomValidators.patternValidator(/^[^ -]/, {
          firstLetter: true
        })
      ]),
      countryCode: new UntypedFormControl(this.countryCodeInitialValue, [
        Validators.required
      ]),
      nationality: new UntypedFormControl(this.countryCodeInitialValue, [
        Validators.required
      ]),
      ...this.generatePhoneNumberControl(phoneControlConfigProperty),
      documentId: new UntypedFormControl(''),
      promoCodeWeb: new UntypedFormControl('', {
        validators: [
          CustomValidators.patternValidator(/^[A-Za-z\d ]*$/, {
            pattern: true
          }),
          CustomValidators.patternValidator(/^[^ ]/, {
            firstLetter: true
          }),
          CustomValidators.patternValidator(/^.{2,}$/, {
            minLength: true
          }),
          CustomValidators.patternValidator(/^.{0,40}$/, {
            maxLength: true
          })
        ]
      }),
      day: new UntypedFormControl(null, [Validators.required]),
      month: new UntypedFormControl(null, [Validators.required]),
      year: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.patternValidator(EMAIL_REGEXP, {
          pattern: true
        })
      ]),
      freeBetSpinChoice: new UntypedFormControl(3)
    };
  }

  public generateAccountDetailsControls(): {
    [key: string]: AbstractControl;
  } {
    return {
      loginName: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_-]+$'),
        Validators.minLength(4),
        Validators.maxLength(30)
      ]),
      pinCode: new UntypedFormControl(null),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
        CustomValidators.patternValidator(/\d/, { number: true }),
        CustomValidators.patternValidator(/[A-Z]/, { upperCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { lowerCase: true }),
        CustomValidators.patternValidator(/^[ -~]+$/, {
          latin: true
        }),
        CustomValidators.patternValidator(/^[^)]*$/, {
          noclosingparenthesis: true
        })
      ]),
      confirmPassword: new UntypedFormControl('', Validators.required),
      iAgree: new UntypedFormControl(true, Validators.requiredTrue),
      ageAgree: new UntypedFormControl(true, Validators.requiredTrue),
      agreementCheckbox: new UntypedFormControl(true)
    };
  }

  public generateRegistrationValidators(): ValidatorFn[] {
    return [
      isAdultValidator('day', 'month', 'year', 'countryCode'),
      matchControlsValidator(
        { controlName: 'password', addError: false },
        { controlName: 'confirmPassword', addError: true }
      ),
      containsValueValidator('loginName', 'password')
    ];
  }

  public generatePhoneNumberControl(oldInterface: boolean): {
    [key: string]: AbstractControl | FormGroup;
  } {
    return oldInterface
      ? {
          phoneNumber: this.fb.group({
            code: new UntypedFormControl('995', [Validators.required]),
            value: new UntypedFormControl('', [
              Validators.required,
              CustomValidators.patternValidator(/^[5][0-9]{8}$/, {
                pattern: true
              })
            ])
          })
        }
      : {
          phoneNumber: new UntypedFormControl('', {
            validators: [
              Validators.required,
              CustomValidators.patternValidator(/^[5][0-9]{8}$/, {
                pattern: true
              })
            ],
            updateOn: 'blur'
          })
        };
  }
}
