import { Card } from '../../cards';

export enum PaymentProvidersById {
  BOG_DEPOSIT = 85,
  BOG_WITHDRAW = 86,
  TBC_DEPOSIT = 65,
  TBC_WITHDRAW = 66,
  ANY_DEPOSIT = 65,
  ANY_WITHDRAW = 66
}

export enum PaymentProvidersByKey {
  BOG_DEPOSIT = 'BOG_DEPOSIT',
  BOG_WITHDRAW = 'BOG_WITHDRAW',
  TBC_DEPOSIT = 'TBC_DEPOSIT',
  TBC_WITHDRAW = 'TBC_WITHDRAW',
  ANY_DEPOSIT = 'ANY_DEPOSIT',
  ANY_WITHDRAW = 'ANY_WITHDRAW'
}

export interface BankProvider {
  key: string;
  logo: string;
  minAmount: number;
  maxTotalAmountOnAttempts: number;
  providerDisabled?: boolean;
}

export interface PaymentProcessType {
  amount: number;
  card: Card | BankProvider;
  saveCard: boolean;
  formIsInvalid?: boolean;
  commission?: number;
}

export interface BankProvidersData {
  active: boolean;
  currencies: ['GEL'];
  isDeposit: true;
  limits: {
    transferNumberLimitAttempts: number;
    fee: number;
    minAmount: number;
    maxTotalAmountOnAttempts: number;
  };
  providerId: number;
  providerName: string;
  settings: {
    visible: boolean;
  };
  providerDisabled?: boolean;
}

export interface PaymentRequestObj {
  providerId: number | any;
  transactionAmount: number;
  transactionCurrency: string;
  callbackUrl?: string;
  card?: {
    activeCard?: boolean;
    creditCardId?: number;
  };
  accountId?: number;
  customer?: {
    pinCode?: number;
  };
  verificationCode?: string;
  success?: boolean;
}

export interface PaymentProviderResponse {
  transactionId?: number;
  responseCode?: number;
  responseText?: string;
  remoteTransactionId?: string;
  providerId: number;
  transactionAmount: number;
  remoteTransactionAmount: number;
  transactionCurrency: string;
  remoteTransactionCurrency: string;
  isBettingShopTransaction: boolean;
  isOnlineShopTransaction: boolean;
  isAgentConsoleTransaction: boolean;
  isAreaManagerTransaction: boolean;
  externalRequestUrl?: string;
  externalRequestForm?: string;
  success: boolean;
  manuallyFinished: boolean;
  balanceTypeId: string;
  unknownProperties?: any;
}
