import { Directive } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthFacade, CaptchaFacade } from '../facade';
import { LoginModeChangeDTO } from '../entity';
import { LoginOtpFacade } from '../facade';

@Directive()
export class LoginBaseContainerComponent {
  loginFailed$: Observable<boolean> = this.authFacade.getLoginFailed$();
  loginBlockedByUnknownEntity$: Observable<boolean> =
    this.authFacade.getLoginBlockedByUnknownEntity$();
  showAuthModal$: Observable<boolean>;
  customerId$: Observable<number | null> = this.authFacade.getCustomerId();
  smsCodeVerified$: Observable<boolean> = this.authFacade.getSmsCodeVerified();

  captchaCode$: Observable<string> = this.captchaFacade.captchaCode$;
  enteredCaptchaValid$: Observable<boolean> =
    this.captchaFacade.isCaptchaCheckedSuccess;
  public readonly loginModeChangeDTO: LoginModeChangeDTO = {};
  constructor(
    protected readonly authFacade: AuthFacade,
    protected readonly captchaFacade: CaptchaFacade,
    protected readonly loginOTPFacade: LoginOtpFacade
  ) {}
  public switchOTPState(
    status: boolean,
    loginModeChangeDTO?: LoginModeChangeDTO
  ): void {
    Object.assign(this.loginModeChangeDTO, loginModeChangeDTO);
    this.loginOTPFacade.setOTPState(status);
  }
}
