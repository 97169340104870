import { FilterItemView, generateFilterItemViews } from '@crc/components';
import { DEFAULT_SLOT_CATEGORY_FILTER } from './default-slot-category-filter';

export const SLOTS_CATEGORY_FILTER_ITEMS: FilterItemView[] = [
  DEFAULT_SLOT_CATEGORY_FILTER,
  generateFilterItemViews(
    '2',
    'favorites',
    'assets/icons/features/slots/filters/favorites.svg',
    undefined,
    'slot:favorites'
  ),
  generateFilterItemViews(
    '3',
    'new_games',
    'assets/icons/features/slots/filters/new-games.svg',
    undefined,
    'web:new_games'
  ),
  generateFilterItemViews(
    '4',
    'buy_bonus',
    'assets/icons/features/slots/filters/buy-bonus.svg',
    undefined,
    'web:buy_bonus'
  ),
  generateFilterItemViews(
    '5',
    'history',
    'assets/icons/features/slots/filters/history.svg',
    undefined,
    'slot:history'
  )
];
