export enum BalanceType {
  BALANCE_ADDED = 'BALANCE_ADDED',
  BALANCE_USED = 'BALANCE_USED',
  BALANCE_EXCHANGED = 'BALANCE_EXCHANGED',
  BALANCE_TRANSFERRED = 'BALANCE_TRANSFERRED'
}

export interface BalanceEventsAmountData {
  toAmount: number;
  toBalanceId: string;
  referenceType?: string;
}

export interface BalanceEventsData {
  id: string;
  type: string;
  accountId: string;
  balanceId: string;
  period: string;
  streamId: string;
  streamPosition: string;
  timestamp: Date;
  data: BalanceEventsAmountData;
  metadata: object;
}

export interface BalanceEventsResponse {
  data: BalanceEventsData[];
}
export interface ExchangeBody {
  toBalanceId: string;
  toAmount: number;
}

export interface BonusCoinHistoryData {
  date: string;
  quantity: number;
  promoName: string;
  id: string;
}
