import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  ViewChild,
  ComponentRef,
  OnDestroy
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  CaptchaFacade,
  LoginModeChangeDTO,
  loginModeChangeHelper,
  OTPAuthResponseInterface,
  OTPSendResponseInterface,
  USERNAME_VALIDATORS
} from '@crc/facade';
import { LoginOtpFacade } from '@crc/facade';
import {
  delayWhen,
  filter,
  from,
  Observable,
  of,
  share,
  startWith,
  switchMap,
  tap,
  timer
} from 'rxjs';
import { AuthOtpPresentationComponent } from '../../presentations/auth-otp-presentation/auth-otp-presentation.component';
import { combineLatest } from 'rxjs';
import {
  AnalyticsService,
  handleMarkingControlsAsTouched,
  MatomoAnalyticsService
} from '@crc/shared';
@Component({
  selector: 'crc-m-auth-otp-container',
  templateUrl: './auth-otp-container.component.html',
  styleUrls: ['./auth-otp-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthOtpContainerComponent implements AfterViewInit, OnDestroy {
  public readonly isCaptchaActive$: Observable<boolean> =
    this.loginOtpFacade.getCaptchaActiveState$();
  public readonly captchaCode$: Observable<string> =
    this.captchaFacade.captchaCode$;
  public readonly isCaptchaValid$: Observable<boolean> =
    this.captchaFacade.isCaptchaCheckedSuccess;
  public sendSMS$!: Observable<OTPSendResponseInterface | null>;
  public formSubmit$!: Observable<OTPAuthResponseInterface | null>;
  public smsSentAlert$!: Observable<boolean>;
  // public autoOTPStatus$: Observable<string> = of(null);
  public isLoading = false;
  @ViewChild(AuthOtpPresentationComponent)
  private readonly OTPComRef!: ComponentRef<AuthOtpPresentationComponent>;
  @Input() private set loginModeChangeDTO(
    loginModeChangeDTO: LoginModeChangeDTO
  ) {
    loginModeChangeHelper(this.form.get('userName'), loginModeChangeDTO);
  }
  @Output() public returnToDefaultProtocol: EventEmitter<LoginModeChangeDTO> =
    new EventEmitter<LoginModeChangeDTO>();
  public readonly form: UntypedFormGroup = new UntypedFormGroup({
    userName: new UntypedFormControl(null, USERNAME_VALIDATORS),
    passwordOTP: new UntypedFormControl(null, [Validators.required])
  });
  public readonly timerResetTrigger$: Observable<boolean> = combineLatest([
    this.form.get('userName').valueChanges.pipe(startWith(() => null)),
    this.loginOtpFacade.getResetTimerState$().pipe(startWith(() => null))
  ]).pipe(
    switchMap(() => from([true, false])),
    delayWhen((res) => (res ? of(null) : timer(0)))
  );
  constructor(
    private readonly loginOtpFacade: LoginOtpFacade,
    private readonly analyticsService: AnalyticsService,
    private readonly matomoAnalyticsService: MatomoAnalyticsService,
    private readonly captchaFacade: CaptchaFacade
  ) {}
  ngAfterViewInit() {
    const sendSMS$: Observable<void> =
      this.OTPComRef?.['sendSMS']?.asObservable();
    const submit$: EventEmitter<boolean> = this.OTPComRef?.['formSubmit'];
    this.sendSMS$ = sendSMS$.pipe(
      switchMap(() => {
        return this.loginOtpFacade.sendSMS$(this.form.get('userName').value);
      }),
      tap((res) => {
        this.form.get('passwordOTP').reset();
        if (res.error === 'WRONG_USERNAME') {
          this.form.get('userName').setErrors({
            wrongUsername: true
          });
        }
      }),
      share()
    );
    this.formSubmit$ = submit$.asObservable().pipe(
      filter((isDisabled) => {
        isDisabled ? handleMarkingControlsAsTouched(this.form) : void 0;
        return !isDisabled;
      }),
      switchMap(() => {
        this.isLoading = true;
        return this.loginOtpFacade.initiateOTPLogin$(this.form.value);
      }),
      tap((res) => {
        this.isLoading = false;
        if (res?.errorCode === 'WRONG_SMS_CODE') {
          this.form.get('passwordOTP').setErrors({
            wrongCode: true
          });
          this.analyticsService.track({
            action: 'Login',
            event: 'login',
            // eslint-disable-next-line camelcase
            login_status: 'fail'
          });
          // Matomo Analytics Event Tracking for Login
          this.matomoAnalyticsService.selectTrackingEvent('loginTotalFail');
        }
        if (res?.errorCode === 'BLOCKED_BY_UNKNOWN_ENTITY') {
          this.form.setErrors({
            blockedByUnknownEntity: true
          });
          this.form.get('passwordOTP').setErrors({
            blockedByUnknownEntity: true
          });
          this.form.get('userName').setErrors({ blockedByUnknownEntity: true });
          this.analyticsService.track({
            action: 'Login',
            event: 'login',
            // eslint-disable-next-line camelcase
            login_status: 'fail'
          });
          // Matomo Analytics Event Tracking for Login
          this.matomoAnalyticsService.selectTrackingEvent('loginTotalFail');
        }
        if (res?.captcha && res?.errorCode) {
          this.loginOtpFacade.setCaptchaActiveState(true);
        }
      })
    );
    this.smsSentAlert$ = this.sendSMS$.pipe(
      filter((res) => res?.success),
      switchMap(() => {
        return from([true, false]);
      }),
      delayWhen((res) => {
        return res ? of(null) : timer(1500);
      })
    );
    // this.handleAutoOTP();
  }
  public handleReturnToDefaultProtocol(): void {
    this.returnToDefaultProtocol.emit({
      userName: this.form.get('userName').value
    });
  }
  private handleAutoOTP() {
    // TODO revert this logic if needed
    // this.autoOTPStatus$ = this.sendSMS$.pipe(
    //   filter((res) => res?.success),
    //   tap(() => {
    //     this.loginOtpFacade.abortAndNullifyAbortController();
    //     this.loginOtpFacade.createAbortController();
    //   }),
    //   switchMap(() => {
    //     return this.loginOtpFacade.getOneTimePasswordLogic$(
    //       this.form.get('passwordOTP')
    //     );
    //   })
    // );
  }
  ngOnDestroy() {
    // this.loginOtpFacade.abortAndNullifyAbortController();
    this.loginOtpFacade.setOTPState(false);
    this.loginOtpFacade.setCaptchaActiveState(false);
  }
  public handleCaptchaValueEnter(captcha: string): void {
    this.captchaFacade.checkCaptchaValidity({
      login: this.form.get('userName').value,
      captcha
    });
  }
  public handleUpdateCaptcha(): void {
    this.captchaFacade.updateCaptchaCode(this.form.get('userName').value);
  }
  public handleCaptchaValidValueEnter(): void {
    this.loginOtpFacade.setCaptchaActiveState(false);
    this.OTPComRef?.['formSubmit']?.emit();
  }
}
