import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseUrl, HttpService, Language } from '@crc/shared';

import { ApiResponse } from '../../../shared';

@Injectable({ providedIn: 'root' })
export class CatalogService {
  constructor(private readonly apiService: HttpService) {}

  getData$(type: string, lang: Language): Observable<Record<string, string>> {
    const path = `/ui/strings/${type}/${lang}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<ApiResponse>({ path, baseUrl })
      .pipe(map((response) => response.data));
  }
}
