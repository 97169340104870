import { Injectable } from '@angular/core';

import { Observable, of, switchMap, take, tap } from 'rxjs';
import { PokerService } from '../services/poker.service';
import { map } from 'rxjs/operators';
import {
  AccountFacade,
  AuthFacade,
  LoginPopupFacade,
  NotVerifiedGuard
} from '../../../auth';
import { GamesFacade } from '../../p2p';
import { openLink } from '@crc/shared';

@Injectable({
  providedIn: 'root'
})
export class PokerFacade {
  constructor(
    private readonly pokerService: PokerService,
    private readonly authFacade: AuthFacade,
    private readonly accountFacade: AccountFacade,
    private readonly gamesFacade: GamesFacade,
    private readonly loginGuardFacade: LoginPopupFacade,
    private readonly notVerifiedGuard: NotVerifiedGuard
  ) {}

  getPokerLink(): Observable<string> {
    return this.pokerService.getPokerLink().pipe(
      map((res) => {
        try {
          JSON.parse(res);
          return null;
        } catch (e) {
          return res;
        }
      })
    );
  }
  playPoker(mobile: boolean) {
    return this.authFacade.isLoggedIn$().pipe(
      take(1),
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return of(true);
        } else {
          this.loginGuardFacade.openLoginPopup();
          return this.loginGuardFacade.getLoginResult$().pipe(take(1));
        }
      }),
      switchMap((loggedIn) => {
        return loggedIn
          ? this.accountFacade.isVerified$.pipe(
              take(1),
              map((verified) => [loggedIn, verified])
            )
          : of([false, false]);
      }),
      switchMap(([loggedIn, verified]) => {
        if (loggedIn) {
          if (verified) {
            return this.getPokerLink().pipe(
              tap((link) => {
                if (link) {
                  this.openPokerLink(link, mobile);
                }
              })
            );
          } else {
            return of(this.notVerifiedGuard.redirectToVerification());
          }
        }
        return of({});
      })
    );
  }

  private openPokerLink(url: string, mobile: boolean) {
    if (mobile) {
      openLink(
        this.gamesFacade.replaceLanguage(url, 'KA') + '&isMobile=true',
        '_self'
      );
    } else {
      window.open(
        this.gamesFacade.replaceLanguage(url, 'KA'),
        '',
        'width=800,height=600'
      );
    }
  }
}
