import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';

import { authActions } from '../store/actions';
import { AuthService, UsernameStorage } from '../services';
import { SignedInFeatureState } from '../store/state';
import { AuthResponse, AuthState, Restricted, SignInPayload } from '../entity';
import { IdentificationSMS } from '../../features';
import { AnalyticsService, MatomoAnalyticsService } from '@crc/shared';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  private readonly customerId$: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);
  private readonly smsCodeVerified$: Subject<boolean | null> = new Subject<
    boolean | null
  >();
  private readonly signedOutCalled$: Subject<void> = new Subject();
  private readonly loginStatus$: BehaviorSubject<AuthState> =
    new BehaviorSubject<AuthState>(AuthState.LOGGED_OUT);
  private readonly loginRestricted$ = new BehaviorSubject<Restricted | null>(
    null
  );

  private readonly loginBarState$ = new BehaviorSubject<boolean>(true);

  private readonly sessionStatus$ = new BehaviorSubject<boolean>(false);
  private readonly $isVisibleButtonLoader = new BehaviorSubject<boolean>(false);
  private readonly $captchaState = new Subject<boolean>();
  private readonly $isRestricted = new Subject<boolean>();

  constructor(
    private readonly authService: AuthService,
    private readonly usernameStorage: UsernameStorage,
    private readonly analyticsService: AnalyticsService,
    private readonly matomoAnalyticsService: MatomoAnalyticsService,
    private readonly store$: Store<SignedInFeatureState>
  ) {}

  signIn(payload: SignInPayload) {
    this.store$.dispatch(authActions.checkSms({ payload }));
    this.setButtonLoaderState(true);
  }

  signInWithPasscode(response: AuthResponse) {
    this.store$.dispatch(
      authActions.signInPostCheck({
        payload: { ...response, twoFA: true }
      })
    );
  }

  signOut(manual = true) {
    this.store$.dispatch(authActions.signOut());
    this.signedOutCalled$.next();
    this.analyticsService.track({
      event: 'logout',
      manual: manual ? 1 : 0
    });
    //matomo analytics for logout
    this.matomoAnalyticsService.selectTrackingEvent('logoutClick');
  }

  getSignedOutCalled$(): Subject<void> {
    return this.signedOutCalled$;
  }

  getLoginStatus$(): Observable<AuthState> {
    return this.loginStatus$.asObservable();
  }

  isLoggedIn$(): Observable<boolean> {
    return this.getLoginStatus$().pipe(
      map((status) => status === AuthState.LOGGED_IN)
    );
  }

  getIsLoggedIn(): boolean {
    return this.getLoginStatus() === AuthState.LOGGED_IN;
  }

  getLoginStatus(): AuthState {
    return this.loginStatus$.getValue();
  }

  getRestrictedStatus() {
    return this.loginRestricted$.asObservable();
  }
  getRestrictedCurrentStatus() {
    return this.loginRestricted$.getValue();
  }

  setLoginStatus$(status: AuthState) {
    if (status === AuthState.LOGGED_IN) {
      this.setSessionStatus$(true);
    } else {
      this.setSessionStatus$(false);
    }
    this.loginStatus$.next(status);
    this.setButtonLoaderState(false);
  }

  getLoginFailed$(): Observable<boolean> {
    return this.loginStatus$.pipe(
      map((loginStatus: AuthState) => loginStatus === AuthState.LOGIN_FAILED)
    );
  }
  getLoginBlockedByUnknownEntity$(): Observable<boolean> {
    return this.loginStatus$.pipe(
      map(
        (loginStatus: AuthState) =>
          loginStatus === AuthState.BLOCKED_BY_UNKNOWN_ENTITY
      )
    );
  }

  setCaptchaState(data: boolean) {
    this.$captchaState.next(data);
  }

  getCaptchaState$() {
    return this.$captchaState.asObservable();
  }

  setIsRestrictedState(data: boolean) {
    this.$isRestricted.next(data);
  }

  getIsRestrictedState$() {
    return this.$isRestricted.asObservable();
  }

  setRestrictedStatus(status: Restricted) {
    this.loginRestricted$.next(status);
  }

  setCustomerId(customerId: number | null) {
    this.customerId$.next(customerId);
    this.setButtonLoaderState(false);
  }

  getCustomerId(): Observable<number | null> {
    return this.customerId$.asObservable();
  }

  verifySmsCode(payload: SignInPayload) {
    this.store$.dispatch(authActions.verifySmsCode({ payload }));
    this.setButtonLoaderState(true);
  }

  setSmsCodeVerified(status: boolean | null) {
    this.smsCodeVerified$.next(status);
    this.setButtonLoaderState(false);
  }

  getSmsCodeVerified(): Observable<boolean | null> {
    return this.smsCodeVerified$.asObservable();
  }

  getLoginBarState(): Observable<boolean> {
    return this.loginBarState$.asObservable();
  }

  setLoginBarState(state: boolean) {
    this.loginBarState$.next(state);
  }

  getSessionStatus$(): Observable<boolean> {
    return this.sessionStatus$.asObservable();
  }

  getSessionStatus(): boolean {
    return this.sessionStatus$.getValue();
  }

  setSessionStatus$(status: boolean) {
    return this.sessionStatus$.next(status);
  }

  getButtonLoaderState$(): Observable<boolean> {
    return this.$isVisibleButtonLoader.asObservable();
  }

  setButtonLoaderState(state: boolean) {
    this.$isVisibleButtonLoader.next(state);
  }

  getUsernameFromStorage(): string {
    return this.usernameStorage.getUsername();
  }
  sendVerificationCode(): Observable<unknown> {
    return this.authService.sendVerificationCode();
  }
  sendPhoneNumberCodeVerify(
    code: string
  ): Observable<IdentificationSMS | null> {
    return this.authService
      .sendPhoneNumberCodeVerify(code)
      .pipe(catchError(() => of(null)));
  }
}
