import { createAction, props } from '@ngrx/store';
import { IRouletteStat } from './roulette.reducers';

export const AVATARS_FEATURE_KEY = '[Roulette]';

export const getRouletteStats = createAction(
  `${AVATARS_FEATURE_KEY} Get Roulette Stats`
);

export const getRouletteStatsSuccess = createAction(
  `${AVATARS_FEATURE_KEY} Get Roulette Stats Success`,
  props<{ payload: IRouletteStat[] }>()
);

export const getRouletteStatsError = createAction(
  `${AVATARS_FEATURE_KEY} Get Roulette Stats Error`,
  props<{ error: string }>()
);
