import { formatDate } from '@angular/common';

export function generateTransactionsSelectMenuItems(id: number, name: string) {
  return { id, name };
}

export function startDates(): string[] {
  const dateFrom = new Date();
  const dateTo = new Date();
  dateFrom.setHours(dateFrom.getHours() - 24);
  const _dateFrom = formatDate(dateFrom, 'yyyy-MM-dd', 'en_US');
  const _dateTo = formatDate(dateTo, 'yyyy-MM-dd', 'en_US');
  return [_dateFrom, _dateTo];
}
