import { reload } from '@crc/shared';

export const SuitRunnerProviderConfig = {
  providerName: 'suit-runner',
  messageEventDataCodes: {
    gameReloadMessage: 'reloadGame',
    tokenRefreshMessage: 'refreshToken'
  }
} as const;
export const NextLevelProviderConfig = {
  providerName: 'plinko',
  messageEventDataCodes: {}
} as const;
export function _handleSuitrunnerProviderMessageEvent(
  event: MessageEvent
): void {
  if (
    event.data ===
    SuitRunnerProviderConfig.messageEventDataCodes.tokenRefreshMessage
  ) {
    reload();
  }
}
