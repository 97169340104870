import { Injectable } from '@angular/core';
import { Cookie, CookieService, generateUUID } from '@crc/shared';
import { ExternalScriptBridgeFacade } from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class AppInitFacade {
  get deviceId() {
    return this.cookieService.get('deviceId');
  }

  constructor(
    private cookieService: CookieService,
    private readonly externalScriptsBridge: ExternalScriptBridgeFacade
  ) {
    this.externalScriptsBridge.OnInit();
  }

  init() {
    this.setUniqDeviceId();
  }

  setUniqDeviceId() {
    let deviceId = this.cookieService.get('deviceId');
    if (!deviceId) {
      deviceId = generateUUID();
      const params: Cookie = {
        name: 'deviceId',
        value: deviceId,
        SameSite: 'strict',
        HttpOnly: true
      };
      this.cookieService.set(params);
    }
  }
}
