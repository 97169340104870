import { Injectable } from '@angular/core';
import { HttpService } from '@crc/shared';
import { catchError, Observable, of } from 'rxjs';
import {
  LegalPopupConsentRequestInterface,
  LegalPopupConsentResponseInterface
} from '../entities/legal-popup.interface';

@Injectable({
  providedIn: 'root'
})
export class LegalPopupService {
  constructor(private readonly _http: HttpService) {}
  public checkCustomConsent(
    definitionId: number
  ): Observable<LegalPopupConsentResponseInterface> {
    return this._http
      .get$<LegalPopupConsentResponseInterface>({
        baseUrl: 'apiUrl',
        path: `/rest/customer/consents/definitions/${definitionId}`
      })
      .pipe(catchError(() => of(null)));
  }
  public addCustomerConsent(
    definitionId: number,
    consentStatus: boolean = true
  ): Observable<LegalPopupConsentResponseInterface> {
    return this._http
      .post$<
        LegalPopupConsentResponseInterface,
        LegalPopupConsentRequestInterface
      >({
        baseUrl: 'apiUrl',
        path: `/rest/customer/consents/definitions`,
        body: {
          definitionId: definitionId,
          accepted: consentStatus
        }
      })
      .pipe(catchError(() => of(null)));
  }
  public checkByContainerName(): Observable<LegalPopupConsentResponseInterface> {
    return this._http
      .get$<LegalPopupConsentResponseInterface>({
        baseUrl: 'apiUrl',
        path: `/rest/customer/consents/containers/PROMOTIONS`
      })
      .pipe(catchError(() => of(null)));
  }
}
