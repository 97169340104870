import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import { nextTick } from '@crc/shared';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'crc-m-shared-user-action-result',
  templateUrl: './user-action-result.component.html',
  styleUrls: ['./user-action-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserActionResultComponent {
  private readonly __doc__: Document = inject(DOCUMENT);
  @Input() private set noScrollDispatchAfterDelay(
    val: number | null | unknown
  ) {
    if ((val ?? null) === null) {
      return;
    }
    nextTick(() => {
      this.__doc__.body.classList.add('no-scroll');
    }, Number(val) || 300);
  }
  @Input() success: boolean;
  @Input() successText: string;
  @Input() failText: string;
  @Input() buttonText = 'close';
  @Input() public hardButtonType: null | 'active' | 'secondary' | 'bonus' = null;

  @Output() ok: EventEmitter<boolean> = new EventEmitter();
}
