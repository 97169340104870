export interface CardCount {
  errorCode: number;
  errorMessage: string;
  data?: CardCountData;
}

export interface CardCountData {
  count: number;
  last?: string;
  hasError?: boolean;
}

export interface CardStatus {
  errorCode: number;
  errorMessage: string;
  data?: CardStatusData;
  hasError?: boolean;
}

export interface CardCityResponse {
  data: CardCity;
}

export interface CardCity {
  city: string;
}

export interface CardStatusData {
  id: number;
  status: string;
  comment?: string;
}

export interface OrderCard extends BaseResponse {
  errorCode: number;
  errorMessage: string;
  data?: [];
}

export interface SendSms extends BaseResponse {
  data:[],
  errorCode: number;
  errorMessage: string;
}

export interface BaseResponse {
  error?: string;
  message?: string;
  payload?: SendSmsPayload;
  retryAfter?: number;
  statusCode?: number;
}


export interface SendSmsPayload {
  errorCode: string;
  retryAfter: number;
  error?: string;
}

export enum CardOrderErrorCodes {
  SUCCESS = 1000,
  DUBBED_ORDER = 4000,
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
  RATE_LIMIT = 'TOO_MANY_REQUESTS'
}

export enum CardStatusEnum {
  PROCESS = 1,
  DECLINED = 2,
  ACCEPTED = 3,
  DELIVERY_PROCESS = 4,
  DELIVERED = 5,
  DELIVERY_FAILED = 6,
  DELIVERY_SUCCESS = 7
}

export enum CardColor {
  BLUE = 'იასამნისფერი',
  GREEN = 'მწვანე'
}

export enum CardPages {
  CROCO_CARD = 'croco-card',
  EMAIL_VERIFY = 'email-verify',
  PHONE_EDIT = 'phone-edit',
  EMAIL_EDIT = 'email-edit'
}

export enum CardPageName {
  CROCO_CARD = 'secret_card',
  EMAIL_VERIFY = 'email_verify',
  PHONE_EDIT = 'profile.change_number',
  EMAIL_EDIT = 'email_edit'
}

