import { Injectable, Optional } from '@angular/core';
import { GtagCustomEvent, MatomoEvent } from './analytics.type';
import { getWindow } from '../../functions';
import { matomoEventTree } from './matomo-dto';
import { MatomoService } from '../matomo/matomo.service';

@Injectable({
  providedIn: 'root'
})
export class MatomoAnalyticsService {
  constructor(@Optional() private readonly matomoService: MatomoService) {}

  //sets and trigger matomo event
  public track(customEvent: MatomoEvent) {
    this.matomoService?.trackEvent?.(
      customEvent?.Category,
      customEvent?.Action,
      customEvent?.Name,
      customEvent?.Value
    );
  }

  public selectTrackingEvent(
    customEventKey: keyof typeof matomoEventTree
  ): void {
    const customEvent: MatomoEvent = matomoEventTree[customEventKey];
    if (customEvent) {
      this.track(customEvent);
    } else {
      console.error('Event not found:', customEvent);
    }
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public static mtm(command: GtagCustomEvent) {
    const windowRef = getWindow() as { [key: string]: any };
    if (windowRef['_mtm']) {
      windowRef['_mtm']?.push?.(command);
    }
  }

  public setUserId(userId: string | number) {
    // eslint-disable-next-line camelcase
    if (userId) {
      this.matomoService?.setUserId(userId.toString());
    }
  }
}
