export const APPLE_PAY_CMS_TOKEN = '676425c54fcd1dd6140cf499' as const; //NOSONAR

export interface IApplePayCmsTokenResponseDTO {
  createdAt?: string;
  desc: string;
  endDate?: number;
  isActive: boolean;
  startDate: number;
  title: string;
  updatedAt?: string;
  __v?: number;
  _id?: typeof APPLE_PAY_CMS_TOKEN;
}
