import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { IdentificationFacade, IdentificationTypes } from '../../features';
import { map, take } from 'rxjs/operators';
import { AccountFacade, AuthFacade, LoginPopupFacade } from '../facade';
import { ENV_CONFIG, EnvironmentConfig } from '@crc/shared';

@Injectable({
  providedIn: 'root'
})
export class IdentificationGuard implements CanActivate {
  constructor(
    private readonly identificationFacade: IdentificationFacade,
    private readonly accountFacade: AccountFacade,
    private readonly authFacade: AuthFacade,
    private readonly loginGuardFacade: LoginPopupFacade,
    private readonly router: Router,
    @Inject(ENV_CONFIG)
    private readonly env: EnvironmentConfig
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return route.data?.verification?.requireLogin
      ? this.authFacade.getIsLoggedIn()
        ? this.getIsVerified(state)
        : this.loginGuardFacade.getLoginResult$().pipe(
            take(1),
            switchMap((loggedIn) =>
              loggedIn ? this.getIsVerified(state) : of(false)
            )
          )
      : of(this.authFacade.getIsLoggedIn()).pipe(
          switchMap((loggedIn) =>
            loggedIn ? this.getIsVerified(state) : of(true)
          )
        );
  }

  getIsVerified(state: RouterStateSnapshot) {
    return combineLatest([
      this.identificationFacade.getIdentification$(),
      this.accountFacade.personalData$,
      this.accountFacade.getVerificationActive$()
    ]).pipe(
      map(([identificationState, personalData, isVerificationActive]) => {
        if (
          identificationState &&
          personalData?.mobileNumber?.includes('null') &&
          identificationState === IdentificationTypes.Obliged &&
          state.url !== '/'
        ) {
          return false;
        }
        if (
          ((isVerificationActive &&
            identificationState === IdentificationTypes.Obliged) ||
            (isVerificationActive !== undefined &&
              identificationState === IdentificationTypes.Obliged)) &&
          state.url !== '/'
        ) {
          return false;
        }
        if (
          identificationState &&
          identificationState === IdentificationTypes.Obliged
        ) {
          return this.router.createUrlTree(['/profile/verification']);
        }

        return !(
          identificationState &&
          identificationState === IdentificationTypes.Obliged
        );
      })
    );
  }
}
