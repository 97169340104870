import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export function fadeInAndOutBottomTop(ms: number): AnimationTriggerMetadata {
  return trigger('fadeInAndOutBottomTop', [
    transition('void => *', [
      style({ opacity: 0, marginTop: '15px', transform: 'translateY(25px)' }),
      animate(
        `${ms}ms ease-in-out`,
        style({ opacity: 1, marginTop: '0', transform: 'translateY(0)' })
      )
    ]),
    transition('* <=> void', [
      animate(
        `${ms}ms ease-in-out`,
        style({ opacity: 0, marginTop: '15px', transform: 'translateY(25px)' })
      )
    ])
  ]);
}

export function slideInAndOutForCustomSupplies() {
  return trigger('slideInOut', [
    state('in', style({ transform: 'translateX(0)' })),
    transition(':enter', [
      style({ transform: 'translateX(-100%)' }),
      animate('300ms ease-in', style({ transform: 'translateX(0)' }))
    ]),
    transition(':leave', [
      animate('300ms ease-out', style({ transform: 'translateX(-100%)' }))
    ])
  ]);
}

export function slideInAndOut(
  delay: number,
  orbit: 'X' | 'Y',
  from: number,
  to: number
): AnimationTriggerMetadata {
  return trigger('slideInAndOut', [
    transition(':enter', [
      style({
        transform: `translate${orbit}(${from}%)`
      }),
      animate(
        delay,
        style({
          transform: `translate${orbit}(${to}%)`
        })
      )
    ]),
    transition(':leave', [
      style({
        transform: `translate${orbit}(${to}%)`
      }),
      animate(
        delay,
        style({
          transform: `translate${orbit}(${from}%)`
        })
      )
    ])
  ]);
}

export function slideLeft(delay: number, to: number): AnimationTriggerMetadata {
  return trigger('slideItem', [
    transition(':leave', [
      animate(delay, style({ opacity: 0, transform: `translateX(${to}%)` }))
    ])
  ]);
}

export function scaleInAndOut(delay: number): AnimationTriggerMetadata {
  return trigger('scaleInAndOut', [
    transition(':enter', [
      style({
        transform: 'scale(.9)'
      }),
      animate(
        delay,
        style({
          transform: 'scale(1)'
        })
      )
    ])
  ]);
}
export function fadeInAndOut(ms: number): AnimationTriggerMetadata {
  return trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(ms, style({ opacity: 1 }))
    ]),
    transition('* <=> void', [animate(ms, style({ opacity: 0 }))])
  ]);
}

export function flipCard(): AnimationTriggerMetadata {
  return trigger('flipState', [
    state(
      'true',
      style({
        transform: 'rotateY(180deg)'
      })
    ),
    state(
      'false',
      style({
        transform: 'rotateY(0)'
      })
    ),
    transition('true => false', animate('400ms ease-out')),
    transition('false => true', animate('400ms ease-in'))
  ]);
}
