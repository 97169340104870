import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export class CaptchaPromptBaseComponent implements OnInit {
  @Input() enteredCaptchaValid: boolean;
  @Output() captchaValidValueEntered$: EventEmitter<string> =
    new EventEmitter();
  @Output() captchaValueEntered$: EventEmitter<string> = new EventEmitter();
  @Output() captchaUpdated$: EventEmitter<void> = new EventEmitter();
  @Output() captchaClosed$: EventEmitter<void> = new EventEmitter();
  form: UntypedFormGroup;
  captchaSafe: SafeHtml;
  dropdownVisible = true;
  captchaUpdateRequested: boolean;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly sanitizer: DomSanitizer,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.generateForm();
  }

  @Input() set captcha(captcha: string) {
    this.captchaSafe = this.sanitizer.bypassSecurityTrustHtml(captcha);
  }

  ngOnInit() {
    this.subscribeToFormValueChanges();
  }

  onSubmit() {
    const { captcha } = this.form.value;
    this.captchaValidValueEntered$.emit(captcha);
  }

  onCloseDropdown() {
    this.dropdownVisible = false;
    this.captchaClosed$.emit();
  }

  onCaptchaUpdated() {
    this.captchaUpdated$.emit();
    this.captchaUpdateRequested = true;
    this.cdr.markForCheck();
  }

  private generateForm() {
    const validators: ValidatorFn[] = [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4),
      Validators.pattern('^[0-9]*$')
    ];
    this.form = this.fb.group({
      captcha: new UntypedFormControl('', validators)
    });
  }

  private subscribeToFormValueChanges() {
    this.form.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((form) => {
          if (this.form.valid) {
            this.captchaValueEntered$.emit(form.captcha);
            this.captchaUpdateRequested = false;
          } else {
            this.captchaUpdateRequested = true;
          }
          this.cdr.markForCheck();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
