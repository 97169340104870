import { RouletteState } from './roulette.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectRouletteState = createFeatureSelector('roulette');

export const selectStatByGameId = createSelector(
  selectRouletteState,
  (state: RouletteState, props: { gameId: string }) => {
    return state?.stats?.find((stat) => {
      return stat.gameId === props.gameId;
    });
  }
);
