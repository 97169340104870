import { Injectable, Injector } from '@angular/core';
import { ApplePayDepositService } from '../services/apple-pay-deposit.service';
import {
  BehaviorSubject, distinctUntilChanged,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap
} from 'rxjs';
import {
  ApplePayStateEnum,
  BankType,
  Currencies,
  IApplePayFeatureStateEnum,
  PaymentMethodTypes,
  PaymentProvider,
  PaymentProviderLimit,
  ProviderTypes,
  TransactionTypes,
  TransferRequest
} from '@crc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, debounceTime, filter, map, tap } from 'rxjs/operators';
import {
  AnalyticsService,
  ClassForwardDirective,
  EnvironmentService,
  EnvNameEnum,
  MatomoAnalyticsService,
  softlyAssertEnvNameList
} from '@crc/shared';
import { Card } from '../../cards';
import { AuthFacade } from '../../../auth';
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ApplePayDepositFacade {
  constructor(
    private readonly applePayDepositService: ApplePayDepositService,
    private readonly matomoAnalyticsService: MatomoAnalyticsService,
    private readonly analyticsService: AnalyticsService,
    private readonly authFacade: AuthFacade,
    private readonly envService: EnvironmentService
  ) {}
  public readonly applePayAvailabilityCheckForUser$: Observable<boolean> =
    this._checkApplePayAvailabilityForUser$().pipe(startWith(false));
  public amountLimitsByProviderCode: {
    [key: string]: {
      depositAmountLimits: PaymentProviderLimit;
      withdrawAmountLimits: PaymentProviderLimit;
    };
  };
  private readonly $applePayState: BehaviorSubject<IApplePayFeatureStateEnum> =
    new BehaviorSubject(ApplePayStateEnum.PRISTINE);

  public getApplePayState$(): Observable<IApplePayFeatureStateEnum> {
    return this.$applePayState.asObservable();
  }

  public getApplePayState(): IApplePayFeatureStateEnum {
    return this.$applePayState.getValue();
  }

  public setApplePayState$(state: IApplePayFeatureStateEnum): void {
    this.$applePayState.next(state);
  }

  public resetApplePayState$(): void {
    this.$applePayState.next(ApplePayStateEnum.PRISTINE);
  }

  initiateDeposit(body: TransferRequest) {
    return this.applePayDepositService.startDeposit(body).pipe(
      tap(() => {
        // res?.content?.content && this.updatePaymentIframe(res?.content);
        // Google Analytics event for deposit
        this.analyticsService.track({
          event: 'deposit_started',
          action: 'Deposit'
        });
        //matomo analytics for deposit started
        this.matomoAnalyticsService.selectTrackingEvent('depositStarted');
      }),
      untilDestroyed(this)
    );
  }
  public getFondyDigitalWalletProvider$(): Observable<PaymentProvider> {
    return this.authFacade.isLoggedIn$().pipe(
      startWith(false),
      distinctUntilChanged(),
      switchMap((status) => {
        if (!status) {
          return of(null);
        }
        return this.applePayDepositService.getBankProviders().pipe(
          tap((providers: PaymentProvider[]) => {
            const limitsByProviderCode: {
              [key: string]: {
                depositAmountLimits: PaymentProviderLimit;
                withdrawAmountLimits: PaymentProviderLimit;
              };
            } = {};

            providers.forEach((provider) => {
              limitsByProviderCode[provider.providerCode] = {
                depositAmountLimits: provider.depositAmountLimits,
                withdrawAmountLimits: provider.withdrawAmountLimits
              };
            });

            this.amountLimitsByProviderCode = limitsByProviderCode;
          }),
          map((providers) => {
            return (
              providers.find(
                (provider) => provider?.providerCode === BankType.Fondy
              ) || null
            );
          }),
          catchError(() => of(null))
        );
      })
    );
  }
  calculateCommission(
    card: Card | PaymentProvider,
    amount: number,
    isDeposit: boolean
  ) {
    return this.applePayDepositService.calculateCommission({
      providerCode: card?.['providerCode'],
      providerType: card?.['providerType'] || ProviderTypes.PAYMENT_GATEWAY,
      currency: Currencies.GEL, // !!!TODO!!! paymentProvider?.supportedDepositCurrencies?.[0] we can't support multi Currencies yet
      amount: amount ? +amount?.toFixed(2) : 0,
      method:
        isDeposit &&
        (card as PaymentProvider)?.supportedDepositMethods?.[0] ===
          PaymentMethodTypes.DIGITAL_WALLET
          ? PaymentMethodTypes.DIGITAL_WALLET
          : PaymentMethodTypes.BANK_CARD,
      // card?.methodType ||
      // (isDeposit
      //   ? (card as PaymentProvider)?.supportedDepositMethods?.[0]
      //   : (card as PaymentProvider)?.supportedWithdrawMethods?.[0]) ||
      // 'BANK_CARD', !!!TODO!!! HARDCODED FOR NOW, we can't support another methods yet
      destinationCurrency: Currencies.GEL, // !!!TODO!!! paymentProvider?.supportedDepositCurrencies?.[0] we can't support multi Currencies yet
      direction: isDeposit
        ? TransactionTypes.DEPOSIT
        : TransactionTypes.WITHDRAW
    });
  }
  calculateMaxWithdrawal(card: Card | PaymentProvider) {
    return this.applePayDepositService.calculateMaxWithdrawal({
      providerCode: card?.['providerCode'],
      providerType: card?.['providerType'] || ProviderTypes.PAYMENT_GATEWAY,
      currency: Currencies.GEL, // !!!TODO!!! paymentProvider?.supportedDepositCurrencies?.[0] we can't support multi Currencies yet
      method: PaymentMethodTypes.BANK_CARD, // !!!TODO!!! HARDCODED FOR NOW, we can't support another methods yet card?.methodType || 'BANK_CARD'
      destinationCurrency: Currencies.GEL // !!!TODO!!! paymentProvider?.supportedDepositCurrencies?.[0] we can't support multi Currencies yet
    });
  }
  public initiateClassForwardingToPopup(injector: Injector): void {
    try {
      const classForwardDirective = injector.get(ClassForwardDirective, null, {
        self: true
      });
      classForwardDirective.selector = '.deposit-popup';
      classForwardDirective.className = 'deposit-popup--active';
    } catch (_) {
      console.error(_);
    }
  }
  private _checkApplePayAvailabilityForUser$(): Observable<boolean> {
    return this.authFacade.isLoggedIn$().pipe(
      filter(Boolean),
      debounceTime(301),
      switchMap(() => {
        return this._shouldAssertFeatureStateWithCMS()
          ? this.applePayDepositService.checkApplePayAvailabilityForUser$()
          : of({
              isActive: true
            });
      }),
      map((res) => Boolean(res?.isActive)),
      shareReplay()
    );
  }
  private _shouldAssertFeatureStateWithCMS(): boolean {
    return softlyAssertEnvNameList(
      [EnvNameEnum.STAGING, EnvNameEnum.PRODUCTION, EnvNameEnum.DEVELOPMENT],
      this.envService.config.environment
    );
  }
}
