export const EnvNameEnum = {
  DEVELOPMENT: 'DEV',
  DEVELOPMENT_SB: 'DEV-SB',
  DEVOPS: 'DEVOPS',
  PRODUCTION: 'PROD',
  TEST: 'QA',
  TEST_SB: 'QA-SB',
  STAGING: 'STAGING',
  LOCAL: 'LOCAL'
} as const;

export type EnvNameEnumUnion = (typeof EnvNameEnum)[keyof typeof EnvNameEnum];

export function softlyAssertEnvNameList(
  envNameList: EnvNameEnumUnion[],
  currentEnv: EnvNameEnumUnion
): boolean {
  return envNameList.includes(currentEnv);
}
