import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SlotsPlayFacade} from '../../slots';
import {CasinoPlayFacade} from '../../casino';
import {filterViewItemDefault, PlayInterface, SplitScreenTypes, SplitScreenTypesEnum} from '../entity';
import {FilterItemView} from '@crc/components';
import {BehaviorSubject, filter, from, merge, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {formatSlotCasinoName} from '../functions/functions';

@Injectable({
  providedIn: 'root'
})
export class SlotCasinoMultiplayerFacade {
  private addGameSelector: string;

  private $splitScreenType: BehaviorSubject<SplitScreenTypes> =
    new BehaviorSubject(SplitScreenTypesEnum.FourFrames);

  private $gameFrameOrder: Map<string, number> = new Map<string, number>();

  private $addGameType: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  private $showChooseNewGame: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private $addGameFilterItems: BehaviorSubject<FilterItemView[]> =
    new BehaviorSubject<FilterItemView[]>(filterViewItemDefault);

  private $addGameSelector: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  updateAddGameSelector(addGameSelector: string) {
    this.addGameSelector = addGameSelector;
  }

  getSplitScreenType$(): Observable<SplitScreenTypes> {
    return this.$splitScreenType.asObservable();
  }

  getSplitScreenTypeValue(): SplitScreenTypesEnum {
    return this.$splitScreenType.getValue();
  }

  updateSplitScreenType(splitScreenType: SplitScreenTypesEnum) {
    this.$splitScreenType.next(splitScreenType);
  }

  getGameType$(): Observable<string> {
    return this.$addGameType.asObservable();
  }

  updateGameType(gameType: string) {
    this.$addGameType.next(gameType);
  }

  getShowChooseNewGame$(): Observable<boolean> {
    return this.$showChooseNewGame.asObservable();
  }

  getShowChooseNewGameValue(): boolean {
    return this.$showChooseNewGame.getValue();
  }

  updateShowChooseNewGame(showChooseNewGame: boolean) {
    this.$showChooseNewGame.next(showChooseNewGame);
  }

  getAddGameFilterItems$(): Observable<FilterItemView[]> {
    return this.$addGameFilterItems.asObservable();
  }

  getAddGameFilterItemsValue(): FilterItemView[] {
    return this.$addGameFilterItems.getValue();
  }

  updateAddGameFilterItems(filterItems: FilterItemView[]) {
    this.$addGameFilterItems.next(filterItems);
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private slotsPlayFacade: SlotsPlayFacade,
    private casinoPlayFacade: CasinoPlayFacade
  ) {}

  getGamePlay() {
    return merge(this.slotsPlayFacade.play$, this.casinoPlayFacade.play$).pipe(
      filter((_) => this.getShowChooseNewGameValue() === true),
      tap((item: PlayInterface): any => {
        from(
          this.router.navigate(['/slot-casino'], {
            queryParamsHandling: 'merge',
            queryParams: {
              [this.addGameSelector + '-gameName']: formatSlotCasinoName(
                item.gameName
              ),
              [this.addGameSelector + '-provider']: item.providerName,
              [this.addGameSelector + '-type']: item.gameType,
              [this.addGameSelector + '-game_id']: item.gameId
            }
          })
        );
      }),
      tap((_) => this.updateShowChooseNewGame(false))
    );
  }
}
