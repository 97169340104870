import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { BonusSteps } from '../../types/step.type';

@Component({
  selector: 'crc-shared-bonus-calculator',
  templateUrl: './bonus-calculator.component.html',
  styleUrls: ['./bonus-calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusCalculatorComponent {
  @Input() balance: number;
  @Input() rate: number = null;
  @Input() isMobile: boolean;
  @Input() bonusType: string;
  @Input() disabledButton: boolean;

  @Output() changeStepClicked = new EventEmitter<BonusSteps>();
  @Output() bonusExchangeClicked = new EventEmitter<{
    amount: number;
    resultAmount: number;
  }>();
  activeIndex = null;
  activeShake = false;
  constructor(private readonly cdr: ChangeDetectorRef) {}
  onPointClick(index: number) {
    this.activeIndex = index;
  }

  onSpecialButtonClick(disabled: boolean, amount: number) {
    if (disabled) {
      this.activeShake = true;
      setTimeout(() => {
        this.activeShake = false;
        this.cdr.markForCheck();
      }, 1500);
    } else {
      this.bonusExchangeClicked.emit({
        amount: amount,
        resultAmount: amount * this.rate
      });
      this.disabledButton = true;
    }
  }
}
