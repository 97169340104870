import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  shareReplay,
  switchMap
} from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { EnvironmentService, replaceImage } from '@crc/shared';
import { FilterItemView } from '@crc/components';

import { CasinoService } from '../services/casino.service';
import { CasinoGame, casinoProviderIcon, ProviderCasinoGames } from '../entity';
import { CASINO_GROUPS } from '../constants/casino-group-filter-items';
import { AccountFacade, AuthFacade } from '../../../auth';
import { CasinoFavoriteFacade } from './casino-favorite.facade';

@Injectable({
  providedIn: 'root'
})
export class CasinoFacade {
  private readonly $providerIcon = new BehaviorSubject<casinoProviderIcon>(
    null
  );
  private readonly $casinoIsEmpty = new BehaviorSubject<boolean>(false);
  customTrigger$ = new BehaviorSubject<void>(null);
  categoriesWithCasinos$ = this.casinosService
    .getCategoriesAndCasinos(this.env.config.platform)
    .pipe(shareReplay());
  //=============
  // public readonly categoriesFilteredByPlatformWithCasinos$: Observable<
  //   CategoryCasinoGames[]
  // > = this.categoriesWithCasinos$.pipe(
  //   map((categories) => {
  //     const platform = this.env.config.platform;
  //     return categories.filter((category) => {
  //       const categoryName = category?.category?.toLowerCase() ?? '';
  //       const groupName = category?.group?.toLowerCase() ?? '';
  //       const searchKeyWord = platform === 'desktop' ? 'web' : 'mob';
  //       return (
  //         category.active &&
  //         ((category?.platform &&
  //           (category?.platform === platform ||
  //             category?.platform === 'all')) ||
  //           (categoryName.includes(':') &&
  //             categoryName.split(':')[0].includes(searchKeyWord)) ||
  //           categoryName.startsWith(searchKeyWord) ||
  //           (groupName.includes(':') &&
  //             groupName.split(':')[0].includes(searchKeyWord)) ||
  //           groupName.startsWith(searchKeyWord)) &&
  //         category?.games?.length
  //       );
  //     });
  //   }),
  //   shareReplay()
  // );

  popularCasinos: Observable<CasinoGame[]> = this.getCasinosByCategory(
    this.env.config.platform === 'desktop' ? 'web:popular' : 'mobile:popular'
  );

  providersWithCasinos$ = this.accountFacade.customerId$.pipe(
    debounceTime(200),
    switchMap((customerId) =>
      this.casinosService.getProvidersWithCasinos(
        this.env.config.platform,
        customerId
      )
    ),
    shareReplay(1)
  );

  allCasinosByProviders$: Observable<CasinoGame[]> =
    this.providersWithCasinos$.pipe(
      map((providers) => {
        return providers.reduce((sum: CasinoGame[], cur) => {
          return [...sum, ...cur.games];
        }, []);
      }),
      shareReplay(1)
    );
  groups$: Observable<FilterItemView[]> = combineLatest([
    this.allCasinosByProviders$,
    this.casinoFavoriteFacade.totalCount$,
    this.customTrigger$.asObservable()
  ]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([casinos, totalCount, _]) => {
      const groups: FilterItemView[] = [];

      // TODO duplicated logic
      if (
        this.env.config.platform === 'desktop' &&
        this.authFacade.getIsLoggedIn()
      ) {
        groups.push({
          filter: 'favorite_games',
          name: 'favorites',
          totalCount: totalCount
        });
      }
      CASINO_GROUPS.forEach((groupName) => {
        groups.push({
          filter: groupName,
          name: groupName,
          totalCount: casinos.filter((c) => c.group_name?.includes(groupName))
            .length
        });
      });
      groups.push({
        filter: 'showgames',
        name: 'casino.show_games',
        totalCount: casinos.filter(
          (c) =>
            !CASINO_GROUPS.reduce((res, cur) => {
              return res || c.group_name?.includes(cur);
            }, false)
        ).length
      });
      return groups;
    })
  );
  providers$ = this.accountFacade.customerId$.pipe(
    debounceTime(200),
    switchMap((customerId) =>
      this.casinosService.getCasinoProviders(
        this.env.config.platform,
        customerId
      )
    ),
    shareReplay(1)
  );

  constructor(
    private casinosService: CasinoService,
    private env: EnvironmentService,
    private readonly accountFacade: AccountFacade,
    private readonly casinoFavoriteFacade: CasinoFavoriteFacade,
    private readonly authFacade: AuthFacade
  ) {}

  getPopulars(): Observable<CasinoGame[]> {
    return this.popularCasinos.pipe(
      map((topGames: CasinoGame[]) => {
        topGames.forEach((casinoGame: CasinoGame) => {
          casinoGame.image = replaceImage<CasinoGame>(
            'casino',
            casinoGame.provider,
            casinoGame
          ).image;
        });
        return topGames;
      })
    );
  }

  setCasinoIsEmpty(data: boolean) {
    this.$casinoIsEmpty.next(data);
  }

  getCasinoIsEmpty() {
    return this.$casinoIsEmpty.asObservable();
  }

  getCasinoIsEmptyValue() {
    return this.$casinoIsEmpty.value;
  }

  getProviderWithCasinos(provider: string): Observable<ProviderCasinoGames> {
    return this.providersWithCasinos$.pipe(
      map((provides) => provides.filter((p) => p.provider === provider)[0]),
      map((p) => {
        if (!p?.games?.length) {
          this.setCasinoIsEmpty(true);
        }

        const games = p?.games;
        if (games !== undefined) {
          p.games = games.sort((a, b) =>
            this.casinoFavoriteFacade.isCasinoInFavorites(a)
              ? -1
              : this.casinoFavoriteFacade.isCasinoInFavorites(b)
              ? 1
              : 0
          );
        }
        return p;
      })
    );
  }

  getCasinosByCategory(category: string): Observable<CasinoGame[]> {
    return this.categoriesWithCasinos$.pipe(
      map((c) => c.filter((c) => c.category === category)[0]?.games ?? [])
    );
  }

  setProviderIcon(icon: string, iconBgColor?: boolean) {
    this.$providerIcon.next({
      icon: icon,
      iconBgColor: iconBgColor
    });
  }

  getProviderIcon$(): Observable<casinoProviderIcon> {
    return this.$providerIcon.asObservable();
  }
}
