import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { DepositActiveModeEnum, DepositActiveModeUnionType } from '@crc/core';
import { NgOptimizedImage } from '@angular/common';
import { SharedTranslateModule } from '@crc/shared';

@Component({
  selector: 'crc-shared-payment-mode-toggle',
  standalone: true,
  templateUrl: './payment-mode-toggle.component.html',
  styleUrl: './payment-mode-toggle.component.scss',
  imports: [NgOptimizedImage, SharedTranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentModeToggleComponent {
  @Input() activeMode!: DepositActiveModeUnionType;
  @Input() isNewLabelEnabled = true;
  @Output() public readonly activeModeChange =
    new EventEmitter<DepositActiveModeUnionType>();
  protected readonly DepositActiveModeEnum = DepositActiveModeEnum;

  public handleBtnClick(mode: DepositActiveModeUnionType): void {
    if (mode !== this.activeMode) {
      this.activeModeChange.emit(mode);
    }
  }
}
