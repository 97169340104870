import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MCashiersFacade {
  private readonly isVisibleCashiers$ = new BehaviorSubject<boolean>(false);
  private readonly locationAlert$ = new BehaviorSubject<boolean>(false);

  getCashierState(): Observable<boolean> {
    return this.isVisibleCashiers$.asObservable();
  }

  setCashierState(state: boolean) {
    this.isVisibleCashiers$.next(state);
  }

  getLocationAlert(): Observable<boolean> {
    return this.locationAlert$.asObservable();
  }

  setLocationAlert(state: boolean) {
    this.locationAlert$.next(state);
  }
}
