import { Injectable } from '@angular/core';
import {
  BaseUrl,
  EnvironmentService,
  HttpService,
  Platform
} from '@crc/shared';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  Mini,
  MiniGames,
  MiniGameSearch,
  SuitRunnerLatestCrashesResponse
} from '../entity';
import { Provider } from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class MiniService {
  constructor(
    private readonly apiService: HttpService,
    private readonly env: EnvironmentService
  ) {}

  getProvidersWithGames(
    platform: string,
    customerId: number
  ): Observable<Mini[]> {
    let path = `/integrations/v2/mini/providers?platform=${platform}&include=games`;
    if (customerId) {
      path += `&customerId=${customerId}`;
    }
    const baseUrl: BaseUrl = 'cmsApiUrl';

    return this.apiService
      .get$<{ data: Mini[] }>({ path, baseUrl })
      .pipe(map((d) => d.data));
  }
  public getMiniGameFavorites$(): Observable<MiniGames[]> {
    const path = '/integrations/v2/mini/user/favorites';
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: { game: MiniGames; provider: Provider }[] }>({
        path,
        baseUrl
      })
      .pipe(
        map((slots) =>
          slots.data.map((slot) => {
            return { ...slot.game, providerName: slot.provider.name };
          })
        ),
        catchError((err) => of([]))
      );
  }
  public searchMiniGames$(keyWord: string): Observable<MiniGameSearch[]> {
    const path = `/integrations/search?type=mini&platform=${this.env.config.platform}&search=${keyWord}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{ data: MiniGameSearch[] }>({ path, baseUrl })
      .pipe(
        map((d) => d.data),
        catchError(() => of([]))
      );
  }
  public addToFavorites(providerId: string, gameId: string) {
    const path = `/integrations/v2/mini/user/favorites?providerId=${providerId}&gameId=${gameId}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.post$({ path, baseUrl, body: {} });
  }

  public removeFromFavorites(providerId: string, gameId: string) {
    const path = `/integrations/v2/mini/user/favorites?providerId=${providerId}&gameId=${gameId}`;
    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService.delete$({ path, baseUrl, body: {} });
  }
  public getBaseProviders$(
    platform: Platform,
    customerId: number
  ): Observable<Provider[]> {
    let path = `/integrations?type=mini&platform=${platform}`;
    if (customerId) {
      path += `&customerId=${customerId}`;
    }

    const baseUrl: BaseUrl = 'cmsApiUrl';
    return this.apiService
      .get$<{
        data: Provider[];
      }>({ path, baseUrl })
      .pipe(map((result) => result.data));
  }

  public getSuitRunnerLatestCrashes(): Observable<
    SuitRunnerLatestCrashesResponse[]
  > {
    return this.apiService
      .get$<SuitRunnerLatestCrashesResponse[]>({
        customBaseUrl: 'https://suit-runner.crocobet.com',
        path: '/utility/getLastCrashes'
      })
      .pipe(catchError(() => of(null)));
  }
}
