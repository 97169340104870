export type PasscodeState =
  | 'ask'
  | 'accepted'
  | 'activated'
  | 'denied'
  | 'temporaryDenied';

export enum PasscodeVariables {
  firstName = 'passcodeFirstName',
  avatarUrl = 'passcodeAvatarUrl',
  deviceId = 'passcodeDeviceId',
  passcodeDate = 'passcodeDate',
  passcodeState = 'passcodeState'
}
