import { NgModule } from '@angular/core';

import { directives, standaloneDirectives } from './directives';

@NgModule({
  declarations: directives,
  imports: standaloneDirectives,
  exports: [...directives, ...standaloneDirectives]
})
export class DirectivesModule {}
