import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { HttpService } from '@crc/shared';
import {
  customerNumberAvailabilityReqInterface,
  customerNumberAvailabilityResInterface,
  customerNumberSmsReqInterface,
  customerNumberSmsResInterface,
  customerNumberValidityVerifyReqInterface,
  customerNumberValidityVerifyResInterface,
  customerPersonalDataValidityResInterface
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ChangeNumberService {
  constructor(private readonly httpService: HttpService) {}
  public checkNumberAvailibility$(
    number: string
  ): Observable<customerNumberAvailabilityResInterface | null> {
    return this.httpService
      .post$<
        customerNumberAvailabilityResInterface,
        customerNumberAvailabilityReqInterface
      >({
        baseUrl: 'cmsApiUrl',
        path: '/customers/account/phone-number/available',
        body: {
          value: number
        }
      })
      .pipe(
        catchError(() => {
          return of(null);
        })
      );
  }
  public checkCustomerValidity$(
    privateNumber: string,
    birthYear: string,
    citizenship: string
  ): Observable<customerPersonalDataValidityResInterface | null> {
    return this.httpService
      .get$<customerPersonalDataValidityResInterface>({
        baseUrl: 'cmsApiUrl',
        path: `/customers/id-check?privateNumber=${privateNumber}&birthYear=${birthYear}&citizenship=${citizenship}`
      })
      .pipe(catchError(() => of(null)));
  }
  public sendSMSToNewNumber$(
    number: string,
    personalId: string,
    birthYear: string
  ): Observable<customerNumberSmsResInterface | null> {
    return this.httpService
      .post$<customerNumberSmsResInterface, customerNumberSmsReqInterface>({
        baseUrl: 'cmsApiUrl',
        path: '/customers/account/phone-number/send-verification-code',
        body: {
          value: number,
          personalId,
          birthYear
        }
      })
      .pipe(catchError((err) => of(err?.error ?? null)));
  }
  public verifyNewNumberValidity$(
    number: string,
    code: string,
    personalId: string,
    birthYear: string
  ): Observable<customerNumberValidityVerifyResInterface | null> {
    return this.httpService
      .put$<
        customerNumberValidityVerifyResInterface,
        customerNumberValidityVerifyReqInterface
      >({
        baseUrl: 'cmsApiUrl',
        path: '/customers/account/phone-number',
        body: {
          value: number,
          verificationCode: `${code}`,
          personalId,
          birthYear
        }
      })
      .pipe(catchError((err) => of(err?.error ?? null)));
  }
}
