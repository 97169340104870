import { createAction, props } from '@ngrx/store';
import { userBarActiveItem, userBarPosition } from '../../containers';
import { LoginPopupActiveTab } from '../../../features/auth/containers';

export const toggleBurgerMenu = createAction('[Layout] Toggle Burger Menu');

export const isVisibleHeader = createAction(
  '[Layout] show/hide Header component',
  props<{ isVisible: boolean }>()
);

export const isVisibleFooter = createAction(
  '[Layout] show/hide Footer component',
  props<{ isVisible: boolean }>()
);

export const isVisibleHomeIcon = createAction(
  '[Layout] show/hide Home icon in user-bar',
  props<{ isVisible: boolean }>()
);

export const setUserBarActiveItem = createAction(
  '[Layout] Toggle active item in user-bar',
  props<{ isActive: userBarActiveItem }>()
);

export const setUserBarVisible = createAction(
  '[Layout] Toggle user bar visibility',
  props<{ visible: boolean }>()
);

export const setUserBarPosition = createAction(
  '[Layout] Set User Bar Position',
  props<{ position: userBarPosition }>()
);

export const setBalanceVisible = createAction(
  "[Layout] Set user's balance visibility in header",
  props<{ isVisible: boolean }>()
);

export const setBalanceVisibleSuccess = createAction(
  "[Layout] Set user's balance visibility in header success"
);

export const loginPopupActiveTab = createAction(
  '[Layout] get/set loginPopupActiveTab',
  props<{ activeTab: LoginPopupActiveTab }>()
);

export const layoutPageReload = createAction(
  '[Layout] Reload Page',
  props<{ reload: boolean }>()
);
