import { StorageService } from '@crc/shared';
import { Injectable } from '@angular/core';
import { SaveUsername } from '../entity';

@Injectable({
  providedIn: 'root'
})
export class UsernameStorage extends StorageService<SaveUsername.username> {
  constructor() {
    super();
  }
  setUsername(username: string) {
    this.set(SaveUsername.username, username);
  }
  getUsername(): string {
    return this.get(SaveUsername.username);
  }
}
